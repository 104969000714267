import { fork } from 'redux-saga/effects';

import PackagesGateway from 'api/Packages';
import { RootSagaReturnType } from 'sagas/types';

import watchCreateEvent from './createEvent';
import watchGetEventTemplate from './getEventTemplate';
import watchGetLeaseSaleSort from './getLeaseSaleSort';
import watchGetPackagesUploadUrl from './getPackagesUploadUrl';
import watchUploadPackagesDocument from './uploadPackagesDocument';
import watchGetVacantPossessionDataSort from './getVacantPossessionSort';
import watchGetDefectInspectionDataSort from './getDefectInspectionSort';
import watchGetPropertyManagementDataSort from './getVacantManagementSort';
import watchGetPropertyManagmentDataSort from './getPropertyManagementSort';
import watchGetPropertyManagementTransactionSort from './getPropertyManagementTransaction';
import watchGetViewAllTransactionsSort from './getPackagesViewAllTransactions';
import watchGetRecurringTransactionsSort from './getPackagesViewRecurringTransactions';
import watchTransactionStatusUpdateSort from './setTransactionUpdateStatus';
import watchEditRecurringTransaction from './setPackagesEditRecurringTransaction';
import watchCreatePropertyManagementTransactionSort from './setCreatePropertyManagementTransaction';
import watchGetTransactionsReportExport from './getTransactionsReportExport';
import watchEditTransaction from './setPackagesEditTransaction';
import watchUpdateProgressDefectInspection from './updateProgressDefectInspection';
import watchGetPropertyManagementWithdrawal from './getPropertyManagementWithdrawal';
import watchEditPropertyManagementWithdrawal from './editPropertyManagementWithdrawal';

export default (api: PackagesGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchCreateEvent, api);
        yield fork(watchGetEventTemplate, api);
        yield fork(watchGetLeaseSaleSort, api);
        yield fork(watchGetPackagesUploadUrl, api);
        yield fork(watchUploadPackagesDocument, api);
        yield fork(watchGetVacantPossessionDataSort, api);
        yield fork(watchGetDefectInspectionDataSort, api);
        yield fork(watchGetPropertyManagementDataSort, api);
        yield fork(watchGetPropertyManagmentDataSort, api);
        yield fork(watchGetPropertyManagementTransactionSort, api);
        yield fork(watchGetViewAllTransactionsSort, api);
        yield fork(watchGetRecurringTransactionsSort, api);
        yield fork(watchTransactionStatusUpdateSort, api);
        yield fork(watchEditTransaction, api);
        yield fork(watchEditRecurringTransaction, api);
        yield fork(watchCreatePropertyManagementTransactionSort, api);
        yield fork(watchGetTransactionsReportExport, api);
        yield fork(watchUpdateProgressDefectInspection, api);
        yield fork(watchGetPropertyManagementWithdrawal, api);
        yield fork(watchEditPropertyManagementWithdrawal, api);
    }

    return {
        rootSaga,
    };
};
