import { ApiResponse } from 'apisauce';
import { GatewayResponse } from 'api/types/types';

import { AdminViewAllCoBrokeResponse, GetREADetailsResponse, READetailsWithPropertyInfo } from '@dm/types';

import {
    GetCobrokesParams,
    IReaGateway,
    ReviewCobrokesParams,
    GetReaParams,
    GetReaDetailsParams,
} from './ReaBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class ReaGateway extends IReaGateway {
    async getCobrokes(params: GetCobrokesParams): GatewayResponse<AdminViewAllCoBrokeResponse> {
        const response: ApiResponse<AdminViewAllCoBrokeResponse> = await this.api.get('/admin/report/coBroke', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async reviewCobrokes(params: ReviewCobrokesParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/admin/rea/reviewCoBroke', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getRea(params: GetReaParams): GatewayResponse<GetREADetailsResponse> {
        const response: ApiResponse<GetREADetailsResponse> = await this.api.get('/admin/report/reas', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getReaDetails(params: GetReaDetailsParams): GatewayResponse<READetailsWithPropertyInfo | null> {
        const response: ApiResponse<READetailsWithPropertyInfo | null> = await this.api.get(`/admin/report/reaDetails/${params.reaId}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }
}
