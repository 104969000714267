import { select, call, put, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';

export default function* watchGetAdminDashboard(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/getAdminDashboardAttempt', handleGetAdminDashboard, api);
}

function* handleGetAdminDashboard(api: AuthGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getAdminDashboard], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.getAdminDashboardFailure('Something went wrong. Please try again.'));
            return;
        }
        yield put(Actions.getAdminDashboardSuccess(response.data));
    } else {
        yield put(Actions.getAdminDashboardFailure(response.message));
    }
}
