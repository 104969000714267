import React from 'react';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';

import { Colors } from '@dm/bigfish';

import Icons from 'assets/icons';

interface SearchInputProps {
  value?: string;
  placeholder?: string;
  onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const SearchInput = (props: SearchInputProps): JSX.Element => {
    const { value, placeholder, onChangeText, style, ...otherProps } = props;

    return (
        <Search style={style} {...otherProps}>
            <Icon src={Icons.SearchIcon} />
            <Input placeholder={placeholder} value={value} onChange={onChangeText} />
        </Search>
    );
};

SearchInput.defaultProps = {
    value: undefined,
    placeholder: 'Search for properties',
    onChangeText: undefined,
    style: undefined,
};

const Search = styled.div`
  position: relative;
  display: flex;  
  align-items: center;
  max-width: 401px;
`;

const Input = styled.input`
  padding: 10px 23px;
  width: 100%;
  border: none;
  font-size: 12px;
  line-height: 14px;
  border-bottom: 0.5px solid ${Colors.black};
  color: ${Colors.black};
  
  &:focus {
    outline: none;
  }
`;

const Icon = styled(SVG)`
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;

  color: ${Colors.black};
`;

export default SearchInput;
