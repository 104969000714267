import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';
import { CreateEventActionPayload } from 'redux/slices/packages/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

export default function* watchCreateEvent(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/createEventAttempt', handleCreateEvent, api);
}

function* handleCreateEvent(api: PackagesGateway, data: CreateEventActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { ownerId } = data.payload;

    const response = yield* call([api, api.createEvent], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.createEventFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.createEventSuccess());
        yield put(Actions.getOwnerDetailsAttempt(ownerId));

        toast.success('Create event success');
    } else {
        yield put(Actions.createEventFailure(response.message));

        toast.error('Something went wrong. Please try again.');
    }
}
