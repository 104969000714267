import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { toast } from 'react-toastify';
import { Oval, TailSpin, Bars } from 'react-loader-spinner';

import { Category, GetOwnerDetailsResponse, IProperty, IPropertyDetails, PropertyFacilities, PropertyFurnishings } from '@dm/types';
import { BreadCrumb, CardCollapsible, Button, LineInput, Colors, Checkbox, RoundInput } from '@dm/bigfish';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import Icons from 'assets/icons';

import MainContainer from 'components/MainContainer';
import SupportInfo from 'components/SupportInfo';
import SelectionInput from 'components/SelectionInput';
import PropertyCard from 'components/PropertyCard';
import SearchInput from 'components/SearchInput';
import Modal from 'components/Modal';
import DescriptionRoundInput from 'components/DescriptionRoundInput';

import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesCity, CoordinatesCountry, CoordinatesPoscode, CoordinatesState } from 'redux/slices/property/types';
import { IEditPropertyDetails, IPropertyAmenities, IPropertyLocation, IPropertyOwners } from 'entities/property';
import SelectionInputMulti from 'components/SelectionMultipleInput';
import PropertyImages from './components/PropertyImage';
import Map from './components/PropertyMap';
import CreateOwnerModal from './components/CreateOwnerModal';

interface CreateNewProps {
    getSearchedPropertyLoading: boolean;
    getSearchedPropertyError: string;
    searchedProperty: IPropertyOwners | null;
    loadingDescription: boolean;
    loadingDetails: boolean;
    loadingAmenities: boolean;
    loadingAttachOwner: boolean;
    coordinates: Coordinates;
    address1st: CoordinatesAddress1;
    address2nd: CoordinatesAddress2;
    poscodes: CoordinatesPoscode;
    states: CoordinatesState;
    countryLocation: CoordinatesCountry;
    cityLocation: CoordinatesCity;
    loadingLocation: boolean;
    propertyDetailsLoading: boolean;
    propertyDetailsData: IProperty | null;
    propertyDetailsSuccess: boolean;
    getOwnerDetailsLoading: boolean;
    getOwnerDetailsError: string;
    ownerDetails: GetOwnerDetailsResponse | null;
    getOwnerDetails: (userId: string) => void;
    clearOwnerDetails: () => void;
    getSearchedProperty: (search: string) => void;
    clearSearchedProperty: () => void;
    setDescription(id: string, description: string): void;
    setDetails(data: IPropertyDetails): void;
    setAmenities(data: IPropertyAmenities): void;
    setPropertyUser(propertyId: string, userId: string): void;
    setLocation(data: IPropertyLocation): void;
    getPropertyDetails: (id: string) => void;
    setIsPropertyDataLoaded: (state: boolean) => void;
    clearpropertyDetails: () => void;
    showErrorMessage: boolean;
    editPropertyDetailsAttempt: boolean;
    editPropertyDetails: (data: IEditPropertyDetails) => void;
    setCreateOwnerModal: (state: boolean) => void;
}

interface NavParams {
    newPropertyId: string;
}

const EditProperty = (props: CreateNewProps): JSX.Element => {
    const {
        getSearchedPropertyLoading,
        getSearchedPropertyError,
        searchedProperty,
        getSearchedProperty,
        clearSearchedProperty,
        setDescription,
        loadingDescription,
        setDetails,
        loadingDetails,
        setAmenities,
        loadingAmenities,
        setPropertyUser,
        loadingAttachOwner,
        setLocation,
        coordinates,
        address1st,
        address2nd,
        poscodes, states,
        countryLocation,
        cityLocation,
        loadingLocation,
        getPropertyDetails,
        propertyDetailsLoading,
        propertyDetailsData,
        propertyDetailsSuccess,
        setIsPropertyDataLoaded,
        getOwnerDetailsLoading,
        getOwnerDetailsError,
        ownerDetails,
        getOwnerDetails,
        clearOwnerDetails,
        clearpropertyDetails,
        showErrorMessage,
        editPropertyDetailsAttempt,
        editPropertyDetails,
        setCreateOwnerModal,
    } = props;
    const locationParams = useLocation();
    const { newPropertyId } = locationParams.state as NavParams;
    const [showModal, setShowModal] = useState(false);
    // status
    const [ownerDetailsStatus, setOwnerDetailsStatus] = useState(false);
    const [attachPropStatus, setAttachPropStatus] = useState(false);
    const [descriptionStatus, setDescriptionStatus] = useState(false);
    const [amenitiesStatus, setAmenitiesStatus] = useState(false);
    const [imagesStatus, setImagesStatus] = useState(false);
    const [locationStatus, setLocationStatus] = useState(false);

    // attach properties
    const [searchedProp, setSearchProp] = useState('');
    const [searchedPropList, setSearchedPropList] = useState<IPropertyOwners | null>();

    const [selectedPropIdList, setSelectedPropIdList] = useState<string[]>([]);
    const [tempProp, setTempProp] = useState<any | null>(null);
    const [idToAttach, setIdToAttach] = useState('');
    const [gotOwner, setGotOwner] = useState(false);

    // property Location
    const [postcode, setPostcode] = useState('');
    const [edited, setEdited] = useState(false);

    const { latitude, longitude } = coordinates;

    const { address1 } = address1st;
    const { address2 } = address2nd;
    const { poscode } = poscodes;
    const { state } = states;
    const { country } = countryLocation;
    const { city } = cityLocation;

    // property details
    const [type, setType] = useState(0);
    const [titleType, setTitleType] = useState(1);
    const [landTitle, setLandTitle] = useState(1);
    const [preferredTenant, setPreferredTenant] = useState<number[]>([]);
    const [tenure, setTenure] = useState(1);
    const [unitType, setUnitType] = useState('');
    const [storey, setStorey] = useState(1);
    const [occupancy, setOccupancy] = useState('');
    const [facingDirection, setFacingDirection] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [buildUpSize, setBuildUpSize] = useState(0);
    const [furnishing, setFurnishing] = useState(0);
    const [builtUpDimension, setBuiltUpDimension] = useState('');
    const [buildUpPricePerSqft, setBuildUpPricePerSqft] = useState(0);

    const [tnbAccNo, setTnbAccNo] = useState<string | undefined>('');
    const [parcelRentAccNo, setParcelRentAccNo] = useState<string | undefined>('');
    const [assTaxAccNo, setAssTaxAccNo] = useState<string | undefined>('');
    const [petGasAccNo, setPetGasAccNo] = useState<string | undefined>('');
    const [parkingLotNo, setParkingLotNo] = useState<string | undefined>('');
    const [indahWaterNo, setIndahWaterNo] = useState<string | undefined>('');
    const [spaPrice, setSpaPrice] = useState<string | undefined>('');
    const [nettPrice, setNettPrice] = useState<string | undefined>('');

    // property Amenities states
    const [television, setTelevision] = useState(false);
    const [curtain, setCurtain] = useState(false);
    const [mattress, setMattress] = useState(false);
    const [washingMachine, setWashingMachine] = useState(false);
    const [hoodHub, setHoodHub] = useState(false);
    const [airConditioner, setAirConditioner] = useState(false);
    const [waterHeater, setWaterHeater] = useState(false);
    const [diningTable, setDiningTable] = useState(false);
    const [wardrobe, setWardrobe] = useState(false);
    const [kitchenCabinet, setKitchenCabinet] = useState(false);
    const [oven, setOven] = useState(false);
    const [refrigerator, setRefrigerator] = useState(false);
    const [sofa, setSofa] = useState(false);
    const [microwave, setMicrowave] = useState(false);
    const [bedFrame, setBedFrame] = useState(false);
    const [internet, setInternet] = useState(false);

    const [bBQ, setBBQ] = useState(false);
    const [coveredParking, setCoveredParking] = useState(false);
    const [gymnasium, setGymnasium] = useState(false);
    const [basketballCourt, setBasketballCourt] = useState(false);
    const [restaurant, setRestaurant] = useState(false);
    const [airConditioner2, setAirConditioner2] = useState(false);
    const [dobiLaundromat, setDobiLaundromat] = useState(false);
    const [nursery, setNursery] = useState(false);
    const [playground, setPlayground] = useState(false);
    const [sauna, setSauna] = useState(false);
    const [swimmingPool, setSwimmingPool] = useState(false);
    const [tennisCourt, setTennisCourt] = useState(false);
    const [security24hour, setSecurity24hour] = useState(false);
    const [miniMart, setMiniMart] = useState(false);
    const [squashCourt, setSquashCourt] = useState(false);
    const [badmintonCourt, setBadmintonCourt] = useState(false);
    const [elevator, setElevator] = useState(false);

    // property Description
    const [descriptionText, setDescriptionText] = useState('');

    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                label: Translate.t('CreateNew.CreateNewBcDashboard'),
                onClick: () => [NavActions.navToPropertyReports(), clearpropertyDetails()],
            },
            {
                label: Translate.t('CreateNew.CreateNewBcEdit'),
            },
            {
                label: 'Property name',
            },
        ],
    );

    const ownerDetailsRef = useRef<any>(null);
    const attachPropRef = useRef<any>(null);
    const descriptionRef = useRef<any>(null);
    const amenitiesRef = useRef<any>(null);
    const imagesRef = useRef<any>(null);
    const locationRef = useRef<any>(null);

    const typeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TypeApartment'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TypeLanded'),
        },
    ];

    const setTitleTypeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsStrata'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsMaster'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsIndividual'),
        },
    ];
    const tenureOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.tenureOptionsFreehold'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.tenureOptionsLeaseHold'),
        },

    ];

    const LandTitleOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsResidential'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsCommercial'),
        },

    ];

    const preferredTenantOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsChinese'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsIndian'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsMalay'),
        },
        {
            id: 3,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsOthers'),
        },

    ];

    const furnishingOption = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.furnishingOptionFully'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.furnishingOptionPartial'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.furnishingOptionNone'),
        },
    ];

    useEffect(() => {
        return (() => {
            clearpropertyDetails();
        });
    }, []);

    useEffect(() => {
        if (propertyDetailsData) {
            setBreadCrumb(
                [
                    {
                        label: Translate.t('CreateNew.CreateNewBcDashboard'),
                        onClick: () => [NavActions.navToPropertyReports(), clearpropertyDetails()],
                    },
                    {
                        label: Translate.t('CreateNew.CreateNewBcEdit'),
                    },
                    {
                        label: propertyDetailsData.name as string,
                    },
                ],
            );
        }
    }, [propertyDetailsData]);

    useEffect(() => {
        getPropertyDetails(newPropertyId);

        if (propertyDetailsData?.userId) {
            getOwnerDetails(propertyDetailsData.userId);
        }

        if (propertyDetailsData?.description?.length) {
            setDescriptionText(propertyDetailsData.description);
            setIsPropertyDataLoaded(false);
            setDescriptionStatus(true);
        }

        if ((propertyDetailsData?.amenities?.facilities.length !== 0) || ((propertyDetailsData?.amenities?.furnishing.length !== 0))) {
            if (propertyDetailsData?.amenities) {
                let parsedAminitiesFurnishingData: PropertyFurnishings[] = [];
                let parsedAminitiesFacilitiesData: PropertyFacilities[] = [];

                if (propertyDetailsData?.amenities.furnishing) {
                    parsedAminitiesFurnishingData = propertyDetailsData?.amenities.furnishing.map(item => {
                        const { name = '', available = false } = item;

                        return {
                            name,
                            available,
                        };
                    });
                }

                if (propertyDetailsData?.amenities.facilities) {
                    parsedAminitiesFacilitiesData = propertyDetailsData?.amenities.facilities.map(item => {
                        const { name = '', available = false } = item;

                        return {
                            name,
                            available,
                        };
                    });
                }

                setIsPropertyDataLoaded(false);
                onAmenitiesDataPersistHandler();
                setAmenitiesStatus(true);
            }
        }

        if (propertyDetailsData?.details) {
            onPropertyDetailsPersist();
        }
        if ((propertyDetailsData?.images !== undefined) && (propertyDetailsData?.images.length > 0)) {
            setImagesStatus(true);
        }

        if ((propertyDetailsData?.userId !== undefined) && (propertyDetailsData?.userId.length)) {
            onAttachingOwnerToProperty();
        }
        if (propertyDetailsData?.location?.address1.length) {
            setLocationStatus(true);
        }
        if (propertyDetailsData?.unitNo) {
            setUnitNumber(propertyDetailsData.unitNo);
        }
    }, [propertyDetailsSuccess]);

    useEffect(() => {
        return () => {
            clearSearchedProperty();
        };
    }, []);

    useEffect(() => {
        if (searchedProperty) setSearchedPropList(searchedProperty);
    }, [searchedProperty]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        if (searchWord) {
            getSearchedProperty(searchWord);
        } else {
            setSearchedPropList(null);
        }
    }, 500, { leading: false }));

    const onChangeSearchedProp = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setSearchProp(value);
        throttledSetSearch.current(value);
    };

    const onClearAttachPropAndSetNewSearch = () => {
        setAttachPropStatus(false);
        setSelectedPropIdList([]);
        setSearchProp('');
        setIdToAttach('');
        setGotOwner(false);
    };

    // Functions to close collapsibles

    const onCloseCollapsibleOwnerDetails = () => {
        if (ownerDetailsRef.current) {
            ownerDetailsRef.current.closeCollapsible();
        }
    };

    const onCloseCollapsibleAttachProp = () => {
        if (attachPropRef.current) {
            attachPropRef.current.closeCollapsible();
        }
    };

    const onCloseCollapsibleDescription = () => {
        if (descriptionRef.current) {
            descriptionRef.current.closeCollapsible();
        }
        setDescriptionStatus(true);
    };

    const onCloseCollapsibleAmenities = () => {
        if (amenitiesRef.current) {
            amenitiesRef.current.closeCollapsible();
        }
        setAmenitiesStatus(true);
    };

    const onCloseCollapsibleLocation = () => {
        if (locationRef.current) {
            locationRef.current.closeCollapsible();
        }
        setLocationStatus(true);
    };

    // Functions to set property details
    const onPropertyDetailsPersist = () => {
        setBuildUpPricePerSqft(propertyDetailsData?.details?.buildUpPricePerSqft ?? 0);
        setBuildUpSize(propertyDetailsData?.details?.buildUpSize ?? 0);
        setBuiltUpDimension(propertyDetailsData?.details?.builtUpDimension ?? '');
        setFacingDirection(propertyDetailsData?.details?.facingDirection ?? '');
        setFurnishing(propertyDetailsData?.details?.furnishing ?? 0);
        setLandTitle(propertyDetailsData?.details?.landTitle ?? 0);
        setOccupancy(propertyDetailsData?.details?.occupancy ?? '');
        setPreferredTenant(propertyDetailsData?.details?.preferredTenant ?? []);
        setStorey(propertyDetailsData?.details?.storey ?? 0);
        setTenure(propertyDetailsData?.details?.tenure ?? 1);
        setTitleType(propertyDetailsData?.details?.titleType ?? 1);
        setType(propertyDetailsData?.details?.type ?? 0);
        setTnbAccNo(propertyDetailsData?.details?.tnbAccNo);
        setParcelRentAccNo(propertyDetailsData?.details?.parcelRentAccNo);
        setAssTaxAccNo(propertyDetailsData?.details?.assTaxAccNo);
        setPetGasAccNo(propertyDetailsData?.details?.petGasAccNo);
        setParkingLotNo(propertyDetailsData?.details?.parkingLotNo);
        setIndahWaterNo(propertyDetailsData?.details?.indahWaterAccNo);

        if ((propertyDetailsData?.details?.buildUpPricePerSqft !== undefined)
            && (propertyDetailsData?.details?.buildUpSize !== undefined)
            && (propertyDetailsData?.details?.builtUpDimension !== undefined)
            && (propertyDetailsData?.details?.facingDirection !== undefined)
            && (propertyDetailsData?.details?.furnishing !== undefined)
            && (propertyDetailsData?.details?.landTitle !== undefined)
            && (propertyDetailsData?.details?.occupancy !== undefined)
            && (propertyDetailsData?.details?.storey !== undefined)
            && (propertyDetailsData?.details?.tenure !== undefined)
            && (propertyDetailsData?.details?.titleType !== undefined)
            && (propertyDetailsData?.details?.type !== undefined)
            && (propertyDetailsData?.details?.unitType !== undefined)
            && (propertyDetailsData?.details?.tnbAccNo !== undefined)
            && (propertyDetailsData?.details?.parcelRentAccNo !== undefined)
            && (propertyDetailsData?.details?.assTaxAccNo !== undefined)
            && (propertyDetailsData?.details?.petGasAccNo !== undefined)
            && (propertyDetailsData?.details?.parkingLotNo !== undefined)) {
            setOwnerDetailsStatus(true);
        }
    };

    const onSubmitPropertyDetails = () => {
        const details = {
            type,
            titleType,
            buildUpSize,
            occupancy,
            landTitle,
            tenure,
            furnishing,
            facingDirection,
            preferredTenant,
            unitType,
            storey,
            builtUpDimension,
            buildUpPricePerSqft,
            tnbAccNo,
            parcelRentAccNo,
            assTaxAccNo,
            petGasAccNo,
            parkingLotNo,
            indahWaterAccNo: indahWaterNo,
            spaPrice,
            nettPrice,
        };

        editPropertyDetails({
            propertyId: newPropertyId,
            details,
            unitNo: unitNumber,
        });
        setOwnerDetailsStatus(true);
        onCloseCollapsibleOwnerDetails();
    };

    const onClearPropertyDetails = () => {
        setOwnerDetailsStatus(false);
        setType(0);
        setTitleType(1);
        setBuildUpSize(0);
        setOccupancy('');
        setLandTitle(0);
        setTenure(0);
        setFurnishing(0);
        setFacingDirection('');
        setPreferredTenant([0]);
        setUnitType('');
        setStorey(0);
        setBuiltUpDimension('');
        setBuildUpPricePerSqft(0);
        setTnbAccNo('');
        setParcelRentAccNo('');
        setAssTaxAccNo('');
        setPetGasAccNo('');
        setParkingLotNo('');
        setIndahWaterNo('');
    };

    const onChangePreferredTenantAction = (newSelectedOptions: number[]) => {
        setPreferredTenant(newSelectedOptions);
    };

    // Functions to set location

    const onSubmitLocationHandler = () => {
        setPostcode(poscode);
        const location = {
            long: Number(longitude),
            lat: Number(latitude),
            address1,
            address2,
            postcode: poscode,
            city,
            state,
            country,
        };

        editPropertyDetails({
            propertyId: newPropertyId,
            location,
        });
        onCloseCollapsibleLocation();
    };

    // Functions to set amenities

    const onSubmitConfirmAmenitiesHandler = () => {
        const data = {
            amenities: {
                furnishing: [{
                    name: 'television ',
                    available: television,
                },
                {
                    name: 'curtain',
                    available: curtain,
                },
                {
                    name: 'mattress',
                    available: mattress,
                },
                {
                    name: 'washingMachine',
                    available: washingMachine,
                },
                {
                    name: 'hoodHub',
                    available: hoodHub,
                },
                {
                    name: 'airConditioner',
                    available: airConditioner,
                },
                {
                    name: 'waterHeater',
                    available: waterHeater,
                },
                {
                    name: 'diningTable',
                    available: diningTable,
                },
                {
                    name: 'wardrobe',
                    available: wardrobe,
                },
                {
                    name: 'kitchenCabinet',
                    available: kitchenCabinet,
                },
                {
                    name: 'oven',
                    available: oven,
                },
                {
                    name: 'refrigerator',
                    available: refrigerator,
                },
                {
                    name: 'sofa',
                    available: sofa,
                },
                {
                    name: 'microwave',
                    available: microwave,
                },
                {
                    name: 'bedFrame',
                    available: bedFrame,
                },
                {
                    name: 'internet',
                    available: internet,
                }],
                facilities: [{
                    name: 'bbq',
                    available: bBQ,
                },
                {
                    name: 'coveredParking',
                    available: coveredParking,
                },
                {
                    name: 'gymnasium',
                    available: gymnasium,
                },
                {
                    name: 'basketballCourt',
                    available: basketballCourt,
                },
                {
                    name: 'restaurant',
                    available: restaurant,
                },
                {
                    name: 'airConditioner2',
                    available: airConditioner2,
                },
                {
                    name: 'dobiLaundromat',
                    available: dobiLaundromat,
                },
                {
                    name: 'nursery',
                    available: nursery,
                },
                {
                    name: 'playground',
                    available: playground,
                },
                {
                    name: 'sauna',
                    available: sauna,
                },
                {
                    name: 'swimmingPool',
                    available: swimmingPool,
                },
                {
                    name: 'tennisCourt',
                    available: tennisCourt,
                },
                {
                    name: 'security24hour',
                    available: security24hour,
                },
                {
                    name: 'miniMart',
                    available: miniMart,
                },
                {
                    name: 'squashCourt',
                    available: squashCourt,
                },
                {
                    name: 'badmintonCourt',
                    available: badmintonCourt,
                },
                {
                    name: 'elevator',
                    available: elevator,
                }],
            },

        };

        editPropertyDetails({
            propertyId: newPropertyId,
            amenities: data.amenities,
        });
        onCloseCollapsibleAmenities();
    };

    const onAmenitiesDataPersistHandler = () => {
        setTelevision(propertyDetailsData?.amenities?.furnishing[0]?.available ?? false);
        setCurtain(propertyDetailsData?.amenities?.furnishing[1]?.available ?? false);
        setMattress(propertyDetailsData?.amenities?.furnishing[2]?.available ?? false);
        setWashingMachine(propertyDetailsData?.amenities?.furnishing[3]?.available ?? false);
        setHoodHub(propertyDetailsData?.amenities?.furnishing[4]?.available ?? false);
        setAirConditioner(propertyDetailsData?.amenities?.furnishing[5]?.available ?? false);
        setWaterHeater(propertyDetailsData?.amenities?.furnishing[6]?.available ?? false);
        setDiningTable(propertyDetailsData?.amenities?.furnishing[7]?.available ?? false);
        setWardrobe(propertyDetailsData?.amenities?.furnishing[8]?.available ?? false);
        setKitchenCabinet(propertyDetailsData?.amenities?.furnishing[9]?.available ?? false);
        setOven(propertyDetailsData?.amenities?.furnishing[10]?.available ?? false);
        setRefrigerator(propertyDetailsData?.amenities?.furnishing[11]?.available ?? false);
        setSofa(propertyDetailsData?.amenities?.furnishing[12]?.available ?? false);
        setMicrowave(propertyDetailsData?.amenities?.furnishing[13]?.available ?? false);
        setBedFrame(propertyDetailsData?.amenities?.furnishing[14]?.available ?? false);
        setInternet(propertyDetailsData?.amenities?.furnishing[15]?.available ?? false);

        setBBQ(propertyDetailsData?.amenities?.facilities[0]?.available ?? false);
        setCoveredParking(propertyDetailsData?.amenities?.facilities[1]?.available ?? false);
        setGymnasium(propertyDetailsData?.amenities?.facilities[2]?.available ?? false);
        setBasketballCourt(propertyDetailsData?.amenities?.facilities[3]?.available ?? false);
        setRestaurant(propertyDetailsData?.amenities?.facilities[4]?.available ?? false);
        setAirConditioner2(propertyDetailsData?.amenities?.facilities[5]?.available ?? false);
        setDobiLaundromat(propertyDetailsData?.amenities?.facilities[6]?.available ?? false);
        setNursery(propertyDetailsData?.amenities?.facilities[7]?.available ?? false);
        setPlayground(propertyDetailsData?.amenities?.facilities[8]?.available ?? false);
        setSauna(propertyDetailsData?.amenities?.facilities[9]?.available ?? false);
        setSwimmingPool(propertyDetailsData?.amenities?.facilities[10]?.available ?? false);
        setTennisCourt(propertyDetailsData?.amenities?.facilities[11]?.available ?? false);
        setSecurity24hour(propertyDetailsData?.amenities?.facilities[12]?.available ?? false);
        setMiniMart(propertyDetailsData?.amenities?.facilities[13]?.available ?? false);
        setSquashCourt(propertyDetailsData?.amenities?.facilities[14]?.available ?? false);
        setBadmintonCourt(propertyDetailsData?.amenities?.facilities[15]?.available ?? false);
        setElevator(propertyDetailsData?.amenities?.facilities[16]?.available ?? false);
    };

    const onSubmitCancelAmenitiesHandler = () => {
        setTelevision(false);
        setCurtain(false);
        setMattress(false);
        setWashingMachine(false);
        setHoodHub(false);
        setAirConditioner(false);
        setWaterHeater(false);
        setDiningTable(false);
        setWardrobe(false);
        setKitchenCabinet(false);
        setOven(false);
        setRefrigerator(false);
        setSofa(false);
        setMicrowave(false);
        setBedFrame(false);
        setInternet(false);
        setBBQ(false);
        setCoveredParking(false);
        setGymnasium(false);
        setBasketballCourt(false);
        setRestaurant(false);
        setAirConditioner2(false);
        setDobiLaundromat(false);
        setNursery(false);
        setPlayground(false);
        setSauna(false);
        setSwimmingPool(false);
        setTennisCourt(false);
        setSecurity24hour(false);
        setMiniMart(false);
        setSquashCourt(false);
        setBadmintonCourt(false);
        setElevator(false);
    };

    // setDescription function
    const onSubmitDescriptionConfirmHandler = () => {
        editPropertyDetails({
            propertyId: newPropertyId,
            description: descriptionText,
        });
        onCloseCollapsibleDescription();
    };

    const onChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescriptionText(e.target.value);
    };

    // Every Functions related to attaching owner to property function

    const onSubmitAttachOwnerToProperty = () => {
        if (idToAttach !== '') {
            setAttachPropStatus(true);
            onCloseCollapsibleAttachProp();
            setPropertyUser(newPropertyId, idToAttach);
        } else {
            toast.error(Translate.t('Property.AttachOwner.PleaseSelectOwner'));
        }
    };

    const onAttachingOwnerToProperty = () => {
        if (propertyDetailsData && propertyDetailsData?.userId !== '620f604aad315a6a50ea4556') {
            setGotOwner(true);
            setAttachPropStatus(true);
            onCloseCollapsibleAttachProp();
            setPropertyUser(newPropertyId, propertyDetailsData.userId);
        }
    };

    const isPropertyClicked = (array: string[], searchPropertyId: string) => {
        return array.some(element => {
            if (element === searchPropertyId) {
                return true;
            }

            return false;
        });
    };

    const onClickProperty = (propertyId: string) => {
        if (selectedPropIdList.length > 0) {
            toast.error(Translate.t('Property.AttachOwner.PleaseSelectOwner'));
            toast.error(Translate.t('Property.AttachOwner.OnlyOneOwner'));
        } else {
            const isExisted = selectedPropIdList.some(propId => propId === propertyId);
            if (!isExisted) {
                setSelectedPropIdList([...selectedPropIdList, propertyId]);
            } else {
                const filteredExisted = selectedPropIdList.filter(propId => propId !== propertyId);
                setSelectedPropIdList(filteredExisted);
            }
        }

        setIdToAttach(propertyId);
    };

    const renderAttachOwner = () => {
        if (gotOwner && ownerDetails) {
            return (
                <PropertyCardContainer>
                    <PropertyCard
                        style={{ zIndex: '1' }}
                        chipText={Translate.t('Property.AttachOwner.AttachOwner')}
                        title={ownerDetails?.userInfo.firstName ?? ownerDetails.userInfo.email}
                        location={ownerDetails?.userInfo.correspondingAddress.address1 ?? ''}
                        active={
                            gotOwner
                        }
                    />
                </PropertyCardContainer>

            );
        }
        if (getSearchedPropertyLoading) {
            return (
                <StatusContainer>
                    <Oval
                        height={180}
                        width={180}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </StatusContainer>

            );
        }

        if (!searchedPropList?.data.length) {
            return (
                <StatusContainer>
                    <p>No owner found</p>
                </StatusContainer>
            );
        }

        const createPropertyLocation = (owner: IPropertyOwners, index: number) => {
            const { location } = owner.data[index];
            if (location) {
                return `${location}`;
            }

            return '';
        };

        return (
            <PropertyCardContainer>

                {searchedPropList?.data.map((owner, index) => {
                    const propertyLocation = createPropertyLocation(searchedPropList, index);
                    const { userId, name, location } = owner;
                    return (
                        <PropertyCard
                            style={{ zIndex: '1' }}
                            key={userId}
                            chipText={Translate.t('Property.AttachOwner.AttachOwner')}
                            title={name}
                            location={propertyLocation}
                            onClick={() => {
                                if (!selectedPropIdList.includes(userId)) {
                                    setTempProp(searchedPropList?.data[index]);
                                    setShowModal(true);
                                }
                            }}
                            active={
                                isPropertyClicked(selectedPropIdList, userId)
                            }
                        />
                    );
                })}
            </PropertyCardContainer>

        );
    };

    const renderCardPropertyDetails = () => {
        if (propertyDetailsLoading) {
            return (
                <SetupContainer>
                    <div style={{ display: 'flex', marginTop: '20%', justifyContent: 'center' }}>
                        <Bars
                            color={Colors.primary}
                            height={260}
                            width={260}
                        />
                    </div>
                </SetupContainer>
            );
        }

        return (
            <SetupContainer>
                <CardCollapsible ref={ownerDetailsRef} preText={Translate.t('CreateNew.CreateNewStep1')} title={Translate.t('Property.PropertyDetails.CollapsibleTitle')} icon={ownerDetailsStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={ownerDetailsStatus}>
                    <SectionContainer>
                        <div>
                            <SectionContainer>
                                <SectionTitle>{Translate.t('CreateNew.CreateNewOwnerDetails')}</SectionTitle>
                                <DetailsContainer>
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.PropertyType')} data={typeOptions} currentValue={type} onChangeSelection={(e) => setType(+e.target.value)} />
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.LandTitleLabel')} data={LandTitleOptions} currentValue={landTitle} onChangeSelection={(e) => setLandTitle(+e.target.value)} />
                                    <SelectionInputMulti label={Translate.t('Property.PropertyDetails.PreferredTenantLabel')} options={preferredTenantOptions} currentValue={preferredTenant} onChange={onChangePreferredTenantAction} />
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.PropertyTitleTypeLabel')} data={setTitleTypeOptions} currentValue={titleType} onChangeSelection={(e) => setTitleType(+e.target.value)} />
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.TenureLabel')} data={tenureOptions} currentValue={tenure} onChangeSelection={(e) => setTenure(+e.target.value)} required />
                                    <LineInput label={Translate.t('Property.PropertyDetails.UnitTypeLabel')} placeholder={Translate.t('Property.PropertyDetails.UnitTypePlaceHolder')} value={unitType} onChangeText={e => setUnitType(e.target.value)} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.BuildUpSizeLabel')} value={buildUpSize.toString()} onChangeText={e => setBuildUpSize(Number(e.target.value))} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.BuiltupPriceperSqftLabel')} placeholder='1234' value={buildUpPricePerSqft.toString()} onChangeText={e => setBuildUpPricePerSqft(Number(e.target.value))} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.BuiltUpDimensionSqftLabel')} placeholder='1234' value={builtUpDimension} onChangeText={e => setBuiltUpDimension((e.target.value))} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.StoreyLabel')} value={storey.toString()} onChangeText={e => setStorey(Number(e.target.value))} />
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.FurnishingLabel')} data={furnishingOption} currentValue={furnishing} onChangeSelection={(e) => setFurnishing(+e.target.value)} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.OccupancyLabel')} placeholder={Translate.t('Property.PropertyDetails.OccupancyPlaceHolder')} value={occupancy} onChangeText={e => setOccupancy(e.target.value)} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.FacingDirectionLabel')} placeholder={Translate.t('Property.PropertyDetails.FacingDirectionPlaceHolder')} value={facingDirection} onChangeText={e => setFacingDirection(e.target.value)} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.UnitNumberLabel')} placeholder='eg: 123' value={unitNumber} onChangeText={e => setUnitNumber(e.target.value)} />
                                    <LineInput
                                        label='TNB acc number'
                                        value={tnbAccNo}
                                        onChangeText={e => setTnbAccNo(e.target.value)}
                                    />
                                    <LineInput
                                        label='Parcel rent acc no'
                                        value={parcelRentAccNo}
                                        onChangeText={e => setParcelRentAccNo(e.target.value)}
                                    />
                                    <LineInput
                                        label='Assessment tax no'
                                        value={assTaxAccNo}
                                        onChangeText={e => setAssTaxAccNo(e.target.value)}
                                    />
                                    <LineInput
                                        label='PET gas acc no'
                                        value={petGasAccNo}
                                        onChangeText={e => setPetGasAccNo(e.target.value)}
                                    />
                                    <LineInput
                                        label='Parking lot no'
                                        value={parkingLotNo}
                                        onChangeText={e => setParkingLotNo(e.target.value)}
                                    />
                                    <LineInput
                                        label='Indah Water acc no'
                                        value={indahWaterNo}
                                        onChangeText={e => setIndahWaterNo(e.target.value)}
                                    />
                                    <LineInput
                                        label='SPA price'
                                        value={spaPrice}
                                        onChangeText={e => setSpaPrice(e.target.value)}
                                    />
                                    <LineInput
                                        label='Nett price'
                                        value={nettPrice}
                                        onChangeText={e => setNettPrice(e.target.value)}
                                    />
                                </DetailsContainer>
                            </SectionContainer>

                            <SubmitButton>
                                <Button type='submit' loading={editPropertyDetailsAttempt} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={onSubmitPropertyDetails} />
                                <Button type='button' loading={editPropertyDetailsAttempt} secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onClearPropertyDetails} />
                            </SubmitButton>
                        </div>
                    </SectionContainer>
                </CardCollapsible>

                <CardCollapsible ref={locationRef} preText={Translate.t('CreateNew.CreateNewStep2')} title={Translate.t('Property.Location.CollapsibleTitle')} icon={locationStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={locationStatus}>
                    <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                        <p>{Translate.t('Property.Location.TitleMessage')}</p>
                        <Map newPropertyId={newPropertyId} setEdited={setEdited} edited={edited} />
                        <SubmitButton>
                            <Button type='button' disabled={showErrorMessage} loading={loadingLocation} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={() => onSubmitLocationHandler()} />
                        </SubmitButton>
                    </SectionContainer>
                </CardCollapsible>

                <CardCollapsible ref={amenitiesRef} preText={Translate.t('CreateNew.CreateNewStep3')} title='property Amenities' icon={amenitiesStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={amenitiesStatus}>
                    <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                        <>
                            <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFurnishing')}</ItemTitle>
                            <DetailsContainer>
                                <Checkbox label={Translate.t('Property.PropertyAmenities.TelevisionLabel')} checked={television} onClickCheckbox={() => setTelevision(!television)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.CurtainLabel')} checked={curtain} onClickCheckbox={() => setCurtain(!curtain)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MattressLabel')} checked={mattress} onClickCheckbox={() => setMattress(!mattress)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WashingMachineLabel')} checked={washingMachine} onClickCheckbox={() => setWashingMachine(!washingMachine)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.HoodHubLabel')} checked={hoodHub} onClickCheckbox={() => setHoodHub(!hoodHub)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner} onClickCheckbox={() => setAirConditioner(!airConditioner)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WaterHeaterLabel')} checked={waterHeater} onClickCheckbox={() => setWaterHeater(!waterHeater)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.DiningTableLabel')} checked={diningTable} onClickCheckbox={() => setDiningTable(!diningTable)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WardrobeLabel')} checked={wardrobe} onClickCheckbox={() => setWardrobe(!wardrobe)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.KitchenCabinetLabel')} checked={kitchenCabinet} onClickCheckbox={() => setKitchenCabinet(!kitchenCabinet)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.OvenLabel')} checked={oven} onClickCheckbox={() => setOven(!oven)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.RefrigeratorLabel')} checked={refrigerator} onClickCheckbox={() => setRefrigerator(!refrigerator)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SofaLabel')} checked={sofa} onClickCheckbox={() => setSofa(!sofa)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MicrowaveLabel')} checked={microwave} onClickCheckbox={() => setMicrowave(!microwave)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BedframeLabel')} checked={bedFrame} onClickCheckbox={() => setBedFrame(!bedFrame)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.InternetLabel')} checked={internet} onClickCheckbox={() => setInternet(!internet)} />
                            </DetailsContainer>
                            <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFacilities')}</ItemTitle>
                            <DetailsContainer>
                                <Checkbox label='BBQ' checked={bBQ} onClickCheckbox={() => setBBQ(!bBQ)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.CoveredParkingLabel')} checked={coveredParking} onClickCheckbox={() => setCoveredParking(!coveredParking)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.GymnasiumLabel')} checked={gymnasium} onClickCheckbox={() => setGymnasium(!gymnasium)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BasketballCourtLabel')} checked={basketballCourt} onClickCheckbox={() => setBasketballCourt(!basketballCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.RestaurantLabel')} checked={restaurant} onClickCheckbox={() => setRestaurant(!restaurant)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner2} onClickCheckbox={() => setAirConditioner2(!airConditioner2)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.DobiLabel')} checked={dobiLaundromat} onClickCheckbox={() => setDobiLaundromat(!dobiLaundromat)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.NurseryLabel')} checked={nursery} onClickCheckbox={() => setNursery(!nursery)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.PlaygroundLabel')} checked={playground} onClickCheckbox={() => setPlayground(!playground)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SaunaLabel')} checked={sauna} onClickCheckbox={() => setSauna(!sauna)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SwimmingPoolLabel')} checked={swimmingPool} onClickCheckbox={() => setSwimmingPool(!swimmingPool)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.TennisCourtLabel')} checked={tennisCourt} onClickCheckbox={() => setTennisCourt(!tennisCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SecurityLabel')} checked={security24hour} onClickCheckbox={() => setSecurity24hour(!security24hour)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MiniMartLabel')} checked={miniMart} onClickCheckbox={() => setMiniMart(!miniMart)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SquashCourtLabel')} checked={squashCourt} onClickCheckbox={() => setSquashCourt(!squashCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BadmintonCourtLabel')} checked={badmintonCourt} onClickCheckbox={() => setBadmintonCourt(!badmintonCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.ElevatorLabel')} checked={elevator} onClickCheckbox={() => setElevator(!elevator)} />
                            </DetailsContainer>
                        </>
                        <SubmitButton>
                            <Button type='button' loading={editPropertyDetailsAttempt} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={onSubmitConfirmAmenitiesHandler} />
                            <Button type='button' loading={editPropertyDetailsAttempt} secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onSubmitCancelAmenitiesHandler} />
                        </SubmitButton>
                    </SectionContainer>
                </CardCollapsible>
                <PropertyImages propertyId={newPropertyId} />
                <CardCollapsible ref={descriptionRef} preText={Translate.t('CreateNew.CreateNewStep5')} title='property Description' icon={descriptionStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={descriptionStatus}>
                    <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                        <DescriptionRoundInput value={descriptionText} onChangeText={onChangeDescription} />
                        <SubmitButton>
                            <Button type='button' loading={editPropertyDetailsAttempt} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={onSubmitDescriptionConfirmHandler} />
                            <Button type='button' loading={editPropertyDetailsAttempt} secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={() => setDescriptionText('')} />
                        </SubmitButton>
                    </SectionContainer>
                </CardCollapsible>

                <CardCollapsible ref={attachPropRef} preText={Translate.t('CreateNew.CreateNewStep6')} title={Translate.t('CreateNew.CreateNewAttachProp')} icon={attachPropStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={attachPropStatus}>
                    <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                        {!gotOwner && (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <SearchInput value={searchedProp} onChangeText={onChangeSearchedProp} placeholder='Search Owner' />
                                <Button type='button' label='Create Owner' onClick={() => setCreateOwnerModal(true)} />
                            </div>
                        )}
                        <div>
                            {renderAttachOwner()}
                        </div>
                        <SubmitButton>
                            <Button type='button' label={Translate.t('CreateNew.CreateNewBtnConfirm')} loading={loadingAttachOwner} onClick={onSubmitAttachOwnerToProperty} />
                            <Button type='button' secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onClearAttachPropAndSetNewSearch} />
                        </SubmitButton>
                    </SectionContainer>
                </CardCollapsible>

                <SubmitButton>
                    <Button label={Translate.t('CreateNew.CreateNewBtnSubmit')} onClick={() => NavActions.navToHome()} />
                </SubmitButton>
                <CreateOwnerModal />
            </SetupContainer>
        );
    };

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Desc>{Translate.t('CreateNew.CreateNewBcDesc')}</Desc>
                <OwnerContentContainer>
                    {renderCardPropertyDetails()}
                </OwnerContentContainer>

                <Modal
                    show={showModal}
                    closeCursor
                    onClickClose={() => {
                        setTempProp(null);
                        setShowModal(false);
                    }}
                    width='600px'

                >
                    <ModalContent>
                        {
                            tempProp && (
                                <>
                                    <p>
                                        {Translate.t('CreateNew.CreateNewModalText1')}
                                        {tempProp.name || 'owner'}

                                        {Translate.t('CreateNew.CreateNewModalText2')}
                                    </p>
                                    <SubmitButton>
                                        <Button
                                            type='button'
                                            label={Translate.t('CreateNew.CreateNewModalBtnYes')}
                                            onClick={() => {
                                                onClickProperty(tempProp.userId);
                                                setShowModal(false);
                                            }}
                                        />
                                        <Button
                                            type='button'
                                            secondary
                                            label={Translate.t('CreateNew.CreateNewModalBtnNo')}
                                            onClick={() => {
                                                setTempProp(null);
                                                setShowModal(false);
                                            }}
                                        />
                                    </SubmitButton>
                                </>
                            )
                        }

                    </ModalContent>
                </Modal>
            </MainContainer>
        </>
    );
};

const ItemTitle = styled.h5`
   font-weight: bold;
   font-size: 14px;
   line-height: 16px;
   margin-top: 5px;
   margin-bottom: 16px;
   > li:not(:first-child){
    margin-top: 16px;
   } 
`;
const OwnerContentContainer = styled.div`
    display: flex;
    margin: 0 20px;
    color: #000;
`;

const SetupContainer = styled.div`
    width: 824px;
    margin-right: 16px;

    > div {
        margin-bottom: 16px;
    }
`;

const SectionTitle = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const SideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 564px;
`;

const SubmitButton = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;

    > button {
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
    }

    > button:not(:last-child){
        margin-right: 10px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 16px;
    color: #000;
    margin: 40px 20px 20px 20px;
`;

const PropertyCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;

    > div {
        margin-top: 20px;
    }

    > div:not(:last-child){
        margin-right: 16px;
    }
`;

const StatusContainer = styled.div`
    display: flex;
    height: 200px;
    width: 100%;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    z-index: 2;

    div {
        margin: 0 auto;
    }
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin-top: 30px;
        margin-bottom: 15px;
        text-align: center;
    }
`;

const mapStateToProps = (state: RootState) => ({
    getSearchedPropertyLoading: Selectors.getPropertyGetSearchedOwnerAttempting(state),
    getSearchedPropertyError: Selectors.getPropertyGetSearchedOnerError(state),
    searchedProperty: Selectors.getPropertySearchedOwner(state),
    loadingDescription: Selectors.setPropertySetPropertySetDescriptionAttempting(state),
    loadingDetails: Selectors.setPropertySetPropertySetPropertyDetailsAttempting(state),
    loadingAmenities: Selectors.setPropertySetPropertySetPropertyAmenitiesAttempting(state),
    loadingAttachOwner: Selectors.setPropertySetPropertySetPropertyUserAttempting(state),
    loadingLocation: Selectors.setPropertySetPropertySetPropertyLocationAttempting(state),
    coordinates: Selectors.getPropertyGetCoordinates(state),
    address1st: Selectors.getPropertyGetAddress1(state),
    address2nd: Selectors.getPropertyGetAddress2(state),
    poscodes: Selectors.getPropertyGetPoscode(state),
    states: Selectors.getPropertyGetState(state),
    countryLocation: Selectors.getPropertyGetCountry(state),
    cityLocation: Selectors.getPropertyGetCity(state),
    isImageUploadComplete: Selectors.setPropertysetIsImageUploadComplete(state),
    propertyDetailsLoading: Selectors.getPropertyGetPropertyDetailsAttempting(state),
    propertyDetailsData: Selectors.getPropertyGetPropertyDetails(state),
    propertyDetailsSuccess: Selectors.setPropertysIsPropertyDataLoaded(state),
    getOwnerDetailsLoading: Selectors.getOwnersGetOwnerDetailsAttempting(state),
    getOwnerDetailsError: Selectors.getOwnersGetOwnerDetailsError(state),
    ownerDetails: Selectors.getOwnersGetOwnerDetails(state),
    showErrorMessage: Selectors.setPropertySetShowErrorMessage(state),

    editPropertyDetailsAttempt: Selectors.getPropertyEditPropertyDetailsAttempting(state),
    // createOwnerModalIsOpen: Selectors.getUiCreateOwnerModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getSearchedProperty: (search: string) => dispatch(Actions.getSearchedOwnerAttempt(search)),
    clearSearchedProperty: () => dispatch(Actions.clearSearchedProperty()),
    setDescription: (id: string, description: string) =>
        dispatch(Actions.propertySetDescriptionAttempt({ id, description })),
    setDetails: (data: IPropertyDetails) =>
        dispatch(Actions.propertySetPropertyDetailsAttempt(data)),
    setAmenities: (data: IPropertyAmenities) =>
        dispatch(Actions.propertySetPropertyAmenitiesAttempt(data)),
    setPropertyUser: (propertyId: string, userId: string) =>
        dispatch(Actions.propertySetUserAttempt({ propertyId, userId })),
    setLocation: (data: IPropertyLocation) =>
        dispatch(Actions.propertySetLocationAttempt(data)),
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) =>
        dispatch(Actions.getPropertyUploadUrlAttempt({ category, id, name, extension, document })),
    setDeleteImages: (category: number, id: string, name: string, fileName: string, fileUrl: string) =>
        dispatch(Actions.propertySetDeleteImageAttempt({ category, id, name, fileName, fileUrl })),
    getPropertyDetails: (id: string) =>
        dispatch(Actions.getPropertPropertyDetailsAttempt({ id })),
    setIsPropertyDataLoaded: (state: boolean) => dispatch(Actions.propertyCreatePropertyModal(state)),
    getOwnerDetails: (userId: string) => dispatch(Actions.getOwnerDetailsAttempt(userId)),
    clearOwnerDetails: () => dispatch(Actions.clearOwnerDetails()),
    clearpropertyDetails: () => dispatch(Actions.clearPropertyDetailsData()),
    setShowErrorMessage: (state: boolean) => dispatch(Actions.setShowErrorMessage(state)),

    editPropertyDetails: (data: IEditPropertyDetails) => dispatch(Actions.editPropertyDetailsAttempt(data)),
    setCreateOwnerModal: (state: boolean) => dispatch(Actions.setCreateOwnerModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProperty);
