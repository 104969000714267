import { Colors } from '@dm/bigfish';
import { RaceEnum } from '@dm/types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface IOption {
  id: string | number,
  description: string | number,
}

interface SelectionInputProps {
  options: IOption[];
  label: string;
  currentValue: number[];
  disabled?: boolean;
  required?: boolean;
  onChange: (selectedOptions: number[]) => void;
}

const SelectionInputMulti = ({ options, label, onChange, currentValue, disabled, required }: SelectionInputProps): JSX.Element => {
    const [selectedOptions, setSelectedOptions] = useState<number[]>(currentValue);

    const handleOptionSelect = (selected: number) => {
        if (selectedOptions.includes(selected)) {
            setSelectedOptions(selectedOptions.filter((option) => option !== selected));
        } else {
            setSelectedOptions([...selectedOptions, selected]);
        }
    };

    useEffect(() => {
        onChange(selectedOptions);
    }, [selectedOptions, currentValue]);

    return (
        <FormGroup>
            <Label>{label}</Label>
            <OptionContainer>
                {options.map((item) => {
                    return (
                        <Options
                            key={item.id}
                            checked={selectedOptions.includes(item.id as number)}
                            onClick={() => handleOptionSelect(Number(item.id))}
                        >
                            <p>{item.description}</p>
                        </Options>
                    );
                })}
            </OptionContainer>
        </FormGroup>
    );
};

SelectionInputMulti.defaultProps = {
    disabled: false,
    required: false,
};

const FormGroup = styled.div`
    margin: 0px 0;
    color: #000;
    overflow: hidden;
`;

const Label = styled.label`
    display: block;
    padding: '2px 5px';

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    text-transform: capitalize;
`;
const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const Options = styled.div<{ checked: boolean }>`
    font-size: 13px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    margin: 7px 7px 0 0;
    background-color: ${(props) => (props.checked ? Colors.primaryLight : Colors.primaryLightest)};
    border-radius: 12px;
    padding: 6px;
    cursor: pointer;
`;

const HiddenInput = styled.input`
    display: none;
`;

export default SelectionInputMulti;
