import { put, call, takeEvery } from 'typed-redux-saga/macro';

import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import Utils from 'lib/Utils';
import Translate from 'lib/translate';
import Actions from 'redux/Actions';
import { IGetTransactionsReportExportPayload } from 'redux/slices/payment/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

export default function* watchGetTransactionsReportExport(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('payment/getTransactionsReportExportAttempt', handleGetTransactionsReportExport, api);
}

function* handleGetTransactionsReportExport(api: PackagesGateway, data: PayloadAction<IGetTransactionsReportExportPayload>) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId, name, unitNo } = data.payload;

        const response = yield* call([api, api.getTransactionsReportExport], {
            authToken,
            propertyId,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesGetIPropertyManagementDataFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            const { data: transactionsReport } = response;
            let totalAmount = 0;
            const massagedTransactionsReport = transactionsReport.map((item) => {
                const {
                    transactionId,
                    description,
                    createdAt,
                    status,
                    amount,
                    ...restOfTransactionReport
                } = item;
                if (status === 'Completed') {
                    if (amount) {
                        totalAmount = amount as number + totalAmount;
                    }
                    return {
                        'Transaction ID': transactionId,
                        'Transaction Description': description,
                        Date: dayjs(createdAt as string).format('DD/MM/YYYY'),
                        Status: status,
                        Amount: amount,
                        // Amount: `RM ${amount}`,
                        ...restOfTransactionReport,
                    };
                }
                return {};
            });
            // empty line object
            const breakLine = {
                'Transaction ID': '',
                'Transaction Description': '',
                Date: '',
                Status: '',
                Amount: '',
            };
            // title object
            const csvTitle = {
                'Transaction ID': name,
                'Transaction Description': '',
                Date: '',
                Status: '',
                Amount: '',
            };
            // header object
            const tableHeader = {
                'Transaction ID': 'Transaction ID',
                'Transaction Description': 'Description',
                Date: 'Date',
                Status: 'Status',
                Amount: 'Amount (RM)',
            };
            // total amount object
            const totalAmountData = {
                'Transaction ID': '',
                'Transaction Description': '',
                Date: '',
                Status: 'Total',
                Amount: totalAmount,
                // Amount: `RM ${totalAmount}`,
            };
            // pushing object to the start of the array
            massagedTransactionsReport.unshift(tableHeader);
            massagedTransactionsReport.unshift(breakLine);
            massagedTransactionsReport.unshift(csvTitle);
            // pushin object to the end of the array
            massagedTransactionsReport.push(breakLine);
            massagedTransactionsReport.push(totalAmountData);

            const unparsedTransactionsReportExport = Papa.unparse(massagedTransactionsReport, {
                header: false, // this is for disabling the table header to add custom header
                skipEmptyLines: true, // skip empty line for rejected trnasactions
            });
            const blob = new Blob([unparsedTransactionsReportExport]);

            if (unparsedTransactionsReportExport) {
                if (unitNo.length > 0) {
                    FileSaver.saveAs(blob, `${name}(${unitNo}) transactions (${dayjs().format('DD-MM-YYYY')}).csv`);
                } else {
                    FileSaver.saveAs(blob, `${name} transactions (${dayjs().format('DD-MM-YYYY')}).csv`);
                }
            } else {
                toast.error('No transactions for this property');
            }

            yield put(Actions.getTransactionsReportExportSuccess());
        } else {
        // handle error
            yield put(Actions.getTransactionsReportExportFailure());
            toast.error('No transactions for this property');
        }
    }
}
