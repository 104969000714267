import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors, Steps } from '@dm/bigfish';
import { IProgressLogs, IProperty, IPropertyDefectInspectionStage, PackageCategory } from '@dm/types';

import Modal from 'components/Modal';
import media from 'lib/Breakpoints';
import dayjs from 'dayjs';
import { UpdateProgressDefectInspectionParams } from 'api/PackagesBase';
import { useParams } from 'react-router-dom';

interface SetMilestonesModalProps {
    isSetMilestonesModalOpen: boolean;
    setMilestonesModalOpen: (state: boolean) => void;
    updateProgressAttempt: boolean;
    updateProgressError: string;
    updateProgress: (data: Omit<UpdateProgressDefectInspectionParams, 'authToken'>) => void;
    currentStep: IPropertyDefectInspectionStage;
    progressLogs: IProgressLogs[];
}

const SetMilestonesModal: FunctionComponent<SetMilestonesModalProps> = (props: SetMilestonesModalProps) => {
    const {
        isSetMilestonesModalOpen,
        setMilestonesModalOpen,
        updateProgressAttempt,
        updateProgressError,
        updateProgress,
        currentStep,
        progressLogs,
    } = props;

    const params = useParams();
    const { id } = params;

    const [progressLogData, setProgressLogData] = useState<IProgressLogs[]>([]);

    const [currentMilestone, setCurrentMilestone] = useState<IPropertyDefectInspectionStage>(IPropertyDefectInspectionStage.PurchasedPackage);
    const [remarks, setRemarks] = useState('');

    const [error, setError] = useState<string>('');

    useEffect(() => {
        setProgressLogData(progressLogs);
        setCurrentMilestone(currentStep);
    }, [progressLogs, currentStep]);

    const stepsData = [
        {
            text: 'Package Purchased',
            status: currentStep >= IPropertyDefectInspectionStage.PurchasedPackage,
        },
        {
            text: 'In Progress',
            status: currentStep >= IPropertyDefectInspectionStage.InProgress,
        },
        {
            text: 'Submitted to management',
            status: currentStep >= IPropertyDefectInspectionStage.SubmittedReportToManagement,
        },
        {
            text: 'Verifying report',
            status: currentStep >= IPropertyDefectInspectionStage.ManagementVerifyingReport,
        },
        {
            text: 'Completed',
            status: currentStep >= IPropertyDefectInspectionStage.Completed,
        },
    ];

    const cancelClickHandler = () => {
        setMilestonesModalOpen(false);
    };

    const confirmClickHandler = () => {
        if (!remarks) {
            setError('Please enter remarks');
            return;
        }
        if (id) {
            updateProgress({
                propertyId: id,
                stage: currentMilestone,
                remark: remarks,
                date: dayjs(new Date()).toISOString(),
            });
        }
    };

    const renderProgressLog = () => {
        return progressLogData.map((item, index) => {
            return (
                <React.Fragment key={item.remark}>
                    <LogContainer>
                        <div id='date'>
                            {dayjs(item.logDate).format('DD-MM-YYYY hh:mm A')}
                        </div>
                        <div>
                            {item.remark}
                        </div>
                    </LogContainer>
                    {index !== progressLogData.length - 1 && <hr />}
                </React.Fragment>
            );
        });
    };

    const renderModalBody = () => {
        if (updateProgressAttempt) {
            return (
                <ModalBody>
                    <Oval
                        height={100}
                        width={100}
                        color={Colors.primary}
                        secondaryColor={Colors.white}
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                    />
                </ModalBody>
            );
        }

        if (updateProgressError) {
            return (
                <ModalBody>
                    {updateProgressError}
                </ModalBody>
            );
        }

        return (
            <ModalBody>
                <Steps data={stepsData} />
                <ProgressLogContainer>
                    <div id='title'>
                        Progress logs
                    </div>
                    <div id='content'>
                        {renderProgressLog()}
                    </div>
                </ProgressLogContainer>
                <InputContainer>
                    <InputContainerItem style={{ flex: '1 0 50%' }}>
                        <div>Remarks</div>
                        <input
                            id='remarks'
                            value={remarks}
                            onChange={e => setRemarks(e.target.value)}
                            placeholder='Enter remarks here'
                            style={{
                                marginTop: '5px',
                                padding: '5px 10px',
                            }}
                        />
                    </InputContainerItem>
                    <InputContainerItem>
                        <div>Stage</div>
                        <select
                            value={currentMilestone}
                            onChange={(e) => setCurrentMilestone(Number(e.target.value) as IPropertyDefectInspectionStage)}
                            style={{
                                marginTop: '5px',
                                padding: '5px',
                            }}
                        >
                            <option value={IPropertyDefectInspectionStage.PurchasedPackage}>Package Purchased</option>
                            <option value={IPropertyDefectInspectionStage.InProgress}>In Progress</option>
                            <option value={IPropertyDefectInspectionStage.SubmittedReportToManagement}>Submitted to management</option>
                            <option value={IPropertyDefectInspectionStage.ManagementVerifyingReport}>Verifying report</option>
                            <option value={IPropertyDefectInspectionStage.Completed}>Completed</option>
                        </select>
                    </InputContainerItem>
                </InputContainer>
            </ModalBody>
        );
    };

    const renderModalFooter = () => {
        return (
            <>
                <WarningText>
                    { error || ''}
                </WarningText>
                <div>
                    <Button
                        label='Cancel'
                        style={{
                            marginRight: '10px',
                            backgroundColor: Colors.white,
                            color: Colors.primary,
                            border: `1px solid ${Colors.primary}`,
                        }}
                        onClick={cancelClickHandler}
                        disabled={updateProgressAttempt}
                    />
                    <Button
                        label='Update'
                        style={{ width: '100px' }}
                        onClick={confirmClickHandler}
                        loading={updateProgressAttempt}
                        disabled={updateProgressAttempt}
                    />
                </div>
            </>

        );
    };

    return (
        <Modal
            show={isSetMilestonesModalOpen}
            width='50%'
            height='fit-content'
        >
            <ModalHeader>
                Set Milestones
            </ModalHeader>

            {renderModalBody()}

            <ModalFooter>
                {renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ModalBody = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    justify-content: center;
    align-items: center;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
`;

const WarningText = styled.div`
    color: ${Colors.danger};
    font-weight: bold;
    font-style: italic;
    align-items: center;
    display: flex;
`;

const ProgressLogContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    #title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 10px 20px;
        background-color: ${Colors.primaryLight};
    }

    #content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: ${Colors.primaryLightest};
        padding: 10px 20px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        max-height: 300px;
        overflow-y: auto;
    }
`;

const LogContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    #date {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        color: ${Colors.greyDark};
    }

    ${media.sm`
        flex-direction: column;
        gap: 5px;
    `}
`;

const InputContainer = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    border-radius: 12px;
    padding: 20px 40px;
    background-color: ${Colors.primaryLightest};
`;

const InputContainerItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const mapStateToProps = (state: RootState) => ({
    isSetMilestonesModalOpen: Selectors.getPropertyGetMilestoneModalOpen(state),
    updateProgressAttempt: Selectors.packagesGetUpdateProgressDefectInspectionAttempt(state),
    updateProgressError: Selectors.packagesGetUpdateProgressDefectInspectionFailure(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setMilestonesModalOpen: (state: boolean) => dispatch(Actions.setMilestonesModalOpen(state)),
    updateProgress: (data: Omit<UpdateProgressDefectInspectionParams, 'authToken'>) => dispatch(Actions.packagesUpdateProgressDefectInspectionAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetMilestonesModal);
