import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { Oval } from 'react-loader-spinner';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BreadCrumb, Colors, Fonts, TabView } from '@dm/bigfish';
import { PropertyManagementTransactionResponse, PropertyManagementTransactionStatus, SortByEnum } from '@dm/types';

import NavActions from 'lib/NavActions';
import MainContainer from 'components/MainContainer';
import InlineSelectionInput from 'components/InlineSelectionInputViewDocument';
import Icons from '../../../assets/icons';
import Modal from './Modal';

interface PropertyManagementViewTransactionProps {
    loadingPropertyManagementDocumentData: boolean;
    errorPropertyManagementDocumentData: string;
    propertyManagementDocumentData: PropertyManagementTransactionResponse[] | null;

    packagesDataSort (propertyId: string, sort: number): void;
}

interface NavParams {
    propertyIndividualId: string;
}

const PropertyManagementViewTransaction: FunctionComponent<PropertyManagementViewTransactionProps> = (props: PropertyManagementViewTransactionProps) => {
    const {
        loadingPropertyManagementDocumentData,
        errorPropertyManagementDocumentData,
        propertyManagementDocumentData,

        packagesDataSort,
    } = props;

    const [dateOrder, setDateOrder] = useState<SortByEnum>(SortByEnum.createdAtDesc);
    const [homeScreenTabView, setHomeScreenTabView] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [modalDocumentUrl, setModalDocumentUrl] = useState('');

    const useLocationstate = useLocation();
    const { propertyIndividualId } = useLocationstate.state as NavParams;

    const onClickViewDocument = (docUrl: string) => {
        if (docUrl) {
            setShowModal(true);
            setModalDocumentUrl(docUrl);
        }
    };

    const [tabViewData] = useState([
        {
            text: Translate.t('GeneralUI.all'),
            onClickTabView: () => {
                setHomeScreenTabView(Translate.t('GeneralUI.all'));
            },
        },
    ]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('GeneralUI.BreadcrumblabelViewDocuments'),
                onClick: () => { return false; },
            },
        ],
    );

    interface IOption {
        id: string | number,
      description: string | number,
    }

    const dateData: IOption[] = [{
        id: 0,
        description: Translate.t('Packages.ViewDocuments.DateDecending'),
    },
    {
        id: 1,
        description: Translate.t('Packages.ViewDocuments.DateAscending'),
    }];

    const alphabeticalOrder: IOption[] = [
        {
            id: 2,
            description: Translate.t('Packages.ViewDocuments.NameAscendingOrder'),
        }, {
            id: 3,
            description: Translate.t('Packages.ViewDocuments.NameDecendingOrder'),
        },
    ];

    const listHeaders: string[] = ['ID', 'Amount', 'Description', 'Status', 'View'];

    useEffect(() => {
        packagesDataSort(propertyIndividualId, dateOrder);
    }, []);

    useEffect(() => {
        packagesDataSort(propertyIndividualId, dateOrder);
        setHomeScreenTabView(Translate.t('GeneralUI.all'));
    }, [dateOrder]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const checkPropertyPackagesStatus = (packages: number) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case PropertyManagementTransactionStatus.Pending:
                return (Translate.t('Packages.PropertyManagement.StatusPending'));

            case PropertyManagementTransactionStatus.Accepted:
                return (Translate.t('Packages.PropertyManagement.StatusAccepted'));

            case PropertyManagementTransactionStatus.Rejected:
                return (Translate.t('Packages.PropertyManagement.StatusRejected'));

            default:
                return (Translate.t('Packages.PropertyManagement.StatusNoStatus'));
        }
    };

    const renderList = () => {
        if (loadingPropertyManagementDocumentData) {
            return (
                <Loading>
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </Loading>
            );
        }

        if (errorPropertyManagementDocumentData.length) {
            return (
                <ErrorMEssageContainer>
                    {Translate.t('Packages.ViewDocuments.NoData')}
                </ErrorMEssageContainer>
            );
        }

        if (propertyManagementDocumentData?.length) {
            return (
                <FiltersContainer>
                    {propertyManagementDocumentData.map((item) => {
                        const { transactionsId, amount, description, status, fileUrl } = item;
                        return (
                            <HeadersContainer>

                                <H4TitleID>{transactionsId}</H4TitleID>
                                <H4Title>{amount}</H4Title>
                                <H4Title>{description}</H4Title>
                                <H4Title>
                                    {checkPropertyPackagesStatus(status)}
                                </H4Title>

                                {(fileUrl.length > 0) && (
                                    <StyledIconButton onClick={() => onClickViewDocument(fileUrl[0].url)}>
                                        <SearchIcon src={Icons.SearchIcon} />
                                    </StyledIconButton>
                                )}

                            </HeadersContainer>
                        );
                    })}
                </FiltersContainer>
            );
        }

        return true;
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Packages.ViewDocuments.Back')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <InfoContainerMain>
                    <LeftContainer>
                        <InfoContainer>
                            <TabViewContainer>
                                <TabView data={tabViewData} selectedTabView={homeScreenTabView} />
                            </TabViewContainer>
                            <TitleMessageContainer>{Translate.t('Packages.ViewDocuments.TitleMessage')}</TitleMessageContainer>
                            <FiltersContainer>

                                <FilterTitleBold>{Translate.t('Packages.PropertyManagement.PropertyManagementTransactionTitle')}</FilterTitleBold>

                                <FiltersInputContainer>
                                    <InlineSelectionInput
                                        data={dateData}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}
                                    />
                                    <InlineSelectionInput
                                        data={alphabeticalOrder}
                                        defaultValue={SortByEnum.updatedAtDesc.toString()}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}
                                    />
                                </FiltersInputContainer>
                            </FiltersContainer>
                            <ListContainer>
                                <HeadersContainer>
                                    {listHeaders.map(item => {
                                        return (
                                            <HeaderText
                                                key={item}
                                            >
                                                {item}
                                            </HeaderText>
                                        );
                                    })}
                                </HeadersContainer>
                                {renderList()}
                            </ListContainer>
                        </InfoContainer>
                    </LeftContainer>

                </InfoContainerMain>

            </MainContainer>

            <Modal
                show={showModal}
                closeCursor
                onClickClose={() => {
                    setShowModal(false);
                }}
            >
                <ModalContent>
                    <CarouselPhoto src={modalDocumentUrl} title='pdf' />
                </ModalContent>
            </Modal>

        </>
    );
};

const CarouselPhoto = styled.iframe`
   width: 100%;
   height: 100%;
   object-fit: cover;
`;

const ModalContent = styled.div`
    width: 100%;
    height: 800px;
    padding: 40px 10px 10px 10px;
    @media (max-width: 1624px) {
        height: 100%;
    }
`;

const StyledIconButton = styled.button`
   display: inline-block;
   border: none;
   background: transparent;
   cursor: pointer;
   transition: all .2s;

    &:active {
        transform: translateY(-3px);
    }
`;

const SearchIcon = styled(SVG)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const H4Title = styled.h4`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
`;

const H4TitleID = styled.h4`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FilterTitleBold = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
`;

const TitleMessageContainer = styled.p`
    margin-bottom: 40px;
`;

const HeaderText = styled.h4`
    justify-self: center;
`;

const InfoContainerMain = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

const LeftContainer = styled.div`
    display: flex;
    width: 70%;
    height: 100%;
`;

const Loading = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

`;

const InfoContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
`;

const TabViewContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const HeadersContainer = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 20% 20% 20% 20% 20% ;
    font-family: ${Fonts.primary};
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 20px 40px 10px 40px;
`;

const ErrorMEssageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 40px 10px 40px;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const FiltersContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const FiltersInputContainer = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const ListContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    height: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    loadingPropertyManagementDocumentData: Selectors.getPackagesIPropertyManagementDataDetailsSortAttempt(state),
    errorPropertyManagementDocumentData: Selectors.getPackagesIPropertyManagementDataDetailsSortFailure(state),
    propertyManagementDocumentData: Selectors.getIPropertyManagementDataDetailsSort(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    packagesDataSort: (propertyId: string, sort: number) =>
        dispatch(Actions.packagesGetIPropertyManagementSortAttempt({ propertyId, sort })),

});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyManagementViewTransaction);
