import { Button } from '@dm/bigfish';
import { IViewAllRecurringPMTransactionResponse, PMTransactionRecurringFrequencyTypeEnum, PMTransactionTypeEnum } from '@dm/types';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import styled from 'styled-components';

type ComponentProps = {
    selectedRecurring: IViewAllRecurringPMTransactionResponse | null;

    modalIsOpen: boolean;
    setModalIsOpen: (state: boolean) => void;

    editRecurringTransactionAttempt: boolean;
    editRecurringTransactionError: string
    setEditRecurringTransaction: (data: FormSchema) => void;
}

type FormSchema = {
    id: string;
    amount: number;
    description: string;
    transactionType: PMTransactionTypeEnum;
    startDate: string;
    endDate: string;
    interval: number;
    frequency: PMTransactionRecurringFrequencyTypeEnum;
}

const EditRecurringTransactionModal = (props: ComponentProps): JSX.Element => {
    const {
        selectedRecurring,

        modalIsOpen,
        setModalIsOpen,

        editRecurringTransactionAttempt,
        editRecurringTransactionError,
        setEditRecurringTransaction,
    } = props;

    const [isChanged, setIsChanged] = useState(false);

    const [formData, setFormData] = useState<FormSchema>({
        id: '',
        amount: 0,
        description: '',
        transactionType: PMTransactionTypeEnum.transaction,
        startDate: '',
        endDate: '',
        interval: 0,
        frequency: PMTransactionRecurringFrequencyTypeEnum.monthly,
    });

    useEffect(() => {
        if (selectedRecurring) {
            setFormData({
                id: selectedRecurring._id,
                amount: selectedRecurring.amount,
                description: selectedRecurring.description,
                transactionType: selectedRecurring.transactionType,
                startDate: dayjs(selectedRecurring.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(selectedRecurring.endDate).format('YYYY-MM-DD'),
                interval: selectedRecurring.interval,
                frequency: selectedRecurring.frequency,
            });
        }
    }, [selectedRecurring, modalIsOpen]);

    useEffect(() => {
        if (formData.amount !== selectedRecurring?.amount
            || formData.description !== selectedRecurring?.description
            || formData.transactionType !== selectedRecurring?.transactionType
            || formData.startDate !== dayjs(selectedRecurring?.startDate).format('YYYY-MM-DD')
            || formData.endDate !== dayjs(selectedRecurring?.endDate).format('YYYY-MM-DD')
            || formData.interval !== selectedRecurring?.interval
            || formData.frequency !== selectedRecurring?.frequency
        ) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [formData]);

    const renderInput = () => {
        return Object.keys(formData).map((key) => {
            if (key === 'amount' || key === 'description') {
                const value = formData[key as keyof FormSchema];
                return (
                    <ModalSubContent key={key}>
                        <Text>{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
                        <StyledInput
                            value={value}
                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                        />
                    </ModalSubContent>
                );
            }

            if (key === 'interval') {
                const value = formData[key as keyof FormSchema];
                return (
                    <ModalSubContent key={key}>
                        <Text>{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
                        <StyledInput
                            type='number'
                            value={value}
                            onChange={(e) => setFormData({ ...formData, [key]: Number(e.target.value) })}
                        />
                    </ModalSubContent>
                );
            }

            if (key === 'frequency') {
                const value = formData[key as keyof FormSchema];
                return (
                    <ModalSubContent key={key}>
                        <Text>{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
                        <select
                            name='frequency'
                            value={value}
                            onChange={(e) => setFormData({ ...formData, [key]: Number(e.target.value) })}
                            style={{ padding: '0 15px' }}
                        >
                            <option value={PMTransactionRecurringFrequencyTypeEnum.daily}>Daily</option>
                            <option value={PMTransactionRecurringFrequencyTypeEnum.weekly}>Weekly</option>
                            <option value={PMTransactionRecurringFrequencyTypeEnum.monthly}>Monthly</option>
                            <option value={PMTransactionRecurringFrequencyTypeEnum.yearly}>Yearly</option>
                        </select>
                    </ModalSubContent>
                );
            }

            if (key === 'startDate' || key === 'endDate') {
                const value = formData[key as keyof FormSchema];
                return (
                    <ModalSubContent key={key}>
                        <Text>{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
                        <StyledDatePicker
                            value={dayjs(value).format('DD/MM/YYYY')}
                            onChange={(e: Date) => setFormData({ ...formData, [key]: dayjs(e).format('YYYY-MM-DD') })}
                            minDate={key === 'startDate' ? new Date() : dayjs(value).toDate()}
                            maxDate={null}
                        />
                    </ModalSubContent>
                );
            }
            return null;
        });
    };

    return (
        <Modal
            height='fit-content'
            width='700px'
            show={modalIsOpen}
            closeCursor
            onClickClose={() => setModalIsOpen(!modalIsOpen)}
        >
            <ModalContainer>
                <Title>Edit Recurring Transaction</Title>
                <ModalContent>
                    {renderInput()}
                </ModalContent>
                <ModalFooter>
                    <Text style={{ color: 'red', marginTop: '10px' }}>
                        {isChanged ? 'click confirm to save changes!' : ''}
                    </Text>
                    <Button
                        label='Confirm'
                        disabled={editRecurringTransactionAttempt}
                        loading={editRecurringTransactionAttempt}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setEditRecurringTransaction(formData)}
                    />
                </ModalFooter>
            </ModalContainer>
        </Modal>
    );
};
// ! styled-components
const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
`;
const Text = styled.p`
    font-size: 16px;
    display: flex;
    align-items: center;
`;
const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const ModalContent = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: 20px;
    justify-content: center;

    padding: 20px;
`;
const ModalSubContent = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;

`;
const StyledInput = styled.input`
    outline: none;
    height: 15px;
    margin: 5px 0;
    padding: 15px;

    width: ${props => props.width}
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 100%;
    height: 40px;
    padding-left: 15px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: left;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    &:focus {
        outline: none;
    }
    .react-datepicker__tab-loop{
        position: absolute;
    }

    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    }
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

const ModalFooter = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const mapStateToProps = (state: RootState) => ({
    modalIsOpen: Selectors.getUiEditRecurringTransactionModal(state),

    editRecurringTransactionAttempt: Selectors.setPackagesEditRecurringTransactionAttempt(state),
    editRecurringTransactionError: Selectors.setPackagesEditRecurringTransactionFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.setEditRecurringTransactionModal(state)),
    setEditRecurringTransaction: (data: FormSchema) => dispatch(Actions.packagesEditRecurringTransactionAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRecurringTransactionModal);
