import React, { CSSProperties, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '@dm/bigfish';

interface IBoxInput {
  placeHolder?: string;
  maxLength?: number;
  required?: boolean;
  countNumber?: number;
  onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  currentText: string;
}

const BoxInput = ({ placeHolder, maxLength, required, countNumber, currentText, onChangeText }:IBoxInput): JSX.Element => {
    return (
        <BoxInputContainer>
            <TextArea placeholder={placeHolder} value={currentText} maxLength={maxLength} onChange={onChangeText} required={required} />
            {
                (countNumber || countNumber === 0) && (
                    <NumberCount>
                        <span>{countNumber}</span>
                        {' '}
                        character remaining
                    </NumberCount>
                )
            }
        </BoxInputContainer>
    );
};

BoxInput.defaultProps = {
    placeHolder: undefined,
    maxLength: undefined,
    required: false,
    countNumber: undefined,
};

const BoxInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TextArea = styled.textarea`
  border: none;
  box-sizing: border-box;
  padding: 20px;
  max-width: 784px;
  height: 180px;
  border-radius: 8px;
  resize: none;

  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  overflow: auto;

  background-color: ${Colors.greyLightest};
  color: ${Colors.greyDark};

  &:focus {
    outline: none;
  }
`;

const NumberCount = styled.p`
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  align-self: flex-end;

  color: ${Colors.greyDark};
`;

export default BoxInput;
