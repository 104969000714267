import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import Translate from 'lib/translate';

import { SetDetails1ActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchSetDetails(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetPropertyDetailsAttempt', handleDetails, api);
}

function* handleDetails(api: PropertyGateway, data: SetDetails1ActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const response = yield* call([api, api.setPropertyDetails], { data: data.payload, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.propertySetPropertyDetailsFailure('Something Wrong'));
            toast.error(Translate.t('GeneralMessage.TryAgain'));
            return;
        }
        yield put(Actions.propertySetPropertyDetailsSuccess(response.data));
        toast.success(Translate.t('Property.Saved'));
    } else {
        // handle error
        yield put(Actions.propertySetPropertyDetailsFailure(response.message));
        toast.error((Translate.t('GeneralMessage.SomethingWrong')));
    }
}
