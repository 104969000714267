import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '@dm/bigfish';

interface IOption{
    id: string | number,
    description: string | number,
}

interface InlineSelectionInputViewDocument {
  data: IOption[];
  currentValue?: string | number;
  onChangeSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  style?: React.CSSProperties;
  defaultValue?: string;
}

const InlineSelectionInputViewDocument = ({ data, defaultValue, currentValue, onChangeSelection, style }: InlineSelectionInputViewDocument): JSX.Element => {
    return (
        <Select style={style} value={currentValue} onChange={onChangeSelection} defaultValue=''>
            {
                data.map((option: IOption, index) => {
                    const uniqueKey = `${option.description}${index}`;
                    return (
                        <option
                            key={uniqueKey}
                            value={option.id}
                        >
                            {option.description}
                        </option>
                    );
                })
            }
        </Select>
    );
};

InlineSelectionInputViewDocument.defaultProps = {
    currentValue: undefined,
    style: undefined,
    defaultValue: undefined,
};

const Select = styled.select<{width?: string}>`
  width: 13.5%;
  background: none;
  color: ${Colors.black};
  margin-right: 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-transform: capitalize;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  &:focus {
    outline: none;
  }
`;

export default InlineSelectionInputViewDocument;
