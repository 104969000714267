import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetTenancyStatusDataActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';
import NavActions from 'lib/NavActions';

export default function* watchSetPropertyTenancyStatus(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/setPropertyTenancyStatusAttempt', handlePropertyTenancyStatus, api);
}

function* handlePropertyTenancyStatus(api: PropertyGateway, data: SetTenancyStatusDataActionPayload) {
    const { propertyId, tenancyStatus } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setPropertyTenancyStatus], { propertyId, tenancyStatus, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.setPropertyTenancyStatusSuccess(response.data));

        toast.success('tenancy status changed');
        yield put(Actions.setVacancyStatusPropertyModalOpen(false));
        NavActions.navToPropertyReports();

        if (!response.data) {
            yield put(Actions.setPropertyTenancyStatusFailure(Translate.t('GeneralMessage.SomethingWrong')));
            toast.error((Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.setPropertyTenancyStatusFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error((Translate.t('GeneralMessage.SomethingWrong')));
            } else {
                toast.error((Translate.t('GeneralMessage.SomethingWrong')));
            }
        }
    }
}
