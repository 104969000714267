import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import SVG from 'react-inlinesvg';
import Modal from 'components/Modal';
import { IOwnerAccount, IOwnerPropertyIds } from 'entities/owners';
import { Button, Colors } from '@dm/bigfish';
import icons from 'assets/icons';
import { toast } from 'react-toastify';

interface ModalProps {
    isOpen: boolean;
    loading: boolean;
    error: string;

    setIsOpen: (state: boolean) => void;
    createNewOwner: (
        propertyIds: IOwnerPropertyIds,
        accCredential: IOwnerAccount,
        ) => void;

}

const CreateOwnerModal = (props: ModalProps):JSX.Element => {
    const {
        isOpen,
        loading,
        error,
        setIsOpen,
        createNewOwner,
    } = props;

    const [ownerEmail, setOwnerEmail] = useState<string>('');
    const [ownerPassword, setOwnerPassword] = useState<string>('');
    const [ownerRepeatPassword, setOwnerRepeatPassword] = useState<string>('');

    const [viewPassword, setViewPassword] = useState<boolean>(false);
    const [viewRepeatPassword, setViewRepeatPassword] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        if (isOpen) {
            setOwnerEmail('');
            setOwnerPassword('');
            setOwnerRepeatPassword('');
            setViewPassword(false);
            setViewRepeatPassword(false);
            setErrorMessage('');
        }
    }, [isOpen]);

    const onCreateHandler = () => {
        if (ownerPassword !== ownerRepeatPassword) {
            toast.error('Passwords do not match');
            return;
        }

        if (!ownerEmail || !ownerPassword || !ownerRepeatPassword) {
            toast.error('All fields are required');
            return;
        }

        createNewOwner([], {
            email: ownerEmail,
            password: ownerPassword,
        });
    };

    return (
        <Modal
            show={isOpen}
            width='500px'
            height='fit-content'
            closeCursor
            onClickClose={() => setIsOpen(false)}
        >
            <ModalHeader>
                Create New Owner
            </ModalHeader>
            <ModalBody>
                <InputContainer>
                    <InputLabel>Email</InputLabel>
                    <Input
                        type='email'
                        value={ownerEmail}
                        onChange={e => setOwnerEmail(e.target.value)}
                        autoComplete='new-email'
                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Password</InputLabel>
                    <InputGroup>
                        <Input
                            type={viewPassword ? 'text' : 'password'}
                            value={ownerPassword}
                            onChange={e => setOwnerPassword(e.target.value)}
                            isPassword
                            autoComplete='new-password'
                        />
                        <StyledIconButton
                            onClick={() => setViewPassword(!viewPassword)}
                        >
                            <Icons src={icons.EyeIcon} width='30px' height='30px' />
                        </StyledIconButton>
                    </InputGroup>
                </InputContainer>
                <InputContainer>
                    <InputLabel>Repeat Password</InputLabel>
                    <InputGroup>
                        <Input
                            type={viewRepeatPassword ? 'text' : 'password'}
                            value={ownerRepeatPassword}
                            onChange={e => setOwnerRepeatPassword(e.target.value)}
                            isPassword
                            autoComplete='new-password'
                        />
                        <StyledIconButton
                            onClick={() => setViewRepeatPassword(!viewRepeatPassword)}
                        >
                            <Icons src={icons.EyeIcon} width='30px' height='30px' />
                        </StyledIconButton>
                    </InputGroup>
                </InputContainer>
            </ModalBody>
            <ModalFooter>
                <Button
                    label='Create'
                    loading={loading}
                    disabled={loading}
                    onClick={onCreateHandler}
                />
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-block: 5px;
`;

const ModalBody = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const InputContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    align-items: center;
`;
const InputLabel = styled.p`
    text-align: center;
    font-size: 16px;
`;

const Input = styled.input<{isPassword?: boolean}>`
    padding: 10px;
    border-radius: 8px;
    border: 1.5px solid ${Colors.grey};
    outline: none;
    width: 100%;

    ${props => props.isPassword && `
        padding-right: 40px;
        border: none;
    `}

`;
const InputGroup = styled.div`
    display: flex;
    border: 1.5px solid ${Colors.grey};
    border-radius: 8px;
    padding-right: 10px;
`;
const StyledIconButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all .2s;
    outline: none;
    user-select: none;
    &:not(:disabled):active {
        transform: translateY(-3px);
    }

    &:disabled{
        cursor: not-allowed;
    }
`;

const Icons = styled(SVG) <{ width: string, height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const mapStateToProps = (state: RootState) => ({
    isOpen: Selectors.getUiCreateOwnerModal(state),

    loading: Selectors.getOwnersCreateNewAttempt(state),
    error: Selectors.getOwnersCreateNewError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsOpen: (state: boolean) => dispatch(Actions.setCreateOwnerModal(state)),

    createNewOwner: (propertyIds: IOwnerPropertyIds, accCredential: IOwnerAccount) =>
        dispatch(Actions.ownersCreateNewAttempt({ propertyIds, accCredential, createdFromProperty: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOwnerModal);
