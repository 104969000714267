import CarelineIcon from './careline.svg';
import UserLoginIcon from './user-login.svg';
import CautionIcon from './caution.svg';
import VerifiedIcon from './verified.svg';
import MapPinIcon from './pin.svg';
import SearchIcon from './search.svg';
import StarIcon from './star.svg';
import StarFillIcon from './star-fill.svg';
import BulletIcon from './bullet.svg';
import PhoneIcon from './phone.svg';
import AddPackge from './addPackage.svg';
import BuyPackage from './buyPackage.svg';
import RightArrow from './right-Arrow.svg';
import WarningIcon from './warning.svg';
import PropertyBid from './propertyBid.svg';
import Documentview from './documentview.svg';
import DocumentDisplay from './document.svg';
import Addbutton from './addbutton.svg';
import Userlogin from './userlogin.svg';
import UploadIcon from './uploadIcon.svg';
import User from './user.svg';
import Closebutton from './closebutton.svg';
import AddUploadName from './add.svg';
import HouseMarkerIcon from './house-marker.svg';
import NoImage from './noImage.svg';
import EditProperty from './editProperty.svg';
import DuplicateProperty from './duplicateProperty.svg';
import EyeIcon from './eye-view.svg';
import UploadFile from './uploadFile.svg';
import UploadFileCheck from './uploadFileCheck.svg';
import checkmark from './checkmark.svg';
import xmark from './xmark.svg';
import clip from './clip.svg';
import DeleteIcon from './delete-icon.svg';
import revertIcon from './revert.svg';

export default {
    CarelineIcon,
    UserLoginIcon,
    CautionIcon,
    VerifiedIcon,
    MapPinIcon,
    SearchIcon,
    StarIcon,
    StarFillIcon,
    BulletIcon,
    PhoneIcon,
    AddPackge,
    BuyPackage,
    RightArrow,
    WarningIcon,
    PropertyBid,
    Documentview,
    DocumentDisplay,
    Addbutton,
    Userlogin,
    UploadIcon,
    User,
    Closebutton,
    AddUploadName,
    HouseMarkerIcon,
    NoImage,
    EditProperty,
    DuplicateProperty,
    EyeIcon,
    UploadFile,
    UploadFileCheck,
    checkmark,
    xmark,
    clip,
    DeleteIcon,
    revertIcon,
};
