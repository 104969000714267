import React, { useState } from 'react';
import { Colors, Paginator } from '@dm/bigfish';
import Translate from 'i18n-js';
import { IViewAllRecurringPMTransactionResponse, PMTransactionRecurringFrequencyTypeEnum, RecurringPMTransactionStatus } from '@dm/types';
import NavActions from 'lib/NavActions';
import dayjs from 'dayjs';
import { Bars, Oval } from 'react-loader-spinner';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import icons from 'assets/icons';
import Actions from 'redux/Actions';
import StyledComponent from '../styles/TransactionsStyle';
import EditRecurringTransactionModal from './EditRecurringTransactionModal';

interface ComponentProps {
        // props from parent
        recurringTransactionsData: IViewAllRecurringPMTransactionResponse[] | null;

        paginationNumber: number;
        paginationData: (string | number)[] | [];
        nextPaginationNumber: () => void;
        prevPaginationNumber: () => void;
        firstPaginationNumber: () => void;
        lastPaginationNumber: () => void;
        onClickPaginationNumber: (pages: number) => void;

        // props from store
        getRecurringTransactionsResponseAttempt: boolean;
        getRecurringTransactionsResponseFailure: string;

        setEditRecurringTransactionModal: (state: boolean) => void;
}

const RecurringTransactionListTable = (props: ComponentProps): JSX.Element => {
    const {
        recurringTransactionsData,

        paginationNumber,
        paginationData,
        nextPaginationNumber,
        prevPaginationNumber,
        firstPaginationNumber,
        lastPaginationNumber,
        onClickPaginationNumber,

        getRecurringTransactionsResponseAttempt,
        getRecurringTransactionsResponseFailure,
        setEditRecurringTransactionModal,
    } = props;
    const {
        StatusContainer,
        PropertyListingTable,
        PropertyListingHeader,
        StyledIconButton,
        Icons,
        PropertyListingRowButton,
        NameContainer,
        PropertyListingsColumnWithImage,
        PropertyListingColumnEmpty,
        LoadingContainer,
        ActionButtonContainer,
    } = StyledComponent;

    const [selectedRecurring, setSelectedRecurring] = useState<IViewAllRecurringPMTransactionResponse | null>(null);
    const renderStatus = (status: RecurringPMTransactionStatus) => {
        switch (status) {
            case RecurringPMTransactionStatus.Active:
                return <p>Active</p>;
            case RecurringPMTransactionStatus.Inactive:
                return <p>Not Active</p>;
            default:
                return null;
        }
    };

    const renderFrequency = (frequency: PMTransactionRecurringFrequencyTypeEnum) => {
        switch (frequency) {
            case PMTransactionRecurringFrequencyTypeEnum.daily:
                return <p>Daily</p>;
            case PMTransactionRecurringFrequencyTypeEnum.weekly:
                return <p>Weekly</p>;
            case PMTransactionRecurringFrequencyTypeEnum.monthly:
                return <p>Monthly</p>;
            case PMTransactionRecurringFrequencyTypeEnum.yearly:
                return <p>Yearly</p>;
            default:
                return null;
        }
    };

    const renderActionButtons = (transactionData: IViewAllRecurringPMTransactionResponse) => {
        return (
            <StyledIconButton
                onClick={() => [setSelectedRecurring(transactionData), setEditRecurringTransactionModal(true)]}
            >
                <Icons src={icons.EditProperty} width='30px' height='20px' stroke='green' />
            </StyledIconButton>
        );
    };

    if (getRecurringTransactionsResponseAttempt) {
        return (
            <LoadingContainer>
                <Bars
                    color={Colors.primary}
                    height={260}
                    width={260}
                />
            </LoadingContainer>
        );
    }

    if (getRecurringTransactionsResponseFailure) {
        return (
            <PropertyListingColumnEmpty>
                <p>{Translate.t('PropertyReport.PropertyReportError4')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError5')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError6')}</p>
            </PropertyListingColumnEmpty>
        );
    }

    if (!recurringTransactionsData) {
        return (
            <PropertyListingColumnEmpty>
                <p>{Translate.t('PropertyReport.PropertyReportError1')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError2')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError3')}</p>
            </PropertyListingColumnEmpty>
        );
    }

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Paginator
                    currentIndex={paginationNumber}
                    data={paginationData}
                    onNext={nextPaginationNumber}
                    onPrev={prevPaginationNumber}
                    onFirst={firstPaginationNumber}
                    onLast={lastPaginationNumber}
                    onClickIndex={onClickPaginationNumber}
                />
            </div>
            <PropertyListingTable>
                <PropertyListingHeader isRecurring>

                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsName')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsUnit')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsAmount')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsDescription')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsStatus')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsFrequency')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsLastDue')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsNextDue')}</h4>
                    <h4>{Translate.t('PropertyRecurringTransactions.PropertyRecurringTransactionsAction')}</h4>
                </PropertyListingHeader>

                {(recurringTransactionsData?.length) && recurringTransactionsData.map((transaction, transactionIndex) => {
                    const {
                        _id,
                        propertyId,
                        propertyName,
                        unitNo,
                        amount,
                        description,
                        status,
                        frequency,
                        lastRunDate,
                        nextRunDate,
                        transactionType,
                    } = transaction;

                    const uniqueKey = `${transactionIndex}-${propertyId}`;

                    return (
                        <PropertyListingRowButton
                            key={uniqueKey}
                            isRecurring
                        >
                            <PropertyListingsColumnWithImage onClick={() => NavActions.navToPropertyDetails(propertyId)}>
                                <NameContainer>
                                    {propertyName}
                                </NameContainer>

                            </PropertyListingsColumnWithImage>
                            <p>{unitNo}</p>
                            {
                                amount > 0 ? (<p style={{ color: Colors.success }}>{`RM ${amount}`}</p>)
                                    : (<p style={{ color: Colors.danger }}>{`RM ${Math.abs(amount)}`}</p>)
                            }
                            <p>{description}</p>
                            {renderStatus(status)}
                            {renderFrequency(frequency)}
                            <p>{ dayjs(lastRunDate).format('YYYY-MM-DD')}</p>
                            <p>{ dayjs(nextRunDate).format('YYYY-MM-DD')}</p>
                            <ActionButtonContainer>
                                {renderActionButtons(transaction)}
                            </ActionButtonContainer>
                        </PropertyListingRowButton>

                    );
                })}

            </PropertyListingTable>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Paginator
                    currentIndex={paginationNumber}
                    data={paginationData}
                    onNext={nextPaginationNumber}
                    onPrev={prevPaginationNumber}
                    onFirst={firstPaginationNumber}
                    onLast={lastPaginationNumber}
                    onClickIndex={onClickPaginationNumber}
                />
            </div>
            <EditRecurringTransactionModal selectedRecurring={selectedRecurring} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({

    getRecurringTransactionsResponseAttempt: Selectors.getPackagesRecurringTransactionsResponseAttempt(state),
    getRecurringTransactionsResponseFailure: Selectors.getPackagesRecurringTransactionsResponseFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setEditRecurringTransactionModal: (state: boolean) => dispatch(Actions.setEditRecurringTransactionModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurringTransactionListTable);
