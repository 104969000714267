import React, { useState } from 'react';
import styled from 'styled-components';

import { Colors } from '@dm/bigfish';
import { IOption } from 'entities/component';

interface InlineSelectionInput {
  data: IOption[];
  currentValue?: string | number;
  onChangeSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  style?: React.CSSProperties;
  defaultValue?: string;
  placeHolder?: string;
  showPlaceHolder?: boolean;
}

const InlineSelectionInput = ({ data, defaultValue, currentValue, onChangeSelection, placeHolder, showPlaceHolder, style }: InlineSelectionInput): JSX.Element => {
    return (
        <Select style={style} value={currentValue} onChange={onChangeSelection}>
            {
                showPlaceHolder && (
                    <option value='' disabled>{placeHolder}</option>
                )
            }
            {
                data.map((option: IOption, index) => {
                    const uniqueKey = `${option.description}${index}`;
                    return (
                        <option
                            key={uniqueKey}
                            value={option.id}
                        >
                            {option.description}
                        </option>
                    );
                })
            }
        </Select>
    );
};

InlineSelectionInput.defaultProps = {
    currentValue: undefined,
    style: undefined,
    defaultValue: undefined,
    placeHolder: 'Select an option',
    showPlaceHolder: false,
};

const Select = styled.select<{width?: string}>`
  width: 80px;
  background: none;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;

  color: ${Colors.black};

  &:focus {
    outline: none;
  }
`;

export default InlineSelectionInput;
