import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OwnersGateway from 'api/Owners';

import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { EditOwnerDetailsPayload } from 'redux/slices/owners/types';

export default function* watchEditOwnerDetails(api: OwnersGateway): SagaWatcherReturnType {
    yield takeEvery('owners/editOwnerDetailsAttempt', handleEditOwnerDetails, api);
}

function* handleEditOwnerDetails(api: OwnersGateway, data: EditOwnerDetailsPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const { userId, userInfo } = data.payload;

    const response = yield* call([api, api.editOwnerDetails], {
        userId,
        userInfo,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message);
        yield put(Actions.editOwnerDetailsFailure(response.message));
    } else {
        toast.success('Owner details updated successfully');
        yield put(Actions.editOwnerDetailsSuccess());
    }
}
