import { PaymentStatusEnum, PaymentTypeEnum } from '@dm/types';
import { IOption } from 'entities/component';

export const paymentTypeDropwdownData: IOption[] = [{
    id: PaymentTypeEnum.ManualBankTransfer,
    description: 'Manual Bank Transfer',
},
{
    id: PaymentTypeEnum.PaymentGateway,
    description: 'Payment Gateway',
}];

export const paymentStatusDropdownData: IOption[] = [
    {
        id: PaymentStatusEnum.Pending,
        description: 'Pending',
    }, {
        id: PaymentStatusEnum.Approved,
        description: 'Approved',
    },
    {
        id: PaymentStatusEnum.Rejected,
        description: 'Rejected',
    },
];
