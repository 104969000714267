import { select, call, put, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GetUserInfoActionPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';
import { toast } from 'react-toastify';

export default function* watchGetUserInfo(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/getUserInfoAttempt', handleGetUserInfo, api);
}

function* handleGetUserInfo(api: AuthGateway, data: GetUserInfoActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getUserInfo], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.getUserInfoFailure('Something went wrong. Please try again.'));
            return;
        }
        yield put(Actions.getUserInfoSuccess(response.data));
    } else {
        yield put(Actions.getUserInfoFailure(response.message));
    }
}
