import React from 'react';
import ModuleCreateModal from './ModuleCreateModal';

const ModalSection = (): JSX.Element => {
    return (
        <ModuleCreateModal />
    );
};

export default ModalSection;
