import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import { toast } from 'react-toastify';
import Actions from 'redux/Actions';
import { SetCreatePMTransactionPayload } from 'redux/slices/packages/types';
import { PMTransactionTypeEnum } from '@dm/types';

export default function* watchCreatePropertyManagementTransactionSort(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesCreatePMTransactionAttempt', handleCreateStatusPackages, api);
}

function* handleCreateStatusPackages(api: PackagesGateway, data: SetCreatePMTransactionPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const {
            propertyId,
            amount,
            description,
            transactionDate,
            transactionType,
            isRecurring,
            startDate,
            endDate,
            frequency,
            interval,
            proceedWithNegativeBalance,
        } = data.payload;

        const getWalletAmount = yield* call([api, api.getPropertyWallet], {
            authToken,
            propertyId,
        });

        if (getWalletAmount.status === GatewayResponseStatus.Success) {
            // ? check wallet balance
            if (getWalletAmount.data
                && getWalletAmount.data.amount < amount
                && transactionType !== PMTransactionTypeEnum.topup
                && !proceedWithNegativeBalance
                && !isRecurring
            ) {
                const negativeBalance = amount - getWalletAmount.data.amount;

                yield put(Actions.setNegativeBalance(negativeBalance));
                yield put(Actions.setDataToCreateTransaction(data.payload));
                yield put(Actions.setIsNegativeBalanceModalOpen(true));
            } else {
                const response = yield* call([api, api.setPackagesPackagesCreatePMTransaction], {
                    propertyId,
                    amount,
                    description,
                    transactionDate,
                    transactionType,
                    isRecurring,
                    startDate,
                    endDate,
                    frequency,
                    interval,
                    authToken,
                });

                if (response.status === GatewayResponseStatus.Success) {
                // perform operation here
                    if (!response.data) {
                        yield put(Actions.packagesCreatePMTransactionFailure(Translate.t('GeneralUI.SomethingWrong')));
                        return;
                    }
                    yield put(Actions.packagesCreatePMTransactionSuccess(response.data));
                    yield put(Actions.setTransactionModalOpen(false));
                    yield put(Actions.packagesGetIViewAllTransactionsResponseAttempt({
                        search: '',
                        status: 9999,
                        sort: 2,
                        transactionType: 9999,
                        index: 1,
                    }));
                    toast.success('Transaction created!');
                } else {
                // handle error
                    yield put(Actions.packagesCreatePMTransactionFailure(response.message));
                    toast.error(response.message);
                }
            }
        }
    }
}
