import { PMTransactionTypeEnum, PropertyManagementTransactionStatus } from '@dm/types';
import { IOption } from 'entities/component';

const transactionStatusDropdownData: IOption[] = [
    {
        id: PropertyManagementTransactionStatus.Accepted,
        description: 'Accepted',
    },
    {
        id: PropertyManagementTransactionStatus.Completed,
        description: 'Completed',
    },
    {
        id: PropertyManagementTransactionStatus.Pending,
        description: 'Pending',
    },
    {
        id: PropertyManagementTransactionStatus.Rejected,
        description: 'Rejected',
    },
];

const transactionTypesDropdownData: IOption[] = [
    {
        id: PMTransactionTypeEnum.topup,
        description: 'Topup',
    },
    {
        id: PMTransactionTypeEnum.transaction,
        description: 'Transaction',
    },
    {
        id: PMTransactionTypeEnum.withdrawal,
        description: 'Withdrawal',
    },
    {
        id: PMTransactionTypeEnum.rental,
        description: 'Rental',
    },
];

export {
    transactionStatusDropdownData,
    transactionTypesDropdownData,
};
