import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IGetCobrokes, IReviewCobrokes, IGetRea } from 'entities/rea';

import { AdminViewAllCoBrokeResponse, GetREADetailsResponse, READetailsWithPropertyInfo } from '@dm/types';

export interface GetCobrokesParams {
    authToken: string;
    data: IGetCobrokes;
}

export interface ReviewCobrokesParams {
    authToken: string;
    data: IReviewCobrokes;
}

export interface GetReaParams {
    authToken: string;
    data: IGetRea;
}

export interface GetReaDetailsParams {
    authToken: string;
    reaId: string;
}

export abstract class IReaGateway extends Gateway {
    abstract getCobrokes(params: GetCobrokesParams): GatewayResponse<AdminViewAllCoBrokeResponse>;

    abstract reviewCobrokes(params: ReviewCobrokesParams): GatewayResponse<string | null>;

    abstract getRea(params: GetReaParams): GatewayResponse<GetREADetailsResponse>;

    abstract getReaDetails(params: GetReaDetailsParams): GatewayResponse<READetailsWithPropertyInfo | null>;
}
