import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { EditPropertyGroupDetailsPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchEditPropertyGroupDetails(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/editPropertyGroupDetailsAttempt', handleEditPropertyGroupDetails, api);
}

function* handleEditPropertyGroupDetails(api: PropertyGateway, data: EditPropertyGroupDetailsPayload) {
    const {
        propertyGroupId,
        details,
        location,
        facilities,
        description,
    } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.editPropertyGroupDetails], {
        propertyGroupId,
        details,
        location,
        facilities,
        description,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('saved!');
        yield put(Actions.editPropertyGroupDetailsSuccess());
        yield put(Actions.getPropertyGroupDetailsAttempt({ propertyGroupId }));
        if (!response.data) {
            yield put(Actions.editPropertyGroupDetailsFailure(Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.editPropertyGroupDetailsFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.Failed'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
