export default [
    {
        id: 'mr',
        description: 'Mr',
    },
    {
        id: 'miss',
        description: 'Miss',
    },
    {
        id: 'mrs',
        description: 'Mrs',
    },
];
