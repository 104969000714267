import React, { FunctionComponent, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { Button, Colors, Fonts } from '@dm/bigfish';
import { Category, IFile, IPackage, PackageCategory, PaymentStatusEnum, ViewAllPMTransactionResponse } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { IApproveRejectPendingPayment } from 'entities/property';

import Translate from 'lib/translate';
import SVG from 'react-inlinesvg';
import icons from 'assets/icons';
import { FileUrl } from 'entities/transactions';

interface PendingPaymentCardProps {
    id: string;
    propertyName: string;
    unitNo: string;
    createdAt: Date;
    totalPrice: number;
    packages: {
        category: PackageCategory;
        price: number;
    }[];
    status: PaymentStatusEnum;
    receipts: IFile[];
    setModalOpen: (state: boolean) => void;
    setSelectedReceipts: (data: IFile[]) => void;
    setApproveRejectModalOpen: (state: boolean) => void;
    setApproveRejectParams: (data: IApproveRejectPendingPayment) => void;

    imageUrls: FileUrl[];
    setIsFileViewModalOpen: (value: boolean) => void;
    setFileList: (value: FileUrl[]) => void;

}

const PendingPaymentCard: FunctionComponent<PendingPaymentCardProps> = (props: PendingPaymentCardProps) => {
    const {
        id,
        propertyName,
        unitNo,
        createdAt,
        totalPrice,
        packages,
        status,
        receipts,
        setModalOpen,
        setSelectedReceipts,
        setApproveRejectModalOpen,
        setApproveRejectParams,
        imageUrls,
        setIsFileViewModalOpen,
        setFileList,
    } = props;

    const renderDocumentsActions = () => {
        return (
            <ActionButtonContainer>
                <StyledIconButton
                    disabled={imageUrls.length === 0}
                    onClick={() => [setIsFileViewModalOpen(true), setFileList(imageUrls)]}
                    style={{ alignSelf: 'center', display: imageUrls.length > 0 ? 'flex' : 'hidden' }}
                >
                    <Icons src={icons.SearchIcon} width='100%' height='20px' />
                </StyledIconButton>
            </ActionButtonContainer>
        );
    };

    const approveRejectClickHandler = (paymentStatus: PaymentStatusEnum) => {
        setApproveRejectModalOpen(true);

        setApproveRejectParams({
            paymentId: id,
            status: paymentStatus,
        });
    };

    return (
        <Card>
            <h4>
                {id}
            </h4>
            <h4>
                {propertyName}
            </h4>
            <h4>
                {unitNo}
            </h4>
            <h4>
                {dayjs(createdAt).format('DD/MM/YYYY')}
            </h4>
            <h4>
                {`RM${totalPrice}`}
            </h4>
            <div>
                {renderDocumentsActions()}
            </div>
            <h4>
                {PaymentStatusEnum[status]}
            </h4>
            {status === PaymentStatusEnum.Pending && (
                <div>
                    <Button
                        label={Translate.t('PaymentsReport.PaymentsRpActionButtonApprove')}
                        style={{
                            width: '100%',
                            marginBottom: '5px',
                            fontSize: '16px',
                        }}
                        onClick={() => approveRejectClickHandler(PaymentStatusEnum.Approved)}
                    />
                    <Button
                        label={Translate.t('PaymentsReport.PaymentsRpActionButtonReject')}
                        style={{
                            width: '100%',
                            fontSize: '16px',
                        }}
                        onClick={() => approveRejectClickHandler(PaymentStatusEnum.Rejected)}
                    />
                </div>
            )}
        </Card>
    );
};

const Card = styled.div`
    display: grid;
    align-items: center;
    text-align: center;
    width: 100%;
    grid-template-columns: repeat(8, 1fr);
    font-family: ${Fonts.primary};
    color: black;
    column-gap: 10px;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const StyledIconButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all .2s;

    &:not(:disabled):active {
        transform: translateY(-3px);
    }

    &:disabled{
        cursor: not-allowed;
    }
`;

const Icons = styled(SVG)<{width: string, height?: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
`;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalOpen: (state: boolean) => dispatch(Actions.setReceiptImageModalOpen(state)),
    setApproveRejectModalOpen: (state: boolean) => dispatch(Actions.setApproveRejectModalOpen(state)),
    setApproveRejectParams: (data: IApproveRejectPendingPayment) => dispatch(Actions.setApproveRejectParams(data)),

    setIsFileViewModalOpen: (state: boolean) => dispatch(Actions.setFileViewModalOpen(state)),
    setFileList: (data: FileUrl[]) => dispatch(Actions.setFileList(data)),

});

export default connect(null, mapDispatchToProps)(PendingPaymentCard);
