export type ViewTransactionsParams = {
    search: string,
    status: number,
    transactionType: number,
    sort: number,
    index: number,
}

export type UpdateTransactionStatusParams = {
    propertyId: string,
    transactionId: string,
    status: number,
    search?: string,
    transactionType?: number,
    sort?: number,
    index?: number,
    currentStatus?: number,
}

export type FileUrl = {
    url: string
}

export enum ParamsEnum {
    TransactionType = 1,
    TransactionStatus = 2,
    TransactionSort = 3,
}
