import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IAdminDashboard, IGetUserInfo } from 'entities/Auth';
import { LoginParams, LoginResponse, IAuthGateway, GetUserInfoParams, GetAdminDashboardParams } from './AuthBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});
export default class AuthGateway extends IAuthGateway {
    async login(params: LoginParams): GatewayResponse<LoginResponse | null> {
        const response: ApiResponse<LoginResponse> = await this.api.post('auth/login', params);
        return this.process(response);
    }

    async getUserInfo(params: GetUserInfoParams): GatewayResponse<IGetUserInfo> {
        const response: ApiResponse<IGetUserInfo> = await this.api.get('admin/user/getUserInfo', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getAdminDashboard(params: GetAdminDashboardParams): GatewayResponse<IAdminDashboard> {
        const response: ApiResponse<IAdminDashboard> = await this.api.get('admin/dashboard/getDashboard', {}, getHeaders(params.authToken));
        return this.process(response);
    }
}
