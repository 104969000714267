import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { Oval } from 'react-loader-spinner';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { BreadCrumb, Colors, Fonts, Paginator } from '@dm/bigfish';
import { GetPendingPaymentResponse, IFile, PaymentStatusEnum, PaymentTypeEnum, ViewAllPMTransactionResponse } from '@dm/types';

import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';
import { paymentTypeDropwdownData, paymentStatusDropdownData } from 'lib/PaymentSelectionData';

import { IPendingPayment } from 'entities/property';

import MainContainer from 'components/MainContainer';
import InlineSelectionInput from 'components/InlineSelectionInput';

import FileListModal from 'components/FileListModal';
import PendingPaymentCard from './components/PendingPaymentCard';
import RecieptsImagesModal from './components/RecieptsImagesModal';
import ApproveRejectModal from './components/ApproveRejectModal';

interface PaymentProps {
    getPendingPaymentLoading: boolean;
    getPendingPaymentError: string;
    pendingPaymentsData: GetPendingPaymentResponse;
    getAllPendingPayment: (
        index: number,
        dateTo: string,
        dateFrom: string,
        type: PaymentTypeEnum,
        status: PaymentStatusEnum,
    ) => void;
    setTabView: (tabView: string) => void;
    getUserInfoData: () => void;
}

const Payment: FunctionComponent<PaymentProps> = (props: PaymentProps) => {
    const {
        getPendingPaymentLoading,
        getPendingPaymentError,
        pendingPaymentsData,
        getAllPendingPayment,
        setTabView,
        getUserInfoData,
    } = props;

    const [dateFrom, setDateFrom] = useState(dayjs(new Date()).subtract(7, 'd').toDate());
    const [dateTo, setDateTo] = useState(new Date());
    const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(PaymentTypeEnum.ManualBankTransfer);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum>(PaymentStatusEnum.Pending);
    const [selectedReceipts, setSelectedReceipts] = useState<IFile[]>([]);

    const [pendingPaymentList, setPendingPaymentList] = useState<IPendingPayment[]>([]);

    const [selectedPropertyId, setSelectedPropertyId] = useState('');
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<PaymentStatusEnum>(PaymentStatusEnum.Pending);

    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('PaymentsReport.PaymentsRpBcDashboard'),
                onClick: () => { NavActions.navToPayments(); },
            },
            {
                label: Translate.t('PaymentsReport.PaymentsRpBcPayments'),
            },
        ],
    );

    useEffect(() => {
        setTabView('payments');
        getUserInfoData();
    }, []);

    useEffect(() => {
        const tommorowDate = dayjs(dateTo).add(1, 'day');

        getAllPendingPayment(
            1,
            tommorowDate.format('YYYY-MM-DD'),
            dayjs(dateFrom).format('YYYY-MM-DD'),
            paymentType,
            paymentStatus,
        );
    }, [dateTo, dateFrom, paymentType, paymentStatus]);

    useEffect(() => {
        if (pendingPaymentsData) {
            const { maxIndex, index = 0, data } = pendingPaymentsData;

            setPaginationData(createPaginationData(maxIndex));
            setMaxPages(maxIndex);

            setPendingPaymentList(data);
        }
    }, [pendingPaymentsData]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);

        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        const tommorowDate = dayjs(dateTo).add(1, 'day');

        if (currentNumber !== paginationNumber) {
            getAllPendingPayment(
                currentNumber,
                tommorowDate.format('YYYY-MM-DD'),
                dayjs(dateFrom).format('YYYY-MM-DD'),
                paymentType,
                paymentStatus,
            );
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);
        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        const tommorowDate = dayjs(dateTo).add(1, 'day');

        if (currentNumber !== paginationNumber) {
            getAllPendingPayment(
                currentNumber,
                tommorowDate.format('YYYY-MM-DD'),
                dayjs(dateFrom).format('YYYY-MM-DD'),
                paymentType,
                paymentStatus,
            );
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);

        const tommorowDate = dayjs(dateTo).add(1, 'day');

        if (paginationNumber !== 1) {
            getAllPendingPayment(
                paginationNumber,
                tommorowDate.format('YYYY-MM-DD'),
                dayjs(dateFrom).format('YYYY-MM-DD'),
                paymentType,
                paymentStatus,
            );
            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);

        const tommorowDate = dayjs(dateTo).add(1, 'day');

        if (paginationNumber !== maxPages) {
            getAllPendingPayment(
                paginationNumber,
                tommorowDate.format('YYYY-MM-DD'),
                dayjs(dateFrom).format('YYYY-MM-DD'),
                paymentType,
                paymentStatus,
            );
            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);

        const tommorowDate = dayjs(dateTo).add(1, 'day');

        if (pages !== paginationNumber) {
            getAllPendingPayment(
                pages,
                tommorowDate.format('YYYY-MM-DD'),
                dayjs(dateFrom).format('YYYY-MM-DD'),
                paymentType,
                paymentStatus,
            );
            winScrollToTop();
        }
    };

    const renderPendingPaymentList = () => {
        if (getPendingPaymentLoading) {
            return (
                <div
                    style={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </div>
            );
        }

        if (!pendingPaymentList.length) {
            return (
                <FiltersContainer>
                    No Data Available
                </FiltersContainer>
            );
        }

        return (
            <div>
                {pendingPaymentList.map(item => {
                    const { _id, totalPrice, packages, status, createdAt, propertyName, unitNo, receipts } = item;
                    const imageUrls:ViewAllPMTransactionResponse['fileUrl'] = receipts.map((receipt) => {
                        return { url: receipt.url };
                    });
                    return (
                        <PendingPaymentCard
                            key={_id}
                            id={_id}
                            totalPrice={totalPrice}
                            packages={packages}
                            status={status}
                            createdAt={createdAt}
                            propertyName={propertyName}
                            unitNo={unitNo}
                            receipts={receipts}
                            setSelectedReceipts={setSelectedReceipts}
                            imageUrls={imageUrls}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <InfoContainer>
                    <FiltersContainer>
                        <p>{Translate.t('PaymentsReport.PaymentsRpTitle')}</p>

                        <FiltersInputContainer>
                            <InlineSelectionInput
                                data={paymentTypeDropwdownData}
                                onChangeSelection={e => setPaymentType(Number(e.target.value))}
                                style={{
                                    marginRight: '20px',
                                }}
                            />
                            <InlineSelectionInput
                                data={paymentStatusDropdownData}
                                defaultValue={PaymentStatusEnum.Approved.toString()}
                                onChangeSelection={e => setPaymentStatus(Number(e.target.value))}
                                style={{
                                    marginRight: '20px',
                                }}
                            />

                            <p
                                style={{
                                    fontSize: '13px',
                                    marginRight: '10px',
                                }}
                            >
                                Date Range:
                            </p>

                            <DatePickerContainer>
                                <StyledDatePicker
                                    value={dayjs(dateFrom).format('DD/MM/YYYY')}
                                    onChange={(e: Date) => setDateFrom(e)}
                                    maxDate={dateTo}
                                />
                            </DatePickerContainer>

                            <p
                                style={{
                                    marginRight: '5px',
                                    marginLeft: '5px',
                                }}
                            >
                                -
                            </p>

                            <DatePickerContainer>
                                <StyledDatePicker
                                    value={dayjs(dateTo).format('DD/MM/YYYY')}
                                    onChange={(e: Date) => { setDateTo(e); }}
                                />
                            </DatePickerContainer>
                        </FiltersInputContainer>
                    </FiltersContainer>

                    <OuterPaginatorContainer>
                        <InnerPaginatorContainer>
                            <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />
                        </InnerPaginatorContainer>
                    </OuterPaginatorContainer>

                    <ListContainer>
                        <HeadersContainer>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderID')}
                            </h4>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderName')}
                            </h4>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderUnit')}
                            </h4>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderCreatedAt')}
                            </h4>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderTotalPrice')}
                            </h4>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderDocuments')}
                            </h4>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderStatus')}
                            </h4>
                            <h4>
                                {Translate.t('PaymentsReport.PaymentsRpHeaderActions')}
                            </h4>
                        </HeadersContainer>
                        {renderPendingPaymentList()}
                    </ListContainer>

                    <OuterPaginatorContainer>
                        <InnerPaginatorContainer>
                            <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />
                        </InnerPaginatorContainer>
                    </OuterPaginatorContainer>
                </InfoContainer>
            </MainContainer>
            <RecieptsImagesModal
                receiptsData={selectedReceipts}
            />
            <FileListModal notDeletable />
            <ApproveRejectModal />
        </>
    );
};

const InfoContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
`;

const HeadersContainer = styled.div`
    display: grid;
    align-items: center;
    text-align: center;
    width: 100%;
    grid-template-columns: repeat(8, 1fr);
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    font-family: ${Fonts.primary};
    background: ${Colors.white};
`;

const FiltersContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

    background: ${Colors.white};
`;

const FiltersInputContainer = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const ListContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    height: 100%;
`;

const OuterPaginatorContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const InnerPaginatorContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 100%;
    background: none;
    color: #696969;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #a2a2a2;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    } 
    .react-datepicker__month-text--disabled, 
    .react-datepicker__quarter-text--disabled, 
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

const DatePickerContainer = styled.div``;

const mapStateToProps = (state: RootState) => ({
    getPendingPaymentLoading: Selectors.getPaymentGetAllPaymentsAttempting(state),
    getPendingPaymentError: Selectors.getPaymentGetAllPaymentsError(state),
    pendingPaymentsData: Selectors.getPaymentGetAllPayments(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getAllPendingPayment: (
        index: number,
        dateTo: string,
        dateFrom: string,
        type: PaymentTypeEnum,
        status: PaymentStatusEnum,
    ) => dispatch(Actions.getAllPaymentsAttempt({ index, dateTo, dateFrom, type, status })),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
