import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import { Bars, Oval } from 'react-loader-spinner';

import Modal from 'components/Modal';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';

import { Button, Colors } from '@dm/bigfish';
import PropertyImage from 'assets/images/house1.png';
import { toast } from 'react-toastify';

import { GetPropertiesResponse, Category, PMTransactionTypeEnum, PMTransactionRecurringFrequencyTypeEnum, IPropertyPackageStatusEnum, PropertyPackages } from '@dm/types';
import { IGetProperty, ISelectedProperty, IServerPropertyReport } from 'entities/property';
import icons from 'assets/icons';
import { IOption } from 'entities/component';
import { CreateTransactionStatusParams } from 'api/PackagesBase';
import utc from 'dayjs/plugin/utc';
import { IPackageTypeEnum } from 'entities/packages';
import StyledComponent from '../styles/NewTransactionModalStyle';

dayjs.extend(utc);

export const pmTransactionTypeData: IOption[] = [
    {
        id: PMTransactionTypeEnum.transaction,
        description: 'Transaction',
    },
    {
        id: PMTransactionTypeEnum.topup,
        description: 'Topup',
    },
    {
        id: PMTransactionTypeEnum.rental,
        description: 'Rental',
    },
];

interface NewTransactionModalProps {
    propertyLoading: boolean;
    propertyError: string;
    propertyReport: IServerPropertyReport;
    getProperty: (data: IGetProperty) => void;
    clearPropertyReport: () => void;

    setPropertyManagementCreateTransactionAttempt:boolean;
    setPropertyManagementCreateTransactionError:string;
    setPropertyManagementCreateTransaction: { id: string } | null;

    setPackagesCreatePMTransactionAttempt: (data: Omit<CreateTransactionStatusParams, 'authToken'>) => void;

    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
    attempLoadingUpload: boolean;
    isDocumentUploadComplete: boolean;
    uploadDocumentError: string;

    isTransactionModalOpen: boolean
    setTransactionModal: (state: boolean) => void;
}

const NewTransactionModal = (props: NewTransactionModalProps):JSX.Element => {
    const {
        propertyLoading,
        propertyError,
        propertyReport,
        getProperty,
        clearPropertyReport,
        setPropertyManagementCreateTransactionAttempt,
        setPropertyManagementCreateTransactionError,
        setPropertyManagementCreateTransaction,
        setPackagesCreatePMTransactionAttempt,
        attempLoadingUpload,
        isDocumentUploadComplete,
        uploadDocumentError,
        getUploadUrl,
        isTransactionModalOpen,
        setTransactionModal,
    } = props;

    const {
        MainContainer,
        ModalTitleContainer,
        ModalContentContainer,
        Title,
        SpanContainer,
        SeperatingLine,
        TransactionPropertyContainer,
        DateContainer,
        IntervalContainer,
        TPTitle,
        StyledSearchBar,
        LoadingContainer,
        TransactionDetailsContainer,
        UpperTransactionDetailsContainer,
        SelectedPropertyContainer,
        DetailsContainer,
        DetailsContainerOverlay,
        TransactionForm,
        InputContainer,
        DropdownWrapper,
        DropdownSelect,
        RecurringToggle,
        RecurringContainer,
        LeftContainer,
        RightContainer,
        StyledInput,
        StyledDatePicker,
        StyledButton,
        ToggleLabel,
        ToggleInput,
        UploadImage,
        UploadDocumentsContainer,
        PropertiesTable,
        PropertyListingTable,
        PropertyListingHeader,
        PropertyListingRowButton,
        PropertyListingRowButtonError,
        NameContainer,
        PropertyListingsColumnWithImage,
        DateWrapper,
        DateTimePicker,
        StyledButtonWrapper,
    } = StyledComponent;

    const [recurringCheck, setRecurringCheck] = useState(false);

    const [amount, setAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [intervalValue, setIntervalValue] = useState('interval');
    const [frequency, setFrequency] = useState<PMTransactionRecurringFrequencyTypeEnum | undefined>(undefined);
    const [transactionType, setTransactionType] = useState<string | PMTransactionTypeEnum>(Number(PMTransactionTypeEnum.transaction));
    const [recurringStartDate, setRecurringStartDate] = useState(new Date());
    const [recurringEndDate, setRecurringEndDate] = useState(new Date());
    const [transactionDate, setTransactionDate] = useState<Date>(new Date());

    const [uploadedFile, setUploadedFile] = useState<File>();

    const [searchKeyword, setSearchKeyword] = useState('');
    const [propertyList, setPropertyList] = useState<GetPropertiesResponse[]>([]);
    const [selectedPropertyList, setSelectedPropertyList] = useState<ISelectedProperty>({ propertyId: '', propertyName: '', unitNo: '', location: '' });

    useEffect(() => {
        if (!isTransactionModalOpen) {
            getProperty({ index: 1, activePackages: PropertyPackages.propertyManagement.toString() });
            setAmount('0');
            setRemarks('');
            setFrequency(undefined);
            setIntervalValue('interval');
            setSelectedPropertyList({ propertyId: '', propertyName: '', unitNo: '', location: '' });
            setTransactionModal(false);
            setTransactionType(Number(PMTransactionTypeEnum.transaction));
            setUploadedFile(undefined);
            setRecurringCheck(false);
            setSearchKeyword('');
            clearPropertyReport();
        }
    }, [isTransactionModalOpen]);

    useEffect(() => {
        const { data } = propertyReport;

        setPropertyList(data);
    }, [propertyReport]);

    useEffect(() => {
        if (dayjs(recurringEndDate).isBefore(recurringStartDate)) {
            setRecurringEndDate(recurringStartDate);
        }
    }, [recurringEndDate, recurringStartDate]);

    useEffect(() => {
        if (setPropertyManagementCreateTransaction !== null && uploadedFile !== undefined) {
            const { type } = uploadedFile;
            let documentExtension = '';

            switch (type) {
                case 'application/pdf': documentExtension = '.pdf'; break;
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }
            getUploadUrl(
                Category.PropertyManagementTransaction,
                selectedPropertyList.propertyId,
                setPropertyManagementCreateTransaction.id,
                documentExtension,
                uploadedFile,
            );
        }
    }, [setPropertyManagementCreateTransaction, uploadedFile]);

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        if (searchWord) {
            getProperty({
                index: 1,
                location: searchWord,
                activePackages: PropertyPackages.propertyManagement.toString(),
            });
        } else {
            getProperty({ index: 1, activePackages: PropertyPackages.propertyManagement.toString() });
        }
    }, 500, { leading: false }));

    const onSearchProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKeyword(value);

        throttledSetSearch.current(value);
    };

    const onCloseModal = () => {
        setAmount('');
        setRemarks('');
        setFrequency(undefined);
        setIntervalValue('interval');
        setSelectedPropertyList({ propertyId: '', propertyName: '', unitNo: '', location: '' });
        setTransactionModal(false);
        setTransactionType(Number(PMTransactionTypeEnum.transaction));
        setUploadedFile(undefined);
        setRecurringCheck(false);
        setSearchKeyword('');
    };

    const onClickCheckInput = (
        propertyId: string,
        transactionAmount: number,
        transactionRemarks: string,
        transactionDateData: Date,
        recurringInterval?: string,
        recurringFrequency?: PMTransactionRecurringFrequencyTypeEnum,
    ) => {
        if (propertyId === '') {
            toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionSelectProperty'));
            return;
        }
        if (transactionAmount === 0) {
            toast.error('Enter transaction amount!');
            return;
        }
        if ((((transactionAmount < 0) || ((Number.isNaN(transactionAmount)))))) {
            if ((transactionAmount < 0)) {
                toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionMoreThan0'));
            } else {
                toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionOnlyNumbers'));
            }
            return;
        }

        if (recurringCheck && (!recurringFrequency || recurringInterval === 'interval')) {
            toast.error('please fill recurring transaction detail!');

            return;
        }

        if (recurringCheck) {
            if ((transactionAmount > 0) && (!(Number.isNaN(transactionAmount)) && recurringFrequency && recurringInterval !== 'interval')) {
                if (propertyId.length) {
                    const dataToSubmit = {
                        propertyId,
                        amount: Number(transactionAmount),
                        description: transactionRemarks,
                        transactionType: Number(transactionType),
                        isRecurring: recurringCheck,
                        startDate: recurringStartDate.toISOString(),
                        endDate: recurringEndDate.toISOString(),
                        interval: Number(recurringInterval),
                        frequency: recurringFrequency,
                    };
                    setPackagesCreatePMTransactionAttempt(dataToSubmit);
                } else {
                    toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionSelectProperty'));
                }
            }
        }

        if (!recurringCheck) {
            if ((transactionAmount > 0) && (!(Number.isNaN(transactionAmount)))) {
                if (propertyId.length) {
                    const dataToSubmit = {
                        propertyId,
                        amount: Number(transactionAmount),
                        description: transactionRemarks,
                        transactionDate: transactionDateData.toISOString(),
                        transactionType: Number(transactionType),
                        isRecurring: recurringCheck,
                    };
                    setPackagesCreatePMTransactionAttempt(dataToSubmit);
                } else {
                    toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionSelectProperty'));
                }
            }
        }
    };

    const onClickProperty = (selectedProps: ISelectedProperty) => {
        if (selectedPropertyList.propertyId === '') {
            setSelectedPropertyList(selectedProps);
        } else {
            toast.error('You can only select one property at a time!');
        }
    };

    const clearSelectedProperty = () => {
        setSelectedPropertyList({ propertyId: '', propertyName: '', unitNo: '', location: '' });
    };

    const onDatepickerChangeHandler = (date: Date) => {
        setTransactionDate(date);
    };
    // ! render zone
    const renderFileUpload = () => {
        const hiddenFileInput = useRef<HTMLInputElement>(null);

        const handleClick = () => {
            hiddenFileInput.current?.click();
        };

        const onClearFile = () => {
            if (uploadedFile !== undefined) {
                setUploadedFile(undefined);
            }
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const fileUploaded = event.target.files?.[0];
            if (fileUploaded !== undefined) {
                if (uploadedFile === undefined) {
                    setUploadedFile(fileUploaded);
                }
                const { type } = fileUploaded;
                let documentExtension = '';

                switch (type) {
                    case 'application/pdf': documentExtension = '.pdf'; break;
                    case 'image/jpg': documentExtension = '.jpg'; break;
                    case 'image/jpeg': documentExtension = '.jpeg'; break;
                    case 'image/png': documentExtension = '.png'; break;
                    default:
                }

                if ((documentExtension === ('.png')) || (documentExtension === ('.jpeg')) || (documentExtension === ('.jpg')) || (documentExtension === ('.pdf'))) {
                    toast.success('File uploaded!');
                } else {
                    setUploadedFile(undefined);
                    toast.error(Translate.t('Packages.PaymentUpload.Filetypenotsupported'));
                }
            }
        };

        return (
            <>
                {
                    uploadedFile
                    && (
                        <SpanContainer style={{ right: 12, top: 50, position: 'absolute' }} aria-hidden='true' role='button' onClick={() => onClearFile()}>
                            <h3>&#88;</h3>
                        </SpanContainer>
                    )
                }
                <UploadDocumentsContainer
                    onClick={handleClick}
                >
                    {
                        attempLoadingUpload
                            ? (
                                <LoadingContainer>
                                    <Oval
                                        height={100}
                                        width={100}
                                        color={Colors.secondary}
                                        secondaryColor={Colors.primary}
                                    />
                                    <p>Uploading documents...</p>
                                </LoadingContainer>
                            ) : (
                                <>
                                    <UploadImage disabled={propertyLoading} src={uploadedFile ? icons.UploadFileCheck : icons.UploadFile} height={50} width={50} />
                                    {
                                        uploadedFile ? (
                                            <p>{uploadedFile.name}</p>
                                        ) : (
                                            <p>Upload Document</p>
                                        )
                                    }
                                </>
                            )
                    }

                </UploadDocumentsContainer>
                <input
                    type='file'
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
            </>
        );
    };
    const renderPropertyList = () => {
        if (propertyLoading) {
            return (
                <LoadingContainer>
                    <Bars
                        color={Colors.primary}
                        height={260}
                        width={260}
                    />
                </LoadingContainer>
            );
        }

        if (!propertyList.length) {
            return (
                <PropertyListingRowButtonError>
                    <p>{Translate.t('PropertyReport.PropertyReportError1')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError2')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError3')}</p>
                </PropertyListingRowButtonError>
            );
        }

        if (propertyError) {
            return (
                <PropertyListingRowButtonError>
                    <p>{Translate.t('PropertyReport.PropertyReportError4')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError5')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError6')}</p>
                </PropertyListingRowButtonError>
            );
        }
        return (
            propertyList.map((property, propertyIndex) => {
                const { propertyId, name, images, location, unitNo } = property;

                const { city = '', country = '' } = location;

                const uniqueKey = propertyIndex + name;

                const selectedProps = {
                    propertyId,
                    propertyName: name,
                    unitNo,
                    location: `${city},${country}`,
                };

                return (
                    <PropertyListingRowButton
                        key={uniqueKey}
                        onClick={() => onClickProperty(selectedProps)}
                        checked={selectedPropertyList?.propertyId === propertyId}
                    >
                        <PropertyListingsColumnWithImage>
                            <NameContainer>
                                {name}
                            </NameContainer>
                            <img
                                src={PropertyImage}
                                alt='properties images'
                            />
                        </PropertyListingsColumnWithImage>
                        <p>{unitNo}</p>
                        <p>
                            {`${city}, ${country}`}
                        </p>
                    </PropertyListingRowButton>
                );
            })
        );
    };
    const renderSubmitButton = (
        propertyId: string,
        transactionAmount: string,
        transactionRemarks: string,
        transactionDateData: Date,
        recurringCheckData: boolean,
        startRecurringDateData: Date,
        endRecurringDateData: Date,
        intervalValueData: string,
        frequencyData?: PMTransactionRecurringFrequencyTypeEnum,
    ) => {
        if (setPropertyManagementCreateTransactionAttempt || attempLoadingUpload) {
            return (
                <StyledButton loading label='creating...' disabled />
            );
        }

        if (recurringCheckData && frequencyData) {
            return (
                <StyledButton label='Create Recurring Transaction' onClick={() => onClickCheckInput(propertyId, Number(transactionAmount), transactionRemarks, transactionDateData, intervalValueData, frequencyData)} />
            );
        }
        return (
            <StyledButton label='Create Transaction' onClick={() => onClickCheckInput(propertyId, Number(transactionAmount), transactionRemarks, transactionDateData)} />
        );
    };
    // ! main render
    return (
        <Modal
            height='850px'
            show={isTransactionModalOpen}
        >
            <MainContainer>
                <ModalTitleContainer>
                    <Title>
                        Create Property Management Transaction
                    </Title>
                    <SpanContainer onClick={() => onCloseModal()}>
                        &times;
                    </SpanContainer>
                </ModalTitleContainer>
                <ModalContentContainer>
                    <TransactionPropertyContainer>
                        <h2>Please select property for the transaction</h2>
                        <TPTitle>
                            <StyledSearchBar
                                title='Search Property'
                                value={searchKeyword}
                                onChangeText={onSearchProperty}
                            />
                            <Button label='Clear Selected Property' onClick={() => clearSelectedProperty()} />
                        </TPTitle>
                        <PropertiesTable>
                            <PropertyListingTable>
                                <PropertyListingHeader>
                                    <h4>Property Name</h4>
                                    <h4>Unit No.</h4>
                                    <h4>Location</h4>
                                </PropertyListingHeader>
                                {renderPropertyList()}
                            </PropertyListingTable>
                        </PropertiesTable>
                    </TransactionPropertyContainer>
                    <SeperatingLine />
                    <TransactionDetailsContainer>
                        <h2>Selected Property</h2>
                        <UpperTransactionDetailsContainer>
                            <SelectedPropertyContainer>
                                {selectedPropertyList?.propertyId
                                    ? (
                                        <PropertyListingRowButton
                                            style={{ marginBottom: 0, borderRadius: '20px' }}
                                            key={selectedPropertyList?.propertyId}
                                            checked
                                        >
                                            <PropertyListingsColumnWithImage>
                                                <NameContainer>
                                                    {selectedPropertyList?.propertyName}
                                                </NameContainer>
                                                <img
                                                    src={PropertyImage}
                                                    alt='properties images'
                                                />
                                            </PropertyListingsColumnWithImage>
                                            <p>{selectedPropertyList?.unitNo}</p>
                                            <p>
                                                {selectedPropertyList?.location}
                                            </p>
                                        </PropertyListingRowButton>
                                    ) : (
                                        <h2>There is no selected property yet. Please select property before creating transaction.</h2>
                                    )}

                            </SelectedPropertyContainer>
                        </UpperTransactionDetailsContainer>
                        <DetailsContainer>
                            <DetailsContainerOverlay propertySelected={selectedPropertyList?.propertyId !== ''} />
                            <h2>Transaction details</h2>
                            <TransactionForm>
                                <InputContainer>
                                    <p>Amount:</p>
                                    <StyledInput width='100%' value={amount} onChange={(e) => setAmount(e.target.value)} />
                                </InputContainer>
                                <InputContainer>
                                    <p>Remark:</p>
                                    <StyledInput width='100%' value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                                </InputContainer>
                                {
                                    recurringCheck ? null : (
                                        <InputContainer>
                                            <p style={{ paddingRight: '20px' }}>Date:</p>
                                            <StyledButtonWrapper>
                                                {/* <div style={{ marginRight: '-1%', borderRadius: '12px', height: '75%', width: '100%' }}> */}
                                                <DateTimePicker
                                                    className='customized-datepicker'
                                                    onChange={(newDate: Date) => onDatepickerChangeHandler(newDate)}
                                                    value={(!transactionDate) ? 'Select Date' : dayjs(transactionDate).format('dddd,  DD-MM-YY')}
                                                    selected={transactionDate}
                                                    dateFormat='MMMM d, yyyy HH:mm'
                                                    shouldCloseOnSelect
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                />
                                                {/* </div> */}
                                            </StyledButtonWrapper>
                                        </InputContainer>
                                    )
                                }
                                <InputContainer>
                                    <p style={{ paddingRight: '20px' }}>Type:</p>
                                    <StyledButtonWrapper>
                                        <DropdownSelect
                                            defaultValue={PMTransactionTypeEnum.transaction}
                                            onChange={(e) => setTransactionType(Number(e.target.value))}
                                        >
                                            <option value='' disabled selected>Select transaction type</option>
                                            <option value={PMTransactionTypeEnum.transaction}>Transaction</option>
                                            <option value={PMTransactionTypeEnum.topup}>Topup</option>
                                            <option value={PMTransactionTypeEnum.rental}>Rental</option>
                                        </DropdownSelect>
                                    </StyledButtonWrapper>
                                </InputContainer>
                                <RecurringToggle>
                                    <ToggleLabel checked={recurringCheck}>
                                        <ToggleInput checked={recurringCheck} onChange={() => setRecurringCheck(!recurringCheck)} />
                                    </ToggleLabel>
                                    <p>Recurring Transaction?</p>
                                </RecurringToggle>
                            </TransactionForm>
                            {renderFileUpload()}
                            {recurringCheck && (
                                <RecurringContainer>
                                    <LeftContainer>
                                        <DateContainer>
                                            <span>
                                                <p>Start date</p>
                                                <StyledDatePicker
                                                    value={dayjs(recurringStartDate).format('DD/MM/YYYY')}
                                                    onChange={(e: Date) => setRecurringStartDate(e)}
                                                    minDate={recurringStartDate}
                                                    maxDate={null}
                                                />
                                            </span>
                                            <h4>to</h4>
                                            <span>
                                                <p>End date</p>
                                                <StyledDatePicker
                                                    value={dayjs(recurringEndDate).format('DD/MM/YYYY')}
                                                    onChange={(e: Date) => setRecurringEndDate(e)}
                                                    minDate={recurringStartDate}
                                                    maxDate={null}
                                                />
                                            </span>
                                        </DateContainer>
                                        <IntervalContainer>
                                            <p>pay every</p>
                                            <input placeholder='Frequency' onChange={(e) => setFrequency(Number(e.target.value))} />
                                            <select name='interval' onChange={(e) => setIntervalValue(e.target.value)}>
                                                <option value='' disabled selected>Interval</option>
                                                <option value={PMTransactionRecurringFrequencyTypeEnum.daily}>Days</option>
                                                <option value={PMTransactionRecurringFrequencyTypeEnum.weekly}>Weeks</option>
                                                <option value={PMTransactionRecurringFrequencyTypeEnum.monthly}>Months</option>
                                                <option value={PMTransactionRecurringFrequencyTypeEnum.yearly}>Years</option>
                                            </select>
                                        </IntervalContainer>
                                    </LeftContainer>
                                    <RightContainer>
                                        <p>
                                            You will be paying
                                            {` $${amount} `}
                                            every
                                            {` ${frequency} `}
                                            {` ${PMTransactionRecurringFrequencyTypeEnum[Number(intervalValue)] ?? 'interval'} `}
                                            starting
                                            {` ${dayjs(recurringStartDate).format('DD/MM/YYYY')} `}
                                            until
                                            {` ${dayjs(recurringEndDate).format('DD/MM/YYYY')} `}
                                        </p>
                                    </RightContainer>
                                    {/* <h2>
                                        You will be paying
                                        {` $${amount} `}
                                        every
                                        {` ${PMTransactionRecurringFrequencyTypeEnum[frequency ?? 0]} `}
                                        {` ${intervalValue} `}
                                        starting
                                        {` ${dayjs(recurringStartDate).format('DD/MM/YYYY')} `}
                                        until
                                        {` ${dayjs(recurringEndDate).format('DD/MM/YYYY')} `}
                                    </h2> */}
                                </RecurringContainer>
                            )}
                            {renderSubmitButton(selectedPropertyList.propertyId, amount, remarks, transactionDate, recurringCheck, recurringStartDate, recurringEndDate, intervalValue, frequency)}
                        </DetailsContainer>
                    </TransactionDetailsContainer>
                </ModalContentContainer>
            </MainContainer>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    propertyLoading: Selectors.getPropertyGetAllAttempt(state),
    propertyError: Selectors.getPropertyGetAllError(state),
    propertyReport: Selectors.getPropertyPropertyReport(state),
    setPropertyManagementCreateTransactionAttempt: Selectors.setPackagesPackagesCreatePMTransactionAttempt(state),
    setPropertyManagementCreateTransactionError: Selectors.setPackagesPackagesCreatePMTransactionFailure(state),
    setPropertyManagementCreateTransaction: Selectors.setPackagesPackagesCreatePMTransaction(state),
    attempLoadingUpload: Selectors.packagesgetPackagesUploadUrlAttempting(state),
    isDocumentUploadComplete: Selectors.packagesSetDocumentUploadStatus(state),
    uploadDocumentError: Selectors.packagesgetPackagesUploadUrlError(state),
    isTransactionModalOpen: Selectors.getTransactionGetTransactionModalOpen(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProperty: (data: IGetProperty) =>
        dispatch(Actions.propertyGetAllAttempt(data)),
    clearPropertyReport: () => dispatch(Actions.clearPropertyReport()),
    setPackagesUpdateTransactionStatusAttempt: (
        propertyId: string,
        transactionId: string,
        status: number,
    ) => dispatch(Actions.packagesUpdateTransactionStatusAttempt({ propertyId, transactionId, status })),
    setPackagesCreatePMTransactionAttempt: (
        data: Omit<CreateTransactionStatusParams, 'authToken'>,
    ) => dispatch(Actions.packagesCreatePMTransactionAttempt(data)),
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) =>
        dispatch(Actions.getPackagesUploadUrlAttempt({ category, id, name, extension, document })),
    setTransactionModal: (state: boolean) => dispatch(Actions.setTransactionModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTransactionModal);
