import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import ReaGateway from 'api/Rea';

import { GetReaActionPayload } from 'redux/slices/rea/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

export default function* watchGetRea(api: ReaGateway): SagaWatcherReturnType {
    yield takeEvery('rea/getReaAttempt', handleGetRea, api);
}

function* handleGetRea(api: ReaGateway, data: GetReaActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getRea], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.getReaFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.getReaSuccess(response.data));
    } else {
        yield put(Actions.getReaFailure(response.message));
    }
}
