import React, { useState, useEffect } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { Button } from '@dm/bigfish';
import { CreateTransactionStatusParams } from 'api/PackagesBase';
import { SetCreatePMTransaction } from 'redux/slices/packages/types';

// ! interface zone
// * props interface
export interface NegativeBalanceModalProps {
    negativeBalance: number;
    isModalOpen: boolean;
    setIsModalOpen: (state: boolean) => void;

    setPropertyManagementCreateTransactionAttempt:boolean;
    setPropertyManagementCreateTransactionError:string;
    setPackagesCreatePMTransactionAttempt: (data: Omit<CreateTransactionStatusParams, 'authToken'>) => void;

    dataToCreateTransaction: SetCreatePMTransaction | null;
}

// ! JSX function component
const NegativeBalanceModal = (props: NegativeBalanceModalProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        negativeBalance,
        isModalOpen,
        setIsModalOpen,
        setPropertyManagementCreateTransactionAttempt,
        setPropertyManagementCreateTransactionError,
        setPackagesCreatePMTransactionAttempt,
        dataToCreateTransaction,
    } = props;

    // ! functions
    const handleConfirmButton = () => {
        if (dataToCreateTransaction) {
            setPackagesCreatePMTransactionAttempt({ ...dataToCreateTransaction, proceedWithNegativeBalance: true });
        }
    };
    // ! render zone
    // * main render
    return (
        <Modal
            height='fit-content'
            width='500px'
            show={isModalOpen}
            closeCursor
            onClickClose={() => setIsModalOpen(!isModalOpen)}
        >
            <ModalContainer>
                <Title>Warning!</Title>
                <ModalContent>
                    <Text>Selected property wallet balance might go into negative.</Text>
                    <Text>Proceed with the transaction?</Text>
                    <Text>
                        Wallet balance:
                        <span style={{ paddingLeft: '10px', fontWeight: 'bold', color: 'red' }}>
                            - RM
                            {negativeBalance}
                        </span>
                    </Text>
                </ModalContent>
                <ModalFooter>
                    <Button
                        label='Confirm'
                        disabled={setPropertyManagementCreateTransactionAttempt}
                        loading={setPropertyManagementCreateTransactionAttempt}
                        style={{ cursor: 'pointer' }}
                        onClick={handleConfirmButton}
                    />
                    <Button
                        label='Cancel'
                        onClick={() => setIsModalOpen(!isModalOpen)}
                    />
                </ModalFooter>
                <Text style={{ color: 'red', marginTop: '10px' }}>
                    {setPropertyManagementCreateTransactionError}
                </Text>
            </ModalContainer>
        </Modal>
    );
};

// ! styled-components
const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
`;
const Text = styled.p`
    font-size: 16px;
`;
const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const ModalContent = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;
const ModalFooter = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;

`;
// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    negativeBalance: Selectors.packagesGetNegativeBalance(state),
    isModalOpen: Selectors.packagesGetIsNegativeBalanceModalOpen(state),
    setPropertyManagementCreateTransactionAttempt: Selectors.setPackagesPackagesCreatePMTransactionAttempt(state),
    setPropertyManagementCreateTransactionError: Selectors.setPackagesPackagesCreatePMTransactionFailure(state),
    dataToCreateTransaction: Selectors.packagesGetDataToCreateTransaction(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsModalOpen: (state: boolean) => dispatch(Actions.setIsNegativeBalanceModalOpen(state)),
    setPackagesCreatePMTransactionAttempt: (
        data: Omit<CreateTransactionStatusParams, 'authToken'>,
    ) => dispatch(Actions.packagesCreatePMTransactionAttempt(data)),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(NegativeBalanceModal);
