import { Colors, SearchBar } from '@dm/bigfish';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

const StatusContainer = styled.div`
    display: flex;
    height: 200px;
    width: 100%;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    z-index: 2;

    div {
        margin: 0 auto;
    }
`;

const SearchAndCreateTransactionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: white;
`;

const ButtonMainContainer = styled.div`
    justify-content: center;
    align-self: center;
`;

const StyledMainButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: white;
`;

const InputItemContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    max-width: 100%;
    width: 40%;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #F2B47E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
`;

const UploadDocumentText = styled.p`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: smaller;
`;

const PropertyReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 120px;
    color: ${Colors.black};
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 40px;
`;

// Filter
const PropertyFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    background: ${Colors.white};
`;

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const FilterTitle = styled.p`
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 16px;
`;

const FilterPropContainer = styled.div`
    display: flex;

    > select:not(:last-child){
        margin-right: 10px;
    }
`;

const SortAndStarredContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
`;

const PropertyContentContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

const PropertyListingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const PropertyListingTable = styled.div`
    width: 100%;
    margin: 20px 0 20px 0;

    > div:not(:last-child){
        margin-bottom: 8px;
    }
`;

const PropertyListingHeader = styled.div<{isRecurring?: boolean}>`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    width: 100%;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    background: ${Colors.white};

    ${props => props.isRecurring && `
        grid-template-columns: repeat(9, 1fr);
    `}

    > h4:first-child {
        padding-left: 10px;
    }

    > h4:not(:first-child){
        text-align: center;
    }
`;

const StyledIconButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all .2s;

    &:not(:disabled):active {
        transform: translateY(-3px);
    }

    &:disabled{
        cursor: not-allowed;
    }
`;

const Icons = styled(SVG)<{width: string, height?: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
`;

const PropertyListingRowButton = styled.button<{isRecurring?: boolean}>`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;
    
    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    ${props => props.isRecurring && `
        grid-template-columns: repeat(9, 1fr);
    `}

    > p {
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
    }
`;

const NameContainer = styled.h4`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const PropertyListingsColumnWithImage = styled.div`
    display: flex;
    align-items: center;

    h4 {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        width: 100px;
        margin-right: 16px;
        text-transform: capitalize;

        color: ${Colors.black};
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 12px;
    }
`;

const PropertyListingColumnEmpty = styled.div`
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: center;
    
    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    > p {
        margin: 0px;
        padding: 0px;
    }

    > p:not(:last-child) {
        margin-bottom: 3px;
    }

    > p:first-child{
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 14px;
    }

`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 580px;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export default {
    StatusContainer,
    SearchAndCreateTransactionContainer,
    ButtonMainContainer,
    StyledMainButton,
    InputItemContainer,
    UploadDocumentText,
    PropertyReportContainer,
    TitleContainer,
    Title,
    StyledSearchBar,
    PropertyFilterContainer,
    FilterContainer,
    FilterTitle,
    FilterPropContainer,
    SortAndStarredContainer,
    PropertyContentContainer,
    PropertyListingContainer,
    PropertyListingTable,
    PropertyListingHeader,
    StyledIconButton,
    Icons,
    PropertyListingRowButton,
    NameContainer,
    PropertyListingsColumnWithImage,
    PropertyListingColumnEmpty,
    LoadingContainer,
    ActionButtonContainer,
};
