import React, { useState } from 'react';
import styled from 'styled-components';
import Translate from 'lib/translate';
import SVG from 'react-inlinesvg';
import { Button } from '@dm/bigfish';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';

import Modal from '../../../components/Modal';
import Icons from '../../../assets/icons';
import UploadComponentPropertyManagement from './UploadComponentPropertyManagement';

export interface PropertyModalProps {
    showModal: boolean
    modalClose: boolean;
    setModalClose: (state: boolean) => void;
}

interface AddPaymentUploadData {
    name: string;
}

const PropertyManagementDocumentUpload = (props : PropertyModalProps) : JSX.Element => {
    const { showModal, modalClose, setModalClose } = props;

    const [counter, setCounter] = useState<AddPaymentUploadData[]>([{ name: '' }]);

    const onClickHandler = () => {
        setModalClose(false);
    };

    const addDocumentUpload = () => {
        setCounter(previous => {
            return [...previous, { name: '' }];
        });
    };

    return (
        <MainContainer>
            <Modal show={modalClose} width='28%'>
                <div>
                    <DocumentUploadTitleContainer>
                        <Title>{Translate.t('GeneralUI.UploadDocument')}</Title>

                        <CloseIcon src={Icons.Closebutton} onClick={onClickHandler} />
                    </DocumentUploadTitleContainer>
                    <ReferenceTitle>{Translate.t('Packages.LeaseAndSale.UploadDocument.Reference')}</ReferenceTitle>
                    {counter.map((item, index) => {
                        const { name } = item;

                        const uniqueKey = name + index;
                        return (
                            <UploadComponentPropertyManagement key={uniqueKey} index={index} />

                        );
                    })}
                    <AddDocumentContainer>
                        <AddIconContainer>
                            <AddIcon src={Icons.AddUploadName} onClick={addDocumentUpload} />
                            <TextAddNew>{Translate.t('Packages.LeaseAndSale.UploadDocument.AddNew')}</TextAddNew>
                        </AddIconContainer>
                        <ButtonStyledContainer>
                            <Button label={Translate.t('Packages.ButtonConfirm')} onClick={() => setModalClose(false)} />
                        </ButtonStyledContainer>

                    </AddDocumentContainer>
                </div>

            </Modal>

        </MainContainer>
    );
};

const ButtonStyledContainer = styled.div`
    max-width: 125px;
`;

const DocumentUploadTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TextAddNew = styled.p`
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #696969;
`;

const AddIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const AddDocumentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
`;

const CloseIcon = styled(SVG)`
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
`;

const AddIcon = styled(SVG)`
    display: flex;
    flex-direction: column;
    margin-right: 8px;
`;

const Title = styled.h2`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    background-color: #ffffff;
    margin-bottom: 40px;
`;

const ReferenceTitle = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

export interface RoundInputsampleProps {
    placeholder?: string;
    value?: string;
    onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onEnterPressed?: () => void;
    style?: React.CSSProperties;
}

const mapStateToProps = (state: RootState) => ({
    modalClose: Selectors.packagesSetLeaseSaleModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalClose: (state: boolean) => dispatch(Actions.setLeaseSaleModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyManagementDocumentUpload);
