import React from 'react';
import styled from 'styled-components';

import { TailSpin } from 'react-loader-spinner';

import { Colors } from '@dm/bigfish';

interface LoadingScreenProps {
  style?: React.CSSProperties;
}

const LoadingScreen = ({ style, ...otherProps }: LoadingScreenProps): JSX.Element => {
    return (
        <StyledContainer style={style} {...otherProps}>
            <TailSpin color={Colors.primary} height='250' width='250' />
        </StyledContainer>
    );
};

LoadingScreen.defaultProps = {
    style: undefined,
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default LoadingScreen;
