import React, { FunctionComponent, useState, useEffect } from 'react';
import Modal from 'components/Modal';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IFile } from '@dm/types';

import { ImageCarousel, IImageCarousel } from '@dm/bigfish';

interface RecieptsImagesModalProps {
    isModalOpen: boolean;
    setModalOpen: (state: boolean) => void;
    receiptsData: IFile[];
}

const RecieptsImagesModal: FunctionComponent<RecieptsImagesModalProps> = (props: RecieptsImagesModalProps) => {
    const { isModalOpen, receiptsData, setModalOpen } = props;

    const [imageUrls, setImageUrls] = useState<IImageCarousel[]>([]);

    useEffect(() => {
        if (receiptsData.length) {
            const parsedReceiptsData: IImageCarousel[] = receiptsData.map(item => {
                const { url = '' } = item;

                return {
                    image: url,
                    desc: '',
                };
            });

            setImageUrls(parsedReceiptsData);
        }
    }, [receiptsData]);

    const renderModalBody = () => {
        if (!imageUrls.length || !imageUrls[0].image) {
            return (
                <div>
                    No images
                </div>
            );
        }

        return (
            <ImageCarousel
                data={imageUrls}
            />
        );
    };

    return (
        <Modal
            show={isModalOpen}
            closeCursor
            onClickClose={() => setModalOpen(false)}
        >
            <div
                style={{
                    padding: '20px',
                }}
            >
                <div
                    style={{
                        marginBottom: '20px',
                        fontSize: '28px',
                    }}
                >
                    Receipts Images
                </div>
                <div>
                    {renderModalBody()}
                </div>

            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.getPaymentGetIsReceiptImagesModalOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalOpen: (state: boolean) => dispatch(Actions.setReceiptImageModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecieptsImagesModal);
