import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { delay } from 'redux-saga/effects';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { GetPendingPaymentsActionPayload } from 'redux/slices/payment/types';
import { Category, GetPendingPaymentResponse, PackageCategory, PaymentStatusEnum, PaymentTypeEnum } from '@dm/types';

export default function* watchGetPendingPayments(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('payment/getAllPaymentsAttempt', handleGetPendingPayments, api);
}

function* handleGetPendingPayments(api: PropertyGateway, data: GetPendingPaymentsActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getPendingPayments], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.propertyGetAllFailure(response.message));
    } else {
        yield put(Actions.getAllPaymentsSuccess(response.data));
    }
}
