import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';
import { Button } from '@dm/bigfish';
import PropertyCreateModal from '../containers/property/components/PropertyCreateModal';

import Modal from './Modal';

interface ModuleCreateModalProps {
  show: boolean,
  isCreatePropertyModalOpen: boolean,
  setShow: (status: boolean) => void;
  setPropertyCreateModal: (status: boolean) => void;
}

const ModuleCreateModal = (props: ModuleCreateModalProps):JSX.Element => {
    const { show, setShow, isCreatePropertyModalOpen, setPropertyCreateModal } = props;

    const onClickOwner = () => {
        setShow(false);
        NavActions.navToNewOwner();
    };

    const onClickCreatNewProperty = () => {
        setPropertyCreateModal(!isCreatePropertyModalOpen);
    };

    return (
        <Modal
            show={show}
            width='544px'
            height='fit-content'
            closeCursor
            onClickClose={() => setShow(false)}
        >
            <Container>
                <Title>{Translate.t('Modal.ModulesCreateTitle')}</Title>
                <Text>
                    {Translate.t('Modal.ModulesCreateDesc')}
                </Text>
                <ButtonContainer>
                    <ButtonModal onClick={onClickOwner} label={Translate.t('Modal.ModulesCreateBtnOwner')} />
                    <ButtonModal onClick={onClickCreatNewProperty} label={Translate.t('Modal.ModulesCreateBtnListing')} />
                </ButtonContainer>
                {isCreatePropertyModalOpen && (
                    <PropertyCreateModal />
                )}

            </Container>
        </Modal>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  > button:not(:last-child){
    margin-right: 8px;
  }
`;

const ButtonModal = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  padding: 8px;
`;

const mapStateToProps = (state: RootState) => ({
    show: Selectors.getUiModulesCreateModal(state),
    isCreatePropertyModalOpen: Selectors.setPropertyCreatePropertyModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setShow: (status: boolean) => dispatch(Actions.setModulesCreateModal(status)),
    setPropertyCreateModal: (state: boolean) => dispatch(Actions.propertyCreatePropertyModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleCreateModal);
