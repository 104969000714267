import React, { FunctionComponent, useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';

import { Button, Colors } from '@dm/bigfish';
import { PaymentStatusEnum } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IApproveRejectPendingPayment } from 'entities/property';

import Translate from 'lib/translate';

import Modal from 'components/Modal';

interface ApproveRejectModalProps {
    isModalOpen: boolean;
    approveRejectParams: IApproveRejectPendingPayment;
    approveRejectLoading: boolean;
    approveRejectError: string;
    setIsModalOpen: (state: boolean) => void;
    resetApproveRejectParams: () => void;
    approveRejectPayment: (data: IApproveRejectPendingPayment) => void;
}

const ApproveRejectModal: FunctionComponent<ApproveRejectModalProps> = (props: ApproveRejectModalProps) => {
    const {
        isModalOpen,
        approveRejectParams,
        approveRejectLoading,
        approveRejectError,
        setIsModalOpen,
        resetApproveRejectParams,
        approveRejectPayment,
    } = props;

    const cancelClickHandler = () => {
        setIsModalOpen(false);
        resetApproveRejectParams();
    };

    const renderModalBody = () => {
        if (approveRejectLoading) {
            return (
                <div style={{ margin: '10px auto' }}>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </div>
            );
        }

        if (approveRejectError) {
            return (
                <div
                    style={{ color: 'red', fontWeight: 'bold' }}
                >
                    {approveRejectError}
                </div>
            );
        }

        return (
            <div>
                {`${Translate.t('PaymentsReport.PaymentsRpApproveRejectMsg1')} ${approveRejectParams.status === PaymentStatusEnum.Approved ? Translate.t('PaymentsReport.PaymentsRpApproveLowerCase') : Translate.t('PaymentsReport.PaymentsRpRejectLowerCase')} ${Translate.t('PaymentsReport.PaymentsRpApproveRejectMsg2')}`}
            </div>
        );
    };

    return (
        <Modal
            width='450px'
            height='fit-content'
            show={isModalOpen}
            closeCursor
            onClickClose={() => setIsModalOpen(false)}
        >
            <div
                style={{
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    fontSize: '24px',
                }}
            >
                {`${approveRejectParams.status === PaymentStatusEnum.Approved ? Translate.t('PaymentsReport.PaymentsRpActionButtonApprove') : Translate.t('PaymentsReport.PaymentsRpActionButtonReject')} ${Translate.t('PaymentsReport.PaymentsRpApproveRejectMsg2')}`}
            </div>
            <div
                style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
            >
                {renderModalBody()}
            </div>
            <div>
                <Button
                    label='Confirm'
                    style={{
                        marginRight: '10px',
                    }}
                    onClick={() => approveRejectPayment(approveRejectParams)}
                />
                <Button
                    label='Cancel'
                    onClick={cancelClickHandler}
                />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.getPaymentGetIsApproveRejectModalOpen(state),
    approveRejectLoading: Selectors.getPaymentApproveRejectPaymentAttempting(state),
    approveRejectError: Selectors.getPaymentApproveRejectPaymentError(state),
    approveRejectParams: Selectors.getPaymentGetApproveRejectParams(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsModalOpen: (state: boolean) => dispatch(Actions.setApproveRejectModalOpen(state)),
    resetApproveRejectParams: () => dispatch(Actions.resetApproveRejectParams()),
    approveRejectPayment: (data: IApproveRejectPendingPayment) => dispatch(Actions.approveRejectPaymentAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveRejectModal);
