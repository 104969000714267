import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { GetDetailsActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';

import { toast } from 'react-toastify';

export default function* watchGetPropertyDetails(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertyGetDetailsAttempt', handleGetPropertyDetails, api);
}

function* handleGetPropertyDetails(api: PropertyGateway, data: GetDetailsActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const { id } = data.payload;

    const response = yield* call([api, api.getPropertyDetails], {
        id,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.propertyGetDetailsFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.propertyGetDetailsSuccess(response.data));
    } else {
        toast.error('Invalid property id. Please try again');
        NavActions.navToHome();
        yield put(Actions.propertyGetDetailsFailure(response.message));
    }
}
