import React from 'react';
import styled, { css } from 'styled-components';
import Translate from 'lib/translate';

export interface IEventTrail {
  text: string;
  onClick?: () => void;
}

export interface IEventTrailRecentEvent {
    id: string;
    message: string;
}

export interface EventTrailProps {
  /**
  * Title
  */
  title: string;
  /**
  * Label of button
  */
  buttonLabel?: string;
  /**
  * On click label button
  */
  onClickButtonLabel?: () => void;
  /**
  * Primary
  */
  primary?: boolean;
  /**
  * Data of event trail item
  */
  data: IEventTrailRecentEvent[];
  /**
  * Data of event trail item
  */
  emptyText?: string;
  /**
  * Style
  */
  style?: React.CSSProperties;
}

const EventTrailContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
  max-width: 544px;
  overflow: hidden;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 16px;
`;

const EventHeader = styled.div<{primary?: boolean}>`
  display: block;
  padding: 12px 20px;
  box-sizing: border-box;
  background: white;
  color: black;
  font-weight: bold;
  text-transform: capitalize;
  ${props => props.primary && css`
    background: #FFCFA6;
    color: white;
  `}
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 20px;
`;

const EventList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0 0 9px 0;
  padding: 0;
  > li:not(:first-child){
    padding-top: 8px;
  }
`;

const EventItem = styled.li<{single?: boolean}>`
  padding-bottom: 12px;
  border-bottom: 0.5px solid #B3B3B3;
  cursor: pointer;
  transition: all .2s;
  backface-visibility: hidden;
  &:hover {
    transform: translateY(-2px);
  }
  ${props => props.single && css`
    padding-bottom: 0;
    border-bottom: 0;
    cursor: text;
    &:hover {
      transform: translateY(0);
    }
  `}
`;

const NavigationButton = styled.button`
  display: inline-block;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: #2F80ED;
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  > span {
    padding-right: 9.5px;
  }
`;

const EventTrailRecentEvents = ({ title, buttonLabel, onClickButtonLabel, primary, data, emptyText, style, ...otherProps } : EventTrailProps) : JSX.Element => {
    const { length } = data;

    return (
        <EventTrailContainer style={style} {...otherProps}>
            <EventHeader primary={primary}>{title}</EventHeader>
            <EventContent>
                <EventList>
                    {
                        data.map((eventItem, index) => {
                            const uniqueKey = eventItem.message + index;
                            return <EventItem key={uniqueKey}>{eventItem.message}</EventItem>;
                        })
                    }

                    {!length && <EventItem single>{emptyText || Translate.t('RecentEvent.RecentEventNoPendingActivities')}</EventItem>}
                </EventList>

                {length > 1 && (
                    <NavigationButton onClick={onClickButtonLabel}>
                        <span>{buttonLabel}</span>
                        &gt;
                    </NavigationButton>
                )}
            </EventContent>
        </EventTrailContainer>
    );
};

export default EventTrailRecentEvents;
