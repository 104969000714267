import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OwnersGateway from 'api/Owners';

import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchGetOwnerDetails(api: OwnersGateway): SagaWatcherReturnType {
    yield takeEvery('owners/getOwnerDetailsAttempt', handleGetOwnerDetails, api);
}

function* handleGetOwnerDetails(api: OwnersGateway, data: PayloadAction<string>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getOwnerDetails], {
        userId: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getOwnerDetailsFailure(response.message));
    } else {
        yield put(Actions.getOwnerDetailsSuccess(response.data));
    }
}
