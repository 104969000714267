import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import ReaGateway from 'api/Rea';

import { ReviewCobrokesActionPayload } from 'redux/slices/rea/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { CoBrokeStatusEnum } from '@dm/types';

export default function* watchReviewCobrokes(api: ReaGateway): SagaWatcherReturnType {
    yield takeEvery('rea/reviewCobrokesAttempt', handleReviewCobrokes, api);
}

function* handleReviewCobrokes(api: ReaGateway, data: ReviewCobrokesActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.reviewCobrokes], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.reviewCobrokesFailure('Something went wrong. Please try again.'));
            toast.error('Something went wrong. Please try again.');
            return;
        }

        yield put(Actions.reviewCobrokesSuccess());
        yield put(Actions.getCobrokesAttempt({ index: 1 }));
        if (data.payload.status === CoBrokeStatusEnum.Rejected) {
            toast.success('Rejected cobrokes success');
        } else { toast.success('Approve cobrokes success'); }
    } else {
        yield put(Actions.reviewCobrokesFailure(response.message));
        toast.error('Something went wrong. Please try again.');
    }
}
