import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import { toast } from 'react-toastify';
import Actions from 'redux/Actions';
import { SetEditRecurringTransactionPayload } from 'redux/slices/packages/types';
import dayjs from 'dayjs';

export default function* watchEditRecurringTransaction(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesEditRecurringTransactionAttempt', handleEditRecurringTransaction, api);
}

function* handleEditRecurringTransaction(api: PackagesGateway, data: SetEditRecurringTransactionPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const {
            id,
            amount,
            description,
            transactionType,
            startDate,
            endDate,
            frequency,
            interval,
        } = data.payload;

        const newStartDate = dayjs(startDate).format();
        const newEndDate = dayjs(endDate).format();

        const response = yield* call([api, api.setPackagesEditRecurringTransaction], {
            id,
            amount: Number(amount),
            description,
            transactionType,
            startDate: newStartDate,
            endDate: newEndDate,
            frequency,
            interval,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesEditRecurringTransactionFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            yield put(Actions.packagesEditRecurringTransactionSuccess(response.data));
            yield put(Actions.setEditRecurringTransactionModal(false));
            yield put(Actions.packagesGetRecurringTransactionsResponseAttempt({
                search: '',
                status: 9999,
                sort: 2,
                transactionType: 9999,
                index: 1,
            }));
            toast.success('Edit Recurring Transaction successfully!');
        } else {
        // handle error
            yield put(Actions.packagesEditRecurringTransactionFailure(response.message));
            toast.error(response.message);
        }
    }
}
