export default [
    {
        id: 'male',
        description: 'Male',
    },
    {
        id: 'female',
        description: 'Female',
    },
];
