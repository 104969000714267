import React, { FunctionComponent, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors, Fonts } from '@dm/bigfish';

import { IFile } from '@dm/types';
import FileSaver, { saveAs } from 'file-saver';

interface PackagesListCardProps {
    id: number;
    propertyName: string;
    documents: IFile[];
    setModalOpen: (state: boolean) => void;
    setSelectedDocuments: (data: IFile[]) => void;
}

const PackagesListCard: FunctionComponent<PackagesListCardProps> = (props: PackagesListCardProps) => {
    const {
        id,
        propertyName,
        documents,
        setModalOpen,
        setSelectedDocuments,
    } = props;

    const [documentList, setDocumentList] = useState<IFile[]>([]);

    const idClickHandler = () => {
        setModalOpen(true);
        documentList.push(documents[id]);
        setSelectedDocuments(documentList);
    };

    const saveFile = () => {
        const urlLink = documents[id].url;
        const documentName = documents[id].name;

        saveAs(
            urlLink,
            documentName,
        );
    };

    return (
        <div>
            <Card>

                <TitleDateContainer>
                    {propertyName}
                </TitleDateContainer>

                <TitleDateContainer>
                    {(documents[id].createdAt !== undefined) ? (
                        <div>
                            {(dayjs(documents[id].createdAt).format('DD/MM/YYYY'))}
                        </div>
                    ) : 'no date'}
                </TitleDateContainer>

                <ButtonContainer>
                    <StyledButton
                        label={Translate.t('Packages.ViewDocuments.ButtonDownloadLabel')}
                        onClick={saveFile}
                    />

                    <StyledButtonView
                        label={Translate.t('Packages.ViewDocuments.ButtonViewLabel')}
                        onClick={idClickHandler}
                    />
                </ButtonContainer>

            </Card>
        </div>

    );
};

const StyledButton = styled(Button)`
    width: 100%;
    margin-bottom: 5px;
    font-size: 16px;
`;

const StyledButtonView = styled(Button)`
    width: 100%;
    font-size: 16px;
`;

const TitleDateContainer = styled.h4`
    justify-self: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 80%;
    padding: 20px;
`;

const Card = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 30% 30% 40%;
    font-family: ${Fonts.primary};
    color: black;
    column-gap: 10px;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalOpen: (state: boolean) => dispatch(Actions.setPackagesDocumentViewModal(state)),
});

export default connect(null, mapDispatchToProps)(PackagesListCard);
