import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import { Bars } from 'react-loader-spinner';
import throttle from 'lodash.throttle';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { BreadCrumb, Colors, Button, Paginator, SearchBar, DropdownInput, DropdownMultiChoiceInput } from '@dm/bigfish';
import { PropertyTypeEnum, PropertyStatus, GetPropertiesResponse, SortByEnum, GetPropertyGroupResponse, IPropertyGroup, IPropertyPackageStatusEnum, TenancyStatus, GroupPropertiesWithCountResponse, PropertyPackages } from '@dm/types';

import { IServerPropertyReport, IGetProperty, IGetPropertyGroup } from 'entities/property';

import { propertyTypeDropdownData, propertyStatusDropdownData, propertySortByDropdownData, propertySelectByActivePackages } from 'lib/PropertySelectionData';

import PropertyImage from 'assets/images/house1.png';
import MainContainer from 'components/MainContainer';
import icons from 'assets/icons';
import Badge from 'components/Badge';
import PropertyCreateModal from './components/PropertyCreateModal';

interface PropertyReportProps {
    loading: boolean;
    error: string;
    propertyReport: IServerPropertyReport;

    duplicatePropertyLoading: boolean;
    duplicatePropertyError: string;
    duplicatePropertySuccess: string | null;

    getProperty: (data: IGetProperty) => void;
    clearPropertyReport: () => void;
    setTabView: (tabView: string) => void;
    setDuplicateProperty: (propertyId: string) => void;
    getGroupPropertiesWithCount: () => void;

    getUserInfoData: () => void;

    setPropertyCreateModal: (state: boolean) => void;

    getPropertyGroupAttempt: boolean;
    getPropertyGroupError: string;
    propertyGroup: GetPropertyGroupResponse;
    getPropertyGroup: (data: IGetPropertyGroup) => void;

    deletePropertyGroupAttempt: boolean;
    deletePropertyGroupError: string;
    deletePropertyGroup: (propertyGroupId: string) => void

    isPropertyGroup: boolean;
    setIsPropertyGroup: (state: boolean) => void;

    duplicatePropertyGroupAttempt: boolean;
    duplicatePropertyGroupError: string;
    duplicatePropertyGroup: (propertyGroupId: string) => void;

    getPropertiesReportExport: (filter: Omit<IGetProperty, 'index'>) => void;
    getPropertiesReportExportAttempt: boolean;

    groupPropertiesWithCount: GroupPropertiesWithCountResponse[]
}

const PropertyReport = (props: PropertyReportProps): JSX.Element => {
    const { loading,
        error,
        propertyReport,
        duplicatePropertyLoading,
        duplicatePropertyError,
        duplicatePropertySuccess,
        getProperty,
        getGroupPropertiesWithCount,
        groupPropertiesWithCount,
        clearPropertyReport,
        setTabView,
        setDuplicateProperty,
        getUserInfoData,
        setPropertyCreateModal,
        getPropertyGroupAttempt,
        getPropertyGroupError,
        propertyGroup,
        getPropertyGroup,
        deletePropertyGroupAttempt,
        deletePropertyGroupError,
        deletePropertyGroup,
        isPropertyGroup,
        setIsPropertyGroup,
        duplicatePropertyGroupAttempt,
        duplicatePropertyGroupError,
        duplicatePropertyGroup,
        getPropertiesReportExport,
        getPropertiesReportExportAttempt } = props;

    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [propertyList, setPropertyList] = useState<GetPropertiesResponse[]>([]);

    const [propertyGroupData, setPropertyGroupData] = useState<IPropertyGroup[]>([]);

    const [buildingTypesOpt, setBuildingTypesOpt] = useState<string | PropertyTypeEnum>('');
    const [statusOpt, setStatusOpt] = useState<string | PropertyStatus>('');
    const [sortByOpt, setSortByOpt] = useState<string | SortByEnum>('');
    const [selectedActivePackages, setSelectedActivePackages] = useState(propertySelectByActivePackages);

    const [groupPropertiesWithCountList, setGroupPropertiesWithCountList] = useState<{ id: string, description: string}[]>([]);

    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                label: Translate.t('PropertyReport.Property'),
                onClick: () => { NavActions.navToPropertyReports(); },
            },
            {
                label: Translate.t('PropertyReport.PropertyReportBcProperty'),
            },
        ],
    );

    useEffect(() => {
        getProperty({ index: 1 });
        getGroupPropertiesWithCount();
        setTabView('property');
        getUserInfoData();
        return () => {
            clearPropertyReport();
        };
    }, []);

    useEffect(() => {
        const groupPropertiesWithCountOpt = groupPropertiesWithCount.map((property) => {
            return {
                id: property._id,
                description: `${property._id} (${property.count})`,
            };
        });

        setGroupPropertiesWithCountList(groupPropertiesWithCountOpt);

        if (isPropertyGroup) {
            const { maxIndex, data } = propertyGroup;
            setBreadCrumb(
                [
                    {
                        label: Translate.t('PropertyReport.Property'),
                        onClick: () => { NavActions.navToPropertyReports(); },
                    },
                    {
                        label: Translate.t('PropertyReport.PropertyReportBcPropertyGroup'),
                    },
                ],
            );
            if (maxIndex) {
                setPropertyGroupData(data as IPropertyGroup[]);
                setPaginationData(createPaginationData(maxIndex));
                setMaxPages(maxIndex);
            }
        } else {
            const { maxIndex, data } = propertyReport;
            setPropertyList(data);
            setPaginationData(createPaginationData(maxIndex));
            setMaxPages(maxIndex);
            setBreadCrumb(
                [
                    {
                        label: Translate.t('PropertyReport.Property'),
                        onClick: () => { NavActions.navToPropertyReports(); },
                    },
                    {
                        label: Translate.t('PropertyReport.PropertyReportBcProperty'),
                    },
                ],
            );
        }
    }, [propertyReport, propertyGroup, isPropertyGroup, groupPropertiesWithCount]);

    useEffect(() => {
        const activePackages = selectedActivePackages
            .filter(item => item.active)
            .map(item => item.id);

        const activePackagesString = activePackages.join();

        if (isPropertyGroup) {
            getPropertyGroup({ index: 1, propertyGroupName: searchKeyword });
        } else {
            getProperty({
                index: 1,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
                activePackages: activePackagesString,
            });
        }
    }, [buildingTypesOpt, statusOpt, sortByOpt, searchKeyword, isPropertyGroup, selectedActivePackages]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const checkPropertyType = (type: number) => {
        switch (type) {
            case PropertyTypeEnum.Apartment:
                return 'apartment';
            case PropertyTypeEnum.Landed:
                return 'landed';
            default:
                return '';
        }
    };

    const renderActionButtons = (propertyId: string) => {
        return (

            <ButtonMainContainer>
                <StyledIconButton onClick={() => setDuplicateProperty(propertyId)} disabled={duplicatePropertyLoading}>
                    <Icons src={icons.DuplicateProperty} width='35px' height='35px' />
                </StyledIconButton>
                <StyledIconButton onClick={() => NavActions.navToEditProperty(propertyId)}>
                    <Icons src={icons.EditProperty} width='35px' height='35px' />
                </StyledIconButton>
            </ButtonMainContainer>
        );
    };

    const renderPropertyGroupActionButtons = (propertyGroupId: string) => {
        return (
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <StyledIconButton onClick={() => duplicatePropertyGroup(propertyGroupId)}>
                    <Icons src={icons.DuplicateProperty} width='35px' height='35px' />
                </StyledIconButton>
                <StyledIconButton onClick={() => NavActions.navToEditPropertyGroup(propertyGroupId)}>
                    <Icons src={icons.EditProperty} width='35px' height='35px' />
                </StyledIconButton>
                <StyledIconButton onClick={() => deletePropertyGroup(propertyGroupId)}>
                    <Icons src={icons.DeleteIcon} width='35px' height='35px' />
                </StyledIconButton>
            </div>
        );
    };

    const checkPropertyStatus = (type: number) => {
        switch (type) {
            case PropertyStatus.approved:
                return 'approved';
            case PropertyStatus.deactivated:
                return 'deactivated';
            case PropertyStatus.draftProperty:
                return 'draft property';
            case PropertyStatus.pendingApproval:
                return 'pending approval';
            case PropertyStatus.tenanted:
                return 'tenanted';
            case PropertyStatus.vacant:
                return 'vacant';
            case PropertyStatus.rejected:
                return 'rejected';
            default:
                return '';
        }
    };

    const handleGroupPropertyWithCount = (property: string | number) => {
        setSearchKeyword(property as string);
    };

    const throttledSetSearchProperty = useRef(throttle((searchWord: string) => {
        setSearchKeyword(searchWord);
    }, 500, { leading: false }));

    const onSearchProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledSetSearchProperty.current(value);
    };

    const onSelectActivePackage = (id: string | number) => {
        setSelectedActivePackages(prevSelectedOptions => prevSelectedOptions.map((item) => ({
            ...item,
            active: id === item.id ? !item.active : item.active,
        })));
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        if (currentNumber !== paginationNumber) {
            if (isPropertyGroup) {
                getPropertyGroup({ index: currentNumber });
                return;
            }
            getProperty({
                index: currentNumber,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);
        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        if (currentNumber !== paginationNumber) {
            if (isPropertyGroup) {
                getPropertyGroup({ index: currentNumber });
                return;
            }
            getProperty({
                index: currentNumber,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);
        if (paginationNumber !== 1) {
            if (isPropertyGroup) {
                getPropertyGroup({ index: 1 });
                return;
            }
            getProperty({
                index: 1,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);
        if (paginationNumber !== maxPages) {
            if (isPropertyGroup) {
                getPropertyGroup({ index: maxPages });
                return;
            }
            getProperty({
                index: maxPages,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);
        if (pages !== paginationNumber) {
            if (isPropertyGroup) {
                getPropertyGroup({ index: pages });
                return;
            }
            getProperty({
                index: pages,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const handleToggleButton = (status: boolean) => {
        setIsPropertyGroup(status);
        setPaginationNumber(1);
    };

    const renderPackagesName = (index: number, statusPackage: IPropertyPackageStatusEnum) => {
        if (statusPackage === IPropertyPackageStatusEnum.Active) {
            switch (index) {
                case 0:
                    return 'Vacant Posession';
                case 1:
                    return 'Defect Inspection';
                case 2:
                    return 'Design & Renovation';
                case 3:
                    return 'Property Management';
                case 4:
                    return 'Vacant Management';
                case 5:
                    return 'Lease & Sale';
                default:
                    return '';
            }
        }
        return null;
    };

    const renderPackagesStatus = (packagesStatus: IPropertyPackageStatusEnum[], propertyId: string) => {
        if (!packagesStatus) return null;
        return packagesStatus.map((item, index) => {
            if (item === IPropertyPackageStatusEnum.Active) {
                return (
                    <Badge
                        key={propertyId}
                        label={renderPackagesName(index, item) ?? ''}
                        color='green'
                    />
                );
            }
            return null;
        });
    };

    const renderPropertyList = () => {
        if (loading) {
            return (
                <LoadingContainer>
                    <Bars
                        color={Colors.primary}
                        height={260}
                        width={260}
                    />
                </LoadingContainer>
            );
        }

        if (!propertyList.length) {
            return (
                <PropertyListingColumnEmpty>
                    <p>{Translate.t('PropertyReport.PropertyReportError1')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError2')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError3')}</p>
                </PropertyListingColumnEmpty>
            );
        }

        if (error) {
            return (
                <PropertyListingColumnEmpty>
                    <p>{Translate.t('PropertyReport.PropertyReportError4')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError5')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError6')}</p>
                </PropertyListingColumnEmpty>
            );
        }

        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        // marginTop: '20px',
                    }}
                >
                    <Paginator
                        currentIndex={paginationNumber}
                        data={paginationData}
                        onNext={nextPaginationNumber}
                        onPrev={prevPaginationNumber}
                        onFirst={firstPaginationNumber}
                        onLast={lastPaginationNumber}
                        onClickIndex={onClickPaginationNumber}
                    />
                </div>
                <PropertyListingTable>
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableHeader>
                                    {Translate.t('PropertyReport.PropertyReportHeaderName')}
                                </TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderUnitNumber')}</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderStatus')}</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderSize')}</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderOwner')}</TableHeader>
                                <TableHeader>Reno Package Name</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderActivePackages')}</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderWallet')}</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderTenancyStatus')}</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderLocation')}</TableHeader>
                                <TableHeader>{Translate.t('PropertyReport.PropertyReportHeaderActions')}</TableHeader>
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {
                                propertyList.map((property, propertyIndex) => {
                                    const {
                                        propertyId,
                                        name,
                                        images,
                                        size,
                                        status,
                                        location,
                                        owner,
                                        unitNo,
                                        vacantPosessionPackageStatus,
                                        defectInspectionPackageStatus,
                                        designRenovationPackageStatus,
                                        propertyManagementPackageStatus,
                                        vacantManagementPackageStatus,
                                        leaseSalePackageStatus,
                                        walletAmount,
                                        tenancyStatus,
                                        renoPackageName,
                                    } = property;
                                    const { city = '', country = '' } = location;
                                    const displayedImage = images[0] ? images[0].url[0] : PropertyImage;

                                    const uniqueKey = propertyIndex + name;

                                    const packagesStatus = [
                                        vacantPosessionPackageStatus,
                                        defectInspectionPackageStatus,
                                        designRenovationPackageStatus,
                                        propertyManagementPackageStatus,
                                        vacantManagementPackageStatus,
                                        leaseSalePackageStatus,
                                    ];
                                    return (

                                        <TableRow key={uniqueKey}>
                                            <TableHeader>
                                                <TableRowHead>
                                                    <p style={{ textAlign: 'left' }}>{name}</p>
                                                    <PropertyListingsColumnWithImage onClick={() => NavActions.navToPropertyDetails(propertyId)}>
                                                        <img
                                                            src={displayedImage}
                                                            alt='properties images'
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = PropertyImage;
                                                            }}
                                                        />
                                                    </PropertyListingsColumnWithImage>
                                                </TableRowHead>
                                            </TableHeader>
                                            <TableData>{unitNo}</TableData>
                                            <TableData>{checkPropertyStatus(status)}</TableData>
                                            <TableData>{`${size} ft`}</TableData>
                                            <TableData>{owner !== 'undefined undefined' ? owner : 'none'}</TableData>
                                            <TableData>{renoPackageName ?? 'N/A'}</TableData>
                                            <TableData>
                                                <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', alignItems: 'center' }}>
                                                    {renderPackagesStatus(packagesStatus, propertyId)}
                                                </div>
                                            </TableData>
                                            <TableData>{`RM ${walletAmount}`}</TableData>
                                            <TableData>{TenancyStatus[tenancyStatus]}</TableData>
                                            <TableData>{`${city} ${country}`}</TableData>
                                            <TableData>{renderActionButtons(propertyId)}</TableData>
                                        </TableRow>

                                    );
                                })
                            }
                        </TableBody>
                    </TableContainer>

                </PropertyListingTable>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Paginator
                        currentIndex={paginationNumber}
                        data={paginationData}
                        onNext={nextPaginationNumber}
                        onPrev={prevPaginationNumber}
                        onFirst={firstPaginationNumber}
                        onLast={lastPaginationNumber}
                        onClickIndex={onClickPaginationNumber}
                    />
                </div>
            </div>
        );
    };

    const renderPropertyGroupList = () => {
        if (getPropertyGroupAttempt || deletePropertyGroupAttempt || duplicatePropertyGroupAttempt) {
            return (
                <LoadingContainer>
                    <Bars
                        color={Colors.primary}
                        height={260}
                        width={260}
                    />
                </LoadingContainer>
            );
        }

        if (!propertyGroup.data) {
            return (
                <PropertyListingColumnEmpty>
                    <p>{Translate.t('PropertyReport.PropertyReportError1')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError2')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError3')}</p>
                </PropertyListingColumnEmpty>
            );
        }

        if (getPropertyGroupError) {
            return (
                <PropertyListingColumnEmpty>
                    <p>{Translate.t('PropertyReport.PropertyReportError4')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError5')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError6')}</p>
                </PropertyListingColumnEmpty>
            );
        }

        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        // marginTop: '20px',
                    }}
                >
                    <Paginator
                        currentIndex={paginationNumber}
                        data={paginationData}
                        onNext={nextPaginationNumber}
                        onPrev={prevPaginationNumber}
                        onFirst={firstPaginationNumber}
                        onLast={lastPaginationNumber}
                        onClickIndex={onClickPaginationNumber}
                    />
                </div>
                <PropertyListingTable>
                    <PropertyListingHeader propertyGroup>
                        <h4>{Translate.t('PropertyReport.PropertyReportHeaderName')}</h4>
                        <div />
                        <h4>{Translate.t('PropertyReport.PropertyReportHeaderType')}</h4>
                        <h4>{Translate.t('PropertyReport.PropertyReportHeaderLocation')}</h4>
                        <h4>{Translate.t('PropertyReport.PropertyReportHeaderActions')}</h4>
                    </PropertyListingHeader>

                    {propertyGroupData.map((property, propertyIndex) => {
                        const { _id, name, images, location, details } = property;
                        const uniqueKey = propertyIndex + name;
                        const displayedImage = images[0] ? images[0].url[0] : PropertyImage;

                        return (
                            <PropertyListingRowButton
                                key={uniqueKey}
                                propertyGroup
                            >
                                <NameContainer>
                                    {name}
                                </NameContainer>
                                <PropertyListingsColumnWithImage
                                    onClick={() => NavActions.navToPropertyGroupDetails(_id)}
                                    propertyGroup
                                >
                                    <img
                                        src={displayedImage}
                                        alt='properties images'
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = PropertyImage;
                                        }}
                                    />
                                </PropertyListingsColumnWithImage>
                                <p>{details ? checkPropertyType(details.type) : 'not set'}</p>
                                <p>
                                    {location ? `${location.city} ${location.country}` : 'not set'}
                                </p>
                                {renderPropertyGroupActionButtons(_id)}
                            </PropertyListingRowButton>
                        );
                    })}
                </PropertyListingTable>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Paginator
                        currentIndex={paginationNumber}
                        data={paginationData}
                        onNext={nextPaginationNumber}
                        onPrev={prevPaginationNumber}
                        onFirst={firstPaginationNumber}
                        onLast={lastPaginationNumber}
                        onClickIndex={onClickPaginationNumber}
                    />
                </div>
            </div>
        );
    };

    const renderButtonGroup = () => {
        if (isPropertyGroup) {
            return (
                <ToggleButtonContainer>
                    <Button
                        label='Create Property Group'
                        size='sm'
                        onClick={() => setPropertyCreateModal(true)}
                    />
                    <Button
                        label='Property List'
                        size='sm'
                        onClick={() => handleToggleButton(false)}
                    />
                </ToggleButtonContainer>
            );
        }
        return (
            <ToggleButtonContainer>
                <Button
                    label='Property Group'
                    size='sm'
                    onClick={() => handleToggleButton(true)}
                    style={{ padding: '10px' }}
                />
            </ToggleButtonContainer>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <PropertyReportContainer>
                    <TitleContainer>
                        <Title>
                            {Translate.t('PropertyReport.PropertyReportTitle')}
                        </Title>
                        <ExportButton
                            loading={getPropertiesReportExportAttempt}
                            label='Export properties report'
                            size='sm'
                            onClick={() => getPropertiesReportExport({
                                location: searchKeyword,
                                status: statusOpt,
                                type: buildingTypesOpt,
                                sortBy: sortByOpt,
                            })}
                        />
                    </TitleContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <StyledSearchBar
                            title={Translate.t('PropertyReport.PropertyReportProperty')}
                            value={searchKeyword}
                            onChangeText={onSearchProperty}
                        />
                        {renderButtonGroup()}
                    </div>
                    <PropertyFilterContainer>
                        <FilterContainer style={{ zIndex: '99' }}>
                            <FilterTitle>
                                {Translate.t('PropertyReport.PropertyReportFilterTitle')}
                            </FilterTitle>
                            <FilterPropContainer>
                                <DropdownInput
                                    style={{ marginRight: '15px' }}
                                    label={Translate.t('PropertyReport.PropertyReportFilterBuildingTypes')}
                                    value={buildingTypesOpt}
                                    options={propertyTypeDropdownData}
                                    onChangeItem={setBuildingTypesOpt}
                                />
                                <DropdownInput
                                    style={{ marginRight: '15px' }}
                                    label={Translate.t('PropertyReport.PropertyReportFilterStatus')}
                                    value={statusOpt}
                                    options={propertyStatusDropdownData}
                                    onChangeItem={setStatusOpt}
                                />
                                <DropdownInput
                                    style={{ marginRight: '15px' }}
                                    label={Translate.t('PropertyReport.PropertyReportFilterGroupPropertiesWithCount')}
                                    value={searchKeyword}
                                    options={groupPropertiesWithCountList}
                                    onChangeItem={handleGroupPropertyWithCount}
                                />
                                <DropdownMultiChoiceInput
                                    options={selectedActivePackages}
                                    onSelectItem={onSelectActivePackage}
                                />
                            </FilterPropContainer>
                        </FilterContainer>
                        <SortAndStarredContainer>
                            <DropdownInput
                                style={{ zIndex: '99' }}
                                label={Translate.t('PropertyReport.PropertyReportFilterProp')}
                                value={sortByOpt}
                                options={propertySortByDropdownData}
                                onChangeItem={setSortByOpt}
                            />
                        </SortAndStarredContainer>
                    </PropertyFilterContainer>
                    <PropertyContentContainer>
                        <PropertyListingContainer>
                            {isPropertyGroup ? renderPropertyGroupList() : renderPropertyList()}
                        </PropertyListingContainer>
                    </PropertyContentContainer>
                </PropertyReportContainer>
                <PropertyCreateModal isPropertyGroup />
            </MainContainer>
        </>
    );
};

// Table styled components

const TableContainer = styled.table`
    width: 100%;
    border: none;
    border-collapse: separate;
    border-spacing: 0;

    & > td, & > th {
        border: 1px solid black;
    }

`;

const TableRow = styled.tr`
    border-spacing: 1rem;

    
    & > td { 
        min-width: 200px;
        padding-inline: 0.5rem;
        width: fit-content;
    }
`;

const TableHeader = styled.th`
    background-color: #FFF3E9;
    padding-block: 1rem;
`;

const TableData = styled.td`
    text-align: center;
`;

const TableRowHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
`;

const TableHead = styled.thead`

    & > tr > th {

        position: sticky;

        :nth-child(1) {
            left: 0;
            z-index: 2;
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            background-color: #FFD3AE;
        }

        :nth-last-child(1) {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }

        :nth-child(2) {
            left: 290px;
            z-index: 2;
            background-color: #FFD3AE;
            border-right:1px solid #D3D3D3;
        }

        :not(:nth-child(-n + 2)) {
            left: 0;
        }
    }

`;

const TableBody = styled.tbody`

 & > tr:nth-child(odd) > * {
    background-color: ${Colors.greyLightest};
 }

 & > tr:nth-child(even) > * {
    background-color: white;
 }

& > tr > th:first-child {
    border-radius: 0;
    position: sticky;
    left: 0;
    z-index: 3;
    padding-inline: 0.5rem;
    min-width: 290px;
    border-bottom:1px solid #D3D3D3;
}

& > tr > td {
    background-color: white;
    position: sticky;

    border-bottom:1px solid #D3D3D3;

    :nth-child(2) {
        left: 290px;
        z-index: 3;
        border-right:1px solid #D3D3D3;
    }

    :not(:nth-child(2)) {
        left: 0;
        z-index: 1;
    }
}
`;

// Table styled components

const ButtonMainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const PropertyReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 120px;
    color: ${Colors.black};
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-block: 20px;
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
`;

const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 40px;
`;

// Filter
const PropertyFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    background: ${Colors.white};
`;

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const FilterTitle = styled.p`
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 16px;
`;

const FilterPropContainer = styled.div`
    display: flex;

    > select:not(:last-child){
        margin-right: 10px;
    }
`;

const SortAndStarredContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
`;

const PropertyContentContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

const PropertyListingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    /* TEMP */
    /* width: 1120px; */
    `;

const PropertyListingTable = styled.div`
    width: 100%;
    margin: 20px 0 20px 0;
    overflow: auto;

    padding: 10px;

    > div:not(:last-child){
        margin-bottom: 8px;
    }

    &::-webkit-scrollbar {
    width: 1.25em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }  

  &:hover {
    &::-webkit-scrollbar-thumb {
        background-color: #F7C48E;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #F2B47E;
  }
`;

const PropertyListingHeader = styled.div<{ propertyGroup?: boolean }>`
    display: grid;
    grid-template-columns: ${props => (props.propertyGroup ? 'repeat(5, 1fr)' : 'repeat(11, 1fr)')};
    align-items: center;
    padding: 16px 9px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    background: ${Colors.white};

    > h4{
        margin-inline: 10px;
        text-align: center;
    }
`;

const PropertyListingRowButton = styled.button<{ propertyGroup?: boolean }>`
    display: grid;
    grid-template-columns: ${props => (props.propertyGroup ? 'repeat(5, 1fr)' : 'repeat(11, 1fr)')};
    align-items: center;
    cursor: pointer;
    width: 100%;
    border: none;
    padding-inline: 8px;
    padding-block: 15px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;

    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    p:nth-child(5){
        text-transform: none;
    }

    > p {
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
        margin-inline: 10px;
    }
`;

const NameContainer = styled.h4`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin-inline: 10px;
    color: ${Colors.black};

`;

const PropertyListingsColumnWithImage = styled.div<{propertyGroup?:boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 12px;
    padding: 5px;
  }
`;

const PropertyListingColumnEmpty = styled.div`
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;

  color: ${Colors.greyDark};
  background: ${Colors.primaryLightest};

  > p {
    margin: 0px;
    padding: 0px;
  }

  > p:not(:last-child) {
    margin-bottom: 3px;
  }

  > p:first-child{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 14px;
  }

`;

const ToggleButtonContainer = styled.div`
    display: flex;
    max-width: 40%;
    gap: 20px;
    margin-bottom: 40px;
`;

const ExportButton = styled(Button)`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.primary};
    border-radius: 8px;
    border: none;
    padding-inline: 20px;
    cursor: pointer;
    color: white;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 580px;
`;

const StyledIconButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all .2s;

    &:not(:disabled):active {
        transform: translateY(-3px);
    }

    &:disabled{
        cursor: not-allowed;
    }
`;

const Icons = styled(SVG) <{ width: string, height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
`;

const ActivePackagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetAllAttempt(state),
    error: Selectors.getPropertyGetAllError(state),
    propertyReport: Selectors.getPropertyPropertyReport(state),
    getSearchedPropertyLoading: Selectors.getPropertyGetSearchedPropertyAttempting(state),
    getSearchedPropertyError: Selectors.getPropertyGetSearchedPropertyError(state),
    searchedProperty: Selectors.getPropertySearchProperty(state),

    duplicatePropertyLoading: Selectors.setPropertySetDuplicatePropertyAttempting(state),
    duplicatePropertyError: Selectors.setPropertySetDuplicatePropertyError(state),
    duplicatePropertySuccess: Selectors.setPropertySetDuplicatePropertySuccess(state),

    getPropertyGroupAttempt: Selectors.getPropertyGetPropertyGroupAttempting(state),
    getPropertyGroupError: Selectors.getPropertyGetPropertyGroupError(state),
    propertyGroup: Selectors.getPropertyPropertyGroup(state),

    deletePropertyGroupAttempt: Selectors.getPropertyDeletePropertyGroupAttempting(state),
    deletePropertyGroupError: Selectors.getPropertyDeletePropertyGroupError(state),

    isPropertyGroup: Selectors.getPropertyIsPropertyGroupList(state),

    duplicatePropertyGroupAttempt: Selectors.getPropertyDuplicatePropertyGroupAttempt(state),
    duplicatePropertyGroupError: Selectors.getPropertyDuplicatePropertyGroupError(state),
    getPropertiesReportExportAttempt: Selectors.getPropertiesReportExportAttempt(state),

    groupPropertiesWithCount: Selectors.groupPropertiesWithCount(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProperty: (data: IGetProperty) =>
        dispatch(Actions.propertyGetAllAttempt(data)),
    clearPropertyReport: () => dispatch(Actions.clearPropertyReport()),
    getSearchedProperty: (search: string) => dispatch(Actions.getSearchedPropertyAttempt(search)),
    clearSearchedProperty: () => dispatch(Actions.clearSearchedProperty()),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    setDuplicateProperty: (propertyId: string) =>
        dispatch(Actions.propertySetDuplicatePropertyAttempt({ propertyId })),

    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),
    setPropertyCreateModal: (state: boolean) => dispatch(Actions.propertyCreatePropertyModal(state)),
    getPropertyGroup: (data: IGetPropertyGroup) => dispatch(Actions.getPropertyGroupAttempt(data)),
    deletePropertyGroup: (propertyGroupId: string) => dispatch(Actions.deletePropertyGroupAttempt({ propertyGroupId })),
    setIsPropertyGroup: (state: boolean) => dispatch(Actions.setIsPropertyGroupList(state)),
    duplicatePropertyGroup: (propertyGroupId: string) => dispatch(Actions.duplicatePropertyGroupAttempt({ propertyGroupId })),
    getPropertiesReportExport: (filter: Omit<IGetProperty, 'index'>) => dispatch(Actions.getPropertiesReportExportAttempt(filter)),
    getGroupPropertiesWithCount: () => dispatch(Actions.groupPropertiesWithCountAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyReport);
