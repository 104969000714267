import { ApiResponse } from 'apisauce';
import { GatewayResponse } from 'api/types/types';

import { IPropertyGroupDetails, IPropertyOwners, IServerPropertyReport, IPropertyWallet } from 'entities/property';

import { GetPendingPaymentResponse, IProperty, AdminGetPropertiesResponse, GetPropertyGroupResponse, IExportPropertiesResponse, GroupPropertiesWithCountResponse } from '@dm/types';

import {
    GetPropertyParams,
    IPropertyGateway,
    GetPropertyDetailsParams,
    SetPackagePricingParams,
    GetPendingPaymentsParams,
    ApproveRejectPaymentParams,
    GetSearchedPropertyParams,
    PropertySetDescriptionParams,
    PropertySetPropertyAmenitiesParams,
    PropertyDetailsParams,
    PropertyUserParams,
    PropertySetPropertyLocationParams,
    PropertyCreatePropertyParams,
    GetPropertyUploadUrlParams,
    UploadPropertyDocumentParams,
    ImageDeleteParams,
    GetPropertyDetailsDataParams,
    SetPropertyTenancyStatusParams,
    SetPropertyDuplicateParams,
    UpdatePropertyPackagesPriceParams,
    SetGoogleDrivePathParams,
    CreatePropertyGroupParams,
    GetPropertyGroupParams,
    DeletePropertyGroupParams,
    EditPropertyGroupDetails,
    CreatePropertyParams,
    EditPropertyDetails,
    EditPropertyPackagesDetailsParams,
    GetPropertiesReportExportParams,
} from './PropertyBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class PropertyGateway extends IPropertyGateway {
    async getProperty(params: GetPropertyParams): GatewayResponse<IServerPropertyReport> {
        const response: ApiResponse<IServerPropertyReport> = await this.api.get('/admin/report/properties', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyDetails(params: GetPropertyDetailsParams): GatewayResponse<IProperty> {
        const { id } = params;

        const response: ApiResponse<IProperty> = await this.api.get(`/admin/report/properties/${id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPackagePricing(params: SetPackagePricingParams): GatewayResponse<null> {
        const { data, authToken } = params;
        const response: ApiResponse<null> = await this.api.put('/admin/property/reviewProperty', data, getHeaders(authToken));
        return this.process(response);
    }

    async getPendingPayments(params: GetPendingPaymentsParams): GatewayResponse<GetPendingPaymentResponse> {
        const response: ApiResponse<GetPendingPaymentResponse> = await this.api.get('/admin/report/payments', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async approveRejectPayment(params: ApproveRejectPaymentParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('/admin/payment/update', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getSearchedProperty(params: GetSearchedPropertyParams): GatewayResponse<AdminGetPropertiesResponse[]> {
        const response: ApiResponse<AdminGetPropertiesResponse[]> = await this.api.get(`/admin/property/search?search=${params.search}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setDescription(params: PropertySetDescriptionParams): GatewayResponse<string> {
        const data = {
            id: params.id,
            description: params.description,
        };
        const response: ApiResponse<string> = await this.api.put('/admin/property/propertyDescription', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyAmenities(params: PropertySetPropertyAmenitiesParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/admin/property/propertyAmenities', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyDetails(params: PropertyDetailsParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/admin/property/propertyDetails', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyUser(params: PropertyUserParams): GatewayResponse<string> {
        const data = {
            propertyId: params.propertyId,
            userId: params.userId,
        };

        const response: ApiResponse<string> = await this.api.put('/admin/property/setUser', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyLocation(params: PropertySetPropertyLocationParams): GatewayResponse<string> {
        const response: ApiResponse<string> = await this.api.put('/admin/property/setLocation', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setCreateProperty(params: PropertyCreatePropertyParams): GatewayResponse<{ id: string}> {
        const data = {
            name: params.name,
        };
        const response: ApiResponse<{id: string}> = await this.api.post('/admin/property', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getSearchedOwner(params: GetSearchedPropertyParams): GatewayResponse<IPropertyOwners | null> {
        const response: ApiResponse<IPropertyOwners | null> = await this.api.get(`/admin/report/owners?query=${params.search}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyUploadUrl(params: GetPropertyUploadUrlParams): GatewayResponse<string> {
        const { category, id, name, extension } = params;
        const response: ApiResponse<string> = await this.api.get('/upload/url', { category, id, name, extension }, getHeaders(params.authToken));
        return this.process(response);
    }

    async uploadPropertyImage(params: UploadPropertyDocumentParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put(params.url, params.doc);
        return this.process(response);
    }

    async setPropertyDeleteImage(params: ImageDeleteParams): GatewayResponse<string> {
        const { category, id, name, fileName } = params;
        const response: ApiResponse<string> = await this.api.delete('/upload', { category, name, id, fileName }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyDetailsData(params: GetPropertyDetailsDataParams): GatewayResponse<IProperty | null> {
        const { id } = params;
        const response: ApiResponse<IProperty | null> = await this.api.get(`/admin/report/properties/${id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyTenancyStatus(params: SetPropertyTenancyStatusParams): GatewayResponse<string | null> {
        const { propertyId, tenancyStatus } = params;

        const response: ApiResponse<string | null> = await this.api.put('/admin/property/tenancyStatus', { propertyId, tenancyStatus }, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyDuplicate(params: SetPropertyDuplicateParams): GatewayResponse<string | null> {
        const { propertyId } = params;
        const response: ApiResponse<string | null> = await this.api.put(`/admin/property/duplicate?propertyId=${propertyId}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyPackagesPrice(params: UpdatePropertyPackagesPriceParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/admin/property/editPackagePricing', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setGoogleDrivePath(params: SetGoogleDrivePathParams): GatewayResponse<IProperty | null> {
        const { propertyId, packageType, googleDrivePath } = params;
        const response: ApiResponse<IProperty | null> = await this.api.patch('/admin/package/setGoogleDrivePath', { propertyId, packageType, googleDrivePath }, getHeaders(params.authToken));
        return this.process(response);
    }

    async createPropertyGroup(params: CreatePropertyGroupParams): GatewayResponse<string | null> {
        const data = {
            name: params.name,
        };
        const response: ApiResponse<string | null> = await this.api.post('/admin/property/createPropertyGroup', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyGroup(params: GetPropertyGroupParams): GatewayResponse<GetPropertyGroupResponse> {
        const { index, propertyGroupName } = params;
        const response: ApiResponse<GetPropertyGroupResponse> = await this.api.get('/admin/property/getPropertyGroup', { index, propertyGroupName }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyGroupDetails(params: GetPropertyGroupParams): GatewayResponse<IPropertyGroupDetails> {
        const { propertyGroupId } = params;
        const response: ApiResponse<IPropertyGroupDetails> = await this.api.get('/admin/property/getPropertyGroup', { propertyGroupId }, getHeaders(params.authToken));
        return this.process(response);
    }

    async editPropertyGroupDetails(params: EditPropertyGroupDetails): GatewayResponse<string | null> {
        const { propertyGroupId, details, location, facilities, description } = params;

        const response: ApiResponse<string | null> = await this.api.post('/admin/property/createPropertyGroup', { propertyGroupId, details, location, facilities, description }, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyGroupDuplicate(params: DeletePropertyGroupParams): GatewayResponse<string | null> {
        const { propertyGroupId } = params;
        const response: ApiResponse<string | null> = await this.api.post('/admin/property/createFromPropertyGroup', { propertyGroupId }, getHeaders(params.authToken));
        return this.process(response);
    }

    async deletePropertyGroup(params: DeletePropertyGroupParams): GatewayResponse<string | null> {
        const { propertyGroupId } = params;
        const response: ApiResponse<string | null> = await this.api.post('/admin/property/deletePropertyGroup', { propertyGroupId }, getHeaders(params.authToken));
        return this.process(response);
    }

    async createProperty(params: CreatePropertyParams): GatewayResponse<string | null> {
        const data = {
            name: params.name,
        };
        const response: ApiResponse<string | null> = await this.api.post('/property/createUpdateProperty', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async editPropertyDetails(params: EditPropertyDetails): GatewayResponse<string | null> {
        const { propertyId, details, location, amenities, description, unitNo } = params;

        const response: ApiResponse<string | null> = await this.api.post('/property/createUpdateProperty', { propertyId, details, location, amenities, description, unitNo }, getHeaders(params.authToken));
        return this.process(response);
    }

    async editPropertyPackagesDetails(params: EditPropertyPackagesDetailsParams): GatewayResponse<string | null> {
        const { propertyId, vacantPossession, defectInspection, designRenovation, propertyManagement, vacantManagement } = params;

        const response: ApiResponse<string | null> = await this.api.put('/admin/property/editPackageDetails', { propertyId, vacantPossession, defectInspection, designRenovation, propertyManagement, vacantManagement }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertiesReportExport(params: GetPropertiesReportExportParams): GatewayResponse<IExportPropertiesResponse[]> {
        const { filter } = params;
        const { location, status, type } = filter;
        const response: ApiResponse<IExportPropertiesResponse[]> = await this.api.get('/admin/report/exportProperties', { location, status, type }, getHeaders(params.authToken));
        return this.process(response);
    }

    async groupPropertiesWithCount(params: { authToken: string}): GatewayResponse<GroupPropertiesWithCountResponse[]> {
        const response: ApiResponse<GroupPropertiesWithCountResponse[]> = await this.api.get('/admin/report/groupPropertiesWithCount', {}, getHeaders(params.authToken));
        return this.process(response);
    }
}
