import React from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface DateInputProps {
  label: string;
  date: Date;
  disabled?: boolean;
  required?: boolean;
  onChangeDate: (date: Date) => void;
}

const DateInput = ({ label, date, disabled, onChangeDate, required }: DateInputProps):JSX.Element => {
    return (
        <FormGroup>
            <Label>{label}</Label>
            <Input selected={date} onChange={onChangeDate} dateFormat='dd/MM/yyyy' disabled={disabled} required={required} />
        </FormGroup>
    );
};

DateInput.defaultProps = {
    disabled: false,
    required: false,
};

const FormGroup = styled.div`
  margin: 2px 0;
  color: #000;
  overflow: hidden;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
`;

const Input = styled(ReactDatePicker)`
  display: block;
  width: 100%;
  margin-top: 6px;
  padding: 7px 10px 10px 0;
  background: none;
  color: #696969;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #a2a2a2;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default DateInput;
