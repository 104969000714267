import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchGetEventTemplate(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/getEventTemplateAttempt', handleGetEventTemplate, api);
}

function* handleGetEventTemplate(api: PackagesGateway, data: PayloadAction<number>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getEventTemplate], {
        category: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.getEventTemplateFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.getEventTemplateSuccess(response.data));
    } else {
        yield put(Actions.getEventTemplateFailure(response.message));
    }
}
