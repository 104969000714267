import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionsFilterPayload, UiReduxState } from './types';

const initialState: UiReduxState = {
    transactionsFilter: {
        search: '',
        status: 9999,
        sort: 2,
        transactionType: 9999,
        index: 1,
    },
    modals: {
        editTransactionModal: false,
        editRecurringTransaction: false,
        createOwner: false,
    },
    selectedLanguage: '',
    selectedTabView: '',
    modulesCreateModal: false,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.selectedLanguage = action.payload;
        },
        setTabView: (state, action: PayloadAction<string>) => {
            state.selectedTabView = action.payload;
        },
        setTransactionsFilter: (state, action: TransactionsFilterPayload) => {
            state.transactionsFilter = action.payload;
        },
        setModulesCreateModal: (state, action: PayloadAction<boolean>) => {
            state.modulesCreateModal = action.payload;
        },
        setEditTransactionModal: (state, action: PayloadAction<boolean>) => {
            state.modals.editTransactionModal = action.payload;
        },
        setEditRecurringTransactionModal: (state, action: PayloadAction<boolean>) => {
            state.modals.editRecurringTransaction = action.payload;
        },
        setCreateOwnerModal: (state, action: PayloadAction<boolean>) => {
            state.modals.createOwner = action.payload;
        },
        reloadPage: () => {
            window.location.reload();
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: uiSlice.actions,
    reducers: uiSlice.reducer,
};
