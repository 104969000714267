import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IDefectInspection, IGetEventTemplate, IPaginatedAdminPropertyManagementWithdrawalResponse, IPaginatedViewAllRecurringPMTransactionResponse, IPropertyManagement, IVacantManagement, IVacantPossession,
    PaginatedViewAllPMTransactionsResponse, PropertyManagementTransactionResponse } from '@dm/types';
import { ILeaseSale } from 'entities/packages';
import { IPropertyWallet } from 'entities/property';
import { IPackagesGateway, CreateEventParams, GetEventTemplateParams, PackagesGetILeaseSaleParams, GetPackagesUploadUrlParams, UploadPackagesDocumentParams,
    PackagesGetDataSortParams, PackagesGetDataTransactionSortParams, PackagesGetAllTransactionDataSortParams, UpdateTransactionStatusParams,
    CreateTransactionStatusParams, GetTransactionsReportExportParams, IGetTransactionsReportExport, GetPropertyWalletParams, EditRecurringTransactionParams,
    EditTransactionParams, GetPropertyManagementWithdrawalParams, EditPropertyManagementWithdrawalParams,
    UpdateProgressDefectInspectionParams } from './PackagesBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class PackagesGateway extends IPackagesGateway {
    async createEvent(params: CreateEventParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('admin/package/createEvent', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getEventTemplate(params: GetEventTemplateParams): GatewayResponse<IGetEventTemplate[]> {
        const response: ApiResponse<IGetEventTemplate[]> = await this.api.get(`/admin/package/eventTemplate?category=${params.category}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesLeaseSaleSort(params: PackagesGetILeaseSaleParams): GatewayResponse<ILeaseSale | null> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<ILeaseSale | null> = await this.api.get(`/admin/package/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesUploadUrl(params: GetPackagesUploadUrlParams): GatewayResponse<string> {
        const { category, id, name, extension } = params;
        const response: ApiResponse<string> = await this.api.get('/upload/url', { category, id, name, extension }, getHeaders(params.authToken));
        return this.process(response);
    }

    async uploadPackagesDocument(params: UploadPackagesDocumentParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put(params.url, params.doc);
        return this.process(response);
    }

    async getPackagesVacantPossessionSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantPossession | null> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IVacantPossession | null > = await this.api.get(`/admin/package/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesDefectInspectionSort(params: PackagesGetDataSortParams): GatewayResponse<IDefectInspection | null> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IDefectInspection | null > = await this.api.get(`/admin/package/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesVacantManagementSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantManagement | null> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IVacantManagement | null > = await this.api.get(`/admin/package/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesPropertyManagementSort(params: PackagesGetDataSortParams): GatewayResponse<IPropertyManagement | null> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IPropertyManagement | null > = await this.api.get(`/admin/package/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesPropertyManagementTransactionSort(params: PackagesGetDataTransactionSortParams): GatewayResponse<PropertyManagementTransactionResponse[] | null> {
        const data = {
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<PropertyManagementTransactionResponse[] | null> = await this.api.get(`/admin/package/adminViewTransaction?propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesViewAllTransactionsResponse(params: PackagesGetAllTransactionDataSortParams): GatewayResponse<PaginatedViewAllPMTransactionsResponse | null> {
        const data = {
            index: params.index,
            sort: params.sort,
            search: params.search,
            status: params.status,
            transactionType: params.transactionType,
        };
        let PMStatus;
        let PMType;

        if (data.status === 9999) {
            PMStatus = '&status=';
        } else {
            PMStatus = `&status=${data.status}`;
        }
        if (data.transactionType === 9999) {
            PMType = '&transactionType=';
        } else {
            PMType = `&transactionType=${data.transactionType}`;
        }
        const response: ApiResponse<PaginatedViewAllPMTransactionsResponse | null> = await this.api.get(`/admin/package/adminViewAllPMTransaction?search=${data.search}${PMStatus}&sort=${data.sort}${PMType}&index=${data.index}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesViewAllRecurringTransactionsResponse(params: PackagesGetAllTransactionDataSortParams): GatewayResponse<IPaginatedViewAllRecurringPMTransactionResponse | null > {
        const data = {
            index: params.index,
            sort: params.sort,
            search: params.search,
            status: params.status,
            transactionType: params.transactionType,
        };
        let PMStatus;
        let PMType;

        if (data.status === 9999) {
            PMStatus = '&status=';
        } else {
            PMStatus = `&status=${data.status}`;
        }
        if (data.transactionType === 9999) {
            PMType = '&transactionType=';
        } else {
            PMType = `&transactionType=${data.transactionType}`;
        }

        const response: ApiResponse<IPaginatedViewAllRecurringPMTransactionResponse | null> = await this.api.get(`/admin/package/adminViewAllRecurringPMTransaction?search=${data.search}${PMStatus}&sort=${data.sort}${PMType}&index=${data.index}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPackagesUpdateTransactionStatusResponse(params: UpdateTransactionStatusParams): GatewayResponse<string | null> {
        const data = {
            propertyId: params.propertyId,
            transactionId: params.transactionId,
            status: params.status,
        };
        const response: ApiResponse<string | null> = await this.api.put('/admin/package/adminUpdatePMTransaction', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPackagesEditTransaction(params: EditTransactionParams): GatewayResponse<string | null> {
        const data = {
            propertyId: params.propertyId,
            transactionId: params.transactionId,
            description: params.description,
            transactionDate: params.transactionDate,
        };
        const response: ApiResponse<string | null> = await this.api.post('/admin/package/editPMTransaction', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPackagesEditRecurringTransaction(params: EditRecurringTransactionParams): GatewayResponse<string | null> {
        const data = {
            id: params.id,
            amount: params.amount,
            description: params.description,
            transactionType: params.transactionType,
            startDate: params.startDate,
            endDate: params.endDate,
            interval: params.interval,
            frequency: params.frequency,
        };

        const response: ApiResponse<string | null> = await this.api.post('/admin/package/editRecurringPMTransaction', { data }, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPackagesPackagesCreatePMTransaction(params: CreateTransactionStatusParams): GatewayResponse<{ id: string } | null> {
        let data;
        if (params.isRecurring) {
            data = {
                propertyId: params.propertyId,
                amount: params.amount,
                description: params.description,
                transactionType: params.transactionType,
                isRecurring: params.isRecurring,
                startDate: params.startDate,
                endDate: params.endDate,
                interval: params.interval,
                frequency: params.frequency,
            };
        } else {
            data = {
                propertyId: params.propertyId,
                amount: params.amount,
                description: params.description,
                transactionDate: params.transactionDate,
                transactionType: params.transactionType,
                isRecurring: params.isRecurring,
            };
        }
        const response: ApiResponse<{ id: string }> = await this.api.post('/admin/package/createPMTransaction', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getTransactionsReportExport(params: GetTransactionsReportExportParams): GatewayResponse<IGetTransactionsReportExport[]> {
        const { authToken, propertyId } = params;
        const response: ApiResponse<IGetTransactionsReportExport[]> = await this.api.get('/admin/package/exportTransactionDetails', { propertyId }, getHeaders(authToken));
        return this.process(response);
    }

    async getPropertyWallet(params: GetPropertyWalletParams): GatewayResponse<IPropertyWallet | null> {
        const { propertyId } = params;
        const response: ApiResponse<IPropertyWallet | null> = await this.api.get('/property/getWallet', { propertyId }, getHeaders(params.authToken));
        return this.process(response);
    }

    async updateProgressDefectInspection(params: UpdateProgressDefectInspectionParams): GatewayResponse<string | null> {
        const { authToken, ...rest } = params;
        const response: ApiResponse<string | null> = await this.api.post(
            '/admin/package/updateProgressDefectInspection',
            { ...rest },
            getHeaders(authToken),
        );
        return this.process(response);
    }

    async getPropertyManagementWithdrawal(params: GetPropertyManagementWithdrawalParams): GatewayResponse<IPaginatedAdminPropertyManagementWithdrawalResponse> {
        const { authToken, index, includePreviousOwner, search, status, sort } = params;

        const data = {
            index,
            includePreviousOwner,
            search,
            status,
            sort,
        };

        const response: ApiResponse<IPaginatedAdminPropertyManagementWithdrawalResponse> = await this.api.get('/admin/package/propertyManagement/withdrawal', data, getHeaders(authToken));

        return this.process(response);
    }

    async editPropertyManagementWithdrawal(params: EditPropertyManagementWithdrawalParams): GatewayResponse<string> {
        const { authToken, withdrawalId, status } = params;

        const data = {
            withdrawalId,
            status,
        };

        const response: ApiResponse<string> = await this.api.patch('/admin/package/propertyManagement/withdrawal', data, getHeaders(authToken));

        return this.process(response);
    }
}
