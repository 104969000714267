import React, { useState, useEffect, useRef } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import Modal from 'components/Modal';
import Translate from 'lib/translate';
import throttle from 'lodash.throttle';

import { Bars } from 'react-loader-spinner';
import { Colors } from '@dm/bigfish';
import { IGetProperty, IServerPropertyReport } from 'entities/property';
import { GetPropertiesResponse } from '@dm/types';
import { IGetTransactionsReportExportPayload } from 'redux/slices/payment/types';

import PropertyImage from 'assets/images/house1.png';
import StyledComponent from '../styles/ExportTransactionModalStyle';

// ! interface zone
// * props interface
export interface ExportTransactionModalProps {
    isExportModalOpen: boolean;
    setIsExportModalOpen: (state: boolean) => void;

    propertyLoading: boolean;
    propertyError: string;
    propertyReport: IServerPropertyReport;
    getProperty: (data: IGetProperty) => void;
    clearPropertyReport: () => void;
    getTransactionsReportAttempt: boolean;
    getTransactionsReport: (params: IGetTransactionsReportExportPayload) => void;
}

// ! JSX function component
const ExportTransactionModal = (props: ExportTransactionModalProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        isExportModalOpen,
        setIsExportModalOpen,
        propertyLoading,
        propertyError,
        propertyReport,
        getProperty,
        clearPropertyReport,
        getTransactionsReportAttempt,
        getTransactionsReport,
    } = props;
    // * styled component destructured
    const {
        TransactionPropertyContainer,
        TPTitle,
        ModalTitleContainer,
        ModalContentContainer,
        Title,
        StyledSearchBar,
        LoadingContainer,
        PropertiesTable,
        PropertyListingTable,
        PropertyListingHeader,
        PropertyListingRowButton,
        PropertyListingRowButtonError,
        NameContainer,
        PropertyListingsColumnWithImage,
        ExportButton,
    } = StyledComponent;

    // ! useState Zone
    const [searchKeyword, setSearchKeyword] = useState('');
    const [propertyList, setPropertyList] = useState<GetPropertiesResponse[]>([]);

    // ! useEffect zone
    useEffect(() => {
        return () => {
            getProperty({ index: 1 });
            setSearchKeyword('');
            clearPropertyReport();
        };
    }, [isExportModalOpen]);

    useEffect(() => {
        const { data } = propertyReport;

        setPropertyList(data);
    }, [propertyReport]);

    // ! functions
    // *
    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        if (searchWord) {
            getProperty({
                index: 1,
                location: searchWord,
            });
        } else {
            getProperty({ index: 1 });
        }
    }, 500, { leading: false }));

    const onSearchProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKeyword(value);

        throttledSetSearch.current(value);
    };

    const handleCloseModal = () => {
        setIsExportModalOpen(false);
        setSearchKeyword('');
    };

    // ! render zone
    const renderPropertyList = () => {
        if (propertyLoading) {
            return (
                <LoadingContainer>
                    <Bars
                        color={Colors.primary}
                        height={260}
                        width={260}
                    />
                </LoadingContainer>
            );
        }

        if (!propertyList.length) {
            return (
                <PropertyListingRowButtonError>
                    <p>{Translate.t('PropertyReport.PropertyReportError1')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError2')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError3')}</p>
                </PropertyListingRowButtonError>
            );
        }

        if (propertyError) {
            return (
                <PropertyListingRowButtonError>
                    <p>{Translate.t('PropertyReport.PropertyReportError4')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError5')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError6')}</p>
                </PropertyListingRowButtonError>
            );
        }
        return (
            propertyList.map((property) => {
                const { propertyId, name, images, owner, unitNo } = property;
                const uniqueKey = propertyId + name;

                return (
                    <PropertyListingRowButton
                        key={uniqueKey}
                        onClick={() => null}
                    >
                        <PropertyListingsColumnWithImage>
                            <NameContainer>
                                {name}
                            </NameContainer>
                            <img
                                src={PropertyImage}
                                alt='properties images'
                            />
                        </PropertyListingsColumnWithImage>
                        <p>{unitNo}</p>
                        <p>{owner}</p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ExportButton
                                label='Export'
                                onClick={() => getTransactionsReport({ propertyId, name, unitNo })}
                                loading={getTransactionsReportAttempt}
                            />
                        </div>
                    </PropertyListingRowButton>
                );
            })
        );
    };
    // * main render
    return (
        <Modal
            height='850px'
            show={isExportModalOpen}
            closeCursor
            onClickClose={handleCloseModal}
        >
            <ModalTitleContainer>
                <Title>
                    Export Transactions Report
                </Title>
            </ModalTitleContainer>
            <ModalContentContainer>
                <TransactionPropertyContainer>
                    <TPTitle>
                        <StyledSearchBar
                            title='Search Property'
                            value={searchKeyword}
                            onChangeText={onSearchProperty}
                        />
                    </TPTitle>
                    <PropertiesTable>
                        <PropertyListingTable>
                            <PropertyListingHeader>
                                <h4>Property Name</h4>
                                <h4>Unit No.</h4>
                                <h4>Owner</h4>
                                <h4>Action</h4>
                            </PropertyListingHeader>
                            {renderPropertyList()}
                        </PropertyListingTable>
                    </PropertiesTable>
                </TransactionPropertyContainer>
            </ModalContentContainer>
        </Modal>
    );
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    isExportModalOpen: Selectors.getTransactionGetExportModalOpen(state),

    propertyLoading: Selectors.getPropertyGetAllAttempt(state),
    propertyError: Selectors.getPropertyGetAllError(state),
    propertyReport: Selectors.getPropertyPropertyReport(state),
    getTransactionsReportAttempt: Selectors.getTransactionGetTransactionReportExportAttempt(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsExportModalOpen: (state: boolean) => dispatch(Actions.setIsExportModalOpen(state)),
    getProperty: (data: IGetProperty) => dispatch(Actions.propertyGetAllAttempt(data)),
    clearPropertyReport: () => dispatch(Actions.clearPropertyReport()),
    getTransactionsReport: (params: IGetTransactionsReportExportPayload) => dispatch(Actions.getTransactionsReportExportAttempt(params)),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(ExportTransactionModal);
