import { fork } from 'redux-saga/effects';

import config from 'config';

import api from 'api';
import mockApi from 'api/mocks';

import auth from './auth';
import owners from './owners';
import property from './property';
import packages from './packages';
import rea from './rea';
import subadmins from './subadmins';

import { SagaForkReturnType } from './types';

const apiInstance = api;

// if (config.useMockApi) {
//     apiInstance = mockApi;
// }

export default function* root(): SagaForkReturnType {
    yield fork(auth(apiInstance.auth).rootSaga);
    yield fork(owners(apiInstance.owners).rootSaga);
    yield fork(property(apiInstance.property).rootSaga);
    yield fork(packages(apiInstance.packages).rootSaga);
    yield fork(rea(apiInstance.rea).rootSaga);
    yield fork(subadmins(apiInstance.subadmins).rootSaga);
}
