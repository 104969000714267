import { GetOwnerDetailsResponse } from '@dm/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IServerOwnerReport } from 'entities/owners';

import { OwnersReduxState, CreateNewActionPayload, GetAllActionPayload, EditOwnerDetailsPayload } from './types';

const initialState: OwnersReduxState = {
    actions: {
        createNew: false,
        getAll: false,
        getOwnerDetails: false,
        editOwnerDetails: false,
    },
    ownersReport: {
        index: 1,
        maxIndex: 1,
        data: [],
    },
    ownerDetails: null,
    error: {
        createNew: '',
        getAll: '',
        getOwnerDetails: '',
        editOwnerDetails: '',
    },
};

const ownersSlice = createSlice({
    name: 'owners',
    initialState,
    reducers: {
        ownersCreateNewAttempt: (state, _action: CreateNewActionPayload) => {
            state.actions.createNew = true;
            state.error.createNew = '';
        },
        ownersCreateNewSuccess: (state) => {
            state.actions.createNew = false;
        },
        ownersCreateNewFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createNew = false;
            if (action.payload) {
                state.error.createNew = action.payload;
            }
        },
        ownersGetAllAttempt: (state, _action: GetAllActionPayload) => {
            state.actions.getAll = true;
            state.error.getAll = '';
        },
        ownersGetAllSuccess: (state, action: PayloadAction<IServerOwnerReport>) => {
            state.actions.getAll = false;
            state.ownersReport = action.payload;
        },
        ownersGetAllFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAll = false;
            if (action.payload) {
                state.error.getAll = action.payload;
            }
        },
        clearOwnersReport: (state) => {
            state.ownersReport = {
                index: 1,
                maxIndex: 1,
                data: [],
            };
        },

        getOwnerDetailsAttempt: (state, _action: PayloadAction<string>) => {
            state.actions.getOwnerDetails = true;
            state.error.getOwnerDetails = '';
        },
        getOwnerDetailsSuccess: (state, action: PayloadAction<GetOwnerDetailsResponse>) => {
            state.actions.getOwnerDetails = false;
            state.error.getOwnerDetails = '';

            if (action.payload) {
                state.ownerDetails = action.payload;
            }
        },
        getOwnerDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getOwnerDetails = false;
            if (action.payload) {
                state.error.getOwnerDetails = action.payload;
            }
        },
        clearOwnerDetails: (state) => {
            state.ownerDetails = null;
        },
        editOwnerDetailsAttempt: (state, _action: EditOwnerDetailsPayload) => {
            state.actions.editOwnerDetails = true;
            state.error.editOwnerDetails = '';
        },
        editOwnerDetailsSuccess: (state) => {
            state.actions.editOwnerDetails = false;
        },
        editOwnerDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editOwnerDetails = false;
            if (action.payload) {
                state.error.editOwnerDetails = action.payload;
            }
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: ownersSlice.actions,
    reducers: ownersSlice.reducer,
};
