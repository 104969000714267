import { InitState } from 'redux/slices/owners';
import { IServerOwnerReport } from 'entities/owners';
import { GetOwnerDetailsResponse } from '@dm/types';

const getCreateNewAttempt = (state: InitState): boolean => state.actions.createNew || false;
const getCreateNewError = (state: InitState): string => state.error.createNew || '';

const ownersGetAllAttempt = (state: InitState): boolean => state.actions.getAll || false;
const ownersGetAllError = (state: InitState): string => state.error.getAll || '';

const ownersReport = (state: InitState): IServerOwnerReport => state.ownersReport;

const getOwnerDetailsAttempting = (state: InitState): boolean => state.actions.getOwnerDetails || false;
const getOwnerDetailsError = (state: InitState): string => state.error.getOwnerDetails || '';

const getOwnerDetails = (state: InitState): GetOwnerDetailsResponse | null => state.ownerDetails || null;

const editOwnerDetailsAttempt = (state: InitState): boolean => state.actions.editOwnerDetails || false;
const editOwnerDetailsError = (state: InitState): string => state.error.editOwnerDetails || '';

export default {
    getCreateNewAttempt,
    getCreateNewError,

    ownersGetAllAttempt,
    ownersGetAllError,

    ownersReport,

    getOwnerDetailsAttempting,
    getOwnerDetailsError,

    getOwnerDetails,

    editOwnerDetailsAttempt,
    editOwnerDetailsError,
};
