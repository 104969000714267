import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import validator from 'validator';
import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

import { AdminGetPropertiesResponse } from '@dm/types';
import { BreadCrumb, CardCollapsible, Button, LineInput, Colors } from '@dm/bigfish';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { IOwnerAccount, IOwnerInfo, IOwnerPropertyIds } from 'entities/owners';

import Icons from 'assets/icons';

import MainContainer from 'components/MainContainer';
import SupportInfo from 'components/SupportInfo';
import DateInput from 'components/DateInput';
import SelectionInput from 'components/SelectionInput';
import PropertyCard from 'components/PropertyCard';
import SearchInput from 'components/SearchInput';
import Modal from 'components/Modal';

import countriesOptions from 'components/options/Countries';
import raceOptions from 'components/options/Race';
import genderOptions from 'components/options/Gender';
import titleOptions from 'components/options/Title';

interface BasicProperty {
    title: string;
    location: string;
}

interface CreateNewProps {
    loading: boolean;
    error: string;
    getSearchedPropertyLoading: boolean;
    getSearchedPropertyError: string;
    searchedProperty: AdminGetPropertiesResponse[];
    createNewOwner: (propertyIds: IOwnerPropertyIds, accCredential: IOwnerAccount, userInfo?: IOwnerInfo) => void;
    getSearchedProperty: (search: string) => void;
    clearSearchedProperty: () => void;
}

const CreateNew = (props: CreateNewProps): JSX.Element => {
    const { loading, error, createNewOwner, getSearchedPropertyLoading, getSearchedPropertyError, searchedProperty, getSearchedProperty, clearSearchedProperty } = props;

    const [showModal, setShowModal] = useState(false);

    // status
    const [ownerDetailsStatus, setOwnerDetailsStatus] = useState(false);
    const [attachPropStatus, setAttachPropStatus] = useState(false);
    const [accDetailsStatus, setAccDetailsStatus] = useState(false);

    // personal details
    const [personalTitle, setPersonalTitle] = useState('mr');
    const [personalGender, setPersonalGender] = useState('male');
    const [personalAddress, setPersonalAddress] = useState('');
    const [personalAddress2, setPersonalAddress2] = useState('');
    const [personalFirstName, setPersonalFirstName] = useState('');
    const [personalLastName, setPersonalLastName] = useState('');
    const [personalIdNo, setPersonalIdNo] = useState('');
    const [personalCity, setPersonalCity] = useState('');
    const [personalPassportNo, setPersonalPassportNo] = useState('');
    const [personalMobileNo, setPersonalMobileNo] = useState('');
    const [personalPostcode, setPersonalPostcode] = useState('');
    const [personalCountryOrigin, setPersonalCountryOrigin] = useState('1');
    const [personalHouseTel, setPersonalHouseTel] = useState('');
    const [personalState, setPersonalState] = useState('');
    const [personalRace, setPersonalRace] = useState(0);
    const [personalCountryResidence, setPersonalCountryResidence] = useState('1');
    const [personalCountry, setPersonalCountry] = useState('1');
    const [dateOfBirth, setDateOfBirth] = useState(new Date());

    // financial
    const [financialBankType, setFinancialBankType] = useState('');
    const [financialCurrency, setFinancialCurrency] = useState('');
    const [financialAddress, setFinancialAddress] = useState('');
    const [financialBankName, setFinancialBankName] = useState('');
    const [financialAccNo, setFinancialAccNo] = useState('');
    const [financialSwiftCode, setFinancialSwiftCode] = useState('');
    const [financialCity, setFinancialCity] = useState('');
    const [financialPostcode, setFinancialPostcode] = useState('');
    const [financialState, setFinancialState] = useState('');
    const [financialCountry, setFinancialCountry] = useState('1');

    // company details
    const [companyName, setCompanyName] = useState('');
    const [companyDesignation, setCompanyDesignation] = useState('');
    const [companyOfficeNo, setCompanyOfficeNo] = useState('');

    // emergency
    const [emergencyFullName, setEmergencyFullName] = useState('');
    const [emergencyRelationship, setEmergencyRelationship] = useState('');
    const [emergencyMobileNo, setEmergencyMobileNo] = useState('');
    const [emergencyEmail, setEmergencyEmail] = useState('');
    const [emergencyEmailIsError, setEmergencyEmailIsError] = useState(false);

    // attach properties
    const [searchedProp, setSearchProp] = useState('');
    const [searchedPropList, setSearchedPropList] = useState<AdminGetPropertiesResponse[]>([]);
    const [selectedPropIdList, setSelectedPropIdList] = useState<string[]>([]);
    const [tempProp, setTempProp] = useState<AdminGetPropertiesResponse | null>(null);

    // acc details
    const [accDetailsEmail, setAccDetailsEmail] = useState('');
    const [accDetailsEmailIsError, setAccDetailsEmailIsError] = useState(false);
    const [accDetailsPassword, setAccDetailsPassword] = useState('');
    const [accDetailsPasswordIsError, setAccDetailsPasswordIsError] = useState(false);
    const [accDetailsPasswordConfirm, setAccDetailsPasswordConfirm] = useState('');
    const [accDetailsPasswordConfirmIsError, setAccDetailsPasswordConfirmIsError] = useState(false);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('CreateNew.CreateNewBcDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('CreateNew.CreateNewBcCreate'),
                onClick: () => { return false; },
            },
            {
                label: Translate.t('CreateNew.CreateNewBcOwner'),
                onClick: () => { return false; },
            },
        ],
    );

    const ownerDetailsRef = useRef<any>(null);
    const attachPropRef = useRef<any>(null);
    const accDetailsRef = useRef<any>(null);

    useEffect(() => {
        return () => {
            clearSearchedProperty();
        };
    }, []);

    useEffect(() => {
        if (searchedProperty) setSearchedPropList(searchedProperty);
    }, [searchedProperty]);

    useEffect(() => {
        setAccDetailsPasswordConfirmIsError(!validator.equals(accDetailsPassword, accDetailsPasswordConfirm));
    }, [accDetailsPasswordConfirm]);

    useEffect(() => {
        setOwnerDetailsStatus(false);
        setAccDetailsStatus(false);
        setAttachPropStatus(false);
    }, [error]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onChangeTextEmergencyEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmergencyEmail(e.target.value);
        setEmergencyEmailIsError(!validator.isEmail(e.target.value));
    };

    const onClearOwnerDetails = () => {
        setOwnerDetailsStatus(false);

        setPersonalTitle('mr');
        setPersonalGender('male');
        setPersonalAddress('');
        setPersonalAddress2('');
        setPersonalFirstName('');
        setPersonalLastName('');
        setPersonalIdNo('');
        setPersonalCity('');
        setPersonalPassportNo('');
        setPersonalMobileNo('');
        setPersonalPostcode('');
        setPersonalCountryOrigin('1');
        setPersonalHouseTel('');
        setPersonalState('');
        setPersonalRace(0);
        setPersonalCountryResidence('1');
        setPersonalCountry('1');
        setDateOfBirth(new Date());

        setFinancialBankType('');
        setFinancialCurrency('');
        setFinancialAddress('');
        setFinancialBankName('');
        setFinancialAccNo('');
        setFinancialSwiftCode('');
        setFinancialCity('');
        setFinancialPostcode('');
        setFinancialState('');
        setFinancialCountry('1');

        setCompanyName('');
        setCompanyDesignation('');
        setCompanyOfficeNo('');

        setEmergencyFullName('');
        setEmergencyRelationship('');
        setEmergencyMobileNo('');
        setEmergencyEmail('');
    };

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        if (searchWord) {
            getSearchedProperty(searchWord);
        } else {
            setSearchedPropList([]);
        }
    }, 500, { leading: false }));

    const onChangeSearchedProp = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setSearchProp(value);
        throttledSetSearch.current(value);
    };

    const onClearAttachProp = () => {
        setAttachPropStatus(false);
        setSelectedPropIdList([]);
        setSearchProp('');
    };

    const onChangeAccDetailsEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccDetailsEmail(e.target.value);
        setAccDetailsEmailIsError(!validator.isEmail(e.target.value));
    };

    const onChangeAccDetailsPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccDetailsPassword(e.target.value);

        const validPasswordTest = validator.isStrongPassword(e.target.value, { minLength: 5, minLowercase: 1, minUppercase: 1, minSymbols: 1 });
        setAccDetailsPasswordIsError(!validPasswordTest);
    };

    const onChangeAccDetailsPasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccDetailsPasswordConfirm(e.target.value);
    };

    const onClearAccDetails = () => {
        setAccDetailsStatus(false);

        setAccDetailsEmail('');
        setAccDetailsPassword('');
        setAccDetailsPasswordConfirm('');
    };

    const onCloseCollapsibleOwnerDetails = () => {
        if (ownerDetailsRef.current) {
            ownerDetailsRef.current.closeCollapsible();
        }
    };

    const onCloseCollapsibleAttachProp = () => {
        if (attachPropRef.current) {
            attachPropRef.current.closeCollapsible();
        }
    };

    const onCloseCollapsibleAccDetails = () => {
        if (accDetailsRef.current) {
            accDetailsRef.current.closeCollapsible();
        }
    };

    const onSubmitOwnerDetails = (e: React.FormEvent) => {
        e.preventDefault();

        setOwnerDetailsStatus(true);
        onCloseCollapsibleOwnerDetails();
    };

    const onSubmitAttachProp = () => {
        if (selectedPropIdList.length) {
            setAttachPropStatus(true);
            onCloseCollapsibleAttachProp();
        } else {
            toast.error('Please select a property to continue');
        }
    };

    const onSubmitAccDetails = (e: React.FormEvent) => {
        e.preventDefault();

        if (!accDetailsEmailIsError && !accDetailsPasswordConfirmIsError && !accDetailsPasswordConfirmIsError) {
            setAccDetailsStatus(true);
            onCloseCollapsibleAccDetails();
        }
    };

    const onSubmitOwnerAccSetup = () => {
        if (!attachPropStatus && !ownerDetailsStatus) {
            toast.error(Translate.t('CreateNew.CreateNewBcError1'));
        } else {
            const accCredential = {
                email: accDetailsEmail,
                password: accDetailsPassword,
            };

            if (ownerDetailsStatus) {
                const userInfo = {
                    firstName: personalFirstName,
                    lastName: personalLastName,
                    phoneNumber: personalMobileNo,
                    title: personalTitle,
                    nationality: personalCountry,
                    race: personalRace,
                    DOB: dayjs(dateOfBirth).format('YYYY-MM-DD'),
                    gender: personalGender,
                    countryOfResidence: personalCountryResidence,
                    houseTel: personalHouseTel,
                    passportNo: personalPassportNo,
                    countryOfOrigin: personalCountryOrigin,
                    idNo: personalIdNo,
                    correspondingAddress: {
                        address1: personalAddress,
                        address2: personalAddress2,
                        city: personalCity,
                        postcode: personalPostcode,
                        state: personalState,
                        country: personalCountry,
                    },
                    financialInfo: {
                        bankType: financialBankType,
                        bankName: financialBankName,
                        currency: financialCurrency,
                        bankAccNo: financialAccNo,
                        swiftCode: financialSwiftCode,
                        branchAddress: {
                            address1: financialAddress,
                            address2: 'asdasd',
                            city: financialCity,
                            postcode: financialPostcode,
                            state: financialState,
                            country: financialCountry,
                        },
                    },
                    companyDetails: {
                        companyName,
                        designation: companyDesignation,
                        officeNo: companyOfficeNo,
                    },
                    emergencyContact: {
                        fullName: emergencyFullName,
                        relationship: emergencyRelationship,
                        phoneNo: emergencyMobileNo,
                        email: emergencyEmail,
                    },
                };

                createNewOwner(selectedPropIdList, accCredential, userInfo);
            } else {
                createNewOwner(selectedPropIdList, accCredential);
            }
        }
    };

    const createPropertyLocation = (property:AdminGetPropertiesResponse) => {
        const { location } = property;

        if (location) {
            const { city = '', state = '' } = location;
            return `${city}, ${state}`;
        }

        return '';
    };

    const isPropertyClicked = (array: string[], searchPropertyId: string) => {
        return array.some(element => {
            if (element === searchPropertyId) {
                return true;
            }

            return false;
        });
    };

    const onClickProperty = (propertyId: string) => {
        const isExisted = selectedPropIdList.some(propId => propId === propertyId);

        if (!isExisted) {
            setSelectedPropIdList([...selectedPropIdList, propertyId]);
        } else {
            const filteredExisted = selectedPropIdList.filter(propId => propId !== propertyId);
            setSelectedPropIdList(filteredExisted);
        }
    };

    const renderPropertyCardList = () => {
        if (getSearchedPropertyLoading) {
            return (
                <StatusContainer>
                    <Oval
                        height={180}
                        width={180}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </StatusContainer>

            );
        }

        if (!searchedPropList.length) {
            return (
                <StatusContainer>
                    <p>{Translate.t('CreateNew.CreateNewNoPropFound')}</p>
                </StatusContainer>
            );
        }

        return (
            <PropertyCardContainer>
                {searchedPropList?.map((property) => {
                    const propertyLocation = createPropertyLocation(property);
                    const { propertyId, name, unitNo } = property;

                    return (
                        <PropertyCard
                            style={{ zIndex: '1' }}
                            key={propertyId}
                            chipText={unitNo ?? ''}
                            title={name}
                            location={propertyLocation}
                            onClick={() => {
                                if (!selectedPropIdList.includes(propertyId)) {
                                    setTempProp(property);
                                    setShowModal(true);
                                }
                            }}
                            active={
                                isPropertyClicked(selectedPropIdList, propertyId)
                            }
                        />
                    );
                })}
            </PropertyCardContainer>
        );
    };

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Desc>{Translate.t('CreateNew.CreateNewBcDesc')}</Desc>
                <OwnerContentContainer>
                    <SetupContainer>
                        <CardCollapsible ref={ownerDetailsRef} preText={Translate.t('CreateNew.CreateNewStep1')} title={Translate.t('CreateNew.CreateNewOwnerDetails')} icon={ownerDetailsStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={ownerDetailsStatus}>
                            <SectionContainer>
                                <form onSubmit={onSubmitOwnerDetails}>
                                    <SectionContainer>
                                        <SectionTitle>{Translate.t('CreateNew.CreateNewOwnerDetails')}</SectionTitle>
                                        <DetailsContainer>
                                            <SelectionInput label={Translate.t('CreateNew.CreateNewLabelTitle')} data={titleOptions} currentValue={personalTitle} onChangeSelection={(e) => setPersonalTitle(e.target.value)} />
                                            <SelectionInput label={Translate.t('CreateNew.CreateNewLabelGender')} data={genderOptions} currentValue={personalGender} onChangeSelection={(e) => setPersonalGender(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelCPAddress1')} value={personalAddress} onChangeText={(e) => setPersonalAddress(e.target.value)} required />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelCPAddress2')} value={personalAddress2} onChangeText={(e) => setPersonalAddress2(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelFirstName')} value={personalFirstName} onChangeText={(e) => setPersonalFirstName(e.target.value)} required />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelLastName')} value={personalLastName} onChangeText={(e) => setPersonalLastName(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelIDNo')} value={personalIdNo} onChangeText={(e) => setPersonalIdNo(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelCity')} value={personalCity} onChangeText={(e) => setPersonalCity(e.target.value)} required />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelPassport')} value={personalPassportNo} onChangeText={(e) => setPersonalPassportNo(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelMobile')} value={personalMobileNo} onChangeText={(e) => setPersonalMobileNo(e.target.value)} required />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelPostcode')} value={personalPostcode} onChangeText={(e) => setPersonalPostcode(e.target.value)} required />
                                            <SelectionInput label={Translate.t('CreateNew.CreateNewLabelCountryOrigin')} data={countriesOptions} currentValue={personalCountryOrigin} onChangeSelection={(e) => setPersonalCountryOrigin(e.target.value)} required />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelHouseTel')} value={personalHouseTel} onChangeText={(e) => setPersonalHouseTel(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelState')} value={personalState} onChangeText={(e) => setPersonalState(e.target.value)} required />
                                            <SelectionInput label={Translate.t('CreateNew.CreateNewLabelRace')} data={raceOptions} currentValue={personalRace} onChangeSelection={(e) => setPersonalRace(+e.target.value)} />
                                            <SelectionInput label={Translate.t('CreateNew.CreateNewLabelCountryResidence')} data={countriesOptions} currentValue={personalCountryResidence} onChangeSelection={(e) => setPersonalCountryResidence(e.target.value)} required />
                                            <SelectionInput label={Translate.t('CreateNew.CreateNewLabelCountry')} data={countriesOptions} currentValue={personalCountry} onChangeSelection={(e) => setPersonalCountry(e.target.value)} required />
                                            <DateInput label={Translate.t('CreateNew.CreateNewLabelDOB')} date={dateOfBirth} onChangeDate={(date) => setDateOfBirth(date)} />
                                        </DetailsContainer>
                                    </SectionContainer>

                                    <SectionContainer>
                                        <SectionTitle>{Translate.t('CreateNew.CreateNewFinancialDetails')}</SectionTitle>
                                        <DetailsContainer>
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelBankType')} value={financialBankType} onChangeText={(e) => setFinancialBankType(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelCurrency')} value={financialCurrency} onChangeText={(e) => setFinancialCurrency(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelBranchAddress')} value={financialAddress} onChangeText={(e) => setFinancialAddress(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelBranchName')} value={financialBankName} onChangeText={(e) => setFinancialBankName(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelBranchAccNo')} value={financialAccNo} onChangeText={(e) => setFinancialAccNo(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelSwiftCode')} value={financialSwiftCode} onChangeText={(e) => setFinancialSwiftCode(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelCity')} value={financialCity} onChangeText={(e) => setFinancialCity(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelPostcode')} value={financialPostcode} onChangeText={(e) => setFinancialPostcode(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelState')} value={financialState} onChangeText={(e) => setFinancialState(e.target.value)} />
                                            <SelectionInput label={Translate.t('CreateNew.CreateNewLabelCountry')} currentValue={financialCountry} data={countriesOptions} onChangeSelection={(e) => setFinancialCountry(e.target.value)} />
                                        </DetailsContainer>
                                    </SectionContainer>

                                    <SectionContainer>
                                        <SectionTitle>{Translate.t('CreateNew.CreateNewCompDetails')}</SectionTitle>
                                        <DetailsContainer>
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelCompanyName')} value={companyName} onChangeText={(e) => setCompanyName(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelDesignation')} value={companyDesignation} onChangeText={(e) => setCompanyDesignation(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelOfficeNo')} value={companyOfficeNo} onChangeText={(e) => setCompanyOfficeNo(e.target.value)} />
                                        </DetailsContainer>
                                    </SectionContainer>

                                    <SectionContainer>
                                        <SectionTitle>{Translate.t('CreateNew.CreateNewEmergencyDetails')}</SectionTitle>
                                        <DetailsContainer>
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelFullName')} value={emergencyFullName} onChangeText={(e) => setEmergencyFullName(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelRelationship')} value={emergencyRelationship} onChangeText={(e) => setEmergencyRelationship(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelMobile')} value={emergencyMobileNo} onChangeText={(e) => setEmergencyMobileNo(e.target.value)} />
                                            <LineInput label={Translate.t('CreateNew.CreateNewLabelEmail')} value={emergencyEmail} onChangeText={onChangeTextEmergencyEmail} inputError={emergencyEmailIsError} errorText={Translate.t('CreateNew.CreateNewErrorEmail')} />
                                        </DetailsContainer>
                                    </SectionContainer>

                                    <SubmitButton>
                                        <Button type='submit' label={Translate.t('CreateNew.CreateNewBtnConfirm')} />
                                        <Button type='button' secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onClearOwnerDetails} />
                                    </SubmitButton>
                                </form>
                            </SectionContainer>
                        </CardCollapsible>

                        <CardCollapsible ref={attachPropRef} preText={Translate.t('CreateNew.CreateNewStep2')} title={Translate.t('CreateNew.CreateNewAttachProp')} icon={attachPropStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={attachPropStatus}>
                            <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                                <SearchInput value={searchedProp} onChangeText={onChangeSearchedProp} />
                                <div>
                                    {renderPropertyCardList()}
                                </div>

                                <SubmitButton>
                                    <Button type='button' label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={onSubmitAttachProp} />
                                    <Button type='button' secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onClearAttachProp} />
                                </SubmitButton>
                            </SectionContainer>
                        </CardCollapsible>

                        <CardCollapsible ref={accDetailsRef} preText={Translate.t('CreateNew.CreateNewStep3')} title={Translate.t('CreateNew.CreateNewAccDetails')} icon={accDetailsStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={accDetailsStatus}>
                            <SectionContainer>
                                <form onSubmit={onSubmitAccDetails}>
                                    <DetailsContainer>
                                        <LineInput label='Email Address' value={accDetailsEmail} onChangeText={onChangeAccDetailsEmail} inputError={accDetailsEmailIsError} errorText={Translate.t('CreateNew.CreateNewErrorEmail')} required />
                                        <LineInput type='password' label='Password' value={accDetailsPassword} onChangeText={onChangeAccDetailsPassword} inputError={accDetailsPasswordIsError} errorText={Translate.t('CreateNew.CreateNewErrorPass')} required />
                                        <LineInput type='password' label='Re-Type for Password Confirmation' value={accDetailsPasswordConfirm} onChangeText={onChangeAccDetailsPasswordConfirm} inputError={accDetailsPasswordConfirmIsError} errorText={Translate.t('CreateNew.CreateNewErrorPassConfirm')} required />
                                    </DetailsContainer>
                                    <SubmitButton>
                                        <Button type='submit' label={Translate.t('CreateNew.CreateNewBtnConfirm')} />
                                        <Button type='button' secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onClearAccDetails} />
                                    </SubmitButton>
                                </form>
                            </SectionContainer>

                        </CardCollapsible>

                        <SubmitButton>
                            <Button loading={loading} label={Translate.t('CreateNew.CreateNewBtnSubmit')} onClick={onSubmitOwnerAccSetup} />
                        </SubmitButton>
                    </SetupContainer>
                    <SideContainer>
                        <SupportInfo />
                    </SideContainer>
                </OwnerContentContainer>

                <Modal
                    show={showModal}
                    closeCursor
                    width='fit-content'
                    onClickClose={() => {
                        setTempProp(null);
                        setShowModal(false);
                    }}
                >
                    <ModalContent>

                        {
                            tempProp && (
                                <>
                                    <p style={{ marginTop: '60px' }}>
                                        {Translate.t('CreateNew.CreateNewModalText1')}
                                        {tempProp.owner !== 'undefined undefined' ? tempProp.owner : 'other owner'}
                                        {Translate.t('CreateNew.CreateNewModalText2')}
                                    </p>
                                    <SubmitButton>
                                        <Button
                                            type='button'
                                            label={Translate.t('CreateNew.CreateNewModalBtnYes')}
                                            onClick={() => {
                                                onClickProperty(tempProp.propertyId);
                                                setShowModal(false);
                                            }}
                                        />
                                        <Button
                                            type='button'
                                            secondary
                                            label={Translate.t('CreateNew.CreateNewModalBtnNo')}
                                            onClick={() => {
                                                setTempProp(null);
                                                setShowModal(false);
                                            }}
                                        />
                                    </SubmitButton>
                                </>
                            )
                        }

                    </ModalContent>
                </Modal>
            </MainContainer>
        </>
    );
};

const OwnerContentContainer = styled.div`
    display: flex;
    margin: 0 20px;
    color: #000;
`;

const SetupContainer = styled.div`
    width: 824px;
    margin-right: 16px;

    > div {
        margin-bottom: 16px;
    }
`;

const SectionTitle = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const SideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 564px;
`;

const SubmitButton = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;

    > button {
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
    }

    > button:not(:last-child){
        margin-right: 10px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 16px;
    color: #000;
    margin: 40px 20px 20px 20px;
`;

const PropertyCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;

    > div {
        margin-top: 20px;
    }

    > div:not(:last-child){
        margin-right: 16px;
    }
`;

const StatusContainer = styled.div`
    display: flex;
    height: 200px;
    width: 100%;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    z-index: 2;

    div {
        margin: 0 auto;
    }
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin-top: 30px;
        margin-bottom: 15px;
        text-align: center;
    }
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getOwnersCreateNewAttempt(state),
    error: Selectors.getOwnersCreateNewError(state),
    getSearchedPropertyLoading: Selectors.getPropertyGetSearchedPropertyAttempting(state),
    getSearchedPropertyError: Selectors.getPropertyGetSearchedPropertyError(state),
    searchedProperty: Selectors.getPropertySearchProperty(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createNewOwner: (propertyIds: IOwnerPropertyIds, accCredential: IOwnerAccount, userInfo?: IOwnerInfo) =>
        dispatch(Actions.ownersCreateNewAttempt({ propertyIds, accCredential, userInfo })),
    getSearchedProperty: (search: string) => dispatch(Actions.getSearchedPropertyAttempt(search)),
    clearSearchedProperty: () => dispatch(Actions.clearSearchedProperty()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNew);
