import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import { SetIVacantPossesionSortPayload } from 'redux/slices/packages/types';

export default function* watchGetPropertyManagementDataSort(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesGetIPropertyManagementDataSortAttempt', handleVacantManagementSortPackages, api);
}

function* handleVacantManagementSortPackages(api: PackagesGateway, data: SetIVacantPossesionSortPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId, category, sort } = data.payload;

        const response = yield* call([api, api.getPackagesPropertyManagementSort], {
            category,
            propertyId,
            sort,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesGetIPropertyManagementDataSortFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            yield put(Actions.packagesGetIPropertyManagementDataSortSuccess(response.data));
        } else {
        // handle error
            yield put(Actions.packagesGetIPropertyManagementDataSortFailure(response.message));
        }
    }
}
