import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors } from '@dm/bigfish';
import { PropertyStatus } from '@dm/types';

import { IServicePackagePricing } from 'entities/property';

import Modal from 'components/Modal';

interface RejectPropertyModalProps {
    id: string;
    isModalOpen: boolean;
    setServiceLoading: boolean;
    setServiceError: string;
    setRejectPropertyModalOpen: (state: boolean) => void;
    setServicePackagePricing: (data: IServicePackagePricing) => void;
}

const RejectPropertyModal: FunctionComponent<RejectPropertyModalProps> = (props: RejectPropertyModalProps) => {
    const {
        id,
        isModalOpen,
        setServiceLoading,
        setServiceError,
        setRejectPropertyModalOpen,
        setServicePackagePricing,
    } = props;

    const cancelClickHandler = () => {
        setRejectPropertyModalOpen(false);
    };

    const confirmClickHandler = () => {
        const dataToSubmit: IServicePackagePricing = {
            propertyId: id,
            status: PropertyStatus.rejected,
        };

        setServicePackagePricing(dataToSubmit);
    };

    const renderModalBody = () => {
        if (setServiceLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Oval
                        height={150}
                        width={150}
                        color={Colors.primary}
                        secondaryColor={Colors.primaryLight}
                    />
                </div>
            );
        }

        if (setServiceError) {
            return (
                <div>
                    {setServiceError}
                </div>
            );
        }

        return (
            <div>
                Are you sure you want to reject this property?
            </div>
        );
    };

    const renderModalFooter = () => {
        if (setServiceError) {
            return (
                <Button
                    label='Proceed'
                    onClick={() => window.location.reload()}
                />
            );
        }

        return (
            <>
                <Button
                    label='Cancel'
                    style={{
                        marginRight: '10px',
                        backgroundColor: Colors.white,
                        color: Colors.primary,
                        border: `1px solid ${Colors.primary}`,
                    }}
                    onClick={cancelClickHandler}
                    disabled={setServiceLoading}
                />
                <Button
                    label='Proceed'
                    onClick={confirmClickHandler}
                    disabled={setServiceLoading}
                />
            </>
        );
    };

    return (
        <Modal
            show={isModalOpen}
            width='40%'
        >
            <ModalHeader>
                Reject this property?
            </ModalHeader>

            <ModalBody>
                {renderModalBody()}
            </ModalBody>

            <ModalFooter>
                {renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ModalBody = styled.div`
    margin-bottom: 20px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const mapStateToProps = (state: RootState) => ({
    setServiceLoading: Selectors.getPropertySetServicePackageAttempting(state),
    setServiceError: Selectors.getPropertySetServicePackageError(state),
    isModalOpen: Selectors.getPropertyGetRejectProperyModalOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setRejectPropertyModalOpen: (state: boolean) => dispatch(Actions.setRejectPropertyModalOpen(state)),
    setServicePackagePricing: (data: IServicePackagePricing) => dispatch(Actions.setServicePackageAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectPropertyModal);
