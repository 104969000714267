import { ApiResponse } from 'apisauce';
import { GatewayResponse } from 'api/types/types';

import { IServerOwnerReport } from 'entities/owners';

import { GetOwnerDetailsResponse, GetOwnerResponse } from '@dm/types';
import { IOwnersGateway, CreateNewParams, GetOwnersParams, GetOwnerDetailsParams, EditOwnerDetailsParams } from './OwnersBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class OwnersGateway extends IOwnersGateway {
    async createNew(params: CreateNewParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('admin/owner', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getOwners(params: GetOwnersParams): GatewayResponse<IServerOwnerReport> {
        const response: ApiResponse<IServerOwnerReport> = await this.api.get('/admin/report/owners', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getOwnerDetails(params: GetOwnerDetailsParams): GatewayResponse<GetOwnerDetailsResponse> {
        const response: ApiResponse<GetOwnerDetailsResponse> = await this.api.get(`/admin/report/owners/${params.userId}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async editOwnerDetails(params: EditOwnerDetailsParams): GatewayResponse<string | null> {
        const { authToken, ...newParam } = params;
        const response: ApiResponse<string | null> = await this.api.put('/admin/owner/editOwnerDetails', newParam, getHeaders(authToken));
        return this.process(response);
    }
}
