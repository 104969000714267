import React from 'react';
import styled from 'styled-components';
import NoImageAvailable from '../assets/images/house1.png';

interface PropertyImageProps {
  alt?: string;
  src?: string;
  style?: React.CSSProperties;
}

const StyledImg = styled.img`
  width: 244px;
  height: 244px;
  object-fit: cover;
  box-sizing: border-box;
  border: 0.5px solid #FFCFA6;
  border-radius: 12px;
`;

const PropertyImage = ({ alt, src, style, ...otherProps } : PropertyImageProps): JSX.Element => {
    return (
        <StyledImg alt={alt} src={src} {...otherProps} />
    );
};

PropertyImage.defaultProps = {
    alt: 'Image',
    src: NoImageAvailable,
    style: '',
};

export default PropertyImage;
