import { Colors } from '@dm/bigfish';
import React, { FC } from 'react';
import styled from 'styled-components';

type BadgeProps = {
    label: string;
    color: string;
}

const Badge: FC<BadgeProps> = (props: BadgeProps) => {
    const {
        label,
        color,

    } = props;

    return (
        <BadgeContainer color={color}>
            {label}
        </BadgeContainer>
    );
};

const BadgeContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<{ color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    padding: 3px 5px;
    font-size: 10px;

    width: fit-content;

    color: ${Colors.white};

    background-color: ${props => props.color ?? Colors.primaryLight};
`;

export default Badge;
