import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import { AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';

interface DummyProps {
    setTabView: (tabView: string) => void;
}

const Dummy = ({ setTabView }:DummyProps) : JSX.Element => {
    const location = useLocation();

    useEffect(() => {
        const pathName = location.pathname.replace('/', '');
        setTabView(pathName);
    }, []);

    return <div>Dummy</div>;
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
});

export default connect(null, mapDispatchToProps)(Dummy);
