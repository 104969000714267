import { GetOwnerDetailsResponse } from '@dm/types';
import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IGetAllSubAdmins, ISubAdmin, ISubAdminsReport, IAssignProperty, IDeleteProperty } from 'entities/subadmins';

export interface CreateSubAdminParams {
    data: ISubAdmin;
    authToken: string,
}

export interface GetSubAdminsParams {
    data: IGetAllSubAdmins;
    authToken: string;
}

export interface SetAssignPropertyParams {
    data: IAssignProperty;
    authToken: string;
}

export interface DeleteSubAdminPropertyParams {
    data: IDeleteProperty;
    authToken: string;
}

export abstract class ISubAdminGateway extends Gateway {
    abstract createSubAdmin(params: CreateSubAdminParams): GatewayResponse<string | null>;

    abstract getSubAdmins(params: GetSubAdminsParams): GatewayResponse<ISubAdminsReport>;

    abstract setSubAdminProperty(params: SetAssignPropertyParams): GatewayResponse<string | null>;

    abstract deleteSubAdminProperty(params: DeleteSubAdminPropertyParams): GatewayResponse<string | null>
}
