import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors } from '@dm/bigfish';
import { IProperty, IPropertyPackageStatusEnum, PropertyPackages } from '@dm/types';

import { IPropertyPackagesDetails, IUpdatePackageParams } from 'entities/property';

import Modal from 'components/Modal';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import TabView, { TabProps } from 'components/TabView';

interface UpdatePackagePriceModalProps {
    id: string;
    isUpdatePackagePriceModalOpen: boolean;
    editPropertyPackageDetailsAttempt: boolean;
    editPropertyPackageDetailsError: string;
    propertyDetails: IProperty | null;
    setUpdatePackagePriceModalOpen: (state: boolean) => void;
    editPropertyPackagesDetails: (data: IUpdatePackageParams) => void;
}

enum UpdatePackageModalInputType {
    priceInput = 1,
    paidPrice = 2,
    packageName = 3,
}

const UpdatePackagePriceModal: FunctionComponent<UpdatePackagePriceModalProps> = (props: UpdatePackagePriceModalProps) => {
    const {
        id,
        isUpdatePackagePriceModalOpen,
        editPropertyPackageDetailsError,
        editPropertyPackageDetailsAttempt,
        propertyDetails,
        setUpdatePackagePriceModalOpen,
        editPropertyPackagesDetails,
    } = props;

    const [isChange, setIsChange] = useState(false);
    const [statusValues, setStatusValues] = useState<{ [key: string]: string }>({});
    const [priceValues, setPriceValues] = useState<{ [key: string]: string }>({});
    const [termValues, setTermValues] = useState<{ [key: string]: string | undefined }>({});

    const [startDateValues, setStartDateValues] = useState<{[key: string]: Date | undefined}>({});
    const [propertyPackageName, setPropertyPackageName] = useState<{[key: string]: string | undefined}>({});
    const [pricePaid, setPricePaid] = useState<{[key: string]: number | undefined}>({});

    const [selectedTab, setSelectedTab] = useState<PropertyPackages>(PropertyPackages.designRenovation);

    // ? used to assign values from api
    useEffect(() => {
        const initialStatusValues: { [key: string]: string } = {};
        const initialPriceValues: { [key: string]: string } = {};
        const initialTermValues: { [key: string]: string | undefined } = {};
        const initialStartDateValues: {[key: string]: Date | undefined} = {};
        const initialPackageNameValues: { [key: string]: string | undefined} = {};
        const initialPricePaidValues: { [key: string]: number | undefined} = {};

        if (propertyDetails?.packages) {
            const packagesArray = Object.entries(propertyDetails?.packages).map((item) => {
                return item;
            });

            packagesArray.forEach(packageItem => {
                initialStatusValues[packageItem[0]] = (packageItem[1].status) ? String(packageItem[1].status) : IPropertyPackageStatusEnum.NotSet.toString();
                initialPriceValues[packageItem[0]] = (packageItem[1].price) ? String(packageItem[1].price) : '0';
                initialTermValues[packageItem[0]] = (packageItem[1].term) ? String(packageItem[1].term) : '0';
                initialStartDateValues[packageItem[0]] = (packageItem[1].startDate) ? new Date(packageItem[1].startDate) : undefined;
                initialPackageNameValues[packageItem[0]] = packageItem[1].packageName ?? undefined;
                initialPricePaidValues[packageItem[0]] = packageItem[1].paidPrice ?? undefined;
            });

            setStatusValues(initialStatusValues);
            setPriceValues(initialPriceValues);
            setTermValues(initialTermValues);
            setStartDateValues(initialStartDateValues);
            setPropertyPackageName(initialPackageNameValues);
            setPricePaid(initialPricePaidValues);
        }
    }, []);

    // ? used to check for any changes
    useEffect(() => {
        const currentStatusValues: { [key: string]: string } = {};
        const currentPriceValues: { [key: string]: string } = {};
        const currentTermValues: { [key: string]: string } = {};
        const currentStartDateValues: {[key: string]: Date | undefined} = {};
        const currentPackageNameValues: {[key: string]: string | undefined} = {};
        const currentPricePaidValues: {[key: string]: number | undefined} = {};

        if (propertyDetails?.packages) {
            const packagesArray = Object.entries(propertyDetails?.packages).map((item) => {
                return item;
            });

            packagesArray.forEach(packageItem => {
                currentStatusValues[packageItem[0]] = (packageItem[1].status) ? String(packageItem[1].status) : IPropertyPackageStatusEnum.NotSet.toString();
                currentPriceValues[packageItem[0]] = (packageItem[1].price) ? String(packageItem[1].price) : '0';
                currentTermValues[packageItem[0]] = (packageItem[1].term) ? String(packageItem[1].term) : '0';
                currentStartDateValues[packageItem[0]] = (packageItem[1].startDate) ? new Date(packageItem[1].startDate) : undefined;
                currentPackageNameValues[packageItem[0]] = packageItem[1].packageName ?? undefined;
                currentPricePaidValues[packageItem[0]] = packageItem[1].paidPrice ?? undefined;
            });

            if (
                JSON.stringify(statusValues) !== JSON.stringify(currentStatusValues)
                || JSON.stringify(priceValues) !== JSON.stringify(currentPriceValues)
                || JSON.stringify(termValues) !== JSON.stringify(currentTermValues)
                || JSON.stringify(startDateValues) !== JSON.stringify(currentStartDateValues)
                || JSON.stringify(propertyPackageName) !== JSON.stringify(currentPackageNameValues)
                || JSON.stringify(pricePaid) !== JSON.stringify(currentPricePaidValues)
            ) {
                setIsChange(true);
            } else {
                setIsChange(false);
            }
        }
    }, [statusValues, priceValues, termValues, startDateValues, propertyPackageName, selectedTab, pricePaid]);

    const TabListData: TabProps[] = [
        {
            label: 'Vacant Possession',
            value: PropertyPackages.vacantPossession,
            onClick: () => setSelectedTab(PropertyPackages.vacantPossession),
        },
        {
            label: 'Defect Inspection',
            value: PropertyPackages.defectInspection,
            onClick: () => setSelectedTab(PropertyPackages.defectInspection),
        },
        {
            label: 'Design & Renovation',
            value: PropertyPackages.designRenovation,
            onClick: () => setSelectedTab(PropertyPackages.designRenovation),
        },
        {
            label: 'Property Management',
            value: PropertyPackages.propertyManagement,
            onClick: () => setSelectedTab(PropertyPackages.propertyManagement),
        },
        {
            label: 'Vacant Management',
            value: PropertyPackages.vacantManagement,
            onClick: () => setSelectedTab(PropertyPackages.vacantManagement),
        },
    ];

    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>, packageName: string) => {
        const selectedStatus = e.target.value;
        setStatusValues(prevState => ({
            ...prevState,
            [packageName]: selectedStatus,
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, packageName: string, inputType: UpdatePackageModalInputType) => {
        const inputValue = e.target.value;
        // Validate input as float number
        if (/^-?\d*\.?\d*$/.test(inputValue)) {
            if (inputType === UpdatePackageModalInputType.priceInput) {
                setPriceValues(prevState => ({
                    ...prevState,
                    [packageName]: inputValue,
                }));
            }

            if (inputType === UpdatePackageModalInputType.paidPrice) {
                setPricePaid(prevState => ({
                    ...prevState,
                    [packageName]: Number(inputValue),
                }));
            }
        }

        if (inputType === UpdatePackageModalInputType.packageName) {
            setPropertyPackageName(prevState => ({
                ...prevState,
                [packageName]: inputValue,
            }));
        }
    };

    const handleTermChange = (e: React.ChangeEvent<HTMLSelectElement>, packageName: string) => {
        const selectedTerm = e.target.value;
        setTermValues(prevState => ({
            ...prevState,
            [packageName]: selectedTerm,
        }));
    };

    const handleStartDateChange = (newDate: Date, packageName: string) => {
        setStartDateValues(prevState => ({
            ...prevState,
            [packageName]: newDate,
        }));
    };

    const cancelClickHandler = () => {
        setUpdatePackagePriceModalOpen(false);
        setIsChange(false);
        const initialStatusValues: { [key: string]: string } = {};
        const initialPriceValues: { [key: string]: string } = {};
        const initialTermValues: { [key: string]: string } = {};
        const initialStartDateValues: {[key: string]: Date | undefined} = {};
        const initialPackageNameValues: { [key: string]: string | undefined} = {};
        const initialPricePaidValues: { [key: string]: number | undefined} = {};

        if (propertyDetails?.packages) {
            const packagesArray = Object.entries(propertyDetails?.packages).map((item) => {
                return item;
            });

            packagesArray.forEach(packageItem => {
                initialStatusValues[packageItem[0]] = (packageItem[1].status) ? String(packageItem[1].status) : IPropertyPackageStatusEnum.NotSet.toString();
                initialPriceValues[packageItem[0]] = (packageItem[1].price) ? String(packageItem[1].price) : '0';
                initialTermValues[packageItem[0]] = (packageItem[1].term) ? String(packageItem[1].term) : '0';
                initialStartDateValues[packageItem[0]] = (packageItem[1].startDate) ? new Date(packageItem[1].startDate) : undefined;
                initialPackageNameValues[packageItem[0]] = packageItem[1].packageName ?? undefined;
                initialPricePaidValues[packageItem[0]] = packageItem[1].paidPrice ?? undefined;
            });

            setStatusValues(initialStatusValues);
            setPriceValues(initialPriceValues);
            setTermValues(initialTermValues);
            setStartDateValues(initialStartDateValues);
            setPropertyPackageName(initialPackageNameValues);
            setPricePaid(initialPricePaidValues);
        }
    };

    const confirmClickHandler = () => {
        const vacantPossesionData:IPropertyPackagesDetails = {
            status: Number(statusValues.vacantPossession),
            price: Number(priceValues.vacantPossession),
            startDate: (startDateValues.vacantPossession) ? startDateValues.vacantPossession.toISOString() : undefined,
            term: (termValues.vacantPossession) ? Number(termValues.vacantPossession) : undefined,
        };
        const defectInspectionData:IPropertyPackagesDetails = {
            status: Number(statusValues.defectInspection),
            price: Number(priceValues.defectInspection),
            startDate: (startDateValues.defectInspection) ? startDateValues.defectInspection.toISOString() : undefined,
            term: (termValues.defectInspection) ? Number(termValues.defectInspection) : undefined,
            paidPrice: pricePaid.defectInspection ? Number(pricePaid.defectInspection) : undefined,
        };
        const designRenovationData:IPropertyPackagesDetails = {
            status: Number(statusValues.designRenovation),
            price: Number(priceValues.designRenovation),
            startDate: (startDateValues.designRenovation) ? startDateValues.designRenovation.toISOString() : undefined,
            term: (termValues.designRenovation) ? Number(termValues.designRenovation) : undefined,
            packageName: propertyPackageName.designRenovation ?? undefined,
            paidPrice: pricePaid.designRenovation ? Number(pricePaid.designRenovation) : undefined,
        };
        const propertyManagementData:IPropertyPackagesDetails = {
            status: Number(statusValues.propertyManagement),
            price: Number(priceValues.propertyManagement),
            startDate: (startDateValues.propertyManagement) ? startDateValues.propertyManagement.toISOString() : undefined,
            term: (termValues.propertyManagement) ? Number(termValues.propertyManagement) : undefined,
        };
        const vacantManagementData:IPropertyPackagesDetails = {
            status: Number(statusValues.vacantManagement),
            price: Number(priceValues.vacantManagement),
            startDate: (startDateValues.vacantManagement) ? startDateValues.vacantManagement.toISOString() : undefined,
            term: (termValues.vacantManagement) ? Number(termValues.vacantManagement) : undefined,
        };

        const dataToSubmit: IUpdatePackageParams = {
            propertyId: id,
            vacantPossession: vacantPossesionData,
            defectInspection: defectInspectionData,
            designRenovation: designRenovationData,
            propertyManagement: propertyManagementData,
            vacantManagement: vacantManagementData,
        };

        editPropertyPackagesDetails(dataToSubmit);
        setIsChange(false);
    };

    const renderModalHeader = () => {
        return (
            'Update Service Package Details'
        );
    };

    const renderTabContent = () => {
        const renderPackageStatus = (packageName: string) => {
            return (
                <select
                    name='status'
                    id={packageName}
                    value={statusValues[packageName] ?? IPropertyPackageStatusEnum.NotSet}
                    onChange={(e) => handleStatusChange(e, packageName)}
                    style={{
                        padding: '5px',
                    }}
                >
                    <option value='' disabled>Select Package</option>
                    <option value='1'>Pending</option>
                    <option value='2'>Pending Payment Verification</option>
                    <option value='3'>Active</option>
                    <option value='4'>Payment Rejected</option>
                    <option value='5'>Disabled</option>
                    <option value='6'>Free Trial</option>
                    <option value='7'>Pending Signature</option>
                    <option value='8'>Not Set</option>

                </select>
            );
        };

        const renderPackagePrice = (packageName: string) => {
            return (
                <input
                    key={packageName}
                    value={priceValues[packageName] ?? 0}
                    onChange={e => handleInputChange(e, packageName, UpdatePackageModalInputType.priceInput)}
                    style={{
                        padding: '5px 10px',
                    }}
                />
            );
        };

        const renderPackageTerm = (packageName: string) => {
            return (
                <select
                    name='term'
                    id={packageName}
                    value={termValues[packageName] ?? 0}
                    onChange={e => handleTermChange(e, packageName)}
                    style={{
                        padding: '5px',
                    }}
                >
                    <option value='' disabled>Select Term</option>
                    <option value='0'>Not Set</option>
                    <option value='1'>Six Month</option>
                    <option value='2'>One Year</option>
                    <option value='3'>Two Years</option>
                </select>
            );
        };

        const renderPackageStartDate = (packageName: string) => {
            return (
                <DateTimePicker
                    onChange={(newDate: Date) => handleStartDateChange(newDate, packageName)}
                    value={(!startDateValues[packageName]) ? 'Select Date' : dayjs(startDateValues[packageName]).format('DD-MM-YYYY')}
                    selected={startDateValues[packageName] ?? undefined}
                    dateFormat='MMMM d, yyyy HH:mm'
                    shouldCloseOnSelect
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    disabled={!(Number(statusValues[packageName]) === IPropertyPackageStatusEnum.Active)}
                />

            );
        };

        const renderPackageName = (packageName: string) => {
            return (
                <input
                    key={packageName}
                    value={propertyPackageName[packageName] ?? ''}
                    onChange={e => handleInputChange(e, packageName, UpdatePackageModalInputType.packageName)}
                    style={{
                        padding: '5px 10px',
                    }}
                />
            );
        };

        const renderPaidPrice = (packageName: string) => {
            return (
                <input
                    key={packageName}
                    value={pricePaid[packageName] ?? 0}
                    onChange={e => handleInputChange(e, packageName, UpdatePackageModalInputType.paidPrice)}
                    style={{
                        padding: '5px 10px',
                    }}
                />
            );
        };

        const inputRow = [
            {
                label: 'Status',
                value: renderPackageStatus(PropertyPackages[selectedTab]),
            },
            {
                label: 'Price',
                value: renderPackagePrice(PropertyPackages[selectedTab]),
            },
            {
                label: 'Term',
                value: renderPackageTerm(PropertyPackages[selectedTab]),
            },
            {
                label: 'Renovation Name',
                value: renderPackageName(PropertyPackages[selectedTab]),
            },
            {
                label: 'Paid Price',
                value: renderPaidPrice(PropertyPackages[selectedTab]),
            },
            {
                label: 'Start Date',
                value: renderPackageStartDate(PropertyPackages[selectedTab]),
            },
        ];

        const renderInput = () => {
            if (selectedTab === PropertyPackages.defectInspection) {
                return inputRow.filter((item) => item.label !== 'Renovation Name').map((item) => {
                    return (
                        <InputGrid key={`${item.label}`}>
                            <div>
                                {item.label}
                            </div>
                            {item.value}
                        </InputGrid>
                    );
                });
            }

            if (selectedTab !== PropertyPackages.designRenovation) {
                return inputRow.filter((item) =>
                    item.label !== 'Renovation Name'
                && item.label !== 'Total Price'
                && item.label !== 'Paid Price').map((item) => {
                    return (
                        <InputGrid key={`${item.label}`}>
                            <div>
                                {item.label}
                            </div>
                            {item.value}
                        </InputGrid>
                    );
                });
            }

            return inputRow.map((item) => (
                <InputGrid key={`${item.label}`}>
                    <div>
                        {item.label}
                    </div>
                    {item.value}
                </InputGrid>
            ));
        };

        return (
            <TabContentGrid>
                {renderInput()}
            </TabContentGrid>
        );
    };

    const renderModalFooter = () => {
        return (
            <>
                <WarningText>
                    { isChange ? 'You have made some changes! Please click save to update.' : ''}
                </WarningText>

                <div>
                    <Button
                        label='Cancel'
                        style={{
                            marginRight: '10px',
                            backgroundColor: Colors.white,
                            color: Colors.primary,
                            border: `1px solid ${Colors.primary}`,
                        }}
                        onClick={cancelClickHandler}
                        disabled={editPropertyPackageDetailsAttempt}
                    />
                    <Button
                        label='Update'
                        style={{ width: '100px' }}
                        onClick={confirmClickHandler}
                        loading={editPropertyPackageDetailsAttempt}
                        disabled={editPropertyPackageDetailsAttempt}
                    />
                </div>
            </>
        );
    };

    return (
        <Modal
            show={isUpdatePackagePriceModalOpen}
            width='60%'
            height='fit-content'
        >
            <ModalHeader>
                {renderModalHeader()}
            </ModalHeader>

            <ModalBody>
                <TabView
                    tabList={TabListData}
                    selectedTab={selectedTab}
                    tabContent={renderTabContent()}
                />
            </ModalBody>

            <ModalFooter>
                {renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin: 20px 30px;
`;

const ModalBody = styled.div`
    margin: 30px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 30px;
`;

const WarningText = styled.div`
    color: ${Colors.danger};
    font-weight: bold;
    font-style: italic;
    align-items: center;
    display: flex;
`;

const DateTimePicker = styled(ReactDatePicker)`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    /* font-size: 1rem; */
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${Colors.black};
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    &:focus, &:active{
        border-color: rgba(61, 39, 78, 1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(61, 39, 78, 0.6);
        outline: 0 none;
    }
    &:disabled{
        background-color: ${Colors.grey};
        cursor: not-allowed;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${Colors.primary};
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${Colors.primary};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${Colors.primary};
    }
`;

const TabContentGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-auto-flow: column;

    width: 100%;
`;

const InputGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;

    align-items: center;
`;

const mapStateToProps = (state: RootState) => ({
    editPropertyPackageDetailsAttempt: Selectors.getPropertyEditPropertyPackagesDetailsAttempting(state),
    editPropertyPackageDetailsError: Selectors.getPropertyEditPropertyPackagesDetailsError(state),
    isUpdatePackagePriceModalOpen: Selectors.getPropertyGetPackagePriceModalOpen(state),
    propertyDetails: Selectors.getPropertyPropertyDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setUpdatePackagePriceModalOpen: (state: boolean) => dispatch(Actions.setUpdatePackagePriceModalOpen(state)),
    editPropertyPackagesDetails: (data: IUpdatePackageParams) => dispatch(Actions.editPropertyPackagesDetailsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePackagePriceModal);
