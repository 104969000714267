import React from 'react';
import styled from 'styled-components';

import { ContactButton } from '@dm/bigfish';

interface SupportInfoProps {
  style?: React.CSSProperties;
}

const SupportInfo = ({ style, ...otherProps }: SupportInfoProps): JSX.Element => {
    return (
        <Container style={style} {...otherProps}>
            <Title>Need Assistance?</Title>
            <Desc>Request a property specialist for further enquiries.</Desc>
            <ContactButton name='Dormeo' phoneNumber='+60 12-345 1231' />
        </Container>
    );
};

SupportInfo.defaultProps = {
    style: undefined,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px ;
  color: #000000;
  border-radius: 12px;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

  >:not(:last-child) {
        margin-bottom: 20px;
  }
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

const Desc = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

export default SupportInfo;
