import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import {
    Category,
    IDefectInspection,
    IGetEventTemplate,
    IPaginatedAdminPropertyManagementWithdrawalResponse,
    IPaginatedViewAllRecurringPMTransactionResponse,
    IPropertyDefectInspectionStage,
    IPropertyManagement,
    IVacantManagement,
    IVacantPossession,
    NumToBoolean,
    PMTransactionRecurringFrequencyTypeEnum,
    PaginatedViewAllPMTransactionsResponse,
    PropertyManagementTransactionResponse,
} from '@dm/types';
import { ILeaseSale } from 'entities/packages';
import { IPropertyWallet } from 'entities/property';

export interface CreateEventParams {
  authToken: string,
  data: {
    propertyId: string,
    category: number,
    status: number,
    message: string,
    saveAsTemplate: boolean
  }
}

export interface GetEventTemplateParams {
  authToken: string,
  category: number,
}

export interface PackagesGetILeaseSaleParams {
  category: number;
  propertyId: string;
  sort: number;
  authToken: string;
}

export interface GetPackagesUploadUrlParams {
  authToken: string;
  category: Category;
  id: string;
  name: string;
  extension: string;
}

export interface UploadPackagesDocumentParams {
  url: string;
  doc: FormData;
}

export interface PackagesGetDataSortParams {
  category: number;
  propertyId: string;
  sort: number;
  authToken: string;
}

export interface PackagesGetAllTransactionDataSortParams {
  search: string,
  status: number,
  transactionType: number,
  sort: number,
  index: number,
  authToken: string;
}

export interface PackagesGetDataTransactionSortParams {
  propertyId: string;
  sort: number;
  authToken: string;
}

export interface UpdateTransactionStatusParams {
  propertyId: string;
  transactionId: string;
  status: number;
  authToken: string;
}

export interface CreateTransactionStatusParams {
  propertyId: string;
  amount: number;
  description: string;
  transactionDate?: string,
  transactionType: number;
  isRecurring: boolean;
  startDate?: string,
  endDate?: string,
  interval?: number,
  frequency?: PMTransactionRecurringFrequencyTypeEnum,
  authToken: string;
  proceedWithNegativeBalance?: boolean;
}

export interface GetTransactionsReportExportParams {
  authToken: string;
  propertyId: string;
}

export interface IGetTransactionsReportExport {
  [key: string]: string | number | boolean | Date | null;
}

export interface EditTransactionParams {
  authToken: string;
  propertyId: string;
  transactionId: string;
  description: string;
  transactionDate: string;
}
export interface EditRecurringTransactionParams {
  authToken: string;
  id: string;
  amount: number;
  description: string;
  transactionType: number;
  startDate: string;
  endDate: string;
  interval: number;
  frequency: number;
}

export type GetPropertyWalletParams = {
  authToken: string;
  propertyId: string;
}

export type UpdateProgressDefectInspectionParams = {
  authToken: string;
  propertyId: string;
  stage: IPropertyDefectInspectionStage;
  remark: string;
  date: string;
}

export interface GetPropertyManagementWithdrawalParams {
  status: number;
  search?: string;
  includePreviousOwner: NumToBoolean;
  index: number;
  sort: number;
  authToken: string;
}

export interface EditPropertyManagementWithdrawalParams {
  authToken: string;
  withdrawalId: string;
  status: number;
}

export abstract class IPackagesGateway extends Gateway {
  abstract createEvent(params: CreateEventParams): GatewayResponse<string | null>;

  abstract getEventTemplate(params: GetEventTemplateParams): GatewayResponse<IGetEventTemplate[]>;

  abstract getPackagesLeaseSaleSort(params: PackagesGetILeaseSaleParams): GatewayResponse<ILeaseSale | null>;

  abstract getPackagesUploadUrl(params: GetPackagesUploadUrlParams): GatewayResponse<string>;

  abstract uploadPackagesDocument(params: UploadPackagesDocumentParams): GatewayResponse<null>;

  abstract getPackagesVacantPossessionSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantPossession | null>;

  abstract getPackagesDefectInspectionSort(params: PackagesGetDataSortParams): GatewayResponse<IDefectInspection | null>;

  abstract getPackagesVacantManagementSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantManagement | null>;

  abstract getPackagesPropertyManagementSort(params: PackagesGetDataSortParams): GatewayResponse<IPropertyManagement | null>;

  abstract getPackagesPropertyManagementTransactionSort(params: PackagesGetDataTransactionSortParams): GatewayResponse<PropertyManagementTransactionResponse[] | null>;

  abstract getPackagesViewAllTransactionsResponse(params: PackagesGetAllTransactionDataSortParams): GatewayResponse<PaginatedViewAllPMTransactionsResponse | null>;

  abstract getPackagesViewAllRecurringTransactionsResponse(params: PackagesGetAllTransactionDataSortParams): GatewayResponse<IPaginatedViewAllRecurringPMTransactionResponse | null >;

  abstract setPackagesUpdateTransactionStatusResponse(params: UpdateTransactionStatusParams): GatewayResponse<string | null>;

  abstract setPackagesEditTransaction(params: EditTransactionParams): GatewayResponse<string | null>;

  abstract setPackagesEditRecurringTransaction(params: EditRecurringTransactionParams): GatewayResponse<string | null>;

  abstract setPackagesPackagesCreatePMTransaction(params: CreateTransactionStatusParams): GatewayResponse<{ id: string } | null>;

  abstract getTransactionsReportExport(params: GetTransactionsReportExportParams): GatewayResponse<IGetTransactionsReportExport[]>;

  abstract getPropertyWallet(params: GetPropertyWalletParams): GatewayResponse<IPropertyWallet | null>;

  abstract updateProgressDefectInspection(params: UpdateProgressDefectInspectionParams): GatewayResponse<string | null>;

  abstract getPropertyManagementWithdrawal(params: GetPropertyManagementWithdrawalParams): GatewayResponse<IPaginatedAdminPropertyManagementWithdrawalResponse>;

  abstract editPropertyManagementWithdrawal(params: EditPropertyManagementWithdrawalParams): GatewayResponse<string>;
}
