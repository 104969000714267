import React, { useState } from 'react';
import { Colors, Paginator } from '@dm/bigfish';
import Translate from 'i18n-js';
import {
    IPaginatedAdminPropertyManagementWithdrawalResponse,
    PropertyManagementWithdrawalStatus,
} from '@dm/types';
import dayjs from 'dayjs';
import { Bars } from 'react-loader-spinner';

import icons from 'assets/icons';

import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { IEditPropertyManagementWithdrawalParams } from '../../../entities/packages';
import StyledComponent from '../styles/WithdrawalStyle';

interface ComponentProps {
    // props from parent
    withdrawalData: IPaginatedAdminPropertyManagementWithdrawalResponse | null;

    // Paginator
    paginationNumber: number;
    paginationData: (string | number)[] | [];
    nextPaginationNumber: () => void;
    prevPaginationNumber: () => void;
    firstPaginationNumber: () => void;
    lastPaginationNumber: () => void;
    onClickPaginationNumber: (pages: number) => void;

    // Dispatch props
    getWithdrawalTransactionLoading: boolean;
    updateWithdrawalTransactionStatus: (params: IEditPropertyManagementWithdrawalParams) => void;

}

const WithdrawalRequestListTable = (props: ComponentProps):JSX.Element => {
    const {

        paginationNumber,
        paginationData,
        nextPaginationNumber,
        prevPaginationNumber,
        firstPaginationNumber,
        lastPaginationNumber,
        onClickPaginationNumber,
        withdrawalData,
        getWithdrawalTransactionLoading,
        updateWithdrawalTransactionStatus,
    } = props;

    const {
        WithdrawalListTable,
        WithdrawalListingHeader,
        WithdrawalListRow,
        NameContainer,
        ActionButtonContainer,
        StyledIconButton,
        Icons,
        RowItem,
        WithdrawalListEmpty,
        LoadingContainer,
    } = StyledComponent;

    const handleWithdrawalStatusUpdate = (withdrawalId: string, status: number) => {
        updateWithdrawalTransactionStatus({ withdrawalId, status });
    };

    const renderDocumentsActions = (withdrawalId: string, status: number) => {
        let disableAction = false;

        if (status === PropertyManagementWithdrawalStatus.approved || status === PropertyManagementWithdrawalStatus.rejected) {
            disableAction = true;
        }

        return (
            <ActionButtonContainer>
                <StyledIconButton
                    disabled={disableAction}
                    onClick={() => handleWithdrawalStatusUpdate(withdrawalId, PropertyManagementWithdrawalStatus.approved)}
                >
                    <Icons src={icons.checkmark} width='100%' height='20px' color={(!disableAction) ? Colors.success : Colors.greyDark} />
                </StyledIconButton>
                <StyledIconButton
                    disabled={disableAction}
                    onClick={() => handleWithdrawalStatusUpdate(withdrawalId, PropertyManagementWithdrawalStatus.rejected)}
                    style={{ alignSelf: 'center' }}
                >
                    <Icons src={icons.xmark} width='100%' height='20px' color={(!disableAction) ? '#ED0800' : Colors.greyDark} />
                </StyledIconButton>
            </ActionButtonContainer>
        );
    };

    const renderWithdrawalRows = () => {
        if (getWithdrawalTransactionLoading) {
            return (
                <LoadingContainer>
                    <Bars
                        color={Colors.primary}
                        height={260}
                        width={260}
                    />
                </LoadingContainer>
            );
        }

        if (!withdrawalData || withdrawalData.data.length < 1) {
            return (
                <WithdrawalListEmpty>
                    <p>No data available!</p>
                </WithdrawalListEmpty>
            );
        }

        return withdrawalData.data.map((withdrawal) => {
            const {
                status,
                amount,
                createdAt,
                owneruser,
                property,
                _id,
            } = withdrawal;

            const uniqueKey = _id;

            const owner = owneruser.firstName ? `${owneruser.firstName} ${owneruser.lastName}` : owneruser.email;

            return (
                <WithdrawalListRow
                    key={uniqueKey}
                >
                    <NameContainer>{property.name}</NameContainer>
                    <RowItem>{property.unitNo}</RowItem>
                    <RowItem>{owner}</RowItem>
                    <RowItem>{`RM ${amount}`}</RowItem>
                    <RowItem>{PropertyManagementWithdrawalStatus[status]}</RowItem>
                    <RowItem>{dayjs(new Date(createdAt)).format('DD/MM/YYYY')}</RowItem>
                    {renderDocumentsActions(_id, status)}
                </WithdrawalListRow>
            );
        });
    };

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Paginator
                    currentIndex={paginationNumber}
                    data={paginationData}
                    onNext={nextPaginationNumber}
                    onPrev={prevPaginationNumber}
                    onFirst={firstPaginationNumber}
                    onLast={lastPaginationNumber}
                    onClickIndex={onClickPaginationNumber}
                />
            </div>
            <WithdrawalListTable>
                <WithdrawalListingHeader>
                    <h4>{Translate.t('Packages.PropertyManagement.Withdrawal.TableHeader.propertyName')}</h4>
                    <h4>{Translate.t('Packages.PropertyManagement.Withdrawal.TableHeader.unitNo')}</h4>
                    <h4>{Translate.t('Packages.PropertyManagement.Withdrawal.TableHeader.propertyOwner')}</h4>
                    <h4>{Translate.t('Packages.PropertyManagement.Withdrawal.TableHeader.withdrawalAmount')}</h4>
                    <h4>{Translate.t('Packages.PropertyManagement.Withdrawal.TableHeader.withdrawalStatus')}</h4>
                    <h4>{Translate.t('Packages.PropertyManagement.Withdrawal.TableHeader.dateCreated')}</h4>
                    <h4>{Translate.t('Packages.PropertyManagement.Withdrawal.TableHeader.action')}</h4>
                </WithdrawalListingHeader>

                {renderWithdrawalRows()}
            </WithdrawalListTable>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Paginator
                    currentIndex={paginationNumber}
                    data={paginationData}
                    onNext={nextPaginationNumber}
                    onPrev={prevPaginationNumber}
                    onFirst={firstPaginationNumber}
                    onLast={lastPaginationNumber}
                    onClickIndex={onClickPaginationNumber}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    getWithdrawalTransactionLoading: Selectors.packagesGetPropertyManagementWithdrawalAttempt(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updateWithdrawalTransactionStatus: (params: IEditPropertyManagementWithdrawalParams) => dispatch(Actions.editPropertyManagementWithdrawalAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalRequestListTable);
