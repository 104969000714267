import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import { Bars } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { BreadCrumb, Colors, Paginator, ContactButton, SearchBar } from '@dm/bigfish';

import MainContainer from 'components/MainContainer';

import { IServerOwnerReport, IGetAllOwner } from 'entities/owners';
import Popover from './components/Popover';
import MultiSearchbar from './components/MultiSearchbar';

interface OwnersReportProps {
    getOwnerReportLoading: boolean;
    getOwnerReportError: string;
    ownersReport: IServerOwnerReport;
    getOwners: (data: IGetAllOwner) => void;
    clearOwnersReport: () => void;
    setTabView: (tabView: string) => void;
    getUserInfoData: () => void;
}

const OwnersReport = (props: OwnersReportProps): JSX.Element => {
    const { getOwnerReportLoading, getOwnerReportError, ownersReport, getOwners, clearOwnersReport, setTabView, getUserInfoData } = props;

    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchPropertyNameKeyword, setSearchPropertyNameKeyword] = useState('');
    const [searchUnitNoKeyword, setSearchUnitNoKeyword] = useState('');
    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('OwnersReport.OwnersReportBcDashboard'),
                onClick: () => { NavActions.navToReportsOwner(); },
            },
            {
                label: Translate.t('OwnersReport.OwnersReportBcOwners'),
            },
        ],
    );

    useEffect(() => {
        getOwners({ index: 1 });
        setTabView('owners');
        getUserInfoData();

        return () => {
            clearOwnersReport();
        };
    }, []);

    useEffect(() => {
        const { maxIndex } = ownersReport;

        setPaginationData(createPaginationData(maxIndex));
        setMaxPages(maxIndex);
    }, [ownersReport]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        if (currentNumber !== paginationNumber) {
            getOwners({ index: currentNumber, query: searchKeyword, propertyName: searchPropertyNameKeyword, unitNo: searchUnitNoKeyword });
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);
        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        if (currentNumber !== paginationNumber) {
            getOwners({ index: currentNumber, query: searchKeyword, propertyName: searchPropertyNameKeyword, unitNo: searchUnitNoKeyword });
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);

        if (paginationNumber !== 1) {
            getOwners({ index: 1, query: searchKeyword, propertyName: searchPropertyNameKeyword, unitNo: searchUnitNoKeyword });
            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);
        if (paginationNumber !== maxPages) {
            getOwners({ index: maxPages, query: searchKeyword, propertyName: searchPropertyNameKeyword, unitNo: searchUnitNoKeyword });
            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);
        if (pages !== paginationNumber) {
            getOwners({ index: pages, query: searchKeyword, propertyName: searchPropertyNameKeyword, unitNo: searchUnitNoKeyword });
            winScrollToTop();
        }
    };

    const throttledSetSearch = useRef(throttle((searchWord: string, searchPropertyName: string, searchUnitNo: string) => {
        const paramObject: IGetAllOwner = { index: 1 };

        paramObject.query = searchWord || paramObject.query;

        paramObject.propertyName = searchPropertyName || paramObject.propertyName;

        paramObject.unitNo = searchUnitNo || paramObject.unitNo;

        getOwners(paramObject);
    }, 500, { leading: false }));

    const onSearchOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setSearchKeyword(value);
    };
    const onSearchPropertyName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchPropertyNameKeyword(value);
    };

    const onSearchUnitNo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchUnitNoKeyword(value);
    };

    useEffect(() => {
        throttledSetSearch.current(searchKeyword, searchPropertyNameKeyword, searchUnitNoKeyword);
    }, [searchKeyword, searchPropertyNameKeyword, searchUnitNoKeyword]);

    const multiSearchBarValue = [
        {
            label: 'Search',
            eventHandler: onSearchOwner,
        },
        {
            label: 'Property name',
            eventHandler: onSearchPropertyName,
        },
        {
            label: 'Unit no',
            eventHandler: onSearchUnitNo,
        },
    ];

    const renderOwnersListing = () => {
        const { data } = ownersReport;

        if (getOwnerReportLoading) {
            return (
                <LoadingContainer>
                    <Bars color={Colors.primary} height={260} width={260} />
                </LoadingContainer>
            );
        }

        return (
            <>
                {data.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}
                <ReportListingTable>
                    <ReportListingHeader>
                        <ReportHeader>{Translate.t('OwnersReport.OwnersReportHeaderName')}</ReportHeader>
                        <ReportHeader>{Translate.t('OwnersReport.OwnersReportHeaderGender')}</ReportHeader>
                        <ReportHeader>{Translate.t('OwnersReport.OwnersReportHeaderLocation')}</ReportHeader>
                        <ReportHeader>{Translate.t('OwnersReport.OwnersReportHeaderProp')}</ReportHeader>
                        <ReportHeader>{Translate.t('OwnersReport.OwnersReportHeaderMonthlyExpense')}</ReportHeader>
                    </ReportListingHeader>

                    {
                        data.map((owner) => {
                            const {
                                email,
                                userId,
                                name,
                                phoneNumber,
                                gender,
                                location,
                                monthlyExpense,
                                properties,
                                isFirstSignUp,
                            } = owner;

                            let ownerName = '';
                            if (name && name !== 'undefined undefined') {
                                ownerName = name;
                            } else {
                                ownerName = email;
                            }

                            return (
                                !isFirstSignUp ? (
                                    <ReportListingColumn key={`key is ${userId}`}>
                                        <ButtonDiv onClick={() => NavActions.navToOwnerDetails(userId || '')}>
                                            <ContactButton name={ownerName} phoneNumber={phoneNumber} />
                                        </ButtonDiv>
                                        <ReportColumn>{gender}</ReportColumn>
                                        <ReportColumn>{location}</ReportColumn>
                                        <ReportColumn>
                                            <Popover ownerProperties={properties} />
                                        </ReportColumn>
                                        <ReportColumn>
                                            { monthlyExpense ? `RM ${monthlyExpense}` : 'RM 0'}
                                        </ReportColumn>
                                    </ReportListingColumn>
                                ) : (
                                    <ReportListingColumn key={`key is ${userId}`} style={{ cursor: 'default' }}>
                                        <ContactButton name={email} chipText='uncompleted' />
                                    </ReportListingColumn>
                                )
                            );
                        })
                    }

                    {(!data.length && !getOwnerReportError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('OwnersReport.OwnersReportError1')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError2')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError3')}</p>
                        </ReportListingColumnEmpty>
                    )}

                    {(!data.length && getOwnerReportError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('OwnersReport.OwnersReportError4')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError5')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError6')}</p>
                        </ReportListingColumnEmpty>
                    )}

                </ReportListingTable>
                {data.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}

            </>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <ReportContainer>
                    <Title>
                        {Translate.t('OwnersReport.OwnersReportSearchTitle')}
                    </Title>

                    <MultiSearchbar inputs={multiSearchBarValue} />

                    <ReportContentContainer>
                        <ReportListingContainer>
                            {renderOwnersListing()}
                        </ReportListingContainer>

                        {/* TEMP */}
                        {/* <RecentContainer>
                            <RecentViewedContainer>
                                <RecentTitle>{Translate.t('OwnersReport.OwnersReportRecentViewed')}</RecentTitle>
                                <RecentOwner name='John Lim' phoneNumber='012793244' />
                                <RecentOwner name='John Lim' phoneNumber='012793244' />
                                <RecentOwner name='John Lim' phoneNumber='012793244' />
                                <RecentOwner name='John Lim' phoneNumber='012793244' />
                                <MoreButton>
                                    <Button label={Translate.t('OwnersReport.OwnersReportButtonMore')} />
                                </MoreButton>
                            </RecentViewedContainer>
                            <RecentLocationContainer>
                                <RecentTitle>{Translate.t('OwnersReport.OwnersReportMoreLocations')}</RecentTitle>
                                <LocationButton city='Bangsar' noProperties='599' />
                                <LocationButton city='Bangsar' noProperties='599' />
                                <LocationButton city='Bangsar' noProperties='599' />
                                <LocationButton city='Bangsar' noProperties='599' />
                                <MoreButton>
                                    <Button label={Translate.t('OwnersReport.OwnersReportButtonMore')} />
                                </MoreButton>
                            </RecentLocationContainer>
                        </RecentContainer> */}
                    </ReportContentContainer>
                </ReportContainer>
            </MainContainer>
        </>
    );
};

const ButtonDiv = styled.div`
    
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 120px 20px;
  color: ${Colors.black};
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const ReportContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ReportListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ReportListingTable = styled.div`
  width: 100%;
  margin: 20px 0 20px 0;

  > div:not(:last-child){
    margin-bottom: 8px;
  }
`;

const ReportListingHeader = styled.div`
  display: grid;
  grid-template-columns: 280px repeat(4, 1fr);
  align-items: center;
  padding: 16px 9px;
  box-sizing: border-box;
  background: ${Colors.white};
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

const ReportHeader = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;

  &:not(:first-child){
    text-align: center;
  }
`;

const ReportListingColumn = styled.button`
  display: grid;
  grid-template-columns: 280px repeat(4, 1fr);
  align-items: center;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  border-radius: 12px;
  margin-bottom: 5px;
  cursor: pointer;

  background: ${Colors.primaryLightest};
`;

const ReportColumn = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;

  color: ${Colors.greyDark};
`;

const ReportListingColumnEmpty = styled.div`
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;

  color: ${Colors.greyDark};
  background: ${Colors.primaryLightest};

  > p {
    margin: 0px;
    padding: 0px;
  }

  > p:not(:last-child) {
    margin-bottom: 3px;
  }

  > p:first-child{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 14px;
  }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 580px;
`;

const mapStateToProps = (state: RootState) => ({
    getOwnerReportLoading: Selectors.getOwnersGetAllAttempt(state),
    getOwnerReportError: Selectors.getOwnersGetAllError(state),
    ownersReport: Selectors.getOwnersOwnersReport(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getOwners: (data: IGetAllOwner) =>
        dispatch(Actions.ownersGetAllAttempt(data)),
    clearOwnersReport: () => dispatch(Actions.clearOwnersReport()),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnersReport);
