import { CSSObject, FlattenSimpleInterpolation, SimpleInterpolation, css } from 'styled-components';

const device = {
  xs: '400px',
  sm: '600px',
  md: '1024px',
  lg: '1280px',
  xl: '1440px',
  xxl: '1920px',
};

type MediaFunction = (...args: (CSSObject | TemplateStringsArray)[]) => SimpleInterpolation;

const media = {
  xs: (...args: any): SimpleInterpolation => css`
    @media (max-width: ${device.xs}) {
      ${css(args)};
    }
  `,
  sm: (...args: any): SimpleInterpolation => css`
    @media (max-width: ${device.sm}) {
      ${css(args)};
    }
  `,
  md: (...args: any): SimpleInterpolation => css`
    @media (max-width: ${device.md}) {
      ${css(args)};
    }
  `,
  lg: (...args: any): SimpleInterpolation => css`
    @media (max-width: ${device.lg}) {
      ${css(args)};
    }
  `,
  xl: (...args: any): SimpleInterpolation => css`
    @media (max-width: ${device.xl}) {
      ${css(args)};
    }
  `,
  xxl: (...args: any): SimpleInterpolation => css`
    @media (max-width: ${device.xxl}) {
      ${css(args)};
    }
  `,
};

export default media;
