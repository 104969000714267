import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';

import Translate from 'lib/translate';

import { FatButton, EventTrail } from '@dm/bigfish';
import { IGetUserInfo } from 'entities/Auth';
import { IAuthUserRole } from '@dm/types';
import media from 'lib/Breakpoints';

interface ModulesProps {
    getUserInfo: IGetUserInfo;
    getUserInfoData: () => void;
    setModulesCreateModal: (status: boolean) => void;
}

const Modules = (props: ModulesProps): JSX.Element => {
    const { getUserInfo, getUserInfoData, setModulesCreateModal } = props;

    const [pendingActivities, setPendingActivities] = useState([]);
    const [pendingApprovals, setPendingApprovals] = useState([]);

    useEffect(() => {
        getUserInfoData();
    }, []);

    return (
        <Container>
            <SelectionContainer>
                <FatButton
                    title={Translate.t('Modules.ModulesCreate')}
                    subtitle={Translate.t('Modules.ModulesCreateDesc')}
                    onClick={() => { setModulesCreateModal(true); }}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesPropertyListings')}
                    subtitle={Translate.t('Modules.ModulesPropertyListingsDesc')}
                    onClick={() => NavActions.navToPropertyReports()}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesOwner')}
                    subtitle={Translate.t('Modules.ModulesOwnerDesc')}
                    onClick={() => NavActions.navToReportsOwner()}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesRea')}
                    subtitle={Translate.t('Modules.ModulesReaDesc')}
                    onClick={() => { NavActions.navToReaReport(); }}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesPayments')}
                    subtitle={Translate.t('Modules.ModulesPaymentsDesc')}
                    onClick={() => NavActions.navToPayments()}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesReviewCoBroke')}
                    subtitle={Translate.t('Modules.ModulesReviewCoBrokeDesc')}
                    onClick={() => NavActions.navToCobrokesReport()}
                />
                {
                    (getUserInfo.role === IAuthUserRole.Admin) ? (
                        <FatButton
                            title={Translate.t('Modules.ModulesSubAdmin')}
                            subtitle={Translate.t('Modules.ModulesSubAdminDesc')}
                            onClick={() => NavActions.navToReportSubAdmins()}
                        />

                    ) : null
                }

            </SelectionContainer>
            {/* TEMP */}
            {/* <EventsContainer>
                <EventTrail
                    primary
                    title={Translate.t('Modules.ModulesPendingActivities')}
                    buttonLabel={Translate.t('Modules.ModulesPendingActivitiesButton')}
                    emptyText={Translate.t('Modules.ModulesPendingActivitiesEmpty')}
                    data={pendingActivities}
                    onClickButtonLabel={() => { console.log('test'); }}
                />
                <EventTrail
                    title={Translate.t('Modules.ModulesPendingApprovals')}
                    buttonLabel={Translate.t('Modules.ModulesPendingApprovalsButton')}
                    emptyText={Translate.t('Modules.ModulesPendingApprovalsEmpty')}
                    data={pendingApprovals}
                    onClickButtonLabel={() => { console.log('test'); }}
                />
            </EventsContainer> */}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    
    /* TEMP */
    /* justify-content: center; */
`;

const SelectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;

    ${media.lg`
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
    `}

    ${media.md`
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, 1fr);
    `}

`;

const EventsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 544px;

    > div:not(:last-child){
        margin-bottom: 16px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    getUserInfo: Selectors.getAuthGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),
    setModulesCreateModal: (status: boolean) => dispatch(Actions.setModulesCreateModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modules);
