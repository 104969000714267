import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import SVG from 'react-inlinesvg';
import { Bars } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { CoBrokeStatusEnum, AdminViewAllCoBrokeResponse } from '@dm/types';
import { BreadCrumb, Colors, Paginator, Button, DropdownInput, ContactButton } from '@dm/bigfish';

import { IGetCobrokes, IReviewCobrokes } from 'entities/rea';

import MainContainer from 'components/MainContainer';
import Modal from 'components/Modal';

import { cobrokesStatusDropdownData } from 'lib/ReaSelectionData';

import Icons from 'assets/icons';

interface CobrokesReportProps {
  getCobrokesLoading: boolean;
  getCobrokesError: string;
  cobrokes: AdminViewAllCoBrokeResponse;
  getCobrokes: (data: IGetCobrokes) => void;
  clearCobrokes: () => void;
  reviewCobrokesLoading: boolean;
  reviewCobrokesError: string;
  reviewCobrokes: (data: IReviewCobrokes) => void;
  setTabView: (tabView: string) => void;
  getUserInfoData: () => void;

}

const CobrokesReport = (props: CobrokesReportProps): JSX.Element => {
    const {
        getCobrokesLoading,
        getCobrokesError,
        cobrokes,
        getCobrokes,
        clearCobrokes,
        reviewCobrokesLoading,
        reviewCobrokesError,
        reviewCobrokes,
        setTabView,
        getUserInfoData,

    } = props;

    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [statusOpt, setStatusOpt] = useState<string | CoBrokeStatusEnum>('');
    const [isSaved, setIsSaved] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalDocument, setModalDocument] = useState('');

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('Cobrokes.CobrokesBcDashboard'),
                onClick: () => { NavActions.navToCobrokesReport(); },
            },
            {
                label: Translate.t('Cobrokes.CobrokesBcCobrokes'),
            },
        ],
    );

    useEffect(() => {
        getCobrokes({ index: 1 });
        setTabView('approvals');
        getUserInfoData();

        return () => {
            clearCobrokes();
        };
    }, []);

    useEffect(() => {
        const { maxIndex } = cobrokes;

        setPaginationData(createPaginationData(maxIndex));
        setMaxPages(maxIndex);
    }, [cobrokes]);

    useEffect(() => {
        getCobrokes({ index: 1, status: statusOpt });
    }, [statusOpt]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        if (currentNumber !== paginationNumber) {
            getCobrokes({ index: currentNumber, status: statusOpt });
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);
        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        if (currentNumber !== paginationNumber) {
            getCobrokes({ index: currentNumber, status: statusOpt });
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);
        if (paginationNumber !== 1) {
            getCobrokes({ index: 1, status: statusOpt });
            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);
        if (paginationNumber !== maxPages) {
            getCobrokes({ index: maxPages, status: statusOpt });
            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);
        if (pages !== paginationNumber) {
            getCobrokes({ index: pages, status: statusOpt });
            winScrollToTop();
        }
    };

    const checkCobrokesStatus = (type: number) => {
        switch (type) {
            case CoBrokeStatusEnum.Approved:
                return 'Approved';
            case CoBrokeStatusEnum.Rejected:
                return 'Rejected';
            case CoBrokeStatusEnum.Pending:
                return 'Pending';
            default:
                return '';
        }
    };

    const checkDocExtension = (docUrl: string) => {
        if (docUrl.includes('pdf')) {
            return 'document';
        }

        return 'images';
    };

    const onClickViewDocument = (docUrl: string) => {
        if (docUrl) {
            setShowModal(true);
            setModalDocument(docUrl);
        }
    };

    const onClickApprove = (cobrokeId: string) => {
        reviewCobrokes({
            id: cobrokeId,
            status: CoBrokeStatusEnum.Approved,
        });
    };

    const onClickRejected = (cobrokeId: string) => {
        reviewCobrokes({
            id: cobrokeId,
            status: CoBrokeStatusEnum.Rejected,
        });
    };

    const renderCobrokesListing = () => {
        const { data } = cobrokes;

        if (getCobrokesLoading || reviewCobrokesLoading) {
            return (
                <LoadingContainer>
                    <Bars color={Colors.primary} height={260} width={260} />
                </LoadingContainer>
            );
        }

        return (
            <>
                {paginationData.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}
                <ReportListingTable>
                    <ReportListingHeader>
                        <ReportHeader>{Translate.t('Cobrokes.CobrokesHeaderEmail')}</ReportHeader>
                        <ReportHeader>{Translate.t('Cobrokes.CobrokesHeaderPropertyName')}</ReportHeader>
                        <ReportHeader>{Translate.t('Cobrokes.CobrokesHeaderPropertyId')}</ReportHeader>
                        <ReportHeader>{Translate.t('Cobrokes.CobrokesHeaderStatus')}</ReportHeader>
                        <ReportHeader>{Translate.t('Cobrokes.CobrokesHeaderView')}</ReportHeader>
                        <ReportHeader>{Translate.t('Cobrokes.CobrokesHeaderAction')}</ReportHeader>
                    </ReportListingHeader>
                    {
                        data.map((cobrokeData) => {
                            const { _id = '', email = '', propertyName = '', propertyId = '', docUrl, status } = cobrokeData;

                            return (
                                <ReportListingColumn key={`key is ${_id}`}>
                                    <ContactButton name={email} />
                                    <ReportColumn>{propertyName}</ReportColumn>
                                    <ReportColumn>{propertyId}</ReportColumn>
                                    <ReportColumn>{checkCobrokesStatus(status)}</ReportColumn>
                                    <ReportColumn>
                                        <StyledIconButton onClick={() => onClickViewDocument(docUrl)}>
                                            <SVG style={{ width: '18px', height: '18px', fill: Colors.black }} src={Icons.SearchIcon} />
                                        </StyledIconButton>
                                    </ReportColumn>
                                    <ReportColumn>
                                        {status !== CoBrokeStatusEnum.Approved ? (
                                            <StyledButton label='Approve' style={{ background: 'green' }} onClick={() => onClickApprove(_id)} />
                                        ) : (
                                            <StyledButton disabled label='Approve' style={{ cursor: 'not-allowed' }} />
                                        )}

                                        {status !== CoBrokeStatusEnum.Rejected ? (
                                            <StyledButton label='Rejected' style={{ background: Colors.warning }} onClick={() => onClickRejected(_id)} />
                                        ) : (
                                            <StyledButton disabled label='Rejected' style={{ cursor: 'not-allowed' }} />
                                        )}
                                    </ReportColumn>
                                </ReportListingColumn>
                            );
                        })
                    }

                    {(!data.length && !getCobrokesError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('OwnersReport.OwnersReportError1')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError2')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError3')}</p>
                        </ReportListingColumnEmpty>
                    )}

                    {(!data.length && getCobrokesError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('OwnersReport.OwnersReportError4')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError5')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError6')}</p>
                        </ReportListingColumnEmpty>
                    )}

                </ReportListingTable>
                {paginationData.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}
            </>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <ReportContainer>
                    <ReportFilterContainer>
                        <FilterContainer>
                            <FilterTitle>
                                Cobrokes Results
                            </FilterTitle>
                            <FilterPropContainer>
                                <DropdownInput
                                    style={{ marginRight: '15px' }}
                                    label={Translate.t('Cobrokes.CobrokesHeaderStatus')}
                                    value={statusOpt}
                                    options={cobrokesStatusDropdownData}
                                    onChangeItem={setStatusOpt}
                                />
                            </FilterPropContainer>
                        </FilterContainer>

                        {/* TEMP */}
                        {/* <SortAndStarredContainer>
                            <StarredButton active={isSaved} onClick={() => setIsSaved(!isSaved)}>
                                {Translate.t('PropertyReport.PropertyReportSaveSearch')}
                                <SVG src={isSaved ? Icons.StarFillIcon : Icons.StarIcon} />
                            </StarredButton>
                        </SortAndStarredContainer> */}
                    </ReportFilterContainer>
                    <ReportContentContainer>
                        <ReportListingContainer>
                            {renderCobrokesListing()}
                        </ReportListingContainer>
                    </ReportContentContainer>
                </ReportContainer>
                <Modal
                    show={showModal}
                    closeCursor
                    onClickClose={() => {
                        setShowModal(false);
                    }}
                >
                    <ModalContent>
                        {modalDocument.includes('pdf') ? (
                            <CarouselPhoto src={modalDocument} title='pdf' />
                        ) : <CarouselPhotoImage src={modalDocument} title='image' />}
                    </ModalContent>
                </Modal>
            </MainContainer>
        </>
    );
};

const CarouselPhoto = styled.iframe`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CarouselPhotoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 20px 120px 20px;

  color: ${Colors.black};
`;

const ReportFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: 20px;
  margin-bottom: 20px;
  
  background: ${Colors.white};

  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterTitle = styled.p`
  font-size: 20px;
  line-height: 24px;

  font-weight: 400;

  margin-bottom: 16px;
`;

const FilterPropContainer = styled.div`
  display: flex;

  > select:not(:last-child){
    margin-right: 10px;
  }
`;

const SortAndStarredContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
`;

const StarredButton = styled.button<{active?: boolean}>`
  display: flex;

  border: none;
  
  background: ${Colors.white};

  font-size: 12px;
  line-height: 14px;

  padding-left: 3px;

  cursor: pointer;
  user-select: none;

  SVG {
    width: 14px;
    height: 14px;

    color: ${props => (props.active ? Colors.black : Colors.white)};
    
    margin-left: 4px;
  }
`;

const ReportContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ReportListingContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 100%;
`;

const ReportListingTable = styled.div`
  width: 100%;

  margin: 20px 0 20px 0;

  > div:not(:last-child){
    margin-bottom: 8px;
  }
`;

const ReportListingHeader = styled.div`
  display: grid;
  grid-template-columns: 280px repeat(5, 1fr);
  align-items: center;

  padding: 16px 9px;

  box-sizing: border-box;
  background: ${Colors.white};
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

const ReportHeader = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;

  &:not(:first-child){
    text-align: center;
  }
`;

const ReportListingColumn = styled.div`
  display: grid;
  grid-template-columns: 280px repeat(5, 1fr);
  align-items: center;

  width: 100%;
  padding: 8px;

  background: ${Colors.primaryLightest};

  box-sizing: border-box;
  border: none;
  border-radius: 12px;

  margin-bottom: 5px;
`;

const ReportColumn = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  
  color: ${Colors.greyDark};
  text-align: center;
`;

const ReportListingColumnEmpty = styled.div`
  padding: 20px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  
  color: ${Colors.greyDark};
  background: ${Colors.primaryLightest};

  box-sizing: border-box;
  border-radius: 12px;

  text-align: center;

  > p {
    margin: 0px;
    padding: 0px;
  }

  > p:not(:last-child) {
    margin-bottom: 3px;
  }

  > p:first-child{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 14px;
  }
`;

const StyledIconButton = styled.button`
  display: inline-block;
  border: none;
  background: transparent;
  cursor: pointer;

  transition: all .2s;

  &:active {
    transform: translateY(-3px);
  }
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  line-height: 1.8;
  padding: 4px 8px;

  &:not(:last-child){
    margin-right: 6px;
  }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 80%;
    height: 580px;
`;

const ModalContent = styled.div`
    width: 100%;
    height: 800px;

    padding: 40px 10px 10px 10px;
    
    @media (max-width: 1624px) {
        height: 600px;
    }
`;

const StyledIframe = styled.iframe`
    display: block;

    width: 100%;
    height: 100%;
`;

const StyledImage = styled.img`
    display: block;

    width: 100%;
    height: 100%;

    object-fit: cover;
`;

const mapStateToProps = (state: RootState) => ({
    getCobrokesLoading: Selectors.getReaGetCobrokesAttempting(state),
    getCobrokesError: Selectors.getReaGetCobrokesError(state),
    cobrokes: Selectors.getReaGetCobrokes(state),
    reviewCobrokesLoading: Selectors.getReaReviewCobrokesAttempting(state),
    reviewCobrokesError: Selectors.getReaReviewCobrokesError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getCobrokes: (data: IGetCobrokes) =>
        dispatch(Actions.getCobrokesAttempt(data)),
    clearCobrokes: () => dispatch(Actions.clearCobrokes()),
    reviewCobrokes: (data: IReviewCobrokes) =>
        dispatch(Actions.reviewCobrokesAttempt(data)),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),

});

export default connect(mapStateToProps, mapDispatchToProps)(CobrokesReport);
