import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Bars } from 'react-loader-spinner';

import { Category, GetOwnerDetailsResponse, IProperty, IPropertyDetails } from '@dm/types';
import { BreadCrumb, CardCollapsible, Button, LineInput, Colors, Checkbox } from '@dm/bigfish';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import Icons from 'assets/icons';

import MainContainer from 'components/MainContainer';
import SupportInfo from 'components/SupportInfo';
import SelectionInput from 'components/SelectionInput';
import DescriptionRoundInput from 'components/DescriptionRoundInput';

import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesCity, CoordinatesCountry, CoordinatesPoscode, CoordinatesState } from 'redux/slices/property/types';
import { IEditPropertyGroupDetails, IPropertyAmenities, IPropertyGroupDetails, IPropertyLocation, IPropertyOwners } from 'entities/property';
import PropertyGroupImage from './components/PropertyGroupImage';
import PropertyGroupMap from './components/PropertyGroupMap';

interface CreateNewProps {
    coordinates: Coordinates;
    address1st: CoordinatesAddress1;
    address2nd: CoordinatesAddress2;
    poscodes: CoordinatesPoscode;
    states: CoordinatesState;
    countryLocation: CoordinatesCountry;
    cityLocation: CoordinatesCity;
    loadingLocation: boolean;

    getPropertyGroupDetailsAttempt: boolean;
    propertyGroupDetails: IPropertyGroupDetails | null;
    getPropertyGroupDetails: (propertyGroupId: string) => void;
    clearPropertyGroupDetails: () => void;

    editPropertyGroupDetailsAttempt: boolean;
    editPropertyGroupDetails: (data: IEditPropertyGroupDetails) => void;

    showErrorMessage: boolean;
}

interface NavParams {
    propertyGroupId: string;
}

const EditPropertyGroup = (props: CreateNewProps): JSX.Element => {
    const { coordinates,
        address1st,
        address2nd,
        poscodes,
        states,
        countryLocation,
        cityLocation,
        loadingLocation,
        getPropertyGroupDetailsAttempt,
        propertyGroupDetails,
        getPropertyGroupDetails,
        clearPropertyGroupDetails,
        editPropertyGroupDetailsAttempt,
        editPropertyGroupDetails,
        showErrorMessage } = props;
    const locationParams = useLocation();
    const { propertyGroupId } = locationParams.state as NavParams;
    // ! name
    const [propertyGroupName, setPropertyGroupName] = useState('');

    // ! status
    const [ownerDetailsStatus, setOwnerDetailsStatus] = useState(false);
    const [descriptionStatus, setDescriptionStatus] = useState(false);
    const [amenitiesStatus, setAmenitiesStatus] = useState(false);
    const [locationStatus, setLocationStatus] = useState(false);

    // ! property Location
    const [postcode, setPostcode] = useState('');
    const [edited, setEdited] = useState(false);
    const { latitude, longitude } = coordinates;
    const { address1 } = address1st;
    const { address2 } = address2nd;
    const { poscode } = poscodes;
    const { state } = states;
    const { country } = countryLocation;
    const { city } = cityLocation;

    // ! property details
    const [type, setType] = useState(0);
    const [titleType, setTitleType] = useState(1);
    const [landTitle, setLandTitle] = useState(1);
    const [storey, setStorey] = useState(1);
    const [facingDirection, setFacingDirection] = useState('');
    const [buildUpSize, setBuildUpSize] = useState(0);
    const [builtUpDimension, setBuiltUpDimension] = useState('');
    const [buildUpPricePerSqft, setBuildUpPricePerSqft] = useState(0);

    // ! property Amenities states
    const [bBQ, setBBQ] = useState(false);
    const [coveredParking, setCoveredParking] = useState(false);
    const [gymnasium, setGymnasium] = useState(false);
    const [basketballCourt, setBasketballCourt] = useState(false);
    const [restaurant, setRestaurant] = useState(false);
    const [airConditioner2, setAirConditioner2] = useState(false);
    const [dobiLaundromat, setDobiLaundromat] = useState(false);
    const [nursery, setNursery] = useState(false);
    const [playground, setPlayground] = useState(false);
    const [sauna, setSauna] = useState(false);
    const [swimmingPool, setSwimmingPool] = useState(false);
    const [tennisCourt, setTennisCourt] = useState(false);
    const [security24hour, setSecurity24hour] = useState(false);
    const [miniMart, setMiniMart] = useState(false);
    const [squashCourt, setSquashCourt] = useState(false);
    const [badmintonCourt, setBadmintonCourt] = useState(false);
    const [elevator, setElevator] = useState(false);

    // ! property Description
    const [descriptionText, setDescriptionText] = useState('');

    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                label: Translate.t('CreateNew.CreateNewBcDashboard'),
                onClick: () => [NavActions.navToPropertyReports()],
            },
            {
                label: 'Property Group Edit',
            },
            {
                label: 'Property name',
            },
        ],
    );

    const ownerDetailsRef = useRef<any>(null);
    const descriptionRef = useRef<any>(null);
    const amenitiesRef = useRef<any>(null);
    const locationRef = useRef<any>(null);

    const typeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TypeApartment'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TypeLanded'),
        },
    ];
    const setTitleTypeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsStrata'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsMaster'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsIndividual'),
        },
    ];
    const LandTitleOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsResidential'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsCommercial'),
        },

    ];

    useEffect(() => {
        if (propertyGroupId) {
            getPropertyGroupDetails(propertyGroupId);
        }
    }, [propertyGroupId]);

    // ? setting details from api to local state
    useEffect(() => {
        // ! name
        if (propertyGroupDetails?.data.name?.length) {
            setPropertyGroupName(propertyGroupDetails?.data.name);
        }
        // ! description
        if (propertyGroupDetails?.data.description?.length) {
            setDescriptionText(propertyGroupDetails?.data.description);
            setDescriptionStatus(true);
        }
        // ! amenities
        if ((propertyGroupDetails?.data.amenities?.facilities.length !== 0)) {
            if (propertyGroupDetails?.data.amenities) {
                onAmenitiesDataPersistHandler();
                setAmenitiesStatus(true);
            }
        }
        // ! details
        if (propertyGroupDetails?.data.details) {
            onPropertyDetailsPersist();
        }

        // ! location
        if (propertyGroupDetails?.data.location
            && propertyGroupDetails?.data.location?.lat !== 0
            && propertyGroupDetails?.data.location?.long !== 0) {
            setLocationStatus(true);
        }
    }, [propertyGroupDetails]);

    useEffect(() => {
        return () => {
            clearPropertyGroupDetails();
        };
    }, []);

    useEffect(() => {
        if (propertyGroupName) {
            setBreadCrumb(
                [
                    {
                        label: Translate.t('CreateNew.CreateNewBcDashboard'),
                        onClick: () => [NavActions.navToPropertyReports()],
                    },
                    {
                        label: 'Property Group Edit',
                    },
                    {
                        label: propertyGroupName,
                    },
                ],
            );
        }
    }, [propertyGroupName]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    // Functions to close collapsibles
    const onCloseCollapsibleOwnerDetails = () => {
        if (ownerDetailsRef.current) {
            ownerDetailsRef.current.closeCollapsible();
        }
    };

    const onCloseCollapsibleDescription = () => {
        if (descriptionRef.current) {
            descriptionRef.current.closeCollapsible();
        }
        setDescriptionStatus(true);
    };

    const onCloseCollapsibleAmenities = () => {
        if (amenitiesRef.current) {
            amenitiesRef.current.closeCollapsible();
        }
        setAmenitiesStatus(true);
    };

    const onCloseCollapsibleLocation = () => {
        if (locationRef.current) {
            locationRef.current.closeCollapsible();
        }
        setLocationStatus(true);
    };

    // ! Functions to set property details
    const onPropertyDetailsPersist = () => {
        if (propertyGroupDetails?.data.details) {
            setType(propertyGroupDetails.data.details?.type);
            setTitleType(propertyGroupDetails.data.details?.titleType);
            setBuildUpSize(propertyGroupDetails.data.details?.buildUpSize);
            setFacingDirection(propertyGroupDetails.data.details?.facingDirection);
            setStorey(propertyGroupDetails.data.details?.storey);
            setBuiltUpDimension(propertyGroupDetails.data.details?.builtUpDimension);
            setBuildUpPricePerSqft(propertyGroupDetails?.data.details?.buildUpPricePerSqft);
            setLandTitle(propertyGroupDetails.data.details?.landTitle);

            if (propertyGroupDetails.data.details.facingDirection) {
                setOwnerDetailsStatus(true);
            }
        }
    };

    const onSubmitPropertyDetails = () => {
        const details = {
            propertyGroupId,
            type,
            titleType,
            buildUpSize,
            landTitle,
            facingDirection,
            storey,
            builtUpDimension,
            buildUpPricePerSqft,
        };

        editPropertyGroupDetails({
            propertyGroupId,
            details,
        });
        setOwnerDetailsStatus(true);
        onCloseCollapsibleOwnerDetails();
    };

    const onClearPropertyDetails = () => {
        setOwnerDetailsStatus(false);
        setType(0);
        setTitleType(1);
        setBuildUpSize(0);
        setLandTitle(0);
        setFacingDirection('');
        setStorey(0);
        setBuiltUpDimension('');
        setBuildUpPricePerSqft(0);
    };

    // Functions to set location
    const onSubmitLocationHandler = () => {
        setPostcode(poscode);
        const location = {
            long: Number(longitude),
            lat: Number(latitude),
            address1,
            address2,
            postcode: poscode,
            city,
            state,
            country,
        };

        editPropertyGroupDetails({
            propertyGroupId,
            location,
        });
        onCloseCollapsibleLocation();
    };

    // Functions to set amenities
    const onSubmitConfirmAmenitiesHandler = () => {
        const facilities = [{
            name: 'bbq',
            available: bBQ,
        },
        {
            name: 'coveredParking',
            available: coveredParking,
        },
        {
            name: 'gymnasium',
            available: gymnasium,
        },
        {
            name: 'basketballCourt',
            available: basketballCourt,
        },
        {
            name: 'restaurant',
            available: restaurant,
        },
        {
            name: 'airConditioner2',
            available: airConditioner2,
        },
        {
            name: 'dobiLaundromat',
            available: dobiLaundromat,
        },
        {
            name: 'nursery',
            available: nursery,
        },
        {
            name: 'playground',
            available: playground,
        },
        {
            name: 'sauna',
            available: sauna,
        },
        {
            name: 'swimmingPool',
            available: swimmingPool,
        },
        {
            name: 'tennisCourt',
            available: tennisCourt,
        },
        {
            name: 'security24hour',
            available: security24hour,
        },
        {
            name: 'miniMart',
            available: miniMart,
        },
        {
            name: 'squashCourt',
            available: squashCourt,
        },
        {
            name: 'badmintonCourt',
            available: badmintonCourt,
        },
        {
            name: 'elevator',
            available: elevator,
        }];

        editPropertyGroupDetails({
            propertyGroupId,
            facilities,
        });
        onCloseCollapsibleAmenities();
    };

    const onAmenitiesDataPersistHandler = () => {
        setBBQ(propertyGroupDetails?.data.amenities?.facilities[0]?.available ?? false);
        setCoveredParking(propertyGroupDetails?.data.amenities?.facilities[1]?.available ?? false);
        setGymnasium(propertyGroupDetails?.data.amenities?.facilities[2]?.available ?? false);
        setBasketballCourt(propertyGroupDetails?.data.amenities?.facilities[3]?.available ?? false);
        setRestaurant(propertyGroupDetails?.data.amenities?.facilities[4]?.available ?? false);
        setAirConditioner2(propertyGroupDetails?.data.amenities?.facilities[5]?.available ?? false);
        setDobiLaundromat(propertyGroupDetails?.data.amenities?.facilities[6]?.available ?? false);
        setNursery(propertyGroupDetails?.data.amenities?.facilities[7]?.available ?? false);
        setPlayground(propertyGroupDetails?.data.amenities?.facilities[8]?.available ?? false);
        setSauna(propertyGroupDetails?.data.amenities?.facilities[9]?.available ?? false);
        setSwimmingPool(propertyGroupDetails?.data.amenities?.facilities[10]?.available ?? false);
        setTennisCourt(propertyGroupDetails?.data.amenities?.facilities[11]?.available ?? false);
        setSecurity24hour(propertyGroupDetails?.data.amenities?.facilities[12]?.available ?? false);
        setMiniMart(propertyGroupDetails?.data.amenities?.facilities[13]?.available ?? false);
        setSquashCourt(propertyGroupDetails?.data.amenities?.facilities[14]?.available ?? false);
        setBadmintonCourt(propertyGroupDetails?.data.amenities?.facilities[15]?.available ?? false);
        setElevator(propertyGroupDetails?.data.amenities?.facilities[16]?.available ?? false);
    };

    const onSubmitCancelAmenitiesHandler = () => {
        setBBQ(false);
        setCoveredParking(false);
        setGymnasium(false);
        setBasketballCourt(false);
        setRestaurant(false);
        setAirConditioner2(false);
        setDobiLaundromat(false);
        setNursery(false);
        setPlayground(false);
        setSauna(false);
        setSwimmingPool(false);
        setTennisCourt(false);
        setSecurity24hour(false);
        setMiniMart(false);
        setSquashCourt(false);
        setBadmintonCourt(false);
        setElevator(false);
    };

    // setDescription function
    const onSubmitDescriptionConfirmHandler = () => {
        editPropertyGroupDetails({
            propertyGroupId,
            description: descriptionText,
        });
        onCloseCollapsibleDescription();
    };

    const onChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescriptionText(e.target.value);
    };

    const renderCardPropertyDetails = () => {
        if (getPropertyGroupDetailsAttempt) {
            return (
                <SetupContainer>
                    <div style={{ display: 'flex', marginTop: '20%', justifyContent: 'center' }}>
                        <Bars
                            color={Colors.primary}
                            height={260}
                            width={260}
                        />
                    </div>
                </SetupContainer>
            );
        }

        return (
            <SetupContainer>
                <CardCollapsible ref={ownerDetailsRef} preText={Translate.t('CreateNew.CreateNewStep1')} title={Translate.t('Property.PropertyDetails.CollapsibleTitle')} icon={ownerDetailsStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={ownerDetailsStatus}>
                    <SectionContainer>
                        <div>
                            <SectionContainer>
                                <SectionTitle>{Translate.t('CreateNew.CreateNewOwnerDetails')}</SectionTitle>
                                <DetailsContainer>
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.PropertyType')} data={typeOptions} currentValue={type} onChangeSelection={(e) => setType(+e.target.value)} />
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.LandTitleLabel')} data={LandTitleOptions} currentValue={landTitle} onChangeSelection={(e) => setLandTitle(+e.target.value)} />
                                    <SelectionInput label={Translate.t('Property.PropertyDetails.PropertyTitleTypeLabel')} data={setTitleTypeOptions} currentValue={titleType} onChangeSelection={(e) => setTitleType(+e.target.value)} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.BuildUpSizeLabel')} value={buildUpSize.toString()} onChangeText={e => setBuildUpSize(Number(e.target.value))} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.BuiltupPriceperSqftLabel')} placeholder='1234' value={buildUpPricePerSqft.toString()} onChangeText={e => setBuildUpPricePerSqft(Number(e.target.value))} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.BuiltUpDimensionSqftLabel')} placeholder='1234' value={builtUpDimension} onChangeText={e => setBuiltUpDimension((e.target.value))} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.StoreyLabel')} value={storey.toString()} onChangeText={e => setStorey(Number(e.target.value))} />
                                    <LineInput label={Translate.t('Property.PropertyDetails.FacingDirectionLabel')} placeholder={Translate.t('Property.PropertyDetails.FacingDirectionPlaceHolder')} value={facingDirection} onChangeText={e => setFacingDirection(e.target.value)} />
                                </DetailsContainer>
                            </SectionContainer>

                            <SubmitButton>
                                <Button type='submit' loading={editPropertyGroupDetailsAttempt} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={onSubmitPropertyDetails} />
                                <Button type='button' loading={editPropertyGroupDetailsAttempt} secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onClearPropertyDetails} />
                            </SubmitButton>
                        </div>
                    </SectionContainer>
                </CardCollapsible>

                <CardCollapsible ref={locationRef} preText={Translate.t('CreateNew.CreateNewStep2')} title={Translate.t('Property.Location.CollapsibleTitle')} icon={locationStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={locationStatus}>
                    <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                        <p>{Translate.t('Property.Location.TitleMessage')}</p>
                        <PropertyGroupMap newPropertyId={propertyGroupId} setEdited={setEdited} edited={edited} />
                        <SubmitButton>
                            <Button type='button' disabled={showErrorMessage} loading={loadingLocation} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={() => onSubmitLocationHandler()} />
                        </SubmitButton>
                    </SectionContainer>
                </CardCollapsible>

                <CardCollapsible ref={amenitiesRef} preText={Translate.t('CreateNew.CreateNewStep3')} title='property Amenities' icon={amenitiesStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={amenitiesStatus}>
                    <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                        <>
                            <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFacilities')}</ItemTitle>
                            <DetailsContainer>
                                <Checkbox label='BBQ' checked={bBQ} onClickCheckbox={() => setBBQ(!bBQ)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.CoveredParkingLabel')} checked={coveredParking} onClickCheckbox={() => setCoveredParking(!coveredParking)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.GymnasiumLabel')} checked={gymnasium} onClickCheckbox={() => setGymnasium(!gymnasium)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BasketballCourtLabel')} checked={basketballCourt} onClickCheckbox={() => setBasketballCourt(!basketballCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.RestaurantLabel')} checked={restaurant} onClickCheckbox={() => setRestaurant(!restaurant)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner2} onClickCheckbox={() => setAirConditioner2(!airConditioner2)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.DobiLabel')} checked={dobiLaundromat} onClickCheckbox={() => setDobiLaundromat(!dobiLaundromat)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.NurseryLabel')} checked={nursery} onClickCheckbox={() => setNursery(!nursery)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.PlaygroundLabel')} checked={playground} onClickCheckbox={() => setPlayground(!playground)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SaunaLabel')} checked={sauna} onClickCheckbox={() => setSauna(!sauna)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SwimmingPoolLabel')} checked={swimmingPool} onClickCheckbox={() => setSwimmingPool(!swimmingPool)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.TennisCourtLabel')} checked={tennisCourt} onClickCheckbox={() => setTennisCourt(!tennisCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SecurityLabel')} checked={security24hour} onClickCheckbox={() => setSecurity24hour(!security24hour)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MiniMartLabel')} checked={miniMart} onClickCheckbox={() => setMiniMart(!miniMart)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SquashCourtLabel')} checked={squashCourt} onClickCheckbox={() => setSquashCourt(!squashCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BadmintonCourtLabel')} checked={badmintonCourt} onClickCheckbox={() => setBadmintonCourt(!badmintonCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.ElevatorLabel')} checked={elevator} onClickCheckbox={() => setElevator(!elevator)} />
                            </DetailsContainer>
                        </>
                        <SubmitButton>
                            <Button type='button' loading={editPropertyGroupDetailsAttempt} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={onSubmitConfirmAmenitiesHandler} />
                            <Button type='button' loading={editPropertyGroupDetailsAttempt} secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={onSubmitCancelAmenitiesHandler} />
                        </SubmitButton>
                    </SectionContainer>
                </CardCollapsible>
                <PropertyGroupImage propertyGroupId={propertyGroupId} />
                <CardCollapsible ref={descriptionRef} preText={Translate.t('CreateNew.CreateNewStep5')} title='property Description' icon={descriptionStatus ? Icons.VerifiedIcon : Icons.CautionIcon} focused={descriptionStatus}>
                    <SectionContainer style={{ maxHeight: '690px', overflow: 'auto' }}>
                        <DescriptionRoundInput value={descriptionText} onChangeText={onChangeDescription} />
                        <SubmitButton>
                            <Button type='button' loading={editPropertyGroupDetailsAttempt} label={Translate.t('CreateNew.CreateNewBtnConfirm')} onClick={onSubmitDescriptionConfirmHandler} />
                            <Button type='button' loading={editPropertyGroupDetailsAttempt} secondary label={Translate.t('CreateNew.CreateNewBtnClear')} onClick={() => setDescriptionText('')} />
                        </SubmitButton>
                    </SectionContainer>
                </CardCollapsible>
                <SubmitButton>
                    <Button label={Translate.t('CreateNew.CreateNewBtnSubmit')} onClick={() => NavActions.navToPropertyReports()} />
                </SubmitButton>
            </SetupContainer>
        );
    };

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Desc>{Translate.t('CreateNew.CreateNewBcDesc')}</Desc>
                <OwnerContentContainer>
                    {renderCardPropertyDetails()}
                    <SideContainer>
                        <SupportInfo />
                    </SideContainer>
                </OwnerContentContainer>
            </MainContainer>
        </>
    );
};

const ItemTitle = styled.h5`
   font-weight: bold;
   font-size: 14px;
   line-height: 16px;
   margin-top: 5px;
   margin-bottom: 16px;
   > li:not(:first-child){
    margin-top: 16px;
   } 
`;
const OwnerContentContainer = styled.div`
    display: flex;
    margin: 0 20px;
    color: #000;
`;

const SetupContainer = styled.div`
    width: 824px;
    margin-right: 16px;

    > div {
        margin-bottom: 16px;
    }
`;

const SectionTitle = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const SideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 564px;
`;

const SubmitButton = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;

    > button {
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
    }

    > button:not(:last-child){
        margin-right: 10px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 16px;
    color: #000;
    margin: 40px 20px 20px 20px;
`;

const mapStateToProps = (state: RootState) => ({
    loadingLocation: Selectors.setPropertySetPropertySetPropertyLocationAttempting(state),
    coordinates: Selectors.getPropertyGetCoordinates(state),
    address1st: Selectors.getPropertyGetAddress1(state),
    address2nd: Selectors.getPropertyGetAddress2(state),
    poscodes: Selectors.getPropertyGetPoscode(state),
    states: Selectors.getPropertyGetState(state),
    countryLocation: Selectors.getPropertyGetCountry(state),
    cityLocation: Selectors.getPropertyGetCity(state),

    getPropertyGroupDetailsAttempt: Selectors.getPropertyGetPropertyGroupDetailsAttempting(state),
    editPropertyGroupDetailsAttempt: Selectors.getPropertyEditPropertyGroupDetailsAttempting(state),
    propertyGroupDetails: Selectors.getPropertyPropertyGroupDetails(state),
    showErrorMessage: Selectors.setPropertySetShowErrorMessage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyGroupDetails: (propertyGroupId: string) => dispatch(Actions.getPropertyGroupDetailsAttempt({ propertyGroupId })),
    clearPropertyGroupDetails: () => dispatch(Actions.clearPropertyGroupDetails()),

    editPropertyGroupDetails: (data: IEditPropertyGroupDetails) => dispatch(Actions.editPropertyGroupDetailsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPropertyGroup);
