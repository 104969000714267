import React, { FunctionComponent, useState, useEffect } from 'react';

import Translate from 'lib/translate';
import styled from 'styled-components';

import NavActions from 'lib/NavActions';

import { IPropertyOwnerResponse } from '@dm/types';

interface PopoverProps {
    ownerProperties: IPropertyOwnerResponse[] | undefined;
}

const Popover: FunctionComponent<PopoverProps> = (props: PopoverProps) => {
    const {
        ownerProperties,
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [hasValue, setHasValue] = useState<boolean>(false);

    useEffect(() => {
        if (ownerProperties && ownerProperties.length > 0) {
            setHasValue(true);
        }
    }, [ownerProperties]);

    return (

        <PopoverButton
            onClick={() => {
                setIsOpen(!isOpen);
            }}
            onBlur={() => setIsOpen(false)}
        >
            {`${Translate.t('OwnersReport.OwnersReportHeaderProp')} (${ownerProperties?.length})`}
            {
                isOpen && hasValue ? (
                    <PopoverContainer>
                        {
                            ownerProperties?.map((item) => {
                                return (
                                    <PopoverContentContainer>
                                        <PopoverUnitNoText>
                                            <PopoverNav onClick={() => NavActions.navToPropertyDetails(item.id, true)}>
                                                {(!item.unitNo || item.unitNo?.length === 0) ? 'N/A' : item.unitNo.trim()}
                                            </PopoverNav>
                                        </PopoverUnitNoText>
                                        <PopoverPropertyNameText>
                                            <PopoverNav onClick={() => NavActions.navToPropertyDetails(item.id, true)}>
                                                {item.name.trim()}
                                            </PopoverNav>
                                        </PopoverPropertyNameText>
                                    </PopoverContentContainer>
                                );
                            })
                        }

                    </PopoverContainer>
                ) : null
            }
        </PopoverButton>

    );
};

const PopoverButton = styled.button`
    all: unset;
    position: relative;
`;

const PopoverNav = styled.span`
 &:hover {
        color: black;
    }
`;

const PopoverContentContainer = styled.div`
    width: fit-content;
    display: flex;
    gap: 1em;
`;

const PopoverText = styled.div`
    font-size: 13px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
    text-align: justify;
    width: fit-content;
`;

const PopoverUnitNoText = styled(PopoverText)`
    font-weight: bold;
    min-width: 50px;
    max-width: 50px;
`;

const PopoverPropertyNameText = styled(PopoverText)`
    text-align: justify;
    width: fit-content;
    max-width: 160px;
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding-block: 1rem;
  background: white;
  z-index: 13;
  width: fit-content;
  height: 100px;
  min-width: 300px;
  max-width: 350px;
  scrollbar-gutter: stable both-edges;
  overflow-y: overlay;
  box-shadow: #0000001f 0px 1px 3px, #0000003d 0px 1px 2px;
  cursor: default;
  border-radius: 10px;
  &::-webkit-scrollbar {
    width: 1.25em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }  

  &:hover {
    &::-webkit-scrollbar-thumb {
        background-color: #F7C48E;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #F2B47E;
  }



`;

export default Popover;
