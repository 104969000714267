import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetDuplicatePropertyGroupActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchSetpropertyGroupDuplicate(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/duplicatePropertyGroupAttempt', handlePropertyGroupDuplicate, api);
}

function* handlePropertyGroupDuplicate(api: PropertyGateway, data: SetDuplicatePropertyGroupActionPayload) {
    const { propertyGroupId } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setPropertyGroupDuplicate], { propertyGroupId, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.duplicatePropertyGroupSuccess(response.data));
        toast.success('property created!');

        if (!response.data) {
            yield put(Actions.duplicatePropertyGroupFailure(Translate.t('GeneralMessage.SomethingWrong')));
            toast.error((Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.duplicatePropertyGroupFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error((Translate.t('GeneralMessage.SomethingWrong')));
            } else {
                toast.error((Translate.t('GeneralMessage.SomethingWrong')));
            }
        }
    }
}
