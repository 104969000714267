import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { GetOwnerDetailsResponse, PropertyStatus, RecentEvents, IGetEventTemplate } from '@dm/types';
import { IOwnerPackage } from 'entities/owners';
import { ICreateRecentEvent, IPackageTypeEnum } from 'entities/packages';

import { BreadCrumb, Colors, CardCollapsible, EventTrail, IEventTrail, TabView, Checkbox, Button } from '@dm/bigfish';

import MainContainer from 'components/MainContainer';
import ButtonInfo from 'components/ButtonInfo';
import RoundedSelectionInput from 'components/RoundedSelectionInput';
import BoxInput from 'components/BoxInput';

import packageStatusOptions from 'components/options/PackageStatus';

import houseImg from 'assets/images/house1.png';

interface CreateEventProps {
    getOwnerDetailsLoading: boolean;
    getOwnerDetailsError: string;
    ownerDetails: GetOwnerDetailsResponse | null;
    getPackagesCreateEventLoading: boolean;
    getPackagesCreateEventError: string;
    getSelectedRecentEvent: IPackageTypeEnum;
    eventTemplates: IGetEventTemplate[] | null;

    getOwnerDetails: (userId: string) => void;
    createRecentEvent: (recentEvent: ICreateRecentEvent) => void;
    setSelectedRecentEvent: (selected: IPackageTypeEnum) => void;
    getEventTemplate: (category: number) => void;
}

interface NavParams {
    ownerId: string;
}

interface IEventTemplateOption{
    id: string | number,
    description: string | number,
}

const createEvent = ({
    getOwnerDetailsLoading,
    getOwnerDetailsError,
    ownerDetails, getOwnerDetails,
    getPackagesCreateEventLoading,
    getPackagesCreateEventError,
    createRecentEvent,
    setSelectedRecentEvent,
    getSelectedRecentEvent,
    getEventTemplate,
    eventTemplates,
}: CreateEventProps): JSX.Element => {
    const { propertyId = '' } = useParams();
    const location = useLocation();
    const { ownerId } = location.state as NavParams;

    const [selectedPropertyId, setSelectedPropertyId] = useState('');
    const [selectedOwnerName, setSelectedOwnerName] = useState('Owner');
    const [selectedPropertyName, setSelectedPropertyName] = useState('Property');
    const [selectedProperties, setSelectedProperties] = useState<IOwnerPackage | null>(null);
    const [selectedPackages, setSelectedPackages] = useState('general');

    const [allRecentEvent, setAllRecentEvent] = useState<IEventTrail[] | []>([]);
    const [showedRecentEvent, setShowedRecentEvent] = useState<IEventTrail[] | []>([]);

    const [eventMessageActive, setEventMessageActive] = useState(false);
    const [eventMessageStatus, setEventMessageStatus] = useState(true);
    const [saveEventTemplate, setSaveEventTemplate] = useState(false);

    const [packageStatus, setPackageStatus] = useState<string | number>('');
    const [eventTemplateOption, setEventTemplateOption] = useState<IEventTemplateOption[] | []>([]);
    const [eventTemplateValue, setEventTemplateValue] = useState<string | number>('');
    const [recentEventText, setRecentEventText] = useState('');
    const [countRecentEventText, setCountRecentEventText] = useState(0);

    const [maxLength, setMaxLength] = useState(150);

    const [tabViewData, setTabViewData] = useState([
        {
            text: 'general',
            onClickTabView: () => {
                setSelectedPackages('general');
            },
        },
    ]);

    const [breadCrumb, setBreadCrumbs] = useState([
        {
            label: Translate.t('OwnersReport.OwnersReportBcDashboard'),
            onClick: () => {
                NavActions.navToHome();
            },
        },
        {
            label: Translate.t('OwnersReport.OwnersReportBcOwners'),
            onClick: () => {
                NavActions.navToReportsOwner();
            },
        },
        {
            label: `${Translate.t('OwnersReport.OwnersReportBcOwnerDetails')} - ${selectedOwnerName}`,
            onClick: () => {
                return false;
            },
        },
        {
            label: selectedPropertyName,
            onClick: () => {
                return false;
            },
        },
        {
            label: 'Events/ Notifications',
            onClick: () => {
                return false;
            },
        },
    ]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const setCollapsibleChipText = (statusEnum: PropertyStatus) => {
        switch (statusEnum) {
            case PropertyStatus.approved: return 'Approved';
            case PropertyStatus.deactivated: return 'Deactivated';
            case PropertyStatus.draftProperty: return 'Draft';
            case PropertyStatus.pendingApproval: return 'Pending';
            case PropertyStatus.tenanted: return 'Tenanted';
            default: return 'Vacant';
        }
    };

    const createEventTrailData = (recentEventData: RecentEvents[]) => {
        return recentEventData.map(event => {
            const { message } = event;

            return {
                text: message,
                onClick: () => { return false; },
            };
        });
    };

    const createEventTemplateData = (eventTemplateData: IGetEventTemplate[]) => {
        const structuredResult = eventTemplateData.map(({ message, status }) => ({
            id: status,
            description: message,
        }));

        return structuredResult;
    };

    useEffect(() => {
        setCountRecentEventText(maxLength);
        getOwnerDetails(ownerId);
    }, [ownerId]);

    useEffect(() => {
        onClearForm();
    }, [getPackagesCreateEventLoading]);

    useEffect(() => {
        if (ownerDetails) {
            const { userInfo, properties } = ownerDetails;

            const ownerName = `${userInfo.firstName} ${userInfo.lastName}`;
            setSelectedOwnerName(ownerName);

            if (propertyId) {
                setSelectedPropertyId(propertyId);

                const filteredProperties = properties?.filter((property) => {
                    return property.id === propertyId;
                });

                if (filteredProperties) {
                    setSelectedProperties(filteredProperties[0]);
                    setSelectedPropertyName(filteredProperties[0].name);

                    const newTabView = [{
                        text: 'general',
                        onClickTabView: () => {
                            setSelectedPackages('general');
                        },
                    }];

                    const recentEventsArray: RecentEvents[] = [];
                    const showedRecentEventsArray: RecentEvents[] = [];

                    if (filteredProperties[0].recentEvents) {
                        recentEventsArray.push(...filteredProperties[0].recentEvents);
                        setAllRecentEvent(createEventTrailData(recentEventsArray));

                        if (getSelectedRecentEvent === IPackageTypeEnum.General) {
                            showedRecentEventsArray.push(...filteredProperties[0].recentEvents);
                            setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                        }
                    }

                    if (filteredProperties[0].packages) {
                        const propPackages = filteredProperties[0].packages;

                        if (propPackages.vacantPossession) {
                            recentEventsArray.push(...propPackages.vacantPossession.recentEvents);

                            if (getSelectedRecentEvent === IPackageTypeEnum.VacantPossession) {
                                showedRecentEventsArray.push(...propPackages.vacantPossession.recentEvents);
                                setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                            }

                            if (propPackages.vacantPossession.status > 1) {
                                newTabView.push({
                                    text: 'vacant possession',
                                    onClickTabView: () => {
                                        setSelectedPackages('vacant possession');
                                    },
                                });
                            }
                        }

                        if (propPackages.defectInspection) {
                            recentEventsArray.push(...propPackages.defectInspection.recentEvents);

                            if (getSelectedRecentEvent === IPackageTypeEnum.DefectInspection) {
                                showedRecentEventsArray.push(...propPackages.defectInspection.recentEvents);
                                setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                            }

                            if (propPackages.defectInspection.status > 1) {
                                newTabView.push({
                                    text: 'defect inspection',
                                    onClickTabView: () => {
                                        setSelectedPackages('defect inspection');
                                    },
                                });
                            }
                        }

                        if (propPackages.propertyManagement) {
                            recentEventsArray.push(...propPackages.propertyManagement.recentEvents);

                            if (getSelectedRecentEvent === IPackageTypeEnum.PropertyManagement) {
                                showedRecentEventsArray.push(...propPackages.propertyManagement.recentEvents);
                                setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                            }

                            if (propPackages.propertyManagement.status > 1) {
                                newTabView.push({
                                    text: 'property management',
                                    onClickTabView: () => {
                                        setSelectedPackages('property management');
                                    },
                                });
                            }
                        }

                        if (propPackages.leaseSale) {
                            recentEventsArray.push(...propPackages.leaseSale.recentEvents);

                            if (getSelectedRecentEvent === IPackageTypeEnum.LeaseAndSale) {
                                showedRecentEventsArray.push(...propPackages.leaseSale.recentEvents);
                                setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                            }

                            if (propPackages.leaseSale.status > 1) {
                                newTabView.push({
                                    text: 'lease & sale',
                                    onClickTabView: () => {
                                        setSelectedPackages('lease & sale');
                                    },
                                });
                            }
                        }

                        if (propPackages.designRenovation) {
                            recentEventsArray.push(...propPackages.designRenovation.recentEvents);

                            if (getSelectedRecentEvent === IPackageTypeEnum.DesignAndRenovation) {
                                showedRecentEventsArray.push(...propPackages.designRenovation.recentEvents);
                                setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                            }

                            if (propPackages.designRenovation.status > 1) {
                                newTabView.push({
                                    text: 'design & renovation',
                                    onClickTabView: () => {
                                        setSelectedPackages('design & renovation');
                                    },
                                });
                            }
                        }

                        setTabViewData(newTabView);
                        setAllRecentEvent(createEventTrailData(recentEventsArray));
                    }
                }
            }

            getEventTemplate(getSelectedRecentEvent);
        }
    }, [ownerDetails]);

    useEffect(() => {
        setBreadCrumbs([
            {
                label: Translate.t('OwnersReport.OwnersReportBcDashboard'),
                onClick: () => {
                    NavActions.navToHome();
                },
            },
            {
                label: Translate.t('OwnersReport.OwnersReportBcOwners'),
                onClick: () => {
                    NavActions.navToReportsOwner();
                },
            },
            {
                label: `${Translate.t('OwnersReport.OwnersReportBcOwnerDetails')} - ${selectedOwnerName}`,
                onClick: () => {
                    return false;
                },
            },
            {
                label: selectedPropertyName,
                onClick: () => {
                    return false;
                },
            },
            {
                label: 'Events/ Notifications',
                onClick: () => {
                    return false;
                },
            },
        ]);
    }, [selectedOwnerName, selectedPropertyName]);

    useEffect(() => {
        setPackageStatus('');
        setRecentEventText('');

        switch (selectedPackages) {
            case 'vacant possession': setSelectedRecentEvent(IPackageTypeEnum.VacantPossession); getEventTemplate(IPackageTypeEnum.VacantPossession); break;
            case 'defect inspection': setSelectedRecentEvent(IPackageTypeEnum.DefectInspection); getEventTemplate(IPackageTypeEnum.DefectInspection); break;
            case 'design & renovation': setSelectedRecentEvent(IPackageTypeEnum.DesignAndRenovation); getEventTemplate(IPackageTypeEnum.DesignAndRenovation); break;
            case 'property management': setSelectedRecentEvent(IPackageTypeEnum.PropertyManagement); getEventTemplate(IPackageTypeEnum.PropertyManagement); break;
            case 'lease & sale': setSelectedRecentEvent(IPackageTypeEnum.LeaseAndSale); getEventTemplate(IPackageTypeEnum.LeaseAndSale); break;
            case 'general': setSelectedRecentEvent(IPackageTypeEnum.General); getEventTemplate(IPackageTypeEnum.General); break;

            default: setSelectedRecentEvent(IPackageTypeEnum.General); getEventTemplate(IPackageTypeEnum.General); break;
        }
    }, [selectedPackages]);

    useEffect(() => {
        const countChar = maxLength - recentEventText.length;
        setCountRecentEventText(countChar);
    }, [recentEventText]);

    useEffect(() => {
        const showedRecentEventsArray: RecentEvents[] = [];

        switch (getSelectedRecentEvent) {
            case IPackageTypeEnum.General:
                if (selectedProperties?.recentEvents) {
                    showedRecentEventsArray.push(...selectedProperties.recentEvents);
                    setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                }
                break;

            case IPackageTypeEnum.VacantPossession:
                if (selectedProperties?.packages.vacantPossession) {
                    const { vacantPossession } = selectedProperties.packages;

                    showedRecentEventsArray.push(...vacantPossession.recentEvents);
                    setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                }
                break;

            case IPackageTypeEnum.DefectInspection:
                if (selectedProperties?.packages.defectInspection) {
                    const { defectInspection } = selectedProperties.packages;

                    showedRecentEventsArray.push(...defectInspection.recentEvents);
                    setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                }
                break;

            case IPackageTypeEnum.PropertyManagement:
                if (selectedProperties?.packages.propertyManagement) {
                    const { propertyManagement } = selectedProperties.packages;

                    showedRecentEventsArray.push(...propertyManagement.recentEvents);
                    setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                }

                break;

            case IPackageTypeEnum.LeaseAndSale:
                if (selectedProperties?.packages.leaseSale) {
                    const { leaseSale } = selectedProperties.packages;

                    showedRecentEventsArray.push(...leaseSale.recentEvents);
                    setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                }

                break;

            case IPackageTypeEnum.DesignAndRenovation:
                if (selectedProperties?.packages.designRenovation) {
                    const { designRenovation } = selectedProperties.packages;

                    showedRecentEventsArray.push(...designRenovation.recentEvents);
                    setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                }

                break;

            default:
                if (selectedProperties?.recentEvents) {
                    showedRecentEventsArray.push(...selectedProperties.recentEvents);
                    setShowedRecentEvent(createEventTrailData(showedRecentEventsArray));
                }
                break;
        }
    }, [getSelectedRecentEvent]);

    useEffect(() => {
        setEventTemplateOption([]);
        setEventTemplateValue('');

        if (eventTemplates?.length) {
            const newEventTemplate = createEventTemplateData(eventTemplates);
            setEventTemplateOption(newEventTemplate);
        }
    }, [eventTemplates]);

    const onSubmitCreateEvent = (e: React.FormEvent) => {
        e.preventDefault();

        const submittedRecentEvent = {
            ownerId,
            propertyId: selectedPropertyId,
            category: getSelectedRecentEvent,
            status: +packageStatus,
            message: recentEventText,
            saveAsTemplate: saveEventTemplate,
        };

        createRecentEvent(submittedRecentEvent);
    };

    const onClearForm = () => {
        setEventMessageStatus(false);
        setPackageStatus('');
        setEventTemplateValue('');
        setRecentEventText('');
        setSaveEventTemplate(false);
    };

    const onChangeEventTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setEventTemplateValue(+e.target.value);

        setPackageStatus(+e.target.value);
        setRecentEventText(e.target.options[e.target.selectedIndex].text);
    };

    const renderContent = () => {
        if (selectedProperties) {
            const { name, city = '', state = '', country = '', status } = selectedProperties;

            return (
                <>
                    <Paragraph>
                        {Translate.t('RecentEvent.RecentEventParagraph')}
                    </Paragraph>

                    <CardCollapsible
                        title={name}
                        subtitle={`${city} ${state} ${country}`}
                        chipText={setCollapsibleChipText(status)}
                        style={{
                            marginBottom: '10px',
                            maxWidth: '100%',
                        }}
                    >
                        <CollapsibleContentContainer>
                            <img
                                src={houseImg}
                                alt=''
                                id='propertyImg'
                            />

                            <div
                                style={{
                                    width: '100%',
                                }}
                            >

                                <EventTrail
                                    title='Recent Events'
                                    primary
                                    buttonLabel='Recent Events'
                                    data={allRecentEvent}
                                    onClickButtonLabel={() => { return false; }}
                                    emptyText='No Recent Events'
                                    style={{
                                        maxWidth: '100%',
                                        marginBottom: '20px',
                                        minHeight: '200px',
                                    }}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <ButtonInfo
                                        desc='Vacant Posession'
                                        onclick={() => { return false; }}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <ButtonInfo
                                        desc='Design Inspection'
                                        onclick={() => { return false; }}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <ButtonInfo
                                        desc='Design And Renovation'
                                        onclick={() => { return false; }}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <ButtonInfo
                                        desc='Lease And Sale'
                                        onclick={() => { return false; }}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <ButtonInfo
                                        desc='Property Management'
                                        onclick={() => { return false; }}
                                    />
                                </div>

                            </div>
                        </CollapsibleContentContainer>
                    </CardCollapsible>

                    <TabViewContainer>
                        <TabView data={tabViewData} selectedTabView={selectedPackages} />
                    </TabViewContainer>

                    <Heading>{Translate.t('RecentEvent.RecentEventHeading')}</Heading>
                    <EventTrail
                        title={Translate.t('RecentEvent.RecentEventTableTitle')}
                        primary
                        data={showedRecentEvent}
                        emptyText='No Recent Events'
                        showButton
                        icon='plus'
                        buttonLabel={Translate.t('RecentEvent.RecentEventTableButton')}
                        onClickButtonLabel={() => setEventMessageActive(true)}
                        style={{
                            marginBottom: '20px',
                            minHeight: '200px',
                        }}
                    />

                    {eventMessageActive && (
                        <EventMessageContainer>
                            <Heading>{Translate.t('RecentEvent.RecentEventTemplateTitle')}</Heading>
                            <FormContainer onSubmit={onSubmitCreateEvent}>
                                <Checkbox
                                    label={Translate.t('RecentEvent.RecentEventTemplatePair')}
                                    checked={eventMessageStatus}
                                    onClickCheckbox={() => setEventMessageStatus(!eventMessageStatus)}
                                    primary
                                />

                                <RoundedSelectionInput
                                    disabled={!eventMessageStatus}
                                    data={packageStatusOptions}
                                    currentValue={packageStatus}
                                    showPlaceHolder
                                    placeHolder='Select a status to pair with the event message'
                                    onChangeSelection={(e) => setPackageStatus(+e.target.value)}
                                    required
                                />

                                <RoundedSelectionInput
                                    data={eventTemplateOption}
                                    disabled={!eventTemplateOption.length}
                                    currentValue={eventTemplateValue}
                                    showPlaceHolder
                                    placeHolder='No event template selected'
                                    onChangeSelection={onChangeEventTemplate}
                                />

                                <BoxInput placeHolder='Write your event message here' maxLength={maxLength} countNumber={countRecentEventText} currentText={recentEventText} onChangeText={(e) => setRecentEventText(e.target.value)} required />

                                <ButtonContainer>
                                    <Checkbox
                                        label={Translate.t('RecentEvent.RecentEventTemplateSaved')}
                                        checked={saveEventTemplate}
                                        onClickCheckbox={() => setSaveEventTemplate(!saveEventTemplate)}
                                    />

                                    <div>
                                        <ActionButton secondary label={Translate.t('RecentEvent.RecentEventTemplateButtonClear')} type='button' onClick={onClearForm} />

                                        <ActionButton label={Translate.t('RecentEvent.RecentEventTemplateButtonPublish')} type='submit' loading={getPackagesCreateEventLoading} />
                                    </div>
                                </ButtonContainer>
                            </FormContainer>
                        </EventMessageContainer>
                    )}
                </>
            );
        }

        return 'Property not found';
    };

    if (getOwnerDetailsLoading) {
        return (
            <MainContainer>
                <div
                    style={{
                        height: '100vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </div>
            </MainContainer>
        );
    }

    if (getOwnerDetailsError) {
        return (
            <MainContainer>
                <div
                    style={{
                        height: '100vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: '24px',
                    }}
                >
                    {getOwnerDetailsError}
                </div>
            </MainContainer>
        );
    }

    return (
        <>
            <BreadCrumb
                backLabel={Translate.t('Ui.UiBack')}
                onBackPressed={onBackPressedBreadCrumb}
                data={breadCrumb}
            />
            <MainContainer>
                <CreateEventContainer>
                    {
                        renderContent()
                    }
                </CreateEventContainer>
            </MainContainer>
        </>
    );
};

const CreateEventContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 824px;
    padding: 40px 20px;
`;

const Paragraph = styled.p`
    font-size: 14px;
    line-height: 1.14;
    font-weight: 400;
    margin-bottom: 40px;
`;

const Heading = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 20px;
`;

const CollapsibleContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;

    #propertyImg {
        height: 250px;
        width: 250px;
        margin-right: 20px;
        border-radius: 12px;
    }
`;

const TabViewContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 32px;

    li {
        width: auto;
        margin-right: 12px;
    }
`;

const EventMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    padding: 20px;
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const ActionButton = styled(Button)`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;

    :last-of-type {
        margin-left: 8px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    getOwnerDetailsLoading: Selectors.getOwnersGetOwnerDetailsAttempting(state),
    getOwnerDetailsError: Selectors.getOwnersGetOwnerDetailsError(state),
    ownerDetails: Selectors.getOwnersGetOwnerDetails(state),
    getPackagesCreateEventLoading: Selectors.getPackagesCreateEventAttempting(state),
    getPackagesCreateEventError: Selectors.getPackagesCreateEventError(state),
    getSelectedRecentEvent: Selectors.getPackagesSelectedRecentEvent(state),
    getEventTemplateLoading: Selectors.getPackagesGetEventTemplateAttempting(state),
    getEventTemplateError: Selectors.getPackagesGetEventTemplateError(state),
    eventTemplates: Selectors.getPackagesEventTemplates(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getOwnerDetails: (userId: string) => dispatch(Actions.getOwnerDetailsAttempt(userId)),
    createRecentEvent: (recentEvent: ICreateRecentEvent) => dispatch(Actions.createEventAttempt(recentEvent)),
    setSelectedRecentEvent: (selected: IPackageTypeEnum) => dispatch(Actions.setSelectedRecentEvent(selected)),
    getEventTemplate: (category: number) => dispatch(Actions.getEventTemplateAttempt(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(createEvent);
