import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '@dm/bigfish';

interface IOption{
  id: string | number,
  description: string | number,
}

interface RoundedSelectionInputProps {
  data: IOption[];
  currentValue?: string | number;
  disabled?: boolean;
  required?: boolean;
  placeHolder?: string;
  showPlaceHolder?: boolean;
  onChangeSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const RoundedSelectionInput = ({ data, currentValue, onChangeSelection, disabled, required, placeHolder, showPlaceHolder }: RoundedSelectionInputProps): JSX.Element => {
    return (
        <FormGroup>
            <Select value={currentValue} disabled={disabled} required={required} onChange={onChangeSelection}>
                {
                    showPlaceHolder && (
                        <option value='' disabled>{placeHolder}</option>
                    )
                }
                {
                    data.map((option: IOption, index) => {
                        const uniqueKey = `${option.description}${index}`;
                        return (<option key={uniqueKey} value={option.id}>{option.description}</option>);
                    })
                }
            </Select>
        </FormGroup>
    );
};

RoundedSelectionInput.defaultProps = {
    currentValue: undefined,
    disabled: false,
    required: false,
    placeHolder: 'Select an option',
    showPlaceHolder: false,
};

const FormGroup = styled.div`
  color: #000;
  font-size: 16px;
`;

const Select = styled.select`
  display: block;
  width: 100%;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 1.14;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  transition: all .5s;

  background-color: ${Colors.greyLightest};
  color: ${Colors.greyDark};

  &:focus {
    outline-color: ${Colors.greyLight};
  }

  ${props => props.disabled && css`
    pointerEvents: none;
    cursor: not-allowed;
  `}
`;

export default RoundedSelectionInput;
