import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import {
    BreadCrumb,
    Button,
    DropdownInput,
} from '@dm/bigfish';
import {
    SortByEnum,
    PaginatedViewAllPMTransactionsResponse,
    ViewAllPMTransactionResponse,
    PMTransactionTypeEnum,
    IPaginatedViewAllRecurringPMTransactionResponse,
    IViewAllRecurringPMTransactionResponse,
    Category,
} from '@dm/types';

import { propertySortByDropdownData } from 'lib/PropertySelectionData';
import {
    transactionStatusDropdownData,
    transactionTypesDropdownData,
} from 'lib/TransactionSelectionData';
import {
    ViewTransactionsParams,
    UpdateTransactionStatusParams,
    ParamsEnum,
} from 'entities/transactions';

import MainContainer from 'components/MainContainer';
import CustomToggle from 'components/CustomToggle';
import FileListModal from 'components/FileListModal';
import UploadFileModal from 'components/UploadFileModal';
import ConfirmRefundModal from './components/ConfirmRevertModal';
import NewTransactionModal from './components/NewTransactionModal';
import NegativeBalanceModal from './components/NegativeBalanceModal';
import ExportTransactionModal from './components/ExportTransactionModal';
import TransactionListTable from './components/TransactionListTable';

import StyledComponent from './styles/TransactionsStyle';
import RecurringTransactionListTable from './components/RecurringTransactionListTable';

interface PropertyReportProps {
    getViewAllTransactionsResponse: PaginatedViewAllPMTransactionsResponse | null;
    getRecurringTransactionsResponse: IPaginatedViewAllRecurringPMTransactionResponse | null;

    clearPropertyReport: () => void;
    setTabView: (tabView: string) => void;

    getAllTransactionsAttempt: (params: ViewTransactionsParams) => void;
    setTransactionStatusAttempt: (params: UpdateTransactionStatusParams) => void;
    getRecurringTransactionsAttempt: (params: ViewTransactionsParams) => void;

    setTransactionModal: (state: boolean) => void;
    getUserInfoData: () => void;
    setIsExportModalOpen: (state: boolean) => void;
    setIsFileViewModalOpen: (value: boolean) => void;
    setIsUploadFileModalOpen: (value: boolean) => void;
    setFileList: (value: ViewAllPMTransactionResponse['fileUrl']) => void;

    setIsConfirmRefundModalOpen: (state: boolean) => void;

    getTransactionsFilter: ViewTransactionsParams;
    setTransactionsFilter: (state: ViewTransactionsParams) => void;
}

const PropertyTransactionReport = (props: PropertyReportProps): JSX.Element => {
    const {

        getViewAllTransactionsResponse,
        getRecurringTransactionsResponse,
        clearPropertyReport,

        getAllTransactionsAttempt,
        setTransactionStatusAttempt,
        getRecurringTransactionsAttempt,

        setTabView,
        setTransactionModal,
        getUserInfoData,
        setIsExportModalOpen,
        setIsFileViewModalOpen,
        setIsUploadFileModalOpen,
        setFileList,
        setIsConfirmRefundModalOpen,
        getTransactionsFilter,
        setTransactionsFilter,
    } = props;

    const {
        SearchAndCreateTransactionContainer,
        PropertyReportContainer,
        TitleContainer,
        Title,
        StyledSearchBar,
        PropertyFilterContainer,
        FilterContainer,
        FilterTitle,
        FilterPropContainer,
        SortAndStarredContainer,
        PropertyContentContainer,
        PropertyListingContainer,
    } = StyledComponent;

    const [isRecurring, setIsRecurring] = useState(false);

    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    // attach properties
    const [transactionsData, setTransactionsData] = useState<ViewAllPMTransactionResponse[] | null>(null);
    const [recurringTransactionsData, setRecurringTransactionsData] = useState<IViewAllRecurringPMTransactionResponse[] | null>(null);

    // transactions params
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState<PMTransactionTypeEnum | number>(9999);
    const [transactionType, setTransactionType] = useState<PMTransactionTypeEnum | number>(9999);
    const [sort, setSort] = useState<SortByEnum | number>(2);
    const [pageIndex, setPageIndex] = useState<number>(1);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('PropertyRpTransactions.PropertyRpTransactionDashboard'),
                onClick: () => { NavActions.navToTransactionsReport(); },
            },
            {
                label: Translate.t('PropertyRpTransactions.PropertyRpTransactionBcTransactions'),
            },
        ],
    );

    const [selectedPropId, setSelectedPropId] = useState('');
    const [selectedTransId, setSelectedTransId] = useState('');
    const [selectedPropName, setSelectedPropName] = useState('');
    const [selectedPropUnit, setSelectedPropUnit] = useState('');
    const [selectedPropAmount, setSelectedPropAmount] = useState(0);

    useEffect(() => {
        setTabView('transactions');
        getUserInfoData();

        setSearch(getTransactionsFilter.search);
        setStatus(getTransactionsFilter.status);
        setTransactionType(getTransactionsFilter.transactionType);
        setSort(getTransactionsFilter.sort);
        setPageIndex(getTransactionsFilter.index);

        return () => {
            clearPropertyReport();
        };
    }, []);

    useEffect(() => {
        const paramsData = {
            search,
            status,
            transactionType,
            sort,
            index: pageIndex,
        };

        setTransactionsFilter(paramsData);
        if (isRecurring) {
            getRecurringTransactionsAttempt(paramsData);
        } else {
            getAllTransactionsAttempt(paramsData);
        }
        setPaginationNumber(1);
    }, [isRecurring, search, status, transactionType, sort, pageIndex]);

    useEffect(() => {
        if (getViewAllTransactionsResponse?.data.length) {
            const { data } = getViewAllTransactionsResponse;
            setTransactionsData(data);
        } else {
            setTransactionsData(null);
        }
    }, [getViewAllTransactionsResponse]);

    useEffect(() => {
        if (getRecurringTransactionsResponse?.data.length) {
            const { data } = getRecurringTransactionsResponse;
            setRecurringTransactionsData(data);
        } else {
            setRecurringTransactionsData(null);
        }
    }, [getRecurringTransactionsResponse]);

    useEffect(() => {
        if (getViewAllTransactionsResponse !== null && !isRecurring) {
            const { index, maxIndex } = getViewAllTransactionsResponse;
            setPaginationData(createPaginationData(maxIndex));
            setPaginationNumber(index);
            setMaxPages(maxIndex);
        }
        if (getRecurringTransactionsResponse !== null && isRecurring) {
            const { index, maxIndex } = getRecurringTransactionsResponse;
            setPaginationData(createPaginationData(maxIndex));
            setPaginationNumber(index);
            setMaxPages(maxIndex);
        }
    }, [getViewAllTransactionsResponse, getRecurringTransactionsResponse]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const statusChangeHandler = (value: string | number, type: ParamsEnum) => {
        if (type === ParamsEnum.TransactionType) {
            setTransactionType(Number(value));
        }
        if (type === ParamsEnum.TransactionStatus) {
            setStatus(Number(value));
        }
        if (type === ParamsEnum.TransactionSort) {
            setSort(Number(value));
        }
    };

    const uploadFileHandler = (propertyId: string, transactionId: string) => {
        setSelectedPropId(propertyId);
        setSelectedTransId(transactionId);
        setIsUploadFileModalOpen(true);
    };

    const viewFileHandler = (data: ViewAllPMTransactionResponse['fileUrl']) => {
        setFileList(data);
        setIsFileViewModalOpen(true);
    };

    const confirmRefundHandler = (propertyId: string, transactionId: string, data: ViewAllPMTransactionResponse) => {
        setSelectedPropId(propertyId);
        setSelectedTransId(transactionId);
        setSelectedPropName(data.propertyName);
        setSelectedPropUnit(data.unitNo);
        setSelectedPropAmount(data.amount);
        setIsConfirmRefundModalOpen(true);
    };

    const throttledTransactionSetSearch = useRef(throttle((searchWord: string) => {
        setSearch(searchWord);
    }, 500, { leading: false }));

    const onSearchTransaction = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledTransactionSetSearch.current(value);
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);

        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }

        if (currentNumber !== paginationNumber) {
            setPageIndex(currentNumber);
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);

        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }

        if (currentNumber !== paginationNumber) {
            setPageIndex(currentNumber);

            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);

        if (paginationNumber !== 1) {
            setPageIndex(1);

            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);

        if (paginationNumber !== maxPages) {
            setPageIndex(maxPages);

            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);

        if (pages !== paginationNumber) {
            setPageIndex(pages);

            winScrollToTop();
        }
    };

    const recurringListToggle = () => {
        setIsRecurring(!isRecurring);
        setPageIndex(1);
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <PropertyReportContainer>
                    <TitleContainer>
                        <Title>
                            {Translate.t('PropertyRpTransactions.PropertyRpTransactionSearchTitle')}
                        </Title>
                        <CustomToggle label='Show recurring transactions' isToggled={isRecurring} onToggle={recurringListToggle} />
                    </TitleContainer>

                    <SearchAndCreateTransactionContainer>
                        <StyledSearchBar
                            title={Translate.t('PropertyReport.PropertyReportProperty')}
                            value={search}
                            onChangeText={onSearchTransaction}
                        />
                        <div style={{ display: 'flex', maxHeight: '40px', gap: '20px' }}>
                            <Button
                                label='Export transactions report'
                                size='sm'
                                onClick={() => setIsExportModalOpen(true)}
                            />
                            <Button
                                label={Translate.t('PropertyRpTransactions.PropertyRpTransactionCreateTransaction')}
                                onClick={() => setTransactionModal(true)}
                                size='sm'
                            />
                        </div>
                    </SearchAndCreateTransactionContainer>
                    <NewTransactionModal />
                    <ExportTransactionModal />
                    <PropertyFilterContainer>
                        <FilterContainer>
                            <FilterTitle>
                                {Translate.t('PropertyReport.PropertyReportFilterTransactions')}
                            </FilterTitle>
                            <FilterPropContainer>
                                <DropdownInput
                                    style={{ marginRight: '15px' }}
                                    label={Translate.t('PropertyReport.PropertyReportFilterTypes')}
                                    value={transactionType ?? 9999}
                                    options={transactionTypesDropdownData}
                                    onChangeItem={(e) => statusChangeHandler(e, ParamsEnum.TransactionType)}
                                />
                                <DropdownInput
                                    style={{ marginRight: '15px' }}
                                    label={Translate.t('PropertyReport.PropertyReportFilterStatus')}
                                    value={status ?? 9999}
                                    options={transactionStatusDropdownData}
                                    onChangeItem={(e) => statusChangeHandler(e, ParamsEnum.TransactionStatus)}
                                />
                            </FilterPropContainer>
                        </FilterContainer>
                        <SortAndStarredContainer>

                            <DropdownInput
                                label={Translate.t('PropertyRpTransactions.PropertyRpTransactionShowTransaction')}
                                value={sort ?? 9999}
                                options={propertySortByDropdownData}
                                onChangeItem={(e) => statusChangeHandler(e, ParamsEnum.TransactionSort)}
                            />
                        </SortAndStarredContainer>
                    </PropertyFilterContainer>
                    <PropertyContentContainer>
                        <PropertyListingContainer>
                            {
                                isRecurring
                                    ? (
                                        <RecurringTransactionListTable
                                            recurringTransactionsData={recurringTransactionsData}
                                            paginationNumber={paginationNumber}
                                            paginationData={paginationData}
                                            nextPaginationNumber={nextPaginationNumber}
                                            prevPaginationNumber={prevPaginationNumber}
                                            firstPaginationNumber={firstPaginationNumber}
                                            lastPaginationNumber={lastPaginationNumber}
                                            onClickPaginationNumber={onClickPaginationNumber}
                                        />
                                    )
                                    : (
                                        <TransactionListTable
                                            transactionsData={transactionsData}
                                            searchData={search}
                                            paginationNumber={paginationNumber}
                                            paginationData={paginationData}
                                            nextPaginationNumber={nextPaginationNumber}
                                            prevPaginationNumber={prevPaginationNumber}
                                            firstPaginationNumber={firstPaginationNumber}
                                            lastPaginationNumber={lastPaginationNumber}
                                            onClickPaginationNumber={onClickPaginationNumber}
                                            viewFileHandler={viewFileHandler}
                                            uploadFileHandler={uploadFileHandler}
                                            confirmRefundHandler={confirmRefundHandler}
                                            setTransactionStatusAttempt={setTransactionStatusAttempt}
                                        />
                                    )
                            }
                        </PropertyListingContainer>

                    </PropertyContentContainer>
                </PropertyReportContainer>
            </MainContainer>
            <FileListModal fileCategory={Category.PropertyManagementTransaction} />
            <UploadFileModal
                propertyId={selectedPropId}
                transactionId={selectedTransId}
            />
            <ConfirmRefundModal
                propertyId={selectedPropId}
                transactionId={selectedTransId}
                propertyName={selectedPropName}
                unitNo={selectedPropUnit}
                amount={selectedPropAmount}
            />
            <NegativeBalanceModal />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({

    getSearchedPropertyLoading: Selectors.getPropertyGetSearchedPropertyAttempting(state),
    getSearchedPropertyError: Selectors.getPropertyGetSearchedPropertyError(state),
    searchedProperty: Selectors.getPropertySearchProperty(state),

    getViewAllTransactionsResponse: Selectors.getViewAllTransactionsResponse(state),
    getRecurringTransactionsResponse: Selectors.getPackagesRecurringTransactionsResponse(state),

    setPackagesPackagesCreatePropertyManagementyTransactionAttempt: Selectors.setPackagesPackagesCreatePMTransactionAttempt(state),
    setPackagesPackagesCreatePropertyManagementyTransactionFailure: Selectors.setPackagesPackagesCreatePMTransactionFailure(state),
    setPackagesPackagesCreatePropertyManagementyTransaction: Selectors.setPackagesPackagesCreatePMTransaction(state),

    attempLoadingUpload: Selectors.packagesgetPackagesUploadUrlAttempting(state),
    isDocumentUploadComplete: Selectors.packagesSetDocumentUploadStatus(state),
    uploadDocumentError: Selectors.packagesgetPackagesUploadUrlError(state),

    getTransactionsFilter: Selectors.getUiTransactionsFilter(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    clearPropertyReport: () => dispatch(Actions.clearPropertyReport()),
    clearSearchedProperty: () => dispatch(Actions.clearSearchedProperty()),
    setTabView: (tabView: string) => dispatch(Actions.setTabView(tabView)),
    getAllTransactionsAttempt: (params: ViewTransactionsParams) => dispatch(Actions.packagesGetIViewAllTransactionsResponseAttempt(params)),
    setTransactionStatusAttempt: (params: UpdateTransactionStatusParams) => dispatch(Actions.packagesUpdateTransactionStatusAttempt(params)),
    getRecurringTransactionsAttempt: (params: ViewTransactionsParams) => dispatch(Actions.packagesGetRecurringTransactionsResponseAttempt(params)),

    setSearchingTransaction: (state: string) => dispatch(Actions.searchingTransaction(state)),

    setTransactionModal: (state: boolean) => dispatch(Actions.setTransactionModalOpen(state)),
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),
    setIsExportModalOpen: (state: boolean) => dispatch(Actions.setIsExportModalOpen(state)),
    setIsFileViewModalOpen: (state: boolean) => dispatch(Actions.setFileViewModalOpen(state)),
    setIsUploadFileModalOpen: (value: boolean) => dispatch(Actions.setUploadFileModalOpen(value)),
    setFileList: (data: ViewAllPMTransactionResponse['fileUrl']) => dispatch(Actions.setFileList(data)),
    setIsConfirmRefundModalOpen: (state: boolean) => dispatch(Actions.setIsConfirmRefundModalOpen(state)),
    setTransactionsFilter: (state: ViewTransactionsParams) => dispatch(Actions.setTransactionsFilter(state)),

});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTransactionReport);
