import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OwnersGateway from 'api/Owners';

import { GetAllActionPayload } from 'redux/slices/owners/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

export default function* watchGetOwners(api: OwnersGateway): SagaWatcherReturnType {
    yield takeEvery('owners/ownersGetAllAttempt', handleGetOwners, api);
}

function* handleGetOwners(api: OwnersGateway, data: GetAllActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getOwners], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.ownersGetAllFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.ownersGetAllSuccess(response.data));
    } else {
        yield put(Actions.ownersCreateNewFailure(response.message));
    }
}
