import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import NavActions from 'lib/NavActions';

import { ISubAdmin } from 'entities/subadmins';

import { Button, Colors } from '@dm/bigfish';
import { Oval } from 'react-loader-spinner';
import Modal from '../../../components/Modal';

export interface CreateSubAdminModalProps {
    // setCreateProperty: (name: string) => void;
    isCreateSubAdminModalOpen: boolean;
    createSubAdminLoading: boolean;
    createSubAdminError: string;
    setCreateSubAdminModal: (state: boolean) => void;
    setCreateSubAdmin: (data: ISubAdmin) => void;
}

const CreateSubAdminModal = (props: CreateSubAdminModalProps): JSX.Element => {
    const { setCreateSubAdmin, setCreateSubAdminModal, isCreateSubAdminModalOpen, createSubAdminLoading, createSubAdminError } = props;

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [checkPassword, setCheckPassword] = useState(false);
    const [checkEmail, setCheckEmail] = useState(false);
    const [checkEmpty, setCheckEmpty] = useState(false);

    const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/img;

    const onClearSubAdmin = () => {
        setEmail('');
        setName('');
        setPhoneNumber('');
        setPassword('');
        setConfirmPassword('');
        setCheckPassword(false);
        setCheckEmail(false);
        setCheckEmpty(false);

        setCreateSubAdminModal(false);
    };

    const confirmClickHandler = () => {
        const dataToSubmit: ISubAdmin = {
            email,
            name,
            password,
            phoneNumber,
        };

        if (
            email.length === 0
            || name.length === 0
            || phoneNumber.length === 0
            || password.length === 0
            || confirmPassword.length === 0
        ) {
            setCheckEmpty(true);
            return;
        }
        setCheckEmpty(false);

        if (password !== confirmPassword) {
            setCheckPassword(true);
            return;
        }
        setCheckPassword(false);

        if (emailRegex.test(email) === false) {
            setCheckEmail(true);
            return;
        }
        setCheckEmail(false);

        if (!checkEmpty && !checkPassword && !checkEmail) {
            setCreateSubAdmin(dataToSubmit);
        }
    };

    const cancelClickHandler = () => {
        onClearSubAdmin();
    };

    useEffect(() => {
        if (!createSubAdminLoading && !createSubAdminError) {
            onClearSubAdmin();
        }
    }, [createSubAdminLoading, createSubAdminError]);

    const renderModalHeader = () => {
        return (
            'Create New Sub-Admin'
        );
    };

    const renderModalBody = () => {
        if (createSubAdminLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Oval
                        height={150}
                        width={150}
                        color={Colors.primary}
                        secondaryColor={Colors.primaryLight}
                    />
                </div>
            );
        }

        if (createSubAdminError) {
            return (
                <div>
                    {createSubAdminError}
                </div>
            );
        }

        return (
            <>
                {checkEmpty && (
                    <StyledError>
                        Please fill up all the input.
                    </StyledError>
                )}

                <StyledLabel>
                    Email
                </StyledLabel>

                {checkEmail && (
                    <StyledError>
                        Please enter a valid email address!
                    </StyledError>
                )}

                <StyledInput
                    invalid={checkEmail}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <StyledLabel>
                    Name
                </StyledLabel>

                <StyledInput
                    invalid={false}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <StyledLabel>
                    phone Number
                </StyledLabel>

                <StyledInput
                    invalid={false}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />

                <StyledLabel>
                    Password
                </StyledLabel>

                <StyledInput
                    // type='password'
                    invalid={checkPassword}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <StyledLabel>
                    Confirm Password
                </StyledLabel>

                {checkPassword && (
                    <StyledError>
                        Password Not Match!
                    </StyledError>
                )}

                <StyledInput
                    // type='password'
                    invalid={checkPassword}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    autoComplete='off'
                />
            </>
        );
    };

    const renderModalFooter = () => {
        return (
            <>
                <Button
                    label='Cancel'
                    style={{
                        marginRight: '10px',
                        backgroundColor: Colors.white,
                        color: Colors.primary,
                        border: `1px solid ${Colors.primary}`,
                    }}
                    onClick={cancelClickHandler}
                    disabled={createSubAdminLoading}
                />
                <Button
                    label='Create!'
                    onClick={confirmClickHandler}
                    disabled={createSubAdminLoading}
                />
            </>
        );
    };

    return (
        <Modal show={isCreateSubAdminModalOpen} width='30%'>
            <ModalHeader>
                {renderModalHeader()}
            </ModalHeader>

            <ModalBody>
                {renderModalBody()}
            </ModalBody>

            <ModalFooter>
                {renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ModalBody = styled.div`
    margin-bottom: 20px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledLabel = styled.div`
    font-size: 16px;
    color: black;
    margin-bottom: 5px;
`;

const StyledError = styled.div`
    font-size: 16px;
    color: red;
    margin-bottom: 5px;
`;

const StyledInput = styled.input<{invalid: boolean, lastChild?: boolean}>`
    border: ${props => (props.invalid ? '1px solid red' : 'none')};
    outline: ${props => (props.invalid ? '1px solid red' : 'none')};
    background-color: #F6F6F6;
    width: 100%;
    border-radius: 12px;
    height: 30px;
    padding: 10px;

    margin-bottom: ${props => (props.lastChild ? '0px' : '20px')};

    &:focus {
        outline: ${props => (props.invalid ? '1px solid red' : '2px solid grey')};
    }

    &:active {
        outline: ${props => (props.invalid ? '1px solid red' : '1px solid grey')};
    }
`;

const mapStateToProps = (state: RootState) => ({
    createSubAdminLoading: Selectors.getSubAdminGetCreateNewSubAdminAttempt(state),
    createSubAdminError: Selectors.getSubAdminGetCreateNewSubAdminError(state),
    isCreateSubAdminModalOpen: Selectors.setSubAdminCreateSubAdminModal(state),
    loadingCreateSubAdmin: Selectors.setPropertyGetPropertySetCreatePropertAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCreateSubAdminModal: (state: boolean) => dispatch(Actions.setCreateSubAdminModal(state)),
    setCreateSubAdmin: (data: ISubAdmin) => dispatch(Actions.subAdminCreateNewAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubAdminModal);
