import { put, call, takeEvery } from 'typed-redux-saga/macro';

import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import Utils from 'lib/Utils';
import Translate from 'lib/translate';
import Actions from 'redux/Actions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import PropertyGateway from 'api/Property';
import xlsx, { IJsonSheet } from 'json-as-xlsx';
import { GetPropertiesReportExportPayload } from 'redux/slices/property/types';

export default function* watchGetPropertiesReportExport(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/getPropertiesReportExportAttempt', handleGetPropertiesReportExport, api);
}

function* handleGetPropertiesReportExport(api: PropertyGateway, data: GetPropertiesReportExportPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const response = yield* call([api, api.getPropertiesReportExport], {
            authToken,
            filter: data.payload,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data || response.data.length === 0) {
                toast.error('No data found!');
                yield put(Actions.getPropertiesReportExportSuccess());
                return;
            }
            const { data: propertiesReport } = response;

            const massagedPropertiesReport = propertiesReport.map((item) => {
                const {
                    no,
                    propertyName,
                    unitNo,
                    ownerName,
                    contact,
                    email,
                    propertyManagementPackage,
                    confirmationLetter,
                    renoPackage,
                    designRenoPayment,
                    account,
                    defectCheck,
                    ...restOfTransactionReport
                } = item;

                return {
                    No: no,
                    PropertyName: propertyName,
                    UnitNo: unitNo,
                    OwnerName: ownerName,
                    Contact: contact,
                    Email: email,
                    BasicPMSsubscribed: propertyManagementPackage.subscribed ? 'Yes' : 'No',
                    BasicPMSStartDate: dayjs(propertyManagementPackage.startDate).format('DD/MM/YYYY'),
                    BasicPMSEndDate: dayjs(propertyManagementPackage.endDate).format('DD/MM/YYYY'),
                    ConfirmationSend: confirmationLetter.sendStatus ? 'Yes' : 'No',
                    ConfirmationSign: confirmationLetter.signStatus ? 'Yes' : 'No',
                    ConfirmationPaid: confirmationLetter.paidStatus ? 'Yes' : 'No',
                    ConfirmationPaymentReceived: confirmationLetter.paymentReceivedStatus ? 'Yes' : 'No',
                    RenoPackage: renoPackage,
                    Payment50: designRenoPayment.paidHalfOfFullPrice,
                    Payment100: designRenoPayment.paidMoreThanHalfOfFullPrice,
                    AccountIndahWater: account.indahWater,
                    AccountTNB: account.tnb,
                    AccountParcelRent: account.parcelRent,
                    AccountAssessmentTax: account.assTax,
                    DefectCheckingPrice: defectCheck.price,
                    DefectCheckingCost: defectCheck.cost,
                    DefectCheckingProfit: defectCheck.profit,
                };
            });

            const downloadFile = () => {
                const exportData: IJsonSheet[] = [
                    {
                        columns: [
                            { label: 'No.', value: 'No' },
                            { label: 'Property Name', value: 'PropertyName' },
                            { label: 'Unit No.', value: 'UnitNo' },
                            { label: 'Owner Name', value: 'OwnerName' },
                            { label: 'Contact', value: 'Contact' },
                            { label: 'Email', value: 'Email' },
                            { label: 'Basic PMS subscribed', value: 'BasicPMSsubscribed' },
                            { label: 'Basic PMS Start Date', value: 'BasicPMSStartDate' },
                            { label: 'Basic PMS End Date', value: 'BasicPMSEndDate' },
                            { label: 'Confirmation send', value: 'ConfirmationSend' },
                            { label: 'Confirmation sign', value: 'ConfirmationSign' },
                            { label: 'Confirmation paid', value: 'ConfirmationPaid' },
                            { label: 'Confirmation payment received', value: 'ConfirmationPaymentReceived' },
                            { label: 'Reno package', value: 'RenoPackage' },
                            { label: 'Reno total paid', value: 'Payment50' },
                            { label: 'Reno total price', value: 'Payment100' },
                            { label: 'Account - Indah Water', value: 'AccountIndahWater' },
                            { label: 'Account - TNB', value: 'AccountTNB' },
                            { label: 'Account - Parcel Rent', value: 'AccountParcelRent' },
                            { label: 'Account - Assessment Tax', value: 'AccountAssessmentTax' },
                            { label: 'Defect Checking - Price', value: 'DefectCheckingPrice' },
                            { label: 'Defect Checking - Cost', value: 'DefectCheckingCost' },
                            { label: 'Defect Checking - Profit', value: 'DefectCheckingProfit' },
                        ],
                        content: massagedPropertiesReport,
                    },
                ];
                const settings = {
                    fileName: `Properties Report - ${dayjs().format('DD-MM-YYYY')}`,
                };
                xlsx(exportData, settings);
            };

            downloadFile();
            yield put(Actions.getPropertiesReportExportSuccess());
        } else {
        // handle error
            yield put(Actions.getPropertiesReportExportFailure());
            toast.error(response.message);
        }
    }
}
