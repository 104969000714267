import { InitState } from 'redux/slices/packages';
import { ILeaseSale, IPackageTypeEnum } from 'entities/packages';
import { IDefectInspection, IGetEventTemplate, IPaginatedAdminPropertyManagementWithdrawalResponse, IPaginatedViewAllRecurringPMTransactionResponse, IPropertyManagement, IVacantManagement, IVacantPossession, PaginatedViewAllPMTransactionsResponse, PropertyManagementTransactionResponse } from '@dm/types';
import { SetCreatePMTransaction, SetCreatePMTransactionPayload } from './types';

const getCreateEventAttempting = (state: InitState): boolean => state.actions.createEvent || false;
const getCreateEventError = (state: InitState): string => state.error.createEvent || '';

const selectedRecentEvent = (state: InitState): IPackageTypeEnum => state.selectedRecentEvent || IPackageTypeEnum.General;

const getEventTemplateAttempting = (state: InitState): boolean => state.actions.getEventTemplate || false;
const getEventTemplateError = (state: InitState): string => state.error.getEventTemplate || '';

const eventTemplates = (state: InitState): IGetEventTemplate[] => state.eventTemplates || [];

const setLeaseSaleModal = (state: InitState): boolean => state.leaseSaleModal || false;
const setLeaseAndSalePackagesDocumentUploadId = (state: InitState): string => state.documentUploadId || '';

const getPackagesLeaseAndSaleDataDetailsSortAttempt = (state: InitState): boolean => state.actions.getpackagesLeaseAndSaleDataSort || false;
const getPackagesLeaseAndSaleDataDetailsSortFailure = (state: InitState): string => state.error.getpackagesLeaseAndSaleDataSort || '';
const getPackagesLeaseAndSaleDataDetailsSort = (state: InitState): ILeaseSale | null => state.IPackagesLeaseSaleDataDetailsSort || null;

const setPackagesDocumentViewModal = (state: InitState): boolean => state.packagesDocumentView || false;

const getPackagesUploadUrlAttempting = (state: InitState): boolean => state.actions.getPackagesUploadUrl || false;
const getPackagesUploadUrlError = (state: InitState): string => state.error.getPackagesUploadUrl || '';
const getPackagesUploadUrl = (state: InitState): string => state.PackagesDocumentUploadURL || '';

const uploadPackagesDocumentAttempting = (state: InitState): boolean => state.actions.uploadPackagesDocument || false;
const uploadPackagesDocumentError = (state: InitState): string => state.error.uploadPackagesDocument || '';
const setDocumentUploadStatus = (state: InitState): boolean => state.documentUploadStatus || false;

const setPackagesDocumentUploadId = (state: InitState): string => state.documentUploadIdPackages || '';

const setVacantPossesionModal = (state: InitState): boolean => state.vacantPossesionModal || false;

const getPackagesDataVacantPossesionDetailsSortAttempt = (state: InitState): boolean => state.actions.getpackagesDataSort || false;
const getPackagesDataVacantPossesionDetailsSortFailure = (state: InitState): string => state.error.getpackagesDataSort || '';
const getPackagesDataVacantPossesionDetailsSort = (state: InitState): IVacantPossession | null => state.IPackagesVacantPossessionSort || null;

const getPackagesDataDefectInspectionDetailsSortAttempt = (state: InitState): boolean => state.actions.getPackageDefectInspectionSort || false;
const getPackagesDataDefectInspectionDetailsSortFailure = (state: InitState): string => state.error.getPackageDefectInspectionSort || '';
const getPackagesDataDefectInspectionDetailsSort = (state: InitState): IDefectInspection | null => state.IPackagesDefectInspectionSort || null;

const getPackagesDataVacantManagementDetailsSortAttempt = (state: InitState): boolean => state.actions.getVacantManagementPackagesDataSort || false;
const getPackagesDataVacantManagementDetailsSortFailure = (state: InitState): string => state.error.getVacantManagementPackagesDataSort || '';
const getPackagesDataVacantManagementDetailsSort = (state: InitState): IVacantManagement | null => state.IPackagesVacantManagementSort || null;

const getPackagesDataPropertyManagementDetailsSortAttempt = (state: InitState): boolean => state.actions.getPropertyManagementPackagesDataSort || false;
const getPackagesDataPropertyManagementDetailsSortFailure = (state: InitState): string => state.error.getPropertyManagementPackagesDataSort || '';
const getPackagesDataPropertyManagementDetailsSort = (state: InitState): IPropertyManagement | null => state.IPackagesPropertyManagementSort || null;

const getPackagesPropertyManagementDetailsSortAttempt = (state: InitState): boolean => state.actions.getpackagesPropertyManagementDataSort || false;
const getPackagesPropertyManagementDetailsSortFailure = (state: InitState): string => state.error.getpackagesPropertyManagementDataSort || '';
const getPackagesPropertyManagementDetailsSort = (state: InitState): PropertyManagementTransactionResponse[] | null => state.IPackagesPropertyManagementDataDetailsSort || null;

const getPaginatedViewAllTransactionsResponseAttempt = (state: InitState): boolean => state.actions.packagesGetIViewAllTransactionsResponse || false;
const getPaginatedViewAllTransactionsResponseFailure = (state: InitState): string => state.error.packagesGetIViewAllTransactionsResponse || '';
const getPaginatedViewAllTransactionsResponse = (state: InitState): PaginatedViewAllPMTransactionsResponse | null => state.IPackagesGetIViewAllTransactionsResponse || null;

const getRecurringTransactionsResponseAttempt = (state: InitState): boolean => state.actions.packagesGetRecurringTransactionsResponse || false;
const getRecurringTransactionsResponseFailure = (state: InitState): string => state.error.packagesGetRecurringTransactionsResponse || '';
const getRecurringTransactionsResponse = (state: InitState): IPaginatedViewAllRecurringPMTransactionResponse | null => state.IPackagesGetRecurringTransactionsResponse || null;

const setPackagesUpdateTransactionStatusAttempt = (state: InitState): boolean => state.actions.updateTransactionStatus || false;
const setPackagesUpdateTransactionStatusFailure = (state: InitState): string => state.error.updateTransactionStatus || '';
const setPackagesUpdateTransactionStatus = (state: InitState): string | null => state.updateTransactionStatusComplete || '';

const setPackagesEditTransactionAttempt = (state: InitState): boolean => state.actions.editTransaction || false;
const setPackagesEditTransactionFailure = (state: InitState): string => state.error.editTransaction || '';
const setPackagesEditTransaction = (state: InitState): string | null => state.editTransactionComplete || null;

const setPackagesEditRecurringTransactionAttempt = (state: InitState): boolean => state.actions.editRecurringTransaction || false;
const setPackagesEditRecurringTransactionFailure = (state: InitState): string => state.error.editRecurringTransaction || '';
const setPackagesEditRecurringTransaction = (state: InitState): string | null => state.editRecurringTransactionComplete || null;

const setPackagesCreatePMTransactionAttempt = (state: InitState): boolean => state.actions.createPMTransaction || false;
const setPackagesCreatePMTransactionFailure = (state: InitState): string => state.error.createPMTransaction || '';
const setPackagesCreatePMTransaction = (state: InitState): { id: string } | null => state.createPMTransactionComplete || null;

const getUpdateProgressDefectInspectionAttempt = (state: InitState): boolean => state.actions.updateProgressDefectInspection || false;
const getUpdateProgressDefectInspectionFailure = (state: InitState): string => state.error.updateProgressDefectInspection || '';

const setPropertyManagementTransactionUploadDocumentCheck = (state: InitState): string => state.propertyManagementTransactionUploadDocumentCheckData || '';

const getNegativeBalance = (state: InitState): number => state.negativeBalance || 0;
const getDataToCreateTransaction = (state: InitState): SetCreatePMTransaction | null => state.dataToCreateTransaction || null;
const getIsNegativeBalanceModalOpen = (state: InitState): boolean => state.isNegativeBalanceModalOpen || false;

const getPropertyManagementWithdrawalAttempt = (state: InitState): boolean => state.actions.getPropertyManagementWithdrawal || false;
const getPropertyManagementWithdrawalFailure = (state: InitState): string => state.error.getPropertyManagementWithdrawal || '';
const getPropertyManagementWithdrawal = (state: InitState): IPaginatedAdminPropertyManagementWithdrawalResponse => state.propertyManagementWithdrawal || null;

const editPropertyManagementWithdrawalStatusAttempt = (state: InitState): boolean => state.actions.editPropertyManagementWithdrawal || false;

export default {
    getCreateEventAttempting,
    getCreateEventError,

    selectedRecentEvent,

    getEventTemplateAttempting,
    getEventTemplateError,
    eventTemplates,

    setLeaseSaleModal,
    setLeaseAndSalePackagesDocumentUploadId,

    getPackagesLeaseAndSaleDataDetailsSortAttempt,
    getPackagesLeaseAndSaleDataDetailsSortFailure,
    getPackagesLeaseAndSaleDataDetailsSort,

    setPackagesDocumentViewModal,

    getPackagesUploadUrlAttempting,
    getPackagesUploadUrlError,
    getPackagesUploadUrl,

    uploadPackagesDocumentAttempting,
    uploadPackagesDocumentError,
    setDocumentUploadStatus,

    setPackagesDocumentUploadId,
    setVacantPossesionModal,

    getPackagesDataVacantPossesionDetailsSortAttempt,
    getPackagesDataVacantPossesionDetailsSortFailure,
    getPackagesDataVacantPossesionDetailsSort,

    getPackagesDataDefectInspectionDetailsSortAttempt,
    getPackagesDataDefectInspectionDetailsSortFailure,
    getPackagesDataDefectInspectionDetailsSort,

    getPackagesDataVacantManagementDetailsSortAttempt,
    getPackagesDataVacantManagementDetailsSortFailure,
    getPackagesDataVacantManagementDetailsSort,

    getPackagesDataPropertyManagementDetailsSortAttempt,
    getPackagesDataPropertyManagementDetailsSortFailure,
    getPackagesDataPropertyManagementDetailsSort,

    getPackagesPropertyManagementDetailsSortAttempt,
    getPackagesPropertyManagementDetailsSortFailure,
    getPackagesPropertyManagementDetailsSort,

    getPaginatedViewAllTransactionsResponseAttempt,
    getPaginatedViewAllTransactionsResponseFailure,
    getPaginatedViewAllTransactionsResponse,

    getRecurringTransactionsResponseAttempt,
    getRecurringTransactionsResponseFailure,
    getRecurringTransactionsResponse,

    setPackagesUpdateTransactionStatusAttempt,
    setPackagesUpdateTransactionStatusFailure,
    setPackagesUpdateTransactionStatus,

    setPackagesEditTransactionAttempt,
    setPackagesEditTransactionFailure,
    setPackagesEditTransaction,

    setPackagesEditRecurringTransactionAttempt,
    setPackagesEditRecurringTransactionFailure,
    setPackagesEditRecurringTransaction,

    setPackagesCreatePMTransactionAttempt,
    setPackagesCreatePMTransactionFailure,
    setPackagesCreatePMTransaction,

    getUpdateProgressDefectInspectionAttempt,
    getUpdateProgressDefectInspectionFailure,

    setPropertyManagementTransactionUploadDocumentCheck,

    getNegativeBalance,
    getDataToCreateTransaction,
    getIsNegativeBalanceModalOpen,

    getPropertyManagementWithdrawalAttempt,
    getPropertyManagementWithdrawalFailure,
    getPropertyManagementWithdrawal,

    editPropertyManagementWithdrawalStatusAttempt,
};
