import { InitState } from 'redux/slices/property';
import { IPropertyGroupDetails, IPropertyOwners, IServerPropertyReport, IServicePackagePriceIndividual } from 'entities/property';
import { IProperty, AdminGetPropertiesResponse, GetPropertyGroupResponse, GroupPropertiesWithCountResponse } from '@dm/types';
import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesCity, CoordinatesCountry, CoordinatesPoscode, CoordinatesState } from './types';

const propertyGetAllAttempt = (state: InitState): boolean => state.actions.getAll || false;
const propertyGetAllError = (state: InitState): string => state.error.getAll || '';

const propertyReport = (state: InitState): IServerPropertyReport => state.propertyReport || null;

const propertyGetDetailsAttempt = (state: InitState): boolean => state.actions.getDetails || false;
const propertyGetDetailsError = (state: InitState): string => state.error.getDetails || '';

const propertyDetails = (state: InitState): IProperty | null => state.propertyDetails || null;

const setServicePackageAttempting = (state: InitState): boolean => state.actions.setServicePackage || false;
const setServicePackageError = (state: InitState): string => state.error.setServicePackage || '';

const isServicePackageModalOpen = (state: InitState): boolean => state.isServicePackageModalOpen || false;
const isUpdatePackagePriceModalOpen = (state: InitState): boolean => state.isUpdatePackagePriceModalOpen || false;
const isSetGoogleDrivePathModalOpen = (state: InitState): boolean => state.isSetGoogleDrivePathModalOpen || false;
const isSetMilestonesModalOpen = (state: InitState): boolean => state.isSetMilestonesModalOpen || false;
const isRejectPropertyModalOpen = (state: InitState): boolean => state.isRejectPropertyModalOpen || false;

const getSearchedPropertyAttempt = (state: InitState): boolean => state.actions.getSearchedProperty || false;
const getSearchedPropertyError = (state: InitState): string => state.error.getSearchedProperty || '';

const searchedProperty = (state: InitState): AdminGetPropertiesResponse[] | [] => state.searchedProperty || null;

const setPropertySetDescriptionAttempting = (state: InitState): boolean => state.actions.setDescription || false;
const setPropertySetDescriptionError = (state: InitState): string => state.error.setDescription || '';
const setPropertySetDescription = (state: InitState): string => state.propertyDescription || '';

const setPropertySetPropertyAmenitiesAttempting = (state: InitState): boolean => state.actions.setPropertyAmenities || false;
const setPropertySetPropertyAmenitiesError = (state: InitState): string => state.error.setPropertyAmenities || '';
const setPropertySetPropertyAmenities = (state: InitState): string => state.setPropertyAmenitiesData || '';

const setPropertySetPropertyDetailsAttempting = (state: InitState): boolean => state.actions.propertyDetails || false;
const setPropertySetPropertyDetailsError = (state: InitState): string => state.error.propertyDetails || '';
const setPropertySetPropertyDetails = (state: InitState): string => state.setPropertyDetails || '';

const setPropertySetPropertyUserAttempting = (state: InitState): boolean => state.actions.propertyUser || false;
const setPropertySetPropertyUserError = (state: InitState): string => state.error.propertyUser || '';
const setPropertySetPropertyUser = (state: InitState): string => state.propertySetUserData || '';

const setPropertySetPropertyLocationAttempting = (state: InitState): boolean => state.actions.setLocationProperty || false;
const setPropertySetPropertyLocationError = (state: InitState): string => state.error.setLocationProperty || '';
const setPropertySetPropertyLocation = (state: InitState): string => state.setLocationData || '';

const setPropertySetCreatePropertAttempting = (state: InitState): boolean => state.actions.setCreateProperty || false;
const setPropertySetCreatePropertError = (state: InitState): string => state.error.setCreateProperty || '';
const setPropertySetCreatePropert = (state: InitState): string => state.setCreatePropertyId || '';

const setPropertyCreatePropertyModal = (state: InitState): boolean => state.createPropertyModal || false;

const getSearchedOwnerAttempt = (state: InitState): boolean => state.actions.getSearchedOwner || false;
const getSearchedOwnerError = (state: InitState): string => state.error.getSearchedOwner || '';
const searchedOwner = (state: InitState): IPropertyOwners | null => state.searchedOwner || null;

const getPropertyGetCoordinates = (state: InitState): Coordinates => state.coordinates || null;

const getPropertyGetAddress1 = (state: InitState): CoordinatesAddress1 => state.coordinatesaddress1 || null;

const getPropertyGetAddress2 = (state: InitState): CoordinatesAddress2 => state.coordinatesaddress2 || null;

const getPropertyGetPoscode = (state: InitState): CoordinatesPoscode => state.coordinatesposcode || null;

const getPropertyGetState = (state: InitState): CoordinatesState => state.coordinatesstate || null;

const getPropertyGetCountry = (state: InitState): CoordinatesCountry => state.coordinatescountry || null;

const getPropertyGetCity = (state: InitState): CoordinatesCity => state.coordinatescity || null;

const getPropertyUploadUrlAttempting = (state: InitState): boolean => state.actions.getPropertyUploadUrl || false;
const getPropertyUploadUrlError = (state: InitState): string => state.error.getPropertyUploadUrl || '';

const getPropertyUploadUrl = (state: InitState): string => state.PropertyImageUploadURL || '';

const uploadPropertyImageAttempting = (state: InitState): boolean => state.actions.uploadPropertyDocument || false;
const uploadPropertyImageError = (state: InitState): string => state.error.uploadPropertyDocument || '';

const setIsPropertyPropertyImageSuccess = (state: InitState): boolean => state.PropertyImageUploadComplete || false;

const setPropertySetDeleteImageAttempting = (state: InitState): boolean => state.actions.setDeleteImage || false;
const setPropertySetDeleteImageError = (state: InitState): string => state.error.setDeleteImage || '';
const setPropertySetDeleteImage = (state: InitState): string => state.deletedImage || '';

const getPropertyGetPropertyDetailsAttempting = (state: InitState): boolean => state.actions.getPropertPropertyDetails || false;
const getPropertyGetPropertyDetailsError = (state: InitState): string => state.error.getPropertPropertyDetails || '';
const getPropertyDetails = (state: InitState): IProperty | null => state.propertyDetailsData || null;

const setPropertysIsPropertyDataLoaded = (state: InitState): boolean => state.isPropertyDataLoaded || false;
const setPropertysLocationLatitudeFirstLoad = (state: InitState): number => state.LocationLatitudeFirstLoad || 0;
const setPropertysLocationLongitudeFirstLoad = (state: InitState): number => state.LocationLongitudeFirstLoad || 0;

const setPropertyLocationSubmitted = (state: InitState): boolean => state.propertyLocationSubmitted || false;

const setPropertyTenancyStatusAttempting = (state: InitState): boolean => state.actions.setPropertyTenancyStatus || false;
const setPropertyTenancyStatusError = (state: InitState): string => state.error.setPropertyTenancyStatus || '';
const setPropertyTenancyStatusSuccess = (state: InitState): string | null => state.tenancyStatusData || '';

const isSVacancyStatusPackageModalOpen = (state: InitState): boolean => state.isVacancyStatusPropertyModalOpen || false;

const setPropertysetDuplicatePropertyAttempting = (state: InitState): boolean => state.actions.setDuplicateProperty || false;
const setPropertsetDuplicatePropertyyError = (state: InitState): string => state.error.setPropertyTenancyStatus || '';
const setPropertyDuplicateSuccess = (state: InitState): string | null => state.propertyDuplicateProperty || '';

const propertySearchTransaction = (state: InitState): string => state.searchTransaction || '';

const setPropertyPackagesPriceAttempting = (state: InitState): boolean => state.actions.setPropertyPackagesPrice || false;
const setPropertyPackagesPriceError = (state: InitState): string => state.error.setPropertyPackagesPrice || '';
const setPropertyPackagesPrice = (state: InitState): string => state.setPropertyPackagesPrice || '';

const setGoogleDrivePathAttempting = (state: InitState): boolean => state.actions.setGoogleDrivePath || false;
const setGoogleDrivePathError = (state: InitState): string => state.error.setGoogleDrivePath || '';
const googleDrivePathData = (state: InitState): IProperty | null => state.googleDrivePath || null;

const getPropertyGroupAttempt = (state: InitState): boolean => state.actions.getPropertyGroup || false;
const getPropertyGroupError = (state: InitState): string => state.error.getPropertyGroup || '';

const propertyGroup = (state: InitState): GetPropertyGroupResponse => state.propertyGroup || null;

const createPropertyGroupAttempting = (state: InitState): boolean => state.actions.createPropertyGroup || false;
const createPropertyGroupError = (state: InitState): string => state.error.createPropertyGroup || '';
const propertyGroupId = (state: InitState): string => state.propertyGroupId || '';

const deletePropertyGroupAttempting = (state: InitState): boolean => state.actions.deletePropertyGroup || false;
const deletePropertyGroupError = (state: InitState): string => state.error.deletePropertyGroup || '';

const getPropertyGroupDetailsAttempt = (state: InitState): boolean => state.actions.getPropertyGroupDetails || false;
const getPropertyGroupDetailsError = (state: InitState): string => state.error.getPropertyGroupDetails || '';

const propertyGroupDetails = (state: InitState): IPropertyGroupDetails => state.propertyGroupDetails || null;

const editPropertyGroupDetailsAttempt = (state: InitState): boolean => state.actions.editPropertyGroupDetails || false;
const editPropertyGroupDetailsError = (state: InitState): string => state.error.editPropertyGroupDetails || '';

const isPropertyGroupList = (state: InitState): boolean => state.isPropertyGroupList || false;

const duplicatePropertyGroupAttempt = (state: InitState): boolean => state.actions.setDuplicatePropertyGroup || false;
const duplicatePropertyGroupError = (state: InitState): string => state.error.setDuplicatePropertyGroup || '';

const setIsPropertyOpenImagesCollapsible = (state: InitState): boolean => state.propertyImagesCollapsible || false;
const setPropertyImagesSubmitted = (state: InitState): boolean => state.propertyImagesSubmitted || false;
const setShowErrorMessage = (state: InitState): boolean => state.isMapLocationError || false;

const createPropertyAttempting = (state: InitState): boolean => state.actions.createProperty || false;
const createPropertyError = (state: InitState): string => state.error.createProperty || '';
const propertyId = (state: InitState): string => state.propertyId || '';

const editPropertyDetailsAttempt = (state: InitState): boolean => state.actions.editPropertyDetails || false;
const editPropertyDetailsError = (state: InitState): string => state.error.editPropertyDetails || '';

const editPropertyPackagesDetailsAttempt = (state: InitState): boolean => state.actions.editPropertyPackages || false;
const editPropertyPackagesDetailsError = (state: InitState): string => state.error.editPropertyPackages || '';

const propertyImagesUploadedFile = (state: InitState): File[] => state.propertyImagesUploaded || [];
const propertyImagesDeletedString = (state: InitState): string[] => state.propertyImagesDeleted || [];

const getPropertiesReportExportAttempt = (state: InitState): boolean => state.actions.getPropertiesReportExport || false;

const groupPropertiesWithCountAttempt = (state: InitState): boolean => state.actions.groupPropertiesWithCount || false;
const groupPropertisWithCount = (state: InitState): GroupPropertiesWithCountResponse[] => state.groupPropertiesWithCount || [];

export default {
    propertyGetAllAttempt,
    propertyGetAllError,

    propertyReport,

    propertyGetDetailsAttempt,
    propertyGetDetailsError,
    propertyDetails,

    setServicePackageAttempting,
    setServicePackageError,

    isServicePackageModalOpen,
    isUpdatePackagePriceModalOpen,
    isSetGoogleDrivePathModalOpen,
    isSetMilestonesModalOpen,
    isRejectPropertyModalOpen,

    getSearchedPropertyAttempt,
    getSearchedPropertyError,
    searchedProperty,

    setPropertySetDescriptionAttempting,
    setPropertySetDescriptionError,
    setPropertySetDescription,

    setPropertySetPropertyAmenitiesAttempting,
    setPropertySetPropertyAmenitiesError,
    setPropertySetPropertyAmenities,

    setPropertySetPropertyDetailsAttempting,
    setPropertySetPropertyDetailsError,
    setPropertySetPropertyDetails,

    setPropertySetPropertyUserAttempting,
    setPropertySetPropertyUserError,
    setPropertySetPropertyUser,

    setPropertySetPropertyLocationAttempting,
    setPropertySetPropertyLocationError,
    setPropertySetPropertyLocation,

    setPropertySetCreatePropertAttempting,
    setPropertySetCreatePropertError,
    setPropertySetCreatePropert,

    setPropertyCreatePropertyModal,

    getSearchedOwnerAttempt,
    getSearchedOwnerError,
    searchedOwner,

    getPropertyGetCoordinates,
    getPropertyGetAddress1,
    getPropertyGetAddress2,
    getPropertyGetPoscode,
    getPropertyGetState,
    getPropertyGetCountry,
    getPropertyGetCity,

    getPropertyUploadUrlAttempting,
    getPropertyUploadUrlError,
    getPropertyUploadUrl,

    uploadPropertyImageAttempting,
    uploadPropertyImageError,

    setIsPropertyPropertyImageSuccess,

    setPropertySetDeleteImageAttempting,
    setPropertySetDeleteImageError,
    setPropertySetDeleteImage,

    getPropertyGetPropertyDetailsAttempting,
    getPropertyGetPropertyDetailsError,
    getPropertyDetails,

    setPropertysIsPropertyDataLoaded,

    setPropertysLocationLatitudeFirstLoad,
    setPropertysLocationLongitudeFirstLoad,

    setPropertyLocationSubmitted,

    setPropertyTenancyStatusAttempting,
    setPropertyTenancyStatusError,
    setPropertyTenancyStatusSuccess,

    isSVacancyStatusPackageModalOpen,

    setPropertysetDuplicatePropertyAttempting,
    setPropertsetDuplicatePropertyyError,
    setPropertyDuplicateSuccess,

    propertySearchTransaction,

    setPropertyPackagesPriceAttempting,
    setPropertyPackagesPriceError,
    setPropertyPackagesPrice,

    setGoogleDrivePathAttempting,
    setGoogleDrivePathError,
    googleDrivePathData,

    getPropertyGroupAttempt,
    getPropertyGroupError,

    propertyGroup,

    createPropertyGroupAttempting,
    createPropertyGroupError,
    propertyGroupId,

    deletePropertyGroupAttempting,
    deletePropertyGroupError,

    getPropertyGroupDetailsAttempt,
    getPropertyGroupDetailsError,

    propertyGroupDetails,

    editPropertyGroupDetailsAttempt,
    editPropertyGroupDetailsError,

    isPropertyGroupList,

    duplicatePropertyGroupAttempt,
    duplicatePropertyGroupError,

    setIsPropertyOpenImagesCollapsible,
    setPropertyImagesSubmitted,
    setShowErrorMessage,

    createPropertyAttempting,
    createPropertyError,
    propertyId,

    editPropertyDetailsAttempt,
    editPropertyDetailsError,

    editPropertyPackagesDetailsAttempt,
    editPropertyPackagesDetailsError,

    propertyImagesUploadedFile,
    propertyImagesDeletedString,

    getPropertiesReportExportAttempt,

    groupPropertiesWithCountAttempt,
    groupPropertisWithCount,
};
