import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import { GatewayResponseStatus } from 'api/types/types';
import PropertyGateway from 'api/Property';
import { SetImageDeleteActionPayload } from 'redux/slices/property/types';
import { toast } from 'react-toastify';

export default function* watchGetPropertyDeleteImage(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetDeleteImageAttempt', handleGetUploadUrl, api);
}

function* handleGetUploadUrl(api: PropertyGateway, data: SetImageDeleteActionPayload) {
    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const { category, id, name, fileName, fileUrl } = data.payload;

        const response = yield* call([api, api.setPropertyDeleteImage], { category, id, name, fileName, authToken });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.propertySetDeleteImageFailure(response.message));
            toast.error((Translate.t('GeneralMessage.SomethingWrong')));
            return;
        } if (response.status === GatewayResponseStatus.Success) {
            const Input = {
                search: '',
                status: 9999,
                sort: 2,
                transactionType: 9999,
                index: 1,
            };
            yield put(Actions.propertySetDeleteImageSuccess({ name, url: [fileUrl], category }));
            yield put(Actions.packagesGetIViewAllTransactionsResponseAttempt(Input));
            yield put(Actions.setFileViewModalOpen(false));
            toast.success(Translate.t('Property.PropertyImages.DeleteImage'));
        }
    }
}
