import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { delay } from 'redux-saga/effects';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { PayloadAction } from '@reduxjs/toolkit';

import { IApproveRejectPendingPayment } from 'entities/property';
import { toast } from 'react-toastify';

export default function* watchApproveRejectPayment(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('payment/approveRejectPaymentAttempt', handleApproveRejectPayment, api);
}

function* handleApproveRejectPayment(api: PropertyGateway, data: PayloadAction<IApproveRejectPendingPayment>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.approveRejectPayment], { authToken, data: data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.approveRejectPaymentFailure(response.message));
    } else {
        yield put(Actions.approveRejectPaymentSuccess());
        toast.success(response.data);
        yield put(Actions.setApproveRejectModalOpen(false));
        // yield put(Actions.getAllPaymentsReset());
        yield put(Actions.reloadPage());
    }
}
