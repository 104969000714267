import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';

import { GatewayResponseStatus } from 'api/types/types';
import { GetPackagesUploadUrlActionPayload } from 'redux/slices/packages/types';
import PackagesGateway from 'api/Packages';
import { Category } from '@dm/types';
import { toast } from 'react-toastify';

export default function* watchGetPackagesUploadUrl(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/getPackagesUploadUrlAttempt', handleGetUploadUrl, api);
}

function* handleGetUploadUrl(api: PackagesGateway, data: GetPackagesUploadUrlActionPayload) {
    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const { category, id, name, extension, document } = data.payload;

        const response = yield* call([api, api.getPackagesUploadUrl], { category, id, name, extension, authToken });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.getPackagesUploadUrlFailure(response.message));
            toast.error(response.message);
            return;
        } if (response.status === GatewayResponseStatus.Success) {
            yield put(Actions.getPackagesUploadUrlSuccess(response.data));
            let fileType = '';

            if (extension === '.pdf') fileType = 'pdf';
            else fileType = 'image';
            if (category === Category.PropertyManagementTransaction) {
                yield put(Actions.setPropertyManagementTransactionUploadDocumentCheck('Property Management Transaction'));
            }
            yield put(Actions.uploadPackagesDocumentAttempt({ preSignedUrl: response.data, document, docType: fileType }));
        }
    }
}
