import React, { useState, KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import { Colors, Fonts } from '@dm/bigfish';

export interface RoundInputProps {
  /**
  * Label
  */
  label?: string;
  /**
  * Placeholder
  */
  placeholder?: string;
  /**
  * Value
  */
  value?: string;
  /**
  * Disabled
  */
  disabled?: boolean;
  /**
  * On change text handler
  */
  onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
  * On enter press handler
  */
  onEnterPressed?: () => void;
  /**
  * Required
  */
  required?: boolean;
   /**
  * Validate status
  */
  inputError?: boolean;
   /**
  * Validate status
  */
  errorText?: string;
  /**
  Type
  */
  type?: string;
  /**
  * Style
  */
  style?: React.CSSProperties;
}

const FormGroup = styled.div`
  margin: 2px 0;
  font-family: ${Fonts.primary};
  font-size: 16px;
`;

const Label = styled.label<{disabled?: boolean}>`
  display: block;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
`;

const Input = styled.input<RoundInputProps>`
  display: block;
  width: 100%;
  height: 80px;
  margin-top: 12px;
  margin-bottom: 6px;
  padding: 8px;
  background-color: ${Colors.greyLightest};
  color: ${Colors.black};
  font-size: 12px;
  line-height: 14px;
  box-sizing: border-box;
  border: none;
  border-radius: 12px;
  transition: all .5s;
  &:focus {
    outline-color: ${Colors.greyLight};
  }
  &::placeholder {
    color: ${Colors.greyDark};
  }
  ${props => props.disabled && css`
    pointerEvents: none;
    cursor: not-allowed;
  `}
  ${props => props.inputError && css`
    border: 1px solid ${Colors.danger};
    &:focus {
      outline-color: ${Colors.danger};
    }
  `}
`;

const ValidateText = styled.small`
  margin-left: 4px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: ${Colors.warning};
`;

const DescriptionRoundInput = ({ label, placeholder, value, disabled, onChangeText, onEnterPressed, required, inputError, errorText, type, style, ...otherProps }: RoundInputProps): JSX.Element => {
    const onEnterPressedHandler = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (onEnterPressed) {
                onEnterPressed();
            }
        }
    };

    return (
        <FormGroup style={style} {...otherProps}>
            {label ? <Label disabled={disabled}>{label}</Label> : ''}

            <Input placeholder={placeholder} disabled={disabled} onChange={onChangeText} onKeyDown={(e: KeyboardEvent) => onEnterPressedHandler(e)} value={value} required={required} inputError={inputError} />

            {inputError && (<ValidateText>{errorText}</ValidateText>)}
        </FormGroup>
    );
};

export default DescriptionRoundInput;
