import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetDescriptionActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchSetDescription(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetDescriptionAttempt', handleDesription, api);
}

function* handleDesription(api: PropertyGateway, data: SetDescriptionActionPayload) {
    const { id, description } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setDescription], { id, description, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Saved');
        yield put(Actions.propertySetDescriptionSuccess(response.data));

        if (!response.data) {
            yield put(Actions.propertySetDescriptionFailure(Translate.t('GeneralMessage.SomethingWrong')));
            toast.error((Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.propertySetDescriptionFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error((Translate.t('GeneralMessage.SomethingWrong')));
            } else {
                toast.error((Translate.t('GeneralMessage.SomethingWrong')));
            }
        }
    }
}
