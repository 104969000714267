import { PropertyTypeEnum, PropertyStatus, SortByEnum, PropertyPackages } from '@dm/types';
import { IOption } from 'entities/component';

export const propertyTypeDropdownData: IOption[] = [
    {
        id: PropertyTypeEnum.Apartment,
        description: 'Apartment',
    },
    {
        id: PropertyTypeEnum.Landed,
        description: 'Landed',
    },
];

export const propertyStatusDropdownData: IOption[] = [
    {
        id: PropertyStatus.approved,
        description: 'Approved',
    },
    {
        id: PropertyStatus.deactivated,
        description: 'Deactivated',
    },
    {
        id: PropertyStatus.draftProperty,
        description: 'Draft Property',
    },
    {
        id: PropertyStatus.pendingApproval,
        description: 'Pending Approval',
    },
    {
        id: PropertyStatus.rejected,
        description: 'Rejected',
    },
    {
        id: PropertyStatus.tenanted,
        description: 'Tenanted',
    },
    {
        id: PropertyStatus.vacant,
        description: 'Vacant',
    },
];

export const propertySortByDropdownData: IOption[] = [
    {
        id: SortByEnum.createdAtDesc,
        description: 'Created at',
    },
    {
        id: SortByEnum.updatedAtDesc,
        description: 'Updated at',
    },
    {
        id: SortByEnum.unitNoDesc,
        description: 'Unit No. descending',
    },
    {
        id: SortByEnum.unitNoAsc,
        description: 'Unit No. ascending',
    },
];

export const propertySelectByActivePackages = [
    {
        id: PropertyPackages.propertyManagement,
        description: 'Property Management',
        active: false,
    },
    {
        id: PropertyPackages.vacantManagement,
        description: 'Vacant Management',
        active: false,
    },
    {
        id: PropertyPackages.defectInspection,
        description: 'Defect Inspection',
        active: false,
    },
    {
        id: PropertyPackages.designRenovation,
        description: 'Design Renovation',
        active: false,
    },
    {
        id: PropertyPackages.vacantPossession,
        description: 'Vacant Possession',
        active: false,
    },
    {
        id: PropertyPackages.leaseSale,
        description: 'Lease Sale',
        active: false,
    },
];
