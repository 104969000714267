import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    BreadCrumb,
    Colors,
    ImageCarousel,
    LineInput,
    Button,
    IImageCarousel,
    EventTrail,
    IEventTrail,
    Checkbox,
    CardCollapsible,
} from '@dm/bigfish';
import {
    FurnishingTypeEnum,
    IProperty,
    IPropertyDefectInspectionStage,
    IPropertyPackageStatusEnum,
    LandTitleTypeEnum,
    PropertyStatus,
    PropertyTitleTypeEnum,
    PropertyTypeEnum,
    TenancyStatus,
    TenureTypeEnum,
} from '@dm/types';

import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import houseImg from 'assets/images/house1.png';

import MainContainer from 'components/MainContainer';
import DateInput from 'components/DateInput';
import SelectionInput from 'components/SelectionInput';
import LoadingScreen from 'components/LoadingScreen';
import {
    PropertyTypeOptions,
    LandTitleOptions,
    PropertyTitleOptions,
    TenureTypeOptions,
    FurnishingTypeOptions,
} from 'components/options/Property';
import ButtonInfoPackages from 'components/ButtonInfoPackages';

import ServicePackageModal from './components/ServicePackageModal';
import RejectPropertyModal from './components/RejectPropertyModal';
import SetVacancyStatusModal from './components/TenancyStatusUpdateModal';
import UpdatePackagePriceModal from './components/UpdatePackagePriceModal';

import Map from './components/PropertyMapDetails';
import SetGoogleDrivePathModal from './components/SetGoogleDrivePathModal';
import SetMilestonesModal from './components/SetMilestonesModal';

interface PropertyDetailsProps {
    loading: boolean;
    error: string;
    propertyDetails: IProperty | null;
    getOwnerDetails: (userId: string) => void;
    getPropertyDetails: (id: string) => void;
    clearPropertyDetails: () => void;
    setServicePackageModalOpen: (state: boolean) => void;
    setUpdatePackagePriceModalOpen: (state: boolean) => void;
    setGoogleDrivePathModalOpen: (state: boolean) => void;
    setMilestonesModalOpen: (state: boolean) => void;
    setRejectPropertModalOpen: (state: boolean) => void;
    setTenancyStatusPropertModalOpen: (state: boolean) => void;
}

const PropertyDetails = (props: PropertyDetailsProps): JSX.Element => {
    const {
        loading,
        error,
        propertyDetails,
        getOwnerDetails,
        getPropertyDetails,
        clearPropertyDetails,
        setServicePackageModalOpen,
        setUpdatePackagePriceModalOpen,
        setGoogleDrivePathModalOpen,
        setMilestonesModalOpen,
        setRejectPropertModalOpen,
        setTenancyStatusPropertModalOpen,
    } = props;

    const [propertyImageCarousel, setPropertyImageCarousel] = useState<IImageCarousel[]>([]);
    const [statePropertyName, setStatePropertyName] = useState('');
    const [statePropertyDesc, setStatePropertyDesc] = useState('');
    const [statePropertyType, setStatePropertyType] = useState<PropertyTypeEnum>(PropertyTypeEnum.Apartment);

    const [stateLandTitle, setStateLandTitle] = useState<LandTitleTypeEnum>(LandTitleTypeEnum.Commercial);
    const [stateUnitType, setStateUnitType] = useState('');

    const [statePropertyTitleType, setStatePropertyTitleType] = useState<PropertyTitleTypeEnum>(PropertyTitleTypeEnum.Individual);
    const [statePropertyTenure, setStatePropertyTenure] = useState<TenureTypeEnum>(TenureTypeEnum.FreeHold);
    const [statePropertyStorey, setStatePropertyStorey] = useState('');

    const [stateBuiltSize, setStateBuiltSize] = useState('');
    const [stateBuiltPrice, setStateBuiltPrice] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [tnbAccNo, setTnbAccNo] = useState<string | undefined>('');
    const [parcelRentAccNo, setParcelRentAccNo] = useState<string | undefined>('');
    const [assTaxAccNo, setAssTaxAccNo] = useState<string | undefined>('');
    const [petGasAccNo, setPetGasAccNo] = useState<string | undefined>('');
    const [parkingLotNo, setParkingLotNo] = useState<string | undefined>('');
    const [indahWaterNo, setIndahWaterNo] = useState<string | undefined>('');
    const [spaPrice, setSpaPrice] = useState<string | undefined>('');
    const [nettPrice, setNettPrice] = useState<string | undefined>('');
    const [tenancyStatus, setTenancyStatus] = useState<number | undefined>();

    const [stateRefNo, setStateRefNo] = useState('');
    const [stateBuiltDimension, setStateBuiltDimension] = useState('');
    const [statePostedDate, setStatePostedDate] = useState(new Date());

    const [stateFurnishing, setStateFurnishing] = useState<FurnishingTypeEnum>(FurnishingTypeEnum.None);
    const [stateOccupancy, setStateOccupancy] = useState('');
    const [stateFacingDirection, setStateFacingDirection] = useState('');
    const [stateRecentEvents, setStateRecentEvents] = useState<IEventTrail[]>([]);

    // property Amenities states
    const [television, setTelevision] = useState(false);
    const [curtain, setCurtain] = useState(false);
    const [mattress, setMattress] = useState(false);
    const [washingMachine, setWashingMachine] = useState(false);
    const [hoodHub, setHoodHub] = useState(false);
    const [airConditioner, setAirConditioner] = useState(false);
    const [waterHeater, setWaterHeater] = useState(false);
    const [diningTable, setDiningTable] = useState(false);
    const [wardrobe, setWardrobe] = useState(false);
    const [kitchenCabinet, setKitchenCabinet] = useState(false);
    const [oven, setOven] = useState(false);
    const [refrigerator, setRefrigerator] = useState(false);
    const [sofa, setSofa] = useState(false);
    const [microwave, setMicrowave] = useState(false);
    const [bedFrame, setBedFrame] = useState(false);
    const [internet, setInternet] = useState(false);

    const [bBQ, setBBQ] = useState(false);
    const [coveredParking, setCoveredParking] = useState(false);
    const [gymnasium, setGymnasium] = useState(false);
    const [basketballCourt, setBasketballCourt] = useState(false);
    const [restaurant, setRestaurant] = useState(false);
    const [airConditioner2, setAirConditioner2] = useState(false);
    const [dobiLaundromat, setDobiLaundromat] = useState(false);
    const [nursery, setNursery] = useState(false);
    const [playground, setPlayground] = useState(false);
    const [sauna, setSauna] = useState(false);
    const [swimmingPool, setSwimmingPool] = useState(false);
    const [tennisCourt, setTennisCourt] = useState(false);
    const [security24hour, setSecurity24hour] = useState(false);
    const [miniMart, setMiniMart] = useState(false);
    const [squashCourt, setSquashCourt] = useState(false);
    const [badmintonCourt, setBadmintonCourt] = useState(false);
    const [elevator, setElevator] = useState(false);

    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                label: Translate.t('PropertyRpDetails.PropertyRpDetailsBcDashboard'),
                onClick: () => { NavActions.navToPropertyReports(); },
            },
            {
                label: Translate.t('PropertyRpDetails.PropertyRpDetailsTitlePropDetails'),
            },
            {
                label: 'Property Details',
            },
        ],
    );

    const params = useParams();
    const { id } = params;

    useEffect(() => {
        if (id) {
            getPropertyDetails(id);
        }

        return () => {
            clearPropertyDetails();
            setServicePackageModalOpen(false);
            setUpdatePackagePriceModalOpen(false);
            setRejectPropertModalOpen(false);
        };
    }, []);

    useEffect(() => {
        if (propertyDetails) {
            setBreadCrumb(
                [
                    {
                        label: Translate.t('PropertyRpDetails.PropertyRpDetailsBcDashboard'),
                        onClick: () => { NavActions.navToPropertyReports(); },
                    },
                    {
                        label: Translate.t('PropertyRpDetails.PropertyRpDetailsTitlePropDetails'),
                    },
                    {
                        label: propertyDetails.name as string,
                    },
                ],
            );
        }
    }, [propertyDetails]);

    useEffect(() => {
        if (propertyDetails) {
            const { _id, name, userId, description = '', recentEvents = [] } = propertyDetails;
            setStatePropertyName(name);
            setStatePropertyDesc(description);
            setStateRefNo(_id);
            getOwnerDetails(userId);

            if (recentEvents.length) {
                const parsedRecentEvents: IEventTrail[] = recentEvents.map(item => {
                    const { message } = item;

                    return {
                        text: message,
                    };
                });

                setStateRecentEvents(parsedRecentEvents);
            }

            if (propertyDetails.details) {
                const {
                    type = PropertyTypeEnum.Apartment,
                    landTitle = LandTitleTypeEnum.Commercial,
                    unitType = '',
                    titleType = PropertyTitleTypeEnum.Individual,
                    tenure = TenureTypeEnum.FreeHold,
                    storey = '',
                    buildUpSize = 0,
                    buildUpPricePerSqft = 0,
                    builtUpDimension = '',
                    furnishing = FurnishingTypeEnum.None,
                    occupancy = '',
                    facingDirection = '',
                } = propertyDetails.details;

                setStatePropertyType(type);
                setStateLandTitle(landTitle);
                setStateUnitType(unitType);
                setStatePropertyTitleType(titleType);
                setStatePropertyTenure(tenure);
                setStatePropertyStorey(storey.toString());
                setStateBuiltSize(`${buildUpSize} sq. ft.`);
                setStateBuiltPrice(`${buildUpPricePerSqft} per sq. ft.`);
                setStateBuiltDimension(`${builtUpDimension}`);
                setStateFurnishing(furnishing);
                setStateOccupancy(occupancy);
                setStateFacingDirection(facingDirection);
                setUnitNumber(propertyDetails?.unitNo ?? '');
                setTnbAccNo(propertyDetails.details.tnbAccNo);
                setParcelRentAccNo(propertyDetails.details.parcelRentAccNo);
                setAssTaxAccNo(propertyDetails.details.assTaxAccNo);
                setPetGasAccNo(propertyDetails.details.petGasAccNo);
                setParkingLotNo(propertyDetails.details.parkingLotNo);
                setIndahWaterNo(propertyDetails.details.indahWaterAccNo);
                setSpaPrice(propertyDetails.details.spaPrice);
                setNettPrice(propertyDetails.details.nettPrice);
                setTenancyStatus(propertyDetails?.tenancyStatus);
            }

            createImageCarouselGallery();
        }
        if ((propertyDetails?.amenities?.facilities.length !== 0) || ((propertyDetails?.amenities?.furnishing.length !== 0))) {
            if (propertyDetails?.amenities) {
                onAmenitiesDataPersistHandler();
            }
        }
    }, [propertyDetails]);

    const setCollapsibleChipText = (statusEnum: PropertyStatus) => {
        switch (statusEnum) {
            case PropertyStatus.approved: return 'Approved';
            case PropertyStatus.deactivated: return 'Deactivated';
            case PropertyStatus.draftProperty: return 'Draft';
            case PropertyStatus.pendingApproval: return 'Pending';
            case PropertyStatus.tenanted: return 'Tenanted';
            default: return 'Vacant';
        }
    };

    // THESE TEXT NOT TRANSLATED BECAUSE IT NOT TO DISPLAY BUT TO CHECK
    const checkPropertyPackagesStatus = (packages: any) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case 0:
                return '';
            case 1:
                return 'Pending';
            case 2:
                return 'pending verification';
            case 3:
                return 'Active';
            case 4:
                return 'PaymentRejected';
            case 5:
                return 'Disabled';
            case 6:
                return 'FreeTrial';
            case 7:
                return 'purchase';
            default:
                return '';
        }
    };

    const checkTenancyStatus = (status: TenancyStatus) => {
        switch (status) {
            case TenancyStatus.Available: return 'Available';
            case TenancyStatus.NotAvailable: return 'Not Available';
            case TenancyStatus.Pending: return 'Pending';
            case TenancyStatus.Tenanted: return 'Tenanted';
            default: return 'Not Set';
        }
    };

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onAmenitiesDataPersistHandler = () => {
        setTelevision(propertyDetails?.amenities?.furnishing[0]?.available ?? false);
        setCurtain(propertyDetails?.amenities?.furnishing[1]?.available ?? false);
        setMattress(propertyDetails?.amenities?.furnishing[2]?.available ?? false);
        setWashingMachine(propertyDetails?.amenities?.furnishing[3]?.available ?? false);
        setHoodHub(propertyDetails?.amenities?.furnishing[4]?.available ?? false);
        setAirConditioner(propertyDetails?.amenities?.furnishing[5]?.available ?? false);
        setWaterHeater(propertyDetails?.amenities?.furnishing[6]?.available ?? false);
        setDiningTable(propertyDetails?.amenities?.furnishing[7]?.available ?? false);
        setWardrobe(propertyDetails?.amenities?.furnishing[8]?.available ?? false);
        setKitchenCabinet(propertyDetails?.amenities?.furnishing[9]?.available ?? false);
        setOven(propertyDetails?.amenities?.furnishing[10]?.available ?? false);
        setRefrigerator(propertyDetails?.amenities?.furnishing[11]?.available ?? false);
        setSofa(propertyDetails?.amenities?.furnishing[12]?.available ?? false);
        setMicrowave(propertyDetails?.amenities?.furnishing[13]?.available ?? false);
        setBedFrame(propertyDetails?.amenities?.furnishing[14]?.available ?? false);
        setInternet(propertyDetails?.amenities?.furnishing[15]?.available ?? false);

        setBBQ(propertyDetails?.amenities?.facilities[0]?.available ?? false);
        setCoveredParking(propertyDetails?.amenities?.facilities[1]?.available ?? false);
        setGymnasium(propertyDetails?.amenities?.facilities[2]?.available ?? false);
        setBasketballCourt(propertyDetails?.amenities?.facilities[3]?.available ?? false);
        setRestaurant(propertyDetails?.amenities?.facilities[4]?.available ?? false);
        setAirConditioner2(propertyDetails?.amenities?.facilities[5]?.available ?? false);
        setDobiLaundromat(propertyDetails?.amenities?.facilities[6]?.available ?? false);
        setNursery(propertyDetails?.amenities?.facilities[7]?.available ?? false);
        setPlayground(propertyDetails?.amenities?.facilities[8]?.available ?? false);
        setSauna(propertyDetails?.amenities?.facilities[9]?.available ?? false);
        setSwimmingPool(propertyDetails?.amenities?.facilities[10]?.available ?? false);
        setTennisCourt(propertyDetails?.amenities?.facilities[11]?.available ?? false);
        setSecurity24hour(propertyDetails?.amenities?.facilities[12]?.available ?? false);
        setMiniMart(propertyDetails?.amenities?.facilities[13]?.available ?? false);
        setSquashCourt(propertyDetails?.amenities?.facilities[14]?.available ?? false);
        setBadmintonCourt(propertyDetails?.amenities?.facilities[15]?.available ?? false);
        setElevator(propertyDetails?.amenities?.facilities[16]?.available ?? false);
    };

    const createImageCarouselGallery = () => {
        const listImageCarousel: IImageCarousel[] = [];

        if (propertyDetails?.images && propertyDetails.images.length) {
            propertyDetails.images.forEach(({ name, url }, mainIndex) => {
                url.forEach((imageUrl, imageUrlIndex) => {
                    const imagesObject = ({
                        image: imageUrl,
                        desc: `${name} ${imageUrlIndex + 1}`,
                    });

                    if (listImageCarousel.length < 5) {
                        listImageCarousel.push(imagesObject);
                    }

                    if (mainIndex === propertyDetails.images.length - 1 && imageUrlIndex === url.length - 1) {
                        setPropertyImageCarousel(listImageCarousel);
                    }
                });
            });
        }
    };

    if (loading) {
        return (
            <MainContainer>
                <LoadingContainer>
                    <LoadingScreen />
                </LoadingContainer>
            </MainContainer>
        );
    }

    if (error) {
        return (
            <MainContainer>
                <LoadingContainer>
                    {error}
                </LoadingContainer>
            </MainContainer>
        );
    }

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <ImageCarouselContainer>
                    {propertyImageCarousel.length >= 1 && (
                        <ImageCarousel
                            style={{
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            data={propertyImageCarousel}
                        />
                    )}

                </ImageCarouselContainer>

                <DetailsViewContainer>
                    <DetailsInfoContainer>
                        <BasicDetailsContainer>
                            <Title>{statePropertyName || Translate.t('PropertyReport.Property')}</Title>
                            <Text>{statePropertyDesc}</Text>
                        </BasicDetailsContainer>
                        <PropertyDetailsContainer>
                            <Title>{Translate.t('PropertyRpDetails.PropertyRpDetailsTitlePropDetails')}</Title>
                            <PropertyDetailsInfoContainer>
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropType')}
                                    data={PropertyTypeOptions}
                                    currentValue={statePropertyType}
                                    onChangeSelection={(e) => setStatePropertyType(+e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelLandTitle')}
                                    data={LandTitleOptions}
                                    currentValue={stateLandTitle}
                                    onChangeSelection={(e) => setStateLandTitle(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelUnitType')}
                                    value={stateUnitType}
                                    onChangeText={(e) => setStateUnitType(e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropTitleType')}
                                    data={PropertyTitleOptions}
                                    currentValue={statePropertyTitleType}
                                    onChangeSelection={(e) => setStatePropertyTitleType(+e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelTenure')}
                                    data={TenureTypeOptions}
                                    currentValue={statePropertyTenure}
                                    onChangeSelection={(e) => setStatePropertyTenure(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelStorey')}
                                    value={statePropertyStorey}
                                    onChangeText={(e) => setStatePropertyStorey(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtSize')}
                                    value={stateBuiltSize}
                                    onChangeText={(e) => setStateBuiltSize(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtPrice')}
                                    value={stateBuiltPrice}
                                    onChangeText={(e) => setStateBuiltPrice(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtDim')}
                                    value={stateBuiltDimension}
                                    onChangeText={(e) => setStateBuiltDimension(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelRefNo')}
                                    value={stateRefNo}
                                    onChangeText={(e) => setStateRefNo(e.target.value)}
                                    disabled
                                />
                                <DateInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPostedDate')}
                                    date={statePostedDate}
                                    onChangeDate={(date) => setStatePostedDate(date)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelFurnishing')}
                                    data={FurnishingTypeOptions}
                                    currentValue={stateFurnishing}
                                    onChangeSelection={(e) => setStateFurnishing(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelOccupancy')}
                                    value={stateOccupancy}
                                    onChangeText={(e) => setStateOccupancy(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelFacingDirection')}
                                    value={stateFacingDirection}
                                    onChangeText={(e) => setStateFacingDirection(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='unit number'
                                    value={unitNumber}
                                    onChangeText={e => setUnitNumber(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='TNB acc number'
                                    value={tnbAccNo}
                                    onChangeText={e => setTnbAccNo(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='Parcel rent acc no'
                                    value={parcelRentAccNo}
                                    onChangeText={e => setParcelRentAccNo(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='Assessment tax no'
                                    value={assTaxAccNo}
                                    onChangeText={e => setAssTaxAccNo(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='PET gas acc no'
                                    value={petGasAccNo}
                                    onChangeText={e => setPetGasAccNo(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='Parking lot no'
                                    value={parkingLotNo}
                                    onChangeText={e => setParkingLotNo(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='Indah Water acc no'
                                    value={indahWaterNo}
                                    onChangeText={e => setIndahWaterNo(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='SPA price'
                                    value={spaPrice}
                                    onChangeText={e => setSpaPrice(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='Nett price'
                                    value={nettPrice}
                                    onChangeText={e => setNettPrice(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='Tenancy status'
                                    value={checkTenancyStatus(tenancyStatus ?? 99)}
                                    onChangeText={e => setTenancyStatus(Number(e.target.value))}
                                    disabled
                                />
                            </PropertyDetailsInfoContainer>
                        </PropertyDetailsContainer>
                        <RoomDetailsContainer>
                            <Title>{Translate.t('PropertyRpDetails.PropertyRpDetailsTitleRoomDetails')}</Title>
                            <PropertyImageGalleryContainer>
                                {
                                    propertyDetails?.images && propertyDetails?.images.map((roomDetail, roomDetailIndex) => {
                                        const roomDetailKey = roomDetailIndex + roomDetail.name;

                                        return (
                                            propertyDetails?.images.length ? (
                                                <PropertyImageGalleryCard key={roomDetailKey}>
                                                    <PropertyImageGalleryTitle>{roomDetail.name}</PropertyImageGalleryTitle>
                                                    <PropertyImageGallery>
                                                        {
                                                            roomDetail.url.map((roomImage, roomImageIndex) => {
                                                                const roomImageKey = roomImageIndex + roomImage;
                                                                return (
                                                                    <img src={roomImage} alt='room images' key={roomImageKey} />
                                                                );
                                                            })
                                                        }
                                                    </PropertyImageGallery>
                                                    {roomDetail?.url.length > 1 && <Text style={{ fontSize: '11px', lineHeight: 1, fontStyle: 'italic', color: Colors.greyDark, marginTop: '10px' }}>{Translate.t('PropertyRpDetails.PropertyRpDetailsInstruction')}</Text>}
                                                </PropertyImageGalleryCard>
                                            ) : (
                                                Translate.t('PropertyRpDetails.PropertyRpDetailsErrorNoImagesFound')
                                            ));
                                    })
                                }

                                {!propertyDetails?.images.length && Translate.t('GeneralUI.Noimagesfound')}
                            </PropertyImageGalleryContainer>
                        </RoomDetailsContainer>
                        <BasicDetailsContainer>
                            <Title>{Translate.t('Property.PropertyAmenities.ItemTitleAmenities')}</Title>
                            <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFurnishing')}</ItemTitle>
                            <DetailsContainer>
                                <Checkbox label={Translate.t('Property.PropertyAmenities.TelevisionLabel')} checked={television} onClickCheckbox={() => setTelevision(!television)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.CurtainLabel')} checked={curtain} onClickCheckbox={() => setCurtain(!curtain)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MattressLabel')} checked={mattress} onClickCheckbox={() => setMattress(!mattress)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WashingMachineLabel')} checked={washingMachine} onClickCheckbox={() => setWashingMachine(!washingMachine)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.HoodHubLabel')} checked={hoodHub} onClickCheckbox={() => setHoodHub(!hoodHub)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner} onClickCheckbox={() => setAirConditioner(!airConditioner)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WaterHeaterLabel')} checked={waterHeater} onClickCheckbox={() => setWaterHeater(!waterHeater)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.DiningTableLabel')} checked={diningTable} onClickCheckbox={() => setDiningTable(!diningTable)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WardrobeLabel')} checked={wardrobe} onClickCheckbox={() => setWardrobe(!wardrobe)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.KitchenCabinetLabel')} checked={kitchenCabinet} onClickCheckbox={() => setKitchenCabinet(!kitchenCabinet)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.OvenLabel')} checked={oven} onClickCheckbox={() => setOven(!oven)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.RefrigeratorLabel')} checked={refrigerator} onClickCheckbox={() => setRefrigerator(!refrigerator)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SofaLabel')} checked={sofa} onClickCheckbox={() => setSofa(!sofa)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MicrowaveLabel')} checked={microwave} onClickCheckbox={() => setMicrowave(!microwave)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BedframeLabel')} checked={bedFrame} onClickCheckbox={() => setBedFrame(!bedFrame)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.InternetLabel')} checked={internet} onClickCheckbox={() => setInternet(!internet)} />
                            </DetailsContainer>
                            <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFacilities')}</ItemTitle>
                            <DetailsContainer>
                                <Checkbox label='BBQ' checked={bBQ} onClickCheckbox={() => setBBQ(!bBQ)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.CoveredParkingLabel')} checked={coveredParking} onClickCheckbox={() => setCoveredParking(!coveredParking)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.GymnasiumLabel')} checked={gymnasium} onClickCheckbox={() => setGymnasium(!gymnasium)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BasketballCourtLabel')} checked={basketballCourt} onClickCheckbox={() => setBasketballCourt(!basketballCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.RestaurantLabel')} checked={restaurant} onClickCheckbox={() => setRestaurant(!restaurant)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner2} onClickCheckbox={() => setAirConditioner2(!airConditioner2)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.DobiLabel')} checked={dobiLaundromat} onClickCheckbox={() => setDobiLaundromat(!dobiLaundromat)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.NurseryLabel')} checked={nursery} onClickCheckbox={() => setNursery(!nursery)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.PlaygroundLabel')} checked={playground} onClickCheckbox={() => setPlayground(!playground)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SaunaLabel')} checked={sauna} onClickCheckbox={() => setSauna(!sauna)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SwimmingPoolLabel')} checked={swimmingPool} onClickCheckbox={() => setSwimmingPool(!swimmingPool)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.TennisCourtLabel')} checked={tennisCourt} onClickCheckbox={() => setTennisCourt(!tennisCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SecurityLabel')} checked={security24hour} onClickCheckbox={() => setSecurity24hour(!security24hour)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MiniMartLabel')} checked={miniMart} onClickCheckbox={() => setMiniMart(!miniMart)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SquashCourtLabel')} checked={squashCourt} onClickCheckbox={() => setSquashCourt(!squashCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BadmintonCourtLabel')} checked={badmintonCourt} onClickCheckbox={() => setBadmintonCourt(!badmintonCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.ElevatorLabel')} checked={elevator} onClickCheckbox={() => setElevator(!elevator)} />
                            </DetailsContainer>
                        </BasicDetailsContainer>
                    </DetailsInfoContainer>
                    <PropertyControlContainerMain>
                        <PropertyControlContainer>
                            <Title>{Translate.t('PropertyRpDetails.PropertyRpDetailsTitleAdminControls')}</Title>
                            <ButtonControlContainer>
                                {
                                    (propertyDetails?.status !== PropertyStatus.approved) && (
                                        <>
                                            <Button
                                                style={{
                                                    backgroundColor: '#509A7F',
                                                    width: '100%',
                                                    height: '70px',
                                                }}
                                                label={Translate.t('PropertyRpDetails.PropertyRpDetailsBtnApprove')}
                                                onClick={() => setServicePackageModalOpen(true)}
                                            />
                                            <Button
                                                style={{
                                                    backgroundColor: Colors.secondaryLight,
                                                    width: '100%',
                                                    height: '70px',
                                                }}
                                                label={Translate.t('PropertyRpDetails.PropertyRpDetailsBtnReject')}
                                                onClick={() => setRejectPropertModalOpen(true)}
                                            />
                                        </>
                                    )
                                }

                                {(propertyDetails?.status === PropertyStatus.approved) && (
                                    // ? adding admin edit pricing for packages here
                                    <>
                                        <Button
                                            style={{
                                                backgroundColor: Colors.secondaryLight,
                                                width: '100%',
                                                height: '70px',
                                            }}
                                            label={Translate.t('PropertyRpDetails.PropertyRpDetailsBtnUpdateTenancyStatus')}
                                            onClick={() => [setTenancyStatusPropertModalOpen(true)]}
                                        />
                                        <Button
                                            style={{
                                                backgroundColor: '#509A7F',
                                                width: '100%',
                                                height: '70px',
                                            }}
                                            label={Translate.t('Packages.UpdatePackagesPrices')}
                                            onClick={() => setUpdatePackagePriceModalOpen(true)}
                                        />
                                        <Button
                                            style={{
                                                backgroundColor: Colors.primary,
                                                width: '100%',
                                                height: '70px',
                                            }}
                                            label={Translate.t('Packages.SetGoogleDrivePath')}
                                            onClick={() => setGoogleDrivePathModalOpen(true)}
                                        />
                                        <Button
                                            style={{
                                                backgroundColor: Colors.primary,
                                                width: '100%',
                                                height: '70px',
                                            }}
                                            label={Translate.t('Packages.SetMilestones')}
                                            onClick={() => setMilestonesModalOpen(true)}
                                        />
                                    </>
                                )}

                                {(propertyDetails?.status === PropertyStatus.approved)
                                    && (

                                        <CardCollapsible
                                            title={propertyDetails?.name.substring(0, 10) ?? ''}
                                            subtitle={propertyDetails?.location?.city ?? ''}
                                            chipText={setCollapsibleChipText(propertyDetails?.status ?? 4)}
                                            style={{
                                                marginBottom: '10px',
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <CollapsibleContentContainer>
                                                <img
                                                    src={houseImg}
                                                    alt=''
                                                    id='propertyImg'
                                                />

                                                <div
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >

                                                    <EventTrail
                                                        title={Translate.t('RecentEvent.RecentEvent')}
                                                        primary
                                                        buttonLabel={Translate.t('RecentEvent.RecentEvent')}
                                                        data={stateRecentEvents}
                                                        onClickButtonLabel={() => { return false; }}
                                                        emptyText={Translate.t('Property.PropertyRecentEventsInfo')}
                                                        style={{
                                                            maxWidth: '100%',
                                                            marginBottom: '20px',
                                                            minHeight: '200px',
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >

                                                        <ButtonInfoFirstMarginContainer>
                                                            {(propertyDetails?.packages && propertyDetails.packages.vacantPossession?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} status={checkPropertyPackagesStatus(propertyDetails.packages.vacantPossession.status)} onclick={() => NavActions.navToPackagesVacantPosession(propertyDetails._id)} />
                                                            ) : (propertyDetails?.packages?.vacantPossession?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToPackagesVacantPosession(propertyDetails?._id ?? '')} />}
                                                        </ButtonInfoFirstMarginContainer>
                                                        <ButtonInfoMarginContainer>
                                                            {(propertyDetails?.packages && propertyDetails?.packages.defectInspection?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} status={checkPropertyPackagesStatus(propertyDetails.packages.defectInspection.status)} onclick={() => NavActions.navToPackagesDefectInspection(propertyDetails._id)} />
                                                            ) : (propertyDetails?.packages?.defectInspection?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToPackagesDefectInspection(propertyDetails?._id ?? '')} />}
                                                        </ButtonInfoMarginContainer>
                                                        <ButtonInfoFirstMarginContainer>
                                                            {(propertyDetails?.packages && propertyDetails.packages.vacantManagement?.status) ? (
                                                                <ButtonInfoPackages desc='vacantManagement' status={checkPropertyPackagesStatus(propertyDetails.packages.vacantManagement.status)} onclick={() => NavActions.navToPackagesVacantManagement(propertyDetails._id)} />
                                                            ) : (propertyDetails?.packages?.vacantManagement?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToPackagesVacantManagement(propertyDetails?._id ?? '')} />}
                                                        </ButtonInfoFirstMarginContainer>
                                                        <ButtonInfoFirstMarginContainer>
                                                            {(propertyDetails?.packages && propertyDetails.packages.propertyManagement?.status) ? (
                                                                <ButtonInfoPackages desc='property Management' status={checkPropertyPackagesStatus(propertyDetails.packages.propertyManagement.status)} onclick={() => NavActions.navToPackagesPropertyManagement(propertyDetails._id)} />
                                                            ) : (propertyDetails?.packages?.propertyManagement?.price) && <ButtonInfoPackages desc='property management' status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToPackagesPropertyManagement(propertyDetails?._id ?? '')} />}
                                                        </ButtonInfoFirstMarginContainer>

                                                        <ButtonInfoMarginContainer>
                                                            {(propertyDetails?.packages && propertyDetails?.packages.leaseSale?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} status={checkPropertyPackagesStatus(propertyDetails?.packages.leaseSale.status)} onclick={() => NavActions.navToPackagesLeaseAndSale(propertyDetails._id)} />
                                                            ) : (propertyDetails?.packages?.leaseSale?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToPackagesLeaseAndSale(propertyDetails._id)} />}
                                                        </ButtonInfoMarginContainer>
                                                        {id && (
                                                            <AdminControlsContainer>
                                                                <Button
                                                                    label={Translate.t('RecentEvent.CreateEditEventMessages')}
                                                                    style={{
                                                                        width: '100%',
                                                                        fontSize: '16px',
                                                                        marginBottom: '10px',
                                                                    }}
                                                                    onClick={() => {
                                                                        NavActions.navToCreateEvent(propertyDetails?._id || '', propertyDetails?.userId);
                                                                    }}
                                                                />

                                                            </AdminControlsContainer>
                                                        )}

                                                    </div>

                                                </div>
                                            </CollapsibleContentContainer>
                                        </CardCollapsible>
                                    )}

                                {/* TEMP */}
                                {/* <Button
                                style={{
                                    width: '100%',
                                    height: '70px',
                                }}
                                label={Translate.t('PropertyRpDetails.PropertyRpDetailsBtnContact')}
                            /> */}
                            </ButtonControlContainer>
                        </PropertyControlContainer>

                        <PropertyMapContainer>
                            {propertyDetails?.location?.address1.length ? (
                                <Map propertyInfo={propertyDetails} />
                            ) : (
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    {Translate.t('PropertyReport.PropertyRpDMapNoLocation')}
                                </div>
                            )}
                        </PropertyMapContainer>
                    </PropertyControlContainerMain>

                </DetailsViewContainer>
            </MainContainer>
            <ServicePackageModal
                id={id || ''}
            />
            <RejectPropertyModal
                id={id || ''}
            />
            <SetVacancyStatusModal
                id={id || ''}
            />
            <UpdatePackagePriceModal
                id={id || ''}
            />
            <SetGoogleDrivePathModal
                id={id || ''}
            />
            <SetMilestonesModal
                currentStep={propertyDetails?.packages?.defectInspection?.stage ?? IPropertyDefectInspectionStage.PurchasedPackage}
                progressLogs={propertyDetails?.packages?.defectInspection?.progressLogs ?? []}
            />
        </>

    );
};

const ItemTitle = styled.h5`
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 16px;
    > li:not(:first-child){
        margin-top: 16px;
    }
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const ButtonInfoMarginContainer = styled.div`
    margin-bottom: 10px;
`;

const ButtonInfoFirstMarginContainer = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;

const AdminControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const CollapsibleContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;

    #propertyImg {
        height: 250px;
        width: 250px;
        margin-right: 20px;
        border-radius: 12px;
    }
`;

const LoadingContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageCarouselContainer = styled.div`
    width: 100%;
    margin-top: 20px;
`;

const DetailsViewContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
`;

const Title = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: capitalize;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const DetailsInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-right: 15px;

    > div {
        padding: 20px;
        box-sizing: border-box;
        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        margin-bottom: 40px;
    }
`;

const BasicDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsInfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const RoomDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PropertyImageGalleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
`;

const PropertyImageGalleryCard = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const PropertyImageGalleryTitle = styled.h5`
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 24px;
`;

const PropertyImageGallery = styled.div`
    display: flex;
    width: 100%;
    height: 340px;
    overflow-x: auto;

    img {
        display: inline-block;
        width: 90%;
        height: 100%;
        object-fit: cover;
    }
`;

const PropertyMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-top: 20px;
`;

const PropertyControlContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const PropertyControlContainerMain = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    padding: 30px;
`;

const ButtonControlContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 8px;

    > button {
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
    }

    > button:not(:last-child){
        margin-bottom: 15px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetDetailsAttempt(state),
    error: Selectors.getPropertyGetDetailsError(state),
    propertyDetails: Selectors.getPropertyPropertyDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyDetails: (id: string) => dispatch(Actions.propertyGetDetailsAttempt({ id })),
    clearPropertyDetails: () => dispatch(Actions.clearPropertyDetails()),
    setServicePackageModalOpen: (state: boolean) => dispatch(Actions.setServicePackageModalOpen(state)),
    setUpdatePackagePriceModalOpen: (state: boolean) => dispatch(Actions.setUpdatePackagePriceModalOpen(state)),
    setGoogleDrivePathModalOpen: (state: boolean) => dispatch(Actions.setGoogleDrivePathModalOpen(state)),
    setMilestonesModalOpen: (state: boolean) => dispatch(Actions.setMilestonesModalOpen(state)),
    setRejectPropertModalOpen: (state: boolean) => dispatch(Actions.setRejectPropertyModalOpen(state)),
    setTenancyStatusPropertModalOpen: (state: boolean) => dispatch(Actions.setVacancyStatusPropertyModalOpen(state)),
    getOwnerDetails: (userId: string) => dispatch(Actions.getOwnerDetailsAttempt(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
