import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import OwnersGateway from 'api/Owners';

import { CreateNewActionPayload } from 'redux/slices/owners/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';

export default function* watchCreateNew(api: OwnersGateway): SagaWatcherReturnType {
    yield takeEvery('owners/ownersCreateNewAttempt', handleCreateNew, api);
}

function* handleCreateNew(api: OwnersGateway, data: CreateNewActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const {
        userInfo,
        accCredential,
        propertyIds,
        createdFromProperty,
    } = data.payload;

    const response = yield* call([api, api.createNew], {
        data: {
            userInfo,
            accCredential,
            propertyIds,
        },
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Congratulations, owner account has been successfully created');

        if (!createdFromProperty) {
            NavActions.navToReportsOwner();
        } else {
            yield put(Actions.setCreateOwnerModal(false));
        }

        if (!response.data) {
            yield put(Actions.ownersCreateNewFailure('Something went wrong. Please try again.'));
        }
    } else {
        yield put(Actions.ownersCreateNewFailure(response.message));

        if (response.message && response.message.toLowerCase() === 'email exist') {
            toast.error('Registration failed! Email has been used');
        } else {
            toast.error('Registration failed! Please try again later');
        }
    }
}
