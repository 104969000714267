import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetLocationPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchLocationProperty(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetLocationAttempt', handleLocation, api);
}

function* handleLocation(api: PropertyGateway, data: SetLocationPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const response = yield* call([api, api.setPropertyLocation], { data: data.payload, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success(Translate.t('Property.Saved'));
        yield put(Actions.propertySetLocationSuccess(response.data));
        yield put(Actions.setPropertyLocationSubmitted(true));

        if (!response.data) {
            yield put(Actions.propertySetLocationFailure(Translate.t('GeneralMessage.SomethingWrong')));
            toast.error(Translate.t('GeneralMessage.SomethingWrong'));
        }
    } else {
        yield put(Actions.propertySetLocationFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.Failed'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
