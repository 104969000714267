import { ViewTransactionsParams } from 'entities/transactions';
import { InitState } from 'redux/slices/ui';

const getSelectedLanguage = (state: InitState): string => state.selectedLanguage || '';
const getSelectedTabView = (state: InitState): string => state.selectedTabView || '';
const getTransactionsFilter = (state: InitState): ViewTransactionsParams => state.transactionsFilter || {};
const getModulesCreateModal = (state: InitState): boolean => state.modulesCreateModal || false;
const getEditTransactionModal = (state: InitState): boolean => state.modals.editTransactionModal || false;
const getEditRecurringTransactionModal = (state: InitState): boolean => state.modals.editRecurringTransaction || false;
const getCreateOwnerModal = (state: InitState): boolean => state.modals.createOwner || false;

export default {
    getSelectedLanguage,
    getSelectedTabView,
    getTransactionsFilter,
    getModulesCreateModal,
    getEditTransactionModal,
    getEditRecurringTransactionModal,
    getCreateOwnerModal,
};
