import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';

import { GatewayResponseStatus } from 'api/types/types';
import PropertyGateway from 'api/Property';
import { GetPropertyUploadUrlActionPayload } from 'redux/slices/property/types';

export default function* watchGetPropertyUploadUrl(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/getPropertyUploadUrlAttempt', handleGetUploadUrl, api);
}

function* handleGetUploadUrl(api: PropertyGateway, data: GetPropertyUploadUrlActionPayload) {
    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const { category, id, name, extension, document } = data.payload;

        const response = yield* call([api, api.getPropertyUploadUrl], { category, id, name, extension, authToken });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.getPropertyUploadUrlFailure(response.message));
            return;
        } if (response.status === GatewayResponseStatus.Success) {
            let fileType = '';

            if (extension === '.pdf') fileType = 'pdf';
            else fileType = 'image';

            yield put(Actions.uploadPropertyImagesAttempt({ preSignedUrl: response.data, document, docType: fileType, roomName: name, category }));
        }
    }
}
