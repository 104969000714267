import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { SetIPackagesDataSortPayload } from 'redux/slices/packages/types';

export default function* watchGetLeaseSaleSort(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesGetILeaseAndSaleDataSortAttempt', handlePackages, api);
}

function* handlePackages(api: PackagesGateway, data: SetIPackagesDataSortPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId, category, sort } = data.payload;

        const response = yield* call([api, api.getPackagesLeaseSaleSort], {
            category,
            propertyId,
            sort,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesGetILeaseAndSaleDataSortFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            yield put(Actions.packagesGetILeaseAndSaleDataSortSuccess(response.data));
        } else {
        // handle error
            yield put(Actions.packagesGetILeaseAndSaleDataSortFailure(response.message));
        }
    }
}
