import { CoBrokeStatusEnum } from '@dm/types';
import { IOption } from 'entities/component';

export const cobrokesStatusDropdownData: IOption[] = [
    {
        id: CoBrokeStatusEnum.Approved,
        description: 'Approved',
    },
    {
        id: CoBrokeStatusEnum.Pending,
        description: 'Pending',
    },
    {
        id: CoBrokeStatusEnum.Rejected,
        description: 'Rejected',
    },
];

export default {

};
