import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';

import { CreatePropertyGroupPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchCreatePropertyGroup(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/createPropertyGroupAttempt', handleCreatePropertyGroup, api);
}

function* handleCreatePropertyGroup(api: PropertyGateway, data: CreatePropertyGroupPayload) {
    const { name } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.createPropertyGroup], {
        name,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('property group created!');
        yield put(Actions.propertyCreatePropertyModal(false));

        if (response.data) {
            yield put(Actions.createPropertyGroupSuccess(response.data));
            NavActions.navToEditPropertyGroup(response.data);
        }
        yield put(Actions.setModulesCreateModal(false));

        if (!response.data) {
            yield put(Actions.createPropertyGroupFailure(Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.createPropertyGroupFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.Failed'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
