import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import { GetIPropertyManagementWithdrawalPayload } from 'redux/slices/packages/types';

export default function* watchGetPropertyManagementWithdrawal(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/getPropertyManagementWithdrawalAttempt', handleGetPropertyManagementWithdrawal, api);
}

function* handleGetPropertyManagementWithdrawal(api: PackagesGateway, data: GetIPropertyManagementWithdrawalPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { status, index, includePreviousOwner, search = '', sort } = data.payload;

        const response = yield* call([api, api.getPropertyManagementWithdrawal], {
            index,
            status,
            includePreviousOwner,
            search,
            sort,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.getPropertyManagementWithdrawalFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }

            yield put(Actions.getPropertyManagementWithdrawalSuccess(response.data));
        } else {
        // handle error
            yield put(Actions.getPropertyManagementWithdrawalFailure(response.message));
        }
    }
}
