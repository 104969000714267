import { GetOwnerDetailsResponse } from '@dm/types';
import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IOwnerAccount, IOwnerInfo, IOwnerPropertyIds, IServerOwnerReport, IGetAllOwner } from 'entities/owners';

export interface CreateNewParams {
    data: {
        userInfo?: IOwnerInfo;
        accCredential: IOwnerAccount;
        propertyIds: IOwnerPropertyIds;
    },
    authToken: string,
}

export interface GetOwnersParams {
    data: IGetAllOwner;
    authToken: string;
}

export interface GetOwnerDetailsParams {
    authToken: string;
    userId: string;
}

export type EditOwnerDetailsParams = {
    authToken: string;
    userId: string;
    userInfo: IOwnerInfo;
}

export abstract class IOwnersGateway extends Gateway {
    abstract createNew(params: CreateNewParams): GatewayResponse<string | null>;

    abstract getOwners(params: GetOwnersParams): GatewayResponse<IServerOwnerReport>;

    abstract getOwnerDetails(params: GetOwnerDetailsParams): GatewayResponse<GetOwnerDetailsResponse>;

    abstract editOwnerDetails(params: EditOwnerDetailsParams): GatewayResponse<string | null>;
}
