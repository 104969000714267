import { Button, Colors, SearchBar } from '@dm/bigfish';
import styled from 'styled-components';

const ModalTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 50px;
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: 75vh;
`;

const Title = styled.h1`
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TransactionPropertyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 10px;
    padding: 10px;

    h2 {
        font-size: medium;
        padding: 10px;
    }
`;
const TPTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledSearchBar = styled(SearchBar)`
    width: 50%;
    height: 30px;;
    padding: 5px 10px;
    margin-top:10px;

    input {
        width: 100%;
        margin-right:20px;
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const PropertiesTable = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 80%;
    margin-top: 10px;
    justify-content: center;
`;

const PropertyListingTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 20px 0 20px 0;
    overflow-y: scroll;

    > div:not(:last-child){
        margin-bottom: 8px;
    }
`;

const PropertyListingHeader = styled.div`
    display: grid;
    grid-template-columns: 250px repeat(3, 1fr);
    align-items: center;
    padding: 16px 9px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    background: ${Colors.white};

    > h4:first-child {
        padding-left: 10px;
    }

    > h4:not(:first-child){
        text-align: center;
    }
`;

const PropertyListingRowButton = styled.button<{checked?: boolean}>`
    display: grid;
    grid-template-columns: 250px repeat(3, 1fr);
    align-items: center;
    width: 100%;
    border: none;
    padding: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;

    color: ${Colors.greyDark};
    background: ${props => (props.checked ? Colors.primaryLight : Colors.primaryLightest)};

    > p {
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
    }
`;

const PropertyListingRowButtonError = styled.button`
    grid-template-columns: 250px repeat(2, 1fr);
    align-items: center;
    width: 100%;
    border: none;
    padding: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;

    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    > p {
        margin: 0px;
        padding: 5px;
        text-transform: capitalize;
    }
`;

const NameContainer = styled.h4`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const PropertyListingsColumnWithImage = styled.div`
    display: flex;
    align-items: center;

    h4 {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        width: 100px;
        margin-right: 16px;
        text-transform: capitalize;

        color: ${Colors.black};
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 12px;
        padding: 5px;
    }
`;
const ExportButton = styled(Button)`
    width: 30%;
    padding: 10px;
    margin: 10px;
`;

export default {
    TransactionPropertyContainer,
    TPTitle,
    ModalTitleContainer,
    ModalContentContainer,
    Title,
    StyledSearchBar,
    LoadingContainer,
    PropertiesTable,
    PropertyListingTable,
    PropertyListingHeader,
    PropertyListingRowButton,
    PropertyListingRowButtonError,
    NameContainer,
    PropertyListingsColumnWithImage,
    ExportButton,
};
