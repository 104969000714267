import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import { BreadCrumb, Colors, LineInput, CardCollapsible, EventTrail, Button, IEventTrail } from '@dm/bigfish';
import { PropertyStatus, READetailsWithPropertyInfo, RecentEvents, IProperty } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import countriesOptions from 'components/options/Countries';

import MainContainer from 'components/MainContainer';
import SelectionInput from 'components/SelectionInput';
import ButtonInfo from 'components/ButtonInfo';
import Modal from 'components/Modal';

import icons from 'assets/icons';
import houseImg from 'assets/images/house1.png';
import avatarImg from 'assets/images/avatar.png';

interface ReaDetailsProps {
    getReaDetailsLoading: boolean;
    reaDetails: READetailsWithPropertyInfo | null;
    getReaDetails: (reaId: string) => void;
    clearReaDetails: () => void;
}

interface ILocation {
    long: number;
    lat: number;
    address1: string;
    address2: string;
    postcode: string;
    city: string;
    state: string;
    country: string;
}

const ReaDetails = (props: ReaDetailsProps): JSX.Element => {
    const { getReaDetailsLoading, reaDetails, getReaDetails, clearReaDetails } = props;

    const { id = '' } = useParams();

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('ReaDetails.ReaDetailsBcDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('ReaDetails.ReaDetailsBcRea'),
                onClick: () => { NavActions.navToReaReport(); },
            },
            {
                label: Translate.t('ReaDetails.ReaDetailsBcReaDetails'),
                onClick: () => { return false; },
            },
        ],
    );

    const [stateFirstName, setStateFirstName] = useState('');
    const [stateLastName, setStateLastName] = useState('');
    const [statePhoneNum, setStatePhoneNum] = useState('');
    const [stateEmail, setStateEmail] = useState('');
    const [stateRenNumber, setStateRenNumber] = useState('');
    const [stateCompanyName, setStateCompanyName] = useState('');

    const [stateAddr1, setStateAddr1] = useState('');
    const [stateAddr2, setStateAddr2] = useState('');
    const [stateCity, setStateCity] = useState('');
    const [stateCountry, setStateCountry] = useState('');
    const [statePostcode, setStatePostcode] = useState('');
    const [stateState, setStateState] = useState('');

    const [recentActivitiesData, setRecentActivitiesData] = useState<IEventTrail[]>([]);
    const [propertyData, setPropertyData] = useState<IProperty[]>([]);

    const [showModal, setShowModal] = useState(false);
    const [modalDocument, setModalDocument] = useState('');

    useEffect(() => {
        return () => {
            clearReaDetails();
        };
    }, []);

    useEffect(() => {
        getReaDetails(id);
    }, [id]);

    useEffect(() => {
        if (reaDetails) {
            const {
                firstName = '',
                lastName = '',
                email = '',
                phoneNumber = '',
                renNumber = '',
                companyName = '',
                address,
                recentEvents,
                properties,
            } = reaDetails;

            setStateFirstName(firstName);
            setStateLastName(lastName);
            setStateEmail(email);
            setStatePhoneNum(phoneNumber);
            setStateRenNumber(renNumber);
            setStateCompanyName(companyName);

            // Handle if address exist
            if (address) {
                const {
                    address1 = '',
                    address2 = '',
                    city = '',
                    country = '',
                    postcode = '',
                    state = '',
                } = address;

                setStateAddr1(address1);
                setStateAddr2(address2);
                setStateCity(city);
                setStateCountry(country);
                setStatePostcode(postcode);
                setStateState(state);
            }

            // Handle if recentEvents exist
            if (recentEvents?.length) {
                const formattedRecentEvent = recentEvents.map(recentEvent => {
                    return {
                        text: recentEvent.message,
                    };
                });

                setRecentActivitiesData(formattedRecentEvent);
            }

            // Handle if property exist
            if (properties?.length) {
                setPropertyData(properties);
            }
        }
    }, [reaDetails]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const checkPropertyStatus = (statusEnum: PropertyStatus) => {
        switch (statusEnum) {
            case PropertyStatus.approved: return 'Approved';
            case PropertyStatus.deactivated: return 'Deactivated';
            case PropertyStatus.draftProperty: return 'Draft';
            case PropertyStatus.pendingApproval: return 'Pending';
            case PropertyStatus.tenanted: return 'Tenanted';
            default: return 'Vacant';
        }
    };

    const createRecentEvent = (packages: any) => {
        const recentEventsData: RecentEvents[] = [];

        if (packages.vacantPossession) {
            recentEventsData.push(...packages.vacantPossession.recentEvents);
        }

        if (packages.defectInspection) {
            recentEventsData.push(...packages.defectInspection.recentEvents);
        }

        if (packages.propertyManagement) {
            recentEventsData.push(...packages.propertyManagement.recentEvents);
        }

        if (packages.leaseSale) {
            recentEventsData.push(...packages.leaseSale.recentEvents);
        }

        if (packages.designRenovation) {
            recentEventsData.push(...packages.designRenovation.recentEvents);
        }

        const parsedRecentEventsData: IEventTrail[] = recentEventsData.map(event => {
            return {
                text: event.message,
                onClick: () => { return false; },
            };
        });

        return parsedRecentEventsData;
    };

    const createPropertyLocation = (location: ILocation) => {
        const { city = '', state = '', country = '' } = location;
        return `${city} ${state} ${country}`;
    };

    const checkDocExtension = (docUrl: string) => {
        if (docUrl.includes('pdf')) {
            return 'document';
        }

        return 'images';
    };

    const onClickViewDocument = () => {
        if (reaDetails) {
            const { renFileUrl } = reaDetails;

            if (renFileUrl.length) {
                setShowModal(true);
                setModalDocument(renFileUrl[0]);
            } else {
                toast('no document found');
            }
        }
    };

    if (getReaDetailsLoading) {
        return (
            <MainContainer>
                <LoadingContainer>
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </LoadingContainer>
            </MainContainer>
        );
    }

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <ReportContainer>
                    <DetailsContainer>
                        <CardInfoContainer>
                            <CardAvatar src={avatarImg} alt='avatar of rea' />
                            <CardDetails>
                                <CardName>
                                    {
                                        (stateFirstName !== '' || stateLastName !== '')
                                            ? `${stateFirstName} ${stateLastName}`
                                            : `${stateEmail}`
                                    }
                                </CardName>
                                <CardPhone>
                                    <SVG src={icons.PhoneIcon} />
                                    <p>0172919213</p>
                                </CardPhone>
                            </CardDetails>
                        </CardInfoContainer>

                        <DetailsInfo>
                            <DetailsHeader>
                                <Title>
                                    {
                                        (stateFirstName !== '' || stateLastName !== '')
                                            ? `${stateFirstName} ${stateLastName}'s Managed Properties`
                                            : Translate.t('ReaDetails.ReaDetailsTitle1')
                                    }
                                </Title>
                                <ViewDocumentBtn label={Translate.t('ReaDetails.ReaDetailsViewDocumentBtn')} onClick={onClickViewDocument} />
                            </DetailsHeader>
                            <DetailsInputContainer>
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelFirstName')}
                                    value={stateFirstName}
                                    onChangeText={(e) => setStateFirstName(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelLastName')}
                                    value={stateLastName}
                                    onChangeText={(e) => setStateLastName(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelEmail')}
                                    value={stateEmail}
                                    onChangeText={(e) => setStateEmail(e.target.value)}
                                    inputError={false}
                                    errorText={Translate.t('CreateNew.CreateNewErrorEmail')}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelMobile')}
                                    value={statePhoneNum}
                                    onChangeText={(e) => setStatePhoneNum(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelRenNumber')}
                                    value={stateRenNumber}
                                    onChangeText={(e) => setStateRenNumber(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabeCompanyName')}
                                    value={stateCompanyName}
                                    onChangeText={(e) => setStateCompanyName(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelCPAddress1')}
                                    value={stateAddr1}
                                    onChangeText={(e) => setStateAddr1(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelCPAddress2')}
                                    value={stateAddr2}
                                    onChangeText={(e) => setStateAddr2(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelCity')}
                                    value={stateCity}
                                    onChangeText={(e) => setStateCity(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelPostcode')}
                                    value={statePostcode}
                                    onChangeText={(e) => setStatePostcode(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('CreateNew.CreateNewLabelState')}
                                    value={stateState}
                                    onChangeText={(e) => setStateState(e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('CreateNew.CreateNewLabelCountry')}
                                    data={countriesOptions}
                                    currentValue={stateCountry}
                                    onChangeSelection={(e) => setStateCountry(e.target.value)}
                                    disabled
                                />
                            </DetailsInputContainer>
                        </DetailsInfo>

                        <PropertyListings>
                            <Title style={{ marginBottom: '50px' }}>
                                {
                                    (stateFirstName !== '' || stateLastName !== '')
                                        ? `${stateFirstName} ${stateLastName}'s Managed Properties`
                                        : Translate.t('ReaDetails.ReaDetailsTitle2')
                                }
                            </Title>

                            {
                                propertyData.map((property) => {
                                    const { _id, name = '', status, packages, location } = property;

                                    // create property location
                                    let propertyLocation = '';
                                    if (location) {
                                        propertyLocation = createPropertyLocation(location);
                                    }

                                    // create recent events
                                    const parsedRecentEvents = createRecentEvent(packages);

                                    return (
                                        <CardCollapsible
                                            title={name}
                                            subtitle={propertyLocation}
                                            chipText={checkPropertyStatus(status)}
                                            style={{ maxWidth: '100%' }}
                                            key={_id}
                                        >
                                            <CollapsibleContentContainer>
                                                <img src={houseImg} alt='' id='propertyImg' />

                                                <div style={{ width: '100%' }}>
                                                    <EventTrail
                                                        title='Recent Events'
                                                        primary
                                                        buttonLabel='Recent Events'
                                                        data={parsedRecentEvents}
                                                        onClickButtonLabel={() => { return false; }}
                                                        emptyText='No Recent Events'
                                                        style={{
                                                            maxWidth: '100%',
                                                            marginBottom: '20px',
                                                            minHeight: '200px',
                                                        }}
                                                    />

                                                    <div>
                                                        <ButtonInfo
                                                            desc='Vacant Posession'
                                                            onclick={() => { return false; }}
                                                            style={{
                                                                marginBottom: '10px',
                                                            }}
                                                        />
                                                        <ButtonInfo
                                                            desc='Design Inspection'
                                                            onclick={() => { return false; }}
                                                            style={{
                                                                marginBottom: '10px',
                                                            }}
                                                        />
                                                        <ButtonInfo
                                                            desc='Design And Renovation'
                                                            onclick={() => { return false; }}
                                                            style={{
                                                                marginBottom: '10px',
                                                            }}
                                                        />
                                                        <ButtonInfo
                                                            desc='Lease And Sale'
                                                            onclick={() => { return false; }}
                                                            style={{
                                                                marginBottom: '10px',
                                                            }}
                                                        />
                                                        <ButtonInfo
                                                            desc='Property Management'
                                                            onclick={() => { return false; }}
                                                        />
                                                    </div>
                                                </div>
                                            </CollapsibleContentContainer>
                                        </CardCollapsible>
                                    );
                                })
                            }

                            {
                                !propertyData.length && (
                                    <NoPropertyContainer>
                                        {Translate.t('ReaDetails.ReaDetailsNoProperty')}
                                    </NoPropertyContainer>
                                )
                            }

                        </PropertyListings>
                    </DetailsContainer>
                    <EventsContainer>
                        <EventTrail
                            primary
                            title={Translate.t('RecentEvent.RecentEventRecentActivities')}
                            buttonLabel={Translate.t('RecentEvent.RecentEventViewAll')}
                            emptyText={Translate.t('RecentEvent.RecentEventNoRecent')}
                            data={recentActivitiesData}
                            onClickButtonLabel={() => { return false; }}
                        />
                    </EventsContainer>
                </ReportContainer>

                <Modal
                    show={showModal}
                    closeCursor
                    onClickClose={() => {
                        setShowModal(false);
                    }}
                >
                    <ModalContent>
                        {(checkDocExtension(modalDocument) === 'document') ? (
                            <StyledIframe src={modalDocument} />
                        ) : (
                            <StyledImage src={modalDocument} />
                        )}
                    </ModalContent>
                </Modal>
            </MainContainer>
        </>
    );
};

const ReportContainer = styled.div`
    display: grid;
    grid-template-columns: 60% 1fr;
    padding: 40px 20px;
    gap: 16px;
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;

    color: #000;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardInfoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 36px;
    color: white;
    background-color: ${Colors.primary};
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 40px;
`;

const CardDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const CardAvatar = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 16px;
    object-fit: contain;
`;

const CardName = styled.p`
    font-weight: 700;
    font-size: 24px;
    line-height: 1.17;
`;

const CardPhone = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.17;

    SVG {
        width: 9px;
        height: 9px;
    }
`;

const ViewDocumentBtn = styled(Button)`
    font-size: 14px;
    line-height: 1.8;
    padding: 8px; 
`;

const DetailsInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    margin-bottom: 60px;
`;

const DetailsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: ${Colors.primaryLightest};
    padding: 18px;
`;

const DetailsInputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    align-items: flex-end;

    margin: 10px 20px 20px 20px;
`;

const EventsContainer = styled.div`
    display: block;
`;

const PropertyListings = styled.div`
    display: flex;
    flex-direction: column;

    gap: 10px;
`;

const CollapsibleContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;

    img {
        height: 250px;
        width: 250px;

        margin-right: 20px;

        border-radius: 12px;
    }
`;

const NoPropertyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    
    color: ${Colors.greyDark};

    height: 100px;
    width: 100%;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const LoadingContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
    width: 100%;
    height: 800px;

    padding: 40px 10px 10px 10px;
    
    @media (max-width: 1624px) {
        height: 600px;
    }
`;

const StyledIframe = styled.iframe`
    display: block;

    width: 100%;
    height: 100%;
`;

const StyledImage = styled.img`
    display: block;

    width: 100%;
    height: 100%;

    object-fit: cover;
`;

const mapStateToProps = (state: RootState) => ({
    getReaDetailsLoading: Selectors.getReaGetReaDetailsAttempting(state),
    getReaDetailsError: Selectors.getReaGetReaDetailsError(state),
    reaDetails: Selectors.getReaReaDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getReaDetails: (reaId: string) =>
        dispatch(Actions.getReaDetailsAttempt(reaId)),
    clearReaDetails: () => dispatch(Actions.clearReaDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReaDetails);
