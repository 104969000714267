import React, { useState, useEffect, useRef, SyntheticEvent, RefObject, createRef } from 'react';
import styled from 'styled-components';
import Translate from 'lib/translate';
import { RoundInput, Button, Colors, CardCollapsible } from '@dm/bigfish';
import { Oval } from 'react-loader-spinner';
import { Category } from '@dm/types';

import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { IPropertyGroupDetails } from 'entities/property';
import Icons from '../../../assets/icons';
import NoImageAvailable from '../../../assets/images/no_image_available.png';

import ImageModal from './ImageModal';

interface ImagesLoadProps {
    propertyGroupId: string;
    isImageUploadComplete: boolean;
    propertyImages: IPropertyGroupDetails;
    deleteImageAttempt: boolean;
    imageDeleteSuccess: string;
    imageDeleteFailed: string;
    getUrlToUploadImage: boolean;
    ImageUploading: boolean;
    propertyImagesCollapsibleStatus: boolean;
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
    setDeleteImages: (category: number, id: string, name: string, fileName: string, fileUrl: string) => void;
    resetDeleteFailed: (state: string) => void;
    setChiptextStatus: (state: boolean) => void;
    setOpenDescriptionCollapsible: (state: boolean) => void;
    setPropertyImagesSubmittedOrNot: (state: boolean) => void;
    getPropertyGroupDetails: (propertyGroupId: string) => void;

    propertyImagesUploaded: File[];
    propertyImagesDeleted: string[];
}

export const ImagesLoad = (props: ImagesLoadProps): JSX.Element => {
    const {
        propertyGroupId,
        isImageUploadComplete,
        propertyImages,
        deleteImageAttempt,
        imageDeleteFailed,
        imageDeleteSuccess,
        getUrlToUploadImage,
        ImageUploading,
        propertyImagesCollapsibleStatus,
        getUploadUrl,
        setDeleteImages,
        resetDeleteFailed,
        setChiptextStatus,
        setOpenDescriptionCollapsible,
        setPropertyImagesSubmittedOrNot,
        getPropertyGroupDetails,
        propertyImagesUploaded,
        propertyImagesDeleted,
    } = props;

    const [roomNameToBeSet, setRoomNameToBeSet] = useState('');
    const [modalOpener, setModalOpener] = useState(false);
    const [addImageStatus, setAddImageStatus] = useState(true);
    const [reference, setReference] = useState<RefObject<HTMLInputElement>[]>([]);

    useEffect(() => {
        if (isImageUploadComplete === true) {
            setModalOpener(false);
        }
    }, [isImageUploadComplete]);

    useEffect(() => {
        setChiptextStatus(false);
        if ((propertyImages?.data.images !== undefined) && (propertyImages?.data.images.length > 0)) {
            setChiptextStatus(true);
            setPropertyImagesSubmittedOrNot(true);

            setReference(propertyImages.data.images.map(() => createRef<HTMLInputElement>()));
        }
    }, [propertyImages]);

    const referenceNewRoom = useRef<HTMLInputElement>(null);

    const onClickHandler = (index: number) => {
        if (reference[index] && reference[index].current) {
            reference[index].current!.value = '';
            reference[index].current!.click();
        }
    };

    const onClickHandlerNewRoom = () => {
        if (referenceNewRoom.current) {
            referenceNewRoom.current.value = '';
            referenceNewRoom.current.click();
        }
    };

    const fileSelectHandler = async (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        if (target.files) {
            let documentExtension = '';

            if (target.files) {
                const doc = target.files[0];

                const { type } = doc;

                switch (type) {
                    case 'image/jpg': documentExtension = '.jpg'; break;
                    case 'image/jpeg': documentExtension = '.jpeg'; break;
                    case 'image/png': documentExtension = '.png'; break;
                    default:
                }
                getUploadUrl(Category.PropertyGroup, propertyGroupId, roomNameToBeSet, documentExtension, doc);
            }
        }
    };

    const fileSelectHandlerWithName = async (event: SyntheticEvent, name: string) => {
        const target = event.target as HTMLInputElement;
        if (target.files) {
            let documentExtension = '';

            if (target.files) {
                const doc = target.files[0];

                const { type } = doc;

                switch (type) {
                    case 'application/pdf': documentExtension = '.pdf'; break;
                    case 'image/jpg': documentExtension = '.jpg'; break;
                    case 'image/jpeg': documentExtension = '.jpeg'; break;
                    case 'image/png': documentExtension = '.png'; break;
                    default:
                }
                getUploadUrl(Category.PropertyGroup, propertyGroupId, name, documentExtension, doc);
            }
        }
    };

    const onOpenCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.openCollapsible();
        }
    };

    const ownerRef = useRef<any>(null);

    const onCloseCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.closeCollapsible();
        }
    };

    const roomNameToBeSetfunc = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setRoomNameToBeSet(e.target.value);
        setAddImageStatus(false);
        if (e.target.value === '') {
            setAddImageStatus(true);
        }
    };

    const onSubmitRemoveHandler = (roomName: string, fileUrl: string, fileName?: string) => {
        if (fileName) {
            setDeleteImages(14, propertyGroupId, roomName, fileName, fileUrl);
        }
    };

    const onSubmitConfirmHandler = () => {
        onCloseCollapsibleOwner();
        setOpenDescriptionCollapsible(true);
    };

    const renderImages = (url: string[], roomName: string) => {
        return url.map((item: string, index: number) => {
            const ImageServerName = item.split('/').pop();
            const checkBlob = item.substring(0, 4) === 'blob';
            if (propertyImagesDeleted.some(imageUrl => imageUrl === item)) {
                return null;
            }
            return (
                <div
                    key={item}
                    style={{ textDecoration: 'none', border: 'none', position: 'relative' }}
                >
                    <ImageContainer
                        src={item}
                        alt=''
                        id='myImg'
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = NoImageAvailable;
                        }}
                        onClick={() => undefined}
                    />
                    <div style={{ position: 'absolute', top: '10px', right: '20px' }}>
                        <Button
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: checkBlob ? '100px' : '50px',
                                maxHeight: '27px',
                                marginTop: '5px',
                                backgroundColor: checkBlob ? Colors.greyLight : Colors.warning,
                                cursor: checkBlob ? 'not-allowed' : 'pointer',
                            }}
                            size='sm'
                            label={checkBlob ? 'uploading' : 'delete'}
                            disabled={checkBlob}
                            onClick={() => [setRoomNameToBeSet(roomName), onSubmitRemoveHandler(roomName, item, ImageServerName)]}
                        />
                    </div>
                </div>
            );
        });
    };

    const renderRoom = () => {
        if (propertyImages.data.images) {
            return propertyImages.data.images.map((data, index) => {
                const { name, url } = data;

                return (
                    <div
                        key={name}
                        style={{
                            height: '350px',
                            display: 'flex',
                            flexDirection: 'row',
                            paddingBottom: '20px',
                        }}
                    >
                        <div
                            style={{
                                borderRadius: '20px',
                                width: '75%',
                                height: '100%',
                                backgroundColor: '#FFCFA6',
                                position: 'relative',
                                zIndex: '5',
                            }}
                        >
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '25px',
                                }}
                            >
                                {name}
                            </p>
                            <ImagesContainer>
                                <FlexRowContainer style={{ maxHeight: '300px' }}>
                                    {renderImages(url, name)}
                                </FlexRowContainer>
                            </ImagesContainer>
                        </div>
                        <div style={{ width: '25%', height: '100%', position: 'relative' }}>
                            <AddImageBox onClick={() => [
                                onClickHandler(index),
                            ]}
                            >
                                <span style={{ color: '#1b1b1b' }}>&#10010;</span>
                                <p>Add Image</p>
                                <input
                                    onChange={(e: SyntheticEvent) => fileSelectHandlerWithName(e, name)}
                                    accept='.jpg,.jpeg,.png,application/pdf,'
                                    type='file'
                                    hidden
                                    ref={reference[index]}
                                />
                            </AddImageBox>
                        </div>
                    </div>
                );
            });
        }

        return <p style={{ fontStyle: 'italic' }}>Create room to add images!</p>;
    };

    const renderContent = () => {
        if (
            getUrlToUploadImage
            || ImageUploading
            || deleteImageAttempt
        ) {
            return (
                <Loading>
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </Loading>
            );
        }

        return (
            <MainContainer>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {renderRoom()}
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <NewImageAdditionContainer onClick={() => setModalOpener(!modalOpener)}>
                        <SpanText>&#10010;</SpanText>
                        <SpanH4Text>Add New Room</SpanH4Text>
                    </NewImageAdditionContainer>
                    <ButtonContainer>
                        <ConfirmPropertyButton label={Translate.t('Property.PropertyConfirmButton')} type='button' onClick={onSubmitConfirmHandler} />
                    </ButtonContainer>
                </div>
            </MainContainer>
        );
    };

    return (
        <div style={{ marginBottom: '20px' }}>

            <CardCollapsible
                ref={ownerRef}
                preText={Translate.t('CreateNew.CreateNewStep4')}
                title={Translate.t('Property.PropertyImages.CollapsibleTitle')}
                icon={isImageUploadComplete ? Icons.VerifiedIcon : Icons.CautionIcon}
                focused={isImageUploadComplete}
            >
                {renderContent()}
            </CardCollapsible>

            {/* {modal will open to upload new property image} */}
            <ImageModal
                show={modalOpener}
                closeCursor
                width='30%'
                height='20%'
                onClickClose={() => {
                    setModalOpener(false);
                }}
            >
                <CenterModalContainer>
                    {(getUrlToUploadImage || ImageUploading) && (
                        <LoadingModal>
                            <Oval
                                height={50}
                                width={50}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        </LoadingModal>
                    )}
                </CenterModalContainer>
                <div>Create Room</div>
                {(!((getUrlToUploadImage || ImageUploading))) && (!deleteImageAttempt) && (
                    <FlexColumnContainer>
                        <FlexRowContainer style={{ alignItems: 'center', maxWidth: '100%', gap: '10px' }}>
                            <p style={{ paddingTop: '10px' }}>Provide Room Name: </p>
                            <StyledRoundInput value={roomNameToBeSet} placeholder='eg. Living room' onChangeText={roomNameToBeSetfunc} />
                        </FlexRowContainer>
                        <NewImageAdditionContainerModal onClick={() => onClickHandlerNewRoom()} checked={addImageStatus}>
                            <SpanText>&#10010;</SpanText>
                            <p>Add Image</p>
                            <input
                                onChange={(e: SyntheticEvent) => fileSelectHandler(e)}
                                accept='.jpg,.jpeg,.png,application/pdf,'
                                type='file'
                                hidden
                                ref={referenceNewRoom}
                                disabled={addImageStatus}
                            />
                        </NewImageAdditionContainerModal>
                        <p style={{ fontSize: '13px', fontStyle: ' italic', textAlign: 'center', paddingTop: '10px' }}>Please enter room name to add image</p>
                    </FlexColumnContainer>
                )}

            </ImageModal>

        </div>

    );
};

const FlexRowContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const FlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledHref = styled.a`
    
`;

const CenterModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
`;

const SpanText = styled.span`
    color: #787878;
`;

const SpanH4Text = styled.h4`
    color: #787878;
`;

const NewImageAdditionContainerModal = styled.div<{ checked: boolean }>`
    display: flex;
    margin-top: 5px;
    justify-content: center;
    padding: 0 4px 0 4px;
    align-items: center;
    max-height: 35px;
    height: 35px;
    width: 100%;
    background-color: ${(props) => (props.checked ? Colors.grey : Colors.primaryLight)};
    border-radius: 12px;
    cursor:  ${(props) => (props.checked ? 'not-allowed' : 'pointer')};
`;

const NewImageAdditionContainer = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 0 4px 0 4px;
    align-items: center;
    max-height: 35px;
    height: 35px;
    width: 200px;
    background-color: rgb(255, 242, 231);
    border-radius: 12px;
    cursor: pointer;
`;

const Loading = styled.div`
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const LoadingModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AddImageBox = styled.div`
    display: flex;
    position: absolute;
    left: -170px;
    width: 110%;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: end;
    height: 180px;
    background-color: ${Colors.primaryLightest};
    border-radius: 12px;
    margin-top: 50%;

    span{
        position: absolute;
        right: 15px;
        top: 50%;
    }

    p{
        position: absolute;
        top: 50%;
    }

    &:hover {
        transition: all .2s ease-in-out; 
        transform: translateX(130px); 
    }
    cursor: pointer;
`;

const ImageContainer = styled.img`
    display: flex;
    flex-direction: row;
    height: 240px;
    width: 240px;
    margin-right: 10px;
    position: relative;
    border-style: double;
    border-radius: 12px;
    margin-left: 10px;
`;

const ImagesContainer = styled.div`
    display: flex;
    flex-direction:row ;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow-y: auto;
    overflow-x: auto;
    padding: 60px 0 5px 10px;

    ::-webkit-scrollbar {
    height: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.primary};
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${Colors.primary};
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
`;

const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-right: 40px;
    max-width: 900px;
`;

const StyledRoundInput = styled(RoundInput)`
    display: flex;
    width: 150px;
    height:40px;
    margin: 0px;
    padding: 0px;
    color: #696969;
    border-radius: 12px;
    align-self: center;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ConfirmPropertyButton = styled(Button)`
    align-items: flex-start;
    padding: 8px;
    position: static;
    width: 66px; 
    height: 32px;
    left: 0px;
    border-radius: 8px;
    background-color: #F2B47E;
    color: white;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const mapStateToProps = (state: RootState) => ({
    isImageUploadComplete: Selectors.setPropertysetIsImageUploadComplete(state),
    propertyImages: Selectors.getPropertyPropertyGroupDetails(state),
    deleteImageAttempt: Selectors.setPropertySetDeleteImageAttempting(state),
    imageDeleteFailed: Selectors.setPropertySetDeleteImageError(state),
    imageDeleteSuccess: Selectors.setPropertySetDeleteImage(state),
    getUrlToUploadImage: Selectors.userGetPropertyUploadUrlAttempting(state),
    ImageUploading: Selectors.userUploadPropertyImageAttempting(state),
    propertyImagesCollapsibleStatus: Selectors.setIsPropertyOpenImagesCollapsible(state),
    propertyImagesUploaded: Selectors.getPropertyPropertyImagesUploadedFile(state),
    propertyImagesDeleted: Selectors.getPropertyPropertyImagesDeletedString(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => dispatch(Actions.getPropertyUploadUrlAttempt({ category, id, name, extension, document })),
    setDeleteImages: (category: number, id: string, name: string, fileName: string, fileUrl: string) => dispatch(Actions.propertySetDeleteImageAttempt({ category, id, name, fileName, fileUrl })),
    getPropertyGroupDetails: (propertyGroupId: string) => dispatch(Actions.getPropertyGroupDetailsAttempt({ propertyGroupId })),
    setChiptextStatus: (state: boolean) => dispatch(Actions.setPropertyImageUploadComplete(state)),
    setOpenDescriptionCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenImagesCollapsible(state)),
    resetDeleteFailed: (state: string) => dispatch(Actions.propertySetDeleteImageFailure(state)),
    setPropertyImagesSubmittedOrNot: (state: boolean) => dispatch(Actions.setPropertyImagesSubmitted(state)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImagesLoad);
