import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors } from '@dm/bigfish';
import { IProperty, PropertyStatus } from '@dm/types';

import { IServicePackagePricing } from 'entities/property';

import Modal from 'components/Modal';
import { toast } from 'react-toastify';
import Translate from 'lib/translate';

interface ServicePackageModalProps {
    id: string;
    isServiceModalOpen: boolean;
    setServiceLoading: boolean;
    setServiceError: string;
    loading: boolean;
    error: string;
    propertyDetails: IProperty | null;
    setServicePackageModalOpen: (state: boolean) => void;
    setServicePackagePricing: (data: IServicePackagePricing) => void;
}

const ServicePackageModal: FunctionComponent<ServicePackageModalProps> = (props: ServicePackageModalProps) => {
    const { id, isServiceModalOpen, setServiceLoading, setServiceError, loading, error, propertyDetails, setServicePackageModalOpen, setServicePackagePricing } = props;
    const [proceed, setProceed] = useState(false);

    const [vacantPossession, setVacantPossession] = useState(0);
    const [defectInspection, setDefectInspection] = useState(0);
    const [designRenovation, setDesignRenovation] = useState(0);
    const [propertyManagement, setPropertyManagement] = useState(0);
    const [vacantManagement, setvacantManagement] = useState(0);

    const [leaseSale, setLeaseSale] = useState(0);

    const cancelClickHandler = () => {
        setServicePackageModalOpen(false);
        setProceed(false);
    };

    const confirmClickHandler = () => {
        const dataToSubmit: IServicePackagePricing = {
            propertyId: id,
            status: PropertyStatus.approved,
            vacantPossession,
            defectInspection,
            designRenovation,
            propertyManagement,
            vacantManagement,
            leaseSale,
        };

        if (!proceed) {
            setProceed(true);
            return;
        }

        if (Number.isNaN(vacantPossession)
        || Number.isNaN(defectInspection)
        || Number.isNaN(designRenovation)
        || Number.isNaN(propertyManagement)
        || Number.isNaN(vacantManagement)
        || Number.isNaN(leaseSale)
        ) {
            toast.error('Input must be a number');
            return;
        }

        if (dataToSubmit.vacantPossession === 0) delete dataToSubmit.vacantPossession;
        if (dataToSubmit.defectInspection === 0) delete dataToSubmit.defectInspection;
        if (dataToSubmit.designRenovation === 0) delete dataToSubmit.designRenovation;
        if (dataToSubmit.propertyManagement === 0) delete dataToSubmit.propertyManagement;
        if (dataToSubmit.vacantManagement === 0) delete dataToSubmit.vacantManagement;
        if (dataToSubmit.leaseSale === 0) delete dataToSubmit.leaseSale;

        if ((defectInspection !== 0) && ((vacantPossession === 0)) && (propertyDetails?.packages?.vacantPossession?.status !== 3)) {
            toast.error(Translate.t('PropertyRpDetails.PropertyRpMessageBuyDefectInspection'));
            return;
        }

        if ((defectInspection + vacantPossession + designRenovation + propertyManagement + vacantManagement + leaseSale) < 10) {
            toast.error(Translate.t('PropertyRpDetails.PropertyRpMessageTotalMinimum'));
            return;
        }

        setServicePackagePricing(dataToSubmit);
    };

    const renderModalHeader = () => {
        if (proceed) {
            return (
                'Set Service Package Pricing'
            );
        }

        return (
            'Service Package Pricing'
        );
    };

    const renderModalBody = () => {
        if (setServiceLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Oval
                        height={150}
                        width={150}
                        color={Colors.primary}
                        secondaryColor={Colors.primaryLight}
                    />
                </div>
            );
        }

        if (setServiceError) {
            return (
                <div>
                    {setServiceError}
                </div>
            );
        }

        if (!proceed) {
            return (
                <div>
                    By approving this property, the service package pricing needs to be set up.
                </div>
            );
        }

        return (
            <>
                <StyledLabel>
                    Vacant Possession
                </StyledLabel>

                {Number.isNaN(vacantPossession) && (
                    <StyledError>
                        Pricing must be a number
                    </StyledError>
                )}

                <StyledInput
                    invalid={Number.isNaN(vacantPossession)}
                    onChange={(e) => setVacantPossession(Number(e.target.value))}
                />

                <StyledLabel>
                    Defect Inspection
                </StyledLabel>

                {Number.isNaN(defectInspection) && (
                    <StyledError>
                        Pricing must be a number
                    </StyledError>
                )}

                <StyledInput
                    invalid={Number.isNaN(defectInspection)}
                    onChange={(e) => setDefectInspection(Number(e.target.value))}
                />

                <StyledLabel>
                    Design & Renovation
                </StyledLabel>

                {Number.isNaN(designRenovation) && (
                    <StyledError>
                        Pricing must be a number
                    </StyledError>
                )}

                <StyledInput
                    invalid={Number.isNaN(designRenovation)}
                    onChange={(e) => setDesignRenovation(Number(e.target.value))}
                />

                <StyledLabel>
                    Property Management
                </StyledLabel>

                {Number.isNaN(propertyManagement) && (
                    <StyledError>
                        Pricing must be a number
                    </StyledError>
                )}

                <StyledInput
                    invalid={Number.isNaN(propertyManagement)}
                    onChange={(e) => setPropertyManagement(Number(e.target.value))}
                />

                <StyledLabel>
                    Vacant Management
                </StyledLabel>

                {Number.isNaN(vacantManagement) && (
                    <StyledError>
                        Pricing must be a number
                    </StyledError>
                )}

                <StyledInput
                    invalid={Number.isNaN(vacantManagement)}
                    onChange={(e) => setvacantManagement(Number(e.target.value))}
                />
            </>
        );
    };

    const renderModalFooter = () => {
        if (setServiceError) {
            return (
                <Button
                    label='Proceed'
                    onClick={() => window.location.reload()}
                />
            );
        }

        return (
            <>
                <Button
                    label='Cancel'
                    style={{
                        marginRight: '10px',
                        backgroundColor: Colors.white,
                        color: Colors.primary,
                        border: `1px solid ${Colors.primary}`,
                    }}
                    onClick={cancelClickHandler}
                    disabled={setServiceLoading}
                />
                <Button
                    label='Proceed'
                    onClick={confirmClickHandler}
                    disabled={setServiceLoading}
                />
            </>
        );
    };

    return (
        <Modal
            show={isServiceModalOpen}
            width={proceed ? '30%' : '40%'}
            height='fit-content'
        >
            <ModalHeader>
                {renderModalHeader()}
            </ModalHeader>

            <ModalBody>
                {renderModalBody()}
            </ModalBody>

            <ModalFooter
                proceed={proceed}
            >
                {renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ModalBody = styled.div`
    margin-bottom: 20px;
`;

interface ModalFooterProps {
    proceed: boolean;
}

const ModalFooter = styled.div<ModalFooterProps>`
    display: flex;
    justify-content: ${props => (props.proceed ? 'flex-start' : 'flex-end')}
`;

const StyledLabel = styled.div`
    font-size: 16px;
    color: black;
    margin-bottom: 5px;
`;

const StyledError = styled.div`
    font-size: 16px;
    color: red;
    margin-bottom: 5px;
`;

const StyledInput = styled.input<{invalid: boolean, lastChild?: boolean}>`
    border: ${props => (props.invalid ? '1px solid red' : 'none')};
    outline: ${props => (props.invalid ? '1px solid red' : 'none')};
    background-color: #F6F6F6;
    width: 80%;
    border-radius: 12px;
    height: 30px;
    padding: 10px;

    margin-bottom: ${props => (props.lastChild ? '0px' : '20px')};

    &:focus {
        outline: ${props => (props.invalid ? '1px solid red' : '2px solid grey')};
    }

    &:active {
        outline: ${props => (props.invalid ? '1px solid red' : '1px solid grey')};
    }
`;

const mapStateToProps = (state: RootState) => ({
    setServiceLoading: Selectors.getPropertySetServicePackageAttempting(state),
    setServiceError: Selectors.getPropertySetServicePackageError(state),
    isServiceModalOpen: Selectors.getPropertyGetServiceModalOpen(state),

    loading: Selectors.getPropertyGetDetailsAttempt(state),
    error: Selectors.getPropertyGetDetailsError(state),
    propertyDetails: Selectors.getPropertyPropertyDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setServicePackageModalOpen: (state: boolean) => dispatch(Actions.setServicePackageModalOpen(state)),
    setServicePackagePricing: (data: IServicePackagePricing) => dispatch(Actions.setServicePackageAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicePackageModal);
