export default [
    {
        id: 0,
        description: 'Chinese',
    },
    {
        id: 1,
        description: 'Indian',
    },
    {
        id: 2,
        description: 'Malay',
    },
    {
        id: 3,
        description: 'Others',
    },
];
