export const PropertyTypeOptions = [
    {
        id: 0,
        description: 'Apartment',
    },
    {
        id: 1,
        description: 'Landed',
    },
];

export const PropertyTitleOptions = [
    {
        id: 0,
        description: 'Strata',
    },
    {
        id: 1,
        description: 'Master',
    },
    {
        id: 2,
        description: 'Individual',
    },
];

export const LandTitleOptions = [
    {
        id: 0,
        description: 'Residential',
    },
    {
        id: 1,
        description: 'Commercial',
    },
];

export const TenureTypeOptions = [
    {
        id: 0,
        description: 'FreeHold',
    },
    {
        id: 1,
        description: 'LeaseHold',
    },
];

export const FurnishingTypeOptions = [
    {
        id: 0,
        description: 'Fully',
    },
    {
        id: 1,
        description: 'Partial',
    },
    {
        id: 2,
        description: 'None',
    },
];

export const PropertyStatusOptions = [
    {
        id: 0,
        description: 'pendingApproval',
    },
    {
        id: 1,
        description: 'vacant',
    },
    {
        id: 2,
        description: 'tenanted',
    },
    {
        id: 3,
        description: 'deactivated',
    },
    {
        id: 4,
        description: 'draftProperty',
    },
    {
        id: 5,
        description: 'approved',
    },
];
