import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import Translate from 'lib/translate';

import { SetGoogleDrivePathPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchSetGoogleDrivePath(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/setGoogleDrivePathAttempt', handleSetGoogleDrivePath, api);
}

function* handleSetGoogleDrivePath(api: PropertyGateway, data: SetGoogleDrivePathPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { propertyId, packageType, googleDrivePath } = data.payload;
    const response = yield* call([api, api.setGoogleDrivePath], {
        authToken,
        propertyId,
        packageType,
        googleDrivePath,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.setGoogleDrivePathError(Translate.t('GeneralMessage.SomethingWrong')));
            return;
        }
        yield put(Actions.setGoogleDrivePathSuccess(response.data));
        yield put(Actions.getPropertPropertyDetailsAttempt({ id: propertyId }));
        toast.success('google drive path updated!');
    } else {
        // handle error
        yield put(Actions.setGoogleDrivePathError(response.message));
        toast.error(Translate.t('GeneralMessage.SomethingWrong'));
    }
}
