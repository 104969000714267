export default [
    {
        id: 1,
        description: 'Malaysia',
        number: 11,
        iso: 'MAL',
    },
    {
        id: 1.2,
        description: 'Afghanistan',
        number: 11,
        iso: 'AFG',
    },
    {
        id: 2,
        description: 'Albania',
        number: 11,
        iso: 'ALB',
    },
    {
        id: 3,
        description: 'Algeria',
        number: 11,
        iso: 'DZA',
    },
    {
        id: 4,
        description: 'American Samoa',
        number: 11,
        iso: 'ASM',
    },
    {
        id: 5,
        description: 'Andorra',
        number: 11,
        iso: 'AND',
    },
    {
        id: 6,
        description: 'Angola',
        number: 11,
        iso: 'AGO',
    },
    {
        id: 7,
        description: 'Antigua And Barbuda',
        number: 11,
        iso: 'ATG',
    },
    {
        id: 8,
        description: 'Azerbaijan',
        number: 11,
        iso: 'AZE',
    },
    {
        id: 9,
        description: 'Argentina',
        number: 11,
        iso: 'ARG',
    },
    {
        id: 10,
        description: 'Australia',
        number: 11,
        iso: 'AUS',
    },
    {
        id: 11,
        description: 'Austria',
        number: 11,
        iso: 'AUT',
    },
    {
        id: 12,
        description: 'Bahamas',
        number: 11,
        iso: 'BHS',
    },
    {
        id: 13,
        description: 'Bahrain',
        number: 11,
        iso: 'BHR',
    },
    {
        id: 14,
        description: 'Bangladesh',
        number: 11,
        iso: 'BGD',
    },
    {
        id: 15,
        description: 'Armenia',
        number: 11,
        iso: 'ARM',
    },
    {
        id: 16,
        description: 'Barbados',
        number: 11,
        iso: 'BRB',
    },
    {
        id: 17,
        description: 'Belgium',
        number: 11,
        iso: 'BEL',
    },
    {
        id: 18,
        description: 'Bermuda',
        number: 11,
        iso: 'BMU',
    },
    {
        id: 19,
        description: 'Bhutan',
        number: 11,
        iso: 'BTN',
    },
    {
        id: 20,
        description: 'Bolivia',
        number: 11,
        iso: 'BOL',
    },
    {
        id: 21,
        description: 'Bosnia And Herzegovina',
        number: 11,
        iso: 'BIH',
    },
    {
        id: 22,
        description: 'Botswana',
        number: 11,
        iso: 'BWA',
    },
    {
        id: 23,
        description: 'Brazil',
        number: 11,
        iso: 'BRA',
    },
    {
        id: 24,
        description: 'Belize',
        number: 11,
        iso: 'BLZ',
    },
    {
        id: 25,
        description: 'Solomon Islands',
        number: 11,
        iso: 'SLB',
    },
    {
        id: 26,
        description: 'Virgin Islands, British',
        number: 11,
        iso: 'VGB',
    },
    {
        id: 27,
        description: 'Brunei Darussalam',
        number: 11,
        iso: 'BRN',
    },
    {
        id: 28,
        description: 'Bulgaria',
        number: 11,
        iso: 'BGR',
    },
    {
        id: 29,
        description: 'Myanmar (Formerly Burma)',
        number: 9,
        iso: 'MMR',
    },
    {
        id: 30,
        description: 'Burundi',
        number: 11,
        iso: 'BDI',
    },
    {
        id: 31,
        description: 'Belarus',
        number: 11,
        iso: 'BLR',
    },
    {
        id: 32,
        description: 'Cambodia',
        number: 11,
        iso: 'KHM',
    },
    {
        id: 33,
        description: 'Cameroon',
        number: 11,
        iso: 'CMR',
    },
    {
        id: 34,
        description: 'Canada',
        number: 11,
        iso: 'CAN',
    },
    {
        id: 35,
        description: 'Cape Verde',
        number: 11,
        iso: 'CPV',
    },
    {
        id: 36,
        description: 'Cayman Islands',
        number: 11,
        iso: 'CYM',
    },
    {
        id: 37,
        description: 'Central African Republic',
        number: 11,
        iso: 'CAF',
    },
    {
        id: 38,
        description: 'Sri Lanka (Formerly Ceylon)',
        number: 11,
        iso: 'LKA',
    },
    {
        id: 39,
        description: 'Chad (Tchad)',
        number: 11,
        iso: 'TCD',
    },
    {
        id: 40,
        description: 'Chile',
        number: 11,
        iso: 'CHL',
    },
    {
        id: 41,
        description: 'China',
        number: 5,
        iso: 'CHN',
    },
    {
        id: 42,
        description: 'Taiwan (Chinese Taipei For Ioc)',
        number: 11,
        iso: 'TWN',
    },
    {
        id: 43,
        description: 'Colombia',
        number: 11,
        iso: 'COL',
    },
    {
        id: 44,
        description: 'Comoros',
        number: 11,
        iso: 'COM',
    },
    {
        id: 45,
        description: 'Mayotte',
        number: 11,
        iso: 'MYT',
    },
    {
        id: 46,
        description: 'Congo, Republic Of',
        number: 11,
        iso: 'COG',
    },
    {
        id: 47,
        description: 'Congo, The Democratic Republic Of The (Formerly Zaire)',
        number: 11,
        iso: 'COD',
    },
    {
        id: 48,
        description: 'Cook Islands',
        number: 11,
        iso: 'COK',
    },
    {
        id: 49,
        description: 'Costa Rica',
        number: 11,
        iso: 'CRI',
    },
    {
        id: 50,
        description: 'Croatia (Hrvatska)',
        number: 11,
        iso: 'HRV',
    },
    {
        id: 51,
        description: 'Cuba',
        number: 11,
        iso: 'CUB',
    },
    {
        id: 52,
        description: 'Cyprus',
        number: 11,
        iso: 'CYP',
    },
    {
        id: 53,
        description: 'Czech Republic',
        number: 11,
        iso: 'CZE',
    },
    {
        id: 54,
        description: 'Benin',
        number: 11,
        iso: 'BEN',
    },
    {
        id: 55,
        description: 'Denmark',
        number: 11,
        iso: 'DNK',
    },
    {
        id: 56,
        description: 'Dominica',
        number: 11,
        iso: 'DMA',
    },
    {
        id: 57,
        description: 'Dominican Republic',
        number: 11,
        iso: 'DOM',
    },
    {
        id: 58,
        description: 'Ecuador',
        number: 11,
        iso: 'ECU',
    },
    {
        id: 59,
        description: 'El Salvador',
        number: 11,
        iso: 'SLV',
    },
    {
        id: 60,
        description: 'Equatorial Guinea',
        number: 11,
        iso: 'GNQ',
    },
    {
        id: 61,
        description: 'Ethiopia',
        number: 11,
        iso: 'ETH',
    },
    {
        id: 62,
        description: 'Eritrea',
        number: 11,
        iso: 'ERI',
    },
    {
        id: 63,
        description: 'Estonia',
        number: 11,
        iso: 'EST',
    },
    {
        id: 64,
        description: 'Faeroe Islands',
        number: 11,
        iso: 'FRO',
    },
    {
        id: 65,
        description: 'Falkland Islands (Malvinas)',
        number: 11,
        iso: 'FLK',
    },
    {
        id: 66,
        description: 'Fiji',
        number: 11,
        iso: 'FJI',
    },
    {
        id: 67,
        description: 'Finland',
        number: 11,
        iso: 'FIN',
    },
    {
        id: 68,
        description: 'France',
        number: 11,
        iso: 'FRA',
    },
    {
        id: 69,
        description: 'England',
        number: 11,
        iso: '#N/A',
    },
    {
        id: 70,
        description: 'French Guiana',
        number: 11,
        iso: 'GUF',
    },
    {
        id: 71,
        description: 'French Polynesia',
        number: 11,
        iso: 'PYF',
    },
    {
        id: 72,
        description: 'Djibouti',
        number: 11,
        iso: 'DJI',
    },
    {
        id: 73,
        description: 'Gabon',
        number: 11,
        iso: 'GAB',
    },
    {
        id: 74,
        description: 'Gambia, The',
        number: 11,
        iso: 'GMB',
    },
    {
        id: 75,
        description: 'Palestinian Territories',
        number: 11,
        iso: 'PSE',
    },
    {
        id: 76,
        description: 'Germany (Deutschland)',
        number: 11,
        iso: 'DEU',
    },
    {
        id: 77,
        description: 'Ghana',
        number: 11,
        iso: 'GHA',
    },
    {
        id: 78,
        description: 'Gibraltar',
        number: 11,
        iso: 'GIB',
    },
    {
        id: 79,
        description: 'Kiribati',
        number: 11,
        iso: 'KIR',
    },
    {
        id: 80,
        description: 'Greece',
        number: 11,
        iso: 'GRC',
    },
    {
        id: 81,
        description: 'Greenland',
        number: 11,
        iso: 'GRL',
    },
    {
        id: 82,
        description: 'Grenada',
        number: 11,
        iso: 'GRD',
    },
    {
        id: 83,
        description: 'Guadeloupe',
        number: 11,
        iso: 'GLP',
    },
    {
        id: 84,
        description: 'Guam',
        number: 11,
        iso: 'GUM',
    },
    {
        id: 85,
        description: 'Guatemala',
        number: 11,
        iso: 'GTM',
    },
    {
        id: 86,
        description: 'Guinea',
        number: 11,
        iso: 'GIN',
    },
    {
        id: 87,
        description: 'Guyana',
        number: 11,
        iso: 'GUY',
    },
    {
        id: 88,
        description: 'Haiti',
        number: 11,
        iso: 'HTI',
    },
    {
        id: 89,
        description: 'Vatican (Holy See)',
        number: 11,
        iso: 'VAT',
    },
    {
        id: 90,
        description: 'Honduras',
        number: 11,
        iso: 'HND',
    },
    {
        id: 91,
        description: 'Hong Kong (Special Administrative Region Of China)',
        number: 11,
        iso: 'HKG',
    },
    {
        id: 92,
        description: 'Hungary',
        number: 11,
        iso: 'HUN',
    },
    {
        id: 93,
        description: 'Iceland',
        number: 11,
        iso: 'ISL',
    },
    {
        id: 94,
        description: 'India',
        number: 11,
        iso: 'IND',
    },
    {
        id: 95,
        description: 'Indonesia',
        number: 6,
        iso: 'IDN',
    },
    {
        id: 96,
        description: 'Iran (Islamic Republic Of Iran)',
        number: 11,
        iso: 'IRN',
    },
    {
        id: 97,
        description: 'Iraq',
        number: 11,
        iso: 'IRQ',
    },
    {
        id: 98,
        description: 'Ireland',
        number: 11,
        iso: 'IRL',
    },
    {
        id: 99,
        description: 'Israel',
        number: 11,
        iso: 'ISR',
    },
    {
        id: 100,
        description: 'Italy',
        number: 11,
        iso: 'ITA',
    },
    {
        id: 101,
        description: 'Cote D ivoire (Ivory Coast)',
        number: 11,
        iso: 'CIV',
    },
    {
        id: 102,
        description: 'Jamaica',
        number: 11,
        iso: 'JAM',
    },
    {
        id: 103,
        description: 'Japan',
        number: 7,
        iso: 'JPN',
    },
    {
        id: 104,
        description: 'Kazakhstan',
        number: 11,
        iso: 'KAZ',
    },
    {
        id: 105,
        description: 'Jordan (Hashemite Kingdom Of Jordan)',
        number: 11,
        iso: 'JOR',
    },
    {
        id: 106,
        description: 'Kenya',
        number: 11,
        iso: 'KEN',
    },
    {
        id: 107,
        description: 'Korea (Democratic Peoples Republic Of [North] Korea)',
        number: 11,
        iso: 'PRK',
    },
    {
        id: 108,
        description: 'Korea (Republic Of [South] Korea)',
        number: 8,
        iso: 'KOR',
    },
    {
        id: 109,
        description: 'Kuwait',
        number: 11,
        iso: 'KWT',
    },
    {
        id: 110,
        description: 'Kyrgyzstan',
        number: 11,
        iso: 'KGZ',
    },
    {
        id: 111,
        description: "Lao People's Democratic Republic",
        number: 11,
        iso: 'LAO',
    },
    {
        id: 112,
        description: 'Lebanon',
        number: 11,
        iso: 'LBN',
    },
    {
        id: 113,
        description: 'Lesotho',
        number: 11,
        iso: 'LSO',
    },
    {
        id: 114,
        description: 'Latvia',
        number: 11,
        iso: 'LVA',
    },
    {
        id: 115,
        description: 'Liberia',
        number: 11,
        iso: 'LBR',
    },
    {
        id: 116,
        description: 'Libya (Libyan Arab Jamahirya)',
        number: 11,
        iso: 'LBY',
    },
    {
        id: 117,
        description: 'Liechtenstein (Furstentum Liechtenstein)',
        number: 11,
        iso: 'LIE',
    },
    {
        id: 118,
        description: 'Lithuania',
        number: 11,
        iso: 'LTU',
    },
    {
        id: 119,
        description: 'Luxembourg',
        number: 11,
        iso: 'LUX',
    },
    {
        id: 120,
        description: 'Macao (Special Administrative Region Of China)',
        number: 11,
        iso: 'MAC',
    },
    {
        id: 121,
        description: 'Madagascar',
        number: 11,
        iso: 'MDG',
    },
    {
        id: 122,
        description: 'Malawi',
        number: 11,
        iso: 'MWI',
    },
    {
        id: 123,
        description: 'Malaysia',
        number: 1,
        iso: 'MYS',
    },
    {
        id: 124,
        description: 'Maldives',
        number: 11,
        iso: 'MDV',
    },
    {
        id: 125,
        description: 'Mali',
        number: 11,
        iso: 'MLI',
    },
    {
        id: 126,
        description: 'Malta',
        number: 11,
        iso: 'MLT',
    },
    {
        id: 127,
        description: 'Martinique',
        number: 11,
        iso: 'MTQ',
    },
    {
        id: 128,
        description: 'Mauritania',
        number: 11,
        iso: 'MRT',
    },
    {
        id: 129,
        description: 'Mauritius',
        number: 11,
        iso: 'MUS',
    },
    {
        id: 130,
        description: 'Mexico',
        number: 11,
        iso: 'MEX',
    },
    {
        id: 131,
        description: 'Monaco',
        number: 11,
        iso: 'MCO',
    },
    {
        id: 132,
        description: 'Mongolia',
        number: 11,
        iso: 'MNG',
    },
    {
        id: 133,
        description: 'Moldova',
        number: 11,
        iso: 'MDA',
    },
    {
        id: 134,
        description: 'Montenegro',
        number: 11,
        iso: 'MNE',
    },
    {
        id: 135,
        description: 'Montserrat',
        number: 11,
        iso: 'MSR',
    },
    {
        id: 136,
        description: 'Morocco',
        number: 11,
        iso: 'MAR',
    },
    {
        id: 137,
        description: 'Mozambique',
        number: 11,
        iso: 'MOZ',
    },
    {
        id: 138,
        description: 'Oman',
        number: 11,
        iso: 'OMN',
    },
    {
        id: 139,
        description: 'Namibia',
        number: 11,
        iso: 'NAM',
    },
    {
        id: 140,
        description: 'Nauru',
        number: 11,
        iso: 'NRU',
    },
    {
        id: 141,
        description: 'Nepal',
        number: 11,
        iso: 'NPL',
    },
    {
        id: 142,
        description: 'Netherlands',
        number: 11,
        iso: 'NLD',
    },
    {
        id: 143,
        description: 'Netherlands Antilles',
        number: 11,
        iso: 'BES',
    },
    {
        id: 144,
        description: 'Aruba',
        number: 11,
        iso: 'ABW',
    },
    {
        id: 145,
        description: 'New Caledonia',
        number: 11,
        iso: 'NCL',
    },
    {
        id: 146,
        description: 'Vanuatu',
        number: 11,
        iso: 'VUT',
    },
    {
        id: 147,
        description: 'New Zealand',
        number: 11,
        iso: 'NZL',
    },
    {
        id: 148,
        description: 'Nicaragua',
        number: 11,
        iso: 'NIC',
    },
    {
        id: 149,
        description: 'Niger',
        number: 11,
        iso: 'NER',
    },
    {
        id: 150,
        description: 'Nigeria',
        number: 11,
        iso: 'NGA',
    },
    {
        id: 151,
        description: 'Niue',
        number: 11,
        iso: 'NIU',
    },
    {
        id: 152,
        description: 'Norway',
        number: 11,
        iso: 'NOR',
    },
    {
        id: 153,
        description: 'Northern Mariana Islands',
        number: 11,
        iso: 'MNP',
    },
    {
        id: 154,
        description: 'Micronesia (Federated States Of Micronesia)',
        number: 11,
        iso: 'FSM',
    },
    {
        id: 155,
        description: 'Marshall Islands',
        number: 11,
        iso: 'MHL',
    },
    {
        id: 156,
        description: 'Palau',
        number: 11,
        iso: 'PLW',
    },
    {
        id: 157,
        description: 'Pakistan',
        number: 11,
        iso: 'PAK',
    },
    {
        id: 158,
        description: 'Panama',
        number: 11,
        iso: 'PAN',
    },
    {
        id: 159,
        description: 'Papua New Guinea',
        number: 11,
        iso: 'PNG',
    },
    {
        id: 160,
        description: 'Paraguay',
        number: 11,
        iso: 'PRY',
    },
    {
        id: 161,
        description: 'Peru',
        number: 11,
        iso: 'PER',
    },
    {
        id: 162,
        description: 'Philippines',
        number: 10,
        iso: 'PHL',
    },
    {
        id: 163,
        description: 'Poland',
        number: 11,
        iso: 'POL',
    },
    {
        id: 164,
        description: 'Portugal',
        number: 11,
        iso: 'PRT',
    },
    {
        id: 165,
        description: 'Guinea-Bissau',
        number: 11,
        iso: 'GNB',
    },
    {
        id: 166,
        description: 'Timor-Leste (Formerly East Timor)',
        number: 11,
        iso: 'TLS',
    },
    {
        id: 167,
        description: 'Puerto Rico',
        number: 11,
        iso: 'PRI',
    },
    {
        id: 168,
        description: 'Qatar',
        number: 11,
        iso: 'QAT',
    },
    {
        id: 169,
        description: 'Reunion',
        number: 11,
        iso: 'REU',
    },
    {
        id: 170,
        description: 'Romania',
        number: 11,
        iso: 'ROU',
    },
    {
        id: 171,
        description: 'Russian Federation',
        number: 11,
        iso: 'RUS',
    },
    {
        id: 172,
        description: 'Rwanda',
        number: 11,
        iso: 'RWA',
    },
    {
        id: 173,
        description: 'Saint Helena',
        number: 11,
        iso: 'SHN',
    },
    {
        id: 174,
        description: 'Saint Kitts And Nevis',
        number: 11,
        iso: 'KNA',
    },
    {
        id: 175,
        description: 'Anguilla',
        number: 11,
        iso: 'AIA',
    },
    {
        id: 176,
        description: 'Saint Lucia',
        number: 11,
        iso: 'LCA',
    },
    {
        id: 177,
        description: 'Saint Pierre And Miquelon',
        number: 11,
        iso: 'SPM',
    },
    {
        id: 178,
        description: 'Saint Vincent And The Grenadines',
        number: 11,
        iso: 'VCT',
    },
    {
        id: 179,
        description: 'San Marino (Republic Of)',
        number: 11,
        iso: 'SMR',
    },
    {
        id: 180,
        description: 'Sao Tome And Principe',
        number: 11,
        iso: 'STP',
    },
    {
        id: 181,
        description: 'Saudi Arabia (Kingdom Of Saudi Arabia)',
        number: 11,
        iso: 'SAU',
    },
    {
        id: 182,
        description: 'Senegal',
        number: 11,
        iso: 'SEN',
    },
    {
        id: 183,
        description: 'Serbia (Republic Of Serbia)',
        number: 11,
        iso: 'SRB',
    },
    {
        id: 184,
        description: 'Seychelles',
        number: 11,
        iso: 'SYC',
    },
    {
        id: 185,
        description: 'Sierra Leone',
        number: 11,
        iso: 'SLE',
    },
    {
        id: 186,
        description: 'Singapore',
        number: 2,
        iso: 'SGP',
    },
    {
        id: 187,
        description: 'Slovakia (Slovak Republic)',
        number: 11,
        iso: 'SVK',
    },
    {
        id: 188,
        description: 'Viet Nam',
        number: 11,
        iso: 'VNM',
    },
    {
        id: 189,
        description: 'Slovenia',
        number: 11,
        iso: 'SVN',
    },
    {
        id: 190,
        description: 'Somalia',
        number: 11,
        iso: 'SOM',
    },
    {
        id: 191,
        description: 'South Africa (Zuid Afrika)',
        number: 11,
        iso: 'ZAF',
    },
    {
        id: 192,
        description: 'Zimbabwe',
        number: 11,
        iso: 'ZWE',
    },
    {
        id: 193,
        description: 'Spain (Espana)',
        number: 11,
        iso: 'ESP',
    },
    {
        id: 194,
        description: 'Sudan',
        number: 11,
        iso: 'SDN',
    },
    {
        id: 195,
        description: 'Suriname',
        number: 11,
        iso: 'SUR',
    },
    {
        id: 196,
        description: 'Swaziland',
        number: 11,
        iso: 'SWZ',
    },
    {
        id: 197,
        description: 'Sweden',
        number: 11,
        iso: 'SWE',
    },
    {
        id: 198,
        description: 'Switzerland (Confederation Of Helvetia)',
        number: 11,
        iso: 'CHE',
    },
    {
        id: 199,
        description: 'Syrian Arab Republic',
        number: 11,
        iso: 'SYR',
    },
    {
        id: 200,
        description: 'Tajikistan',
        number: 11,
        iso: 'TJK',
    },
    {
        id: 201,
        description: 'Thailand',
        number: 3,
        iso: 'THA',
    },
    {
        id: 202,
        description: 'Togo',
        number: 11,
        iso: 'TGO',
    },
    {
        id: 203,
        description: 'Tokelau',
        number: 11,
        iso: 'TKL',
    },
    {
        id: 204,
        description: 'Tonga',
        number: 11,
        iso: 'TON',
    },
    {
        id: 205,
        description: 'Trinidad And Tobago',
        number: 11,
        iso: 'TTO',
    },
    {
        id: 206,
        description: 'United Arab Emirates',
        number: 11,
        iso: 'ARE',
    },
    {
        id: 207,
        description: 'Tunisia',
        number: 11,
        iso: 'TUN',
    },
    {
        id: 208,
        description: 'Turkey',
        number: 11,
        iso: 'TUR',
    },
    {
        id: 209,
        description: 'Turkmenistan',
        number: 11,
        iso: 'TKM',
    },
    {
        id: 210,
        description: 'Turks And Caicos Islands',
        number: 11,
        iso: 'TCA',
    },
    {
        id: 211,
        description: 'Tuvalu',
        number: 11,
        iso: 'TUV',
    },
    {
        id: 212,
        description: 'Uganda',
        number: 11,
        iso: 'UGA',
    },
    {
        id: 213,
        description: 'Ukraine',
        number: 11,
        iso: 'UKR',
    },
    {
        id: 214,
        description: 'Macedonia (Formerly Yugoslav Republic Of Macedonia)',
        number: 11,
        iso: 'MKD',
    },
    {
        id: 215,
        description: 'Egypt',
        number: 11,
        iso: 'EGY',
    },
    {
        id: 216,
        description: 'United Kingdom (Great Britain)',
        number: 11,
        iso: 'GBR',
    },
    {
        id: 217,
        description: 'Tanzania',
        number: 11,
        iso: 'TZA',
    },
    {
        id: 218,
        description: 'United States',
        number: 4,
        iso: 'USA',
    },
    {
        id: 219,
        description: 'Virgin Islands U.S.',
        number: 11,
        iso: 'VIR',
    },
    {
        id: 220,
        description: 'Burkina Faso',
        number: 11,
        iso: 'BFA',
    },
    {
        id: 221,
        description: 'Uruguay',
        number: 11,
        iso: 'URY',
    },
    {
        id: 222,
        description: 'Uzbekistan',
        number: 11,
        iso: 'UZB',
    },
    {
        id: 223,
        description: 'Venezuela',
        number: 11,
        iso: 'VEN',
    },
    {
        id: 224,
        description: 'Wallis And Futuna',
        number: 11,
        iso: 'WLF',
    },
    {
        id: 225,
        description: 'Samoa (Formerly Western Samoa)',
        number: 11,
        iso: 'WSM',
    },
    {
        id: 226,
        description: 'Yemen (Yemen Arab Republic)',
        number: 11,
        iso: 'YEM',
    },
    {
        id: 227,
        description: 'Zambia (Formerly Northern Rhodesia)',
        number: 11,
        iso: 'ZMB',
    },
    {
        id: 228,
        description: 'Non-Malaysian',
        number: 11,
        iso: '#N/A',
    },
];
