import { InitState } from 'redux/slices/rea';
import { AdminViewAllCoBrokeResponse, GetREADetailsResponse, READetailsWithPropertyInfo } from '@dm/types';

const getCobrokesAttempting = (state: InitState): boolean => state.actions.getCobrokes || false;
const getCobrokesError = (state: InitState): string => state.error.getCobrokes || '';

const getCobrokes = (state: InitState): AdminViewAllCoBrokeResponse => state.cobrokes || null;

const reviewCobrokesAttempting = (state: InitState): boolean => state.actions.reviewCobrokes || false;
const reviewCobrokesError = (state: InitState): string => state.error.reviewCobrokes || '';

const getReaAttempting = (state: InitState): boolean => state.actions.getRea || false;
const getReaError = (state: InitState): string => state.error.getRea || '';
const getReaReport = (state: InitState): GetREADetailsResponse => state.reaReport || null;

const getReaDetailsAttempting = (state: InitState): boolean => state.actions.getReaDetails || false;
const getReaDetailsError = (state: InitState): string => state.error.getReaDetails || '';
const getReaDetails = (state: InitState): READetailsWithPropertyInfo | null => state.reaDetails || null;

export default {
    getCobrokesAttempting,
    getCobrokesError,

    getCobrokes,

    reviewCobrokesAttempting,
    reviewCobrokesError,

    getReaAttempting,
    getReaError,
    getReaReport,

    getReaDetailsAttempting,
    getReaDetailsError,
    getReaDetails,
};
