import React, { useEffect, useRef, useState } from 'react';
import * as ReactDom from 'react-dom';
import styled from 'styled-components';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { Colors } from '@dm/bigfish';
import Translate from 'lib/translate';

import SVG from 'react-inlinesvg';
import config from 'config';
import { IProperty } from '@dm/types';
import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesPoscode, CoordinatesState, CoordinatesCountry, CoordinatesCity } from 'entities/property';
import icons from '../../../assets/icons';
/* global google */

interface MapProps {
    setCoordinates: (params: Coordinates) => void;
    setAddress1: (params: CoordinatesAddress1) => void;
    setAddress2: (params: CoordinatesAddress2) => void;
    setPoscode: (params: CoordinatesPoscode) => void;
    setState: (params: CoordinatesState) => void;
    setCountry: (params: CoordinatesCountry) => void;
    setCity: (params: CoordinatesCity) => void;
    setLocationLatitudeFirstLoad: (params: number) => void;
    setLocationLongitudeFirstLoad: (params: number) => void;
    getPropertyDetails: (id: string) => void;
    propertyDetailsData: IProperty | null;
    propertySetPropertyLocationSubmitted: boolean;
    setEdited: (state: boolean) => void;
    edited: boolean;
    newPropertyId: string;
    showErrorMessage: boolean;
    setShowErrorMessage: (state: boolean) => void;
}

const Map = (props: MapProps) => {
    const { setEdited,
        setCoordinates,
        setAddress1,
        setAddress2,
        setPoscode,
        setState,
        setCountry,
        setCity,
        setLocationLongitudeFirstLoad,
        setLocationLatitudeFirstLoad,
        getPropertyDetails,
        propertyDetailsData,
        edited,
        propertySetPropertyLocationSubmitted,
        newPropertyId,
        showErrorMessage,
        setShowErrorMessage } = props;

    const [showMarker, setShowMarker] = useState(false);

    const allStreetNumber = ['street_number', 'route', 'subpremise', 'sublocality_level_1'];
    const allStreetAddresses = ['street_address', 'neighborhood', 'neighborhood', 'sublocality', 'premise'];
    const allCity = ['locality', 'administrative_area_level_2'];

    useEffect(() => {
        // getPropertyDetails(newPropertyId);
    }, [edited, propertyDetailsData?.location?.address1, propertySetPropertyLocationSubmitted]);

    useEffect(() => {
        loadGoogleMapScript(initAutocomplete);
    }, [propertyDetailsData?.location?.address1, edited, propertySetPropertyLocationSubmitted]);

    const loadGoogleMapScript = (callback) => {
        try {
            const googleMapScript = document.createElement('script');
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${config.dormeoInformations.mapApiKey}&libraries=places`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener('load', callback);
        } catch (e) {
            console.log('');
        }
    };

    function initAutocomplete() {
        let coordinateLat = 0;
        let coordinateLng = 0;
        firstLoad();
        function firstLoad() {
            if ((propertyDetailsData?.location?.address1.length)) {
                const currentLatitude = parseFloat(propertyDetailsData.location.lat ?? 0);
                const currentLongitude = parseFloat(propertyDetailsData.location.long ?? 0);
                const latlng = {
                    lat: currentLatitude,
                    lng: currentLongitude,
                };

                const geocoderFirstLoad = new google.maps.Geocoder();
                geocoderFirstLoad.geocode({ location: latlng }, (results, status) => {
                    if (status !== google.maps.GeocoderStatus.OK) {
                        console.log(status);
                        mapFunctionIfNoData();
                    }
                    // This is checking to see if the Geoeode Status is OK before proceeding
                    if (status === google.maps.GeocoderStatus.OK) {
                        coordinateLat = results[0].geometry.location.lat();
                        coordinateLng = results[0].geometry.location.lng();
                        mapFunction();
                    }
                });
            } else {
                mapFunctionIfNoData();
            }
        }

        const mapFunction = () => {
            const myLatLng = { lat: coordinateLat, lng: coordinateLng };
            const map = new google.maps.Map(document.getElementById('map'), {
                center: myLatLng,
                zoom: 17,
                mapTypeId: 'roadmap',
                streetViewControl: false,
                mapTypeControl: false,
                FullscreenControlOptions: false,

                disableDefaultUI: true,
                panControl: true,
            });
            if ((coordinateLat !== 0) || (coordinateLng !== 0)) {
                let markers = [];

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];

                markers.push(new google.maps.Marker({
                    position: myLatLng,
                    map,
                    title: `${propertyDetailsData?.location?.address1 ?? ''}${propertyDetailsData?.location?.address2 ?? ''}${propertyDetailsData?.location?.city ?? ''}`,
                }));
            }
            // Create the search box and link it to the UI element.
            const input = document.getElementById('pac-input');
            const searchBox = new google.maps.places.SearchBox(input);

            // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', () => {
                searchBox.setBounds(map.getBounds());
            });

            let markers = [];

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();

                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        return;
                    }

                    // Create a marker for each place.
                    markers.push(
                        new google.maps.Marker({
                            map,
                            title: place.name,
                            position: place.geometry.location,
                        }),

                    );

                    map.setCenter(markers[0].getPosition());

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);

                        const addressComponents = place.address_components;
                        const streetNumberComponent = addressComponents.find(addr => allStreetNumber.some(type => addr.types.includes(type)));
                        const streetAddressComponent = addressComponents.find(addr => allStreetAddresses.some(type => addr.types.includes(type)));
                        const cityComponent = addressComponents.find(addr => allCity.some(type => addr.types.includes(type)));
                        const stateComponent = addressComponents.find(addr => addr.types.includes('administrative_area_level_1'));
                        const zipCodeComponent = addressComponents.find(addr => addr.types.includes('postal_code'));
                        const countryComponent = addressComponents.find(addr => addr.types.includes('country'));

                        const streetNumber = streetNumberComponent ? streetNumberComponent.short_name : '';
                        const streetAddress = streetAddressComponent ? streetAddressComponent.short_name : '';
                        const city = cityComponent ? cityComponent.short_name : '';
                        const state = stateComponent ? stateComponent.short_name : '';
                        const zipCode = zipCodeComponent ? zipCodeComponent.short_name : '';
                        const country = countryComponent ? countryComponent.short_name : '';

                        if (streetNumber === '') {
                            setShowErrorMessage(true);
                        } else {
                            setShowErrorMessage(false);
                            setCoordinates({
                                latitude: markers[0].position.lat(),
                                longitude: markers[0].position.lng(),
                            });

                            setAddress1({
                                address1: streetNumber,
                            });

                            setAddress2({
                                address2: streetAddress,
                            });

                            setPoscode({
                                poscode: zipCode,
                            });

                            setState({
                                state,
                            });

                            setCountry({
                                country,
                            });

                            setCity({
                                city,
                            });
                        }
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
            });

            map.addListener('dragend', () => {
                const centerMap = map.getCenter();

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });

                markers = [];

                markers.push(new google.maps.Marker({
                    map,
                    position: centerMap,
                    draggable: false,
                }));

                map.setCenter(markers[0].getPosition());

                getReverseGeocodingData(markers[0].position.lat(), markers[0].position.lng());
                function getReverseGeocodingData(lat, lng) {
                    const latlng = new google.maps.LatLng(lat, lng);
                    // This is making the Geocode request
                    const geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ latLng: latlng }, (results, status) => {
                        if (status !== google.maps.GeocoderStatus.OK) {
                            console.log(status);
                        }
                        // This is checking to see if the Geoeode Status is OK before proceeding
                        if (status === google.maps.GeocoderStatus.OK) {
                            const addressComponents = results[0].address_components;
                            const streetNumberComponent = addressComponents.find(addr => allStreetNumber.some(type => addr.types.includes(type)));
                            const streetAddressComponent = addressComponents.find(addr => allStreetAddresses.some(type => addr.types.includes(type)));
                            const cityComponent = addressComponents.find(addr => allCity.some(type => addr.types.includes(type)));
                            const stateComponent = addressComponents.find(addr => addr.types.includes('administrative_area_level_1'));
                            const zipCodeComponent = addressComponents.find(addr => addr.types.includes('postal_code'));
                            const countryComponent = addressComponents.find(addr => addr.types.includes('country'));

                            const streetNumber = streetNumberComponent ? streetNumberComponent.short_name : '';
                            const streetAddress = streetAddressComponent ? streetAddressComponent.short_name : '';
                            const city = cityComponent ? cityComponent.short_name : '';
                            const state = stateComponent ? stateComponent.short_name : '';
                            const zipCode = zipCodeComponent ? zipCodeComponent.short_name : '';
                            const country = countryComponent ? countryComponent.short_name : '';

                            if (streetNumber === '') {
                                setShowErrorMessage(true);
                            } else {
                                setShowErrorMessage(false);
                                setCoordinates({
                                    latitude: markers[0].position.lat(),
                                    longitude: markers[0].position.lng(),
                                });

                                setAddress1({
                                    address1: streetNumber,
                                });

                                setAddress2({
                                    address2: streetAddress,
                                });

                                setPoscode({
                                    poscode: zipCode,
                                });

                                setState({
                                    state,
                                });

                                setCountry({
                                    country,
                                });

                                setCity({
                                    city,
                                });
                            }
                        }
                    });
                }
            });
        };

        function mapFunctionIfNoData() {
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 3.15, lng: 101.72 },
                zoom: 17,
                mapTypeId: 'roadmap',
                streetViewControl: false,
                mapTypeControl: false,
                FullscreenControlOptions: false,
                disableDefaultUI: true,
                panControl: true,
            });
            // Create the search box and link it to the UI element.
            const input = document.getElementById('pac-input');
            const searchBox = new google.maps.places.SearchBox(input);

            // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', () => {
                searchBox.setBounds(map.getBounds());
            });

            let markers = [];

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            // ! function for when user uses the searchbar
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces();
                if (places.length === 0) {
                    return;
                }

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();
                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        return;
                    }

                    // Create a marker for each place.
                    markers.push(
                        new google.maps.Marker({
                            map,
                            title: place.name,
                            position: place.geometry.location,
                        }),

                    );

                    map.setCenter(markers[0].getPosition());

                    if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);

                        const addressComponents = place.address_components;
                        const streetNumberComponent = addressComponents.find(addr => allStreetNumber.some(type => addr.types.includes(type)));
                        const streetAddressComponent = addressComponents.find(addr => allStreetAddresses.some(type => addr.types.includes(type)));
                        const cityComponent = addressComponents.find(addr => allCity.some(type => addr.types.includes(type)));
                        const stateComponent = addressComponents.find(addr => addr.types.includes('administrative_area_level_1'));
                        const zipCodeComponent = addressComponents.find(addr => addr.types.includes('postal_code'));
                        const countryComponent = addressComponents.find(addr => addr.types.includes('country'));

                        const streetNumber = streetNumberComponent ? streetNumberComponent.short_name : '';
                        const streetAddress = streetAddressComponent ? streetAddressComponent.short_name : '';
                        const city = cityComponent ? cityComponent.short_name : '';
                        const state = stateComponent ? stateComponent.short_name : '';
                        const zipCode = zipCodeComponent ? zipCodeComponent.short_name : '';
                        const country = countryComponent ? countryComponent.short_name : '';

                        if (streetNumber === '') {
                            setShowErrorMessage(true);
                        } else {
                            setShowErrorMessage(false);
                            setCoordinates({
                                latitude: markers[0].position.lat(),
                                longitude: markers[0].position.lng(),
                            });

                            setAddress1({
                                address1: streetNumber,
                            });

                            setAddress2({
                                address2: streetAddress,
                            });

                            setPoscode({
                                poscode: zipCode,
                            });

                            setState({
                                state,
                            });

                            setCountry({
                                country,
                            });

                            setCity({
                                city,
                            });
                        }
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
            });

            // ! function for dragging map around
            map.addListener('dragend', () => {
                const centerMap = map.getCenter();
                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });

                markers = [];

                markers.push(new google.maps.Marker({
                    map,
                    position: centerMap,
                    draggable: false,
                }));

                map.setCenter(markers[0].getPosition());

                getReverseGeocodingData(markers[0].position.lat(), markers[0].position.lng());
                function getReverseGeocodingData(lat, lng) {
                    const latlng = new google.maps.LatLng(lat, lng);
                    // This is making the Geocode request
                    const geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ latLng: latlng }, (results, status) => {
                        if (status !== google.maps.GeocoderStatus.OK) {
                            console.log(status);
                        }
                        // This is checking to see if the Geoeode Status is OK before proceeding
                        if (status === google.maps.GeocoderStatus.OK) {
                            const addressComponents = results[0].address_components;
                            const streetNumberComponent = addressComponents.find(addr => allStreetNumber.some(type => addr.types.includes(type)));
                            const streetAddressComponent = addressComponents.find(addr => allStreetAddresses.some(type => addr.types.includes(type)));
                            const cityComponent = addressComponents.find(addr => allCity.some(type => addr.types.includes(type)));
                            const stateComponent = addressComponents.find(addr => addr.types.includes('administrative_area_level_1'));
                            const zipCodeComponent = addressComponents.find(addr => addr.types.includes('postal_code'));
                            const countryComponent = addressComponents.find(addr => addr.types.includes('country'));

                            const streetNumber = streetNumberComponent ? streetNumberComponent.short_name : '';
                            const streetAddress = streetAddressComponent ? streetAddressComponent.short_name : '';
                            const city = cityComponent ? cityComponent.short_name : '';
                            const state = stateComponent ? stateComponent.short_name : '';
                            const zipCode = zipCodeComponent ? zipCodeComponent.short_name : '';
                            const country = countryComponent ? countryComponent.short_name : '';

                            if (streetNumber === '') {
                                setShowErrorMessage(true);
                            } else {
                                setShowErrorMessage(false);
                                setCoordinates({
                                    latitude: markers[0].position.lat(),
                                    longitude: markers[0].position.lng(),
                                });

                                setAddress1({
                                    address1: streetNumber,
                                });

                                setAddress2({
                                    address2: streetAddress,
                                });

                                setPoscode({
                                    poscode: zipCode,
                                });

                                setState({
                                    state,
                                });

                                setCountry({
                                    country,
                                });

                                setCity({
                                    city,
                                });
                            }
                        }
                    });
                }
            });
        }
    }
    return (

        <>
            <div>
                <title>{Translate.t('Property.Location.PlacesSearchBox')}</title>
            </div>
            <div style={{ minHeight: '400px' }}>

                <InputContainer
                    id='pac-input'
                    type='text'
                    placeholder={Translate.t('Property.Location.SearchBox')}
                />

                <MarkerContainer>
                    <div id='map' style={{ width: '100%', height: '100%', background: 'white' }} />
                </MarkerContainer>
                {
                    showErrorMessage
                        ? (<ErrorMessage>Invalid location, please adjust the marker!</ErrorMessage>) : null
                }
            </div>

        </>
    );
};

const InputContainer = styled.input`
    height: 28px;
    width: 55%;
    z-index: 1;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    padding: 8px;
    border-bottom-color: 5px blue;
`;

const MarkerContainer = styled.div`
    width: 85%;
    height: 300px;
    position: relative;
`;

const ErrorMessage = styled.div`
    padding: 20px;
    color: red;
    font-style: italic;
`;

const mapStateToProps = (state: RootState) => ({
    locationLatitudeFirstLoad: Selectors.setPropertysLocationLatitudeFirstLoad(state),
    locationLongitudeFirstLoad: Selectors.setPropertysLocationLongitudeFirstLoad(state),
    propertyDetailsData: Selectors.getPropertyGetPropertyDetails(state),
    propertySetPropertyLocationSubmitted: Selectors.propertySetPropertyLocationSubmitted(state),
    showErrorMessage: Selectors.setPropertySetShowErrorMessage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCoordinates: (params: Coordinates) => dispatch(Actions.propertySetCoordinates(params)),
    setAddress1: (params: CoordinatesAddress1) => dispatch(Actions.propertySetCoordinatesAddress1(params)),
    setAddress2: (params: CoordinatesAddress2) => dispatch(Actions.propertySetCoordinatesAddress2(params)),
    setPoscode: (params: CoordinatesPoscode) => dispatch(Actions.propertySetCoordinatesPoscode(params)),
    setState: (params: CoordinatesState) => dispatch(Actions.propertySetCoordinatesState(params)),
    setCountry: (params: CoordinatesCountry) => dispatch(Actions.propertySetCoordinatesCountry(params)),
    setCity: (params: CoordinatesCity) => dispatch(Actions.propertySetCoordinatesCity(params)),
    setLocationLatitudeFirstLoad: (params: number) => dispatch(Actions.propertySetLocationLatitudeFirstLoad(params)),
    setLocationLongitudeFirstLoad: (params: number) => dispatch(Actions.propertySetLocationLongitudeFirstLoad(params)),
    getPropertyDetails: (id: string) =>
        dispatch(Actions.getPropertPropertyDetailsAttempt({ id })),
    setShowErrorMessage: (state: boolean) => dispatch(Actions.setShowErrorMessage(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
