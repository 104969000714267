import { Colors } from '@dm/bigfish';
import { PropertyPackages } from '@dm/types';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

export type TabProps = {
    label: string;
    value: PropertyPackages;
    onClick: () => void;
}

type TabViewProps = {
    tabList: TabProps[];
    selectedTab: PropertyPackages;
    tabContent: React.ReactNode;
}

const TabView: FC<TabViewProps> = (props: TabViewProps) => {
    const {
        tabList,
        selectedTab,
        tabContent,
    } = props;

    return (
        <TabViewContainer>
            <TabListContainer>
                {tabList.map((tab) => (
                    <TabContainer
                        isSelected={tab.value === selectedTab}
                        key={tab.value}
                        onClick={tab.onClick}
                    >
                        {tab.label}
                    </TabContainer>
                ))}
            </TabListContainer>
            <Divider />
            <TabContentContainer>
                {tabContent}
            </TabContentContainer>
        </TabViewContainer>
    );
};

const TabViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

const TabListContainer = styled.div`
    display: flex;
    width: 100%;
`;

const TabContainer = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    color: ${Colors.black};
    user-select: none;

    ${(props) => props.isSelected && css`
        background-color: ${Colors.primary};
        color: ${Colors.white};
    `}
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${Colors.greyDark};
    margin: 10px 0;
`;
const TabContentContainer = styled.div`
    display: flex;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

export default TabView;
