import Modal from 'components/Modal';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import styled from 'styled-components';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import ReactDatePicker from 'react-datepicker';
import { Button, Colors } from '@dm/bigfish';
import { ViewAllPMTransactionResponse } from '@dm/types';
import { SetEditTransactionPayload } from 'redux/slices/packages/types';

dayjs.extend(utc);

interface EditTransactionModalProps {
    selectedTransaction?: ViewAllPMTransactionResponse;

    modalIsOpen: boolean;

    setModalIsOpen: (state: boolean) => void;

    editTransactionAttempt: boolean;
    editTransactionError: string;
    setEditTransaction: (data: SetEditTransactionPayload['payload']) => void;
}

const EditTransactionsModal = (props: EditTransactionModalProps): JSX.Element => {
    const {
        selectedTransaction,
        modalIsOpen,
        setModalIsOpen,
        editTransactionAttempt,
        editTransactionError,
        setEditTransaction,
    } = props;

    const [propertyId, setPropertyId] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [description, setDescription] = useState('');
    const [transactionDate, setTransactionDate] = useState<Date>(new Date());

    const onDatepickerChangeHandler = (date: Date) => {
        setTransactionDate(date);
    };

    useEffect(() => {
        if (selectedTransaction) {
            setPropertyId(selectedTransaction.propertyId);
            setTransactionId(selectedTransaction.transactionsId);
            setDescription(selectedTransaction.description);
            setTransactionDate(new Date(selectedTransaction.createdAt));
        }
    }, [selectedTransaction]);

    return (
        <Modal
            height=''
            width='550px'
            show={modalIsOpen}
            closeCursor
            onClickClose={() => setModalIsOpen(!modalIsOpen)}
        >
            <ModalContainer>
                <Title>Edit Transaction</Title>
                <ModalContent>
                    <InputContainer>
                        <p style={{ paddingRight: '20px' }}>Description:</p>
                        <StyledInput
                            type='text'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <p style={{ paddingRight: '20px' }}>Date:</p>
                        <StyledButtonWrapper>
                            <DateTimePicker
                                className='customized-datepicker'
                                onChange={(newDate: Date) => onDatepickerChangeHandler(newDate)}
                                value={(!transactionDate) ? 'Select Date' : dayjs(transactionDate).format('dddd,  DD-MM-YY')}
                                selected={transactionDate}
                                dateFormat='MMMM d, yyyy HH:mm'
                                shouldCloseOnSelect
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                            />
                        </StyledButtonWrapper>
                    </InputContainer>
                </ModalContent>
                <ModalFooter>
                    <Button
                        label='Save'
                        disabled={editTransactionAttempt}
                        loading={editTransactionAttempt}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setEditTransaction({
                            propertyId,
                            transactionId,
                            description,
                            transactionDate: dayjs(transactionDate).utc().format(),
                        })}
                    />
                </ModalFooter>
            </ModalContainer>
        </Modal>
    );
};

EditTransactionsModal.defaultProps = {
    selectedTransaction: undefined,
};

const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
`;
const Text = styled.p`
    font-size: 16px;
    display: flex;
    align-items: center;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const ModalContent = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    justify-content: center;

    padding: 20px;
`;

const ModalFooter = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: end;
`;

const InputContainer = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;

    align-items: center;
`;

const StyledInput = styled.input`
    border-radius: 12px;
    outline: none;
    height: 20px;
    margin: 5px 0;
    padding: 15px;
    text-align: center;

    width: ${props => props.width}
`;

const StyledButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
`;

const DateTimePicker = styled(ReactDatePicker)`
    text-align: center;
    z-index: 9999999999;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${Colors.black};
    appearance: none;
    border-radius: 12px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus, &:active{
        border-color: rgba(61, 39, 78, 1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(61, 39, 78, 0.6);
        outline: 0 none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${Colors.primary};
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${Colors.primary};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${Colors.primary};
    }
`;

const mapStateToProps = (state: RootState) => ({
    modalIsOpen: Selectors.getUiEditTransactionModal(state),
    editTransactionAttempt: Selectors.setPackagesEditTransactionAttempt(state),
    editTransactionError: Selectors.setPackagesEditTransactionFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalIsOpen: (state: boolean) => dispatch(Actions.setEditTransactionModal(state)),
    setEditTransaction: (data: SetEditTransactionPayload['payload']) => dispatch(Actions.packagesEditTransactionAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTransactionsModal);
