import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

export default function* watchGroupPropertiesWithCount(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/groupPropertiesWithCountAttempt', handleGroupPropertiesWithCount, api);
}

function* handleGroupPropertiesWithCount(api: PropertyGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.groupPropertiesWithCount], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.propertyGetAllFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.groupPropertiesWithCountSuccess(response.data));
    } else {
        yield put(Actions.groupPropertiesWithCountFailure(response.message));
    }
}
