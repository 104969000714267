import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { BreadCrumb, TabView } from '@dm/bigfish';

import media from 'lib/Breakpoints';
import Stats from './components/Stats';
import Modules from './components/Modules';

import MainContainer from '../../components/MainContainer';

interface HomeScreenProps {
    setTabView: (tabView: string) => void;
}

const HomeScreen = (props: HomeScreenProps): JSX.Element => {
    const { setTabView } = props;
    const [homeScreenTabView, setHomeScreenTabView] = useState('');
    // ? how to get SuperAdmin id
    // const superAdminId = '';

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('BreadCrumb.BreadCrumbWelcomeBack'),
            },
        ],
    );

    const [tabViewData] = useState([
        // TEMP
        // {
        //     text: 'stats',
        //     onClickTabView: () => {
        //         setHomeScreenTabView('stats');
        //         NavActions.navToDashboard();
        //     },
        // },
        {
            text: 'modules',
            onClickTabView: () => {
                setHomeScreenTabView('modules');
                NavActions.navToModules();
            },
        },
    ]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const location = useLocation();

    useEffect(() => {
        // TEMP
        // const pathName = location.pathname.replace('/', '');
        // if (pathName === 'modules') {
        //     setHomeScreenTabView('modules');
        // } else {
        //     setHomeScreenTabView('stats');
        // }

        setHomeScreenTabView('modules');
        setTabView('dashboard');
    }, []);

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <HomeScreenContent>
                    <Stats />
                    <ModulesContainer>
                        <h1>Modules</h1>
                        <Modules />
                    </ModulesContainer>
                </HomeScreenContent>
            </MainContainer>
        </>
    );
};

const TabViewContainer = styled.div`
    margin-top: 30px;
    margin-left: 20px;
`;
const ModulesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    h1{
        margin-top: 20px;

        ${media.lg`
            display: none;
        `}
    }
`;
const HomeScreenContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;

    h1 {
        margin-top: 20px;

        ${media.xl`
            font-size: 2rem;
        `}
    }

    ${media.lg`
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    `}
`;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
});

export default connect(null, mapDispatchToProps)(HomeScreen);
