import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import SubAdminsGateway from 'api/Subadmins';

import { SetAssignPropertyActionPayload } from 'redux/slices/subadmins/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';

export default function* watchAssignSubAdminProperty(api: SubAdminsGateway): SagaWatcherReturnType {
    yield takeEvery('subadmins/setAssignPropertyAttempt', handleAssignSubAdminProperty, api);
}

function* handleAssignSubAdminProperty(api: SubAdminsGateway, data: SetAssignPropertyActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setSubAdminProperty], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Properties Added Successfully!');
        yield put(Actions.setAssignPropertySuccess());
        yield put(Actions.subAdminGetAllAttempt({ index: 1 }));
        NavActions.navToReportSubAdmins();

        if (!response.data) {
            yield put(Actions.setAssignPropertyFailure('Something went wrong. Please try again.'));
        }
    } else {
        yield put(Actions.setAssignPropertyFailure(response.message));

        if (response.message && response.message.toLowerCase() === 'Invalid request body') {
            toast.error('Adding property failed! Please try again later');
        } else {
            toast.error('Adding property failed! Please try again later');
        }
    }
}
