import React, { FunctionComponent, useState, useRef, useEffect, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import { toast } from 'react-toastify';
import axios from 'axios';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { TailSpin } from 'react-loader-spinner';

import icons from 'assets/icons';
import { RoundInput, Colors } from '@dm/bigfish';
import { Category, PackageCategory } from '@dm/types';

interface UploadComponentDefectInspectionProps {
    index: number
    key: string;
    propertyId: string;
    attempLoadingUpload: boolean;
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
    documentTypeName: string;
    isDocumentUploadComplete: boolean;
    setUploadStatusReset: (state: boolean) => void;
}

const UploadComponentDefectInspection: FunctionComponent<UploadComponentDefectInspectionProps> = (props: UploadComponentDefectInspectionProps) => {
    const { index, key, getUploadUrl, attempLoadingUpload, isDocumentUploadComplete, setUploadStatusReset, propertyId, documentTypeName } = props;

    const [name, setName] = useState('');
    const [disableButton, setdisableButton] = useState(false);

    const reference = useRef<HTMLInputElement>(null);

    useEffect(() => {
        onStatusChange();
    }, [isDocumentUploadComplete]);

    const onClickHandler = () => {
        if (reference.current) {
            reference.current.value = '';
            reference.current.click();
        }
    };

    const onStatusChange = () => {
        if (isDocumentUploadComplete === true) {
            setdisableButton(true);
            setUploadStatusReset(false);
        }
    };

    const fileSelectHandler = async (event: SyntheticEvent) => {
        if (!name) {
            toast.error(Translate.t('Packages.PackagesCard.InsertNameError'));
            return;
        }

        const target = event.target as HTMLInputElement;

        let documentExtension = '';

        if (target.files) {
            const doc = target.files[0];

            const { type } = doc;

            switch (type) {
                case 'application/pdf': documentExtension = '.pdf'; break;
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }

            if ((documentExtension === ('.png')) || (documentExtension === ('.jpeg')) || (documentExtension === ('.jpg')) || (documentExtension === ('.pdf'))) {
                getUploadUrl(Category.DefectInspection, propertyId, name, documentExtension, doc);
            } else {
                toast.error(Translate.t('Packages.PaymentUpload.Filetypenotsupported'));
            }
        }
    };

    return (

        <UploadContainerMain>

            <RoundInput value={name} placeholder={Translate.t('GeneralUI.Eg')} onChangeText={(e) => setName(e.target.value)} />

            {!attempLoadingUpload ? (
                <div>
                    <InputItemContainer onClick={onClickHandler} key={key} disabled={disableButton}>
                        <UploadIcon
                            src={icons.UploadIcon}
                        />
                        <UploadText>
                            {Translate.t('Packages.VacantPossession.UploadDocument.Upload')}
                            {documentTypeName}
                        </UploadText>

                        <input
                            onChange={(e: SyntheticEvent) => fileSelectHandler(e)}
                            accept='.jpg,.jpeg,.png,application/pdf,'
                            type='file'
                            hidden
                            ref={reference}
                        />
                    </InputItemContainer>
                </div>
            ) : (
                <div>
                    <InputItemContainer onClick={onClickHandler} disabled={disableButton}>
                        <TailSpin color={Colors.primary} height={20} width={125} />
                    </InputItemContainer>
                </div>
            )}
        </UploadContainerMain>

    );
};

const UploadContainerMain = styled.div`
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
`;

const UploadText = styled.p`
    display: block;
`;

const UploadIcon = styled(SVG)`
    display: block;
    margin-right: 5px;
`;

const InputItemContainer = styled.div<{disabled?: boolean }>`
    display: flex;
    width: 100%;
    background: #F2B47E;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
    margin-bottom: 8px;
    ${props => (props.disabled) && css`
    color: ${Colors.greyLightest};
    background-color: ${Colors.greyLight};
    pointerEvents: none;
    cursor: none;
    &:hover{
        opacity: 1;
      };
  `}
`;

const mapStateToProps = (state: RootState) => ({
    attempLoadingUpload: Selectors.packagesgetPackagesUploadUrlAttempting(state),
    isDocumentUploadComplete: Selectors.packagesSetDocumentUploadStatus(state),
    propertyId: Selectors.packagesSetPackagesDocumentUploadId(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) =>
        dispatch(Actions.getPackagesUploadUrlAttempt({ category, id, name, extension, document })),
    setUploadStatusReset: (state: boolean) => dispatch(Actions.setDocumentUploadStatus(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadComponentDefectInspection);
