import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import { create } from 'apisauce';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Translate from 'lib/translate';

import PropertyGateway from 'api/Property';
import { UploadPropertyImageActionPayload } from 'redux/slices/property/types';

export default function* watchUploadPropertyDocument(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/uploadPropertyImagesAttempt', handleUploadDocument, api);
}

function* handleUploadDocument(api: PropertyGateway, data: UploadPropertyImageActionPayload) {
    const { preSignedUrl, document, docType, roomName, category } = data.payload;

    const formData = new FormData();
    formData.append('file', document);

    const s3Api = create({
        baseURL: preSignedUrl,
        headers: {
            'content-type': `application/${docType}`,
        },
    });

    const response = yield* call(s3Api.put, '', document);

    if (!response.ok) {
        toast.error('Error');
        return;
    }

    yield put(Actions.getPropertyUploadUrlSuccess('getting presign url success'));
    yield put(Actions.uploadPropertyImagesSuccess({ name: roomName, url: [URL.createObjectURL(document)], category }));
    yield put(Actions.setPropertyImageUploadComplete(true));

    toast.success(Translate.t('Property.UploadedSuccess'));
}
