const dev = {
    baseUrl: process.env.REACT_APP_BASE_URL,
};

const prod = {
    baseUrl: process.env.REACT_APP_BASE_URL,
};

const dormeoInformations = {
    mapApiKey: process.env.REACT_APP_MAPAPI_KEY,
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

const useMockApi = false;

export default {
    ...config,
    useMockApi,
    dormeoInformations,
};
