export default {
    Ui: {
        UiCareline: 'Contact Our Careline',
        UiBack: 'Back',
        UiCompanyName: 'Dormeo Homes',
        UiSearchTitle: 'buy',
        UiProfileButtonTitle: 'login',
    },

    GeneralUI: {
        BreadcrumblabelDashboard: 'Dashboard',
        BreadcrumblabelPropertyListings: 'Property Listings',
        BreadcrumblabelSetupProcess: 'Setup Process',
        BreadcrumblabelWelcomeBackUser: 'Welcome Back',
        BreadcrumblabelViewDocuments: 'View Documents',

        CollapsibleStatusPendingApproval: 'Pending Approval',
        CollapsibleStatusVacantVacant: 'vacant',
        CollapsibleStatusTenanted: 'tenanted',
        CollapsibleStatusDeactivated: 'deactivated',
        CollapsibleStatusDraftProperty: 'draft Property',
        CollapsibleStatusApproved: 'approved',
        CollapsibleStatusNone: '',
        Eg: 'eg. 123',
        all: 'all',
        UploadDocument: 'Upload Document',
        Noimagesfound: 'No images found',

        Failed: 'failed',
        TryAgain: 'failed! Please try again later',
        SomethingWrong: 'Something went wrong. Please try again.',
        Saved: 'Saved',
        UploadedSuccess: 'Uploaded Success',
        Error: 'Error',
    },

    GeneralMessage: {
        TryAgain: ' Please try again',
        SomethingWrong: 'Something went wrong. Please try again.',
    },

    BreadCrumb: {
        BreadCrumbDashboard: 'Dashboard',
        BreadCrumbWelcomeBack: 'Welcome Back Admin!',
    },

    Login: {
        LoginLabel: 'Login',
        LoginOwnerLogin: 'Admin Login',
        LoginEmail: 'Your email address',
        LoginPlaceholder: 'eg. example123@gmail.com',
        LoginRememberMe: 'Remember me',
        LoginPassword: 'Your password',
        LoginHaveYou: 'Have you',
        LoginForgotPassword: 'forgotten your password?',
        LoginNewTo: 'New to Dormeo?',
        LoginJoinNow: 'Join now',
        LoginErrorEmail: 'Please insert a valid email address',
        LoginBcLogin: 'Login',
        LoginBcOwnerLogin: 'Admin Login',
    },

    Modules: {
        ModulesCreate: 'Create',
        ModulesCreateDesc: 'Add new owner accounts & property listings',
        ModulesReports: 'Reports',
        ModulesReportsDesc: 'Check detailed reports on listings',
        ModulesOwner: 'Owners',
        ModulesOwnerDesc: 'Check current property owner’s details',
        ModulesRea: 'Real Estate Agents',
        ModulesReaDesc: 'Check current REA’s details',
        ModulesPropertyListings: 'Property Listings',
        ModulesPropertyListingsDesc: 'Check current property listing’s details',
        ModulesFinancialReports: 'Financial Reports',
        ModulesFinancialReportsDesc: 'Check current property listing’s financial reports',
        ModulesPayments: 'Payments',
        ModulesPaymentsDesc: 'View all payments',
        ModulesPendingActivities: 'Pending Activities',
        ModulesPendingActivitiesButton: 'Go to Pending Activities page',
        ModulesPendingActivitiesEmpty: 'You have no pending activities',
        ModulesPendingApprovals: 'Pending Approvals',
        ModulesPendingApprovalsButton: 'Go to Approvals page',
        ModulesPendingApprovalsEmpty: 'You have no pending approvals',
        ModulesReviewCoBroke: 'Review co-broke',
        ModulesReviewCoBrokeDesc: 'View all the cobrokes',
        ModulesSubAdmin: 'Sub-Admins',
        ModulesSubAdminDesc: 'Create or view list of sub-admins',
    },

    Modal: {
        ModulesCreateTitle: 'What are you creating?',
        ModulesCreateDesc: 'Please choose the correct option to start creation process.',
        ModulesCreateBtnOwner: 'Owner Account',
        ModulesCreateBtnListing: 'Property Listing',

        TenancyStatusPending: 'Pending',
        TenancyStatusAvailable: 'Available',
        TenancyStatusNotAvailable: 'Not Available',
        TenancyStatusTenanted: 'Tenanted',
        TenancyStatusSelectstatus: 'Select status',
        TenancyStatusProceed: 'Proceed',
        TenancyStatusCancel: 'Cancel',
        TenancyStatusHeader: 'Change Vacancy Status',

    },

    CreateNew: {
        CreateNewBcDashboard: 'Property',
        CreatePropertyStatus: 'Property Created',
        PropertyListingNew: 'Property Listing New',
        CreateNewBcCreate: 'Create',
        CreateNewBcEdit: 'Property Edit',
        CreateNewBcOwner: 'Owner Account Setup Process',
        CreateNewBcError1: 'Please fill and complete the attach properties field and acc details!',
        CreateNewBcDesc: 'Please ensure each step of the process is filled in correctly to ensure no errors.',
        CreateNewStep1: 'step 1:',
        CreateNewOwnerDetails: 'Owner’s Details',
        CreateNewDesc: 'Please fill in the details for the following categories to complete your account setup.',
        CreateNewTitle: 'Personal Details',
        CreateNewLabelTitle: 'Title',
        CreateNewLabelGender: 'Gender',
        CreateNewLabelCPAddress1: 'Corresponding Address 1',
        CreateNewLabelCPAddress2: 'Corresponding Address 2',
        CreateNewLabelFirstName: 'First Name',
        CreateNewLabelLastName: 'Last Name',
        CreateNewLabelIDNo: 'ID No',
        CreateNewLabelCity: 'City/ Town',
        CreateNewLabelPassport: 'Passport No',
        CreateNewLabelMobile: 'Mobile No',
        CreateNewLabelPostcode: 'Postcode',
        CreateNewLabelCountryOrigin: 'Country of Origin',
        CreateNewLabelHouseTel: 'House Tel.',
        CreateNewLabelState: 'State',
        CreateNewLabelRace: 'Race',
        CreateNewLabelCountryResidence: 'Country of Residence',
        CreateNewLabelCountry: 'Country',
        CreateNewLabelDOB: 'Date of Birth',
        CreateNewLabelBankType: 'Bank Type',
        CreateNewLabelCurrency: 'Currency',
        CreateNewLabelBranchAddress: 'Bank Address',
        CreateNewLabelBranchName: 'Bank Name',
        CreateNewLabelBranchAccNo: 'Bank Acc No',
        CreateNewLabelSwiftCode: 'Swift Code',
        CreateNewLabelCompanyName: 'Company Name',
        CreateNewLabelDesignation: 'Company Designation',
        CreateNewLabelOfficeNo: 'Office No.',
        CreateNewLabelFullName: 'Full Name',
        CreateNewLabelRelationship: 'Relationship',
        CreateNewLabelRenNumber: 'REN Number',
        CreateNewLabeCompanyName: 'Company Name',
        CreateNewLabelEmail: 'Email',
        CreateNewErrorEmail: 'Please insert a valid email address',
        CreateNewErrorPass: 'Please insert a valid password',
        CreateNewErrorPassConfirm: 'Password confirmation does not match with password',
        CreateNewFinancialDetails: 'Financial Details',
        CreateNewCompDetails: 'Company Details',
        CreateNewEmergencyDetails: 'Emergency Contact Details',
        CreateNewStep2: 'step 2:',
        CreateNewAttachProp: 'Attach Owner',
        CreateNewNewProj: 'new project',
        CreateNewNoPropFound: 'No Property Found',
        CreateNewStep3: 'step 3:',
        CreateNewStep4: 'step 4:',
        CreateNewStep5: 'step 5',
        CreateNewStep6: 'step 6',
        CreateNewAccDetails: 'Account Details',
        CreateNewBtnSubmit: 'Submit',
        CreateNewBtnClear: 'Clear',
        CreateNewBtnConfirm: 'Confirm',
        CreateNewModalText1: 'This property belongs to ',
        CreateNewModalText2: ', are sure to continue?',
        CreateNewModalBtnYes: 'Yes, continue',
        CreateNewModalBtnNo: 'No, select another',
    },
    OwnersPackageDetailsCheck: {
        Nothing: '',
        Pending: '',
        PendingVerification: '',
        Active: '',
        PaymentRejected: 'PaymentRejected',
        Disabled: 'Disabled',
        FreeTrial: 'FreeTrial',
        purchase: '',
    },

    OwnersReport: {
        OwnersReportBcDashboard: 'Owners',
        OwnersReportBcOwners: 'Owners Listings',
        OwnersReportBcOwnerDetails: 'Owner Details',
        OwnersReportSearchTitle: 'Search for Owner Listing',
        OwnersReportFilterTitle: 'Owner Accounts Results',
        OwnersReportFilterCountry: 'Country',
        OwnersReportFilterLocation: 'Location',
        OwnersReportFilterStatus: 'Status',
        OwnersReportFilterProperties: 'Properties',
        OwnersReportFilterFinance: 'Finance',
        OwnersReportFilterDefault: 'Default',
        OwnersReportSaveSearch: 'Save search',
        OwnersReportHeaderName: 'Name',
        OwnersReportHeaderPackages: 'Packages',
        OwnersReportHeaderAge: 'Age',
        OwnersReportHeaderGender: 'Gender',
        OwnersReportHeaderLocation: 'Location',
        OwnersReportHeaderProp: 'Properties',
        OwnersReportHeaderMonthlyExpense: 'Monthly Expense',
        OwnersReportError1: 'No records found',
        OwnersReportError2: 'If using a custom view, try adjusting the filters.',
        OwnersReportError3: 'Otherwise, create some data!',
        OwnersReportError4: 'Something error',
        OwnersReportError5: 'Please contact system administrator for details.',
        OwnersReportError6: 'Otherwise, please try again!',
        OwnersReportRecentViewed: 'Recently Viewed',
        OwnersReportMoreLocations: 'More Locations',
        OwnersReportButtonMore: 'More',
        OwnersReportManagedProperties: 'Managed Properties',
    },

    PropertyReport: {
        Property: 'Property',
        PropertyReportBcDashboard: 'Property',
        PropertyReportBcProperty: 'Property Listings',
        PropertyReportBcPropertyGroup: 'Property Group Listings',
        PropertyReportFilterTitle: 'All Property Listings',
        PropertyReportFilterTransactions: 'All Property Transactions',
        PropertyReportFilterBuildingTypes: 'All Building Types',
        PropertyReportFilterStatus: 'Status',
        PropertyReportFilterTypes: 'Types',
        PropertyReportFilterGroupPropertiesWithCount: 'Properties',
        PropertyReportFilterActivePacakges: 'By Active Package',
        PropertyReportFilterSize: 'Built-up Size',
        PropertyReportFilterConfig: 'Configurations',
        PropertyReportFilterProp: 'Sort by',
        PropertyReportFilterDefault: 'Default',
        PropertyReportSaveSearch: 'Save search',
        PropertyReportHeaderName: 'Name',
        PropertyReportHeaderStatus: 'Status',
        PropertyReportHeaderSize: 'Size',
        PropertyReportHeaderUnitNumber: 'Unit Number',
        PropertyReportHeaderType: 'Type',
        PropertyReportHeaderConfig: 'Configuration',
        PropertyReportHeaderOwner: 'Owner',
        PropertyReportHeaderActivePackages: 'Active Packages',
        PropertyReportHeaderWallet: 'Wallet',
        PropertyReportHeaderTenancyStatus: 'Tenancy Status',
        PropertyReportHeaderActions: 'Actions',
        PropertyReportHeaderLocation: 'Location',
        PropertyReportError1: 'No records found',
        PropertyReportError2: 'If using a custom view, try adjusting the filters.',
        PropertyReportError3: 'Otherwise, create some data!',
        PropertyReportError4: 'Something error',
        PropertyReportError5: 'Please contact system administrator for details.',
        PropertyReportError6: 'Otherwise, please try again!',
        PropertyReportRecentViewed: 'Recently Viewed',
        PropertyReportMoreLocations: 'More Locations',
        PropertyReportButtonMore: 'More',
        PropertyReportProperty: 'Property',
        PropertyReportTitle: 'Search For Property Listing',
        PropertyReportDuplicateText: 'Duplicate',
        PropertyReportCompleteText: 'Complete',
        PropertyReportCompletedText: 'Completed',
        PropertyRpDMapNoLocation: 'No location',
        PropertyReportEditText: 'Edit',

    },

    PropertyRpTransactions: {
        PropertyRpTransactionDashboard: 'Transactions',
        PropertyRpTransactionBcTransactions: 'Transactions Listings',
        PropertyRpTransactionFailed: 'Updating Transaction Status Failed',
        PropertyRpTransactionpropertyName: 'Property Name',
        PropertyRpTransactionpropertyUnitNo: 'Unit No',
        PropertyRpTransactionpropertyDescription: 'Description',
        PropertyRpTransactionpropertyCreatedAt: 'Created at',
        PropertyRpTransactionpropertyUpdatedAt: 'Updated at',
        PropertyRpTransactionStatus: 'Status',
        PropertyRpTransactionTypes: 'Types',
        PropertyRpTransactionAmount: 'Amount',
        PropertyRpTransactionAction: 'Action',
        PropertyRpTransactionView: 'View',
        PropertyRpTransactionSearchTitle: 'Search by property name',
        PropertyRpTransactionSearchTitleNameSearch: 'search property name',
        PropertyRpTransactionCreateTransaction: 'Create transaction',
        PropertyRpTransactionShowTransaction: 'show transaction',
        PropertyRpTransactionEnterAmount: 'Enter Amount',
        PropertyRpTransactionEnterRemarks: 'Enter Remarks ',
        PropertyRpTransactionuploadDocument: 'Upload document',
        PropertyRpTransactionSuccess: 'Upload Successful',
        PropertyRpTransactionFail: 'Upload Failed',
        PropertyRpTransactionConfirmCreateTransaction: 'Confirm create transaction for',
        PropertyRpTransactionSelectProperty: 'Select a property',
        PropertyRpTransactionMoreThan0: 'Please enter amount more than 0',
        PropertyRpTransactionOnlyNumbers: 'Please enter only numbers',
    },

    PropertyRecurringTransactions: {
        PropertyRecurringTransactionsName: 'Property name',
        PropertyRecurringTransactionsUnit: 'Unit number',
        PropertyRecurringTransactionsOwnerName: 'Owner name',
        PropertyRecurringTransactionsAmount: 'Amount',
        PropertyRecurringTransactionsDescription: 'Description',
        PropertyRecurringTransactionsStatus: 'Status',
        PropertyRecurringTransactionsFrequency: 'Frequency',
        PropertyRecurringTransactionsLastDue: 'Last due',
        PropertyRecurringTransactionsNextDue: 'Next due',
        PropertyRecurringTransactionsType: 'Type',
        PropertyRecurringTransactionsAction: 'Action',
    },

    PropertyRpDetails: {
        PropertyRpDetailsBcDashboard: 'Property',
        PropertyRpDetailsBcPropertyListing: 'Property Listings',
        PropertyRpDetailsTitlePropDetails: 'Property Details',
        PropertyRpDetailsTitleRoomDetails: 'Room Details',
        PropertyRpDetailsTitleAdminControls: 'Admin Controls',
        PropertyRpDetailsBtnApprove: 'Approve Property',
        PropertyRpDetailsBtnReject: 'Reject Property',
        PropertyRpDetailsBtnUpdateTenancyStatus: 'Update Tenancy Status',
        PropertyRpDetailsBtnContact: 'Contact Owner',
        PropertyRpDetailsLabelPropType: 'Property Type',
        PropertyRpDetailsLabelLandTitle: 'Land Title',
        PropertyRpDetailsLabelUnitType: 'Unit Type',
        PropertyRpDetailsLabelPropTitleType: 'Property Title Type',
        PropertyRpDetailsLabelTenure: 'Tenure',
        PropertyRpDetailsLabelStorey: 'Storey',
        PropertyRpDetailsLabelBtSize: 'Built-up Size',
        PropertyRpDetailsLabelBtPrice: 'Built-Up Price',
        PropertyRpDetailsLabelBtDim: 'Built-Up Dimension',
        PropertyRpDetailsLabelRefNo: 'Reference No.',
        PropertyRpDetailsLabelPostedDate: 'Posted Date',
        PropertyRpDetailsLabelFurnishing: 'Furnishing',
        PropertyRpDetailsLabelOccupancy: 'Occupancy',
        PropertyRpDetailsLabelFacingDirection: 'Facing Direction',
        PropertyRpDetailsErrorNoImagesFound: 'No images found',
        PropertyRpDetailsInstruction: 'Scroll horizontally to show other images',
        PropertyRpMessageBuyDefectInspection: 'Please set price for vacant Possession to buy defect inspection',
        PropertyRpMessageTotalMinimum: 'Total price need to be more than 10',
        PropertyRpMessageSetPriceSuccess: 'Service Package Pricing Set',
        PropertyRpMessageSetPriceRejected: 'property has been rejected',
    },

    PaymentsReport: {
        PaymentsRpBcDashboard: 'Payments',
        PaymentsRpBcPayments: 'Payment Listings',
        PaymentsRpTitle: 'Payments',
        PaymentsRpHeaderID: 'ID',
        PaymentsRpHeaderName: 'Property',
        PaymentsRpHeaderUnit: 'Unit No.',
        PaymentsRpHeaderCreatedAt: 'Created At',
        PaymentsRpHeaderTotalPrice: 'Total Price',
        PaymentsRpHeaderDocuments: 'Documents',
        PaymentsRpHeaderStatus: 'Status',
        PaymentsRpHeaderActions: 'Actions',
        PaymentsRpActionButtonApprove: 'Approve',
        PaymentsRpActionButtonReject: 'Reject',
        PaymentsRpApproveLowerCase: 'approve',
        PaymentsRpRejectLowerCase: 'reject',
        PaymentsRpApproveRejectMsg1: 'Are you sure you want to',
        PaymentsRpApproveRejectMsg2: 'this payment?',
    },

    RecentEvent: {
        RecentEvent: 'Recent Events',
        RecentEventParagraph: 'Manage the Events and Notifications for Properties',
        RecentEventHeading: 'Current Event Message Display',
        RecentEventTableTitle: 'Latest Recent Events',
        RecentEventTableButton: 'Add New Event',
        RecentEventTemplateTitle: 'Write an Event Message',
        RecentEventTemplatePair: 'Pair Event Message with Status',
        RecentEventTemplateSaved: 'Save as Event Template',
        RecentEventTemplateButtonClear: 'Clear All',
        RecentEventTemplateButtonPublish: 'Publish',
        RecentEventRecentActivities: 'Recent Activities',
        RecentEventNoRecent: 'You have no recent activities',
        RecentEventViewAll: 'View All',
        RecentEventNoPendingActivities: 'You have no pending activities',
        CreateEditEventMessages: 'Create/Edit Event Messages',
    },

    Cobrokes: {
        CobrokesBcDashboard: 'Approvals',
        CobrokesBcCobrokes: 'Cobrokes',
        CobrokesHeaderEmail: 'Email',
        CobrokesHeaderPropertyName: 'Property Name',
        CobrokesHeaderPropertyId: 'Property ID',
        CobrokesHeaderStatus: 'Status',
        CobrokesHeaderView: 'View',
        CobrokesHeaderAction: 'Action',
        CobrokesFilterTitle: ' Cobrokes Results',
    },

    ReaReport: {
        ReaReportBcDashboard: 'Agents',
        ReaReportBcRea: 'REA',
        ReaReportSearchTitle: 'Search for Real Estate Agent Report',
        ReaReportHeaderEmail: 'Email',
        ReaReportHeaderFirstName: 'First Name',
        ReaReportHeaderLastName: 'Last Name',
        ReaReportHeaderPhoneNo: 'Phone Number',
    },

    ReaDetails: {
        ReaDetailsBcDashboard: 'Dashboard',
        ReaDetailsBcRea: 'REA',
        ReaDetailsBcReaDetails: 'REA Details',
        ReaDetailsTitle1: "Agents's Details",
        ReaDetailsTitle2: "Agents's Managed Properties",
        ReaDetailsNoProperty: 'No Property',
        ReaDetailsViewDocumentBtn: 'View REA Document',
    },

    SubAdmins: {
        SubAdminsBcDashboard: 'Sub-admins',
        SubAdminsBcSubAdmins: 'Sub-admins Listings',
        SubAdminsBtCreateSubAdmin: 'Create Sub-admin',
        SubAdminsHeaderName: 'Name',
        SubAdminsHeaderEmail: 'Email',
        SubAdminsHeaderPhone: 'Phone Number',
        SubAdminsHeaderAction: 'Action',
        SubAdminsSearchTitle: 'Search for Sub-Admins',
        SubAdminsFilterTitle: 'Sub-admin',
        SubAdminsReportError1: 'No records found',
        SubAdminsReportError2: 'If using a custom view, try adjusting the filters.',
        SubAdminsReportError3: 'Otherwise, add some property!',
        SubAdminsReportError4: 'Failed to load data',
        SubAdminsReportError5: 'Please contact system administrator for details.',
        SubAdminsReportError6: 'Otherwise, please try again!',

        SubAdminCreate: {
            SubAdminTitle: 'Sub admin details',
            SubAdminName: 'Name',
            SubAdminEmail: 'Email',
            SubAdminPhone: 'Phone Number',
            SubAdminPassword: 'Password',
            SubAdminConfirmPassword: 'Confirm Password',
        },

        SubAdminProperty: {
            PropertyTitle: 'Property Details',
            PropertyName: 'Property Name',
            PropertyUnit: 'Unit No.',
            PropertyAccessLevel: 'Access Level',
            PropertyAction: 'Action',
            AccessLevelReadOnly: 'Read Only',
            AccessLevelReadWrite: 'Read and Write',
        },

        SubAdminAddProperty: {
            AddNewProperty: 'Add new property',
            AddPropertyTitle: 'Add Property to Sub-Admin',
            AddPropertyLocation: 'Location',
            AddPropertyAction: 'Add',
            AddSelectedProperty: 'Add selected properties',
            AddedProperty: 'Property added!',
            AddAccessLevelConfirm: 'Select access level to add property',
        },
    },

    Packages: {
        BreadcrumblabelServicePackages: 'Service Packages',
        PackageMainTitle: 'Subscribe the Dormeo Homes Complete Service Package for ',
        PackageSubTitle: 'Complete Package',
        PackagesPerMonth: 'per month ',
        AllPackagesBought: 'All Packages Bought',
        Includesall: 'Includes all',
        services: 'services',
        NoPackages: 'no packages available',
        ButtonConfirm: 'CPropertyReportFilterProponfirm',
        RecentEvent: 'Property Recent Events',

        PackagesAlternativeTitle: 'Alternatively',
        PackageChooseTitle: 'Choose the best Dormeo services below.',

        VacantPossessionPoints: 'Vacant points here',
        DefectInspectionPoints: 'Defect points here',
        DesignRenoVationPoints: 'Design & Renovation',
        PropertyManagementPoints: 'Property  management',
        LeaseAndSalePoints: 'Lease points here',
        UpdatePackagesPrices: 'Update Packages Prices',
        SetGoogleDrivePath: 'Set Google Drive Path',
        SetMilestones: 'Set Milestones',

        PackagesCard: {

            QuickNavigation: 'Quick Navigation',
            QuickLink: 'Here are the quick links to our other services',
            CardTitleVacantPossession: 'Vacant Possession',
            CardSubTitleVacantPossession: 'Subtitle 1',
            CardMonthyPricing: 'per month',

            CardTitleDefectInspection: 'Defect Inspection',
            CardSubTitleDefectInspection: 'Subtitle 1',

            CardTitleDesignRenovation: 'Design & Renovation ',
            CardSubTitleDesignRenovation: 'Subtitle 1',

            CardTitlePropertyManagement: 'Property Management ',
            CardSubTitlePropertyManagement: 'Subtitle 1',

            CardTitleLeaseSale: 'Lease & Sale ',
            CardSubTitleLeaseSale: 'Subtitle 1',

            CardTitleVacantManagement: 'Vacant Management ',
            CardSubTitleVacantManagement: 'Subtitle 1',

            PackageBought: 'package bought',
            PackageNotAvailable: 'package not available',

            PackagesBuy: 'Subscribe NOW',

            PackagesSubscribeLabel: 'Subscribe Selected Packages',

            PackagesTitleVacantPossession: 'Vacant Possession Package.',
            PackagesTitleDefectInspection: 'Defect Inspection Package.',
            PackagesTitleDesignRenovation: 'Design Renovation Package.',
            PackagesTitlePropertyManagement: 'Property Management Package.',
            PackagesTitleLeaseSale: 'Lease Sale Package.',

            PackagesPaymentOptions: 'Select your Payment Options',
            BankTransfer: 'Manual Bank Transfer',
            CardTransfer: 'Credit/Debit Card',

            BankTransferTitle: 'Bank Transfer Procedure',
            BodySubTitle: 'Please proceed with bank transfer to:',

            BankName: 'Bank Name',
            BankNumber: 'Bank Number',
            AccountName: 'Account Name',

            PackagesTitleMessage: 'Please ensure the correct amount is transferred into the account.',
            CheckoutSummary: 'Checkout Summary',

            ProceedPayment: 'Proceed with Payment',
            InsertNameError: 'Insert Name Error',

        },

        PaymentUpload: {
            MainTitle: 'Upload Receipt',
            Reference: 'Reference',
            AddNewDocuments: 'Add New Receipt',
            UploadDocument: 'Upload Receipt',
            InsertNameError: 'Insert Name Error',
            Filetypenotsupported: 'File type not supported , accepted formats are pdf and images ',
        },

        VacantPossession: {

            ButtonInfo: {
                StatusNotPurchasedYet: 'not purchased yet',
                StatusPending: 'Pending',
                StatusActive: 'Active',
                StatusPaymentRejected: 'PaymentRejected',
                StatusDisabled: 'Disabled',
                StatusFreeTrial: 'FreeTrial',
                Statuspurchase: 'purchase',
            },

            CollapsibleStatus: {
                StatusPending: 'Property Pending Approval',
                StatusVacant: 'Vacant',
                StatusPropertyTenanted: 'PropertyTenanted',
                StatusPropertyDeactivated: 'PropertyDeactivated',
                StatusDraft: 'Property Draft Property',
                StatusApproved: 'roperty Approved',
            },

            UploadDocument: {
                Document1: 'Document 1',
                Document2: 'Document 2',
                Others: 'Others',
                Reference: 'Reference',
                AddNew: 'Add New',
                Upload: 'Upload',
            },

            UploadVPDocuments: 'Upload VP Documents',
            CheckVPRelatedDocuments: 'Check VP-Related Documents',
            IfNotBought: 'Purchase the Vacant Possession Package for this Property +',

        },

        DefectInspection: {

            ViewBid: 'View Property Bids',
            UploadDefectInspectionDocument: 'Upload Defect Inspection-Related Documents',
            ViewDefectInspectionDocuments: 'Check Defect Inspection-Related Documents',

            IfDefectInspectionNotBought: 'Purchase the Defect Inspection Package for this Property +',
            IfVacantPossessionNotBought: 'Purchase the Vacant Possesion Package as it is dependency for Defect Inspection Package for this Property +',
            IfNotBuyBothPackage: 'Purchase the Vacant Possesion And Defect Inspection Package for this Property +',
            PackageStatus: 'Package Status:',

            UploadDocument: {
                Reference: 'Reference',
                AddNew: 'Add New Document',
                Upload: 'Upload',
            },

        },

        LeaseAndSale: {

            ViewBid: 'View Property Bids',
            UploadLeaseDocument: 'Upload Lease & Sale-Related Documents',
            ViewLeaseDocuments: 'Check Lease & Sale-Related Documents',

            IfLeasePackageNotBought: 'Purchase the Lease & Sale Package for this Property +',
            PackageStatus: 'Package Status:',

            UploadDocument: {
                Reference: 'Reference',
                AddNew: 'Add New Document',
                Upload: 'Upload',
            },

            IfNotBought: 'Purchase the Lease And Sale Package for this Property +',

        },

        ViewDocuments: {
            DateDecending: 'Date Decending',
            DateAscending: 'Date Ascending',

            NameAscendingOrder: 'Name Ascending Order',
            NameDecendingOrder: 'Name Decending Order',

            FileName: 'File Name',
            DateUploaded: 'Date Uploaded',
            Actions: 'Actions',

            NoData: 'No Data Available',

            Back: 'Back',

            TitleMessage: 'Please make sure you select whether to search for tenants or properties.',
            FilterVacantDocuments: 'Filter Documents in Vacant Possession',
            FilterDefectDocuments: 'Filter Documents in Defect Inspection',
            FilterLeaseDocuments: 'Filter Documents in Lease And Sale',
            FilterPropertyManagementDocuments: 'Filter Documents in Property Management',
            FilterVacantManagementDocuments: 'Filter Documents in Vacant  Management',
            ButtonDownloadLabel: 'Download',
            ButtonViewLabel: 'View',

            NoImages: 'No images',
            Documents: 'Documents',

        },

        PropertyManagement: {
            PropertyManagementTransactionTitle: 'Property Management Transactions',
            StatusPending: 'Pending',
            StatusAccepted: 'Accepted',
            StatusRejected: 'Rejected',
            StatusNoStatus: 'No status',

            Withdrawal: {
                withdrawalDashboard: 'Withdrawal',
                withdrawalBc: 'Withdrawal Requests',
                withdrawalSearchTitle: 'Search by property information or owner information.',
                withdrawalSearchBoxTitle: 'Search',
                withdrawalReportTitle: 'All Property Management Withdrawal Requests',
                withdrawalReportFilterStatusTitle: 'Status',
                withdrawalReportFilterSortTitle: 'Sort',
                TableHeader: {
                    propertyName: 'Property Name',
                    unitNo: 'Unit No',
                    propertyOwner: 'Owner',
                    withdrawalAmount: 'Amount',
                    withdrawalStatus: 'Status',
                    dateCreated: 'Date Created',
                    action: 'Action',
                },
            },
        },

        MYR: 'MYR',

    },

    Property: {
        Saved: 'saved',
        UploadedSuccess: 'Uploaded Success',
        PropertyNo: 'no',
        PropertyRecentEvents: 'Recent Events',
        PropertyRecentEventsNav: 'Go to Defect Infection page',
        PropertyRecentEventsInfo: 'You have no recent events',
        PropertyVacantPossesion: 'Vacant Possesion',
        PropertyDefectInspection: 'Defect Inspection',
        PropertyDesignRenovation: 'Design Renovation',
        PropertyManagement: 'Property Management',
        PropertyLeaseSale: 'Lease & Sale',
        PropertyPage: 'Property Page',
        PropertyNoProperty: 'You currently have no attached properties. Add one now!',
        PropertyAddNewProperty: 'Add New Property',
        PropertyPendingActivites: 'Pending Activites',
        PropertyPendingActivitesNav: 'Go to Pending Activities page',
        PropertyPendingActivitesInfo: 'You have no recent activites',
        PropertyError: '⚠️ The server encountered an error and could not complete your request.',
        PropertyPendingApproval: 'Pending Approval',
        PropertyVacant: 'Vacant',
        PropertyTenanted: 'Tenanted',
        PropertyDeactivated: 'Deactivated',
        PropertyDraftProperty: 'Draft Property',
        PropertyApproved: 'Approved',

        PropertySubmitButton: 'Submit',
        PropertyConfirmButton: 'Confirm',
        PropertyClearButton: 'Clear',
        PropertyRemoveButton: 'Remove',
        PropertySubmitted: 'Submitted for approval',
        PropertyPackagePriceUpdated: 'Packages Price Updated!',

        PropertyCreate: {
            PropertyTitleMessage: 'Please ensure each step of the process is filled in correctly to ensure no errors.',
            PropertyConfirmButton: 'Confirm',
            PropertyName: 'Property Name',
        },

        AttachOwner: {
            AttachOwner: 'Attach Owner',
            PleaseSelectOwner: 'Please select a Owner to continue',
            OnlyOneOwner: 'Please only attach one Owner or Clear current Owner',
        },

        TermsAndConditions: {
            CollapsibleTitle: 'Terms And Conditions',
            CollapsiblePretext: 'Step 1:',
            ConfirmButtonLabel: 'I agree with the Terms & Conditions.',
        },

        Location: {
            TitleMessage: 'Please provide a description for your property.',
            CollapsibleTitle: 'Add Location',
            CollapsiblePretext: 'Step 2:',
            PlacesSearchBox: 'Places Search Box',
            SearchBox: 'Search Box',
        },

        PropertyDetails: {
            TitleMessage: 'Property Type',
            CollapsibleTitle: 'Property Details',
            CollapsiblePretext: 'Step 3:',
            PropertyTypeLanded: 'Landed',
            PropertyTypeCondo: 'Condo',
            PropertyTypeRoom: 'Room',
            PropertyType: 'Property Type',
            UnitType: 'Unit Type',
            TypeApartment: 'Apartment',
            TypeLanded: 'Landed',

            TitleTypeOptionsStrata: 'Strata',
            TitleTypeOptionsMaster: 'Master',
            TitleTypeOptionsIndividual: 'Individual',

            tenureOptionsFreehold: 'Freehold',
            tenureOptionsLeaseHold: 'LeaseHold',

            LandTitleOptionsResidential: 'Residential',
            LandTitleOptionsCommercial: 'Commercial',

            preferredTenantOptionsChinese: 'Chinese',
            preferredTenantOptionsIndian: 'Indian',
            preferredTenantOptionsMalay: 'Malay',
            preferredTenantOptionsOthers: 'Others',

            furnishingOptionFully: 'Fully',
            furnishingOptionPartial: 'Partial',
            furnishingOptionNone: 'None',

            LandTitleLabel: 'LandTitle',
            PreferredTenantLabel: 'Preferred Tenant',
            PropertyTitleTypeLabel: 'Property Title Type',
            PreferredTenant: 'Preferred Tenant',
            TenureLabel: 'Tenure',
            UnitTypeLabel: 'Unit Type',
            BuildUpSizeLabel: 'BuildUpSize',
            BuiltupPriceperSqftLabel: 'Built-up Price per sq. ft.',
            BuiltUpDimensionSqftLabel: 'Built Up Dimension sq. ft.',
            StoreyLabel: 'Storey',
            LandAreaSizeLabel: 'Land Area Size',
            FurnishingLabel: 'Furnish',
            OccupancyLabel: 'Occupancy',
            FacingDirectionLabel: 'Facing Direction',
            UnitNumberLabel: 'unit number',

            UnitTypePlaceHolder: 'Corner Unit',
            OccupancyPlaceHolder: 'Tenanted',
            FacingDirectionPlaceHolder: 'South East',
        },

        PropertyAmenities: {
            ItemTitleAmenities: 'Amenities',
            ItemTitleFurnishing: 'Furnishing',
            ItemTitleFacilities: 'Facilities',
            CollapsibleTitle: 'Property Amenities',
            CollapsiblePretext: 'Step 4:',

            TelevisionLabel: 'Television',
            CurtainLabel: 'Curtain',
            MattressLabel: 'Mattress',
            WashingMachineLabel: 'Washing Machine',
            HoodHubLabel: 'Hood & Hub',
            AirConditionerLabel: 'Air-Conditioner',
            WaterHeaterLabel: 'Water Heater',
            DiningTableLabel: 'Dining Table',
            WardrobeLabel: 'Wardrobe',
            KitchenCabinetLabel: 'Kitchen Cabinet',
            OvenLabel: 'Oven',
            RefrigeratorLabel: 'Refrigerator',
            SofaLabel: 'Sofa',
            MicrowaveLabel: 'Microwave',
            BedframeLabel: 'Bedframe',
            InternetLabel: 'Internet',

            CoveredParkingLabel: 'Covered Parking',
            GymnasiumLabel: 'Gymnasium',
            BasketballCourtLabel: 'Basketball Court',
            RestaurantLabel: 'Restaurant',
            DobiLabel: 'Dobi / Laundromat',
            NurseryLabel: 'Nursery',
            PlaygroundLabel: 'Playground',
            SaunaLabel: 'Sauna',
            SwimmingPoolLabel: 'Swimming Pool',
            TennisCourtLabel: 'Tennis Court',
            SecurityLabel: '24-Hour Security',
            MiniMartLabel: 'Mini Mart',
            SquashCourtLabel: 'Squash Court',
            BadmintonCourtLabel: 'Badminton Court',
            ElevatorLabel: 'Elevator',
        },

        PropertyImages: {
            ItemTitle: 'You are required to provide photos of each rooms in your property.',
            CollapsibleTitle: 'Add Images',
            CollapsiblePretext: 'Step 5:',
            NewRoomLabel: 'Add another room',
            RoomNamePlaceHolder: 'Room name here',
            NameToastError: 'Insert Name Error',
            DeleteImage: 'Image deleted successfully!',
        },

        PropertyDescription: {
            ItemTitle: 'Please provide a description for your property.',
            CollapsibleTitle: 'Property Description',
            CollapsiblePretext: 'Step 6:',
            InputPlaceHolder: 'Write your property description here',
        },

        PropertyViewDetails: {
            BreadcrumblabelDashboard: 'Details Dashboard',
            BreadcrumblabelPropertyListing: 'Details Property Listing',
            referenceNumber: 'reference Number',
            PostedDate: 'Posted Date',
            TitleRoomDetails: 'Room Details',
            PropertyDetailsInstruction: 'Property Details Instruction',
            NoImagesFound: 'No images found',
        },

        ToPackagesSetup: 'Dormeo Homes Service Packages Setup',
    },
};
