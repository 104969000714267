import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILeaseSale, IPackageTypeEnum } from 'entities/packages';

import {
    IDefectInspection,
    IGetEventTemplate,
    IPaginatedViewAllRecurringPMTransactionResponse,
    IPropertyManagement,
    IVacantManagement,
    IVacantPossession,
    PaginatedViewAllPMTransactionsResponse,
    PropertyManagementTransactionResponse,
    IPaginatedAdminPropertyManagementWithdrawalResponse,
} from '@dm/types';

import { PackagesReduxState, CreateEventActionPayload, SetIPackagesDataSortPayload, UploadPackagesDocumentActionPayload, GetPackagesUploadUrlActionPayload,
    SetIVacantPossesionSortPayload, SetIPackagesPropertyManagementSortPayload, SetIPackagesViewAllTransactionSortPayload, SetUpdateTransactionStatusPayload,
    SetCreatePMTransactionPayload, EditIPropertyManagementWithdrawalPayload, SetCreatePMRecurringTransactionPayload, SetIDefectInspectionSortPayload,
    SetEditRecurringTransactionPayload, SetEditTransactionPayload, UpdateProgressDefectInspectionPayload, GetIPropertyManagementWithdrawalPayload } from './types';

const initialState: PackagesReduxState = {
    actions: {
        createEvent: false,
        getEventTemplate: false,
        getpackagesLeaseAndSaleDataSort: false,
        getPackagesUploadUrl: false,
        uploadPackagesDocument: false,
        getpackagesDataSort: false,
        getPackageDefectInspectionSort: false,
        getVacantManagementPackagesDataSort: false,
        getPropertyManagementPackagesDataSort: false,
        getpackagesPropertyManagementDataSort: false,
        packagesGetIViewAllTransactionsResponse: false,
        packagesGetRecurringTransactionsResponse: false,
        updateTransactionStatus: false,
        editTransaction: false,
        editRecurringTransaction: false,
        createPMTransaction: false,
        createPMRecurringTransaction: false,
        updateProgressDefectInspection: false,
        getPropertyManagementWithdrawal: true,
        editPropertyManagementWithdrawal: true,
    },
    eventTemplates: [],
    selectedRecentEvent: IPackageTypeEnum.General,
    leaseSaleModal: false,
    documentUploadId: '',
    IPackagesLeaseSaleDataDetailsSort: null,
    packagesDocumentView: false,
    PackagesDocumentUploadURL: '',
    documentUploadStatus: false,
    documentUploadIdPackages: '',
    propertyManagementTransactionUploadDocumentCheckData: '',
    vacantPossesionModal: false,
    IPackagesVacantPossessionSort: null,
    IPackagesDefectInspectionSort: null,
    IPackagesVacantManagementSort: null,
    IPackagesPropertyManagementSort: null,
    IPackagesPropertyManagementDataDetailsSort: null,
    IPackagesGetIViewAllTransactionsResponse: null,
    IPackagesGetRecurringTransactionsResponse: null,
    updateTransactionStatusComplete: null,
    editTransactionComplete: null,
    editRecurringTransactionComplete: null,
    createPMTransactionComplete: null,
    negativeBalance: 0,
    dataToCreateTransaction: null,
    isNegativeBalanceModalOpen: false,
    propertyManagementWithdrawal: {
        index: 1,
        maxIndex: 1,
        data: [],
    },
    error: {
        createEvent: '',
        getEventTemplate: '',
        getpackagesLeaseAndSaleDataSort: '',
        getPackagesUploadUrl: '',
        uploadPackagesDocument: '',
        getpackagesDataSort: '',
        getPackageDefectInspectionSort: '',
        getVacantManagementPackagesDataSort: '',
        getPropertyManagementPackagesDataSort: '',
        getpackagesPropertyManagementDataSort: '',
        packagesGetIViewAllTransactionsResponse: '',
        packagesGetRecurringTransactionsResponse: '',
        updateTransactionStatus: '',
        editTransaction: '',
        editRecurringTransaction: '',
        createPMTransaction: '',
        createPMRecurringTransaction: '',
        updateProgressDefectInspection: '',
        getPropertyManagementWithdrawal: '',
        editPropertyManagementWithdrawal: '',
    },
};

const packagesSlice = createSlice({
    name: 'packages',
    initialState,
    reducers: {
        createEventAttempt: (state, _action: CreateEventActionPayload) => {
            state.actions.createEvent = true;
            state.error.createEvent = '';
        },
        createEventSuccess: (state) => {
            state.actions.createEvent = false;
            state.error.createEvent = '';
        },
        createEventFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createEvent = false;
            if (action.payload) {
                state.error.createEvent = action.payload;
            }
        },
        setSelectedRecentEvent: (state, action: PayloadAction<IPackageTypeEnum>) => {
            state.selectedRecentEvent = action.payload;
        },
        getEventTemplateAttempt: (state, _action: PayloadAction<number>) => {
            state.actions.getEventTemplate = true;
            state.error.getEventTemplate = '';
        },
        getEventTemplateSuccess: (state, action: PayloadAction<IGetEventTemplate[]>) => {
            state.actions.getEventTemplate = false;
            state.error.getEventTemplate = '';

            if (action.payload) {
                state.eventTemplates = action.payload;
            }
        },
        getEventTemplateFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getEventTemplate = false;
            if (action.payload) {
                state.error.getEventTemplate = action.payload;
            }
        },

        setLeaseSaleModal: (state, action: PayloadAction<boolean>) => {
            state.leaseSaleModal = action.payload;
        },

        setCreateDocumentUploadID: (state, action: PayloadAction<string>) => {
            state.documentUploadId = action.payload;
        },

        packagesGetILeaseAndSaleDataSortAttempt: (state, _action: SetIPackagesDataSortPayload) => {
            state.actions.getpackagesLeaseAndSaleDataSort = true;
            state.error.getpackagesLeaseAndSaleDataSort = '';
        },
        packagesGetILeaseAndSaleDataSortSuccess: (state, action: PayloadAction<ILeaseSale | null>) => {
            state.actions.getpackagesLeaseAndSaleDataSort = false;
            state.IPackagesLeaseSaleDataDetailsSort = action.payload;
        },
        packagesGetILeaseAndSaleDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getpackagesLeaseAndSaleDataSort = false;
            if (action.payload) {
                state.error.getpackagesLeaseAndSaleDataSort = action.payload;
            }
        },

        setPackagesDocumentViewModal: (state, action: PayloadAction<boolean>) => {
            state.packagesDocumentView = action.payload;
        },

        getPackagesUploadUrlAttempt: (state, _action: GetPackagesUploadUrlActionPayload) => {
            state.actions.getPackagesUploadUrl = true;
            state.error.getPackagesUploadUrl = '';
        },
        getPackagesUploadUrlSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getPackagesUploadUrl = false;
            state.error.getPackagesUploadUrl = '';

            state.actions.createPMTransaction = false;

            state.PackagesDocumentUploadURL = action.payload;
        },
        getPackagesUploadUrlFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackagesUploadUrl = false;
            if (action.payload) {
                state.error.getPackagesUploadUrl = action.payload;
            }
        },

        uploadPackagesDocumentAttempt: (state, _action: UploadPackagesDocumentActionPayload) => {
            state.actions.uploadPackagesDocument = true;
            state.error.uploadPackagesDocument = '';
        },
        uploadPackagesDocumentSuccess: (state) => {
            state.actions.uploadPackagesDocument = false;
            state.error.uploadPackagesDocument = '';
        },

        uploadPackagesDocumentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadPackagesDocument = false;
            if (action.payload) {
                state.error.uploadPackagesDocument = action.payload;
            }
        },

        setDocumentUploadStatus: (state, action: PayloadAction<boolean>) => {
            state.documentUploadStatus = action.payload;
        },

        setCreateDocumentUploadIDLease: (state, action: PayloadAction<string>) => {
            state.documentUploadIdPackages = action.payload;
        },

        setVacantPossesionModal: (state, action: PayloadAction<boolean>) => {
            state.vacantPossesionModal = action.payload;
        },

        packagesGetIVacantPossessionDataSortAttempt: (state, _action: SetIVacantPossesionSortPayload) => {
            state.actions.getpackagesDataSort = true;
            state.error.getpackagesDataSort = '';
        },
        packagesGetIVacantPossessionDataSortSuccess: (state, action: PayloadAction<IVacantPossession >) => {
            state.actions.getpackagesDataSort = false;
            state.IPackagesVacantPossessionSort = action.payload;
        },
        packagesGetIVacantPossessionDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getpackagesDataSort = false;
            if (action.payload) {
                state.error.getpackagesDataSort = action.payload;
            }
        },
        packageGetIDefectInspectionDataSortAttempt: (state, _action: SetIDefectInspectionSortPayload) => {
            state.actions.getPackageDefectInspectionSort = true;
            state.error.getPackageDefectInspectionSort = '';
        },
        packagesGetIDefectInspectionDataSortSuccess: (state, action: PayloadAction<IDefectInspection >) => {
            state.actions.getPackageDefectInspectionSort = false;
            state.IPackagesDefectInspectionSort = action.payload;
        },
        packagesGetIDefectInspectionDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackageDefectInspectionSort = false;
            if (action.payload) {
                state.error.getPackageDefectInspectionSort = action.payload;
            }
        },

        packagesGetIVacantManagementDataSortAttempt: (state, _action: SetIVacantPossesionSortPayload) => {
            state.actions.getVacantManagementPackagesDataSort = true;
            state.error.getVacantManagementPackagesDataSort = '';
        },
        packagesGetIVacantManagementDataSortSuccess: (state, action: PayloadAction<IVacantManagement>) => {
            state.actions.getVacantManagementPackagesDataSort = false;
            state.IPackagesVacantManagementSort = action.payload;
        },
        packagesGetIVacantManagementDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getVacantManagementPackagesDataSort = false;
            if (action.payload) {
                state.error.getVacantManagementPackagesDataSort = action.payload;
            }
        },

        packagesGetIPropertyManagementDataSortAttempt: (state, _action: SetIVacantPossesionSortPayload) => {
            state.actions.getPropertyManagementPackagesDataSort = true;
            state.error.getPropertyManagementPackagesDataSort = '';
        },
        packagesGetIPropertyManagementDataSortSuccess: (state, action: PayloadAction<IPropertyManagement>) => {
            state.actions.getPropertyManagementPackagesDataSort = false;
            state.IPackagesPropertyManagementSort = action.payload;
        },
        packagesGetIPropertyManagementDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyManagementPackagesDataSort = false;
            if (action.payload) {
                state.error.getPropertyManagementPackagesDataSort = action.payload;
            }
        },

        packagesGetIPropertyManagementSortAttempt: (state, _action: SetIPackagesPropertyManagementSortPayload) => {
            state.actions.getpackagesPropertyManagementDataSort = true;
            state.error.getpackagesPropertyManagementDataSort = '';
        },
        packagesGetIPropertyManagementSortSuccess: (state, action: PayloadAction<PropertyManagementTransactionResponse[] | null>) => {
            state.actions.getpackagesPropertyManagementDataSort = false;
            state.IPackagesPropertyManagementDataDetailsSort = action.payload;
        },
        packagesGetIPropertyManagementDataFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getpackagesPropertyManagementDataSort = false;
            if (action.payload) {
                state.error.getpackagesPropertyManagementDataSort = action.payload;
            }
        },

        packagesGetIViewAllTransactionsResponseAttempt: (state, _action: SetIPackagesViewAllTransactionSortPayload) => {
            state.actions.packagesGetIViewAllTransactionsResponse = true;
            state.error.packagesGetIViewAllTransactionsResponse = '';
            state.error.updateTransactionStatus = '';
        },
        packagesGetIViewAllTransactionsResponseSuccess: (state, action: PayloadAction<PaginatedViewAllPMTransactionsResponse | null>) => {
            state.actions.packagesGetIViewAllTransactionsResponse = false;
            state.IPackagesGetIViewAllTransactionsResponse = action.payload;
        },
        packagesGetIViewAllTransactionsResponseFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.packagesGetIViewAllTransactionsResponse = false;
            if (action.payload) {
                state.error.packagesGetIViewAllTransactionsResponse = action.payload;
            }
        },
        packagesGetRecurringTransactionsResponseAttempt: (state, _action: SetIPackagesViewAllTransactionSortPayload) => {
            state.actions.packagesGetRecurringTransactionsResponse = true;
            state.error.packagesGetRecurringTransactionsResponse = '';
            state.error.updateTransactionStatus = '';
        },
        packagesGetRecurringTransactionsResponseSuccess: (state, action: PayloadAction<IPaginatedViewAllRecurringPMTransactionResponse | null>) => {
            state.actions.packagesGetRecurringTransactionsResponse = false;
            state.IPackagesGetRecurringTransactionsResponse = action.payload;
        },
        packagesGetRecurringTransactionsResponseFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.packagesGetRecurringTransactionsResponse = false;
            if (action.payload) {
                state.error.packagesGetRecurringTransactionsResponse = action.payload;
            }
        },

        packagesUpdateTransactionStatusAttempt: (state, _action: SetUpdateTransactionStatusPayload) => {
            state.actions.updateTransactionStatus = true;
            state.error.updateTransactionStatus = '';
        },
        packagesUpdateTransactionStatusSuccess: (state, action: PayloadAction<string | null>) => {
            state.actions.updateTransactionStatus = false;
            state.updateTransactionStatusComplete = action.payload;
        },
        packagesUpdateTransactionStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateTransactionStatus = false;
            if (action.payload) {
                state.error.updateTransactionStatus = action.payload;
            }
        },

        packagesEditTransactionAttempt: (state, _action: SetEditTransactionPayload) => {
            state.actions.editTransaction = true;
            state.error.editTransaction = '';
        },
        packagesEditTransactionSuccess: (state, action: PayloadAction<string | null>) => {
            state.actions.editTransaction = false;
            state.editTransactionComplete = action.payload;
        },
        packagesEditTransactionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editTransaction = false;
            if (action.payload) {
                state.error.editTransaction = action.payload;
            }
        },
        packagesEditRecurringTransactionAttempt: (state, _action: SetEditRecurringTransactionPayload) => {
            state.actions.editRecurringTransaction = true;
            state.error.editRecurringTransaction = '';
        },
        packagesEditRecurringTransactionSuccess: (state, action: PayloadAction<string | null>) => {
            state.actions.editRecurringTransaction = false;
            state.editRecurringTransactionComplete = action.payload;
        },
        packagesEditRecurringTransactionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editRecurringTransaction = false;
            if (action.payload) {
                state.error.editRecurringTransaction = action.payload;
            }
        },

        packagesCreatePMTransactionAttempt: (state, _action: SetCreatePMTransactionPayload) => {
            state.actions.createPMTransaction = true;
            state.error.createPMTransaction = '';
        },
        packagesCreatePMTransactionSuccess: (state, action: PayloadAction<{ id: string } | null>) => {
            state.actions.createPMTransaction = false;
            state.createPMTransactionComplete = action.payload;

            state.isNegativeBalanceModalOpen = initialState.isNegativeBalanceModalOpen;
        },
        packagesCreatePMTransactionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createPMTransaction = false;
            if (action.payload) {
                state.error.createPMTransaction = action.payload;
            }
            state.isNegativeBalanceModalOpen = initialState.isNegativeBalanceModalOpen;
        },

        packagesCreatePMRecurringTransactionAttempt: (state, _action: SetCreatePMRecurringTransactionPayload) => {
            state.actions.createPMRecurringTransaction = true;
            state.error.createPMRecurringTransaction = '';
        },
        packagesCreatePMRecurringTransactionSuccess: (state, action: PayloadAction<{ id: string } | null>) => {
            state.actions.createPMRecurringTransaction = false;
            state.createPMTransactionComplete = action.payload;
        },
        packagesCreatePMRecurringTransactionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createPMRecurringTransaction = false;
            if (action.payload) {
                state.error.createPMRecurringTransaction = action.payload;
            }
        },
        packagesUpdateProgressDefectInspectionAttempt: (state, _action: UpdateProgressDefectInspectionPayload) => {
            state.actions.updateProgressDefectInspection = true;
            state.error.updateProgressDefectInspection = '';
        },
        packagesUpdateProgressDefectInspectionSuccess: (state) => {
            state.actions.updateProgressDefectInspection = false;
        },
        packagesUpdateProgressDefectInspectionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateProgressDefectInspection = false;
            if (action.payload) {
                state.error.updateProgressDefectInspection = action.payload;
            }
        },
        clearPackagesData: (state) => {
            state.actions.getPackagesUploadUrl = false;
            state.error.getPackagesUploadUrl = '';
            state.createPMTransactionComplete = null;
        },

        setPropertyManagementTransactionUploadDocumentCheck: (state, action: PayloadAction<string>) => {
            state.propertyManagementTransactionUploadDocumentCheckData = action.payload;
        },

        setNegativeBalance: (state, action: PayloadAction<number>) => {
            state.negativeBalance = action.payload;
        },

        setDataToCreateTransaction: (state, action: SetCreatePMTransactionPayload) => {
            state.dataToCreateTransaction = action.payload;
        },
        setIsNegativeBalanceModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isNegativeBalanceModalOpen = action.payload;
            state.actions.createPMTransaction = false;
            state.actions.createPMRecurringTransaction = false;
        },

        getPropertyManagementWithdrawalSuccess: (state, action: PayloadAction<IPaginatedAdminPropertyManagementWithdrawalResponse>) => {
            state.actions.getPropertyManagementWithdrawal = false;

            if (action.payload) {
                state.propertyManagementWithdrawal = action.payload;
            }
        },

        getPropertyManagementWithdrawalAttempt: (state, _action: GetIPropertyManagementWithdrawalPayload) => {
            state.actions.getPropertyManagementWithdrawal = true;
            state.error.getPropertyManagementWithdrawal = '';
        },

        getPropertyManagementWithdrawalFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyManagementWithdrawal = false;

            if (action.payload) {
                state.error.getPropertyManagementWithdrawal = action.payload;
            }
        },

        editPropertyManagementWithdrawalAttempt: (state, _action: EditIPropertyManagementWithdrawalPayload) => {
            state.actions.editPropertyManagementWithdrawal = true;
            state.error.editPropertyManagementWithdrawal = '';
        },

        editPropertyManagementWithdrawalSuccess: (state, _action: PayloadAction<string>) => {
            state.actions.editPropertyManagementWithdrawal = false;
        },

        editPropertyManagementWithdrawalFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editPropertyManagementWithdrawal = false;

            if (action.payload) {
                state.error.editPropertyManagementWithdrawal = action.payload;
            }
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: packagesSlice.actions,
    reducers: packagesSlice.reducer,
};
