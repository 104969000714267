import { GetOwnerDetailsResponse } from '@dm/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISubAdminPropertyWithId, ISubAdminsReport } from 'entities/subadmins';
import Selectors from './Selectors';

import { OwnersReduxState, CreateNewSubAdminActionPayload, GetAllSubAdminActionPayload, SetAssignPropertyActionPayload, DeletePropertyActionPayload } from './types';

const initialState: OwnersReduxState = {
    actions: {
        createNewSubAdmin: false,
        getSubAdminsReport: false,
        setSubAdminProperty: false,
        deleteSubAdminProperty: false,
    },
    propertyListData: {
        userId: '',
        access: [{
            propertyId: '',
            propertyName: '',
            unitNo: '',
            accessLevel: 0,
        }],
    },
    subAdminsReport: {
        index: 1,
        maxIndex: 1,
        data: [],
    },
    createSubAdminModal: false,
    viewSubAdminPropertyModal: false,
    viewAddPropertyModal: false,
    error: {
        createNewSubAdmin: '',
        getSubAdminsReport: '',
        setSubAdminProperty: '',
        deleteSubAdminProperty: '',
    },
};

const subAdminSlice = createSlice({
    name: 'subadmins',
    initialState,
    reducers: {
        setCreateSubAdminModal: (state, action: PayloadAction<boolean>) => {
            state.createSubAdminModal = action.payload;
        },
        setViewSubAdminPropertyModal: (state, action: PayloadAction<boolean>) => {
            state.viewSubAdminPropertyModal = action.payload;
        },
        setViewAddPropertyModal: (state, action: PayloadAction<boolean>) => {
            state.viewAddPropertyModal = action.payload;
        },
        subAdminCreateNewAttempt: (state, _action: CreateNewSubAdminActionPayload) => {
            state.actions.createNewSubAdmin = true;
            state.error.createNewSubAdmin = '';
        },
        subAdminCreateNewSuccess: (state) => {
            state.actions.createNewSubAdmin = false;
        },
        subAdminCreateNewFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createNewSubAdmin = false;
            if (action.payload) {
                state.error.createNewSubAdmin = action.payload;
            }
        },
        subAdminGetAllAttempt: (state, _action: GetAllSubAdminActionPayload) => {
            state.actions.getSubAdminsReport = true;
            state.error.getSubAdminsReport = '';
        },
        subAdminGetAllSuccess: (state, action: PayloadAction<ISubAdminsReport>) => {
            state.actions.getSubAdminsReport = false;
            state.subAdminsReport = action.payload;
        },
        subAdminGetAllFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSubAdminsReport = false;
            if (action.payload) {
                state.error.getSubAdminsReport = action.payload;
            }
        },
        setAssignPropertyAttempt: (state, _action: SetAssignPropertyActionPayload) => {
            state.actions.setSubAdminProperty = true;
            state.error.setSubAdminProperty = '';
        },

        setAssignPropertySuccess: (state) => {
            state.actions.setSubAdminProperty = false;
            state.error.setSubAdminProperty = '';
            state.viewAddPropertyModal = false;
            state.viewSubAdminPropertyModal = false;
        },

        setAssignPropertyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setSubAdminProperty = false;
            if (action.payload) {
                state.error.setSubAdminProperty = action.payload;
            }
        },
        deletePropertyAttempt: (state, _action: DeletePropertyActionPayload) => {
            state.actions.deleteSubAdminProperty = true;
            state.error.deleteSubAdminProperty = '';
        },

        deletePropertySuccess: (state) => {
            state.actions.deleteSubAdminProperty = false;
            state.error.deleteSubAdminProperty = '';
            // if (!state.actions.deleteSubAdminProperty) {
            //     state.viewSubAdminPropertyModal = false;
            // }
        },

        deletePropertyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteSubAdminProperty = false;
            if (action.payload) {
                state.error.deleteSubAdminProperty = action.payload;
            }
        },

        setPropertyList: (state, action: PayloadAction<ISubAdminPropertyWithId>) => {
            state.propertyListData = action.payload;
        },

        clearSubAdminsReport: (state) => {
            state.subAdminsReport = {
                index: 1,
                maxIndex: 1,
                data: [],
            };
        },

        clearPropertyList: (state) => {
            state.propertyListData = {
                userId: '',
                access: [],
            };
        },

    },
});

export type InitState = typeof initialState;

export default {
    actions: subAdminSlice.actions,
    reducers: subAdminSlice.reducer,
};
