import React, { useState, useEffect } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { Button } from '@dm/bigfish';
import { PropertyManagementTransactionStatus } from '@dm/types';

// ! interface zone
// * props interface
export interface ComfirmRefundModalProps {
    propertyId: string;
    transactionId: string;
    propertyName: string;
    unitNo: string;
    amount: number;
    isConfirmRefundModalOpen: boolean;
    setIsConfirmRefundModalOpen: (state: boolean) => void;

    setUpdateTransactionStatusAttempt: boolean;
    setUpdateTransactionStatusFailure: string;
    setPackagesUpdateTransactionStatusAttempt: (
        propertyId: string,
        transactionId: string,
        status: number,
    ) => void;
}

// ! JSX function component
const ConfirmRefundModal = (props: ComfirmRefundModalProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        propertyId,
        transactionId,
        propertyName,
        unitNo,
        amount,
        isConfirmRefundModalOpen,
        setIsConfirmRefundModalOpen,
        setUpdateTransactionStatusAttempt,
        setUpdateTransactionStatusFailure,
        setPackagesUpdateTransactionStatusAttempt,
    } = props;

    // ! functions
    const confirmRefundHandler = () => {
        setPackagesUpdateTransactionStatusAttempt(propertyId, transactionId, PropertyManagementTransactionStatus.Reverted);
    };

    // ! render zone
    // * main render
    return (
        <Modal
            height='fit-content'
            width='500px'
            show={isConfirmRefundModalOpen}
            closeCursor
            onClickClose={() => setIsConfirmRefundModalOpen(!isConfirmRefundModalOpen)}
        >
            <ModalContainer>
                <Title>Confirm Revert</Title>
                <ModalContent>
                    <Text>Are you sure you want to revert for the transaction below?</Text>
                    <ModalSubContent>
                        <Text>
                            Property Name:
                            {' '}
                            <strong>{propertyName}</strong>
                        </Text>
                        <Text>
                            Unit No:
                            {' '}
                            <strong>{unitNo ?? 'N/A'}</strong>
                        </Text>
                        <Text>
                            Amount:
                            {' '}
                            <strong>
                                RM
                                {amount}
                            </strong>
                        </Text>
                    </ModalSubContent>
                </ModalContent>
                <ModalFooter>
                    <Button
                        label='Confirm'
                        disabled={setUpdateTransactionStatusAttempt}
                        loading={setUpdateTransactionStatusAttempt}
                        style={{ cursor: 'pointer' }}
                        onClick={confirmRefundHandler}
                    />
                    <Button
                        label='Cancel'
                        onClick={() => setIsConfirmRefundModalOpen(!isConfirmRefundModalOpen)}
                    />
                </ModalFooter>
                <Text style={{ color: 'red', marginTop: '10px' }}>
                    {setUpdateTransactionStatusFailure}
                </Text>
            </ModalContainer>
        </Modal>
    );
};

// ! styled-components
const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
`;
const Text = styled.p`
    font-size: 16px;
`;
const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const ModalContent = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;
const ModalSubContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;
const ModalFooter = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    
`;
// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    isConfirmRefundModalOpen: Selectors.getTransactionGetConfirmRefundModalOpen(state),
    setUpdateTransactionStatusAttempt: Selectors.setPackagesPackagesUpdateTransactionStatusAttempt(state),
    setUpdateTransactionStatusFailure: Selectors.setPackagesPackagesUpdateTransactionStatusFailure(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsConfirmRefundModalOpen: (state: boolean) => dispatch(Actions.setIsConfirmRefundModalOpen(state)),
    setPackagesUpdateTransactionStatusAttempt: (
        propertyId: string,
        transactionId: string,
        status: number,
    ) => dispatch(Actions.packagesUpdateTransactionStatusAttempt({ propertyId, transactionId, status })),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRefundModal);
