import { Button, Colors, SearchBar } from '@dm/bigfish';
import ReactDatePicker from 'react-datepicker';
import styled, { keyframes } from 'styled-components';
import SVG from 'react-inlinesvg';

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height:90vh;
`;

const ModalTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 50px;
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: 75vh;
`;

const Title = styled.h1`
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const SpanContainer = styled.div`
    cursor: pointer;
    font-size: 28px;
`;

const SeperatingLine = styled.div`
    width: 2px;
    background-color: black;
    height: 100%;
`;

const TransactionPropertyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 10px;
    padding: 10px;

    h2 {
        font-size: medium;
        padding: 10px;
    }
`;
const TPTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledSearchBar = styled(SearchBar)`
    width: 50%;
    height: 30px;;
    padding: 5px 10px;
    margin-top:10px;

    input {
        width: 100%;
        margin-right:20px;
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TransactionDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 10px;
    justify-content: center;
    padding: 10px;

    h2 {
        font-size: medium;
        padding: 10px 20px;
    }
`;

const UpperTransactionDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-shrink: 3;
    margin-bottom: 10px;
`;

const SelectedPropertyContainer = styled.div`
    display: flex;
    width: 100%;
    max-height: 125px;
    height: 100%;
    //margin: 10px 0 0 10px;
    border: 2px dotted #ccc;
    border-radius: 20px;

    h2{
        display: flex;
        flex-direction: row;
        margin: auto;
        align-items: center;
        font-weight: lighter;
        color: #fd9797;
    }
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    padding-top: 10px;
    position: relative;
    background-color: ${Colors.primaryLightest};

    border-radius: 20px;
    /* justify-content: center; */
    h2 {
        font-size: medium;
        padding: 10px 20px;
    }
`;

const DetailsContainerOverlay = styled.div<{propertySelected: boolean}>`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    backdrop-filter: grayscale(80%);
    z-index:10;
    border-radius: 20px;
    cursor: not-allowed;

    display: ${props => (props.propertySelected ? 'none' : 'block')};
`;

const TransactionForm = styled.form`
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 10px;
    margin: 10px;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50%;
    justify-content: space-between;
`;
const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownSelect = styled.select`
    width: 100%;
    border-radius: 12px;
    padding: 7px 15px;
    display: flex;
    border: 1.5px solid #0f0f0f;
    appearance: none; /* Reset default styles for the dropdown container */
    cursor: pointer; /* Show pointer cursor on hover */

    /* Custom styles for the dropdown container */
    border-radius: 12px;
    background-color: #ffffff; /* Adjust background color */
    color: #333333; /* Adjust text color */
    font-size: 14px; /* Adjust font size */
    outline: none; /* Remove default focus outline */

    /* Styles for the dropdown arrow */
    background-image: linear-gradient(45deg, transparent 50%, #333333 50%),
                        linear-gradient(135deg, #333333 50%, transparent 50%);
    background-position: calc(100% - 18px) center, calc(100% - 13px) center;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;

    /* Styles on hover and focus */
    &:hover,
    &:focus {
        border-color: #1a1a1a; /* Adjust border color on hover or focus */
    }
`;

const RecurringToggle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    p{
        align-self: center;
        padding-left: 10px;
    }
`;

const fadeIn = keyframes`
    0% {
    opacity: 0;
    transform: translateY(-50px);
    }
    100% {
    opacity: 1;
    transform: translateY(0);
    }
`;

const RecurringContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-height: 35%;
    height: 100%;
    margin: 10px;
    /* padding: 18px; */
    gap: 10px;

    transform: translateY(-50px);
    animation: ${fadeIn} 1s forwards;

    p{
        align-items: center;
    }

    h2{
        display:flex;
        padding: 10px;
        width: 50%;
        overflow-wrap: break-word;
    }
`;

const LeftContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 20px;
    padding: 10px;
`;
const RightContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 20px;
    padding: 10px;

    p{
        line-height: 1.5;
        text-align: center;
    }
`;
const DateContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    span{
        /* display: flex; */
        flex-direction: column;
    }

    p{
        font-size: 10px;
        display: flex;
        justify-content: center;
    }

    h4{
        font-weight: lighter;
        //align-self: end;
        padding-top: 20px;
    }
`;

const IntervalContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-top: 30px;
    position: relative;

    input{
        width: 150px;
        padding: 0 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        height: 30px;
    }
    Select{
        width:100px;
        padding: 0 10px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: white;
    }
    p{
        position: absolute;
        top: 0;
        left:100px;
    }
`;

const StyledInput = styled.input`
    border-radius: 12px;
    outline: none;
    height: 20px;
    margin: 5px 0;
    padding: 15px;

    width: ${props => props.width}
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 100px;
    margin: 3px 10px;
    height: 30px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    border-bottom: 1px solid #a2a2a2;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    } 
    .react-datepicker__month-text--disabled, 
    .react-datepicker__quarter-text--disabled, 
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

const StyledButton = styled(Button)`
    width: 30%;
    padding: 10px;
    margin: 10px;
    position: absolute;
    bottom: 10px;
    right: 20px;
`;

const ToggleInput = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
`;

const ToggleLabel = styled.label<{ checked: boolean}>`
    display: flex;
    align-items: center;
    position: relative;
    width: 50px;
    height: 24px;
    border-radius: 24px;
    border: 2px solid #949494;
    background-color: ${props => (props.checked ? Colors.primaryLight : Colors.primaryLightest)};
    transition: background-color 0.2s ease;

    &:after {
    content: '';
    /* position: absolute; */
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color:${props => (props.checked ? Colors.primary : Colors.grey)};
    transition: transform 0.2s ease;
    transform: ${props => (props.checked ? 'translateX(26px)' : 'translateX(0)')};
    }
`;

const UploadImage = styled(SVG)<{disabled?:boolean}>`
    padding: 50px;
    position: absolute;
    width: 100%;
    height: 100%;

    &:active:not([disabled]){
        transform: translateY(4px);
    }
`;

const UploadDocumentsContainer = styled.button`
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 40%;
    margin: 50px 10px;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    right: 20px;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    border: 3px dotted #a3a3a3;
    border-radius: 20px;
    background-color: ${Colors.primaryLightest};

    &:disabled{
        background-color: rgba(0, 0, 0, 0.192);
    }

    p{
        position: absolute;
        bottom: 10px;
    }

    &:hover:not([disabled]){
        background-color: ${Colors.primaryLight};
    }
`;

const PropertiesTable = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 80%;
    margin-top: 10px;
    justify-content: center;
`;

const PropertyListingTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 20px 0 20px 0;
    overflow-y: scroll;

    > div:not(:last-child){
        margin-bottom: 8px;
    }
`;

const PropertyListingHeader = styled.div`
    display: grid;
    grid-template-columns: 250px repeat(2, 1fr);
    align-items: center;
    padding: 16px 9px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    background: ${Colors.white};

    > h4:first-child {
        padding-left: 10px;
    }

    > h4:not(:first-child){
        text-align: center;
    }
`;

const PropertyListingRowButton = styled.button<{checked?: boolean}>`
    display: grid;
    grid-template-columns: 250px repeat(2, 1fr);
    align-items: center;
    width: 100%;
    border: none;
    padding: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;

    color: ${Colors.greyDark};
    background: ${props => (props.checked ? Colors.primaryLight : Colors.primaryLightest)};

    > p {
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
    }
`;

const PropertyListingRowButtonError = styled.button`
    grid-template-columns: 250px repeat(2, 1fr);
    align-items: center;
    width: 100%;
    border: none;
    padding: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;

    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    > p {
        margin: 0px;
        padding: 5px;
        text-transform: capitalize;
    }
`;

const NameContainer = styled.h4`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const PropertyListingsColumnWithImage = styled.div`
    display: flex;
    align-items: center;

    h4 {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        width: 100px;
        margin-right: 16px;
        text-transform: capitalize;

        color: ${Colors.black};
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 12px;
        padding: 5px;
    }
`;

const DateWrapper = styled.div`
    margin-top: 2px;
    border-radius: 12px;
    height: 30px;
`;

const DateTimePicker = styled(ReactDatePicker)`
    text-align: center;
    z-index: 9999999999;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${Colors.black};
    appearance: none;
    border-radius: 12px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus, &:active{
        border-color: rgba(61, 39, 78, 1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(61, 39, 78, 0.6);
        outline: 0 none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${Colors.primary};
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${Colors.primary};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${Colors.primary};
    }
`;

const StyledButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
`;

export default {
    MainContainer,
    ModalTitleContainer,
    ModalContentContainer,
    Title,
    SpanContainer,
    SeperatingLine,
    TransactionPropertyContainer,
    DateContainer,
    IntervalContainer,
    TPTitle,
    StyledSearchBar,
    LoadingContainer,
    TransactionDetailsContainer,
    UpperTransactionDetailsContainer,
    SelectedPropertyContainer,
    DetailsContainer,
    DetailsContainerOverlay,
    TransactionForm,
    InputContainer,
    DropdownWrapper,
    DropdownSelect,
    RecurringToggle,
    RecurringContainer,
    LeftContainer,
    RightContainer,
    StyledInput,
    StyledDatePicker,
    StyledButton,
    ToggleLabel,
    ToggleInput,
    UploadImage,
    UploadDocumentsContainer,
    PropertiesTable,
    PropertyListingTable,
    PropertyListingHeader,
    PropertyListingRowButton,
    PropertyListingRowButtonError,
    NameContainer,
    PropertyListingsColumnWithImage,
    DateWrapper,
    DateTimePicker,
    StyledButtonWrapper,
};
