import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

import { BreadCrumb, Colors, LineInput, CardCollapsible, EventTrail, Button, IEventTrail } from '@dm/bigfish';
import { GetOwnerDetailsResponse, IPropertyPackageStatusEnum, PropertyStatus, RecentEvents } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { IEditOwnerDetails, IOwnerPackage } from 'entities/owners';

import icons from 'assets/icons';
import houseImg from 'assets/images/house1.png';
import avatarImg from 'assets/images/avatar.png';

import countriesOptions from 'components/options/Countries';
import raceOptions from 'components/options/Race';
import genderOptions from 'components/options/Gender';
import titleOptions from 'components/options/Title';

import MainContainer from 'components/MainContainer';
import SelectionInput from 'components/SelectionInput';
import DateInput from 'components/DateInput';
import ButtonInfo from 'components/ButtonInfo';
import ButtonInfoPackages from 'components/ButtonInfoPackages';
import { EditOwnerDetailsPayload } from 'redux/slices/owners/types';

interface OwnerDetailProps {
    getOwnerDetailsLoading: boolean;
    getOwnerDetailsError: string;
    ownerDetails: GetOwnerDetailsResponse | null;

    getOwnerDetails: (userId: string) => void;
    clearOwnerDetails: () => void;

    editOwnerDetailsAttempt: boolean;
    editOwnerDetailsError: string;
    editOwnerDetails: (data: IEditOwnerDetails) => void;
}

const OwnerDetail: FunctionComponent<OwnerDetailProps> = (props: OwnerDetailProps) => {
    const {
        getOwnerDetailsLoading,
        getOwnerDetailsError,
        ownerDetails,
        getOwnerDetails,
        clearOwnerDetails,
        editOwnerDetailsAttempt,
        editOwnerDetailsError,
        editOwnerDetails,
    } = props;

    const { id = '' } = useParams();

    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                label: Translate.t('OwnersReport.OwnersReportBcDashboard'),
                onClick: () => { NavActions.navToReportsOwner(); },
            },
            {
                label: Translate.t('OwnersReport.OwnersReportBcOwners'),
            },
        ],
    );

    const [stateFirstName, setStateFirstName] = useState<string>('');
    const [stateLastName, setStateLastName] = useState<string>('');
    const [stateTitle, setStateTitle] = useState<string>('');
    const [stateGender, setStateGender] = useState<string>('');
    const [stateRace, setStateRace] = useState<number>(0);
    const [stateId, setStateId] = useState<string>('');
    const [stateCountryOfOrigin, setStateCountryOfOrigin] = useState<string>('');
    const [stateCountryOfResidence, setStateCountryOfResidence] = useState<string>('');
    const [stateDOB, setStateDOB] = useState<string | Date>(new Date());
    const [stateHouseTel, setStateHouseTel] = useState<string>('');
    const [statePhoneNum, setStatePhoneNum] = useState<string>('');
    const [statePassportNo, setStatePassportNo] = useState<string>('');
    const [stateEmail, setStateEmail] = useState<string>('');

    // Corresponding Address
    const [stateAddr1, setStateAddr1] = useState<string>('');
    const [stateAddr2, setStateAddr2] = useState<string>('');
    const [stateCity, setStateCity] = useState<string>('');
    const [stateCountry, setStateCountry] = useState<string>('');
    const [statePostcode, setStatePostcode] = useState<string>('');
    const [stateState, setStateState] = useState<string>('');

    const [propertyData, setPropertyData] = useState<IOwnerPackage[]>([]);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    useEffect(() => {
        return (() => {
            clearOwnerDetails();
            setIsEdit(false);
        });
    }, []);

    useEffect(() => {
        clearOwnerDetails();
        getOwnerDetails(id);
    }, [id]);

    useEffect(() => {
        if (ownerDetails) {
            const { userInfo, properties = [] } = ownerDetails;

            const {
                firstName,
                lastName,
                title,
                gender,
                race,
                correspondingAddress,
                idNo,
                countryOfOrigin,
                countryOfResidence,
                DOB,
                houseTel,
                phoneNumber,
                passportNo,
                email,
            } = userInfo;

            const { address1, address2 = '', city, country, postcode, state } = correspondingAddress;

            setBreadCrumb([
                {
                    label: Translate.t('OwnersReport.OwnersReportBcDashboard'),
                    onClick: () => { NavActions.navToReportsOwner(); },
                },
                {
                    label: Translate.t('OwnersReport.OwnersReportBcOwners'),
                },
                {
                    label: `${userInfo.firstName ?? ''} ${userInfo.lastName ?? ''}`,
                },
            ]);

            setStateFirstName(firstName);
            setStateLastName(lastName);
            setStateTitle(title);
            setStateGender(gender);
            setStateRace(race);
            setStateId(idNo);
            setStateCountryOfOrigin(countryOfOrigin);
            setStateCountryOfResidence(countryOfResidence);
            setStateDOB(DOB);
            setStateHouseTel(houseTel);
            setStatePhoneNum(phoneNumber);
            setStatePassportNo(passportNo);
            setStateEmail(email ?? '');
            // Corresponding Address
            setStateAddr1(address1);
            setStateAddr2(address2);
            setStateCity(city);
            setStateCountry(country);
            setStatePostcode(postcode);
            setStateState(state);

            if (properties.length) {
                setPropertyData(properties);
            }
        }
    }, [ownerDetails]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const setCollapsibleChipText = (statusEnum: PropertyStatus) => {
        switch (statusEnum) {
            case PropertyStatus.approved: return 'Approved';
            case PropertyStatus.deactivated: return 'Deactivated';
            case PropertyStatus.draftProperty: return 'Draft';
            case PropertyStatus.pendingApproval: return 'Pending';
            case PropertyStatus.tenanted: return 'Tenanted';
            default: return 'Vacant';
        }
    };

    // THESE TEXT NOT TRANSLATED BECAUSE IT NOT TO DISPLAY BUT TO CHECK
    const checkPropertyPackagesStatus = (packages: any) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case 0:
                return '';
            case 1:
                return 'Pending';
            case 2:
                return 'pending verification';
            case 3:
                return 'Active';
            case 4:
                return 'PaymentRejected';
            case 5:
                return 'Disabled';
            case 6:
                return 'FreeTrial';
            case 7:
                return 'purchase';
            default:
                return '';
        }
    };
    // const createPropertyLocation = (property:IOwnerPackage) => {
    //     const { location } = property;

    //     if (location) {
    //         const { city = '', state = '' } = location;
    //         return `${city}, ${state}`;
    //     }

    //     return '';
    // };

    const handleEditOwner = () => {
        const data: IEditOwnerDetails = {
            userId: id,
            userInfo: {
                firstName: stateFirstName,
                lastName: stateLastName,
                title: stateTitle,
                gender: stateGender,
                race: stateRace,
                idNo: stateId,
                countryOfOrigin: stateCountryOfOrigin,
                countryOfResidence: stateCountryOfResidence,
                DOB: stateDOB,
                houseTel: stateHouseTel,
                phoneNumber: statePhoneNum,
                passportNo: statePassportNo,
                correspondingAddress: {
                    address1: stateAddr1,
                    address2: stateAddr2,
                    city: stateCity,
                    country: stateCountry,
                    postcode: statePostcode,
                    state: stateState,
                },
            },
        };

        editOwnerDetails(data);
    };

    if (getOwnerDetailsLoading) {
        return (
            <MainContainer>
                <div
                    style={{
                        height: '100vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </div>
            </MainContainer>
        );
    }

    if (getOwnerDetailsError) {
        return (
            <MainContainer>
                <div
                    style={{
                        height: '100vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: '24px',
                    }}
                >
                    {getOwnerDetailsError}
                </div>
            </MainContainer>
        );
    }

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <div
                    style={{
                        padding: '20px',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                width: '60%',
                            }}
                        >
                            <OwnerInfoContainer>
                                <img
                                    src={avatarImg}
                                    alt='avatar of owner'
                                    id='pfp'
                                />
                                <OwnerNameAndPhoneNumContainer>
                                    <OwnerNameContainer>
                                        {`${stateFirstName} ${stateLastName}`}
                                    </OwnerNameContainer>
                                    <OwnerPhoneNumContainer>
                                        <SVG
                                            src={icons.PhoneIcon}
                                            style={{
                                                height: '15px',
                                                width: '15px',
                                                marginRight: '10px',
                                            }}
                                        />
                                        <div>
                                            {statePhoneNum}
                                        </div>
                                    </OwnerPhoneNumContainer>
                                </OwnerNameAndPhoneNumContainer>
                            </OwnerInfoContainer>

                            <SectionContainer>
                                <SectionTitle>{Translate.t('CreateNew.CreateNewOwnerDetails')}</SectionTitle>
                                <DetailsContainer>
                                    <SelectionInput
                                        label={Translate.t('CreateNew.CreateNewLabelTitle')}
                                        data={titleOptions}
                                        currentValue={stateTitle}
                                        onChangeSelection={(e) => setStateTitle(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <SelectionInput
                                        label={Translate.t('CreateNew.CreateNewLabelGender')}
                                        data={genderOptions}
                                        currentValue={stateGender}
                                        onChangeSelection={(e) => setStateGender(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelCPAddress1')}
                                        value={stateAddr1}
                                        onChangeText={(e) => setStateAddr1(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelCPAddress2')}
                                        value={stateAddr2}
                                        onChangeText={(e) => setStateAddr2(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelFirstName')}
                                        value={stateFirstName}
                                        onChangeText={(e) => setStateFirstName(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelLastName')}
                                        value={stateLastName}
                                        onChangeText={(e) => setStateLastName(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelIDNo')}
                                        value={stateId}
                                        onChangeText={(e) => setStateId(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelCity')}
                                        value={stateCity}
                                        onChangeText={(e) => setStateCity(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelPassport')}
                                        value={statePassportNo}
                                        onChangeText={(e) => setStatePassportNo(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelMobile')}
                                        value={statePhoneNum}
                                        onChangeText={(e) => setStatePhoneNum(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelPostcode')}
                                        value={statePostcode}
                                        onChangeText={(e) => setStatePostcode(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <SelectionInput
                                        label={Translate.t('CreateNew.CreateNewLabelCountryOrigin')}
                                        data={countriesOptions}
                                        currentValue={stateCountryOfOrigin}
                                        onChangeSelection={(e) => setStateCountryOfOrigin(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelHouseTel')}
                                        value={stateHouseTel}
                                        onChangeText={(e) => setStateHouseTel(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label={Translate.t('CreateNew.CreateNewLabelState')}
                                        value={stateState}
                                        onChangeText={(e) => setStateState(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <SelectionInput
                                        label={Translate.t('CreateNew.CreateNewLabelRace')}
                                        data={raceOptions}
                                        currentValue={stateRace}
                                        onChangeSelection={(e) => setStateRace(+e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <SelectionInput
                                        label={Translate.t('CreateNew.CreateNewLabelCountryResidence')}
                                        data={countriesOptions}
                                        currentValue={stateCountryOfResidence}
                                        onChangeSelection={(e) => setStateCountryOfResidence(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <SelectionInput
                                        label={Translate.t('CreateNew.CreateNewLabelCountry')}
                                        data={countriesOptions}
                                        currentValue={stateCountry}
                                        onChangeSelection={(e) => setStateCountry(e.target.value)}
                                        disabled={!isEdit}
                                    />
                                    <DateInput
                                        label={Translate.t('CreateNew.CreateNewLabelDOB')}
                                        date={new Date(stateDOB)}
                                        onChangeDate={(date) => setStateDOB(date)}
                                        disabled={!isEdit}
                                    />
                                    <LineInput
                                        label='Email'
                                        value={stateEmail}
                                        onChangeText={(e) => setStateEmail(e.target.value)}
                                        inputError={false}
                                        disabled
                                    />
                                </DetailsContainer>
                                <ButtonContainer>
                                    {isEdit && <WarningText>click save to made your changes!</WarningText>}
                                    <StyledButton label='Edit details' onClick={() => setIsEdit(true)} isEdit={isEdit} />
                                    <StyledButton
                                        label='Save'
                                        onClick={handleEditOwner}
                                        isEdit={!isEdit}
                                        loading={editOwnerDetailsAttempt}
                                        disabled={editOwnerDetailsAttempt}
                                    />
                                    <StyledButton
                                        label='Cancel'
                                        onClick={() => setIsEdit(false)}
                                        isEdit={!isEdit}
                                        disabled={editOwnerDetailsAttempt}
                                    />
                                </ButtonContainer>
                            </SectionContainer>
                        </div>

                        {
                            propertyData.length > 1 && (
                                <div
                                    style={{
                                        marginTop: '60px',
                                        marginBottom: '60px',
                                        marginLeft: '20px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {`${stateFirstName} ${stateLastName}'s ${Translate.t('OwnersReport.OwnersReportManagedProperties')}`}
                                </div>
                            )
                        }

                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    width: '60%',
                                }}
                            >
                                {propertyData.map((item, index) => {
                                    const { name, status, packages, unitNo, country = '', city = '', state = '' } = item;

                                    const uniqueKey = name + index;

                                    const recentEventsData: RecentEvents[] = [];

                                    if (packages.vacantPossession) {
                                        recentEventsData.push(...packages.vacantPossession.recentEvents);
                                    }

                                    if (packages.defectInspection) {
                                        recentEventsData.push(...packages.defectInspection.recentEvents);
                                    }

                                    if (packages.propertyManagement) {
                                        recentEventsData.push(...packages.propertyManagement.recentEvents);
                                    }

                                    if (packages.leaseSale) {
                                        recentEventsData.push(...packages.leaseSale.recentEvents);
                                    }

                                    if (packages.designRenovation) {
                                        recentEventsData.push(...packages.designRenovation.recentEvents);
                                    }

                                    const parsedRecentEventsData: IEventTrail[] = recentEventsData.map(event => {
                                        const { message } = event;

                                        return {
                                            text: message,
                                            onClick: () => { return false; },
                                        };
                                    });

                                    return (
                                        <CardCollapsible
                                            preText={unitNo ?? ''}
                                            title={name.substring(0, 10)}
                                            subtitle={`${city} ${state} ${country}`}
                                            chipText={setCollapsibleChipText(status)}
                                            style={{
                                                marginBottom: '10px',
                                                maxWidth: '100%',
                                            }}
                                            key={uniqueKey}
                                        >
                                            <CollapsibleContentContainer>
                                                <img
                                                    src={houseImg}
                                                    alt=''
                                                    id='propertyImg'
                                                />

                                                <div
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >

                                                    <EventTrail
                                                        title='Recent Events'
                                                        primary
                                                        buttonLabel='Recent Events'
                                                        data={parsedRecentEventsData}
                                                        onClickButtonLabel={() => { return false; }}
                                                        emptyText='No Recent Events'
                                                        style={{
                                                            maxWidth: '100%',
                                                            marginBottom: '20px',
                                                            minHeight: '200px',
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >

                                                        <ButtonInfoFirstMarginContainer>
                                                            {(item.packages && item.packages.vacantPossession?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} status={checkPropertyPackagesStatus(item.packages.vacantPossession.status)} onclick={() => NavActions.navToPackagesVacantPosession(item.id)} />
                                                            ) : (item.packages?.vacantPossession?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToPackagesVacantPosession(item.id)} />}
                                                        </ButtonInfoFirstMarginContainer>
                                                        {/* FUTURES BELOW TO BE USED IN FUTURE */}
                                                        {/* <ButtonInfoMarginContainer>
                                                            {(item.packages && item.packages.defectInspection?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} status={checkPropertyPackagesStatus(item.packages.defectInspection.status)} onclick={() => undefined} />
                                                            ) : (item.packages?.defectInspection?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => undefined} />}
                                                        </ButtonInfoMarginContainer>

                                                        <ButtonInfoMarginContainer>
                                                            {(item.packages && item.packages.designRenovation?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} status={checkPropertyPackagesStatus(item.packages.designRenovation.status)} onclick={() => undefined} />
                                                            ) : (item.packages?.designRenovation?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => undefined} />}
                                                        </ButtonInfoMarginContainer>

                                                        <ButtonInfoMarginContainer>
                                                            {(item.packages && item.packages.propertyManagement?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')} status={checkPropertyPackagesStatus(item.packages.propertyManagement.status)} onclick={() => undefined} />
                                                            ) : (item.packages?.propertyManagement?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => undefined} />}
                                                        </ButtonInfoMarginContainer> */}

                                                        <ButtonInfoMarginContainer>
                                                            {(item.packages && item.packages.leaseSale?.status) ? (
                                                                <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} status={checkPropertyPackagesStatus(item.packages.leaseSale.status)} onclick={() => NavActions.navToPackagesLeaseAndSale(item.id)} />
                                                            ) : (item.packages?.leaseSale?.price) && <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToPackagesLeaseAndSale(item.id)} />}
                                                        </ButtonInfoMarginContainer>

                                                        <AdminControlsContainer>
                                                            <Button
                                                                label='Create/Edit Event Messages'
                                                                style={{
                                                                    width: '100%',
                                                                    fontSize: '16px',
                                                                    marginBottom: '10px',
                                                                }}
                                                                onClick={() => {
                                                                    clearOwnerDetails();
                                                                    NavActions.navToCreateEvent(item.id || '', id);
                                                                }}
                                                            />

                                                            <Button
                                                                label='Create/Edit Design & Renovation Projects'
                                                                style={{
                                                                    width: '100%',
                                                                    fontSize: '16px',
                                                                }}
                                                            />
                                                        </AdminControlsContainer>
                                                    </div>

                                                </div>
                                            </CollapsibleContentContainer>
                                        </CardCollapsible>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </MainContainer>
        </>
    );
};
const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
`;
const WarningText = styled.p`
    font-style: italic;
    color: red;
`;
const StyledButton = styled(Button)<{isEdit?: boolean }>`
    display: ${props => (props.isEdit ? 'none' : 'block')};
`;

const ButtonInfoMarginContainer = styled.div`
    margin-bottom: 10px;
`;

const ButtonInfoFirstMarginContainer = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;

const OwnerInfoContainer = styled.div`
    background-color: ${Colors.primary};
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    #pfp {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: contain;
    }
`;

const OwnerNameAndPhoneNumContainer = styled.div`
    margin-left: 20px;
`;

const OwnerNameContainer = styled.div`
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: bold;
`;

const OwnerPhoneNumContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SectionTitle = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const CollapsibleContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;

    #propertyImg {
        height: 250px;
        width: 250px;
        margin-right: 20px;
        border-radius: 12px;
    }
`;

const AdminControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    getOwnerDetailsLoading: Selectors.getOwnersGetOwnerDetailsAttempting(state),
    getOwnerDetailsError: Selectors.getOwnersGetOwnerDetailsError(state),
    ownerDetails: Selectors.getOwnersGetOwnerDetails(state),

    editOwnerDetailsAttempt: Selectors.getOwnersEditOwnerDetailsAttempt(state),
    editOwnerDetailsError: Selectors.getOwnersEditOwnerDetailsError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getOwnerDetails: (userId: string) => dispatch(Actions.getOwnerDetailsAttempt(userId)),
    clearOwnerDetails: () => dispatch(Actions.clearOwnerDetails()),
    editOwnerDetails: (data: IEditOwnerDetails) => dispatch(Actions.editOwnerDetailsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerDetail);
