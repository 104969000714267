import { fork } from 'redux-saga/effects';

import SubAdminsGateway from 'api/Subadmins';
import { RootSagaReturnType } from 'sagas/types';

import watchCreateSubAdmin from './createSubAdmin';
import watchGetSubAdmins from './getSubAdmins';
import watchAssignSubAdminProperty from './assignSubAdminProperty';
import watchRemoveSubAdminProperty from './removeSubAdminProperty';

export default (api: SubAdminsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchCreateSubAdmin, api);
        yield fork(watchGetSubAdmins, api);
        yield fork(watchAssignSubAdminProperty, api);
        yield fork(watchRemoveSubAdminProperty, api);
    }

    return {
        rootSaga,
    };
};
