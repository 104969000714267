import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { GetDetailsActionPayload, SetServicePackageActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';

import { toast } from 'react-toastify';
import { PropertyStatus } from '@dm/types';

export default function* watchSetServicePackagePricing(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/setServicePackageAttempt', handleSetServicePackagePricing, api);
}

function* handleSetServicePackagePricing(api: PropertyGateway, data: SetServicePackageActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setPackagePricing], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.setServicePackageFailure(response.message));
    } else if (data.payload.status === PropertyStatus.deactivated) {
        toast.success(Translate.t('PropertyRpDetails.PropertyRpMessageSetPriceRejected'));
        yield put(Actions.setServicePackageSuccess());
        yield put(Actions.setServicePackageModalOpen(false));
        yield put(Actions.setRejectPropertyModalOpen(false));
        NavActions.navToPropertyReports();
    } else {
        toast.success(Translate.t('PropertyRpDetails.PropertyRpMessageSetPriceSuccess'));
        yield put(Actions.setServicePackageSuccess());
        yield put(Actions.setServicePackageModalOpen(false));
        yield put(Actions.setRejectPropertyModalOpen(false));
        NavActions.navToPropertyReports();
    }
}
