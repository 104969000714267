import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors, DropdownInput } from '@dm/bigfish';
import Translate from 'lib/translate';

import Modal from 'components/Modal';

import { IOption } from 'entities/component';
import { TenancyStatus } from '@dm/types';

interface TenancyStatusUpdateModalProps {
    id: string;
    isModalOpen: boolean;
    setSetPropertyTenancyLoading: boolean;
    setSetPropertyTenancyError: string;
    setVacancyStatusPropertyModalOpen: (state: boolean) => void;
    setTenancyStatus: (propertyId: string, tenancyStatus: number) => void;
}

const TenancyStatusUpdateModal: FunctionComponent<TenancyStatusUpdateModalProps> = (props: TenancyStatusUpdateModalProps) => {
    const {
        id,
        isModalOpen,
        setSetPropertyTenancyLoading,
        setSetPropertyTenancyError,
        setVacancyStatusPropertyModalOpen,
        setTenancyStatus,
    } = props;

    const [buildingTypesOpt, setBuildingTypesOpt] = useState<string | TenancyStatus>('');

    const cancelClickHandler = () => {
        setVacancyStatusPropertyModalOpen(false);
    };

    const confirmClickHandler = () => {
        setTenancyStatus(id, Number(buildingTypesOpt));
    };

    const propertyTypeDropdownData: IOption[] = [
        {
            id: TenancyStatus.Pending,
            description: Translate.t('Modal.TenancyStatusPending'),
        },
        {
            id: TenancyStatus.Available,
            description: Translate.t('Modal.TenancyStatusAvailable'),
        },
        {
            id: TenancyStatus.NotAvailable,
            description: Translate.t('Modal.TenancyStatusNotAvailable'),
        },
        {
            id: TenancyStatus.Tenanted,
            description: Translate.t('Modal.TenancyStatusTenanted'),
        },
    ];

    const renderModalBody = () => {
        if (setSetPropertyTenancyLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Oval
                        height={150}
                        width={150}
                        color={Colors.primary}
                        secondaryColor={Colors.primaryLight}
                    />
                </div>
            );
        }

        if (setSetPropertyTenancyError) {
            return (
                <div>
                    {setSetPropertyTenancyError}
                </div>
            );
        }

        return (
            <div>
                <DropdownInput
                    style={{ width: '100%' }}
                    label={Translate.t('Modal.TenancyStatusSelectstatus')}
                    value={buildingTypesOpt}
                    options={propertyTypeDropdownData}
                    onChangeItem={setBuildingTypesOpt}
                />

            </div>
        );
    };

    const renderModalFooter = () => {
        if (setSetPropertyTenancyError) {
            return (
                <Button
                    label={Translate.t('Modal.TenancyStatusProceed')}
                    onClick={() => window.location.reload()}
                />
            );
        }

        return (
            <>
                <Button
                    label={Translate.t('Modal.TenancyStatusCancel')}
                    style={{
                        marginRight: '10px',
                        backgroundColor: Colors.white,
                        color: Colors.primary,
                        border: `1px solid ${Colors.primary}`,
                    }}
                    onClick={cancelClickHandler}
                    disabled={setSetPropertyTenancyLoading}
                />
                <Button
                    label='Proceed'
                    onClick={confirmClickHandler}
                    disabled={setSetPropertyTenancyLoading}
                />
            </>
        );
    };

    return (
        <Modal
            show={isModalOpen}
            width='40%'
            height='fit-content'
        >
            <ModalHeader>
                {Translate.t('Modal.TenancyStatusHeader')}
            </ModalHeader>

            <ModalBody>
                {renderModalBody()}
            </ModalBody>

            <ModalFooter>
                {renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ModalBody = styled.div`
    margin-bottom: 20px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const mapStateToProps = (state: RootState) => ({
    setSetPropertyTenancyLoading: Selectors.setPropertySetPropertyTenancyStatusAttempting(state),
    setSetPropertyTenancyError: Selectors.setPropertySetPropertyTenancyStatusError(state),
    isModalOpen: Selectors.getPropertyGetVacancyStatusModalOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setVacancyStatusPropertyModalOpen: (state: boolean) => dispatch(Actions.setVacancyStatusPropertyModalOpen(state)),
    setTenancyStatus: (propertyId: string, tenancyStatus: number) => dispatch(Actions.setPropertyTenancyStatusAttempt({ propertyId, tenancyStatus })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenancyStatusUpdateModal);
