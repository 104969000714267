import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import SVG from 'react-inlinesvg';
import { stringify } from 'querystring';
import Icons from '../assets/icons';

interface ButtonInfoPackagesProps {
  desc: string;
  leftIconSrc?: string;
  rightIconSrc?: string;
  status?: string;
  onclick: () => void;
}

const StyledButtonRight = styled.button<{status?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 0.5px solid #F2865E;
  background-color: #F2865E;
  color: #ffffff;;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  cursor: pointer;
`;

const StyledIcon = styled(SVG)<{iconColor?: string, status?: string}>`
  width: 20px;
  height: 20px;
  color: #ffffff;
  ${props => (props.status === 'pending verification') && css`
    color: #F2865E;
  `}
`;

const StyledDescRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${StyledIcon}{
    margin-right: 6px;
  }
`;

const QuickNavigationButton = ({ desc, onclick, leftIconSrc, rightIconSrc, status, ...otherProps }: ButtonInfoPackagesProps):JSX.Element => {
    return (
        <StyledButtonRight onClick={onclick} status={status} {...otherProps}>
            <StyledDescRight>
                {desc}
                <StyledIcon status={status} src={Icons.RightArrow} />
            </StyledDescRight>
        </StyledButtonRight>

    );
};

QuickNavigationButton.defaultProps = {
    leftIconSrc: '',
    rightIconSrc: '',
    status: '',
};

export default QuickNavigationButton;
