import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import {
    BreadCrumb,
    DropdownInput,
} from '@dm/bigfish';
import {
    SortByEnum,
    PropertyManagementWithdrawalStatus,
    NumToBoolean,
    IPaginatedAdminPropertyManagementWithdrawalResponse,
} from '@dm/types';

import { withdrawalStatusData,
    withdrawalSortData } from 'lib/WithdrawalFilterSelectionData';

import { IGetPropertyManagementWithdrawalParams } from 'entities/packages';

import MainContainer from 'components/MainContainer';

import WithdrawalRequestListTable from './components/WithdrawalRequestListTable';

import StyledComponent from './styles/WithdrawalStyle';

interface PropertyReportProps {

    setTabView: (tabView: string) => void;
    getWithdrawals: (params: IGetPropertyManagementWithdrawalParams) => void;
    withdrawalDataInState: IPaginatedAdminPropertyManagementWithdrawalResponse | null;
    getUpdateWithdrawalTransactionStatusAttempt: boolean;
}

const PropertyManagementWithdrawalListing = (props: PropertyReportProps): JSX.Element => {
    const {

        setTabView,
        getUpdateWithdrawalTransactionStatusAttempt,
        getWithdrawals,
        withdrawalDataInState,
    } = props;

    const {
        SearchAndCreateTransactionContainer,
        PropertyReportContainer,
        TitleContainer,
        Title,
        StyledSearchBar,
        WithdrawalFilterContainer,
        FilterContainer,
        FilterTitle,
        FilterPropContainer,
        SortAndStarredContainer,
        WithdrawalContentContainer,
        WithdrawalListingContainer,
    } = StyledComponent;

    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [withdrawalData, setWithdrawalData] = useState<IPaginatedAdminPropertyManagementWithdrawalResponse | null>(withdrawalDataInState);

    const [sort, setSort] = useState<SortByEnum | number>(SortByEnum.createdAtDesc);
    const [withdrawalStatus, setWithdrawalStatus] = useState<PropertyManagementWithdrawalStatus | number>(0);
    const [includePreviousOwner, setIncludePreviousOwner] = useState<NumToBoolean>(NumToBoolean.false);
    const [search, setSearch] = useState('');
    const [pageIndex, setPageIndex] = useState<number>(1);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('Packages.PropertyManagement.Withdrawal.withdrawalDashboard'),
                onClick: () => { NavActions.navToWithdrawals(); },
            },
            {
                label: Translate.t('Packages.PropertyManagement.Withdrawal.withdrawalBc'),
            },
        ],
    );

    useEffect(() => {
        setTabView('withdrawals');
        getWithdrawals({
            status: 0,
            includePreviousOwner: NumToBoolean.false,
            index: 1,
            sort: SortByEnum.createdAtDesc,
        });
    }, []);

    useEffect(() => {
        const paramsData = {
            status: withdrawalStatus,
            includePreviousOwner,
            index: pageIndex,
            search,
            sort,
        };

        getWithdrawals(paramsData);
    }, [sort, withdrawalStatus, includePreviousOwner, search, getUpdateWithdrawalTransactionStatusAttempt]);

    useEffect(() => {
        const paramsData = {
            status: withdrawalStatus,
            includePreviousOwner,
            index: pageIndex,
            search,
            sort,
        };

        getWithdrawals(paramsData);
    }, [pageIndex]);

    useEffect(() => {
        if (withdrawalDataInState) {
            setWithdrawalData(withdrawalDataInState);

            setPaginationData(createPaginationData(withdrawalDataInState.maxIndex));
            setPaginationNumber(withdrawalDataInState.index);
            setMaxPages(withdrawalDataInState.maxIndex);
        }
    }, [withdrawalDataInState]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const throttledTransactionSetSearch = useRef(throttle((searchWord: string) => {
        setSearch(searchWord);
    }, 500, { leading: false }));

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        throttledTransactionSetSearch.current(value);
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);

        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }

        if (currentNumber !== paginationNumber) {
            setPageIndex(currentNumber);
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);

        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }

        if (currentNumber !== paginationNumber) {
            setPageIndex(currentNumber);

            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);

        if (paginationNumber !== 1) {
            setPageIndex(1);

            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);

        if (paginationNumber !== maxPages) {
            setPageIndex(maxPages);

            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);

        if (pages !== paginationNumber) {
            setPageIndex(pages);

            winScrollToTop();
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <PropertyReportContainer>
                    <TitleContainer>
                        <Title>
                            {Translate.t('Packages.PropertyManagement.Withdrawal.withdrawalSearchTitle')}
                        </Title>
                    </TitleContainer>

                    <SearchAndCreateTransactionContainer>
                        <StyledSearchBar
                            title={Translate.t('Packages.PropertyManagement.Withdrawal.withdrawalSearchBoxTitle')}
                            value=''
                            onChangeText={onSearch}
                        />
                    </SearchAndCreateTransactionContainer>

                    <WithdrawalFilterContainer>
                        <FilterContainer>
                            <FilterTitle>
                                {Translate.t('Packages.PropertyManagement.Withdrawal.withdrawalReportTitle')}
                            </FilterTitle>
                            <FilterPropContainer>
                                <DropdownInput
                                    style={{ marginRight: '15px' }}
                                    label={Translate.t('Packages.PropertyManagement.Withdrawal.withdrawalReportFilterStatusTitle')}
                                    value={withdrawalStatus}
                                    options={withdrawalStatusData}
                                    onChangeItem={(e) => setWithdrawalStatus(+e)}
                                />
                            </FilterPropContainer>
                        </FilterContainer>
                        <SortAndStarredContainer>
                            <DropdownInput
                                label={Translate.t('Packages.PropertyManagement.Withdrawal.withdrawalReportFilterSortTitle')}
                                value={sort}
                                options={withdrawalSortData}
                                onChangeItem={(e) => setSort(+e)}
                            />
                        </SortAndStarredContainer>
                    </WithdrawalFilterContainer>

                    <WithdrawalContentContainer>
                        <WithdrawalListingContainer>
                            <WithdrawalRequestListTable
                                withdrawalData={withdrawalData}
                                paginationNumber={paginationNumber}
                                paginationData={paginationData}
                                nextPaginationNumber={nextPaginationNumber}
                                prevPaginationNumber={prevPaginationNumber}
                                firstPaginationNumber={firstPaginationNumber}
                                lastPaginationNumber={lastPaginationNumber}
                                onClickPaginationNumber={onClickPaginationNumber}
                            />

                        </WithdrawalListingContainer>

                    </WithdrawalContentContainer>
                </PropertyReportContainer>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    withdrawalDataInState: Selectors.packagesGetPropertyManagementWithdrawal(state),
    getUpdateWithdrawalTransactionStatusAttempt: Selectors.packagesEditPropertyManagementWithdrawal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) => dispatch(Actions.setTabView(tabView)),
    getWithdrawals: (params: IGetPropertyManagementWithdrawalParams) => dispatch(Actions.getPropertyManagementWithdrawalAttempt(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyManagementWithdrawalListing);
