import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import SubAdminsGateway from 'api/Subadmins';

import { GetAllSubAdminActionPayload } from 'redux/slices/subadmins/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

export default function* watchGetSubAdmins(api: SubAdminsGateway): SagaWatcherReturnType {
    yield takeEvery('subadmins/subAdminGetAllAttempt', handleGetSubAdmins, api);
}

function* handleGetSubAdmins(api: SubAdminsGateway, data: GetAllSubAdminActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getSubAdmins], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.ownersGetAllFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.subAdminGetAllSuccess(response.data));
    } else {
        yield put(Actions.subAdminGetAllFailure(response.message));
    }
}
