import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import NavActions from 'lib/NavActions';

import { Button, Colors } from '@dm/bigfish';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';

export interface PropertyModalProps {
    loading: boolean;
    error: string;
    isCreatePropertyModalClose: boolean;
    loadingCreateProperty:boolean;
    setPropertyCreateModal: (state: boolean) => void;
    isPropertyGroup?: boolean;

    createPropertyGroupAttempting: boolean;
    createPropertyGroupError: string;
    propertyGroupId: string;
    createPropertyGroup: (name: string) => void;

    createPropertyAttempting: boolean;
    createPropertyError: string;
    propertyId: string;
    createProperty: (name: string) => void;
}
const propertyCreateModal = (props : PropertyModalProps) : JSX.Element => {
    const {
        loading,
        error,
        setPropertyCreateModal,
        isCreatePropertyModalClose,
        loadingCreateProperty,
        isPropertyGroup,
        createPropertyGroupAttempting,
        createPropertyGroupError,
        propertyGroupId,
        createPropertyGroup,
        createPropertyAttempting,
        createPropertyError,
        propertyId,
        createProperty,
    } = props;

    const [propertyName, setPropertyName] = useState('');

    useEffect(() => {
        return () => {
            setPropertyName('');
        };
    }, [isCreatePropertyModalClose]);

    const setPropertyNameFunction = () => {
        if (isPropertyGroup) {
            createPropertyGroup(propertyName);
        } else {
            createProperty(propertyName);
        }
    };

    const renderConfirmButton = () => {
        if (isPropertyGroup) {
            return (
                <ConfirmdPropertyButton
                    loading={createPropertyGroupAttempting}
                    disabled={propertyName.trim().length <= 0 || createPropertyGroupAttempting}
                    label={Translate.t('Property.PropertyCreate.PropertyConfirmButton')}
                    onClick={() => setPropertyNameFunction()}
                />
            );
        }
        return (
            <ConfirmdPropertyButton
                loading={createPropertyAttempting}
                disabled={propertyName.trim().length <= 0 || createPropertyAttempting}
                label={Translate.t('Property.PropertyCreate.PropertyConfirmButton')}
                onClick={() => setPropertyNameFunction()}
            />
        );
    };

    return (
        <MainContainer>
            <Modal
                show={isCreatePropertyModalClose}
                width='544px'
                height='fit-content'
            >
                <ContainerTitle>
                    <Title>
                        {isPropertyGroup ? 'Property Group Name' : Translate.t('Property.PropertyCreate.PropertyName')}
                    </Title>
                    <SpanContainer aria-hidden='true' role='button' onClick={() => setPropertyCreateModal(!isCreatePropertyModalClose)}>
                        <h3>&#88;</h3>
                    </SpanContainer>
                </ContainerTitle>
                <RoundInputsample placeholder='eg: Subang Jaya' value={propertyName} onChangeText={(e) => setPropertyName(e.target.value)} />
                <ButtonContainer>
                    {renderConfirmButton()}
                </ButtonContainer>
            </Modal>

        </MainContainer>
    );
};

const SpanContainer = styled.div`
cursor: pointer;
`;

const Title = styled.h2`
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    background-color: #ffffff;
    margin-bottom: 40px;
`;

const ContainerTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: flex-end;
`;

const ConfirmdPropertyButton = styled(Button)<{loading?: boolean, disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    height: 32px;
    width: 66px;
    padding: 8px;
    background-color: ${props => (props.disabled ? Colors.grey : '#FFCFA6')};
    color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    border: none;
    ${props => (props.loading) && css`
        cursor: not-allowed;
        pointer-events: none;
    `}
    ${props => (props.disabled) && css`
        cursor: not-allowed;
        pointer-events: none;
        animation: unset;
    `}
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;

    cursor: pointer;
`;

export interface RoundInputsampleProps {
    placeholder?: string;
    value?: string;
    onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onEnterPressed?: () => void;
    style?: React.CSSProperties;
}

const FormGroup = styled.div`
    margin: 2px 0;
    font-family: #F2B47E;
    font-size: 16px;
`;

const Input = styled.input<RoundInputsampleProps>`
    display: flex;
    width: 100%;
    height: 100px;
    margin-bottom: 16px;
    padding: 8px;
    padding-top: 0px;
    padding-bottom: 50px;
    background-color: #F6F6F6;
    color: black;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    border: none;
    border-radius: 12px;
    transition: all .5s;
    &:focus {
      outline-color: #cccccc;
    }
    &::placeholder {
      display: flex;
      margin: 0px;
      padding:0px;
      color: #696969;
    }
`;

const RoundInputsample = ({ placeholder, value, onChangeText, onEnterPressed, style, ...otherProps }: RoundInputsampleProps): JSX.Element => {
    return (
        <FormGroup style={style} {...otherProps}>
            <Input placeholder={placeholder} onChange={onChangeText} onKeyDown={onEnterPressed} value={value} />
        </FormGroup>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.setPropertyGetPropertySetCreatePropertAttempting(state),
    error: Selectors.setPropertyGetPropertySetCreatePropertError(state),
    isCreatePropertyModalClose: Selectors.setPropertyCreatePropertyModal(state),
    loadingCreateProperty: Selectors.setPropertyGetPropertySetCreatePropertAttempting(state),

    createPropertyGroupAttempting: Selectors.getPropertyCreatePropertyGroupAttempting(state),
    createPropertyGroupError: Selectors.getPropertyCreatePropertyGroupError(state),
    propertyGroupId: Selectors.getPropertyPropertyGroupId(state),

    createPropertyAttempting: Selectors.getPropertyCreatePropertyAttempting(state),
    createPropertyError: Selectors.getPropertyCreatePropertyError(state),
    propertyId: Selectors.getPropertyPropertyId(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setPropertyCreateModal: (state: boolean) => dispatch(Actions.propertyCreatePropertyModal(state)),
    createPropertyGroup: (name: string) => dispatch(Actions.createPropertyGroupAttempt({ name })),
    createProperty: (name: string) => dispatch(Actions.createPropertyAttempt({ name })),
});

export default connect(mapStateToProps, mapDispatchToProps)(propertyCreateModal);
