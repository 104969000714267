import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import { toast } from 'react-toastify';
import Actions from 'redux/Actions';
import { SetEditTransactionPayload } from 'redux/slices/packages/types';
import dayjs from 'dayjs';
import Selectors from 'redux/Selectors';

export default function* watchEditTransaction(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesEditTransactionAttempt', handleEditTransaction, api);
}

function* handleEditTransaction(api: PackagesGateway, data: SetEditTransactionPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const {
            propertyId,
            transactionId,
            description,
            transactionDate,
        } = data.payload;

        const response = yield* call([api, api.setPackagesEditTransaction], {
            propertyId,
            transactionId,
            description,
            transactionDate,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesEditTransactionFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            const transactionsFilter = yield* select(Selectors.getUiTransactionsFilter);

            yield put(Actions.packagesEditTransactionSuccess(response.data));
            yield put(Actions.setEditTransactionModal(false));
            yield put(Actions.packagesGetIViewAllTransactionsResponseAttempt(transactionsFilter));
            toast.success('Edit Transaction successfully!');
        } else {
        // handle error
            yield put(Actions.packagesEditTransactionFailure(response.message));
            toast.error(response.message);
        }
    }
}
