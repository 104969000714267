import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import { EditIPropertyManagementWithdrawalPayload } from 'redux/slices/packages/types';

export default function* watchEditPropertyManagementWithdrawal(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/editPropertyManagementWithdrawalAttempt', handleEditPropertyManagementWithdrawal, api);
}

function* handleEditPropertyManagementWithdrawal(api: PackagesGateway, data: EditIPropertyManagementWithdrawalPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { withdrawalId, status } = data.payload;

        const response = yield* call([api, api.editPropertyManagementWithdrawal], {
            withdrawalId,
            status,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.editPropertyManagementWithdrawalFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }

            yield put(Actions.editPropertyManagementWithdrawalSuccess(response.data));
        } else {
        // handle error
            yield put(Actions.editPropertyManagementWithdrawalFailure(response.message));
        }
    }
}
