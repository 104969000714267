import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { GetAllActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

export default function* watchGetProperty(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertyGetAllAttempt', handleGetProperty, api);
}

function* handleGetProperty(api: PropertyGateway, data: GetAllActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getProperty], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.propertyGetAllFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.propertyGetAllSuccess(response.data));
    } else {
        yield put(Actions.propertyGetAllFailure(response.message));
    }
}
