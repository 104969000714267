import { IFile, IPropertyPackages, IProperty, NumToBoolean } from '@dm/types';

export interface ICreateRecentEvent {
  ownerId: string,
  propertyId: string,
  category: number,
  status: number,
  message: string,
  saveAsTemplate: boolean
}

export enum IPackageTypeEnum {
  General = 0,
  VacantPossession = 1,
  DefectInspection = 2,
  DesignAndRenovation = 3,
  PropertyManagement = 4,
  LeaseAndSale = 5,
}

export type IPropertyPackagesPrice = Pick<IProperty, '_id'| 'packages'>

export interface ILeaseSale extends IPropertyPackages {
  leaseSaleDocuments: IFile[];
}

export interface IGetPropertyManagementWithdrawalParams {
  index: number,
  status: number,
  includePreviousOwner: NumToBoolean,
  search?: string,
  sort: number,
}

export interface IEditPropertyManagementWithdrawalParams {
  withdrawalId: string;
  status: number;
}
