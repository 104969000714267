import React from 'react';
import styled from 'styled-components';

import media from 'lib/Breakpoints';
import Wrapper from './Wrapper';

interface MainContainerProps {
  children: JSX.Element | JSX.Element[];
}

const MainContainer = ({ children }: MainContainerProps): JSX.Element => {
    return (
        <Wrapper>
            <BoxContent>{children}</BoxContent>
        </Wrapper>
    );
};

const BoxContent = styled.div`
  position: relative;
  min-height: 710px;
  background: #fff;
  padding-top: 0.1px; //need to give padding
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05);
`;

export default MainContainer;
