import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import SubAdminsGateway from 'api/Subadmins';

import { CreateNewSubAdminActionPayload } from 'redux/slices/subadmins/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';

export default function* watchCreateSubAdmin(api: SubAdminsGateway): SagaWatcherReturnType {
    yield takeEvery('subadmins/subAdminCreateNewAttempt', handleCreateSubAdmin, api);
}

function* handleCreateSubAdmin(api: SubAdminsGateway, data: CreateNewSubAdminActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.createSubAdmin], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Sub-Admin account has been successfully created');
        yield put(Actions.subAdminCreateNewSuccess());
        NavActions.navToReportSubAdmins();

        if (!response.data) {
            yield put(Actions.subAdminCreateNewFailure('Something went wrong. Please try again.'));
        }
    } else {
        yield put(Actions.subAdminCreateNewFailure(response.message));

        if (response.message && response.message.toLowerCase() === 'email exist') {
            toast.error('Registration failed! Email has been used');
        } else {
            toast.error('Registration failed! Please try again later');
        }
    }
}
