import React, { useState, useEffect, FunctionComponent } from 'react';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { Circles } from 'react-loader-spinner';
import { Colors } from '@dm/bigfish';
import Modal from 'components/Modal';

import styled from 'styled-components';
import { ViewAllPMTransactionResponse } from '@dm/types';

import SVG from 'react-inlinesvg';
import icons from 'assets/icons';

import InnerImageZoom from 'react-inner-image-zoom';
import { FileUrl } from 'entities/transactions';

interface FileListModalProps {
    isModalOpen: boolean;
    fileList: FileUrl[];
    setIsModalOpen: (state: boolean) => void;
    setDeleteImages: (category: number, id: string, name: string, fileName: string, fileUrl: string) => void;

    deleteImageAttempt: boolean;
    imageDeleteSuccess: string;
    imageDeleteFailed: string;

    fileCategory?: number;
    notDeletable?: boolean;
}

const FileListModal: FunctionComponent<FileListModalProps> = (props: FileListModalProps) => {
    const {
        isModalOpen,
        fileList,
        setIsModalOpen,
        setDeleteImages,
        deleteImageAttempt,
        imageDeleteFailed,
        imageDeleteSuccess,
        fileCategory,
        notDeletable,
    } = props;

    const [imageUrl, setImageUrl] = useState<string>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (fileList.length > 0) {
            setImageUrl(fileList[0].url);
        }
    }, [fileList]);

    useEffect(() => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }, [imageUrl]);

    const renderFileList = () => {
        if (fileList.length > 0) {
            return (
                <>
                    {
                        fileList.map((item) => {
                            const fileUrl = item.url.split('/');
                            const fileName = fileUrl[fileUrl.length - 1].split('?')[0];
                            const fileId = fileUrl[5];
                            const transactionId = fileUrl[4];

                            return (
                                <FileButton
                                    key={item.url}
                                    isSelected={item.url === imageUrl}
                                    onClick={() => setImageUrl(item.url)}
                                >
                                    <p>
                                        {fileName}
                                    </p>
                                    {
                                        !notDeletable
                                        && (
                                            <StyledIconButton
                                                onClick={() => setDeleteImages(fileCategory ?? 0, transactionId, fileId, fileName, item.url)}
                                                style={{ alignSelf: 'center' }}
                                            >
                                                <Icons src={icons.DeleteIcon} width='100%' height='20px' color='black' />
                                            </StyledIconButton>
                                        )
                                    }
                                </FileButton>
                            );
                        })
                    }
                </>
            );
        }

        return (
            <p>There is no documents available to view</p>
        );
    };

    const renderFileViewContainer = () => {
        if (isLoading || deleteImageAttempt) {
            return (
                <LoadingContainer>
                    <Circles color={Colors.primary} />
                </LoadingContainer>
            );
        }

        if (imageUrl) {
            const fileUrl = imageUrl.split('/');
            const fileName = fileUrl[fileUrl.length - 1].split('?')[0];
            const fileType = fileName.split('.')[1].toLowerCase();

            if (fileType === 'pdf') {
                return (
                    <iframe
                        src={imageUrl}
                        style={{ width: '700px', height: '600px' }}
                        id='FileViewContainer'
                        title='File view container'
                    />
                );
            }

            return (
                <InnerImageZoom
                    src={imageUrl}
                    zoomScale={1.5}
                />
            );
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                No Image
            </div>
        );
    };

    return (
        <Modal
            width='60%'
            height='850px'
            show={isModalOpen}
            closeCursor
            onClickClose={() => [setIsModalOpen(false), setImageUrl('')]}
        >
            <ModalTitle
                style={{

                }}
            >
                View Files
            </ModalTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', height: '90%' }}>
                <ButtonContainer>
                    {renderFileList()}
                </ButtonContainer>
                <FileViewContainer>
                    {renderFileViewContainer()}
                </FileViewContainer>
            </div>
        </Modal>
    );
};

FileListModal.defaultProps = {
    fileCategory: 0,
    notDeletable: false,
};

const ModalTitle = styled.div`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    gap: 20px;
`;

const FileViewContainer = styled.div`
    width: 68%;
    display: flex; justify-content: center;
    align-items: center;
    border: 4px dashed grey;
    border-radius: 10px;
    overflow: auto;
`;

const FileButton = styled.div<{isSelected: boolean}>`
    border: 2px solid ${Colors.primaryLight};
    background-color: ${props => (props.isSelected ? Colors.primaryLight : Colors.white)};
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &:hover{
        background-color: ${Colors.primaryLight};
        cursor: pointer;
    }
`;

const StyledIconButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all .2s;

    &:not(:disabled):active {
        transform: translateY(-3px);
    }

    &:disabled{
        cursor: not-allowed;
    }
`;

const Icons = styled(SVG)<{width: string, height?: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 500px;
`;

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.getTransactionGetFileViewModalOpen(state),
    fileList: Selectors.getTransactionGetFileList(state),

    deleteImageAttempt: Selectors.setPropertySetDeleteImageAttempting(state),
    imageDeleteFailed: Selectors.setPropertySetDeleteImageError(state),
    imageDeleteSuccess: Selectors.setPropertySetDeleteImage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsModalOpen: (state: boolean) => dispatch(Actions.setFileViewModalOpen(state)),
    setDeleteImages: (category: number, id: string, name: string, fileName: string, fileUrl: string) =>
        dispatch(Actions.propertySetDeleteImageAttempt({ category, id, name, fileName, fileUrl })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileListModal);
