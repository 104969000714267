import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    BreadCrumb,
    Colors,
    ImageCarousel,
    LineInput,
    Button,
    IImageCarousel,
    EventTrail,
    IEventTrail,
    Checkbox,
    CardCollapsible,
} from '@dm/bigfish';
import {
    FurnishingTypeEnum,
    IProperty,
    IPropertyPackageStatusEnum,
    LandTitleTypeEnum,
    PropertyStatus,
    PropertyTitleTypeEnum,
    PropertyTypeEnum,
    TenureTypeEnum,
} from '@dm/types';

import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import houseImg from 'assets/images/house1.png';
import { IPropertyGroupDetails } from 'entities/property';

import MainContainer from 'components/MainContainer';
import DateInput from 'components/DateInput';
import SelectionInput from 'components/SelectionInput';
import LoadingScreen from 'components/LoadingScreen';
import {
    PropertyTypeOptions,
    LandTitleOptions,
    PropertyTitleOptions,
    TenureTypeOptions,
    FurnishingTypeOptions,
} from 'components/options/Property';

import Map from './components/PropertyGroupMapDetails';

interface PropertyGroupDetailsProps {
    error: string;
    getOwnerDetails: (userId: string) => void;

    getPropertyGroupDetailsAttempt: boolean;
    propertyGroupDetails: IPropertyGroupDetails | null;
    getPropertyGroupDetails: (propertyGroupId: string) => void;
    clearPropertyGroupDetails: () => void;
}

const PropertyGroupDetails = (props: PropertyGroupDetailsProps): JSX.Element => {
    const {
        error,
        getOwnerDetails,

        getPropertyGroupDetailsAttempt,
        propertyGroupDetails,
        getPropertyGroupDetails,
        clearPropertyGroupDetails,
    } = props;

    const [propertyImageCarousel, setPropertyImageCarousel] = useState<IImageCarousel[]>([]);
    const [statePropertyName, setStatePropertyName] = useState('');
    const [statePropertyDesc, setStatePropertyDesc] = useState('');
    const [statePropertyType, setStatePropertyType] = useState<PropertyTypeEnum>(PropertyTypeEnum.Apartment);

    const [stateLandTitle, setStateLandTitle] = useState<LandTitleTypeEnum>(LandTitleTypeEnum.Commercial);
    const [stateUnitType, setStateUnitType] = useState('');

    const [statePropertyTitleType, setStatePropertyTitleType] = useState<PropertyTitleTypeEnum>(PropertyTitleTypeEnum.Individual);
    const [statePropertyTenure, setStatePropertyTenure] = useState<TenureTypeEnum>(TenureTypeEnum.FreeHold);
    const [statePropertyStorey, setStatePropertyStorey] = useState('');

    const [stateBuiltSize, setStateBuiltSize] = useState('');
    const [stateBuiltPrice, setStateBuiltPrice] = useState('');
    const [unitNumber, setUnitNumber] = useState('');

    const [stateRefNo, setStateRefNo] = useState('');
    const [stateBuiltDimension, setStateBuiltDimension] = useState('');
    const [statePostedDate, setStatePostedDate] = useState(new Date());

    const [stateFurnishing, setStateFurnishing] = useState<FurnishingTypeEnum>(FurnishingTypeEnum.None);
    const [stateOccupancy, setStateOccupancy] = useState('');
    const [stateFacingDirection, setStateFacingDirection] = useState('');
    const [stateRecentEvents, setStateRecentEvents] = useState<IEventTrail[]>([]);

    // property Amenities states
    const [television, setTelevision] = useState(false);
    const [curtain, setCurtain] = useState(false);
    const [mattress, setMattress] = useState(false);
    const [washingMachine, setWashingMachine] = useState(false);
    const [hoodHub, setHoodHub] = useState(false);
    const [airConditioner, setAirConditioner] = useState(false);
    const [waterHeater, setWaterHeater] = useState(false);
    const [diningTable, setDiningTable] = useState(false);
    const [wardrobe, setWardrobe] = useState(false);
    const [kitchenCabinet, setKitchenCabinet] = useState(false);
    const [oven, setOven] = useState(false);
    const [refrigerator, setRefrigerator] = useState(false);
    const [sofa, setSofa] = useState(false);
    const [microwave, setMicrowave] = useState(false);
    const [bedFrame, setBedFrame] = useState(false);
    const [internet, setInternet] = useState(false);

    const [bBQ, setBBQ] = useState(false);
    const [coveredParking, setCoveredParking] = useState(false);
    const [gymnasium, setGymnasium] = useState(false);
    const [basketballCourt, setBasketballCourt] = useState(false);
    const [restaurant, setRestaurant] = useState(false);
    const [airConditioner2, setAirConditioner2] = useState(false);
    const [dobiLaundromat, setDobiLaundromat] = useState(false);
    const [nursery, setNursery] = useState(false);
    const [playground, setPlayground] = useState(false);
    const [sauna, setSauna] = useState(false);
    const [swimmingPool, setSwimmingPool] = useState(false);
    const [tennisCourt, setTennisCourt] = useState(false);
    const [security24hour, setSecurity24hour] = useState(false);
    const [miniMart, setMiniMart] = useState(false);
    const [squashCourt, setSquashCourt] = useState(false);
    const [badmintonCourt, setBadmintonCourt] = useState(false);
    const [elevator, setElevator] = useState(false);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('PropertyRpDetails.PropertyRpDetailsBcDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: 'Property Group Listing',
                onClick: () => { NavActions.navToPropertyReports(); },
            },
            {
                label: ((propertyGroupDetails && propertyGroupDetails.data.name) ? propertyGroupDetails.data.name : 'Property Group Details'),
                onClick: () => { return false; },
            },
        ],
    );

    const params = useParams();
    const { id } = params;

    useEffect(() => {
        if (id) {
            getPropertyGroupDetails(id);
        }

        return () => {
            clearPropertyGroupDetails();
        };
    }, []);

    useEffect(() => {
        if (propertyGroupDetails) {
            const { _id, name, description = '' } = propertyGroupDetails.data;
            setStatePropertyName(name);
            setStatePropertyDesc(description);
            setStateRefNo(_id);

            if (propertyGroupDetails.data.details) {
                const {
                    type = PropertyTypeEnum.Apartment,
                    landTitle = LandTitleTypeEnum.Commercial,
                    titleType = PropertyTitleTypeEnum.Individual,
                    storey = '',
                    buildUpSize = 0,
                    buildUpPricePerSqft = 0,
                    builtUpDimension = '',
                    facingDirection = '',
                } = propertyGroupDetails.data.details;

                setStatePropertyType(type);
                setStateLandTitle(landTitle);
                setStatePropertyTitleType(titleType);
                setStatePropertyStorey(storey.toString());
                setStateBuiltSize(`${buildUpSize} sq. ft.`);
                setStateBuiltPrice(`${buildUpPricePerSqft} per sq. ft.`);
                setStateBuiltDimension(`${builtUpDimension}`);
                setStateFacingDirection(facingDirection);
            }

            createImageCarouselGallery();
        }
        if ((propertyGroupDetails?.data.amenities?.facilities.length !== 0)) {
            if (propertyGroupDetails?.data.amenities) {
                onAmenitiesDataPersistHandler();
            }
        }
    }, [propertyGroupDetails]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onAmenitiesDataPersistHandler = () => {
        setBBQ(propertyGroupDetails?.data.amenities?.facilities[0]?.available ?? false);
        setCoveredParking(propertyGroupDetails?.data.amenities?.facilities[1]?.available ?? false);
        setGymnasium(propertyGroupDetails?.data.amenities?.facilities[2]?.available ?? false);
        setBasketballCourt(propertyGroupDetails?.data.amenities?.facilities[3]?.available ?? false);
        setRestaurant(propertyGroupDetails?.data.amenities?.facilities[4]?.available ?? false);
        setAirConditioner2(propertyGroupDetails?.data.amenities?.facilities[5]?.available ?? false);
        setDobiLaundromat(propertyGroupDetails?.data.amenities?.facilities[6]?.available ?? false);
        setNursery(propertyGroupDetails?.data.amenities?.facilities[7]?.available ?? false);
        setPlayground(propertyGroupDetails?.data.amenities?.facilities[8]?.available ?? false);
        setSauna(propertyGroupDetails?.data.amenities?.facilities[9]?.available ?? false);
        setSwimmingPool(propertyGroupDetails?.data.amenities?.facilities[10]?.available ?? false);
        setTennisCourt(propertyGroupDetails?.data.amenities?.facilities[11]?.available ?? false);
        setSecurity24hour(propertyGroupDetails?.data.amenities?.facilities[12]?.available ?? false);
        setMiniMart(propertyGroupDetails?.data.amenities?.facilities[13]?.available ?? false);
        setSquashCourt(propertyGroupDetails?.data.amenities?.facilities[14]?.available ?? false);
        setBadmintonCourt(propertyGroupDetails?.data.amenities?.facilities[15]?.available ?? false);
        setElevator(propertyGroupDetails?.data.amenities?.facilities[16]?.available ?? false);
    };

    const createImageCarouselGallery = () => {
        const listImageCarousel: IImageCarousel[] = [];

        if (propertyGroupDetails?.data.images && propertyGroupDetails.data.images.length) {
            propertyGroupDetails.data.images.forEach(({ name, url }, mainIndex) => {
                url.forEach((imageUrl, imageUrlIndex) => {
                    const imagesObject = ({
                        image: imageUrl,
                        desc: `${name} ${imageUrlIndex + 1}`,
                    });

                    if (listImageCarousel.length < 5) {
                        listImageCarousel.push(imagesObject);
                    }

                    if (mainIndex === propertyGroupDetails.data.images.length - 1 && imageUrlIndex === url.length - 1) {
                        setPropertyImageCarousel(listImageCarousel);
                    }
                });
            });
        }
    };

    if (getPropertyGroupDetailsAttempt) {
        return (
            <MainContainer>
                <LoadingContainer>
                    <LoadingScreen />
                </LoadingContainer>
            </MainContainer>
        );
    }

    if (error) {
        return (
            <MainContainer>
                <LoadingContainer>
                    {error}
                </LoadingContainer>
            </MainContainer>
        );
    }

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <ImageCarouselContainer>
                    {propertyImageCarousel.length >= 1 && (
                        <ImageCarousel
                            style={{
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            data={propertyImageCarousel}
                        />
                    )}

                </ImageCarouselContainer>

                <DetailsViewContainer>
                    <DetailsInfoContainer>
                        <BasicDetailsContainer>
                            <Title>{statePropertyName || Translate.t('PropertyReport.Property')}</Title>
                            <Text>{statePropertyDesc}</Text>
                        </BasicDetailsContainer>
                        <PropertyDetailsContainer>
                            <Title>{Translate.t('PropertyRpDetails.PropertyRpDetailsTitlePropDetails')}</Title>
                            <PropertyDetailsInfoContainer>
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropType')}
                                    data={PropertyTypeOptions}
                                    currentValue={statePropertyType}
                                    onChangeSelection={(e) => setStatePropertyType(+e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelLandTitle')}
                                    data={LandTitleOptions}
                                    currentValue={stateLandTitle}
                                    onChangeSelection={(e) => setStateLandTitle(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelUnitType')}
                                    value={stateUnitType}
                                    onChangeText={(e) => setStateUnitType(e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropTitleType')}
                                    data={PropertyTitleOptions}
                                    currentValue={statePropertyTitleType}
                                    onChangeSelection={(e) => setStatePropertyTitleType(+e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelTenure')}
                                    data={TenureTypeOptions}
                                    currentValue={statePropertyTenure}
                                    onChangeSelection={(e) => setStatePropertyTenure(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelStorey')}
                                    value={statePropertyStorey}
                                    onChangeText={(e) => setStatePropertyStorey(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtSize')}
                                    value={stateBuiltSize}
                                    onChangeText={(e) => setStateBuiltSize(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtPrice')}
                                    value={stateBuiltPrice}
                                    onChangeText={(e) => setStateBuiltPrice(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtDim')}
                                    value={stateBuiltDimension}
                                    onChangeText={(e) => setStateBuiltDimension(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelRefNo')}
                                    value={stateRefNo}
                                    onChangeText={(e) => setStateRefNo(e.target.value)}
                                    disabled
                                />
                                <DateInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPostedDate')}
                                    date={statePostedDate}
                                    onChangeDate={(date) => setStatePostedDate(date)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelFurnishing')}
                                    data={FurnishingTypeOptions}
                                    currentValue={stateFurnishing}
                                    onChangeSelection={(e) => setStateFurnishing(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelOccupancy')}
                                    value={stateOccupancy}
                                    onChangeText={(e) => setStateOccupancy(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelFacingDirection')}
                                    value={stateFacingDirection}
                                    onChangeText={(e) => setStateFacingDirection(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label='unit number'
                                    value={unitNumber}
                                    onChangeText={e => setUnitNumber(e.target.value)}
                                    disabled
                                />

                            </PropertyDetailsInfoContainer>
                        </PropertyDetailsContainer>
                        <RoomDetailsContainer>
                            <Title>{Translate.t('PropertyRpDetails.PropertyRpDetailsTitleRoomDetails')}</Title>
                            <PropertyImageGalleryContainer>
                                {
                                    propertyGroupDetails?.data.images && propertyGroupDetails?.data.images.map((roomDetail, roomDetailIndex) => {
                                        const roomDetailKey = roomDetailIndex + roomDetail.name;

                                        return (
                                            propertyGroupDetails?.data.images.length ? (
                                                <PropertyImageGalleryCard key={roomDetailKey}>
                                                    <PropertyImageGalleryTitle>{roomDetail.name}</PropertyImageGalleryTitle>
                                                    <PropertyImageGallery>
                                                        {
                                                            roomDetail.url.map((roomImage, roomImageIndex) => {
                                                                const roomImageKey = roomImageIndex + roomImage;
                                                                return (
                                                                    <img src={roomImage} alt='room images' key={roomImageKey} />
                                                                );
                                                            })
                                                        }
                                                    </PropertyImageGallery>
                                                    {roomDetail?.url.length > 1 && <Text style={{ fontSize: '11px', lineHeight: 1, fontStyle: 'italic', color: Colors.greyDark, marginTop: '10px' }}>{Translate.t('PropertyRpDetails.PropertyRpDetailsInstruction')}</Text>}
                                                </PropertyImageGalleryCard>
                                            ) : (
                                                Translate.t('PropertyRpDetails.PropertyRpDetailsErrorNoImagesFound')
                                            ));
                                    })
                                }

                                { !propertyGroupDetails?.data.images.length && Translate.t('GeneralUI.Noimagesfound')}
                            </PropertyImageGalleryContainer>
                        </RoomDetailsContainer>
                        <BasicDetailsContainer>
                            <Title>{Translate.t('Property.PropertyAmenities.ItemTitleAmenities')}</Title>
                            <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFurnishing')}</ItemTitle>
                            <DetailsContainer>
                                <Checkbox label={Translate.t('Property.PropertyAmenities.TelevisionLabel')} checked={television} onClickCheckbox={() => setTelevision(!television)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.CurtainLabel')} checked={curtain} onClickCheckbox={() => setCurtain(!curtain)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MattressLabel')} checked={mattress} onClickCheckbox={() => setMattress(!mattress)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WashingMachineLabel')} checked={washingMachine} onClickCheckbox={() => setWashingMachine(!washingMachine)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.HoodHubLabel')} checked={hoodHub} onClickCheckbox={() => setHoodHub(!hoodHub)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner} onClickCheckbox={() => setAirConditioner(!airConditioner)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WaterHeaterLabel')} checked={waterHeater} onClickCheckbox={() => setWaterHeater(!waterHeater)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.DiningTableLabel')} checked={diningTable} onClickCheckbox={() => setDiningTable(!diningTable)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.WardrobeLabel')} checked={wardrobe} onClickCheckbox={() => setWardrobe(!wardrobe)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.KitchenCabinetLabel')} checked={kitchenCabinet} onClickCheckbox={() => setKitchenCabinet(!kitchenCabinet)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.OvenLabel')} checked={oven} onClickCheckbox={() => setOven(!oven)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.RefrigeratorLabel')} checked={refrigerator} onClickCheckbox={() => setRefrigerator(!refrigerator)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SofaLabel')} checked={sofa} onClickCheckbox={() => setSofa(!sofa)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MicrowaveLabel')} checked={microwave} onClickCheckbox={() => setMicrowave(!microwave)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BedframeLabel')} checked={bedFrame} onClickCheckbox={() => setBedFrame(!bedFrame)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.InternetLabel')} checked={internet} onClickCheckbox={() => setInternet(!internet)} />
                            </DetailsContainer>
                            <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFacilities')}</ItemTitle>
                            <DetailsContainer>
                                <Checkbox label='BBQ' checked={bBQ} onClickCheckbox={() => setBBQ(!bBQ)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.CoveredParkingLabel')} checked={coveredParking} onClickCheckbox={() => setCoveredParking(!coveredParking)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.GymnasiumLabel')} checked={gymnasium} onClickCheckbox={() => setGymnasium(!gymnasium)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BasketballCourtLabel')} checked={basketballCourt} onClickCheckbox={() => setBasketballCourt(!basketballCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.RestaurantLabel')} checked={restaurant} onClickCheckbox={() => setRestaurant(!restaurant)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner2} onClickCheckbox={() => setAirConditioner2(!airConditioner2)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.DobiLabel')} checked={dobiLaundromat} onClickCheckbox={() => setDobiLaundromat(!dobiLaundromat)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.NurseryLabel')} checked={nursery} onClickCheckbox={() => setNursery(!nursery)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.PlaygroundLabel')} checked={playground} onClickCheckbox={() => setPlayground(!playground)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SaunaLabel')} checked={sauna} onClickCheckbox={() => setSauna(!sauna)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SwimmingPoolLabel')} checked={swimmingPool} onClickCheckbox={() => setSwimmingPool(!swimmingPool)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.TennisCourtLabel')} checked={tennisCourt} onClickCheckbox={() => setTennisCourt(!tennisCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SecurityLabel')} checked={security24hour} onClickCheckbox={() => setSecurity24hour(!security24hour)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.MiniMartLabel')} checked={miniMart} onClickCheckbox={() => setMiniMart(!miniMart)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.SquashCourtLabel')} checked={squashCourt} onClickCheckbox={() => setSquashCourt(!squashCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.BadmintonCourtLabel')} checked={badmintonCourt} onClickCheckbox={() => setBadmintonCourt(!badmintonCourt)} />
                                <Checkbox label={Translate.t('Property.PropertyAmenities.ElevatorLabel')} checked={elevator} onClickCheckbox={() => setElevator(!elevator)} />
                            </DetailsContainer>
                        </BasicDetailsContainer>
                    </DetailsInfoContainer>
                    <PropertyControlContainerMain>
                        <PropertyMapContainer>
                            {propertyGroupDetails?.data.location?.address1.length ? (
                                <Map propertyInfo={propertyGroupDetails} />
                            ) : (
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    {Translate.t('PropertyReport.PropertyRpDMapNoLocation')}
                                </div>
                            )}
                        </PropertyMapContainer>
                    </PropertyControlContainerMain>

                </DetailsViewContainer>
            </MainContainer>
        </>

    );
};

const ItemTitle = styled.h5`
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 16px;
    > li:not(:first-child){
        margin-top: 16px;
    }
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const LoadingContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageCarouselContainer = styled.div`
    width: 100%;
    margin-top: 20px;
`;

const DetailsViewContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
`;

const Title = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: capitalize;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const DetailsInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-right: 15px;

    > div {
        padding: 20px;
        box-sizing: border-box;
        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        margin-bottom: 40px;
    }
`;

const BasicDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsInfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const RoomDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PropertyImageGalleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
`;

const PropertyImageGalleryCard = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const PropertyImageGalleryTitle = styled.h5`
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 24px;
`;

const PropertyImageGallery = styled.div`
    display: flex;
    width: 100%;
    height: 340px;
    overflow-x: auto;

    img {
        display: inline-block;
        width: 90%;
        height: 100%;
        object-fit: cover;
    }
`;

const PropertyMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const PropertyControlContainerMain = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    padding-inline: 30px;
`;

const mapStateToProps = (state: RootState) => ({
    getPropertyGroupDetailsAttempt: Selectors.getPropertyGetPropertyGroupDetailsAttempting(state),
    error: Selectors.getPropertyGetDetailsError(state),
    propertyGroupDetails: Selectors.getPropertyPropertyGroupDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyGroupDetails: (propertyGroupId: string) => dispatch(Actions.getPropertyGroupDetailsAttempt({ propertyGroupId })),
    clearPropertyGroupDetails: () => dispatch(Actions.clearPropertyGroupDetails()),
    setServicePackageModalOpen: (state: boolean) => dispatch(Actions.setServicePackageModalOpen(state)),
    setUpdatePackagePriceModalOpen: (state: boolean) => dispatch(Actions.setUpdatePackagePriceModalOpen(state)),
    setGoogleDrivePathModalOpen: (state: boolean) => dispatch(Actions.setGoogleDrivePathModalOpen(state)),
    setRejectPropertModalOpen: (state: boolean) => dispatch(Actions.setRejectPropertyModalOpen(state)),
    setTenancyStatusPropertModalOpen: (state: boolean) => dispatch(Actions.setVacancyStatusPropertyModalOpen(state)),
    getOwnerDetails: (userId: string) => dispatch(Actions.getOwnerDetailsAttempt(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyGroupDetails);
