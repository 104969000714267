import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import { Bars } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { GetREADetailsResponse } from '@dm/types';
import { BreadCrumb, Colors, Paginator, ContactButton, SearchBar } from '@dm/bigfish';

import { IGetRea } from 'entities/rea';

import MainContainer from 'components/MainContainer';

interface ReaReportProps {
    getReaLoading: boolean;
    getReaError: string;
    reaReport: GetREADetailsResponse;
    getRea: (data: IGetRea) => void;
    clearReaReport: () => void;
    setTabView: (tabView: string) => void;
    getUserInfoData: () => void;
  }

const ReaReport = (props : ReaReportProps): JSX.Element => {
    const { getReaLoading, getReaError, reaReport, getRea, clearReaReport, setTabView, getUserInfoData } = props;

    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('ReaReport.ReaReportBcDashboard'),
                onClick: () => { NavActions.navToReaReport(); },
            },
            {
                label: Translate.t('ReaReport.ReaReportBcRea'),
            },
        ],
    );

    useEffect(() => {
        getRea({ index: 1 });
        setTabView('agents');
        getUserInfoData();
        return () => {
            clearReaReport();
        };
    }, []);

    useEffect(() => {
        const { maxIndex } = reaReport;

        setPaginationData(createPaginationData(maxIndex));
        setMaxPages(maxIndex);
    }, [reaReport]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        if (currentNumber !== paginationNumber) {
            getRea({ index: currentNumber, search: searchKeyword });
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);
        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        if (currentNumber !== paginationNumber) {
            getRea({ index: currentNumber, search: searchKeyword });
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);
        if (paginationNumber !== 1) {
            getRea({ index: 1, search: searchKeyword });
            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);
        if (paginationNumber !== maxPages) {
            getRea({ index: maxPages, search: searchKeyword });
            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);
        if (pages !== paginationNumber) {
            getRea({ index: pages, search: searchKeyword });
            winScrollToTop();
        }
    };

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        if (searchWord) {
            getRea({ index: 1, search: searchWord });
        } else {
            getRea({ index: 1 });
        }
    }, 500, { leading: false }));

    const onSearchRea = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKeyword(value);

        throttledSetSearch.current(value);
    };

    const renderReaListing = () => {
        const { data } = reaReport;

        if (getReaLoading) {
            return (
                <LoadingContainer>
                    <Bars color={Colors.primary} height={260} width={260} />
                </LoadingContainer>
            );
        }

        return (
            <>
                {data.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}
                <ReportListingTable>
                    <ReportListingHeader>
                        <ReportHeader>{Translate.t('ReaReport.ReaReportHeaderEmail')}</ReportHeader>
                        <ReportHeader>{Translate.t('ReaReport.ReaReportHeaderFirstName')}</ReportHeader>
                        <ReportHeader>{Translate.t('ReaReport.ReaReportHeaderLastName')}</ReportHeader>
                        <ReportHeader>{Translate.t('ReaReport.ReaReportHeaderPhoneNo')}</ReportHeader>
                    </ReportListingHeader>

                    {
                        data.map((reaDetail) => {
                            const {
                                _id,
                                email = '',
                                firstName = '',
                                lastName = '',
                                phoneNumber = '',
                            } = reaDetail;

                            return (
                                <ReportListingColumn key={`my id is ${_id}`} onClick={() => NavActions.navToReaDetails(_id || '')}>
                                    <ContactButton name={email} />
                                    <ReportColumn>{firstName}</ReportColumn>
                                    <ReportColumn>{lastName}</ReportColumn>
                                    <ReportColumn>{phoneNumber}</ReportColumn>
                                </ReportListingColumn>
                            );
                        })
                    }

                    {(!data.length && !getReaError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('OwnersReport.OwnersReportError1')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError2')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError3')}</p>
                        </ReportListingColumnEmpty>
                    )}

                    {(!data.length && getReaError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('OwnersReport.OwnersReportError4')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError5')}</p>
                            <p>{Translate.t('OwnersReport.OwnersReportError6')}</p>
                        </ReportListingColumnEmpty>
                    )}

                </ReportListingTable>
                {data.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}
            </>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <ReportContainer>
                    <Title>
                        {Translate.t('ReaReport.ReaReportSearchTitle')}
                    </Title>
                    <StyledSearchBar
                        title={Translate.t('ReaReport.ReaReportBcRea')}
                        value={searchKeyword}
                        onChangeText={onSearchRea}
                    />
                    <ReportContentContainer>
                        <ReportListingContainer>
                            {renderReaListing()}
                        </ReportListingContainer>
                    </ReportContentContainer>
                </ReportContainer>
            </MainContainer>
        </>
    );
};

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 20px 120px 20px;

  color: ${Colors.black};
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 40px;
`;

const ReportContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ReportListingContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 100%;
`;

const ReportListingTable = styled.div`
  width: 100%;

  margin: 20px 0 20px 0;

  > div:not(:last-child){
    margin-bottom: 8px;
  }
`;

const ReportListingHeader = styled.div`
  display: grid;
  grid-template-columns: 280px repeat(3, 1fr);
  align-items: center;

  padding: 16px 9px;

  box-sizing: border-box;
  background: ${Colors.white};
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

const ReportHeader = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;

  &:not(:first-child){
    text-align: center;
  }
`;

const ReportListingColumn = styled.button`
  display: grid;
  grid-template-columns: 280px repeat(3, 1fr);
  align-items: center;

  width: 100%;
  padding: 8px;

  background: ${Colors.primaryLightest};

  box-sizing: border-box;
  border: none;
  border-radius: 12px;

  margin-bottom: 5px;

  cursor: pointer;
`;

const ReportColumn = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  
  color: ${Colors.greyDark};
  text-align: center;
`;

const ReportListingColumnEmpty = styled.div`
  padding: 20px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  
  color: ${Colors.greyDark};
  background: ${Colors.primaryLightest};

  box-sizing: border-box;
  border-radius: 12px;

  text-align: center;

  > p {
    margin: 0px;
    padding: 0px;
  }

  > p:not(:last-child) {
    margin-bottom: 3px;
  }

  > p:first-child{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 14px;
  }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 80%;
    height: 580px;
`;

const mapStateToProps = (state: RootState) => ({
    getReaLoading: Selectors.getReaGetReaAttempting(state),
    getReaError: Selectors.getReaGetReaError(state),
    reaReport: Selectors.getReaReaReport(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getRea: (data: IGetRea) =>
        dispatch(Actions.getReaAttempt(data)),
    clearReaReport: () => dispatch(Actions.clearReaReport()),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReaReport);
