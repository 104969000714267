import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import { Bars } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { BreadCrumb, Colors, Paginator, ContactButton, Button, SearchBar } from '@dm/bigfish';

import MainContainer from 'components/MainContainer';

import { ISubAdminsReport, IGetAllSubAdmins, ISubAdminPropertyWithId } from 'entities/subadmins';
import CreateSubAdminModal from './components/createSubAdminModal';
import ViewSubAdminPropertyModal from './components/viewSubAdminPropertyModal';

import EyeIcon from '../../assets/icons/eye-view.svg';

interface SubAdminReportProps {
    setPropertyList(data: ISubAdminPropertyWithId): void;

    getSubAdminReportLoading: boolean;
    getSubAdminReportError: string;
    subAdminsReport: ISubAdminsReport;
    getSubAdmins: (data: IGetAllSubAdmins) => void;
    clearSubAdminReport: () => void;

    setTabView: (tabView: string) => void;
    setCreateSubAdminModal: (state: boolean) => void;
    setViewSubAdminPropertyModal: (state: boolean) => void;
    getUserInfoData: () => void;

}

const SubAdminReport = (props: SubAdminReportProps): JSX.Element => {
    const { setPropertyList,
        getSubAdminReportLoading,
        getSubAdminReportError,
        subAdminsReport,
        getSubAdmins,
        clearSubAdminReport,
        setTabView,
        setCreateSubAdminModal,
        setViewSubAdminPropertyModal,
        getUserInfoData } = props;

    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('SubAdmins.SubAdminsBcDashboard'),
                onClick: () => { NavActions.navToReportSubAdmins(); },
            },
            {
                label: Translate.t('SubAdmins.SubAdminsBcSubAdmins'),
            },
        ],
    );

    useEffect(() => {
        getSubAdmins({ index: 1 });
        setTabView('sub-admins');
        getUserInfoData();

        return () => {
            clearSubAdminReport();
        };
    }, []);

    useEffect(() => {
        const { maxIndex } = subAdminsReport;
        setPaginationData(createPaginationData(maxIndex));
        setMaxPages(maxIndex);
    }, [subAdminsReport]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        if (currentNumber !== paginationNumber) {
            getSubAdmins({ index: currentNumber, query: searchKeyword });
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);
        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        if (currentNumber !== paginationNumber) {
            getSubAdmins({ index: currentNumber, query: searchKeyword });
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);
        if (paginationNumber !== 1) {
            getSubAdmins({ index: 1, query: searchKeyword });
            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);
        if (paginationNumber !== maxPages) {
            getSubAdmins({ index: maxPages, query: searchKeyword });
            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);
        if (pages !== paginationNumber) {
            getSubAdmins({ index: pages, query: searchKeyword });
            winScrollToTop();
        }
    };

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        if (searchWord) {
            getSubAdmins({ index: 1, query: searchWord });
        } else {
            getSubAdmins({ index: 1 });
        }
    }, 500, { leading: false }));

    const onSearchSubAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKeyword(value);

        throttledSetSearch.current(value);
    };

    const renderSubAdminsListing = () => {
        const { data } = subAdminsReport;

        if (getSubAdminReportLoading) {
            return (
                <LoadingContainer>
                    <Bars color={Colors.primary} height={260} width={260} />
                </LoadingContainer>
            );
        }

        return (
            <>
                {data.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}
                <ReportListingTable>
                    <ReportListingHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminsHeaderName')}</ReportHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminsHeaderEmail')}</ReportHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminsHeaderPhone')}</ReportHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminsHeaderAction')}</ReportHeader>
                    </ReportListingHeader>

                    {
                        data.map((subadmins) => {
                            const {
                                userId = '',
                                name = '',
                                email = '',
                                phoneNumber = '',
                                propertiesAccess = [],
                            } = subadmins;

                            const propertyWithSubAdminId = {
                                userId,
                                access: propertiesAccess,
                            };

                            return (
                                <ReportListingColumn key={`key is ${name}`}>
                                    <ContactButton name={name} />
                                    <ReportColumn>{email || ''}</ReportColumn>
                                    <ReportColumn>{phoneNumber || ''}</ReportColumn>
                                    <ReportColumn>
                                        <ViewPropertyButton
                                            onClick={() => [setViewSubAdminPropertyModal(true), setPropertyList(propertyWithSubAdminId)]}
                                        >
                                            <EyeIconContainer src={EyeIcon} />
                                        </ViewPropertyButton>

                                    </ReportColumn>
                                </ReportListingColumn>
                            );
                        })
                    }

                    {(!data.length && !getSubAdminReportError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('SubAdmins.SubAdminsReportError1')}</p>
                            <p>{Translate.t('SubAdmins.SubAdminsReportError2')}</p>
                            <p>{Translate.t('SubAdmins.SubAdminsReportError3')}</p>
                        </ReportListingColumnEmpty>
                    )}

                    {(!data.length && getSubAdminReportError) && (
                        <ReportListingColumnEmpty>
                            <p>{Translate.t('SubAdmins.SubAdminsReportError4')}</p>
                            <p>{Translate.t('SubAdmins.SubAdminsReportError5')}</p>
                            <p>{Translate.t('SubAdmins.SubAdminsReportError6')}</p>
                        </ReportListingColumnEmpty>
                    )}

                </ReportListingTable>
                {data.length >= 1 && <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}

            </>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <ReportContainer>
                    <TitleAlign>
                        <div>
                            <Title>
                                {Translate.t('SubAdmins.SubAdminsSearchTitle')}
                            </Title>
                            <StyledSearchBar
                                title={Translate.t('SubAdmins.SubAdminsFilterTitle')}
                                value={searchKeyword}
                                onChangeText={onSearchSubAdmin}
                            />
                        </div>
                        <CreateSubAdminButton
                            label={Translate.t('SubAdmins.SubAdminsBtCreateSubAdmin')}
                            onClick={() => { setCreateSubAdminModal(true); }}
                        />
                    </TitleAlign>
                    <ReportContentContainer>
                        <ReportListingContainer>
                            {renderSubAdminsListing()}
                        </ReportListingContainer>
                    </ReportContentContainer>
                </ReportContainer>
                <CreateSubAdminModal />
                <ViewSubAdminPropertyModal />
            </MainContainer>
        </>
    );
};

const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 120px 20px;
    color: ${Colors.black};
`;

const TitleAlign = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 40px;
`;

const CreateSubAdminButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 300px;
    max-height: 50px;
    margin-top: 30px;
    > button {
        font-size: 12px;
        line-height: 14px;
        padding: 8px;
    }
`;

const ReportContentContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const ReportListingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const ReportListingTable = styled.div`
    width: 100%;
    margin: 20px 0 20px 0;

    > div:not(:last-child){
        margin-bottom: 8px;
    }
`;

const ReportListingHeader = styled.div`
    display: grid;
    grid-template-columns: 280px repeat(3, 1fr);
    align-items: center;
    padding: 16px 9px;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const ReportHeader = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-transform: capitalize;

    &:not(:first-child){
        text-align: center;
    }
`;

const ReportListingColumn = styled.button`
    display: grid;
    grid-template-columns: 280px repeat(3, 1fr);
    align-items: center;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: none;
    border-radius: 12px;
    margin-bottom: 5px;
    cursor: pointer;

    background: ${Colors.primaryLightest};
`;

const ReportColumn = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;

    color: ${Colors.greyDark};
`;

const ReportListingColumnEmpty = styled.div`
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: center;

    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    > p {
        margin: 0px;
        padding: 0px;
    }

    > p:not(:last-child) {
        margin-bottom: 3px;
    }

    > p:first-child{
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 14px;
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 580px;
`;

const ViewPropertyButton = styled.button`
    display: flex;
    font-weight: 400;
    font-size: 18px;
    margin-left: 145px;
    background-color: ${Colors.primary};
    color: ${Colors.white};
    opacity: 1;
    border-radius: 9px;
    border: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    height: 40px;
    width: 70px;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;

    transition: all .3s;

    &:hover{
        opacity: 0.7;
    };

    &:active {
        transform: translateY(-3px);
    }
`;

const EyeIconContainer = styled.img`
    width: 30px;
    height: 30px;
    margin: 15px;
`;

const mapStateToProps = (state: RootState) => ({
    getSubAdminReportLoading: Selectors.getSubAdminGetSubAdminReportAttempt(state),
    getSubAdminReportError: Selectors.getSubAdminGetSubAdminReportError(state),
    subAdminsReport: Selectors.getSubAdminsAdminsReport(state),
    isViewSubAdminPropertyModalOpen: Selectors.setSubAdminViewSubAdminPropertyModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getSubAdmins: (data: IGetAllSubAdmins) =>
        dispatch(Actions.subAdminGetAllAttempt(data)),
    clearSubAdminReport: () => dispatch(Actions.clearSubAdminsReport()),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    setCreateSubAdminModal: (state: boolean) => dispatch(Actions.setCreateSubAdminModal(state)),
    setViewSubAdminPropertyModal: (state: boolean) => dispatch(Actions.setViewSubAdminPropertyModal(state)),
    setPropertyList: (data: ISubAdminPropertyWithId) => dispatch(Actions.setPropertyList(data)),
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminReport);
