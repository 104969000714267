import React, { useState } from 'react';

import styled from 'styled-components';
import Translate from 'lib/translate';
import SVG from 'react-inlinesvg';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import PropertyImage from 'assets/images/house1.png';

import { Button, Colors, SearchBar } from '@dm/bigfish';
import { IDeleteProperty, ISubAdminProperty, ISubAdminPropertyWithId } from 'entities/subadmins';
import { Oval } from 'react-loader-spinner';
import Modal from '../../../components/Modal';
import AddPropertyModal from './addPropertyModal';

import DeleteIcon from '../../../assets/icons/delete-icon.svg';

export interface ViewSubAdminPropertyModalProps {
    // setCreateProperty: (name: string) => void;
    getPropertyList: ISubAdminPropertyWithId;
    setPropertyList(data: ISubAdminPropertyWithId): void;

    removePropertyLoading: boolean;
    removePropertyError: string;
    removeProperty:(data: IDeleteProperty) => void;

    isViewSubAdminPropertyModalOpen: boolean;
    setViewSubAdminPropertyModal: (state: boolean) => void;
    setAddPropertyModal: (state: boolean) => void;
}
const ViewSubAdminPropertyModal = (props: ViewSubAdminPropertyModalProps): JSX.Element => {
    const { getPropertyList,
        setPropertyList,
        removePropertyLoading,
        removePropertyError,
        removeProperty,
        setViewSubAdminPropertyModal,
        isViewSubAdminPropertyModalOpen,
        setAddPropertyModal } = props;

    const [searchKeyword, setSearchKeyword] = useState('');

    const deleteButtonClicker = (userId: string, propertyId: string) => {
        const deletePropertydata = {
            propertyId,
            userId,
        };
        removeProperty(deletePropertydata);

        const filteredProperty = { userId, access: getPropertyList.access.filter((item) => item.propertyId !== propertyId) };
        setPropertyList(filteredProperty);
    };

    const propertiesDataList = () => {
        if (removePropertyLoading) {
            return (
                <LoadingContainer>
                    <Oval
                        height={100}
                        width={100}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </LoadingContainer>

            );
        }

        if (removePropertyError) {
            return (
                <ReportListingColumnEmpty>
                    <p>{Translate.t('SubAdmins.SubAdminsReportError1')}</p>
                    <p>{Translate.t('SubAdmins.SubAdminsReportError2')}</p>
                    <p>{Translate.t('SubAdmins.SubAdminsReportError3')}</p>
                </ReportListingColumnEmpty>
            );
        }

        if (getPropertyList.access.length === 0) {
            return (
                <ReportListingColumnEmpty>
                    <p>{Translate.t('SubAdmins.SubAdminsReportError1')}</p>
                    <p>{Translate.t('SubAdmins.SubAdminsReportError2')}</p>
                    <p>{Translate.t('SubAdmins.SubAdminsReportError3')}</p>
                </ReportListingColumnEmpty>
            );
        }
        return (
            getPropertyList?.access
                    && getPropertyList.access.filter((row) =>
                        !searchKeyword.length || row.propertyName
                            .toString()
                            .toLowerCase()
                            .includes(searchKeyword.toString().toLowerCase()))
                        .map((property: ISubAdminProperty) => {
                            const {
                                propertyId = '',
                                propertyName = '',
                                unitNo = '',
                                accessLevel = 0,
                            } = property;

                            let accessLevelTitle = '';

                            switch (accessLevel) {
                                case 0:
                                    accessLevelTitle = Translate.t('SubAdmins.SubAdminProperty.AccessLevelReadWrite');
                                    break;
                                case 1:
                                    accessLevelTitle = Translate.t('SubAdmins.SubAdminProperty.AccessLevelReadOnly');
                                    break;
                                default:
                                    accessLevelTitle = 'not assigned yet!';
                                    break;
                            }

                            return (
                                <ReportListingColumn key={propertyId}>

                                    <ReportColumn>
                                        <ImageContainer
                                            alt='properties images'
                                            src={PropertyImage}
                                        />
                                        {propertyName || ''}
                                    </ReportColumn>
                                    <ReportColumn>{unitNo || ''}</ReportColumn>
                                    <ReportColumn>{accessLevelTitle || ''}</ReportColumn>
                                    <ReportColumn>
                                        <DeletePropertyButton
                                            onClick={() => deleteButtonClicker(getPropertyList.userId, propertyId)}
                                        >
                                            <SVG src={DeleteIcon} height={20} strokeWidth={0} />
                                        </DeletePropertyButton>
                                    </ReportColumn>
                                </ReportListingColumn>
                            );
                        })
        );
    };

    return (
        <MainContainer>
            <Modal show={isViewSubAdminPropertyModalOpen} width='60vw'>
                <ContainerTitle>
                    <Title>
                        {Translate.t('SubAdmins.SubAdminProperty.PropertyTitle')}
                    </Title>
                    <SpanContainer aria-hidden='true' role='button' onClick={() => setViewSubAdminPropertyModal(false)}>
                        <h3>&#88;</h3>
                    </SpanContainer>
                </ContainerTitle>
                <ButtonPlaceholder>
                    <SearchBar
                        title='find property'
                        value={searchKeyword}
                        onChangeText={(e) => setSearchKeyword(e.target.value)}
                    />
                    <AddPropertyButton
                        label={Translate.t('SubAdmins.SubAdminAddProperty.AddNewProperty')}
                        onClick={() => [setAddPropertyModal(true)]}
                    />
                </ButtonPlaceholder>

                <ReportListingTable rowCount={getPropertyList.access.length}>
                    <ReportListingHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminProperty.PropertyName')}</ReportHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminProperty.PropertyUnit')}</ReportHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminProperty.PropertyAccessLevel')}</ReportHeader>
                        <ReportHeader>{Translate.t('SubAdmins.SubAdminProperty.PropertyAction')}</ReportHeader>
                    </ReportListingHeader>
                    {propertiesDataList()}
                </ReportListingTable>
            </Modal>
            <AddPropertyModal subAdminPropertyData={getPropertyList} />
        </MainContainer>
    );
};

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const ContainerTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.h2`
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    background-color: #ffffff;
    margin-bottom: 40px;
`;

const SpanContainer = styled.div`
cursor: pointer;
`;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const AddPropertyButton = styled(Button)`
    display: flex;
    align-items: center;
    height: 40px;
    align-self: end;
`;

const ReportListingTable = styled.div<{rowCount: number}>`
    width: 100%;
    margin: 20px 0 20px 0;
    max-height: 50vh;

    overflow-y: ${props => (props.rowCount < 4 ? 'none' : 'scroll')};
    > div:not(:last-child){
        margin-bottom: 8px;
    }
`;

const ReportListingHeader = styled.div`
    display: grid;
    grid-template-columns: 280px repeat(3, 1fr);
    align-items: center;
    padding: 16px 9px;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const ReportHeader = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;

    &:first-child{
        margin-left: 100px;
    }
`;

const ReportListingColumn = styled.button`
    display: grid;
    grid-template-columns: 280px repeat(3, 1fr);
    align-items: center;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: none;
    border-radius: 12px;
    margin-bottom: 5px;
    cursor: pointer;

    background: ${Colors.primaryLightest};
`;

const ReportColumn = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    align-items: center;
    justify-content: center;

    color: ${Colors.greyDark};

    &:first-child{
        justify-content: left;
        gap: 30px;
    }

`;

const ReportListingColumnEmpty = styled.div`
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: center;

    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    > p {
        margin: 0px;
        padding: 0px;
    }

    > p:not(:last-child) {
        margin-bottom: 3px;
    }

    > p:first-child{
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 14px;
    }
`;
const DeletePropertyButton = styled.button`
    display: flex;
    font-weight: 400;
    font-size: 18px;
    background-color: ${Colors.primary};
    color: ${Colors.white};
    opacity: 1;
    border-radius: 9px;
    border: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    height: 40px;
    width: 60px;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;

    transition: all .3s;

    &:hover{
        opacity: 0.7;
    };

    &:active {
        transform: translateY(-3px);
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
`;

const ImageContainer = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 12px;
    padding: 5px;
    justify-content:left;
`;

const mapStateToProps = (state: RootState) => ({
    removePropertyLoading: Selectors.setSubAdminsSetDeletePropertyLoading(state),
    removePropertyError: Selectors.setSubAdminsSetDeletePropertyError(state),
    isViewSubAdminPropertyModalOpen: Selectors.setSubAdminViewSubAdminPropertyModal(state),
    getPropertyList: Selectors.getSubAdminsGetPropertyList(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setViewSubAdminPropertyModal: (state: boolean) => dispatch(Actions.setViewSubAdminPropertyModal(state)),
    setAddPropertyModal: (state: boolean) => dispatch(Actions.setViewAddPropertyModal(state)),
    removeProperty: (data: IDeleteProperty) => dispatch(Actions.deletePropertyAttempt(data)),
    setPropertyList: (data: ISubAdminPropertyWithId) => dispatch(Actions.setPropertyList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubAdminPropertyModal);
