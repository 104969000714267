import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Oval } from 'react-loader-spinner';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { BreadCrumb, Colors, Fonts, TabView } from '@dm/bigfish';
import { Category, IDefectInspection, IFile, IVacantPossession, SortByEnum } from '@dm/types';

import NavActions from 'lib/NavActions';

import MainContainer from 'components/MainContainer';

import InlineSelectionInput from 'components/InlineSelectionInputViewDocument';

import ButtonInfoPackages from 'components/ButtonViewAndUploadPackages';
import PackagesListCard from '../PackagesListCard';
import ImagesModal from '../DocumentImagesModal';
import SupportInfo from '../../../components/SupportInfo';
import Icons from '../../../assets/icons';
import QuickNavigationButton from '../../../components/QuickNavigationButton';

interface DefectInspectionPackagesProps {
    loadingDefectDocumentData: boolean;
    errorDefectDocumentData: string;
    defectDocumentData: IDefectInspection | null;
    packagesDataSort (category: number, propertyId: string, sort: number): void;
    setTabView: (tabView: string) => void;
}

interface NavParams {
    propertyIndividualId: string;
}

const DefectInspectionViewDocuments: FunctionComponent<DefectInspectionPackagesProps> = (props: DefectInspectionPackagesProps) => {
    const {
        loadingDefectDocumentData,
        errorDefectDocumentData,
        defectDocumentData,
        packagesDataSort,
        setTabView,
    } = props;

    const [selectedDocuments, setSelectedDocuments] = useState<IFile[]>([]);
    const [defectInspectionDocumentList, setDefectInspectionDocumentList] = useState<IFile[]>([]);
    const [dateOrder, setDateOrder] = useState<SortByEnum>(SortByEnum.createdAtDesc);
    const [homeScreenTabView, setHomeScreenTabView] = useState('');
    const useLocationstate = useLocation();
    const { propertyIndividualId } = useLocationstate.state as NavParams;
    const [tabViewData] = useState([
        {
            text: 'all',
            onClickTabView: () => {
                setHomeScreenTabView('all');
            },
        },
    ]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('GeneralUI.BreadcrumblabelViewDocuments'),
                onClick: () => { return false; },
            },
        ],
    );

    interface IOption {
        id: string | number,
      description: string | number,
    }

    const dateData: IOption[] = [{
        id: 0,
        description: Translate.t('Packages.ViewDocuments.DateDecending'),
    },
    {
        id: 1,
        description: Translate.t('Packages.ViewDocuments.DateAscending'),
    }];

    const alphabeticalOrder: IOption[] = [
        {
            id: 2,
            description: Translate.t('Packages.ViewDocuments.NameAscendingOrder'),
        }, {
            id: 3,
            description: Translate.t('Packages.ViewDocuments.NameDecendingOrder'),
        },
    ];

    const listHeaders: string[] = [Translate.t('Packages.ViewDocuments.FileName'), Translate.t('Packages.ViewDocuments.DateUploaded'), Translate.t('Packages.ViewDocuments.Actions')];

    useEffect(() => {
        packagesDataSort(Category.DefectInspection, propertyIndividualId, dateOrder);
        setHomeScreenTabView('all');
    }, [dateOrder]);

    useEffect(() => {
        if (defectDocumentData) {
            setDefectInspectionDocumentList(defectDocumentData.defectInspectionDocuments);
        }
        console.log(defectDocumentData);
    }, [defectDocumentData]);

    const renderList = () => {
        if (loadingDefectDocumentData) {
            return (
                <Loading>
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </Loading>
            );
        }

        if (!defectInspectionDocumentList.length) {
            return (
                <FiltersContainer>
                    {Translate.t('Packages.ViewDocuments.NoData')}
                </FiltersContainer>
            );
        }

        return (
            <div>
                {defectInspectionDocumentList.map((item, index) => {
                    const uniquekey = index + Math.random();
                    return (
                        <PackagesListCard
                            key={uniquekey}
                            id={index}
                            propertyName={(defectDocumentData?.defectInspectionDocuments[index].name && defectDocumentData?.defectInspectionDocuments[index].name === undefined) ? '' : defectDocumentData?.defectInspectionDocuments[index].name ?? ''}
                            documents={defectInspectionDocumentList}
                            setSelectedDocuments={setSelectedDocuments}

                        />
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <InfoContainerMain>
                    <LeftContainer>
                        <InfoContainer>
                            <TabViewContainer>
                                <TabView data={tabViewData} selectedTabView={homeScreenTabView} />
                            </TabViewContainer>
                            <TitleMessageContainer>{Translate.t('Packages.ViewDocuments.TitleMessage')}</TitleMessageContainer>
                            <FiltersContainer>

                                <FilterTitleBold>{Translate.t('Packages.ViewDocuments.FilterDefectDocuments')}</FilterTitleBold>

                                <FiltersInputContainer>
                                    <InlineSelectionInput
                                        data={dateData}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}

                                    />
                                    <InlineSelectionInput
                                        data={alphabeticalOrder}
                                        defaultValue={SortByEnum.updatedAtDesc.toString()}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}

                                    />
                                </FiltersInputContainer>
                            </FiltersContainer>

                            <ListContainer>
                                <HeadersContainer>
                                    {listHeaders.map(item => {
                                        return (
                                            <HeaderText
                                                key={item}
                                            >
                                                {item}
                                            </HeaderText>
                                        );
                                    })}
                                </HeadersContainer>
                                {renderList()}
                            </ListContainer>
                        </InfoContainer>
                    </LeftContainer>

                    <RightContainer>
                        <SupportInfo />
                        <NavigationContainer>
                            <QuickNavigation>{Translate.t('Packages.PackagesCard.QuickNavigation')}</QuickNavigation>
                            <QuickNavigationDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</QuickNavigationDescription>
                            {/* {FEATURE QUICK NAVIGATION TO BE USED IN FUTURE} */}
                            {/* <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} rightIconSrc={Icons.RightArrow} onclick={() => undefined} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} rightIconSrc={Icons.RightArrow} onclick={() => undefined} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')} rightIconSrc={Icons.RightArrow} onclick={() => undefined} /> */}
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToLeaseAndSaleViewDocuments(propertyIndividualId)} />
                        </NavigationContainer>
                    </RightContainer>

                </InfoContainerMain>

            </MainContainer>
            <ImagesModal
                documentData={selectedDocuments}
            />

        </>
    );
};

const HeaderText = styled.h4`
    justify-self: center;
`;

const Loading = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const QuickNavigation = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const QuickNavigationDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
`;

const TitleMessageContainer = styled.p`
    margin-bottom: 40px;
`;

const FilterTitleBold = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
`;

const RightContainer = styled.div`
    margin-top: 10%;
    width: 40%;
    padding-left: 10px;
    padding-right: 20px;
`;

const LeftContainer = styled.div`
    display: flex;
    width: 70%;
    height: 100%;
`;

const InfoContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
`;

const InfoContainerMain = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

const ButtonInfoPackagesStyled = styled(ButtonInfoPackages)`
    margin-top: 12px;
`;

const TabViewContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    max-height: 544px;
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const HeadersContainer = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 30% 30% 40% ;
    font-family: ${Fonts.primary};
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const FiltersContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const FiltersInputContainer = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const ListContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    height: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    loadingDefectDocumentData: Selectors.getPackagesDataDefectInspectionDetailsSortAttempt(state),
    errorDefectDocumentData: Selectors.getPackagesDataDefectInspectionDetailsSortFailure(state),
    defectDocumentData: Selectors.getPackagesDataDefectInspectionDetailsSort(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    packagesDataSort: (category: number, propertyId: string, sort: number) =>
        dispatch(Actions.packageGetIDefectInspectionDataSortAttempt({ category, propertyId, sort })),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefectInspectionViewDocuments);
