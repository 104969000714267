import { Colors, SearchBar } from '@dm/bigfish';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

const SearchAndCreateTransactionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: white;
`;

const PropertyReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 120px;
    color: ${Colors.black};
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 40px;
`;

// Filter
const WithdrawalFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    background: ${Colors.white};
`;

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const FilterTitle = styled.p`
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 16px;
`;

const FilterPropContainer = styled.div`
    display: flex;

    > select:not(:last-child){
        margin-right: 10px;
    }
`;

const SortAndStarredContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
`;

const WithdrawalContentContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

const WithdrawalListingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const WithdrawalListTable = styled.div`
    width: 100%;
    margin: 20px 0 20px 0;

    > div:not(:last-child){
        margin-bottom: 8px;
    }
`;

const WithdrawalListingHeader = styled.div<{isRecurring?: boolean}>`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    width: 100%;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    background: ${Colors.white};

    > h4:first-child {
        padding-left: 10px;
    }

    > h4:not(:first-child){
        text-align: center;
    }
`;

const StyledIconButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all .2s;

    &:not(:disabled):active {
        transform: translateY(-3px);
    }

    &:disabled{
        cursor: not-allowed;
    }
`;

const Icons = styled(SVG)<{width: string, height?: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
`;

const WithdrawalListRow = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
    width: 100%;
    border: none;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;
    
    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    > p {
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
    }
`;

const NameContainer = styled.h4`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    padding-left: 10px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 580px;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const RowItem = styled.p`
    text-align: center;
`;

const WithdrawalListEmpty = styled.div`
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;

  color: ${Colors.greyDark};
  background: ${Colors.primaryLightest};

  > p {
    margin: 0px;
    padding: 0px;
  }

  > p:not(:last-child) {
    margin-bottom: 3px;
  }

  > p:first-child{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 14px;
  }

`;

export default {
    SearchAndCreateTransactionContainer,
    PropertyReportContainer,
    TitleContainer,
    Title,
    StyledSearchBar,
    WithdrawalFilterContainer,
    FilterContainer,
    FilterTitle,
    FilterPropContainer,
    SortAndStarredContainer,
    WithdrawalContentContainer,
    WithdrawalListingContainer,
    WithdrawalListTable,
    WithdrawalListingHeader,
    WithdrawalListRow,
    NameContainer,
    ActionButtonContainer,
    StyledIconButton,
    Icons,
    RowItem,
    WithdrawalListEmpty,
    LoadingContainer,
};
