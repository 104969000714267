import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminViewAllCoBrokeResponse, GetREADetailsResponse, READetailsWithPropertyInfo } from '@dm/types';
import { ReaReduxState, GetCobrokesActionPayload, ReviewCobrokesActionPayload, GetReaActionPayload } from './types';

const initialState: ReaReduxState = {
    actions: {
        getCobrokes: false,
        reviewCobrokes: false,
        getRea: false,
        getReaDetails: false,
    },
    cobrokes: {
        index: 1,
        maxIndex: 1,
        data: [],
    },
    reaReport: {
        index: 1,
        maxIndex: 1,
        data: [],
    },
    reaDetails: null,
    error: {
        getCobrokes: '',
        reviewCobrokes: '',
        getRea: '',
        getReaDetails: '',
    },
};

const reaSlice = createSlice({
    name: 'rea',
    initialState,
    reducers: {
        getCobrokesAttempt: (state, _action: GetCobrokesActionPayload) => {
            state.actions.getCobrokes = true;
            state.error.getCobrokes = '';
        },
        getCobrokesSuccess: (state, action: PayloadAction<AdminViewAllCoBrokeResponse>) => {
            state.actions.getCobrokes = false;
            state.cobrokes = action.payload;
        },
        getCobrokesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getCobrokes = false;
            if (action.payload) state.error.getCobrokes = action.payload;
        },
        clearCobrokes: (state) => {
            state.cobrokes = {
                index: 1,
                maxIndex: 1,
                data: [],
            };
        },
        reviewCobrokesAttempt: (state, _action: ReviewCobrokesActionPayload) => {
            state.actions.reviewCobrokes = true;
            state.error.reviewCobrokes = '';
        },
        reviewCobrokesSuccess: (state) => {
            state.actions.reviewCobrokes = false;
        },
        reviewCobrokesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.reviewCobrokes = false;
            if (action.payload) state.error.reviewCobrokes = action.payload;
        },
        getReaAttempt: (state, _action: GetReaActionPayload) => {
            state.actions.getRea = true;
            state.error.getRea = '';
        },
        getReaSuccess: (state, action: PayloadAction<GetREADetailsResponse>) => {
            state.actions.getRea = false;
            state.reaReport = action.payload;
        },
        getReaFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getRea = false;
            if (action.payload) state.error.getRea = action.payload;
        },
        clearReaReport: (state) => {
            state.reaReport = {
                index: 1,
                maxIndex: 1,
                data: [],
            };
        },
        getReaDetailsAttempt: (state, _action: PayloadAction<string>) => {
            state.actions.getReaDetails = true;
            state.error.getReaDetails = '';
        },
        getReaDetailsSuccess: (state, action: PayloadAction<READetailsWithPropertyInfo | null>) => {
            state.actions.getReaDetails = false;
            state.reaDetails = action.payload;
        },
        getReaDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getReaDetails = false;
            if (action.payload) state.error.getReaDetails = action.payload;
        },
        clearReaDetails: (state) => {
            state.reaDetails = null;
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: reaSlice.actions,
    reducers: reaSlice.reducer,
};
