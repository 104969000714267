import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import validator from 'validator';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';

import { RoundInput, Button, BreadCrumb, Checkbox } from '@dm/bigfish';

import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';

interface LoginProps {
    loading: boolean;
    error: string;
    isAuthenticated: string,
    login(email: string, password: string, rememberMe?: boolean): void;
}

const Login = (props: LoginProps): JSX.Element => {
    const { loading, error, isAuthenticated, login } = props;

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<boolean | null>(null);
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('Login.LoginBcOwnerLogin'),
            },
        ],
    );

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onChangeTextEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError(!validator.isEmail(e.target.value));
    };

    const onChangeTextPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onSubmitLoginHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (!emailError) login(email, password, rememberMe);
    };

    useEffect(() => {
        if (isAuthenticated) NavActions.navToHome();
    }, [isAuthenticated]);

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Form onSubmit={onSubmitLoginHandler}>
                    <FormTitle>{Translate.t('Login.LoginOwnerLogin')}</FormTitle>
                    {' '}
                    <InputGroup label={Translate.t('Login.LoginEmail')} placeholder={Translate.t('Login.LoginPlaceholder')} value={email} onChangeText={onChangeTextEmail} inputError={emailError || false} errorText={Translate.t('Login.LoginErrorEmail')} required />
                    <InputGroup type='password' label={Translate.t('Login.LoginPassword')} placeholder={Translate.t('Login.LoginPlaceholder')} value={password} onChangeText={onChangeTextPassword} required />
                    <Checkbox label={Translate.t('Login.LoginRememberMe')} checked={rememberMe} onClickCheckbox={() => setRememberMe(!rememberMe)} />

                    <SubmitButton>
                        <Button loading={loading} label={Translate.t('Login.LoginLabel')} />
                    </SubmitButton>
                </Form>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </MainContainer>
        </>
    );
};

const Form = styled.form`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    max-width: 554px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const FormTitle = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 28px;
`;

const InputGroup = styled(RoundInput)`
    margin-bottom: 16px;
`;

const Text = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #696969;
`;

const TextLink = styled.a`
    font-size: inherit;
    line-height: inherit;
    color: #2F80ED;
    cursor: pointer;
    user-select: none;

    &:hover{
        color: #2f81edd1;
    }
`;

const SubmitButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthLoginAttempting(state),
    error: Selectors.getAuthLoginError(state),
    isAuthenticated: Selectors.getAuthAuthToken(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    login: (email: string, password: string, rememberMe?: boolean) =>
        dispatch(Actions.authLoginAttempt({ email, password, rememberMe })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
