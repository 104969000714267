import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CardCollapsible, Colors, Button, BreadCrumb } from '@dm/bigfish';
import { RootState, AppDispatch } from 'redux/store';

import MainContainer from 'components/MainContainer';
import NavActions from 'lib/NavActions';
import { IProperty, IPropertyPackageStatusEnum } from '@dm/types';
import LoadingScreen from 'components/LoadingScreen';
import SupportInfo from 'components/SupportInfo';
import PropertyImage from '../../../components/PropertyImage';
import NoImageAvailable from '../../../assets/images/house1.png';
import QuickNavigationButton from '../../../components/QuickNavigationButton';
import ButtonInfoPackages from '../../../components/ButtonViewAndUploadPackages';
import EventTrailRecentEvents from '../../../components/EventTrailRecentEvents';
import Icons from '../../../assets/icons';
import PropertyManagementDocumentUpload from './PropertyManagementDocumentUpload';

interface NavParams {
    propertyId: string;
}

interface CollapsibleListProps {
    loading: boolean;
    error: string;
    propertyList: IProperty | null;
    getPropertyIndividual: (id: string) => void;
    setPropertyManagementDocumentUploadId: (id: string) => void;
    modalLoad: boolean;
    setModalOpen: (state: boolean) => void;
}

const VacantManagement = (props : CollapsibleListProps): JSX.Element => {
    const { loading, error, propertyList, getPropertyIndividual, setPropertyManagementDocumentUploadId, modalLoad, setModalOpen } = props;

    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [chip, setChip] = useState(false);

    const [chipnumber, setChipnumber] = useState(0);
    const [country, setCountry] = useState('');
    const [packageBought, setPackageBought] = useState(false);
    const ownerTermsRef = useRef<any>(null);
    const useLocationstate = useLocation();
    const { propertyId } = useLocationstate.state as NavParams;

    const [pendingEvents, setPendingEvents] = useState([]);

    useEffect(() => {
        onOpenCollapsibleOwner();
        getPropertyIndividual(propertyId);

        setOwnerStatusForPackage();
    }, []);

    const setOwnerStatusForPackage = () => {
        if (propertyList?.packages) {
            if (propertyList?.packages.propertyManagement?.status === IPropertyPackageStatusEnum.Active) {
                setPackageBought(true);
                setName(propertyList.name);
                if (propertyList.location?.address1) {
                    setCity(propertyList.location?.address1);
                }
                if (propertyList.location?.state) {
                    setState(propertyList.location?.state);
                }
                if (propertyList.location?.country) {
                    setState(propertyList.location?.country);
                }
                if (propertyList.packages.propertyManagement.status) {
                    setChip(true);
                    setChipnumber(propertyList.packages.propertyManagement.status);
                }
            }
        }
    };

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Packages.PackagesCard.CardTitlePropertyManagement'),
                onClick: () => { return false; },
            },
        ],
    );

    const onOpenCollapsibleOwner = () => {
        if (ownerTermsRef.current) {
            ownerTermsRef.current.openCollapsible();
        }
    };

    const onClickSetPropertyManagementDocument = () => {
        setPropertyManagementDocumentUploadId(propertyId);
        setModalOpen(true);
    };

    const checkPropertyPackagesStatus = (packages: any) => {
        const packagesnumber = packages;

        switch (packagesnumber) {
            case 0:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusNotPurchasedYet');
            case 1:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 2:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 3:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusActive');
            case 4:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPaymentRejected');
            case 5:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusDisabled');
            case 6:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusFreeTrial');
            case 7:
                return Translate.t('Packages.VacantPossession.ButtonInfo.Statuspurchase');
            default:
                return '';
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <HeadersContainer>
                    <CardCollapsibleStyled ref={ownerTermsRef} title={propertyList?.name ?? ''} subtitle={propertyList?.location ? `${(propertyList.location?.city) ? propertyList.location.city : city} ${(propertyList.location?.state) ? propertyList.location?.state : state} ${(propertyList.location?.country) ? propertyList.location.country : country}` : ''} chipText={(propertyList?.packages?.propertyManagement?.status) ? checkPropertyPackagesStatus(propertyList.packages.propertyManagement.status) : ''}>
                        {loading && (
                            <LoadingScreen />
                        )}
                        {(!loading) && (propertyList?.packages && propertyList.packages.propertyManagement?.status === IPropertyPackageStatusEnum.Active) && (

                            <div>
                                <ModulesPropertyContainer>
                                    <PropertySubInfoContainer>
                                        <PropertyImage src={NoImageAvailable} />
                                    </PropertySubInfoContainer>
                                    <PropertyInfoContainer>
                                        <ButtonInfoPackages desc='property management upload document' rightIconSrc={Icons.UploadIcon} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => onClickSetPropertyManagementDocument()} />
                                        <ButtonInfoPackages desc='property management view document' rightIconSrc={Icons.DocumentDisplay} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => NavActions.navToPropertyManagementViewDocuments(propertyId)} />
                                        <ButtonInfoPackages desc='property management view transactions' rightIconSrc={Icons.DocumentDisplay} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => NavActions.navToPropertyManagementViewTransaction(propertyId)} />

                                        {modalLoad && (
                                            <PropertyManagementDocumentUpload showModal />
                                        )}
                                        <EventTrailRecentEvents title={Translate.t('RecentEvent.RecentEvent')} primary buttonLabel={Translate.t('Packages.RecentEvent')} data={(propertyList.packages.propertyManagement.recentEvents && propertyList.packages.propertyManagement.recentEvents[0]) ? propertyList.packages.propertyManagement.recentEvents : pendingEvents} />
                                    </PropertyInfoContainer>
                                </ModulesPropertyContainer>

                                <ModulesFooter>
                                    <ModuleFooterNav>
                                        {Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')}
                                    </ModuleFooterNav>
                                </ModulesFooter>
                            </div>
                        )}

                        {(propertyList?.packages && propertyList.packages.propertyManagement?.status !== IPropertyPackageStatusEnum.Active) && (
                            <div>
                                <ButtonContainer>
                                    <SubscriberButton
                                        label={Translate.t('Packages.LeaseAndSale.IfNotBought')}
                                        onClick={() => NavActions.navToHome()}
                                    />
                                </ButtonContainer>
                            </div>

                        )}

                    </CardCollapsibleStyled>

                    <div>
                        <SupportContainer>
                            <SupportInfo />
                        </SupportContainer>

                        <NavigationContainer>
                            <NavigationTextTitle>{Translate.t('Packages.PackagesCard.QuickNavigation')}</NavigationTextTitle>
                            <NavigationTextDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</NavigationTextDescription>
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} onclick={() => NavActions.navToPackagesVacantPosession(propertyId)} />
                        </NavigationContainer>
                    </div>
                </HeadersContainer>

            </MainContainer>
        </>

    );
};

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 10%;
    margin-bottom: 10%;
    justify-content: center;
    align-self: center;
`;
const SupportContainer = styled.div`
   margin-bottom: 20px;
`;

const NavigationTextTitle = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const NavigationTextDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
`;

const HeadersContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 65% 30%  ;
    padding: 20px;
    padding-top: 40px;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    max-height: 544px;
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const CardCollapsibleStyled = styled(CardCollapsible)`
    width: 100%;
`;

const SubscriberButton = styled(Button)`
    display: flex;
    width: 90%;
    height: 28%;
    padding: 20px;
    background-color: #F2865E;
    color: white;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
`;

const ModulesFooter = styled.div<{focused?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 48px;
    padding: 12px 20px;
    background-color: #FFF2E7;
    ${(props) => props.focused && css`
    background-color: #F2865E;;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    `}
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
`;

const ModuleFooterNav = styled.button`
    display: flex;
    align-items: center;
    color: #F2865E;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    span {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
    }
`;

const ModulesPropertyContainer = styled.div`
    display: flex;
    padding: 20px;
    img {
        margin-right: 16px;
    }
`;

const PropertySubInfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    >:not(:last-child) {
        margin-bottom: 20px;
    }
    span {
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        font-style: italic;
        max-width: 224px;
        text-align: justify;
    }
`;

const PropertyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    >:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetDetailsAttempt(state),
    error: Selectors.getPropertyGetDetailsError(state),
    propertyList: Selectors.getPropertyPropertyDetails(state),
    modalLoad: Selectors.packagesSetLeaseSaleModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyIndividual: (id: string) =>
        dispatch(Actions.propertyGetDetailsAttempt({ id })),
    clearProperty: () => dispatch(Actions.clearPropertyDetails()),
    setPropertyManagementDocumentUploadId: (params: string) => dispatch(Actions.setCreateDocumentUploadID(params)),
    setModalOpen: (state: boolean) => dispatch(Actions.setLeaseSaleModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacantManagement);
