import { InitState } from 'redux/slices/payment';
import { GetPendingPaymentResponse, ViewAllPMTransactionResponse } from '@dm/types';
import { IApproveRejectPendingPayment } from 'entities/property';

const getAllPaymentAttempting = (state: InitState): boolean => state.actions.getAllPayment || false;
const getAllPaymentError = (state: InitState): string => state.error.getAllPayment || '';

const getAllPaymentData = (state: InitState): GetPendingPaymentResponse => state.paymentData || null;

const getIsReceiptImagesModalOpen = (state: InitState): boolean => state.receiptImagesModalOpen || false;

const getIsApproveRejectModalOpen = (state: InitState): boolean => state.approveRejectModalOpen || false;
const getApproveRejectParams = (state: InitState): IApproveRejectPendingPayment => state.approveRejectPaymentParams || null;

const getApproveRejectPaymentAttempting = (state: InitState): boolean => state.actions.approveReject || false;
const getApproveRejectPaymentError = (state: InitState): string => state.error.approveReject || '';

const getTransactionModalOpen = (state: InitState): boolean => state.isTransactionModalOpen || false;
const getIsUploadFileModalOpen = (state: InitState): boolean => state.isUploadFileModalOpen || false;
const getIsFileViewModalOpen = (state: InitState): boolean => state.isFileViewModalOpen || false;
const getIsExportModalOpen = (state: InitState): boolean => state.isExportModalOpen || false;
const getIsConfirmRefundModalOpen = (state: InitState): boolean => state.isConfirmRefundModalOpen || false;

const getFileList = (state: InitState): ViewAllPMTransactionResponse['fileUrl'] => state.fileList || [];

const getTransactionReportExportAttempt = (state: InitState): boolean => state.actions.getTransactionsReportExport || false;

export default {
    getAllPaymentAttempting,
    getAllPaymentError,

    getAllPaymentData,

    getIsReceiptImagesModalOpen,

    getIsApproveRejectModalOpen,
    getApproveRejectParams,

    getApproveRejectPaymentAttempting,
    getApproveRejectPaymentError,

    getTransactionModalOpen,
    getIsUploadFileModalOpen,
    getIsFileViewModalOpen,
    getIsExportModalOpen,
    getIsConfirmRefundModalOpen,

    getFileList,

    getTransactionReportExportAttempt,
};
