import { ApiResponse } from 'apisauce';
import { GatewayResponse } from 'api/types/types';

import { ISubAdminsReport } from 'entities/subadmins';

// import { GetOwnerDetailsResponse } from '@dm/types';
import { ISubAdminGateway, CreateSubAdminParams, GetSubAdminsParams, SetAssignPropertyParams, DeleteSubAdminPropertyParams } from './SubadminsBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class SubAdminsGateway extends ISubAdminGateway {
    async createSubAdmin(params: CreateSubAdminParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('admin/user/createSubAdmin', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getSubAdmins(params: GetSubAdminsParams): GatewayResponse<ISubAdminsReport> {
        const response: ApiResponse<ISubAdminsReport> = await this.api.get('/admin/report/subAdmins', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setSubAdminProperty(params: SetAssignPropertyParams): GatewayResponse<string | null> {
        const { data, authToken } = params;
        const response: ApiResponse<string | null> = await this.api.put('/admin/user/assign', data, getHeaders(authToken));
        return this.process(response);
    }

    async deleteSubAdminProperty(params: DeleteSubAdminPropertyParams): GatewayResponse<string | null> {
        const { data, authToken } = params;
        const response: ApiResponse<string | null> = await this.api.put('/admin/user/removeProperty', data, getHeaders(authToken));
        return this.process(response);
    }
}
