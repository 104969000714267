import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import Translate from 'lib/translate';

import { GatewayResponseStatus } from 'api/types/types';
import { SetUserActionPayload } from 'redux/slices/property/types';

export default function* watchSetUser(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetUserAttempt', handleDesription, api);
}

function* handleDesription(api: PropertyGateway, data: SetUserActionPayload) {
    const { propertyId, userId } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setPropertyUser], { authToken, propertyId, userId });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success(Translate.t('Property.Saved'));
        yield put(Actions.propertySetUserSuccess(response.data));

        if (!response.data) {
            yield put(Actions.propertySetUserFailure(Translate.t('GeneralMessage.SomethingWrong')));
            toast.error(Translate.t('GeneralMessage.SomethingWrong'));
        }
    } else {
        yield put(Actions.propertySetUserFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
