import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import SVG from 'react-inlinesvg';
import Icons from '../assets/icons';

interface ButtonInfoProps {
  desc: string;
  leftIconSrc?: string;
  rightIconSrc?: string;
  status?: string;
  onclick: () => void;
  style?: CSSProperties;
}

const StyledButton = styled.button<{status?: string}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 0.5px solid #F2865E;;
    background-color: #fff;
    color: #F2865E;;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    cursor: pointer;

    ${props => (props.status === 'purchase') && css`
        color: #696969;
        border: 0.5px solid #696969;
    `}

    ${props => (props.status === 'active') && css`
        color: #FFFFFF;
        border: none;
        background-color: #F2865E;
    `}
`;

const StyledIcon = styled(SVG)<{iconColor?: string, status?: string}>`
  width: 20px;
  height: 20px;

  ${props => (props.status === 'purchase') && css`
    color: #F2865E;
  `}
`;

const StyledDesc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${StyledIcon}{
    margin-right: 6px;
  }
`;

const ButtonInfo = ({ desc, onclick, leftIconSrc, rightIconSrc, status, style, ...otherProps }: ButtonInfoProps):JSX.Element => {
    return (
        <StyledButton onClick={onclick} status={status} {...otherProps} style={style}>
            <StyledDesc>
                {leftIconSrc && (status === 'Pending') && (
                    <StyledIcon status={status} src={leftIconSrc} />
                )}
                {desc}
            </StyledDesc>
            {(status === 'Disabled') && (
                <StyledIcon
                    status={status}
                    src={Icons.AddPackge}
                />
            )}
            {rightIconSrc && (status === 'purchased' || status === 'active') && (
                <StyledIcon status={status} src={rightIconSrc} />
            )}
        </StyledButton>
    );
};

ButtonInfo.defaultProps = {
    leftIconSrc: '',
    rightIconSrc: '',
    status: '',
    style: {},
};

export default ButtonInfo;
