import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';
import Translate from 'lib/translate';

import { SetPropertyPackagePricePayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchSetPropertyPackagesPrice(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/setPropertyPackagePriceAttempt', handleSetPropertyPackagesPrice, api);
}

function* handleSetPropertyPackagesPrice(api: PropertyGateway, data: SetPropertyPackagePricePayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setPropertyPackagesPrice], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.setPropertyPackagePriceError(Translate.t('GeneralMessage.SomethingWrong')));
            return;
        }
        yield put(Actions.setPropertyPackagePriceSuccess(response.data));
        toast.success(Translate.t('Property.PropertyPackagePriceUpdated'));
    } else {
        // handle error
        yield put(Actions.propertySetPropertyAmenitiesFailure(response.message));
        toast.error(Translate.t('GeneralMessage.SomethingWrong'));
    }
}
