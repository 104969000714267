import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Bars } from 'react-loader-spinner';
import throttle from 'lodash.throttle';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import Translate from 'lib/translate';

import { Colors, Button, Paginator, SearchBar } from '@dm/bigfish';
import { PropertyTypeEnum, PropertyStatus, GetPropertiesResponse, SortByEnum } from '@dm/types';

import { IServerPropertyReport, IGetProperty } from 'entities/property';
import { IPropertySubAdmin, ISubAdminPropertyWithId, IAssignProperty } from 'entities/subadmins';

import PropertyImage from 'assets/images/house1.png';
import Modal from 'components/Modal';

interface AddPropertyModalProps {
    subAdminPropertyData: ISubAdminPropertyWithId;

    loading: boolean;
    error: string;
    propertyReport: IServerPropertyReport;
    getProperty: (data: IGetProperty) => void;
    clearPropertyReport: () => void;
    setTabView: (tabView: string) => void;

    isAddPropertyModalOpen: boolean;
    setAddPropertyModal: (state:boolean) => void;

    setAssignPropertyLoading: boolean;
    setAssignPropertyError: string;
    setAssignProperty: (data: IAssignProperty) => void;
}

const AddPropertyModal = (props: AddPropertyModalProps): JSX.Element => {
    const { subAdminPropertyData,
        loading,
        error,
        propertyReport,
        getProperty,
        clearPropertyReport,
        setTabView,
        isAddPropertyModalOpen,
        setAddPropertyModal,
        setAssignPropertyLoading,
        setAssignPropertyError,
        setAssignProperty } = props;

    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);

    const [propertyList, setPropertyList] = useState<GetPropertiesResponse[]>([]);

    const [buildingTypesOpt, setBuildingTypesOpt] = useState<string | PropertyTypeEnum>('');
    const [statusOpt, setStatusOpt] = useState<string | PropertyStatus>('');
    const [accessLevel, setAccessLevel] = useState<string>('');
    const [sortByOpt, setSortByOpt] = useState<string | SortByEnum>('');

    const [addedProperty, setAddedProperty] = useState<IPropertySubAdmin[]>([]);

    const [propertyCount, setPropertyCount] = useState(0);
    const [accessLevelSelected, setAccessLevelSelected] = useState<string[]>([]);

    useEffect(() => {
        getProperty({ index: 1 });
        setTabView('property');

        return () => {
            clearPropertyReport();
        };
    }, []);

    useEffect(() => {
        const { maxIndex, data } = propertyReport;

        setPropertyList(data);
        setPaginationData(createPaginationData(maxIndex));
        setMaxPages(maxIndex);
    }, [propertyReport]);

    useEffect(() => {
        getProperty({
            index: 1,
            location: searchKeyword,
            status: statusOpt,
            type: buildingTypesOpt,
            sortBy: sortByOpt,
        });
    }, [buildingTypesOpt, statusOpt, sortByOpt]);

    const throttledSetSearch = useRef(throttle((searchWord: string) => {
        if (searchWord) {
            getProperty({
                index: 1,
                location: searchWord,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
        } else {
            getProperty({ index: 1 });
        }
    }, 500, { leading: false }));

    const onSearchProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKeyword(value);

        throttledSetSearch.current(value);
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        if (currentNumber !== paginationNumber) {
            getProperty({
                index: currentNumber,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);
        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        if (currentNumber !== paginationNumber) {
            getProperty({
                index: currentNumber,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);
        if (paginationNumber !== 1) {
            getProperty({
                index: 1,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);
        if (paginationNumber !== maxPages) {
            getProperty({
                index: maxPages,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);
        if (pages !== paginationNumber) {
            getProperty({
                index: pages,
                location: searchKeyword,
                status: statusOpt,
                type: buildingTypesOpt,
                sortBy: sortByOpt,
            });
            winScrollToTop();
        }
    };

    // ? for submit all property
    const allPropertyDataToSubmit = () => {
        const allData = {
            userId: subAdminPropertyData.userId,
            access: addedProperty,
        };

        setAssignProperty(allData);
    };

    const propertyCheck = (propertyId: string, accessLevelData: string) => {
        if (addedProperty.some((item) => item.propertyId === propertyId)) {
            const propertySelected = addedProperty.filter((item) => item.propertyId !== propertyId);
            setAddedProperty(propertySelected);
            setPropertyCount((count) => count - 1);
        } else {
            const propertyData = { propertyId, accessLevel: Number(accessLevelData) };
            setAddedProperty([...addedProperty, propertyData]);
            setPropertyCount((count) => count + 1);
        }
    };

    const addAllPropertyButton = () => {
        if (setAssignPropertyLoading) {
            return (
                <Button
                    label='Adding'
                    disabled={setAssignPropertyLoading}
                />
            );
        }
        return (
            <Button
                label={Translate.t('SubAdmins.SubAdminAddProperty.AddSelectedProperty')}
                onClick={() => allPropertyDataToSubmit()}
            />
        );
    };

    const renderPropertyList = () => {
        if (setAssignPropertyLoading) {
            return (
                <LoadingContainer>
                    <Bars
                        color={Colors.primary}
                        height={250}
                        width={260}
                    />
                </LoadingContainer>
            );
        }

        if (!propertyList.length) {
            return (
                <PropertyListingColumnEmpty>
                    <p>{Translate.t('PropertyReport.PropertyReportError1')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError2')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError3')}</p>
                </PropertyListingColumnEmpty>
            );
        }

        if (error) {
            return (
                <PropertyListingColumnEmpty>
                    <p>{Translate.t('PropertyReport.PropertyReportError4')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError5')}</p>
                    <p>{Translate.t('PropertyReport.PropertyReportError6')}</p>
                </PropertyListingColumnEmpty>
            );
        }

        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Paginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />
                </div>
                <PropertyListingTable>
                    <PropertyListingHeader>
                        <h4>{Translate.t('SubAdmins.SubAdminProperty.PropertyName')}</h4>
                        <h4>{Translate.t('SubAdmins.SubAdminProperty.PropertyUnit')}</h4>
                        <h4>{Translate.t('SubAdmins.SubAdminAddProperty.AddPropertyLocation')}</h4>
                        <h4>{Translate.t('SubAdmins.SubAdminProperty.PropertyAccessLevel')}</h4>
                        <h4>{Translate.t('SubAdmins.SubAdminProperty.PropertyAction')}</h4>
                    </PropertyListingHeader>

                    {propertyList.map((property, propertyIndex) => {
                        const { propertyId, name, images, location, unitNo } = property;

                        const accessLevelStatus = subAdminPropertyData.access.find((item) => item.propertyId === propertyId)?.accessLevel;

                        const { city = '', country = '' } = location;

                        const uniqueKey = propertyIndex + name;

                        return (
                            <PropertyListingRowButton
                                key={uniqueKey}
                                checked={(subAdminPropertyData.access.some((item) => item.propertyId === propertyId))}
                            >
                                <PropertyListingsColumnWithImage>
                                    <NameContainer>
                                        {name}
                                    </NameContainer>
                                    <img
                                        src={PropertyImage}
                                        alt='properties images'
                                    />
                                </PropertyListingsColumnWithImage>
                                <p>{unitNo}</p>
                                <p>
                                    {`${city}, ${country}`}
                                </p>
                                {
                                    (subAdminPropertyData.access.some((item) => item.propertyId === propertyId))
                                        ? (
                                            <p>
                                                {(accessLevelStatus === 1) ? 'Read only' : 'Write and Read' }
                                            </p>
                                        ) : (
                                            <AccessLevelDropdown>
                                                {/* <DropdownInput
                                                    key={propertyId}
                                                    style={{ background: Colors.primaryLightest }}
                                                    label='Select access level'
                                                    value={accessLevel}
                                                    options={subAdminAccessDropdownData}
                                                    onChangeItem={() => [setAccessLevel, setAccessLevelSelected([...accessLevelSelected, propertyId])]}
                                                /> */}

                                                <select
                                                    name='AccessLevel'
                                                    id={propertyId}
                                                    defaultValue=''
                                                    onChange={(e) => [setAccessLevel(e.target.value), setAccessLevelSelected([...accessLevelSelected, propertyId])]}
                                                >
                                                    <option value='' disabled>Select Access Level</option>
                                                    <option value='1'>Read Only</option>
                                                    <option value='0'>Write and Read</option>
                                                </select>
                                            </AccessLevelDropdown>
                                        )
                                }

                                {
                                    (subAdminPropertyData.access.some((item) => item.propertyId === propertyId))
                                        ? (<PropertyAddedText>{Translate.t('SubAdmins.SubAdminAddProperty.AddedProperty')}</PropertyAddedText>) : [
                                            (accessLevelSelected.length === 0) ? (
                                                <p>{Translate.t('SubAdmins.SubAdminAddProperty.AddAccessLevelConfirm')}</p>
                                            ) : [(accessLevelSelected.some((item) => item === propertyId))
                                                ? (
                                                    <AddButtonContainer>
                                                        <AddPropertyCheckBox
                                                            type='checkbox'
                                                            id={propertyId}
                                                            name={name}
                                                            value={propertyId}
                                                            onChange={() => propertyCheck(propertyId, accessLevel)}
                                                            checked={(addedProperty.some((item) => item.propertyId === propertyId))}
                                                        />
                                                    </AddButtonContainer>
                                                ) : (<p>Select access level first to add property </p>)]]
                                }

                            </PropertyListingRowButton>
                        );
                    })}
                </PropertyListingTable>
            </div>
        );
    };

    return (

        <Modal show={isAddPropertyModalOpen} width='70vw'>
            <PropertyReportContainer>
                <TitleContainer>
                    <Title>
                        {Translate.t('SubAdmins.SubAdminAddProperty.AddPropertyTitle')}
                    </Title>
                    <SpanContainer aria-hidden='true' role='button' onClick={() => setAddPropertyModal(false)}>
                        <h3>&#88;</h3>
                    </SpanContainer>
                </TitleContainer>
                <StyledSearchBar
                    title={Translate.t('PropertyReport.PropertyReportProperty')}
                    value={searchKeyword}
                    onChangeText={onSearchProperty}
                />
                <PropertyContentContainer>
                    <PropertyListingContainer>
                        {renderPropertyList()}
                    </PropertyListingContainer>
                </PropertyContentContainer>
                <AddPropertiesButtonContainer>
                    <PropertyCountContainer>
                        Property Selected:
                        <span>{propertyCount}</span>
                    </PropertyCountContainer>
                    {addAllPropertyButton()}
                </AddPropertiesButtonContainer>

            </PropertyReportContainer>

        </Modal>
    );
};

const PropertyReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 120px;
    color: ${Colors.black};
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    margin-top: 20px;
    margin-bottom: 40px;
`;

const SpanContainer = styled.div`
    cursor: pointer;
    align-self: center;
    margin: 0 20px;
`;

const StyledSearchBar = styled(SearchBar)`
    margin-bottom: 40px;
`;

const PropertyContentContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

const PropertyListingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

  /* TEMP */
  /* width: 1120px; */
`;

const AddPropertiesButtonContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const PropertyCountContainer = styled.div`
    display: flex;
    gap: 5px;
`;

const PropertyAddedText = styled.p`
    font-weight: bold;
`;

const AddButtonContainer = styled.div`
    display: flex;
    gap: 5px;
    justify-content: center;
`;

const AddPropertyCheckBox = styled.input`
    display: flex;
    justify-content: center;
`;

const AccessLevelDropdown = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 5px;
    align-items: center;
`;

const PropertyListingTable = styled.div`
    width: 100%;
    margin: 20px 0 20px 0;
    max-height: 45vh;
    overflow-y: scroll;

    > div:not(:last-child){
        margin-bottom: 8px;
    }
`;

const PropertyListingHeader = styled.div`
    display: grid;
    grid-template-columns: 250px repeat(4, 1fr);
    align-items: center;
    padding: 16px 9px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    background: ${Colors.white};

    > h4:first-child {
        padding-left: 10px;
    }

    > h4:not(:first-child){
        text-align: center;
    }
`;

const PropertyListingRowButton = styled.button<{checked?: boolean}>`
    display: grid;
    grid-template-columns: 250px repeat(4, 1fr);
    align-items: center;
    width: 100%;
    border: none;
    padding: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;

    color: ${Colors.greyDark};
    background: ${props => (props.checked ? Colors.primaryLight : Colors.primaryLightest)};

    > p {
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
    }
`;

const NameContainer = styled.h4`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const PropertyListingsColumnWithImage = styled.div`
    display: flex;
    align-items: center;

    h4 {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        width: 100px;
        margin-right: 16px;
        text-transform: capitalize;

        color: ${Colors.black};
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 12px;
        padding: 5px;
    }
`;

const PropertyListingColumnEmpty = styled.div`
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: center;

    color: ${Colors.greyDark};
    background: ${Colors.primaryLightest};

    > p {
        margin: 0px;
        padding: 0px;
    }

    > p:not(:last-child) {
        margin-bottom: 3px;
    }

    > p:first-child{
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 14px;
    }

`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 300px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetAllAttempt(state),
    error: Selectors.getPropertyGetAllError(state),
    propertyReport: Selectors.getPropertyPropertyReport(state),
    getSearchedPropertyLoading: Selectors.getPropertyGetSearchedPropertyAttempting(state),
    getSearchedPropertyError: Selectors.getPropertyGetSearchedPropertyError(state),
    searchedProperty: Selectors.getPropertySearchProperty(state),

    duplicatePropertyLoading: Selectors.setPropertySetDuplicatePropertyAttempting(state),
    duplicatePropertyError: Selectors.setPropertySetDuplicatePropertyError(state),
    duplicatePropertySuccess: Selectors.setPropertySetDuplicatePropertySuccess(state),

    isAddPropertyModalOpen: Selectors.setSubAdminViewAddPropertyModal(state),
    setAssignPropertyLoading: Selectors.setSubAdminSetAssignPropertyLoading(state),
    setAssignPropertyError: Selectors.setSubAdminSetAssignPropertyError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProperty: (data: IGetProperty) =>
        dispatch(Actions.propertyGetAllAttempt(data)),
    clearPropertyReport: () => dispatch(Actions.clearPropertyReport()),
    getSearchedProperty: (search: string) => dispatch(Actions.getSearchedPropertyAttempt(search)),
    clearSearchedProperty: () => dispatch(Actions.clearSearchedProperty()),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    setDuplicateProperty: (propertyId: string) =>
        dispatch(Actions.propertySetDuplicatePropertyAttempt({ propertyId })),
    setAddPropertyModal: (state: boolean) => dispatch(Actions.setViewAddPropertyModal(state)),
    setAssignProperty: (data: IAssignProperty) => dispatch(Actions.setAssignPropertyAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPropertyModal);
