import { InitState } from 'redux/slices/subadmins';
import { GetOwnerDetailsResponse } from '@dm/types';
import { ISubAdminPropertyWithId, ISubAdminsReport } from 'entities/subadmins';

const getCreateNewSubAdminAttempt = (state: InitState): boolean => state.actions.createNewSubAdmin || false;
const getCreateNewSubAdminError = (state: InitState): string => state.error.createNewSubAdmin || '';

const getSubAdminReportAttempt = (state: InitState): boolean => state.actions.getSubAdminsReport || false;
const getSubAdminReportError = (state: InitState): string => state.error.getSubAdminsReport || '';

const setAssignPropertyAttempt = (state: InitState): boolean => state.actions.setSubAdminProperty || false;
const setAssignPropertyError = (state: InitState): string => state.error.setSubAdminProperty || '';

const setDeletePropertyAttempt = (state: InitState): boolean => state.actions.deleteSubAdminProperty || false;
const setDeletePropertyError = (state: InitState): string => state.error.deleteSubAdminProperty || '';

const subAdminsReport = (state: InitState): ISubAdminsReport => state.subAdminsReport;
const getPropertyList = (state: InitState): ISubAdminPropertyWithId => state.propertyListData;

const setCreateSubAdminModal = (state: InitState): boolean => state.createSubAdminModal || false;
const setViewSubAdminPropertyModal = (state: InitState): boolean => state.viewSubAdminPropertyModal || false;
const setViewAddPropertyModal = (state: InitState): boolean => state.viewAddPropertyModal || false;

export default {
    getCreateNewSubAdminAttempt,
    getCreateNewSubAdminError,
    getSubAdminReportAttempt,
    getSubAdminReportError,
    subAdminsReport,
    setCreateSubAdminModal,
    setViewSubAdminPropertyModal,
    setViewAddPropertyModal,
    setAssignPropertyAttempt,
    setAssignPropertyError,
    setDeletePropertyAttempt,
    setDeletePropertyError,
    getPropertyList,
};
