import { fork } from 'redux-saga/effects';

import OwnersGateway from 'api/Owners';
import { RootSagaReturnType } from 'sagas/types';

import watchCreateNew from './createNew';
import watchGetOwners from './getOwners';
import watchGetOwnerDetails from './getOwnerDetails';
import watchEditOwnerDetails from './editOwnerDetails';

export default (api: OwnersGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchCreateNew, api);
        yield fork(watchGetOwners, api);
        yield fork(watchGetOwnerDetails, api);
        yield fork(watchEditOwnerDetails, api);
    }

    return {
        rootSaga,
    };
};
