import config from 'config';

import AuthGateway from './Auth';
import OwnersGateway from './Owners';
import PropertyGateway from './Property';
import PackagesGateway from './Packages';
import ReaGateway from './Rea';
import SubAdminsGateway from './Subadmins';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const owners = new OwnersGateway(baseUrl);
const property = new PropertyGateway(baseUrl);
const packages = new PackagesGateway(baseUrl);
const rea = new ReaGateway(baseUrl);
const subadmins = new SubAdminsGateway(baseUrl);

export default {
    auth,
    owners,
    property,
    packages,
    rea,
    subadmins,
};
