import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import ReaGateway from 'api/Rea';

import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { PayloadAction } from '@reduxjs/toolkit';

import NavActions from 'lib/NavActions';

export default function* watchGetReaDetails(api: ReaGateway): SagaWatcherReturnType {
    yield takeEvery('rea/getReaDetailsAttempt', handleGetReaDetails, api);
}

function* handleGetReaDetails(api: ReaGateway, data: PayloadAction<string>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getReaDetails], {
        reaId: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.getReaDetailsFailure('Something went wrong. Please try again.'));

            NavActions.navToReaReport();
            toast.error('Something went wrong. Please try again.');
            return;
        }

        yield put(Actions.getReaDetailsSuccess(response.data));
    } else {
        toast.error('Something went wrong. Please try again.');
        NavActions.navToReaReport();
        yield put(Actions.getReaDetailsFailure(response.message));
    }
}
