import { PMTransactionTypeEnum, PropertyManagementWithdrawalStatus, SortByEnum } from '@dm/types';
import { IOption } from 'entities/component';

const withdrawalStatusData: IOption[] = [
    {
        id: 0,
        description: 'All Status',
    },
    {
        id: PropertyManagementWithdrawalStatus.approved,
        description: 'Approved',
    },
    {
        id: PropertyManagementWithdrawalStatus.pending,
        description: 'Pending',
    },
    {
        id: PropertyManagementWithdrawalStatus.rejected,
        description: 'Rejected',
    },
];

const withdrawalSortData: IOption[] = [
    {
        id: SortByEnum.createdAtDesc,
        description: 'Latest First',
    },
    {
        id: SortByEnum.createdAtAsc,
        description: 'Oldest First',
    },
];

export {
    withdrawalStatusData,
    withdrawalSortData,
};
