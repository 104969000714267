import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors } from '@dm/bigfish';
import { IProperty, PackageCategory } from '@dm/types';

import Modal from 'components/Modal';

interface SetGoogleDrivePathModalProps {
    id: string;
    setGoogleDrivePathAttempt: boolean;
    setGoogleDrivePathError: string;
    setGoogleDrivePath: (propertyId: string, packageType: PackageCategory, googleDrivePath: string) => void;
    propertyDetails: IProperty | null;
    getPropertyDetails: (id: string) => void;

    isSetGoogleDrivePathModalOpen: boolean;
    setGoogleDrivePathModalOpen: (state: boolean) => void;
}

const SetGoogleDrivePathModal: FunctionComponent<SetGoogleDrivePathModalProps> = (props: SetGoogleDrivePathModalProps) => {
    const {
        id,
        setGoogleDrivePathAttempt,
        setGoogleDrivePathError,
        setGoogleDrivePath,
        propertyDetails,
        getPropertyDetails,
        isSetGoogleDrivePathModalOpen,
        setGoogleDrivePathModalOpen,
    } = props;

    const [proceed, setProceed] = useState(true);

    const [vacantPossession, setVacantPossession] = useState<string>('');
    const [defectInspection, setDefectInspection] = useState<string>('');

    // useEffect(() => {
    //     return () => {
    //         getPropertyDetails
    //     };
    // }, []);

    useEffect(() => {
        if (propertyDetails) {
            if (propertyDetails.packages?.vacantPossession) {
                setVacantPossession(propertyDetails.packages.vacantPossession.googleDrivePath);
            }
            if (propertyDetails.packages?.defectInspection) {
                setDefectInspection(propertyDetails.packages.defectInspection.googleDrivePath);
            }
        }
    }, [propertyDetails?.packages]);

    const cancelClickHandler = () => {
        setGoogleDrivePathModalOpen(false);
        if (propertyDetails) {
            if (propertyDetails.packages?.vacantPossession) {
                setVacantPossession(propertyDetails.packages.vacantPossession.googleDrivePath);
            }
            if (propertyDetails.packages?.defectInspection) {
                setDefectInspection(propertyDetails.packages.defectInspection.googleDrivePath);
            }
        }
    };
    const confirmClickHandler = () => {
        setGoogleDrivePath(
            id,
            PackageCategory.VacantPossession,
            vacantPossession,
        );
        setGoogleDrivePath(
            id,
            PackageCategory.DefectInspection,
            defectInspection,
        );
    };

    const renderModalHeader = () => {
        return (
            'Set Google Drive Path'
        );
    };

    const renderModalBody = () => {
        if (setGoogleDrivePathAttempt) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Oval
                        height={150}
                        width={150}
                        color={Colors.primary}
                        secondaryColor={Colors.primaryLight}
                    />
                </div>
            );
        }

        if (setGoogleDrivePathError) {
            return (
                <div>
                    {setGoogleDrivePathError}
                </div>
            );
        }

        return (
            <>
                <StyledLabel>
                    Vacant Possession
                </StyledLabel>
                <StyledInput
                    value={vacantPossession}
                    onChange={(e) => setVacantPossession(e.target.value)}
                />

                <StyledLabel>
                    Defect Inspection
                </StyledLabel>
                <StyledInput
                    value={defectInspection}
                    onChange={(e) => setDefectInspection(e.target.value)}
                />

            </>
        );
    };

    const renderModalFooter = () => {
        if (setGoogleDrivePathError) {
            return (
                <Button
                    label='Proceed'
                    onClick={() => window.location.reload()}
                />
            );
        }

        return (
            <>
                <Button
                    label='Cancel'
                    style={{
                        marginRight: '10px',
                        backgroundColor: Colors.white,
                        color: Colors.primary,
                        border: `1px solid ${Colors.primary}`,
                    }}
                    onClick={cancelClickHandler}
                    disabled={setGoogleDrivePathAttempt}
                />
                <Button
                    label='Proceed'
                    onClick={confirmClickHandler}
                    disabled={setGoogleDrivePathAttempt}
                />
            </>
        );
    };

    return (
        <Modal
            show={isSetGoogleDrivePathModalOpen}
            width={proceed ? '30%' : '40%'}
            height='fit-content'
        >
            <ModalHeader>
                {renderModalHeader()}
            </ModalHeader>

            <ModalBody>
                {renderModalBody()}
            </ModalBody>

            <ModalFooter
                proceed={proceed}
            >
                {renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ModalBody = styled.div`
    margin-bottom: 20px;
`;

interface ModalFooterProps {
    proceed: boolean;
}

const ModalFooter = styled.div<ModalFooterProps>`
    display: flex;
    justify-content: ${props => (props.proceed ? 'flex-start' : 'flex-end')}
`;

const StyledLabel = styled.div`
    font-size: 16px;
    color: black;
    margin-bottom: 5px;
`;

const StyledInput = styled.input<{invalid?: boolean, lastChild?: boolean}>`
    border: ${props => (props.invalid ? '1px solid red' : 'none')};
    outline: ${props => (props.invalid ? '1px solid red' : 'none')};
    background-color: #F6F6F6;
    width: 100%;
    border-radius: 12px;
    height: 30px;
    padding: 10px;

    margin-bottom: ${props => (props.lastChild ? '0px' : '20px')};

    &:focus {
        outline: ${props => (props.invalid ? '1px solid red' : '2px solid grey')};
    }

    &:active {
        outline: ${props => (props.invalid ? '1px solid red' : '1px solid grey')};
    }
`;

const mapStateToProps = (state: RootState) => ({
    isSetGoogleDrivePathModalOpen: Selectors.getPropertyGetGoogleDrivePathModalOpen(state),
    setGoogleDrivePathAttempt: Selectors.getPropertySetGoogleDrivePathAttempting(state),
    setGoogleDrivePathError: Selectors.getPropertySetGoogleDrivePathError(state),
    propertyDetails: Selectors.getPropertyPropertyDetails(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyDetails: (id: string) => dispatch(Actions.propertyGetDetailsAttempt({ id })),

    setGoogleDrivePathModalOpen: (state: boolean) => dispatch(Actions.setGoogleDrivePathModalOpen(state)),
    setGoogleDrivePath: (propertyId: string, packageType: PackageCategory, googleDrivePath: string) => dispatch(Actions.setGoogleDrivePathAttempt({ propertyId, packageType, googleDrivePath })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetGoogleDrivePathModal);
