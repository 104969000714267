import React from 'react';
import styled, { css } from 'styled-components';

import SVG from 'react-inlinesvg';

import { Colors } from '@dm/bigfish';

import Icons from 'assets/icons';

import PropertyImage from '../assets/images/house1.png';

interface PropertyCardProps {
  img?: string;
  chipText?: string;
  title: string;
  location: string;
  style?: React.CSSProperties;
  active?: boolean;
  onClick?: () => void;
}

const PropertyCard = (props: PropertyCardProps): JSX.Element => {
    const { img, chipText, title, location, style, onClick, active, ...otherProps } = props;

    return (
        <Container style={style} onClick={onClick} active={active} {...otherProps}>
            <ImageContainer>
                {chipText && <Chip>{chipText}</Chip>}
                <Img src={img || PropertyImage} alt='property image' />
            </ImageContainer>
            <TextContainer>
                <TextTitle>{title}</TextTitle>
                <LocationContainer>
                    <Logo src={Icons.MapPinIcon} />
                    <TextLocation>{location}</TextLocation>
                </LocationContainer>
            </TextContainer>
        </Container>
    );
};

PropertyCard.defaultProps = {
    img: undefined,
    chipText: undefined,
    style: undefined,
    onClick: undefined,
    active: false,
};

const Container = styled.div<{active?: boolean}>`
  display: flex;
  flex-direction: column;
  width: 264px;
  height: 248px;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  
  background: ${Colors.primary};
  color: ${Colors.white};

  ${(props) => props.active && css`
    border: 4px solid ${Colors.secondary};
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  height: 160px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
`;

const Chip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  max-width: 200px;
  padding: 10px;
  border-bottom-right-radius: 12px;

  background: ${Colors.primary};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
`;

const TextTitle = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700px;
  margin-bottom: 16px;
`;

const TextLocation = styled.h5`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

const Logo = styled(SVG)`
  width: 12px;
  height: 14.5px;
  color: ${Colors.white};
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;

  ${Logo}{
    margin-right: 10px;
  }
`;

export default PropertyCard;
