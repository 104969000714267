import React, { FunctionComponent } from 'react';

import SVG from 'react-inlinesvg';

import styled from 'styled-components';
import Icons from 'assets/icons';

interface InputProps {
    label: string;
    eventHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface MultiSearchbarProps {
    inputs: InputProps[];
}

const renderSearchbar = (data: InputProps[]) => {
    return (
        <>
            {
                data.map((input, index) => {
                    if (index === 0) {
                        return (
                            <StyledInput placeholder={input.label} onChange={input.eventHandler} />
                        );
                    }
                    return (
                        <StyledInputWithBoxShadow placeholder={input.label} onChange={input.eventHandler} />
                    );
                })
            }
            <IconContainer>
                <Icon src={Icons.SearchIcon} />
            </IconContainer>
        </>
    );
};

const MultiSearchbar: FunctionComponent<MultiSearchbarProps> = (props: MultiSearchbarProps) => {
    const { inputs } = props;

    return (

        <MultiSearchBarBody>
            {
                (inputs.length > 0) ? (
                    renderSearchbar(inputs)
                ) : null
            }
        </MultiSearchBarBody>

    );
};

const MultiSearchBarBody = styled.div`
    display: flex;
    width: fit-content;
    margin-bottom: 40px;
    min-height: 40px;
    padding-right: 0.5rem;
    border-radius: 20px;
    box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px;
`;

const IconContainer = styled.div`
    width: 30px;
    height: 30px;
    background-color: #F2B47E;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    align-self: center;
`;

const Icon = styled(SVG)`
    width: 15px;
    height: 15px;
    color: white;
`;

const StyledInput = styled.input`
    padding: 0 1.6rem;
    flex: 1;
    font-size: 14px;
    line-height: 16px;
    padding-block: 12px;
    border: none;
    outline: none;
    margin: 0;
    border-top-style: hidden;
    border-right-style: hidden;
    cursor: pointer;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    user-select: none;
    &::-webkit-user-select {
        user-select: none;
    }
    &::-moz-user-select {
        user-select: none;
    }
    &::-ms-user-select {
        user-select: none;
    }
`;

const StyledInputWithBoxShadow = styled(StyledInput)`
    box-shadow: -3px 0 2px -2px #c2c0c0;
    border-radius: unset;
`;

export default MultiSearchbar;
