export default [
    {
        id: 1,
        description: 'Pending',
    },
    {
        id: 2,
        description: 'Pending Payment Verification',
    },
    {
        id: 3,
        description: 'Active',
    },
    {
        id: 4,
        description: 'Payment Rejected',
    },
    {
        id: 5,
        description: 'Disabled',
    },

    {
        id: 6,
        description: 'Free Trial',
    },
];
