import { Colors } from '@dm/bigfish';
import React from 'react';
import styled, { keyframes } from 'styled-components';

interface CustomToggleProps {
  label: string;
  isToggled: boolean;
  onToggle: () => void;
}

const CustomToggle = (props: CustomToggleProps):JSX.Element => {
    const { label, isToggled, onToggle } = props;
    return (
        <RecurringToggle>
            <p>{label}</p>
            <ToggleLabel checked={isToggled}>
                <ToggleInput checked={isToggled} onChange={onToggle} />
            </ToggleLabel>
        </RecurringToggle>
    );
};

const RecurringToggle = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
    p{
        align-self: center;
        padding-left: 10px;
    }
`;

const ToggleInput = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
`;

const ToggleLabel = styled.label<{ checked: boolean}>`
    display: flex;
    align-items: center;
    position: relative;
    width: 50px;
    height: 24px;
    border-radius: 24px;
    border: 2px solid #949494;
    background-color: ${props => (props.checked ? Colors.primaryLight : Colors.primaryLightest)};
    transition: background-color 0.2s ease;

    &:after {
    content: '';
    /* position: absolute; */
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color:${props => (props.checked ? Colors.primary : Colors.grey)};
    transition: transform 0.2s ease;
    transform: ${props => (props.checked ? 'translateX(26px)' : 'translateX(0)')};
    }
`;

export default CustomToggle;
