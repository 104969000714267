export default {
    Ui: {
        UiCareline: 'Hubungi Kami',
        UiBack: 'Kembali',
        UiCompanyName: 'Dormeo Homes',
        UiSearchTitle: 'Beli',
        UiProfileButtonTitle: 'Daftar masuk',
    },

    BreadCrumb: {
        BreadCrumbDashboard: 'Dashboard',
        BreadCrumbWelcomeBack: 'Welcome Back Admin!',
    },

    Login: {
        LoginLabel: 'Daftar Masuk',
        LoginOwnerLogin: 'Daftar Masuk Pentadbir',
        LoginEmail: 'alamat emel anda',
        LoginPlaceholder: 'cth. example123@gmail.com',
        LoginRememberMe: 'Ingat Saya',
        LoginPassword: 'Kata laluan Anda',
        LoginHaveYou: 'Adakah anda',
        LoginNewTo: 'Baru untuk Dormeo?',
        LoginJoinNow: 'Daftar sekarang',
        LoginForgotPassword: 'lupa kata laluan?',
        LoginErrorEmail: 'Sila masukkan alamat emel yang sah',
        LoginBcLogin: 'Daftar Masuk',
        LoginBcOwnerLogin: 'Daftar Masuk Pentadbir',
    },

    Modules: {
        ModulesCreate: 'Create',
        ModulesCreateDesc: 'Add new owner accounts & property listings',
        ModulesReports: 'Reports',
        ModulesReportsDesc: 'Check detailed reports on listings',
        ModulesOwner: 'Owners',
        ModulesOwnerDesc: 'Check current property owner’s details',
        ModulesRea: 'Real Estate Agents',
        ModulesReaDesc: 'Check current REA’s details',
        ModulesPropertyListings: 'Property Listings',
        ModulesPropertyListingsDesc: 'Check current property listing’s details',
        ModulesFinancialReports: 'Financial Reports',
        ModulesFinancialReportsDesc: 'Check current property listing’s financial reports',
        ModulesPayments: 'Payments',
        ModulesPaymentsDesc: 'View all payments',
        ModulesPendingActivities: 'Pending Activities',
        ModulesPendingActivitiesButton: 'Go to Pending Activities page',
        ModulesPendingActivitiesEmpty: 'You have no pending activities',
        ModulesPendingApprovals: 'Pending Approvals',
        ModulesPendingApprovalsButton: 'Go to Approvals page',
        ModulesPendingApprovalsEmpty: 'You have no pending approvals',
        ModulesReviewCoBroke: 'Review co-broke',
        ModulesReviewCoBrokeDesc: 'View all the cobrokes',
    },

    Modal: {
        ModulesCreateTitle: 'What are you creating?',
        ModulesCreateDesc: 'Please choose the correct option to start creation process.',
        ModulesCreateBtnOwner: 'Owner Account',
        ModulesCreateBtnListing: 'Property Listing',
    },

    CreateNew: {
        CreateNewBcDashboard: 'Dashboard',
        CreateNewBcCreate: 'Create',
        CreateNewBcOwner: 'Owner Account Setup Process',
        CreateNewBcError1: 'Please fill and complete the attach properties field and acc details!',
        CreateNewBcDesc: 'Please ensure each step of the process is filled in correctly to ensure no errors.',
        CreateNewStep1: 'step 1:',
        CreateNewOwnerDetails: 'Owner’s Details',
        CreateNewDesc: 'Please fill in the details for the following categories to complete your account setup.',
        CreateNewTitle: 'Personal Details',
        CreateNewLabelTitle: 'Title',
        CreateNewLabelGender: 'Gender',
        CreateNewLabelCPAddress1: 'Corresponding Address 1',
        CreateNewLabelCPAddress2: 'Corresponding Address 2',
        CreateNewLabelFirstName: 'First Name',
        CreateNewLabelLastName: 'Last Name',
        CreateNewLabelIDNo: 'ID No',
        CreateNewLabelCity: 'City/ Town',
        CreateNewLabelPassport: 'Passport No',
        CreateNewLabelMobile: 'Mobile No',
        CreateNewLabelPostcode: 'Postcode',
        CreateNewLabelCountryOrigin: 'Country of Origin',
        CreateNewLabelHouseTel: 'House Tel.',
        CreateNewLabelState: 'State',
        CreateNewLabelRace: 'Race',
        CreateNewLabelCountryResidence: 'Country of Residence',
        CreateNewLabelCountry: 'Country',
        CreateNewLabelDOB: 'Date of Birth',
        CreateNewLabelBankType: 'Bank Type',
        CreateNewLabelCurrency: 'Currency',
        CreateNewLabelBranchAddress: 'Bank Address',
        CreateNewLabelBranchName: 'Bank Name',
        CreateNewLabelBranchAccNo: 'Bank Acc No',
        CreateNewLabelSwiftCode: 'Swift Code',
        CreateNewLabelCompanyName: 'Company Name',
        CreateNewLabelDesignation: 'Company Designation',
        CreateNewLabelOfficeNo: 'Office No.',
        CreateNewLabelFullName: 'Full Name',
        CreateNewLabelRelationship: 'Relationship',
        CreateNewLabelEmail: 'Email',
        CreateNewErrorEmail: 'Please insert a valid email address',
        CreateNewErrorPass: 'Please insert a valid password',
        CreateNewErrorPassConfirm: 'Password confirmation does not match with password',
        CreateNewFinancialDetails: 'Financial Details',
        CreateNewCompDetails: 'Company Details',
        CreateNewEmergencyDetails: 'Emergency Contact Details',
        CreateNewStep2: 'step 2:',
        CreateNewAttachProp: 'Attach Properties',
        CreateNewNewProj: 'new project',
        CreateNewNoPropFound: 'No Property Found',
        CreateNewStep3: 'step 3:',
        CreateNewAccDetails: 'Account Details',
        CreateNewBtnSubmit: 'Submit',
        CreateNewBtnClear: 'Clear',
        CreateNewBtnConfirm: 'Confirm',
        CreateNewModalText1: 'This property belongs to ',
        CreateNewModalText2: ', are sure to continue?',
        CreateNewModalBtnYes: 'Yes, continue',
        CreateNewModalBtnNo: 'No, select another',
    },

    OwnersReport: {
        OwnersReportBcDashboard: 'Dashboard',
        OwnersReportBcOwners: 'Owners',
        OwnersReportBcOwnerDetails: 'Owner Details',
        OwnersReportSearchTitle: 'Search for Owner Listing',
        OwnersReportFilterTitle: 'Owner Accounts Results',
        OwnersReportFilterCountry: 'Country',
        OwnersReportFilterLocation: 'Location',
        OwnersReportFilterStatus: 'Status',
        OwnersReportFilterProperties: 'Properties',
        OwnersReportFilterFinance: 'Finance',
        OwnersReportFilterDefault: 'Default',
        OwnersReportSaveSearch: 'Save search',
        OwnersReportHeaderName: 'Name',
        OwnersReportHeaderPackages: 'Packages',
        OwnersReportHeaderAge: 'Age',
        OwnersReportHeaderGender: 'Gender',
        OwnersReportHeaderLocation: 'Location',
        OwnersReportHeaderProp: 'Properties',
        OwnersReportHeaderMonthlyExpense: 'Monthly Expense',
        OwnersReportError1: 'No records found',
        OwnersReportError2: 'If using a custom view, try adjusting the filters.',
        OwnersReportError3: 'Otherwise, create some data!',
        OwnersReportError4: 'Something error',
        OwnersReportError5: 'Please contact system administrator for details.',
        OwnersReportError6: 'Otherwise, please try again!',
        OwnersReportRecentViewed: 'Recently Viewed',
        OwnersReportMoreLocations: 'More Locations',
        OwnersReportButtonMore: 'More',
        OwnersReportManagedProperties: 'Managed Properties',
    },

    PropertyReport: {
        PropertyReportBcDashboard: 'Dashboard',
        PropertyReportBcProperty: 'Property Listings',
        PropertyReportFilterTitle: 'All Property Listings',
        PropertyReportFilterBuildingTypes: 'All Building Types',
        PropertyReportFilterStatus: 'Status',
        PropertyReportFilterTypes: 'Types',
        PropertyReportFilterSize: 'Built-up Size',
        PropertyReportFilterConfig: 'Configurations',
        PropertyReportFilterProp: 'Show Properties',
        PropertyReportFilterDefault: 'Default',
        PropertyReportSaveSearch: 'Save search',
        PropertyReportHeaderName: 'Name',
        PropertyReportHeaderStatus: 'Status',
        PropertyReportHeaderSize: 'Size',
        PropertyReportHeaderType: 'Type',
        PropertyReportHeaderConfig: 'Configuration',
        PropertyReportHeaderOwner: 'Owner',
        PropertyReportHeaderLocation: 'Location',
        PropertyReportError1: 'No records found',
        PropertyReportError2: 'If using a custom view, try adjusting the filters.',
        PropertyReportError3: 'Otherwise, create some data!',
        PropertyReportError4: 'Something error',
        PropertyReportError5: 'Please contact system administrator for details.',
        PropertyReportError6: 'Otherwise, please try again!',
        PropertyReportRecentViewed: 'Recently Viewed',
        PropertyReportMoreLocations: 'More Locations',
        PropertyReportButtonMore: 'More',
        PropertyReportProperty: 'Property',
        PropertyReportTitle: 'Search For Property Listing',
    },

    PropertyRpDetails: {
        PropertyRpDetailsBcDashboard: 'Dashboard',
        PropertyRpDetailsBcPropertyListing: 'Property Listings',
        PropertyRpDetailsTitlePropDetails: 'Property Details',
        PropertyRpDetailsTitleRoomDetails: 'Room Details',
        PropertyRpDetailsTitleAdminControls: 'Admin Controls',
        PropertyRpDetailsBtnApprove: 'Approve Property',
        PropertyRpDetailsBtnReject: 'Reject Property',
        PropertyRpDetailsBtnContact: 'Contact Owner',
        PropertyRpDetailsLabelPropType: 'Property Type',
        PropertyRpDetailsLabelLandTitle: 'Land Title',
        PropertyRpDetailsLabelUnitType: 'Unit Type',
        PropertyRpDetailsLabelPropTitleType: 'Property Title Type',
        PropertyRpDetailsLabelTenure: 'Tenure',
        PropertyRpDetailsLabelStorey: 'Storey',
        PropertyRpDetailsLabelBtSize: 'Built-up Size',
        PropertyRpDetailsLabelBtPrice: 'Built-Up Price',
        PropertyRpDetailsLabelBtDim: 'Built-Up Dimension',
        PropertyRpDetailsLabelRefNo: 'Reference No.',
        PropertyRpDetailsLabelPostedDate: 'Posted Date',
        PropertyRpDetailsLabelFurnishing: 'Furnishing',
        PropertyRpDetailsLabelOccupancy: 'Occupancy',
        PropertyRpDetailsLabelFacingDirection: 'Facing Direction',
        PropertyRpDetailsErrorNoImagesFound: 'No images found',
        PropertyRpDetailsInstruction: 'Scroll horizontally to show other images',
    },

    PaymentsReport: {
        PaymentsRpBcDashboard: 'Dashboard',
        PaymentsRpBcPayments: 'Payments',
        PaymentsRpTitle: 'Payments',
        PaymentsRpHeaderID: 'ID',
        PaymentsRpHeaderName: 'Property',
        PaymentsRpHeaderUnit: 'Unit No.',
        PaymentsRpHeaderCreatedAt: 'Created At',
        PaymentsRpHeaderTotalPrice: 'Total Price',
        PaymentsRpHeaderDocuments: 'Dokemen',
        PaymentsRpHeaderStatus: 'Status',
        PaymentsRpHeaderActions: 'Actions',
        PaymentsRpActionButtonApprove: 'Approve',
        PaymentsRpActionButtonReject: 'Reject',
        PaymentsRpApproveLowerCase: 'approve',
        PaymentsRpRejectLowerCase: 'reject',
        PaymentsRpApproveRejectMsg1: 'Are you sure you want to',
        PaymentsRpApproveRejectMsg2: 'this payment?',
    },

    RecentEvent: {
        RecentEventParagraph: 'Manage the Events and Notifications for Properties',
        RecentEventHeading: 'Current Event Message Display',
        RecentEventTableTitle: 'Latest Recent Events',
        RecentEventTableButton: 'Add New Event',
        RecentEventTemplateTitle: 'Write an Event Message',
        RecentEventTemplatePair: 'Pair Event Message with Status',
        RecentEventTemplateSaved: 'Save as Event Template',
        RecentEventTemplateButtonClear: 'Clear All',
        RecentEventTemplateButtonPublish: 'Publish',
        RecentEventRecentActivities: 'Recent Activities',
        RecentEventNoRecent: 'You have no recent activities',
        RecentEventViewAll: 'View All',
    },

    Cobrokes: {
        CobrokesBcCobrokes: 'Cobrokes',
        CobrokesHeaderEmail: 'Email',
        CobrokesHeaderPropertyName: 'Property Name',
        CobrokesHeaderPropertyId: 'Property ID',
        CobrokesHeaderStatus: 'Status',
        CobrokesHeaderView: 'View',
        CobrokesHeaderAction: 'Action',
        CobrokesFilterTitle: ' Cobrokes Results',
    },

    ReaReport: {
        ReaReportBcDashboard: 'Dashboard',
        ReaReportBcRea: 'REA',
        ReaReportSearchTitle: 'Search for Real Estate Agent Report',
        ReaReportHeaderEmail: 'Email',
        ReaReportHeaderFirstName: 'First Name',
        ReaReportHeaderLastName: 'Last Name',
        ReaReportHeaderPhoneNo: 'Phone Number',
    },

    ReaDetails: {
        ReaDetailsBcDashboard: 'Dashboard',
        ReaDetailsBcRea: 'REA',
        ReaDetailsBcReaDetails: 'REA Details',
        ReaDetailsTitle1: "Agents's Details",
        ReaDetailsTitle2: "Agents's Managed Properties",
        ReaDetailsNoProperty: 'No Property',
        ReaDetailsViewDocumentBtn: 'View REA Document',
    },

};
