import { fork } from 'redux-saga/effects';

import ReaGateway from 'api/Rea';
import { RootSagaReturnType } from 'sagas/types';

import watchGetCobrokes from './getCobrokes';
import watchReviewCobrokes from './reviewCobrokes';
import watchGetRea from './getRea';
import watchGetReaDetails from './getReaDetails';

export default (api: ReaGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetCobrokes, api);
        yield fork(watchReviewCobrokes, api);
        yield fork(watchGetRea, api);
        yield fork(watchGetReaDetails, api);
    }

    return {
        rootSaga,
    };
};
