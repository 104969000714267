import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPendingPaymentResponse, PaymentStatusEnum, ViewAllPMTransactionResponse } from '@dm/types';
import { IApproveRejectPendingPayment } from 'entities/property';
import { GetPendingPaymentsActionPayload, IGetTransactionsReportExportPayload, PaymentReduxState } from './types';

const initialState: PaymentReduxState = {
    actions: {
        getAllPayment: false,
        approveReject: false,
        getFileList: false,
        getTransactionsReportExport: false,
        confirmRefund: false,
    },
    paymentData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    receiptImagesModalOpen: false,
    approveRejectModalOpen: false,
    isTransactionModalOpen: false,
    isUploadFileModalOpen: false,
    isFileViewModalOpen: false,
    isExportModalOpen: false,
    isConfirmRefundModalOpen: false,
    fileList: [],
    approveRejectPaymentParams: {
        paymentId: '',
        status: PaymentStatusEnum.Pending,
    },
    error: {
        getAllPayment: '',
        approveReject: '',
        getFileList: '',
        confirmRefund: '',
    },
};

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        getAllPaymentsAttempt: (state, _action: GetPendingPaymentsActionPayload) => {
            state.actions.getAllPayment = true;
            state.error.getAllPayment = '';
        },
        getAllPaymentsSuccess: (state, action: PayloadAction<GetPendingPaymentResponse>) => {
            state.actions.getAllPayment = false;
            state.error.getAllPayment = '';

            state.paymentData = action.payload;
        },
        getAllPaymentsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAllPayment = false;
            if (action.payload) {
                state.error.getAllPayment = action.payload;
            }
        },
        getAllPaymentsReset: (state) => {
            state.paymentData = {
                index: 0,
                maxIndex: 0,
                data: [],
            };
        },
        setReceiptImageModalOpen: (state, action: PayloadAction<boolean>) => {
            state.receiptImagesModalOpen = action.payload;
        },

        setApproveRejectModalOpen: (state, action: PayloadAction<boolean>) => {
            state.approveRejectModalOpen = action.payload;
        },
        setApproveRejectParams: (state, action: PayloadAction<IApproveRejectPendingPayment>) => {
            state.approveRejectPaymentParams = action.payload;
        },
        resetApproveRejectParams: (state) => {
            state.approveRejectPaymentParams = {
                paymentId: '',
                status: PaymentStatusEnum.Pending,
            };
        },

        approveRejectPaymentAttempt: (state, _action: PayloadAction<IApproveRejectPendingPayment>) => {
            state.actions.approveReject = true;
            state.error.approveReject = '';
        },
        approveRejectPaymentSuccess: (state) => {
            state.actions.approveReject = false;
            state.error.approveReject = '';
        },
        approveRejectPaymentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.approveReject = false;
            if (action.payload) {
                state.error.approveReject = action.payload;
            }
        },
        setTransactionModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isTransactionModalOpen = action.payload;
        },
        setUploadFileModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUploadFileModalOpen = action.payload;
        },
        setFileViewModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isFileViewModalOpen = action.payload;
        },
        setIsExportModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isExportModalOpen = action.payload;
        },
        setIsConfirmRefundModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isConfirmRefundModalOpen = action.payload;
        },
        setFileList: (state, action: PayloadAction<ViewAllPMTransactionResponse['fileUrl']>) => {
            state.fileList = action.payload;
        },
        getTransactionsReportExportAttempt: (state, _action: PayloadAction<IGetTransactionsReportExportPayload>) => {
            state.actions.getTransactionsReportExport = true;
        },
        getTransactionsReportExportSuccess: (state) => {
            state.actions.getTransactionsReportExport = false;
        },
        getTransactionsReportExportFailure: (state) => {
            state.actions.getTransactionsReportExport = false;
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: paymentSlice.actions,
    reducers: paymentSlice.reducer,
};
