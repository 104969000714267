import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import { toast } from 'react-toastify';
import Actions from 'redux/Actions';
import { UpdateProgressDefectInspectionPayload } from 'redux/slices/packages/types';

export default function* watchUpdateProgressDefectInspection(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesUpdateProgressDefectInspectionAttempt', handleUpdateProgressDefectInspection, api);
}

function* handleUpdateProgressDefectInspection(api: PackagesGateway, data: UpdateProgressDefectInspectionPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const {
            propertyId,
            stage,
            remark,
            date,
        } = data.payload;

        const response = yield* call([api, api.updateProgressDefectInspection], {
            authToken,
            propertyId,
            stage,
            remark,
            date,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesUpdateProgressDefectInspectionFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }

            yield put(Actions.packagesUpdateProgressDefectInspectionSuccess());
            yield put(Actions.propertyGetDetailsAttempt({ id: propertyId }));
            toast.success('Progress updated!');
        } else {
        // handle error
            yield put(Actions.packagesUpdateProgressDefectInspectionFailure(response.message));
            toast.error(response.message);
        }
    }
}
